import { SetPiece } from 'sos-models';

export const GET_COMPLETE = '[SetPiece] Get Complete';
type GET_COMPLETE = '[SetPiece] Get Complete';

export const LIST_COMPLETE = '[SetPiece] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FOR_SET = '[SetPiece] List for Set';
type LIST_FOR_SET = typeof LIST_FOR_SET;

export const LIST_FOR_SET_FAILED = '[SetPiece] List for Set Failed';
type LIST_FOR_SET_FAILED = typeof LIST_FOR_SET_FAILED;

export const LIST_NO_SET = '[SetPiece] List for No Set';
type LIST_NO_SET = typeof LIST_NO_SET;

export const LIST_NO_SET_FAILED = '[SetPiece] List for No Set Failed';
type LIST_NO_SET_FAILED = typeof LIST_NO_SET_FAILED;

export interface GetCompleteAction {
	setPiece: SetPiece;
	type: GET_COMPLETE;
}

export interface ListCompleteAction {
	type: LIST_COMPLETE;
	inventory: SetPiece[];
}

export interface ListForSetAction {
	type: LIST_FOR_SET;
	episodicId: number;
	setId: number;
}

export interface ListForSetFailedAction {
	type: LIST_FOR_SET_FAILED;
	error: Error;
}

export interface ListNoSetAction {
	type: LIST_NO_SET;
	episodicId: number;
}

export interface ListNoSetFailedAction {
	type: LIST_NO_SET_FAILED;
	error: Error;
}

export function getComplete(setPiece: SetPiece): GetCompleteAction {
	return {
		setPiece,
		type: GET_COMPLETE,
	};
}

export function listComplete(inventory: SetPiece[]): ListCompleteAction {
	return {
		inventory,
		type: LIST_COMPLETE,
	};
}

export function listForSet(
	episodicId: number,
	setId: number
): ListForSetAction {
	return {
		type: LIST_FOR_SET,
		episodicId,
		setId,
	};
}

export function listForSetFailed(error: Error): ListForSetFailedAction {
	return {
		type: LIST_FOR_SET_FAILED,
		error,
	};
}

export function listNoSet(episodicId: number): ListNoSetAction {
	return {
		type: LIST_NO_SET,
		episodicId,
	};
}

export function listNoSetFailed(error: Error): ListNoSetFailedAction {
	return {
		type: LIST_NO_SET_FAILED,
		error,
	};
}

export type Action =
	| GetCompleteAction
	| ListCompleteAction
	| ListForSetAction
	| ListForSetFailedAction
	| ListNoSetAction
	| ListNoSetFailedAction;
