import {
	EpisodicActor,
	HaEpisodicActor,
	MuEpisodicActor,
	PartialDeptEpisodicActorNotes,
} from 'sos-models';

export const CREATE = '[Episodic Actor] Create';
type CREATE = typeof CREATE;

export const CREATE_COMPLETE = '[Episodic Actor] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const CREATE_FAILED = '[Episodic Actor] Create Failed';
type CREATE_FAILED = typeof CREATE_FAILED;

export const EXPORT_TO_NEW_SEASON = '[Episodic Actor] Export to New Season';
type EXPORT_TO_NEW_SEASON = typeof EXPORT_TO_NEW_SEASON;

export const EXPORT_TO_NEW_SEASON_COMPLETE =
	'[Episodic Actor] Export to New Season Complete';
type EXPORT_TO_NEW_SEASON_COMPLETE = typeof EXPORT_TO_NEW_SEASON_COMPLETE;

export const EXPORT_TO_NEW_SEASON_FAILED =
	'[Episodic Actor] Export to New Season Failed';
type EXPORT_TO_NEW_SEASON_FAILED = typeof EXPORT_TO_NEW_SEASON_FAILED;

export const GET = '[Episodic Actor] Get';
type GET = typeof GET;

export const GET_COMPLETE = '[Episodic Actor] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const GET_FAILED = '[Episodic Actor] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export const LIST = '[Episodic Actor] List';
type LIST = typeof LIST;

export const LIST_COMPLETE = '[Episodic Actor] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FAILED = '[Episodic Actor] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const SET_CURRENT = '[Episodic Actor] Set Current';
type SET_CURRENT = typeof SET_CURRENT;

export const DESTROY = '[Episodic Actor] Destroy';
type DESTROY = typeof DESTROY;

export const DESTROY_COMPLETE = '[Episodic Actor] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const DESTROY_FAILED = '[Episodic Actor] Destroy Failed';
type DESTROY_FAILED = typeof DESTROY_FAILED;

export const UPDATE = '[Episodic Actor] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[Episodic Actor] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[Episodic Actor] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const UPDATE_NOTES = '[Episodic Actor] Update Notes';
type UPDATE_NOTES = typeof UPDATE_NOTES;

export const UPDATE_NOTES_COMPLETE = '[Episodic Actor] Update Notes Complete';
type UPDATE_NOTES_COMPLETE = typeof UPDATE_NOTES_COMPLETE;

export const UPDATE_NOTES_FAILED = '[Episodic Actor] Update Notes Failed';
type UPDATE_NOTES_FAILED = typeof UPDATE_NOTES_FAILED;

export interface SetCurrentAction {
	episodicActorId: number;
	type: SET_CURRENT;
}

export interface ExportToNewSeasonAction {
	episodicActorIds: number[];
	episodicId: number;
	newEpisodicId: number;
	checkIfExported: boolean;
	type: EXPORT_TO_NEW_SEASON;
}

export interface ExportToNewSeasonCompleteAction {
	type: EXPORT_TO_NEW_SEASON_COMPLETE;
}

export interface ExportToNewSeasonFailedAction {
	error: Error;
	type: EXPORT_TO_NEW_SEASON_FAILED;
}

export interface GetAction {
	episodicActorId: number;
	episodicId: number;
	type: GET;
}

export interface GetCompleteAction {
	episodicActor: EpisodicActor;
	type: GET_COMPLETE;
}

export interface GetFailedAction {
	error: Error;
	type: GET_FAILED;
}

export interface CreateAction {
	epCharIds: number[];
	episodicActors: Partial<EpisodicActor>[];
	episodicId: number;
	type: CREATE;
}

export interface CreateCompleteAction {
	episodicActors: EpisodicActor[];
	type: CREATE_COMPLETE;
}

export interface CreateFailedAction {
	error: Error;
	type: CREATE_FAILED;
}

export interface GetAction {
	episodicActorId: number;
	episodicId: number;
	type: GET;
}

export interface GetCompleteAction {
	episodicActor: EpisodicActor;
	type: GET_COMPLETE;
}

export interface GetFailedAction {
	type: GET_FAILED;
	error: Error;
}

export interface DestroyAction {
	episodicActorId: number;
	episodicId: number;
	type: DESTROY;
}

export interface DestroyCompleteAction {
	episodicActor: EpisodicActor;
	type: DESTROY_COMPLETE;
}

export interface DestroyFailedAction {
	type: DESTROY_FAILED;
	error: Error;
}

export interface ListAction {
	episodicId: number;
	type: LIST;
}

export interface ListCompleteAction {
	episodicActors: EpisodicActor[];
	type: LIST_COMPLETE;
}

export interface ListFailedAction {
	error: Error;
	type: LIST_FAILED;
}

export interface UpdateAction {
	episodicId: number;
	episodicActors: Partial<EpisodicActor>[];
	epActorIdToEpCharIds: { [id: number]: number[] };
	haEpisodicActors?: Partial<HaEpisodicActor>[];
	muEpisodicActors?: Partial<MuEpisodicActor>[];
	type: UPDATE;
}

export interface UpdateCompleteAction {
	episodicActors: EpisodicActor[];
	type: UPDATE_COMPLETE;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export interface UpdateNotesAction {
	episodicId: number;
	episodicActorId: number;
	episodicActorNotes: PartialDeptEpisodicActorNotes;
	type: UPDATE_NOTES;
}

export interface UpdateNotesCompleteAction {
	type: UPDATE_NOTES_COMPLETE;
}

export interface UpdateNotesFailedAction {
	error: Error;
	type: UPDATE_NOTES_FAILED;
}

export interface SetCurrentAction {
	episodicActorId: number;
	type: SET_CURRENT;
}

export function create(
	episodicId: number,
	episodicActors: Partial<EpisodicActor>[],
	epCharIds: number[]
): CreateAction {
	return {
		episodicId,
		episodicActors,
		epCharIds,
		type: CREATE,
	};
}

export function createComplete(
	episodicActors: EpisodicActor[]
): CreateCompleteAction {
	return {
		episodicActors,
		type: CREATE_COMPLETE,
	};
}

export function createFailed(error: Error): CreateFailedAction {
	return {
		error,
		type: CREATE_FAILED,
	};
}

export function exportToNewSeason(
	episodicId: number,
	newEpisodicId: number,
	episodicActorIds: number[],
	checkIfExported: boolean
): ExportToNewSeasonAction {
	return {
		episodicId,
		episodicActorIds,
		newEpisodicId,
		checkIfExported,
		type: EXPORT_TO_NEW_SEASON,
	};
}

export function exportToNewSeasonComplete(): ExportToNewSeasonCompleteAction {
	return {
		type: EXPORT_TO_NEW_SEASON_COMPLETE,
	};
}

export function exportToNewSeasonFailed(
	error: Error
): ExportToNewSeasonFailedAction {
	return {
		error,
		type: EXPORT_TO_NEW_SEASON_FAILED,
	};
}

export function get(episodicId: number, episodicActorId: number): GetAction {
	return {
		episodicId,
		episodicActorId,
		type: GET,
	};
}

export function getComplete(episodicActor: EpisodicActor): GetCompleteAction {
	return {
		episodicActor,
		type: GET_COMPLETE,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	};
}

export function destroy(
	episodicId: number,
	episodicActorId: number
): DestroyAction {
	return {
		episodicId,
		episodicActorId,
		type: DESTROY,
	};
}

export function destroyComplete(
	episodicActor: EpisodicActor
): DestroyCompleteAction {
	return {
		episodicActor,
		type: DESTROY_COMPLETE,
	};
}

export function destroyFailed(error: Error): DestroyFailedAction {
	return {
		error,
		type: DESTROY_FAILED,
	};
}

export function list(episodicId: number): ListAction {
	return {
		episodicId,
		type: LIST,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export function listComplete(
	episodicActors: EpisodicActor[]
): ListCompleteAction {
	return {
		episodicActors,
		type: LIST_COMPLETE,
	};
}

export function update(
	episodicId: number,
	episodicActors: Partial<EpisodicActor>[],
	epActorIdToEpCharIds: { [id: number]: number[] },
	haEpisodicActors: Partial<HaEpisodicActor>[] = [],
	muEpisodicActors: Partial<MuEpisodicActor>[] = []
): UpdateAction {
	return {
		episodicId,
		episodicActors,
		epActorIdToEpCharIds,
		haEpisodicActors,
		muEpisodicActors,
		type: UPDATE,
	};
}

export function updateComplete(
	episodicActors: EpisodicActor[]
): UpdateCompleteAction {
	return {
		episodicActors,
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function updateNotes(
	episodicId: number,
	episodicActorId: number,
	episodicActorNotes: PartialDeptEpisodicActorNotes
): UpdateNotesAction {
	return {
		episodicId,
		episodicActorId,
		episodicActorNotes,
		type: UPDATE_NOTES,
	};
}

export function updateNotesComplete(): UpdateNotesCompleteAction {
	return {
		type: UPDATE_NOTES_COMPLETE,
	};
}

export function updateNotesFailed(error: Error): UpdateNotesFailedAction {
	return {
		error,
		type: UPDATE_NOTES_FAILED,
	};
}

export function setCurrent(episodicActorId: number): SetCurrentAction {
	return {
		episodicActorId,
		type: SET_CURRENT,
	};
}

export type Action =
	| CreateAction
	| CreateCompleteAction
	| CreateFailedAction
	| ExportToNewSeasonAction
	| ExportToNewSeasonCompleteAction
	| ExportToNewSeasonFailedAction
	| GetAction
	| GetCompleteAction
	| GetFailedAction
	| DestroyAction
	| DestroyCompleteAction
	| DestroyFailedAction
	| ListAction
	| ListFailedAction
	| ListCompleteAction
	| SetCurrentAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction
	| UpdateNotesAction
	| UpdateNotesCompleteAction
	| UpdateNotesFailedAction;
