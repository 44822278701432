import { SceneSortBy } from '@synconset/sorting';
import { Moment } from 'moment';
import { Scene, SortOrder } from 'sos-models';
import * as actions from './scene.actions';
import * as charSceneActions from '../char-scenes/char-scene.actions';
import * as inventoryViewActions from '../inventory/inventory-view.actions';
import * as propSceneActions from '../prop-scenes/prop-scene.actions';
import { sceneSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	complete,
	createComplete,
	createReducer,
	destroyComplete,
	failed,
	getComplete,
	listComplete,
	loading,
	updateComplete,
} from '../utils';
import { saveAs } from 'file-saver';

export interface State extends BaseReducerState<Scene> {
	createStatus: ActionStatus;
	currentId: number;
	destroyStatus: ActionStatus;
	exportStatus: ActionStatus;
	fromDateFilter: Moment;
	getStatus: ActionStatus;
	hideOmittedFilter: boolean;
	listForPropStatus: ActionStatus;
	listStatus: ActionStatus;
	sortBy: SceneSortBy;
	sortOrder: SortOrder;
	toDateFilter: Moment;
	updateStatus: ActionStatus;
}

const initial: State = {
	createStatus: ActionStatus.Inactive,
	currentId: null,
	destroyStatus: ActionStatus.Inactive,
	entities: {},
	exportStatus: ActionStatus.Inactive,
	fromDateFilter: null,
	getStatus: ActionStatus.Inactive,
	hideOmittedFilter: false,
	ids: [],
	listForPropStatus: ActionStatus.Inactive,
	listStatus: ActionStatus.Inactive,
	sortBy: 'name',
	sortOrder: 'asc',
	toDateFilter: null,
	updateStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	| actions.Action
	| inventoryViewActions.Action
	| propSceneActions.Action
	| charSceneActions.Action
>(initial, {
	[actions.CREATE]: loading<State>('createStatus'),
	[actions.CREATE_COMPLETE]: createComplete<
		Scene,
		State,
		actions.CreateCompleteAction
	>('scenes', sceneSchema),
	[actions.CREATE_FAILED]: failed<State>('createStatus'),
	[actions.DUPLICATE]: loading<State>('createStatus'),
	[actions.DUPLICATE_FAILED]: failed<State>('createStatus'),
	[actions.EXPORT_SCENES]: loading<State>('exportStatus'),
	[actions.EXPORT_SCENES_COMPLETE]: exportScenesComplete,
	[actions.EXPORT_SCENES_FAILED]: failed<State>('exportStatus'),
	[actions.LIST]: loading<State>('listStatus'),
	[actions.LIST_COMPLETE]: listComplete<
		Scene,
		State,
		actions.ListCompleteAction
	>('scenes', sceneSchema),
	[actions.LIST_FAILED]: failed<State>('listStatus'),
	[actions.DESTROY]: loading<State>('destroyStatus'),
	[actions.DESTROY_COMPLETE]: destroyComplete<
		Scene,
		State,
		actions.DestroyCompleteAction
	>('scenes'),
	[actions.DESTROY_FAILED]: failed<State>('destroyStatus'),
	[actions.UPDATE]: loading<State>('updateStatus'),
	[actions.UPDATE_COMPLETE]: updateComplete<
		Scene,
		State,
		actions.UpdateCompleteAction
	>('scenes', sceneSchema),
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
	[actions.SET_CURRENT]: setCurrent,
	[actions.GET_COMPLETE]: getComplete<Scene, State, actions.GetCompleteAction>(
		'scenes',
		sceneSchema
	),
	[actions.GET]: loading<State>('getStatus'),
	[actions.GET_FAILED]: failed<State>('getStatus'),
	[actions.SET_SORTING]: setSorting,
	[actions.SET_FILTER]: setFilter,
	[actions.GET_EPISODES_FOR_PROP]: loading<State>('listForPropStatus'),
	[actions.GET_EPISODES_FOR_PROP_FAILED]: failed<State>('listForPropStatus'),
	[actions.GET_EPISODES_FOR_PROP_COMPLETE]: complete<State>(
		'listForPropStatus'
	),
});

function exportScenesComplete(
	state: State,
	{ fileContent }: actions.ExportScenesCompleteAction
) {
	const blob = new Blob([fileContent], { type: 'text/csv' });
	saveAs(blob, 'Scenes_Export.csv');

	return {
		...state,
		exportStatus: ActionStatus.Complete,
	};
}

function setCurrent(
	state: State,
	{ sceneId }: actions.SetCurrentAction
): State {
	return {
		...state,
		currentId: sceneId,
	};
}

function setSorting(
	state: State,
	{ sorting }: actions.SetSortingAction
): State {
	// DX grid sends up sorting info as an array. For scenes we're only ever
	// going to have one element in that array. If not, it's a problem.
	const sortInfo = sorting[0];
	return {
		...state,
		sortBy: sortInfo.columnName,
		sortOrder: sortInfo.direction,
	};
}

function setFilter(
	state: State,
	{ fromDateFilter, toDateFilter, hideOmittedFilter }: actions.SetFilterAction
): State {
	return {
		...state,
		fromDateFilter,
		toDateFilter,
		hideOmittedFilter,
	};
}
