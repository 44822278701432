import { CmChangePicture } from 'sos-models';
import * as actions from './cm-change-picture.actions';
import * as episodicCharacterPictureActions from '../episodic-character-pictures/episodic-character-picture.actions';
import { cmChangePictureSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createReducer,
	destroyComplete,
	failed,
	listComplete,
	loading,
	NormalizedEntityMapping,
} from '../utils';

export interface State extends BaseReducerState<CmChangePicture> {
	destroyStatus: ActionStatus;
	entities: NormalizedEntityMapping<CmChangePicture>;
	ids: number[];
	listStatus: ActionStatus;
}

const initial: State = {
	destroyStatus: ActionStatus.Inactive,
	entities: {},
	ids: [],
	listStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	actions.Action | episodicCharacterPictureActions.Action
>(initial, {
	[actions.DESTROY_COMPLETE]: destroyComplete<
		CmChangePicture,
		State,
		actions.DestroyCompleteAction
	>('cmChangePicture'),
	[actions.DESTROY_FAILED]: failed<State>('destroyStatus'),
	[actions.DESTROY]: loading<State>('destroyStatus'),
	[actions.LIST_COMPLETE]: listComplete<
		CmChangePicture,
		State,
		actions.ListCompleteAction
	>('cmChangePictures', cmChangePictureSchema),
});
