import { HaLookPicture } from 'sos-models';

export const DESTROY = '[HA Look Pictures] Destroy';
type DESTROY = typeof DESTROY;

export const DESTROY_COMPLETE = '[HA Look Pictures] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const DESTROY_FAILED = '[HA Look Pictures] Destroy Failed';
type DESTROY_FAILED = typeof DESTROY_FAILED;

export const LIST_COMPLETE = '[HA Look Pictures] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export interface DestroyAction {
	haLookPictureId: number;
	episodicId: number;
	type: DESTROY;
}

export interface DestroyCompleteAction {
	haLookPicture: HaLookPicture[];
	type: DESTROY_COMPLETE;
}

export interface DestroyFailedAction {
	error: Error;
	type: DESTROY_FAILED;
}

export interface ListCompleteAction {
	haLookPictures: HaLookPicture[];
	type: LIST_COMPLETE;
}

export function destroy(
	episodicId: number,
	haLookPictureId: number
): DestroyAction {
	return {
		haLookPictureId,
		episodicId,
		type: DESTROY,
	};
}

export function destroyComplete(
	haLookPicture: HaLookPicture[]
): DestroyCompleteAction {
	return {
		haLookPicture,
		type: DESTROY_COMPLETE,
	};
}

export function destroyFailed(error: Error): DestroyFailedAction {
	return {
		error,
		type: DESTROY_FAILED,
	};
}

export function listComplete(
	haLookPictures: HaLookPicture[]
): ListCompleteAction {
	return {
		haLookPictures,
		type: LIST_COMPLETE,
	};
}

export type Action =
	| DestroyAction
	| DestroyCompleteAction
	| DestroyFailedAction
	| ListCompleteAction;
