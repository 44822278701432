import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();
export const forceHistory = createBrowserHistory({});

history.listen(() => {
	// needs this so that the location in the react app
	// gets updated when hitting the back button outside
	// of it
});

forceHistory.listen(() => {
	// same as above. forceHistory should only be used
	// in the context of the navbar so that the
	// iframe will refresh on navigation
});
