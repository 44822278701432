import { Reducer } from 'redux';
import {
	EpisodicCharacter,
	EpisodicCharacterMergeConflictGetResponse,
} from 'sos-models';
import * as actions from './episodic-character.actions';
import { episodicCharacterSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	complete,
	createComplete,
	createReducer,
	destroyComplete,
	failed,
	getComplete,
	listComplete,
	loading,
	updateComplete,
} from '../utils';
import { saveAs } from 'file-saver';

export interface State extends BaseReducerState<EpisodicCharacter> {
	createStatus: ActionStatus;
	currentId: number;
	destroyStatus: ActionStatus;
	exportStatus: ActionStatus;
	exportToNewSeasonStatus: ActionStatus;
	getInventoryStatus: ActionStatus;
	getMergeConflictsStatus: ActionStatus;
	getStatus: ActionStatus;
	listStatus: ActionStatus;
	mergeConflicts: EpisodicCharacterMergeConflictGetResponse;
	mergeStatus: ActionStatus;
	updateStatus: ActionStatus;
}

const initial: State = {
	createStatus: ActionStatus.Inactive,
	currentId: null,
	destroyStatus: ActionStatus.Inactive,
	entities: {},
	exportStatus: ActionStatus.Inactive,
	exportToNewSeasonStatus: ActionStatus.Inactive,
	getInventoryStatus: ActionStatus.Inactive,
	getMergeConflictsStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
	ids: [],
	listStatus: ActionStatus.Inactive,
	mergeConflicts: {
		conflicted: {},
	},
	mergeStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
};

export const reducer: Reducer<State> = createReducer<State, actions.Action>(
	initial,
	{
		[actions.CREATE]: loading<State>('createStatus'),
		[actions.CREATE_COMPLETE]: createComplete<
			EpisodicCharacter,
			State,
			actions.CreateCompleteAction
		>('episodicCharacters', episodicCharacterSchema),
		[actions.CREATE_FAILED]: failed<State>('createStatus'),
		[actions.DESTROY]: loading<State>('destroyStatus'),
		[actions.DESTROY_COMPLETE]: destroyComplete<
			EpisodicCharacter,
			State,
			actions.DestroyCompleteAction
		>('episodicCharacters'),
		[actions.DESTROY_FAILED]: failed<State>('destroyStatus'),
		[actions.EXPORT_TO_CSV]: loading<State>('exportStatus'),
		[actions.EXPORT_TO_CSV_COMPLETE]: exportToCSVComplete,
		[actions.EXPORT_TO_CSV_SECONDARY_COMPLETE]: exportToCSVSecondaryComplete,
		[actions.EXPORT_TO_CSV_FAILED]: failed<State>('exportStatus'),
		[actions.EXPORT_TO_NEW_SEASON]: loading<State>('exportToNewSeasonStatus'),
		[actions.EXPORT_TO_NEW_SEASON_COMPLETE]: complete<State>(
			'exportToNewSeasonStatus'
		),
		[actions.EXPORT_TO_NEW_SEASON_FAILED]: failed<State>(
			'exportToNewSeasonStatus'
		),
		[actions.GET]: loading<State>('getStatus'),
		[actions.GET_COMPLETE]: getComplete<
			EpisodicCharacter,
			State,
			actions.GetCompleteAction
		>('episodicCharacters', episodicCharacterSchema),
		[actions.GET_FAILED]: failed<State>('getStatus'),
		[actions.GET_MERGE_CONFLICTS]: loading<State>('getMergeConflictsStatus'),
		[actions.GET_MERGE_CONFLICTS_COMPLETE]: getMergeConflictsComplete,
		[actions.GET_MERGE_CONFLICTS_FAILED]: failed<State>(
			'getMergeConflictsStatus'
		),
		[actions.MERGE]: loading<State>('mergeStatus'),
		[actions.MERGE_COMPLETE]: complete<State>('mergeStatus'),
		[actions.MERGE_FAILED]: failed<State>('mergeStatus'),
		[actions.LIST]: loading<State>('listStatus'),
		[actions.LIST_COMPLETE]: listComplete<
			EpisodicCharacter,
			State,
			actions.ListCompleteAction
		>('episodicCharacters', episodicCharacterSchema),
		[actions.LIST_FAILED]: failed<State>('listStatus'),
		[actions.UPDATE]: loading<State>('updateStatus'),
		[actions.UPDATE_COMPLETE]: updateComplete<
			EpisodicCharacter,
			State,
			actions.UpdateCompleteAction
		>('episodicCharacters', episodicCharacterSchema),
		[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
		[actions.UPDATE_CHAR_NUMS]: loading<State>('updateStatus'),
		[actions.UPDATE_MULTIPLE]: loading<State>('updateStatus'),
		[actions.SET_CURRENT]: setCurrent,
		[actions.LIST_FOR_EP_ACTOR]: loading<State>('listStatus'),
		[actions.LIST_FOR_EP_ACTOR_COMPLETE]: listComplete<
			EpisodicCharacter,
			State,
			actions.ListCompleteAction
		>('episodicCharacters', episodicCharacterSchema),
		[actions.LIST_FOR_EP_ACTOR_FAILED]: failed<State>('listStatus'),
	}
);

function exportToCSVComplete(
	state: State,
	{ fileContent }: actions.ExportToCSVCompleteAction
) {
	const blob = new Blob([fileContent], { type: 'text/csv' });
	saveAs(blob, 'Characters_Export.csv');

	return {
		...state,
		exportStatus: ActionStatus.Complete,
	};
}

function exportToCSVSecondaryComplete(
	state: State,
	{ fileContent }: actions.ExportToCSVSecondaryCompleteAction
) {
	const blob = new Blob([fileContent], { type: 'text/csv' });
	saveAs(blob, 'Background_Characters_Export.csv');

	return {
		...state,
		exportStatus: ActionStatus.Complete,
	};
}

function getMergeConflictsComplete(
	state: State,
	action: actions.GetMergeConflictsCompleteAction
): State {
	return {
		...state,
		mergeConflicts: action.mergeConflicts,
		getMergeConflictsStatus: ActionStatus.Complete,
	};
}

function setCurrent(
	state: State,
	{ episodicCharacterId }: actions.SetCurrentAction
): State {
	return {
		...state,
		currentId: episodicCharacterId,
	};
}
