import * as deptChangeSceneActions from './dept-change-scene.actions';
import { ActionStatus, createReducer, failed, loading } from '../utils';

export interface State {
	deleteStatus: ActionStatus;
	getStatus: ActionStatus;
	updateStatus: ActionStatus;
}

const initial: State = {
	deleteStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, deptChangeSceneActions.Action>(
	initial,
	{
		[deptChangeSceneActions.GET]: loading<State>('getStatus'),
		[deptChangeSceneActions.GET_FAILED]: failed<State>('getStatus'),
		[deptChangeSceneActions.UPDATE]: loading<State>('updateStatus'),
		[deptChangeSceneActions.UPDATE_FAILED]: failed<State>('updateStatus'),
		[deptChangeSceneActions.DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID]: loading<
			State
		>('deleteStatus'),
		[deptChangeSceneActions.DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID_COMPLETE]: deleteChangeSceneComplete,
		[deptChangeSceneActions.DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID_FAILED]: failed<
			State
		>('deleteStatus'),
	}
);

function deleteChangeSceneComplete(
	state: State,
	{
		changeSceneId,
	}: deptChangeSceneActions.DeleteChangeSceneByIdAndDeptIdCompleteAction
): State {
	return {
		...state,
		deleteStatus: ActionStatus.Complete,
	};
}
