export const ACTIVE = '[ActivityMonitor] Active';
type ACTIVE = typeof ACTIVE;

export const EXPIRED = '[ActivityMonitor] Expired';
type EXPIRED = typeof EXPIRED;

export const HEARTBEAT = '[ActivityMonitor] Heartbeat';
type HEARTBEAT = typeof HEARTBEAT;

export const INACTIVE = '[ActivityMonitor] Inactive';
type INACTIVE = typeof INACTIVE;

export interface ActiveAction {
	type: ACTIVE;
}

export interface ExpiredAction {
	type: EXPIRED;
}

export interface HeartbeatAction {
	type: HEARTBEAT;
}

export interface InactiveAction {
	type: INACTIVE;
}

export function active(): ActiveAction {
	return {
		type: ACTIVE,
	};
}

export function expired(): ExpiredAction {
	return {
		type: EXPIRED,
	};
}

export function heartbeat(): HeartbeatAction {
	return {
		type: HEARTBEAT,
	};
}

export function inactive(): InactiveAction {
	return {
		type: INACTIVE,
	};
}

export type Action =
	| ActiveAction
	| ExpiredAction
	| HeartbeatAction
	| InactiveAction;
