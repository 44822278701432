import { omit, without } from 'lodash';
import { normalize, schema } from 'normalizr';
import { DEPARTMENTS, DeptWrapBoxes, WrapBox } from 'sos-models';
import * as actions from './wrap-box.actions';
import {
	ActionStatus,
	BaseReducerState,
	createReducer,
	failed,
	loading,
	NormalizationOutput,
	NormalizedEntityMapping,
	uniqueArrayMerge,
} from '../utils';

export interface BaseWrapBoxReducerState<T extends WrapBox>
	extends BaseReducerState<T> {
	entities: NormalizedEntityMapping<T>;
	ids: number[];
	currentId: number;
	createStatus: ActionStatus;
	getStatus: ActionStatus;
	listStatus: ActionStatus;
	updateStatus: ActionStatus;
	destroyStatus: ActionStatus;
}

export function createWrapBoxReducer<T extends WrapBox>(
	deptWrapBoxTransformer: (dwb: DeptWrapBoxes) => T[],
	normalizedEntitiesTransformer: (
		normalized: NormalizationOutput<T>
	) => NormalizedEntityMapping<T>,
	entitySchema: schema.Entity,
	deptId: DEPARTMENTS
) {
	type State = BaseWrapBoxReducerState<T>;

	const initial: State = {
		entities: {},
		ids: [],
		currentId: null,
		createStatus: ActionStatus.Inactive,
		getStatus: ActionStatus.Inactive,
		listStatus: ActionStatus.Inactive,
		updateStatus: ActionStatus.Inactive,
		destroyStatus: ActionStatus.Inactive,
	};

	return createReducer<any, actions.Action>(initial, {
		[actions.CREATE]: loading<State>('createStatus'),
		[actions.CREATE_COMPLETE]: createComplete,
		[actions.CREATE_FAILED]: failed<State>('createStatus'),
		[actions.GET]: loading<State>('getStatus'),
		[actions.GET_COMPLETE]: getComplete,
		[actions.GET_FAILED]: failed<State>('getStatus'),
		[actions.LIST]: loading<State>('listStatus'),
		[actions.LIST_COMPLETE]: listComplete,
		[actions.LIST_FAILED]: failed<State>('listStatus'),
		[actions.UPDATE]: loading<State>('updateStatus'),
		[actions.UPDATE_COMPLETE]: updateComplete,
		[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
		[actions.DESTROY]: loading<State>('destroyStatus'),
		[actions.DESTROY_COMPLETE]: destroyComplete,
		[actions.DESTROY_FAILED]: failed<State>('destroyStatus'),
	});

	function createComplete(
		state: State,
		{ wrapBoxes, departmentId }: actions.CreateCompleteAction
	): State {
		if (departmentId === deptId) {
			const createdWrapBoxes: T[] = deptWrapBoxTransformer(wrapBoxes);
			const normalized: NormalizationOutput<T> = normalize(createdWrapBoxes, [
				entitySchema,
			]);

			return {
				...state,
				entities: {
					...state.entities,
					...normalizedEntitiesTransformer(normalized),
				},
				ids: uniqueArrayMerge(state.ids, normalized.result as number[]),
				createStatus: ActionStatus.Complete,
			};
		}
		return state;
	}

	function getComplete(
		state: State,
		{ wrapBox, departmentId }: actions.GetCompleteAction
	): State {
		if (departmentId === deptId) {
			const wrapBoxes: T[] = deptWrapBoxTransformer(wrapBox);

			if (wrapBoxes) {
				const normalized: NormalizationOutput<T> = normalize(wrapBoxes, [
					entitySchema,
				]);

				return {
					...state,
					entities: {
						...state.entities,
						...normalizedEntitiesTransformer(normalized),
					},
					currentId: normalized.result[0] as number,
					ids: uniqueArrayMerge(state.ids, normalized.result as number[]),
					getStatus: ActionStatus.Complete,
				};
			}
		}

		return {
			...state,
			currentId: null,
		};
	}

	function listComplete(
		state: State,
		action: actions.ListCompleteAction
	): State {
		const wrapBoxes: T[] = deptWrapBoxTransformer(action.deptWrapBoxes);
		if (wrapBoxes) {
			const normalized: NormalizationOutput<T> = normalize(wrapBoxes, [
				entitySchema,
			]);
			return {
				...state,
				entities: {
					...state.entities,
					...normalizedEntitiesTransformer(normalized),
				},
				ids: uniqueArrayMerge(state.ids, normalized.result as number[]),
				listStatus: ActionStatus.Complete,
			};
		}
		return { ...state, listStatus: ActionStatus.Complete };
	}

	function updateComplete(
		state: State,
		action: actions.UpdateCompleteAction
	): State {
		const wrapBoxes: T[] = deptWrapBoxTransformer(action.deptWrapBoxes);
		if (wrapBoxes) {
			const normalized: NormalizationOutput<T> = normalize(wrapBoxes, [
				entitySchema,
			]);
			return {
				...state,
				entities: {
					...state.entities,
					...normalizedEntitiesTransformer(normalized),
				},
				ids: uniqueArrayMerge(state.ids, normalized.result as number[]),
				updateStatus: ActionStatus.Complete,
			};
		}
		return { ...state, updateStatus: ActionStatus.Complete };
	}

	function destroyComplete(
		state: State,
		{ deleted }: actions.DestroyCompleteAction
	): State {
		let wrapBox: WrapBox;

		if (deleted.CmWrapBox) {
			[wrapBox] = deleted.CmWrapBox;
		} else if (deleted.PrWrapBox) {
			[wrapBox] = deleted.PrWrapBox;
		} else if (deleted.SetWrapBox) {
			[wrapBox] = deleted.SetWrapBox;
		}

		if (wrapBox.department === deptId) {
			const entities: NormalizedEntityMapping<T> = omit(state.entities, [
				wrapBox.id,
			]);
			const ids = without(state.ids, wrapBox.id);
			return {
				...state,
				entities,
				ids,
				destroyStatus: ActionStatus.Complete,
			};
		}
		return state;
	}
}
