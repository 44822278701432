import { normalize } from 'normalizr';
import { Episodic } from 'sos-models';
import * as actions from './episodic.actions';
import { episodicSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createReducer,
	failed,
	listComplete,
	loading,
	uniqueArrayMerge,
} from '../utils';
import { setEpisodic } from '../../logger';

export interface State extends BaseReducerState<Episodic> {
	currentId: number;
	currentLogoPictureId: number;
	destroyStatus: ActionStatus;
	getStatus: ActionStatus;
	getPictureCountsStatus: ActionStatus;
	listStatus: ActionStatus;
	pictureCountsByDept: { [dept: string]: number }; // Move this into department-level reducers in card AH-863
	updateStatus: ActionStatus;
}

const initial: State = {
	currentId: null,
	currentLogoPictureId: null,
	destroyStatus: ActionStatus.Inactive,
	entities: {},
	getStatus: ActionStatus.Inactive,
	getPictureCountsStatus: ActionStatus.Inactive,
	ids: [],
	listStatus: ActionStatus.Inactive,
	pictureCountsByDept: {},
	updateStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.DESTROY_COMPLETE]: destroyComplete,
	[actions.GET_COMPLETE]: getComplete,
	[actions.GET_FAILED]: failed<State>('getStatus'),
	[actions.GET]: loading<State>('getStatus'),
	[actions.GET_PICTURE_COUNTS_COMPLETE]: getPictureCountsComplete,
	[actions.GET_PICTURE_COUNTS_FAILED]: failed<State>('getPictureCountsStatus'),
	[actions.GET_PICTURE_COUNTS]: loading<State>('getPictureCountsStatus'),
	[actions.LIST_COMPLETE]: listComplete<
		Episodic,
		State,
		actions.ListCompleteAction
	>('episodics', episodicSchema),
	[actions.LIST_FAILED]: failed<State>('listStatus'),
	[actions.LIST]: loading<State>('listStatus'),
	[actions.UPDATE_COMPLETE]: updateComplete,
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
	[actions.UPDATE]: loading<State>('updateStatus'),
	[actions.UPDATE_TITLES_COMPLETE]: updateComplete,
	[actions.UPDATE_TITLES_FAILED]: failed<State>('updateStatus'),
	[actions.UPDATE_TITLES]: loading<State>('updateStatus'),
});

function updateComplete(
	state: State,
	action: actions.UpdateCompleteAction
): State {
	const normalized = normalize(action.episodics, [episodicSchema]);
	return {
		...state,
		currentId: normalized.result[0],
		entities: {
			...state.entities,
			...normalized.entities.episodics,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		updateStatus: ActionStatus.Complete,
	};
}

function destroyComplete(
	state: State,
	action: actions.DestroyCompleteAction
): State {
	return {
		...state,
		currentId: null,
		currentLogoPictureId: null,
		entities: {},
		ids: [],
		destroyStatus: ActionStatus.Complete,
	};
}

function getComplete(state: State, action: actions.GetCompleteAction): State {
	const normalized = normalize(action.episodic, episodicSchema);
	setEpisodic(action.episodic.id);
	return {
		...state,
		currentId: normalized.result,
		currentLogoPictureId:
			action.episodic.logo && action.episodic.logo.length
				? action.episodic.logo[0].id
				: null,
		entities: {
			...state.entities,
			...normalized.entities.episodics,
		},
		getStatus: ActionStatus.Complete,
		ids: uniqueArrayMerge(state.ids, [normalized.result]),
	};
}

function getPictureCountsComplete(
	state: State,
	action: actions.GetPictureCountsCompleteAction
): State {
	const pictureCountsByDept = action.pictureCountsByDept;
	return {
		...state,
		getPictureCountsStatus: ActionStatus.Complete,
		pictureCountsByDept,
	};
}
