import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	DEPARTMENTS,
	DeptSpecificWrapBoxGetResponse,
	DeptWrapBoxes,
	WrapBoxCreateResponse,
	WrapBoxGetResponse,
	WrapBoxListResponse,
} from 'sos-models';
import * as actions from './wrap-box.actions';
import { DestroyedWrapBoxes } from './wrap-box.actions';
import * as api from './wrap-box.api';
import * as logger from '../../logger';
import {
	changeActions,
	cmStorageLocationActions,
	pictureActions,
	propStorageLocationActions,
	setStorageLocationActions,
} from '../actions';

export function* create({
	episodicId,
	departmentId,
	wrapBox,
}: actions.CreateAction) {
	try {
		const createdWrapBox: WrapBoxCreateResponse = yield call(
			api.create,
			episodicId,
			departmentId,
			wrapBox
		);
		yield put(actions.createComplete(departmentId, createdWrapBox.created));
	} catch (err) {
		yield put(actions.createFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* get({
	episodicId,
	departmentId,
	wrapBoxId,
}: actions.GetAction) {
	try {
		const response: WrapBoxGetResponse = yield call(
			api.get,
			episodicId,
			departmentId,
			wrapBoxId
		);

		if (departmentId === DEPARTMENTS.CM) {
			const {
				listed,
			}: DeptSpecificWrapBoxGetResponse<DEPARTMENTS.CM> = response as DeptSpecificWrapBoxGetResponse<
				DEPARTMENTS.CM
			>;

			yield put(actions.getComplete(response.listed, departmentId));

			if (listed.Change) {
				yield put(changeActions.listComplete(listed.Change));
			}

			if (listed.CmStorageLocation) {
				yield put(
					cmStorageLocationActions.getComplete(listed.CmStorageLocation[0])
				);
			}
		}

		if (departmentId === DEPARTMENTS.PR) {
			const {
				listed,
			}: DeptSpecificWrapBoxGetResponse<DEPARTMENTS.PR> = response;

			yield put(actions.getComplete(response.listed, departmentId));

			if (listed.PrStorageLocation) {
				yield put(
					propStorageLocationActions.getComplete(listed.PrStorageLocation[0])
				);
			}
		}

		if (departmentId === DEPARTMENTS.SET) {
			const {
				listed,
			}: DeptSpecificWrapBoxGetResponse<DEPARTMENTS.SET> = response;

			yield put(actions.getComplete(response.listed, departmentId));

			if (listed.SetStorageLocation) {
				yield put(
					setStorageLocationActions.getComplete(listed.SetStorageLocation[0])
				);
			}
		}

		if (response.listed.Picture && response.listed.Picture[0]) {
			yield put(pictureActions.getComplete(response.listed.Picture[0]));
		}
	} catch (err) {
		yield put(actions.getFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* list({ episodicId }: actions.ListAction) {
	try {
		const response: WrapBoxListResponse = yield call(api.list, episodicId);
		yield put(actions.listComplete(response.listed));
	} catch (err) {
		yield put(actions.listFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* update({ episodicId, deptWrapBoxes }: actions.UpdateAction) {
	try {
		const updatedWrapBoxes: DeptWrapBoxes = yield call(
			api.update,
			episodicId,
			deptWrapBoxes
		);
		yield put(actions.updateComplete(updatedWrapBoxes));
	} catch (err) {
		yield put(actions.updateFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* exportWrapBoxes({
	episodicId,
	options,
}: actions.ExportAction) {
	try {
		const results = yield call(api.exportWrapBoxes, episodicId, options);
		yield put(actions.exportComplete(results));
	} catch (err) {
		yield put(actions.exportFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

export function* destroy({
	episodicId,
	departmentId,
	wrapBoxId,
}: actions.DestroyAction) {
	try {
		const { deleted }: { deleted: DestroyedWrapBoxes } = yield call(
			api.destroy,
			episodicId,
			departmentId,
			wrapBoxId
		);
		yield put(actions.destroyComplete(deleted));
	} catch (err) {
		yield put(actions.destroyFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeEvery(actions.CREATE, create);
	yield takeLatest(actions.GET, get);
	yield takeLatest(actions.LIST, list);
	yield takeEvery(actions.UPDATE, update);
	yield takeLatest(actions.EXPORT_WRAP_BOXES, exportWrapBoxes);
	yield takeEvery(actions.DESTROY, destroy);
}

export default saga;
