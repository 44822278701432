import { TableColumn } from 'sos-models';
import * as actions from './change-scene-table-column.actions';
import { SCENE_TABLE_COLUMNS } from './change-scene-table-columns.data';
import { createColumnReducer } from '../column.reducer';

export interface State {
	ids: string[];
	hidden: string[];
	widths: { [name: string]: TableColumn };
}

const initial: State = {
	ids: [],
	hidden: [],
	widths: SCENE_TABLE_COLUMNS,
};

export const reducer = createColumnReducer<State, actions.Action>(
	initial,
	actions
);
