import { FIELD_TYPES, TableColumn } from 'sos-models';

export const SCENE_TABLE_COLUMNS: { [name: string]: TableColumn } = {
	main_photo_id: {
		name: ' ',
		canonical_name: 'main_photo_id',
		width: 55,
		type: null,
	},
	name: {
		name: 'Scene #',
		canonical_name: 'name',
		width: 150,
		type: FIELD_TYPES.INPUT,
		maxLength: 255,
	},
	script_date: {
		name: 'Script Day',
		canonical_name: 'script_date',
		width: 100,
		type: FIELD_TYPES.COMBO,
	},
	scene_location_id: {
		name: 'Script Loc.',
		canonical_name: 'scene_location_id',
		width: 150,
		type: FIELD_TYPES.SELECT,
	},
	description: {
		name: 'Scene Description',
		canonical_name: 'description',
		width: 200,
		type: FIELD_TYPES.INPUT,
		maxLength: 65535,
	},
	primary_ids: {
		name: 'Primary',
		canonical_name: 'primary_ids',
		width: 150,
		type: FIELD_TYPES.MULTISELECT,
	},
	background_ids: {
		name: 'Background',
		canonical_name: 'background_ids',
		width: 150,
		type: FIELD_TYPES.MULTISELECT,
	},
	set_ids: {
		name: 'Sets',
		canonical_name: 'set_ids',
		width: 150,
		type: FIELD_TYPES.MULTISELECT,
	},
	shoot_day: {
		name: 'Shoot Date',
		canonical_name: 'shoot_day',
		width: 150,
		type: FIELD_TYPES.DATE,
	},
	page_length: {
		name: 'Page Count',
		canonical_name: 'page_length',
		width: 100,
		type: FIELD_TYPES.INPUT,
	},
	omit: {
		name: 'Omit',
		canonical_name: 'omit',
		width: 100,
		type: FIELD_TYPES.CHECKBOX,
	},
	day: {
		name: 'D/N',
		canonical_name: 'script_date',
		width: 50,
		type: FIELD_TYPES.COMBO,
	},
	scene_information: {
		name: 'Scene Information',
		canonical_name: 'scene_information',
		width: 200,
		type: FIELD_TYPES.INPUT,
	},
	change_id: {
		name: 'Change',
		canonical_name: 'change_id',
		width: 100,
		type: FIELD_TYPES.INPUT,
	},
	wear_notes: {
		name: 'Wear Notes',
		canonical_name: 'wear_notes',
		width: 150,
		type: FIELD_TYPES.TEXTAREA,
		maxLength: 65000,
	},
	prop_name: {
		name: 'Props',
		canonical_name: 'prop_name',
		width: 150,
		type: FIELD_TYPES.SELECT,
	},
	script_day_date: {
		name: 'Script Day',
		canonical_name: 'script_day_date',
		width: 150,
		type: FIELD_TYPES.COMBO,
	},
};

export const makeTableColumns = (columnsObj: {
	[name: string]: TableColumn;
}): { title: string; name: string }[] => {
	return Object.keys(columnsObj).map((columnName) => ({
		title: columnsObj[columnName].name,
		name: columnsObj[columnName].canonical_name,
	}));
};

export const singleDeptCmHaMuColumns = {
	name: SCENE_TABLE_COLUMNS.name,
	script_date: SCENE_TABLE_COLUMNS.script_date,
	scene_information: SCENE_TABLE_COLUMNS.scene_information,
	shoot_day: SCENE_TABLE_COLUMNS.shoot_day,
	main_photo_id: SCENE_TABLE_COLUMNS.main_photo_id,
	change_id: SCENE_TABLE_COLUMNS.change_id,
	wear_notes: SCENE_TABLE_COLUMNS.wear_notes,
};

export const singleDeptPropColumns = {
	name: SCENE_TABLE_COLUMNS.name,
	script_date: SCENE_TABLE_COLUMNS.script_date,
	scene_information: SCENE_TABLE_COLUMNS.scene_information,
	shoot_day: SCENE_TABLE_COLUMNS.shoot_day,
	prop_name: SCENE_TABLE_COLUMNS.prop_name,
};

export const singleDeptDefaultColumns = {
	main_photo_id: SCENE_TABLE_COLUMNS.main_photo_id,
	name: SCENE_TABLE_COLUMNS.name,
	script_date: SCENE_TABLE_COLUMNS.script_date,
	scene_information: SCENE_TABLE_COLUMNS.scene_information,
	shoot_day: SCENE_TABLE_COLUMNS.shoot_day,
};

export const changeViewSceneColumns = {
	name: SCENE_TABLE_COLUMNS.name,
	script_date: SCENE_TABLE_COLUMNS.script_date,
	scene_location_id: SCENE_TABLE_COLUMNS.scene_location_id,
	scene_information: SCENE_TABLE_COLUMNS.scene_information,
	shoot_day: SCENE_TABLE_COLUMNS.shoot_day,
	wear_notes: SCENE_TABLE_COLUMNS.wear_notes,
};

export const charViewSceneColumns = {
	name: SCENE_TABLE_COLUMNS.name,
	script_date: SCENE_TABLE_COLUMNS.script_date,
	scene_information: SCENE_TABLE_COLUMNS.scene_information,
	shoot_day: SCENE_TABLE_COLUMNS.shoot_day,
};
