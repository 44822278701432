import { schema } from 'normalizr';

// Permissions
const userSchema = new schema.Entity('users');
const permissionSchema = new schema.Entity('permissions');
const cmPermissionSchema = new schema.Entity('cmPermissions');
const haPermissionSchema = new schema.Entity('haPermissions');
const muPermissionSchema = new schema.Entity('muPermissions');
const setPermissionSchema = new schema.Entity('setPermissions');
const prPermissionSchema = new schema.Entity('prPermissions');
const prodPermissionSchema = new schema.Entity('prodPermissions');
export const userSettingSchema = new schema.Entity('userSettings');

permissionSchema.define({
	cmPermission: cmPermissionSchema,
	haPermission: haPermissionSchema,
	muPermission: muPermissionSchema,
	setPermission: setPermissionSchema,
	prPermission: prPermissionSchema,
	prodPermissions: [prodPermissionSchema],
});

userSchema.define({
	permissions: [permissionSchema],
});

const accountSchema = new schema.Entity('accounts');
const episodicSchema = new schema.Entity('episodics');
const departmentSettingSchema = new schema.Entity('departmentSettings');
const productionSchema = new schema.Entity('productions');

episodicSchema.define({
	departmentSettings: [departmentSettingSchema],
	productions: [productionSchema],
});

// Scenes
const sceneSchema = new schema.Entity('scenes');
export const scriptLocationSchema = new schema.Entity('scriptLocations');

// Application Features
export const appFeatureSchema = new schema.Entity('app-feature');

// Inventory
const episodicItemSchema = new schema.Entity('episodicItems');
const changeEpisodicItemSchema = new schema.Entity('changeEpisodicItems');
const episodicPropSchema = new schema.Entity('episodicProps');
const setPieceSchema = new schema.Entity('setPieces');
export const actorSchema = new schema.Entity('actors');
export const episodicActorSchema = new schema.Entity('episodicActors');
const episodicCharacterSchema = new schema.Entity('episodicCharacters');
export const episodicCharacterPictureSchema = new schema.Entity(
	'episodicCharacterPictures'
);
export const charSceneSchema = new schema.Entity('charScenes');
export const changeSchema = new schema.Entity('changes');
export const changeLookPictureSchema = new schema.Entity('changeLookPictures');
export const changeLookSchema = new schema.Entity('changeLooks');
export const cmEpisodicActorSchema = new schema.Entity('cmEpisodicActors');
export const deptChangeSceneSchema = new schema.Entity('deptChangeScenes');
export const episodicFlagSchema = new schema.Entity('episodicFlags');
export const haChangeSchema = new schema.Entity('haChanges');
export const haEpisodicActorSchema = new schema.Entity('haEpisodicActors');
export const muChangeSchema = new schema.Entity('muChanges');
export const muEpisodicActorSchema = new schema.Entity('muEpisodicActors');
export const changeSceneSchema = new schema.Entity('changeScenes');
export const haChangeSceneSchema = new schema.Entity('haChangeScenes');
export const haChangeFieldNameSchema = new schema.Entity('haChangeFieldName');
export const muChangeFieldNameSchema = new schema.Entity('muChangeFieldName');
export const muChangeSceneSchema = new schema.Entity('muChangeScenes');
const characterSchema = new schema.Entity('characters');
export const cmStatusSchema = new schema.Entity('cmStatuses');
export const prEpisodicActorSchema = new schema.Entity('prEpisodicActors');
export const propStatusSchema = new schema.Entity('propStatuses');
export const propStatusColorSchema = new schema.Entity('propStatusColors');
export const setPieceStatusSchema = new schema.Entity('setPieceStatuses');
export const cmStorageLocationSchema = new schema.Entity('cmStorageLocations');
export const propStorageLocationSchema = new schema.Entity(
	'propStorageLocations'
);
export const setStorageLocationSchema = new schema.Entity(
	'setStorageLocations'
);
export const cmBrandSchema = new schema.Entity('cmBrands');
export const cmCategorySchema = new schema.Entity('cmCategories');
export const pictureSceneSchema = new schema.Entity('pictureScenes');
export const propCategorySchema = new schema.Entity('propCategories');
export const propSceneSchema = new schema.Entity('propScenes');
export const propSceneCharSceneSchema = new schema.Entity(
	'propSceneCharScenes'
);
export const setPieceTypeSchema = new schema.Entity('setPieceTypes');
const setPieceSetSchema = new schema.Entity('setPieceSets');
export const setPiecePictureSchema = new schema.Entity('setPiecePictures');
export const setSchema = new schema.Entity('sets');
export const setPictureSchema = new schema.Entity('setPictures');
export const setSceneSchema = new schema.Entity('setScenes');
export const setProdSchema = new schema.Entity('setProds');
export const vendorSchema = new schema.Entity('vendors');
export const cmWrapBoxSchema = new schema.Entity('cmWrapBoxes');
export const prWrapBoxSchema = new schema.Entity('prWrapBoxes');
export const setWrapBoxSchema = new schema.Entity('setWrapBoxes');
export const cmWrapBoxEpisodicItemSchema = new schema.Entity(
	'cmWrapBoxEpisodicItems'
);
export const prWrapBoxEpisodicPropSchema = new schema.Entity(
	'prWrapBoxEpisodicProps'
);
export const setWrapBoxSetPieceSchema = new schema.Entity(
	'setWrapBoxSetPieces'
);
export const cmEpisodicItemPictureSchema = new schema.Entity(
	'cmEpisodicItemPictures'
);
export const prEpisodicPropPictureSchema = new schema.Entity(
	'prEpisodicPropPictures'
);
export const cmSceneNoteSchema = new schema.Entity('cmSceneNotes');
export const haSceneNoteSchema = new schema.Entity('haSceneNotes');
export const muSceneNoteSchema = new schema.Entity('muSceneNotes');
export const prSceneNoteSchema = new schema.Entity('prSceneNotes');
export const wrapBoxSchema = new schema.Entity('wrapBoxes');
export const pictureSchema = new schema.Entity('pictures');
export const cmEpisodicCharacterNoteSchema = new schema.Entity(
	'cmEpisodicCharacterNoteSchema'
);
export const haEpisodicCharacterNoteSchema = new schema.Entity(
	'haEpisodicCharacterNoteSchema'
);
export const muEpisodicCharacterNoteSchema = new schema.Entity(
	'muEpisodicCharacterNoteSchema'
);
export const prEpisodicCharacterNoteSchema = new schema.Entity(
	'prEpisodicCharacterNoteSchema'
);

export const cmChangePictureSchema = new schema.Entity('cmChangePictures');
export const haLookPictureSchema = new schema.Entity('haLookPictures');
export const muLookPictureSchema = new schema.Entity('muLookPictures');

sceneSchema.define({
	cmSceneNote: cmSceneNoteSchema,
	haSceneNote: haSceneNoteSchema,
	muSceneNote: muSceneNoteSchema,
	prSceneNote: prSceneNoteSchema,
});

characterSchema.define({
	episodicCharacter: episodicCharacterSchema,
	production: productionSchema,
});

changeSchema.define({
	character: characterSchema,
	production: productionSchema,
});

episodicActorSchema.define({
	actors: [actorSchema],
});

episodicCharacterSchema.define({
	characters: [characterSchema],
	episodicActor: episodicActorSchema,
});

setPieceSetSchema.define({
	set: setSchema,
});

setPieceSchema.define({
	setPiecePictures: [setPiecePictureSchema],
	setPieceSets: [setPieceSetSchema],
	status: setPieceStatusSchema,
	storageLocation: setStorageLocationSchema,
	category: setPieceTypeSchema,
});

episodicItemSchema.define({
	episodicCharacter: episodicCharacterSchema,
	status: cmStatusSchema,
	storageLocation: cmStorageLocationSchema,
	category: cmCategorySchema,
	cmEpisodicItemPictures: [cmEpisodicItemPictureSchema],
});

episodicPropSchema.define({
	episodicCharacter: episodicCharacterSchema,
	status: propStatusSchema,
	storageLocation: propStorageLocationSchema,
	category: propCategorySchema,
	prEpisodicPropPictures: [prEpisodicPropPictureSchema],
});

const inventoryFieldSettingSchema = new schema.Entity('inventoryFieldSettings');

export {
	accountSchema,
	characterSchema,
	changeEpisodicItemSchema,
	cmPermissionSchema,
	departmentSettingSchema,
	episodicSchema,
	episodicCharacterSchema,
	haPermissionSchema,
	muPermissionSchema,
	permissionSchema,
	prodPermissionSchema,
	productionSchema,
	prPermissionSchema,
	setPermissionSchema,
	userSchema,
	inventoryFieldSettingSchema,
	episodicItemSchema,
	episodicPropSchema,
	setPieceSchema,
	setPieceSetSchema,
	sceneSchema,
};
