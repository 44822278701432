import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { BgCharSearchResponse, UniversalSearchResponse } from 'sos-models';
import * as actions from './universal-search.actions';
import * as api from './universal-search.api';
import * as logger from '../../logger';
import { characterActions } from '../actions';

export function* search({
	episodicId,
	productionIds,
	departmentIds,
	query,
}: actions.SearchAction) {
	try {
		if (productionIds.length) {
			const { hits }: UniversalSearchResponse = yield call(
				api.search,
				episodicId,
				productionIds,
				departmentIds,
				query
			);
			yield put(actions.searchComplete(hits));
		} else {
			yield toast.warn(
				'You must have at least one episode selected to search.'
			);
			yield;
		}
	} catch (err) {
		yield put(actions.searchFailed(err));
		logger.responseError(err, 'performing the search', true);
	}
}

export function* searchBackgroundCharacters({
	episodicId,
	productionIds,
	departmentIds,
	sceneId,
	query,
}: actions.SearchBackgroundCharactersAction) {
	try {
		const { listed }: BgCharSearchResponse = yield call(
			api.searchBackgroundCharacters,
			episodicId,
			productionIds,
			departmentIds,
			sceneId,
			query
		);
		yield put(
			actions.searchBackgroundCharactersComplete(listed.EpisodicCharacter)
		);
		yield put(characterActions.listComplete(listed.Character));
	} catch (err) {
		yield put(actions.searchBackgroundCharactersFailed(err));
		logger.responseError(err, 'performing the search bg chars', true);
	}
}

function* saga() {
	yield takeLatest(actions.SEARCH, search);
	yield takeLatest(actions.SEARCH_BG_CHARS, searchBackgroundCharacters);
}

export default saga;
