import { normalize } from 'normalizr';
import { AppFeature } from 'sos-models';
import * as actions from './app-feature.actions';
import { appFeatureSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createReducer,
	failed,
	listComplete,
	loading,
	uniqueArrayMerge,
} from '../utils';

export interface State extends BaseReducerState<AppFeature> {
	appFeatureIds: [];
	listStatus: ActionStatus;
	updateStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	appFeatureIds: [],
	listStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.LIST_COMPLETE]: listComplete<
		AppFeature,
		State,
		actions.ListCompleteAction
	>('appFeatures', appFeatureSchema),
	[actions.LIST_FAILED]: failed<State>('listStatus'),
	[actions.LIST]: loading<State>('listStatus'),
	[actions.UPDATE_COMPLETE]: updateComplete,
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
	[actions.UPDATE]: loading<State>('updateStatus'),
});

function updateComplete(
	state: State,
	action: actions.UpdateCompleteAction
): State {
	const normalized = normalize(action.appFeatures, [appFeatureSchema]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities['app-feature'],
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		updateStatus: ActionStatus.Complete,
	};
}
