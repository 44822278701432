import * as Sentry from '@sentry/browser';
import * as singleSpa from 'single-spa';
import version from './config/version';
import * as logger from './logger';
import { setupIntercom } from './setupIntercom.js';
import store from './store';
import {
	accountActions,
	characterActions,
	episodicActions,
	episodicCharacterActions,
	permissionActions,
	userActions,
	userSettingActions,
} from './store/actions';
import { list as listChars } from './store/episodic-characters/episodic-character.api';
import { get as getEpisodic } from './store/episodics/episodic.api';
import { get as getPerms } from './store/permissions/permission.api';
import { ActionStatus } from './store/utils';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(fas);

function searchURLIncludes(routes: string[]) {
	return routes.some((r) => window.location.search.includes(r));
}

function searchURLPathname(routes: string[]) {
	return routes.some((r) => window.location.pathname.startsWith(r));
}

if (logger.useSentry) {
	Sentry.init({
		dsn: 'https://d4684564434443aebf5f30da4533d428@sentry.io/294655',
		environment: process.env.SOS_ENV,
		release: version,
	});

	// TODO: set context somewhere where it makes sense
	// Raven.setUserContext({
	// 	id: document.getElementById('intercom_user_id').value
	// });

	// Raven.setTagsContext({
	// 	department: document.getElementById('intercom_department').value,
	// 	prod_id: document.getElementById('intercom_prod_id').value
	// });
}
const appPathPrefixes = [
	'/navbar',
	'/angular',
	'/core',
	'/moneytracking',
	'/sales',
	'/app',
];
const skipUrls = [
	'?r=site/login',
	'?r=users/create',
	'?r=users/forgot',
	'?r=users/get',
	'?r=users/reset',
	'?r=users/verify',
	'?r=users/smartID',
	'?r=users/activate',
];

singleSpa.registerApplication(
	'navbar',
	() => import(/* webpackChunkName: "navbar" */ './navbar'),
	(location: Location) => {
		return (
			!location.pathname.startsWith('/studioadmin') &&
			!location.pathname.startsWith('/home') &&
			!location.pathname.startsWith('/app/users') &&
			!location.pathname.startsWith('/sales') &&
			location.pathname !== '' &&
			location.pathname !== '/' &&
			searchURLPathname(appPathPrefixes) &&
			!searchURLIncludes(skipUrls)
		);
	}
);

singleSpa.registerApplication(
	'core',
	() => import(/* webpackChunkName: "core" */ './core'),
	pathPrefix('/core')
);
singleSpa.registerApplication(
	'angular',
	() => import(/*webpackChunkName: "ng" */ 'sos-angular/synconset/spa'),
	pathPrefix('/angular')
);
singleSpa.registerApplication(
	'moneytracking',
	() => import(/*webpackChunkName: "mt" */ 'sos-angular/moneytracking/spa'),
	pathPrefix('/moneytracking')
);
singleSpa.registerApplication(
	'sales',
	() => import(/*webpackChunkName: "sales" */ 'sos-angular/sales/spa'),
	pathPrefix('/sales')
);
singleSpa.registerApplication(
	'studioadmin',
	() => import(/*webpackChunkName: "sa" */ 'sos-angular/studioadmin/spa'),
	pathPrefix('/studioadmin')
);
singleSpa.registerApplication(
	'app',
	() => import(/*webpackChunkName: "app"*/ './app'),
	pathPrefix('/app')
);

singleSpa.start();

function pathPrefix(prefix: string) {
	return function (location: Location) {
		return location.pathname.startsWith(`${prefix}`);
	};
}

window.addEventListener('single-spa:routing-event', async () => {
	if (location.pathname === '' || location.pathname === '/') {
		window.location.replace('/home');
	} else if (
		location.pathname === '/index.php' &&
		searchURLIncludes(skipUrls)
	) {
		window.location.replace(`/core${location.search}`);
	} else if (
		!location.pathname.startsWith('/app') &&
		!location.pathname.startsWith('/home') &&
		!searchURLIncludes(skipUrls)
	) {
		const { listed } = await getEpisodic();
		if (listed.Episodic) {
			store.dispatch(episodicActions.getComplete(listed.Episodic[0]));
			// need characters for the photo upload modal
			const charsRes = await listChars(listed.Episodic[0].id);
			if (charsRes.listed) {
				store.dispatch(
					episodicCharacterActions.listComplete(
						charsRes.listed.EpisodicCharacter
					)
				);
				store.dispatch(
					characterActions.listComplete(charsRes.listed.Character)
				);
			}
		}

		if (listed.Account) {
			store.dispatch(accountActions.listComplete(listed.Account));
		}
		await fetchPerms(listed.Episodic[0].id);
	} else if (location.pathname.startsWith('/app/episodics')) {
		const state = store.getState();
		if (state.episodics.getStatus === ActionStatus.Complete) {
			const currentId: number = state.episodics.currentId;
			await fetchPerms(currentId);
		}
	}
});

async function fetchPerms(currentId?: number) {
	try {
		const result = await getPerms(currentId);
		if (result.listed.Permission) {
			store.dispatch(permissionActions.getComplete(result.listed.Permission));
		}
		if (result.listed.UserSetting) {
			store.dispatch(userSettingActions.getComplete(result.listed.UserSetting));
		}
		if (result.listed.User) {
			store.dispatch(userActions.getComplete(result.listed.User[0]));
		}
	} catch (err) {
		store.dispatch(permissionActions.getFailed(err));
		if (err.response) {
			logger.log(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

window.addEventListener('single-spa:no-app-change', () => {
	const appNames = singleSpa.getAppNames();
	const appLoaded = appNames.some((name) => {
		return singleSpa.getAppStatus(name) !== 'NOT_LOADED';
	});
	if (!appLoaded) {
		window.location.replace('/server/index.php?r=site/404');
	}
});

if (process.env.SOS_ENV !== 'local' || process.env.SOS_DEBUG === 'true') {
	// This starts up Pendo
	(function (apiKey) {
		// tslint:disable-line
		(function (p, e, n, d, o) {
			// tslint:disable-line
			var v, w, x, y, z;
			o = p[d] = p[d] || {};
			o._q = [];
			v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
			for (w = 0, x = v.length; w < x; ++w)
				(function (m) {
					// tslint:disable-line
					o[m] =
						o[m] ||
						function () {
							o._q[m === v[0] ? 'unshift' : 'push'](
								[m].concat([].slice.call(arguments, 0))
							);
						}; // tslint:disable-line
				})(v[w]);
			y = e.createElement(n);
			y.async = !0;
			y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
			z = e.getElementsByTagName(n)[0];
			z.parentNode.insertBefore(y, z);
		})(window, document, 'script', 'pendo');
	})(process.env.PENDO_KEY);

	// This starts up Intercom
	setupIntercom(process.env);
}
