import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	ChangeScene,
	ChangeUpdateResponse,
	DEPARTMENTS,
	DeptSpecificChangeLookCreateResponse,
	DeptSpecificChangeLookDeleteResponse,
	DeptSpecificChangeLookGetResponse,
} from 'sos-models';
import * as changeActions from './change.actions';
import * as api from './change.api';
import * as logger from '../../logger';
import {
	changeEpisodicItemActions,
	changeLookActions,
	changeSceneActions,
	cmChangePictureActions,
	cmWrapBoxEpisodicItemActions,
	deptChangeSceneActions,
	episodicItemActions,
} from '../actions';

export function* getForEpItem({
	episodicId,
	epItemId,
}: changeActions.GetChangesForItemAction) {
	try {
		const {
			listed,
		}: DeptSpecificChangeLookGetResponse<DEPARTMENTS.CM> = yield call(
			api.getForEpItem,
			episodicId,
			epItemId
		);
		if (listed.Change) {
			yield put(
				changeActions.getChangesForItemComplete(listed.Change, epItemId)
			);
		}

		if (listed.ChangeEpisodicItem) {
			yield put(
				changeEpisodicItemActions.listComplete(listed.ChangeEpisodicItem)
			);
		}

		if (listed.CmChangePicture) {
			yield put(cmChangePictureActions.listComplete(listed.CmChangePicture));
		}
	} catch (err) {
		yield put(changeActions.getChangesForItemFailed(err));

		if (err.response) {
			toast.error(
				'There was an error loading the change table. Please refresh to try again.'
			);
		} else {
			logger.error(err);
		}
	}
}

export function* getChangesForScene({
	prodId,
	sceneId,
}: changeActions.GetChangesForSceneAction) {
	try {
		const result: DeptSpecificChangeLookGetResponse<DEPARTMENTS.CM> = yield call(
			api.getChangesForScene,
			prodId,
			sceneId
		);
		yield put(
			changeActions.getChangesForSceneComplete(result.listed.Change, prodId)
		);
	} catch (err) {
		yield put(changeActions.getChangesForSceneFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading the change table. Please refresh to try again.'
			);
		} else {
			logger.error(err);
		}
	}
}

export function* getForEpChar({
	episodicId,
	epCharId,
}: changeActions.GetChangesForEpCharAction) {
	try {
		const {
			listed,
		}: DeptSpecificChangeLookGetResponse<DEPARTMENTS.CM> = yield call(
			api.getForEpChar,
			episodicId,
			epCharId
		);
		if (listed.Change) {
			yield put(changeActions.getChangesForEpCharComplete(listed.Change));
		}
	} catch (err) {
		yield put(changeActions.getChangesForEpCharFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading the change table. Please refresh to try again.'
			);
		} else {
			logger.error(err);
		}
	}
}

export function* deleteForEpChar({
	episodicId,
	epCharId,
	changeId,
}: changeActions.DeleteChangeForEpCharAction) {
	try {
		const result: DeptSpecificChangeLookDeleteResponse<DEPARTMENTS.CM> = yield call(
			api.deleteForEpChar,
			episodicId,
			epCharId,
			changeId
		);
		const { destroyed } = result as DeptSpecificChangeLookDeleteResponse<
			DEPARTMENTS.CM
		>;
		yield put(changeActions.deleteChangeForEpCharComplete(epCharId, result));
		yield put(
			changeLookActions.deleteComplete(destroyed.Change, DEPARTMENTS.CM)
		);

		if (destroyed.ChangeScene) {
			yield put(changeSceneActions.deleteComplete(destroyed.ChangeScene));
			yield destroyed.ChangeScene.forEach((changeScene: ChangeScene) => {
				put(
					deptChangeSceneActions.deleteChangeSceneByIdAndDeptIdComplete(
						changeScene.id,
						DEPARTMENTS.CM
					)
				);
			});
		}
		if (destroyed.ChangeEpisodicItem) {
			yield put(
				changeEpisodicItemActions.deleteChangeEpisodicItemComplete(
					destroyed.ChangeEpisodicItem
				)
			);
		}
	} catch (err) {
		yield put(changeActions.deleteChangeForEpCharFailed(err));
		logger.responseError(err, 'deleting this change', true);
	}
}

export function* create({
	episodicId,
	change,
}: changeActions.CreateChangeAction) {
	try {
		const res: DeptSpecificChangeLookCreateResponse<DEPARTMENTS.CM> = yield call(
			api.create,
			episodicId,
			DEPARTMENTS.CM,
			change
		);
		yield put(changeActions.createChangeComplete(res.created.Change));
	} catch (err) {
		yield put(changeActions.createChangeFailed(err));
		logger.responseError(err, 'creating this change', true);
	}
}

export function* createByScene({
	episodicId,
	change,
	sceneId,
	oldChange,
}: changeActions.CreateChangeBySceneAction) {
	try {
		const { created, deleted, updated } = yield call(
			api.createByScene,
			episodicId,
			DEPARTMENTS.CM,
			change,
			sceneId,
			oldChange
		);
		yield put(
			changeActions.createChangeBySceneComplete(created, deleted, updated)
		);
	} catch (err) {
		yield put(changeActions.createChangeBySceneFailed(err));
		logger.responseError(err, 'creating this change', true);
	}
}

export function* update({
	episodicId,
	prodId,
	changes,
}: changeActions.UpdateAction) {
	try {
		const response: ChangeUpdateResponse = yield call(
			api.update,
			episodicId,
			prodId,
			changes
		);
		if (response.updated.Change) {
			yield put(changeActions.updateComplete(response.updated.Change));
		}
		if (response.created.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.createComplete(
					response.created.CmWrapBoxEpisodicItem
				)
			);
		}
		if (response.destroyed && response.destroyed.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.destroyComplete(
					response.destroyed.CmWrapBoxEpisodicItem
				)
			);
		}
		if (response.listed && response.listed.ChangeEpisodicItem) {
			yield put(
				changeEpisodicItemActions.listComplete(
					response.listed.ChangeEpisodicItem
				)
			);
		}
		if (response.listed && response.listed.EpisodicItem) {
			yield put(
				episodicItemActions.listComplete(
					response.listed.EpisodicItem
				)
			);
		}
		if (response.updated && response.updated.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.updateComplete(
					response.updated.CmWrapBoxEpisodicItem
				)
			);
		}
	} catch (err) {
		yield put(changeActions.updateFailed(err));
		logger.responseError(err, 'updating changes', true);
	}
}

function* saga() {
	yield takeLatest(changeActions.GET_CHANGES_FOR_ITEM, getForEpItem);
	yield takeLatest(changeActions.GET_CHANGES_FOR_SCENE, getChangesForScene);
	yield takeLatest(changeActions.GET_CHANGES_FOR_EP_CHAR, getForEpChar);
	yield takeLatest(changeActions.DELETE_CHANGE_FOR_EP_CHAR, deleteForEpChar);
	yield takeLatest(changeActions.CREATE_CHANGE, create);
	yield takeLatest(changeActions.CREATE_CHANGE_BY_SCENE, createByScene);
	yield takeEvery(changeActions.UPDATE, update);
}

export default saga;
