import { PermissionGetResponse } from 'sos-models';
import axios from '../../config/axios';

export async function get(episodicId?: number): Promise<PermissionGetResponse> {
	let path: number | string = episodicId;
	if (!path) {
		path = 'current';
	}
	return axios
		.get<PermissionGetResponse>(`/api/permissions/${path}`)
		.then((response) => response.data);
}
