import { omit } from 'lodash';
import {
	AnyInventory,
	DEPARTMENTS,
	InventoryCreateResponse,
	InventoryExportToNewSeasonCheckReponse,
	InventoryExportToNewSeasonReponse,
	InventoryListResponse,
	InventoryQueryOptions,
	InventoryRemoveFromWrapBoxResponse,
	InventoryUpdatePayload,
	InventoryUpdateResponse,
	REPORT_TYPES,
	WrapBoxInventoryCreatePayload,
	WrapBoxInventoryListRelationsResponse,
	WrapBoxInventoryListResponse,
	WrapBoxInventoryUpdatePayload,
	WrapBoxInventoryUpdateResponse,
} from 'sos-models';
import { ListOptions } from './inventory.actions';
import axios from '../../config/axios';

interface ListResults {
	inventory: Partial<AnyInventory>[];
	totalCount: number;
}

export async function list(
	episodicId: number,
	options: ListOptions
): Promise<InventoryListResponse> {
	return await axios
		.get<InventoryListResponse>(`/api/episodics/${episodicId}/inventory`, {
			params: omit(options, ['episodicId']),
		})
		.then((response) => {
			return response.data;
		});
}

export async function listForWrapBox(
	episodicId: number,
	department: DEPARTMENTS,
	wrapBoxId: number
): Promise<WrapBoxInventoryListResponse> {
	return await axios
		.get<WrapBoxInventoryListResponse>(
			`/api/episodics/${episodicId}/wrap-boxes/${department}/${wrapBoxId}/inventory`
		)
		.then((response) => response.data);
}

export async function listWrapBoxInventoryRelations(
	episodicId: number,
	department: DEPARTMENTS,
	artifactId: number
): Promise<WrapBoxInventoryListRelationsResponse> {
	return await axios
		.get<WrapBoxInventoryListRelationsResponse>(
			`/api/episodics/${episodicId}/inventory/${department}/${artifactId}/wrap-boxes`
		)
		.then((response) => response.data);
}

export const exportToNewSeason = async (
	episodicId: number,
	newEpisodicId: number,
	epItemIds: number[],
	epPropIds: number[],
	setPieceIds: number[],
	checkIfExported: boolean,
	allPagesSelected: boolean,
	options: ListOptions
): Promise<
	InventoryExportToNewSeasonCheckReponse | InventoryExportToNewSeasonReponse
> => {
	const { data } = await axios.put(
		`/api/episodics/${episodicId}/inventory/export-to-new-season/${newEpisodicId}`,
		{
			epItemIds,
			epPropIds,
			setPieceIds,
			checkIfExported,
			allPagesSelected,
			options,
		}
	);
	return data;
};

export async function get(
	id: number,
	episodicId: number,
	department: number
): Promise<AnyInventory> {
	return await axios
		.get<AnyInventory>(
			`/api/episodics/${episodicId}/inventory/${department}/${id}`
		)
		.then((response) => response.data);
}

export async function update(
	episodicId: number,
	inventory: InventoryUpdatePayload
): Promise<InventoryUpdateResponse> {
	return await axios
		.put<InventoryUpdateResponse>(
			`/api/episodics/${episodicId}/inventory`,
			inventory
		)
		.then((response) => response.data);
}

export async function create(
	episodicId: number,
	inventory: Partial<AnyInventory>,
	setIds?: number[]
): Promise<InventoryCreateResponse> {
	return await axios
		.post<InventoryCreateResponse>(`/api/episodics/${episodicId}/inventory`, {
			artifact: inventory,
			setIds,
		})
		.then((response) => response.data);
}

export async function createWrapBoxInventory(
	episodicId: number,
	departmentId: number,
	inventoryId: number,
	wrapBoxPayload: WrapBoxInventoryCreatePayload
): Promise<WrapBoxInventoryUpdateResponse> {
	return await axios
		.post<WrapBoxInventoryUpdateResponse>(
			`/api/episodics/${episodicId}/inventory/${departmentId}/${inventoryId}/wrap-boxes`,
			wrapBoxPayload
		)
		.then((response) => response.data);
}

export async function remove(
	id: number,
	episodicId: number,
	department: DEPARTMENTS
) {
	return await axios
		.delete(`/api/episodics/${episodicId}/inventory/${department}/${id}`)
		.then((response) => response.data);
}

export async function removeFromWrapBox(
	episodicId: number,
	wrapBoxId: number,
	department: DEPARTMENTS,
	inventoryId: number
): Promise<InventoryRemoveFromWrapBoxResponse> {
	return await axios
		.put<InventoryRemoveFromWrapBoxResponse>(
			`/api/episodics/${episodicId}/inventory/${department}/${inventoryId}/wrap-box/${wrapBoxId}`
		)
		.then((response) => response.data);
}

export async function updateWrapBoxInventory(
	episodicId: number,
	departmentId: number,
	inventoryId: number,
	wrapBoxPayload: WrapBoxInventoryUpdatePayload
): Promise<WrapBoxInventoryUpdateResponse> {
	return await axios
		.put<WrapBoxInventoryUpdateResponse>(
			`/api/episodics/${episodicId}/inventory/${departmentId}/${inventoryId}/wrap-boxes`,
			wrapBoxPayload
		)
		.then((response) => response.data);
}

export async function exportInventory(
	episodicId: number,
	options: InventoryQueryOptions,
	reportType: REPORT_TYPES
): Promise<ListResults> {
	return await axios
		.get<any>(`/api/episodics/${episodicId}/inventory/export/${reportType}`, {
			params: options,
			responseType: 'arraybuffer',
			headers: {
				Accept: 'application/pdf',
			},
		})
		.then((response) => {
			if (reportType === 1) {
				return new Blob([response.data], { type: 'application/pdf' });
			} else {
				return response.data;
			}
		});
}
