import {
	AnyInventory,
	DEPARTMENTS,
	Inventory,
	InventoryQueryOptions,
	InventoryUpdatePayload,
	REPORT_TYPES,
	WrapBoxInventoryCreatePayload,
	WrapBoxInventoryListResponse,
	WrapBoxInventoryUpdatePayload,
} from 'sos-models';

export const LIST = '[Inventory] List';
type LIST = typeof LIST;

export const LIST_FAILED = '[Inventory] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const LIST_COMPLETE = '[Inventory] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FOR_WRAP_BOX = '[Inventory] List for Wrap Box';
type LIST_FOR_WRAP_BOX = typeof LIST_FOR_WRAP_BOX;

export const LIST_FOR_WRAP_BOX_FAILED = '[Inventory] List for Wrap Box Failed';
type LIST_FOR_WRAP_BOX_FAILED = typeof LIST_FOR_WRAP_BOX_FAILED;

export const LIST_FOR_WRAP_BOX_COMPLETE =
	'[Inventory] List for Wrap Box Complete';
type LIST_FOR_WRAP_BOX_COMPLETE = typeof LIST_FOR_WRAP_BOX_COMPLETE;

export const LIST_WRAP_BOX_INVENTORY_RELATIONS =
	'[Inventory] List Wrap Box InventoryRelations';
type LIST_WRAP_BOX_INVENTORY_RELATIONS = typeof LIST_WRAP_BOX_INVENTORY_RELATIONS;

export const LIST_WRAP_BOX_INVENTORY_RELATIONS_COMPLETE =
	'[Inventory] List Wrap Box Inventory Relations Complete';
type LIST_WRAP_BOX_INVENTORY_RELATIONS_COMPLETE = typeof LIST_WRAP_BOX_INVENTORY_RELATIONS_COMPLETE;

export const LIST_WRAP_BOX_INVENTORY_RELATIONS_FAILED =
	'[Inventory] List Wrap Box Inventory Relations Failed';
type LIST_WRAP_BOX_INVENTORY_RELATIONS_FAILED = typeof LIST_WRAP_BOX_INVENTORY_RELATIONS_FAILED;

export const EXPORT_TO_NEW_SEASON = '[Inventory] Export to New Season';
type EXPORT_TO_NEW_SEASON = typeof EXPORT_TO_NEW_SEASON;

export const EXPORT_TO_NEW_SEASON_COMPLETE =
	'[Inventory] Export to New Season Complete';
type EXPORT_TO_NEW_SEASON_COMPLETE = typeof EXPORT_TO_NEW_SEASON_COMPLETE;

export const EXPORT_TO_NEW_SEASON_FAILED =
	'[Inventory] Export to New Season Failed';
type EXPORT_TO_NEW_SEASON_FAILED = typeof EXPORT_TO_NEW_SEASON_FAILED;

export const GET = '[Inventory] Get';
type GET = typeof GET;

export const GET_FAILED = '[Inventory] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export const GET_COMPLETE = '[Inventory] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const REMOVE = '[Inventory] Remove';
type REMOVE = typeof REMOVE;

export const REMOVE_FAILED = '[Inventory] Remove Failed';
type REMOVE_FAILED = typeof REMOVE_FAILED;

export const REMOVE_COMPLETE = '[Inventory] Remove Complete';
type REMOVE_COMPLETE = typeof REMOVE_COMPLETE;

export const REMOVE_FROM_WRAP_BOX = '[Inventory] Remove From Wrap Box';
type REMOVE_FROM_WRAP_BOX = typeof REMOVE_FROM_WRAP_BOX;

export const REMOVE_FROM_WRAP_BOX_FAILED =
	'[Inventory] Remove From Wrap Box Failed';
type REMOVE_FROM_WRAP_BOX_FAILED = typeof REMOVE_FROM_WRAP_BOX_FAILED;

export const REMOVE_FROM_WRAP_BOX_COMPLETE =
	'[Inventory] Remove From Wrap Box Complete';
type REMOVE_FROM_WRAP_BOX_COMPLETE = typeof REMOVE_FROM_WRAP_BOX_COMPLETE;

export const UPDATE = '[Inventory] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_FAILED = '[Inventory] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const UPDATE_COMPLETE = '[Inventory] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_INVENTORY_WRAP_BOX = '[Inventory Wrap Box] Update';
type UPDATE_INVENTORY_WRAP_BOX = typeof UPDATE_INVENTORY_WRAP_BOX;

export const UPDATE_INVENTORY_WRAP_BOX_COMPLETE =
	'[Inventory Wrap Box] Update Complete';
type UPDATE_INVENTORY_WRAP_BOX_COMPLETE = typeof UPDATE_INVENTORY_WRAP_BOX_COMPLETE;

export const UPDATE_INVENTORY_WRAP_BOX_FAILED =
	'[Inventory Wrap Box] Update Failed';
type UPDATE_INVENTORY_WRAP_BOX_FAILED = typeof UPDATE_INVENTORY_WRAP_BOX_FAILED;

export const CREATE = '[Inventory] Create';
type CREATE = typeof CREATE;

export const CREATE_FAILED = '[Inventory] Create Failed';
type CREATE_FAILED = typeof CREATE_FAILED;

export const CREATE_COMPLETE = '[Inventory] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const CREATE_INVENTORY_WRAP_BOX = '[Inventory Wrap Box] Create';
type CREATE_INVENTORY_WRAP_BOX = typeof CREATE_INVENTORY_WRAP_BOX;

export const CREATE_INVENTORY_WRAP_BOX_COMPLETE =
	'[Inventory Wrap Box] Create Complete';
type CREATE_INVENTORY_WRAP_BOX_COMPLETE = typeof CREATE_INVENTORY_WRAP_BOX_COMPLETE;

export const CREATE_INVENTORY_WRAP_BOX_FAILED =
	'[Inventory Wrap Box] Create Failed';
type CREATE_INVENTORY_WRAP_BOX_FAILED = typeof CREATE_INVENTORY_WRAP_BOX_FAILED;

export const EXPORT_INVENTORY = '[Inventory] Export Inventory Table';
type EXPORT_INVENTORY = typeof EXPORT_INVENTORY;

export const EXPORT_FAILED = '[Inventory] Export Failed';
type EXPORT_FAILED = typeof EXPORT_FAILED;

export const EXPORT_COMPLETE = '[Inventory] Export Complete';
type EXPORT_COMPLETE = typeof EXPORT_COMPLETE;

export const RESET_CREATE_STATUS = '[Inventory] Reset Create Status';
type RESET_CREATE_STATUS = typeof RESET_CREATE_STATUS;

export interface ResetCreateStatusAction {
	type: RESET_CREATE_STATUS;
}

export interface ListOptions extends InventoryQueryOptions {
	episodicId: number;
}

export interface ListAction {
	episodicId: number;
	options: ListOptions;
	type: LIST;
}

export interface ListFailedAction {
	type: LIST_FAILED;
	error: Error;
}

export interface ListCompleteAction {
	inventory: Inventory[];
	totalCount: number;
	options: ListOptions;
	type: LIST_COMPLETE;
}

export interface ListForWrapBoxAction {
	episodicId: number;
	department: DEPARTMENTS;
	wrapBoxId: number;
	type: LIST_FOR_WRAP_BOX;
}

export interface ListForWrapBoxFailedAction {
	type: LIST_FOR_WRAP_BOX_FAILED;
	error: Error;
}

export type ListForWrapBoxCompleteAction = WrapBoxInventoryListResponse & {
	type: LIST_FOR_WRAP_BOX_COMPLETE;
};

export interface ListWrapBoxInventoryRelationsAction {
	episodicId: number;
	department: DEPARTMENTS;
	artifactId: number;
	type: LIST_WRAP_BOX_INVENTORY_RELATIONS;
}

export interface ListWrapBoxInventoryRelationsCompleteAction {
	type: LIST_WRAP_BOX_INVENTORY_RELATIONS_COMPLETE;
}

export interface ListWrapBoxInventoryRelationsFailedAction {
	type: LIST_WRAP_BOX_INVENTORY_RELATIONS_FAILED;
	error: Error;
}

export interface ExportToNewSeasonAction {
	epItemIds: number[];
	epPropIds: number[];
	setPieceIds: number[];
	episodicId: number;
	newEpisodicId: number;
	checkIfExported: boolean;
	allPagesSelected: boolean;
	options: ListOptions;
	type: EXPORT_TO_NEW_SEASON;
}

export interface ExportToNewSeasonCompleteAction {
	type: EXPORT_TO_NEW_SEASON_COMPLETE;
}

export interface ExportToNewSeasonFailedAction {
	error: Error;
	type: EXPORT_TO_NEW_SEASON_FAILED;
}

export interface GetAction {
	id: number;
	episodicId: number;
	department: number;
	type: GET;
}

export interface GetFailedAction {
	type: GET_FAILED;
	error: Error;
}

export interface GetCompleteAction {
	inventory: AnyInventory;
	deptId: number;
	type: GET_COMPLETE;
}

export interface RemoveAction {
	id: number;
	episodicId: number;
	department: number;
	type: REMOVE;
}

export interface RemoveFailedAction {
	type: REMOVE_FAILED;
	error: Error;
}

export interface RemoveCompleteAction {
	id: number;
	department: number;
	type: REMOVE_COMPLETE;
}

export interface RemoveFromWrapBoxAction {
	episodicId: number;
	wrapBoxId: number;
	department: number;
	inventoryId: number;
	type: REMOVE_FROM_WRAP_BOX;
}

export interface RemoveFromWrapBoxFailedAction {
	type: REMOVE_FROM_WRAP_BOX_FAILED;
	error: Error;
}

export interface RemoveFromWrapBoxCompleteAction {
	type: REMOVE_FROM_WRAP_BOX_COMPLETE;
}

export interface UpdateAction {
	episodicId: number;
	inventory: InventoryUpdatePayload;
	type: UPDATE;
}

export interface UpdateFailedAction {
	type: UPDATE_FAILED;
	error: Error;
}

export interface UpdateCompleteAction {
	inventory: Partial<Inventory>[];
	type: UPDATE_COMPLETE;
}

export interface UpdateWrapBoxInventoryAction {
	episodicId: number;
	departmentId: number;
	inventoryId: number;
	wrapBoxPayload: WrapBoxInventoryUpdatePayload;
	type: UPDATE_INVENTORY_WRAP_BOX;
}

export interface UpdateWrapBoxInventoryCompleteAction {
	type: UPDATE_INVENTORY_WRAP_BOX_COMPLETE;
}

export interface UpdateWrapBoxInventoryFailedAction {
	error: Error;
	type: UPDATE_INVENTORY_WRAP_BOX_FAILED;
}

export interface CreateAction {
	episodicId: number;
	inventory: Partial<AnyInventory>;
	setIds?: number[];
	type: CREATE;
}

export interface CreateFailedAction {
	type: CREATE_FAILED;
	error: Error;
}

export interface CreateCompleteAction {
	inventory: Inventory[];
	type: CREATE_COMPLETE;
}

export interface CreateWrapBoxInventoryAction {
	episodicId: number;
	departmentId: number;
	inventoryId: number;
	wrapBoxPayload: WrapBoxInventoryCreatePayload;
	type: CREATE_INVENTORY_WRAP_BOX;
}

export interface CreateWrapBoxInventoryCompleteAction {
	type: CREATE_INVENTORY_WRAP_BOX_COMPLETE;
}

export interface CreateWrapBoxInventoryFailedAction {
	error: Error;
	type: CREATE_INVENTORY_WRAP_BOX_FAILED;
}
export interface ExportAction {
	episodicId: number;
	options: ListOptions;
	reportType: REPORT_TYPES;
	type: EXPORT_INVENTORY;
}

export interface ExportCompleteAction {
	fileContent: string;
	reportType: REPORT_TYPES;
	type: EXPORT_COMPLETE;
}

export interface ExportFailedAction {
	error: Error;
	type: EXPORT_FAILED;
}

export function list(episodicId: number, options: ListOptions): ListAction {
	return {
		episodicId,
		options,
		type: LIST,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export function listComplete(
	inventory: Inventory[],
	totalCount: number,
	options: ListOptions
): ListCompleteAction {
	return {
		inventory,
		totalCount,
		options,
		type: LIST_COMPLETE,
	};
}

export function listForWrapBox(
	episodicId: number,
	department: DEPARTMENTS,
	wrapBoxId: number
): ListForWrapBoxAction {
	return {
		episodicId,
		department,
		wrapBoxId,
		type: LIST_FOR_WRAP_BOX,
	};
}

export function listForWrapBoxFailed(error: Error): ListForWrapBoxFailedAction {
	return {
		error,
		type: LIST_FOR_WRAP_BOX_FAILED,
	};
}

export function listWrapBoxInventoryRelations(
	episodicId: number,
	department: DEPARTMENTS,
	artifactId: number
): ListWrapBoxInventoryRelationsAction {
	return {
		episodicId,
		department,
		artifactId,
		type: LIST_WRAP_BOX_INVENTORY_RELATIONS,
	};
}

export function listWrapBoxInventoryRelationsFailed(
	error: Error
): ListWrapBoxInventoryRelationsFailedAction {
	return {
		error,
		type: LIST_WRAP_BOX_INVENTORY_RELATIONS_FAILED,
	};
}

export function exportToNewSeason(
	episodicId: number,
	newEpisodicId: number,
	epItemIds: number[],
	epPropIds: number[],
	setPieceIds: number[],
	checkIfExported: boolean,
	allPagesSelected: boolean,
	options: ListOptions
): ExportToNewSeasonAction {
	return {
		episodicId,
		epItemIds,
		epPropIds,
		setPieceIds,
		newEpisodicId,
		checkIfExported,
		allPagesSelected,
		options,
		type: EXPORT_TO_NEW_SEASON,
	};
}

export function exportToNewSeasonComplete(): ExportToNewSeasonCompleteAction {
	return {
		type: EXPORT_TO_NEW_SEASON_COMPLETE,
	};
}

export function exportToNewSeasonFailed(
	error: Error
): ExportToNewSeasonFailedAction {
	return {
		error,
		type: EXPORT_TO_NEW_SEASON_FAILED,
	};
}

export function get(
	id: number,
	episodicId: number,
	department: number
): GetAction {
	return {
		id,
		episodicId,
		department,
		type: GET,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	};
}

export function getComplete(
	inventory: AnyInventory,
	deptId: number
): GetCompleteAction {
	return {
		inventory,
		deptId,
		type: GET_COMPLETE,
	};
}

export function remove(
	id: number,
	episodicId: number,
	department: number
): RemoveAction {
	return {
		id,
		episodicId,
		department,
		type: REMOVE,
	};
}

export function removeFailed(error: Error): RemoveFailedAction {
	return {
		error,
		type: REMOVE_FAILED,
	};
}

export function removeComplete(
	id: number,
	department: number
): RemoveCompleteAction {
	return {
		id,
		department,
		type: REMOVE_COMPLETE,
	};
}

export function removeFromWrapBox(
	episodicId: number,
	wrapBoxId: number,
	department: number,
	inventoryId: number
): RemoveFromWrapBoxAction {
	return {
		wrapBoxId,
		episodicId,
		department,
		inventoryId,
		type: REMOVE_FROM_WRAP_BOX,
	};
}

export function removeFromWrapBoxFailed(
	error: Error
): RemoveFromWrapBoxFailedAction {
	return {
		error,
		type: REMOVE_FROM_WRAP_BOX_FAILED,
	};
}

export function removeFromWrapBoxComplete(): RemoveFromWrapBoxCompleteAction {
	return {
		type: REMOVE_FROM_WRAP_BOX_COMPLETE,
	};
}

export function update(
	episodicId: number,
	inventory: InventoryUpdatePayload
): UpdateAction {
	return {
		episodicId,
		inventory,
		type: UPDATE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function updateComplete(
	inventory: Partial<Inventory>[]
): UpdateCompleteAction {
	return {
		inventory,
		type: UPDATE_COMPLETE,
	};
}

export function updateWrapBoxInventory(
	episodicId: number,
	departmentId: number,
	inventoryId: number,
	wrapBoxPayload: WrapBoxInventoryUpdatePayload
): UpdateWrapBoxInventoryAction {
	return {
		episodicId,
		departmentId,
		inventoryId,
		wrapBoxPayload,
		type: UPDATE_INVENTORY_WRAP_BOX,
	};
}

export function updateWrapBoxInventoryFailed(
	error: Error
): UpdateWrapBoxInventoryFailedAction {
	return {
		error,
		type: UPDATE_INVENTORY_WRAP_BOX_FAILED,
	};
}

export function updateWrapBoxInventoryComplete(): UpdateWrapBoxInventoryCompleteAction {
	return {
		type: UPDATE_INVENTORY_WRAP_BOX_COMPLETE,
	};
}

export function create(
	episodicId: number,
	inventory: Partial<AnyInventory>,
	setIds?: number[]
): CreateAction {
	return {
		episodicId,
		inventory,
		setIds,
		type: CREATE,
	};
}

export function createFailed(error: Error): CreateFailedAction {
	return {
		error,
		type: CREATE_FAILED,
	};
}

export function createComplete(inventory: Inventory[]): CreateCompleteAction {
	return {
		inventory,
		type: CREATE_COMPLETE,
	};
}

export function createWrapBoxInventory(
	episodicId: number,
	departmentId: number,
	inventoryId: number,
	wrapBoxPayload: WrapBoxInventoryCreatePayload
): CreateWrapBoxInventoryAction {
	return {
		episodicId,
		departmentId,
		inventoryId,
		wrapBoxPayload,
		type: CREATE_INVENTORY_WRAP_BOX,
	};
}

export function createWrapBoxInventoryFailed(
	error: Error
): CreateWrapBoxInventoryFailedAction {
	return {
		error,
		type: CREATE_INVENTORY_WRAP_BOX_FAILED,
	};
}

export function createWrapBoxInventoryComplete(): CreateWrapBoxInventoryCompleteAction {
	return {
		type: CREATE_INVENTORY_WRAP_BOX_COMPLETE,
	};
}

export function exportInventory(
	episodicId: number,
	options: ListOptions,
	reportType: REPORT_TYPES
): ExportAction {
	return {
		episodicId,
		options,
		reportType,
		type: EXPORT_INVENTORY,
	};
}

export function exportFailed(error: Error): ExportFailedAction {
	return {
		error,
		type: EXPORT_FAILED,
	};
}

export function exportComplete(
	fileContent: string,
	reportType: REPORT_TYPES
): ExportCompleteAction {
	return {
		fileContent,
		reportType,
		type: EXPORT_COMPLETE,
	};
}

export function resetCreateStatus(): ResetCreateStatusAction {
	return {
		type: RESET_CREATE_STATUS,
	};
}

export type Action =
	| ListAction
	| ListFailedAction
	| ListCompleteAction
	| ListForWrapBoxAction
	| ListForWrapBoxFailedAction
	| ListForWrapBoxCompleteAction
	| ListWrapBoxInventoryRelationsAction
	| ListWrapBoxInventoryRelationsCompleteAction
	| ListWrapBoxInventoryRelationsFailedAction
	| ExportToNewSeasonAction
	| ExportToNewSeasonCompleteAction
	| ExportToNewSeasonFailedAction
	| UpdateWrapBoxInventoryAction
	| UpdateWrapBoxInventoryFailedAction
	| UpdateWrapBoxInventoryCompleteAction
	| GetAction
	| GetFailedAction
	| GetCompleteAction
	| RemoveAction
	| RemoveFailedAction
	| RemoveCompleteAction
	| RemoveFromWrapBoxAction
	| RemoveFromWrapBoxFailedAction
	| RemoveFromWrapBoxCompleteAction
	| ResetCreateStatusAction
	| UpdateAction
	| UpdateFailedAction
	| UpdateCompleteAction
	| CreateAction
	| CreateFailedAction
	| CreateCompleteAction
	| CreateWrapBoxInventoryAction
	| CreateWrapBoxInventoryFailedAction
	| CreateWrapBoxInventoryCompleteAction
	| ExportAction
	| ExportFailedAction
	| ExportCompleteAction;
