import { PropSceneCharScene } from 'sos-models';
import * as actions from './prop-scene-char-scene.actions';
import { propSceneCharSceneSchema } from '../schemas';
import {
	BaseReducerState,
	createComplete,
	createReducer,
	destroyComplete,
	listComplete,
} from '../utils';

export interface State extends BaseReducerState<PropSceneCharScene> {}

const initial: State = {
	entities: {},
	ids: [],
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.CREATE_COMPLETE]: createComplete<
		PropSceneCharScene,
		State,
		actions.CreateCompleteAction
	>('propSceneCharScenes', propSceneCharSceneSchema),
	[actions.LIST_COMPLETE]: listComplete<
		PropSceneCharScene,
		State,
		actions.ListCompleteAction
	>('propSceneCharScenes', propSceneCharSceneSchema),
	[actions.DESTROY_COMPLETE]: destroyComplete<
		PropSceneCharScene,
		State,
		actions.DestroyCompleteAction
	>('propSceneCharScenes'),
});
