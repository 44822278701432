import { EpisodicProp, PropScene, PropSceneCharScene } from 'sos-models';

export const LIST_COMPLETE = '[Prop Scene] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const GET_FOR_EP_CHAR = '[Prop Scene] Get For Ep Char';
type GET_FOR_EP_CHAR = typeof GET_FOR_EP_CHAR;

export const GET_FOR_EP_CHAR_COMPLETE = '[Prop Scene] Get For Ep Char Complete';
type GET_FOR_EP_CHAR_COMPLETE = typeof GET_FOR_EP_CHAR_COMPLETE;

export const GET_FOR_EP_CHAR_FAILED = '[Prop Scene] Get For Ep Char Failed';
type GET_FOR_EP_CHAR_FAILED = typeof GET_FOR_EP_CHAR_FAILED;

export const DESTROY = '[Prop Scene] Destroy';
type DESTROY = typeof DESTROY;

export const DESTROY_COMPLETE = '[Prop Scene] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const DESTROY_FAILED = '[Prop Scene] Destroy Failed';
type DESTROY_FAILED = typeof DESTROY_FAILED;

export const CREATE = '[Prop Scene] Create';
type CREATE = typeof CREATE;

export const CREATE_BY_SCENE = '[Prop Scene] Create By Scene';
type CREATE_BY_SCENE = typeof CREATE_BY_SCENE;

export const CREATE_FAILED = '[Prop Scene] Create Failed';
type CREATE_FAILED = typeof CREATE_FAILED;

export const CREATE_COMPLETE = '[Prop Scene] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const CREATE_MULTIPLE = '[Prop Scene] Create Multiple';
type CREATE_MULTIPLE = typeof CREATE_MULTIPLE;

export const CREATE_MULTIPLE_FAILED = '[Prop Scene] Create Multiple Failed';
type CREATE_MULTIPLE_FAILED = typeof CREATE_MULTIPLE_FAILED;

export const CREATE_MULTIPLE_COMPLETE = '[Prop Scene] Create Multiple Complete';
type CREATE_MULTIPLE_COMPLETE = typeof CREATE_MULTIPLE_COMPLETE;

export const CREATE_SCENE_EPISODIC_PROP =
	'[Prop Scene] Create Scene Episodic Prop';
type CREATE_SCENE_EPISODIC_PROP = typeof CREATE_SCENE_EPISODIC_PROP;

export const UPDATE_COMPLETE = '[Prop Scene] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export interface ListCompleteAction {
	propScenes: PropScene[];
	type: LIST_COMPLETE;
}

export interface GetForEpCharAction {
	episodicId: number;
	episodicCharacterId: number;
	type: GET_FOR_EP_CHAR;
}

export interface GetForEpCharCompleteAction {
	propScenes: PropScene[];
	episodicCharacterId: number;
	type: GET_FOR_EP_CHAR_COMPLETE;
}

export interface GetForEpCharFailedAction {
	error: Error;
	type: GET_FOR_EP_CHAR_FAILED;
}

export interface DestroyAction {
	episodicId: number;
	episodicPropId: number;
	sceneId: number;
	type: DESTROY;
}

export interface DestroyFailedAction {
	error: Error;
	type: DESTROY_FAILED;
}

export interface DestroyCompleteAction {
	type: DESTROY_COMPLETE;
	propScenes: PropScene[];
}

export interface CreateBySceneAction {
	episodicId: number;
	sceneId: number;
	originalSceneId: number;
	epPropIds: number[];
	type: CREATE_BY_SCENE;
}

export interface CreateAction {
	episodicId: number;
	episodicPropId: number;
	sceneIds: number[];
	type: CREATE;
}

export interface CreateFailedAction {
	error: Error;
	type: CREATE_FAILED;
}

export interface CreateCompleteAction {
	type: CREATE_COMPLETE;
	propScenes: PropScene[];
}

export interface CreateMultipleAction {
	episodicId: number;
	episodicPropIds: number[];
	sceneIds: number[];
	epCharId: number;
	type: CREATE_MULTIPLE;
}

export interface CreateMultipleFailedAction {
	error: Error;
	type: CREATE_MULTIPLE_FAILED;
}

export interface CreateMultipleCompleteAction {
	type: CREATE_MULTIPLE_COMPLETE;
	propScenes: PropScene[];
	propSceneCharScenes: PropSceneCharScene[];
}

export interface CreateSceneEpisodicPropAction {
	sceneId: number;
	prodId: number;
	episodicId: number;
	episodicProp: EpisodicProp;
	type: CREATE_SCENE_EPISODIC_PROP;
}

export interface UpdateCompleteAction {
	type: UPDATE_COMPLETE;
	propScenes: PropScene[];
}

export function listComplete(propScenes: PropScene[]): ListCompleteAction {
	return {
		propScenes,
		type: LIST_COMPLETE,
	};
}

export function getForEpChar(
	episodicId: number,
	episodicCharacterId: number
): GetForEpCharAction {
	return {
		episodicId,
		episodicCharacterId,
		type: GET_FOR_EP_CHAR,
	};
}

export function getForEpCharComplete(
	propScenes: PropScene[],
	episodicCharacterId: number
): GetForEpCharCompleteAction {
	return {
		propScenes,
		episodicCharacterId,
		type: GET_FOR_EP_CHAR_COMPLETE,
	};
}

export function getForEpCharFailed(error: Error): GetForEpCharFailedAction {
	return {
		error,
		type: GET_FOR_EP_CHAR_FAILED,
	};
}

export function destroy(
	episodicId: number,
	episodicPropId: number,
	sceneId: number
): DestroyAction {
	return {
		episodicId,
		episodicPropId,
		sceneId,
		type: DESTROY,
	};
}

export function destroyFailed(error: Error): DestroyFailedAction {
	return {
		error,
		type: DESTROY_FAILED,
	};
}

export function destroyComplete(
	propScenes: PropScene[]
): DestroyCompleteAction {
	return {
		propScenes,
		type: DESTROY_COMPLETE,
	};
}

export function create(
	episodicId: number,
	episodicPropId: number,
	sceneIds: number[]
): CreateAction {
	return {
		episodicId,
		episodicPropId,
		sceneIds,
		type: CREATE,
	};
}

export function createByScene(
	episodicId: number,
	sceneId: number,
	originalSceneId: number,
	epPropIds: number[]
): CreateBySceneAction {
	return {
		episodicId,
		sceneId,
		originalSceneId,
		epPropIds,
		type: CREATE_BY_SCENE,
	};
}

export function createFailed(error: Error): CreateFailedAction {
	return {
		error,
		type: CREATE_FAILED,
	};
}

export function createComplete(propScenes: PropScene[]): CreateCompleteAction {
	return {
		propScenes,
		type: CREATE_COMPLETE,
	};
}

export function createMultiple(
	episodicId: number,
	episodicPropIds: number[],
	sceneIds: number[],
	epCharId?: number
): CreateMultipleAction {
	return {
		episodicId,
		episodicPropIds,
		sceneIds,
		epCharId: epCharId ? epCharId : null,
		type: CREATE_MULTIPLE,
	};
}

export function createMultipleFailed(error: Error): CreateMultipleFailedAction {
	return {
		error,
		type: CREATE_MULTIPLE_FAILED,
	};
}

export function createMultipleComplete(propScenes: PropScene[], propSceneCharScenes: PropSceneCharScene[]): CreateMultipleCompleteAction {
	return {
		propScenes,
		propSceneCharScenes,
		type: CREATE_MULTIPLE_COMPLETE,
	};
}

export function createSceneEpisodicProp(
	sceneId: number,
	prodId: number,
	episodicId: number,
	episodicProp: EpisodicProp
): CreateSceneEpisodicPropAction {
	return {
		sceneId,
		prodId,
		episodicId,
		episodicProp,
		type: CREATE_SCENE_EPISODIC_PROP,
	};
}

export function updateComplete(propScenes: PropScene[]): UpdateCompleteAction {
	return {
		propScenes,
		type: UPDATE_COMPLETE,
	};
}

export type Action =
	| ListCompleteAction
	| GetForEpCharAction
	| GetForEpCharCompleteAction
	| GetForEpCharFailedAction
	| CreateAction
	| CreateBySceneAction
	| CreateFailedAction
	| CreateCompleteAction
	| CreateMultipleAction
	| CreateMultipleFailedAction
	| CreateMultipleCompleteAction
	| CreateSceneEpisodicPropAction
	| DestroyAction
	| DestroyFailedAction
	| DestroyCompleteAction
	| UpdateCompleteAction;
