import { Sorting } from '@devexpress/dx-react-grid';
import {
	Change,
	ChangeEpisodicItem,
	ChangeScene,
	DEPARTMENTS,
	DeptSpecificChangeLookDeleteResponse,
} from 'sos-models';

export const GET = '[Change] Get';
type GET = typeof GET;

export const GET_COMPLETE = '[Change] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const GET_FAILED = '[Change] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export const DELETE_COMPLETE = '[Change] Delete Complete';
type DELETE_COMPLETE = typeof DELETE_COMPLETE;

export const GET_CHANGES_FOR_CHANGE_LOOK =
	'[Change] Get Changes For Change Look';
type GET_CHANGES_FOR_CHANGE_LOOK = typeof GET_CHANGES_FOR_CHANGE_LOOK;

export const GET_CHANGES_FOR_CHANGE_LOOK_COMPLETE =
	'[Change] Get Changes For Change Look Complete';
type GET_CHANGES_FOR_CHANGE_LOOK_COMPLETE = typeof GET_CHANGES_FOR_CHANGE_LOOK_COMPLETE;

export const GET_CHANGES_FOR_CHANGE_LOOK_FAILED =
	'[Change] Get Changes For Change Look Failed';
type GET_CHANGES_FOR_CHANGE_LOOK_FAILED = typeof GET_CHANGES_FOR_CHANGE_LOOK_FAILED;

export const GET_CHANGES_FOR_ITEM = '[Change] Get Changes For Item';
type GET_CHANGES_FOR_ITEM = typeof GET_CHANGES_FOR_ITEM;

export const GET_CHANGES_FOR_ITEM_COMPLETE =
	'[Change] Get Changes For Item Complete';
type GET_CHANGES_FOR_ITEM_COMPLETE = typeof GET_CHANGES_FOR_ITEM_COMPLETE;

export const GET_CHANGES_FOR_ITEM_FAILED =
	'[Change] Get Changes For Item Failed';
type GET_CHANGES_FOR_ITEM_FAILED = typeof GET_CHANGES_FOR_ITEM_FAILED;

export const GET_CHANGES_FOR_SCENE = '[Change] Get Changes For Scene';
type GET_CHANGES_FOR_SCENE = typeof GET_CHANGES_FOR_SCENE;

export const GET_CHANGES_FOR_SCENE_COMPLETE =
	'[Change] Get Changes For Scene Complete';
type GET_CHANGES_FOR_SCENE_COMPLETE = typeof GET_CHANGES_FOR_SCENE_COMPLETE;

export const GET_CHANGES_FOR_SCENE_FAILED =
	'[Change] Get Changes For Scene Failed';
type GET_CHANGES_FOR_SCENE_FAILED = typeof GET_CHANGES_FOR_SCENE_FAILED;

export const GET_CHANGES_FOR_EP_CHAR = '[Change] Get Changes For Ep Char';
type GET_CHANGES_FOR_EP_CHAR = typeof GET_CHANGES_FOR_EP_CHAR;

export const GET_CHANGES_FOR_EP_CHAR_COMPLETE =
	'[Change] Get Changes For Ep Char Complete';
type GET_CHANGES_FOR_EP_CHAR_COMPLETE = typeof GET_CHANGES_FOR_EP_CHAR_COMPLETE;

export const GET_CHANGES_FOR_EP_CHAR_FAILED =
	'[Change] Get Changes For Ep Char Failed';
type GET_CHANGES_FOR_EP_CHAR_FAILED = typeof GET_CHANGES_FOR_EP_CHAR_FAILED;

export const DELETE_CHANGE_FOR_EP_CHAR = '[Change] Delete Change For Ep Char';
type DELETE_CHANGE_FOR_EP_CHAR = typeof DELETE_CHANGE_FOR_EP_CHAR;

export const DELETE_CHANGE_FOR_EP_CHAR_COMPLETE =
	'[Change] Delete Change For Ep Char Complete';
type DELETE_CHANGE_FOR_EP_CHAR_COMPLETE = typeof DELETE_CHANGE_FOR_EP_CHAR_COMPLETE;

export const DELETE_CHANGE_FOR_EP_CHAR_FAILED =
	'[Change] Delete Change For Ep Char Failed';
type DELETE_CHANGE_FOR_EP_CHAR_FAILED = typeof DELETE_CHANGE_FOR_EP_CHAR_FAILED;

export const CREATE_CHANGE = '[Change] Create Change';
type CREATE_CHANGE = typeof CREATE_CHANGE;

export const CREATE_CHANGE_COMPLETE = '[Change] Create Change Complete';
type CREATE_CHANGE_COMPLETE = typeof CREATE_CHANGE_COMPLETE;

export const CREATE_CHANGE_FAILED = '[Change] Create Change Failed';
type CREATE_CHANGE_FAILED = typeof CREATE_CHANGE_FAILED;

export const CREATE_CHANGE_BY_SCENE = '[Change] Create Change By Scene';
type CREATE_CHANGE_BY_SCENE = typeof CREATE_CHANGE_BY_SCENE;

export const CREATE_CHANGE_BY_SCENE_COMPLETE =
	'[Change] Create Change By Scene Complete';
type CREATE_CHANGE_BY_SCENE_COMPLETE = typeof CREATE_CHANGE_BY_SCENE_COMPLETE;

export const CREATE_CHANGE_BY_SCENE_FAILED =
	'[Change] Create Change By Scene Failed';
type CREATE_CHANGE_BY_SCENE_FAILED = typeof CREATE_CHANGE_BY_SCENE_FAILED;

export const GET_CHANGE_EPISODIC_ITEMS_COMPLETE =
	'[Change] Get ChangeEpisodicItem Complete';
type GET_CHANGE_EPISODIC_ITEMS_COMPLETE = typeof GET_CHANGE_EPISODIC_ITEMS_COMPLETE;

export const LIST_COMPLETE = '[Change] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const SET_SORTING = '[Change] Set Sorting';
type SET_SORTING = typeof SET_SORTING;

export const UPDATE = '[Change] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[Change] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[Change] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export interface GetAction {
	changeId: number;
	type: GET;
}

export interface GetCompleteAction {
	change: Change;
	type: GET_COMPLETE;
}

export interface GetFailedAction {
	error: Error;
	type: GET_FAILED;
}

export interface DeleteCompleteAction {
	type: DELETE_COMPLETE;
	changes: Change[];
}

export interface GetChangesForChangeLookAction {
	changeLookId: number;
	type: GET_CHANGES_FOR_CHANGE_LOOK;
}

export interface GetChangesForChangeLookCompleteAction {
	changes: Change[];
	changeLookId: number;
	type: GET_CHANGES_FOR_CHANGE_LOOK_COMPLETE;
}

export interface GetChangesForChangeLookFailedAction {
	error: Error;
	type: GET_CHANGES_FOR_CHANGE_LOOK_FAILED;
}

export interface GetChangesForItemAction {
	type: GET_CHANGES_FOR_ITEM;
	episodicId: number;
	epItemId: number;
}

export interface GetChangesForItemCompleteAction {
	type: GET_CHANGES_FOR_ITEM_COMPLETE;
	changes: Change[];
	epItemId: number;
}

export interface GetChangesForItemFailedAction {
	type: GET_CHANGES_FOR_ITEM_FAILED;
	error: Error;
}

export interface GetChangesForSceneAction {
	type: GET_CHANGES_FOR_SCENE;
	prodId: number;
	sceneId: number;
}

export interface GetChangesForSceneCompleteAction {
	type: GET_CHANGES_FOR_SCENE_COMPLETE;
	changes: Change[];
	prodId: number;
}

export interface GetChangesForSceneFailedAction {
	type: GET_CHANGES_FOR_SCENE_FAILED;
	error: Error;
}

export interface GetChangesForEpCharAction {
	type: GET_CHANGES_FOR_EP_CHAR;
	episodicId: number;
	epCharId: number;
}

export interface GetChangesForEpCharCompleteAction {
	type: GET_CHANGES_FOR_EP_CHAR_COMPLETE;
	changes: Change[];
}

export interface GetChangesForEpCharFailedAction {
	type: GET_CHANGES_FOR_EP_CHAR_FAILED;
	error: Error;
}

export interface DeleteChangeForEpCharAction {
	type: DELETE_CHANGE_FOR_EP_CHAR;
	episodicId: number;
	epCharId: number;
	changeId: number;
}

export interface DeleteChangeForEpCharCompleteAction {
	type: DELETE_CHANGE_FOR_EP_CHAR_COMPLETE;
	epCharId: number;
	response: DeptSpecificChangeLookDeleteResponse<DEPARTMENTS.CM>;
}

export interface DeleteChangeForEpCharFailedAction {
	type: DELETE_CHANGE_FOR_EP_CHAR_FAILED;
	error: Error;
}

export interface CreateChangeAction {
	type: CREATE_CHANGE;
	episodicId: number;
	change: Partial<Change>;
}

export interface CreateChangeCompleteAction {
	type: CREATE_CHANGE_COMPLETE;
	change: Change[];
}

export interface CreateChangeFailedAction {
	type: CREATE_CHANGE_FAILED;
	error: Error;
}

export interface CreateChangeBySceneAction {
	type: CREATE_CHANGE_BY_SCENE;
	episodicId: number;
	change: Partial<Change>;
	oldChange?: Change;
	sceneId: number;
}

export interface CreateChangeBySceneCompleteAction {
	type: CREATE_CHANGE_BY_SCENE_COMPLETE;
	created: {
		Change: Change[];
		ChangeScene: ChangeScene[];
	};
	deleted: {
		ChangeScene: ChangeScene[];
	};
	updated: {
		Change: Change[];
	};
}

export interface CreateChangeBySceneFailedAction {
	type: CREATE_CHANGE_BY_SCENE_FAILED;
	error: Error;
}

export interface GetChangeEpisodicItemsCompleteAction {
	change: Change[];
	changeEpisodicItem: ChangeEpisodicItem[];
	type: GET_CHANGE_EPISODIC_ITEMS_COMPLETE;
}

export interface ListCompleteAction {
	changes: Change[];
	type: LIST_COMPLETE;
}

export interface SetSortingAction {
	sorting: Sorting[];
	type: SET_SORTING;
}

export interface UpdateAction {
	episodicId: number;
	prodId: number;
	changes: Partial<Change>[];
	type: UPDATE;
}

export interface UpdateCompleteAction {
	changes: Partial<Change>[];
	type: UPDATE_COMPLETE;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export function get(changeId: number): GetAction {
	return {
		changeId,
		type: GET,
	};
}

export function getComplete(change: Change): GetCompleteAction {
	return {
		change,
		type: GET_COMPLETE,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	};
}

export function deleteComplete(changes: Change[]): DeleteCompleteAction {
	return {
		changes,
		type: DELETE_COMPLETE,
	};
}

export function getChangesForChangeLook(
	changeLookId: number
): GetChangesForChangeLookAction {
	return {
		changeLookId,
		type: GET_CHANGES_FOR_CHANGE_LOOK,
	};
}

export function getChangesForChangeLookComplete(
	changes: Change[],
	changeLookId: number
): GetChangesForChangeLookCompleteAction {
	return {
		changes,
		changeLookId,
		type: GET_CHANGES_FOR_CHANGE_LOOK_COMPLETE,
	};
}

export function getChangesForChangeLookFailed(
	error: Error
): GetChangesForChangeLookFailedAction {
	return {
		error,
		type: GET_CHANGES_FOR_CHANGE_LOOK_FAILED,
	};
}

export function getChangesForItem(
	episodicId: number,
	epItemId: number
): GetChangesForItemAction {
	return {
		episodicId,
		epItemId,
		type: GET_CHANGES_FOR_ITEM,
	};
}

export function getChangesForItemComplete(
	changes: Change[],
	epItemId: number
): GetChangesForItemCompleteAction {
	return {
		changes,
		epItemId,
		type: GET_CHANGES_FOR_ITEM_COMPLETE,
	};
}

export function getChangesForItemFailed(
	error: Error
): GetChangesForItemFailedAction {
	return {
		error,
		type: GET_CHANGES_FOR_ITEM_FAILED,
	};
}

export function getChangesForScene(
	prodId: number,
	sceneId: number
): GetChangesForSceneAction {
	return {
		prodId,
		sceneId,
		type: GET_CHANGES_FOR_SCENE,
	};
}

export function getChangesForSceneComplete(
	changes: Change[],
	prodId: number
): GetChangesForSceneCompleteAction {
	return {
		changes,
		prodId,
		type: GET_CHANGES_FOR_SCENE_COMPLETE,
	};
}

export function getChangesForSceneFailed(
	error: Error
): GetChangesForSceneFailedAction {
	return {
		error,
		type: GET_CHANGES_FOR_SCENE_FAILED,
	};
}

export function getChangesForEpChar(
	episodicId: number,
	epCharId: number
): GetChangesForEpCharAction {
	return {
		episodicId,
		epCharId,
		type: GET_CHANGES_FOR_EP_CHAR,
	};
}

export function getChangesForEpCharComplete(
	changes: Change[]
): GetChangesForEpCharCompleteAction {
	return {
		changes,
		type: GET_CHANGES_FOR_EP_CHAR_COMPLETE,
	};
}

export function getChangesForEpCharFailed(
	error: Error
): GetChangesForEpCharFailedAction {
	return {
		error,
		type: GET_CHANGES_FOR_EP_CHAR_FAILED,
	};
}

export function deleteChangeForEpChar(
	episodicId: number,
	epCharId: number,
	changeId: number
): DeleteChangeForEpCharAction {
	return {
		episodicId,
		epCharId,
		changeId,
		type: DELETE_CHANGE_FOR_EP_CHAR,
	};
}

export function deleteChangeForEpCharComplete(
	epCharId: number,
	response: DeptSpecificChangeLookDeleteResponse<DEPARTMENTS.CM>
): DeleteChangeForEpCharCompleteAction {
	return {
		epCharId,
		response,
		type: DELETE_CHANGE_FOR_EP_CHAR_COMPLETE,
	};
}

export function deleteChangeForEpCharFailed(
	error: Error
): DeleteChangeForEpCharFailedAction {
	return {
		error,
		type: DELETE_CHANGE_FOR_EP_CHAR_FAILED,
	};
}

export function createChange(
	episodicId: number,
	change: Partial<Change>
): CreateChangeAction {
	return {
		episodicId,
		change,
		type: CREATE_CHANGE,
	};
}

export function createChangeComplete(
	change: Change[]
): CreateChangeCompleteAction {
	return {
		change,
		type: CREATE_CHANGE_COMPLETE,
	};
}

export function createChangeFailed(error: Error): CreateChangeFailedAction {
	return {
		error,
		type: CREATE_CHANGE_FAILED,
	};
}

export function createChangeByScene(
	episodicId: number,
	change: Partial<Change>,
	sceneId: number,
	oldChange?: Change
): CreateChangeBySceneAction {
	return {
		episodicId,
		change,
		sceneId,
		oldChange,
		type: CREATE_CHANGE_BY_SCENE,
	};
}

export function createChangeBySceneComplete(
	created: { Change: Change[]; ChangeScene: ChangeScene[] },
	deleted: { ChangeScene: ChangeScene[] },
	updated: { Change: Change[] }
): CreateChangeBySceneCompleteAction {
	return {
		created,
		deleted,
		updated,
		type: CREATE_CHANGE_BY_SCENE_COMPLETE,
	};
}

export function createChangeBySceneFailed(
	error: Error
): CreateChangeBySceneFailedAction {
	return {
		error,
		type: CREATE_CHANGE_BY_SCENE_FAILED,
	};
}

export function getChangeEpisodicItemsComplete(
	change: Change[],
	changeEpisodicItem: ChangeEpisodicItem[]
): GetChangeEpisodicItemsCompleteAction {
	return {
		change,
		changeEpisodicItem,
		type: GET_CHANGE_EPISODIC_ITEMS_COMPLETE,
	};
}

export function listComplete(changes: Change[]): ListCompleteAction {
	return {
		changes,
		type: LIST_COMPLETE,
	};
}

export function setSorting(sorting: Sorting[]): SetSortingAction {
	return {
		sorting,
		type: SET_SORTING,
	};
}

export function update(
	episodicId: number,
	prodId: number,
	changes: Partial<Change>[]
): UpdateAction {
	return {
		episodicId,
		prodId,
		changes,
		type: UPDATE,
	};
}

export function updateComplete(changes: Change[]): UpdateCompleteAction {
	return {
		changes,
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export type Action =
	| GetAction
	| GetCompleteAction
	| GetFailedAction
	| DeleteCompleteAction
	| GetChangesForChangeLookAction
	| GetChangesForChangeLookCompleteAction
	| GetChangesForChangeLookFailedAction
	| GetChangesForItemAction
	| GetChangesForItemCompleteAction
	| GetChangesForItemFailedAction
	| GetChangesForSceneAction
	| GetChangesForSceneCompleteAction
	| GetChangesForSceneFailedAction
	| GetChangesForEpCharAction
	| GetChangesForEpCharCompleteAction
	| GetChangesForEpCharFailedAction
	| DeleteChangeForEpCharAction
	| DeleteChangeForEpCharCompleteAction
	| DeleteChangeForEpCharFailedAction
	| CreateChangeAction
	| CreateChangeCompleteAction
	| CreateChangeFailedAction
	| CreateChangeBySceneAction
	| CreateChangeBySceneCompleteAction
	| CreateChangeBySceneFailedAction
	| GetChangeEpisodicItemsCompleteAction
	| ListCompleteAction
	| SetSortingAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction;
