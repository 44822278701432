import { Change, DEPARTMENTS } from 'sos-models';

export const MODIFY_CHANGE_NUM = '[Character View] Modify Change Number';
type MODIFY_CHANGE_NUM = typeof MODIFY_CHANGE_NUM;

export const CANCEL_CM_CHANGE_EDIT = '[Character View] Cancel CM Change Edits';
type CANCEL_CM_CHANGE_EDIT = typeof CANCEL_CM_CHANGE_EDIT;

export const SET_CURRENT_DEPT_FOR_PHOTO_SIDEBAR =
	'[Character View] Set Current Department For Photo Sidebar';
type SET_CURRENT_DEPT_FOR_PHOTO_SIDEBAR = typeof SET_CURRENT_DEPT_FOR_PHOTO_SIDEBAR;

export const SET_CURRENT_DEPT_FOR_CHANGE_PROP_TABLES =
	'[Character View] Set Current Department For Change Prop Tables';
type SET_CURRENT_DEPT_FOR_CHANGE_PROP_TABLES = typeof SET_CURRENT_DEPT_FOR_CHANGE_PROP_TABLES;

export const UPDATE_CHANGE_NUM = '[Character View] Update Change Numbers';
type UPDATE_CHANGE_NUM = typeof UPDATE_CHANGE_NUM;

export const UPDATE_CHANGE_NUM_FAILED =
	'[Character View] Update Change Numbers Failed';
type UPDATE_CHANGE_NUM_FAILED = typeof UPDATE_CHANGE_NUM_FAILED;

export const UPDATE_CHANGE_NUM_COMPLETE =
	'[Character View] Update Change Numbers Complete';
type UPDATE_CHANGE_NUM_COMPLETE = typeof UPDATE_CHANGE_NUM_COMPLETE;

export interface ModifyChangeNumAction {
	type: MODIFY_CHANGE_NUM;
	isIncrement: boolean;
	changeId: number;
	prodId: number;
}

export interface CancelCmChangeAction {
	changes: Change[];
	type: CANCEL_CM_CHANGE_EDIT;
}

export interface SetCurrentDeptForPhotoSidebarAction {
	departmentId: DEPARTMENTS;
	type: SET_CURRENT_DEPT_FOR_PHOTO_SIDEBAR;
}

export interface SetCurrentDeptForChangePropTablesAction {
	departmentId: DEPARTMENTS;
	type: SET_CURRENT_DEPT_FOR_CHANGE_PROP_TABLES;
}

export interface UpdateMultipleAction {
	episodicId: number;
	prodId: number;
	changes: Change[];
	type: UPDATE_CHANGE_NUM;
}

export interface UpdateMultipleCompleteAction {
	changes: Change[];
	type: UPDATE_CHANGE_NUM_COMPLETE;
}

export interface UpdateMultipleFailedAction {
	error: Error;
	type: UPDATE_CHANGE_NUM_FAILED;
}

export function modifyChangeNum(
	isIncrement: boolean,
	changeId: number,
	prodId: number
): ModifyChangeNumAction {
	return {
		isIncrement,
		changeId,
		prodId,
		type: MODIFY_CHANGE_NUM,
	};
}

export function cancelCmChanges(changes: Change[]): CancelCmChangeAction {
	return {
		changes,
		type: CANCEL_CM_CHANGE_EDIT,
	};
}

export function setCurrentDepartmentForPhotoSidebar(
	departmentId: DEPARTMENTS
): SetCurrentDeptForPhotoSidebarAction {
	return {
		departmentId,
		type: SET_CURRENT_DEPT_FOR_PHOTO_SIDEBAR,
	};
}

export function setCurrentDepartmentForChangePropTables(
	departmentId: DEPARTMENTS
): SetCurrentDeptForChangePropTablesAction {
	return {
		departmentId,
		type: SET_CURRENT_DEPT_FOR_CHANGE_PROP_TABLES,
	};
}

export function updateCmChanges(
	episodicId: number,
	prodId: number,
	changes: Change[]
): UpdateMultipleAction {
	return {
		episodicId,
		prodId,
		changes,
		type: UPDATE_CHANGE_NUM,
	};
}

export function updateFailed(error: Error): UpdateMultipleFailedAction {
	return {
		error,
		type: UPDATE_CHANGE_NUM_FAILED,
	};
}

export function updateComplete(
	changes: Change[]
): UpdateMultipleCompleteAction {
	return {
		changes,
		type: UPDATE_CHANGE_NUM_COMPLETE,
	};
}

export type Action =
	| ModifyChangeNumAction
	| CancelCmChangeAction
	| SetCurrentDeptForPhotoSidebarAction
	| SetCurrentDeptForChangePropTablesAction
	| UpdateMultipleAction
	| UpdateMultipleCompleteAction
	| UpdateMultipleFailedAction;
