import { normalize } from 'normalizr';
import { SetPermission } from 'sos-models';
import * as episodicActions from '../episodics/episodic.actions';
import * as permissionActions from '../permissions/permission.actions';
import { setPermissionSchema } from '../schemas';
import {
	ActionStatus,
	createReducer,
	failed,
	loading,
	uniqueArrayMerge,
} from '../utils';

export interface State {
	entities: { [id: number]: SetPermission };
	ids: number[];
	loading: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	loading: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	permissionActions.Action | episodicActions.Action
>(initial, {
	[permissionActions.GET]: loading<State>('loading'),
	[permissionActions.GET_COMPLETE]: getPermissionComplete,
	[permissionActions.GET_FAILED]: failed<State>('loading'),
	[episodicActions.GET_DEPT_PERMS]: loading<State>('loading'),
	[episodicActions.GET_DEPT_PERMS_COMPLETE]: getDeptPermsComplete,
	[episodicActions.GET_DEPT_PERMS_FAILED]: failed<State>('loading'),
});

function getPermissionComplete(
	state: State,
	action: permissionActions.GetCompleteAction
): State {
	const setPermissions = action.permissions.map((p) => p.setPermission);
	const normalized = normalize(setPermissions, [setPermissionSchema]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.setPermissions,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		loading: ActionStatus.Complete,
	};
}

function getDeptPermsComplete(
	state: State,
	action: episodicActions.GetDeptPermsCompleteAction
): State {
	if (!action.permissions.SetPermission) {
		return state;
	}
	const normalized = normalize(action.permissions.SetPermission, [
		setPermissionSchema,
	]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.setPermissions,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		loading: ActionStatus.Complete,
	};
}
