import { InventoryPictureListResponse } from 'sos-models';
import axios from '../../config/axios';

export async function list(
	episodicId: number,
	departmentId: number,
	inventoryId: number
): Promise<InventoryPictureListResponse> {
	const { data } = await axios.get<InventoryPictureListResponse>(
		`/api/episodics/${episodicId}/inventory/${departmentId}/${inventoryId}/pictures`
	);
	return data;
}
