import {
	ChangeEpisodicItem,
	ChangeEpisodicItemCreateResponse,
	ChangeEpisodicItemUpdateResponse,
	EpisodicItem,
	EpisodicItemsForChangeListResponse,
} from 'sos-models';
import { GenPartial } from 'typeormgen';
import axios from '../../config/axios';

export async function create(
	changeId: number,
	prodId: number,
	episodicId: number,
	episodicItemId: number
): Promise<ChangeEpisodicItemCreateResponse> {
	const { data } = await axios.post<ChangeEpisodicItemCreateResponse>(
		`/api/episodics/${episodicId}/productions/${prodId}/changes/${changeId}/change-episodic-items/${episodicItemId}`
	);
	return data;
}
export async function createChangeEpisodicItem(
	changeId: number,
	prodId: number,
	episodicId: number,
	episodicItem: EpisodicItem
): Promise<ChangeEpisodicItemCreateResponse> {
	const { data } = await axios.post<ChangeEpisodicItemCreateResponse>(
		`/api/episodics/${episodicId}/productions/${prodId}/changes/${changeId}/change-episodic-items`,
		episodicItem
	);
	return data;
}

export async function update(
	episodicId: number,
	prodId: number,
	changeId: number,
	payload: GenPartial<ChangeEpisodicItem>[]
) {
	const { data } = await axios.put<ChangeEpisodicItemUpdateResponse>(
		`/api/episodics/${episodicId}/productions/${prodId}/changes/${changeId}/change-episodic-items`,
		payload
	);
	return data;
}

export async function deleteChangeEpisodicItem(
	changeId: number,
	prodId: number,
	episodicId: number,
	episodicItemId: number
) {
	const { data } = await axios.delete(
		`/api/episodics/${episodicId}/productions/${prodId}/changes/${changeId}/change-episodic-items/${episodicItemId}`
	);
	return data;
}

export async function getEpisodicItemsForChange(
	episodicId: number,
	changeId: number
): Promise<EpisodicItemsForChangeListResponse> {
	const { data } = await axios.get<EpisodicItemsForChangeListResponse>(
		`/api/episodics/${episodicId}/changes/${changeId}/episodic-items`
	);
	return data;
}
