import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { DEPARTMENTS, DeptSpecificChangeSceneGetResponse } from 'sos-models';
import * as actions from './change-scene.actions';
import * as api from './change-scene.api';
import * as logger from '../../logger';
import * as charSceneActions from '../char-scenes/char-scene.actions';

export function* getForScene({
	prodId,
	sceneId,
}: actions.GetChangeScenesForSceneAction) {
	try {
		const changes = yield call(api.getForScene, prodId, sceneId);
		yield put(actions.getChangeScenesForSceneComplete(changes, sceneId));
	} catch (err) {
		yield put(actions.getChangeScenesForSceneFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading the change table. Please refresh to try again.'
			);
		} else {
			logger.error(err);
		}
	}
}

export function* listForEpChar({
	episodicId,
	epCharId,
}: actions.ListForEpCharAction) {
	try {
		const {
			listed,
		}: DeptSpecificChangeSceneGetResponse<DEPARTMENTS.CM> = yield call(
			api.getForEpCharId,
			episodicId,
			epCharId
		);
		yield put(actions.listForEpCharComplete(listed.ChangeScene));
	} catch (err) {
		yield put(actions.listForEpCharFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading the change table. Please refresh to try again.'
			);
		} else {
			logger.error(err);
		}
	}
}

export function* createAndDeleteExisting({
	episodicId,
	changeScenes,
}: actions.CreateAndDeleteExistingAction) {
	try {
		const { created, deleted, updated } = yield call(
			api.createAndDeleteExisting,
			episodicId,
			DEPARTMENTS.CM,
			changeScenes
		);
		if (updated.CharScene.length > 0) {
			yield put(charSceneActions.updateComplete(updated.CharScene));
		}
		yield put(
			actions.createAndDeleteExistingComplete(created, deleted, updated)
		);
	} catch (err) {
		yield put(actions.createAndDeleteExistingFailed(err));
		logger.responseError(err, 'creating this change', true);
	}
}

export function* update({ episodicId, changeScene }: actions.UpdateAction) {
	try {
		const updated = yield call(api.update, episodicId, changeScene);
		yield put(actions.updateComplete(updated));
	} catch (err) {
		yield put(actions.updateFailed(err));
		logger.responseError(err, 'updating this change', true);
	}
}

function* saga() {
	yield takeLatest(actions.GET_CHANGE_SCENES_FOR_SCENE, getForScene);
	yield takeLatest(actions.LIST_FOR_EP_CHAR, listForEpChar);
	yield takeLatest(actions.CREATE_AND_DELETE_EXISTING, createAndDeleteExisting);
	yield takeLatest(actions.UPDATE, update);
}

export default saga;
