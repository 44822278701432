import { CmStatus } from 'sos-models';
import axios from '../../config/axios';

export async function create(
	cmStatus: Partial<CmStatus>
): Promise<Partial<CmStatus>> {
	return axios
		.post<Partial<CmStatus>>(`/api/cm-statuses`, cmStatus)
		.then((response) => response.data);
}

export async function destroy(cmStatus: Partial<CmStatus>) {
	return axios
		.delete(`/api/cm-statuses/${cmStatus.id}`)
		.then((response) => response.data);
}

export async function update(
	cmStatus: Partial<CmStatus>
): Promise<Partial<CmStatus>> {
	return axios
		.put<Partial<CmStatus>>(`/api/cm-statuses/${cmStatus.id}`, cmStatus)
		.then((response) => response.data);
}
