import { normalize } from 'normalizr';
import { SetProd } from 'sos-models';
import * as actions from './set-prod.actions';
import { setProdSchema } from '../schemas';
import { ActionStatus, createReducer, failed, loading } from '../utils';

export interface State {
	entities: { [id: number]: SetProd };
	ids: number[];
	listStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	listStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.LIST]: loading<State>('listStatus'),
	[actions.LIST_COMPLETE]: listComplete,
	[actions.LIST_FAILED]: failed<State>('listStatus'),
});

function listComplete(state: State, action: actions.ListCompleteAction): State {
	const normalized = normalize(action.setProds, [setProdSchema]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.setProds,
		},
		ids: normalized.result,
		listStatus: ActionStatus.Complete,
	};
}
