import { EditStateType, TableType } from 'sos-models';

export const SET_EDIT_ALL_TYPE = '[Table] Set Edit All Type';
type SET_EDIT_ALL_TYPE = typeof SET_EDIT_ALL_TYPE;

export interface SetEditAllTypeAction {
	type: SET_EDIT_ALL_TYPE;
	tableType: TableType;
	editState: EditStateType;
}

export function setEditAllType(
	tableType: TableType,
	editState: EditStateType
): SetEditAllTypeAction {
	return {
		tableType,
		editState,
		type: SET_EDIT_ALL_TYPE,
	};
}

export type Action = SetEditAllTypeAction;
