import { ActivityCard } from 'sos-models';
import { ActionStatus, createReducer, failed, loading } from '../utils';
import * as actions from './activity-feed.actions';

export interface State {
	activityCards: ActivityCard[];
	getStatus: ActionStatus;
}

const initial: State = {
	activityCards: [],
	getStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.GET_COMPLETE]: getComplete,
	[actions.GET_FAILED]: failed<State>('getStatus'),
	[actions.GET]: loading<State>('getStatus'),
});

function getComplete(state: State, action: actions.GetCompleteAction): State {
	let activityCards: ActivityCard[] = [];
	if (action.offset === 0) {
		activityCards = action.activityCards;
	} else {
		activityCards = [...state.activityCards, ...action.activityCards];
	}
	return {
		...state,
		activityCards,
		getStatus: ActionStatus.Complete,
	};
}