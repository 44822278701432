import { normalize } from 'normalizr';
import { EpisodicItem, EpisodicProp, SetPiece, Vendor } from 'sos-models';
import * as inventoryActions from '../inventory/inventory.actions';
import { vendorSchema } from '../schemas';
import { ActionStatus, createReducer, failed, loading } from '../utils';
import * as actions from './vendor.actions';

export interface State {
	entities: { [id: number]: Vendor };
	ids: number[];
	createStatus: ActionStatus;
	listStatus: ActionStatus;
	getStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	createStatus: ActionStatus.Inactive,
	listStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	actions.Action | inventoryActions.Action
>(initial, {
	[actions.CREATE]: loading<State>('createStatus'),
	[actions.CREATE_COMPLETE]: createComplete,
	[actions.CREATE_FAILED]: failed<State>('createStatus'),
	[actions.LIST]: loading<State>('listStatus'),
	[actions.LIST_COMPLETE]: listComplete,
	[actions.LIST_FAILED]: failed<State>('listStatus'),
	[inventoryActions.GET]: loading<State>('getStatus'),
	[inventoryActions.GET_COMPLETE]: inventoryGetComplete,
	[inventoryActions.GET_FAILED]: failed<State>('getStatus'),
});

function createComplete(
	state: State,
	action: actions.CreateCompleteAction
): State {
	const normalized = normalize(action.vendor, vendorSchema);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.vendors,
		},
		ids: [...state.ids, normalized.result],
		createStatus: ActionStatus.Complete,
	};
}

function listComplete(state: State, action: actions.ListCompleteAction): State {
	const normalized = normalize(action.vendors, [vendorSchema]);
	return {
		...state,
		entities: normalized.entities.vendors,
		ids: normalized.result,
		listStatus: ActionStatus.Complete,
	};
}

function inventoryGetComplete(
	state: State,
	action: inventoryActions.GetCompleteAction
): State {
	const inventory = action.inventory as EpisodicItem | EpisodicProp | SetPiece;
	if (!inventory.vendor) {
		return {
			...state,
			getStatus: ActionStatus.Complete,
		};
	}
	const normalized = normalize(inventory.vendor, vendorSchema);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.vendors,
		},
		getStatus: ActionStatus.Complete,
	};
}
