import { createSlice } from '@reduxjs/toolkit';
import { Account } from 'sos-models';
import { accountSchema } from '../schemas';
import { BaseReducerState, listComplete } from '../utils';

export interface State extends BaseReducerState<Account> {
	entities: { [id: number]: Account };
	ids: number[];
}

const initialState: State = {
	entities: {},
	ids: [],
};

export const accountSlice = createSlice({
	name: 'account',
	initialState,
	reducers: {
		listComplete: () => {
			listComplete<
				Account,
				State,
				{
					type: 'listComplete';
					accounts: Account[];
				}
			>('accounts', accountSchema);
		},
	},
	selectors: {
		getEntities: (state: State) => state.entities,
		getIds: (state: State) => state.ids,
	},
});

export default accountSlice.reducer;
