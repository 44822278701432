import { call, put, takeLatest } from 'redux-saga/effects';
import { ChangeUpdateResponse } from 'sos-models';
import * as actions from './character-view.actions';
import * as api from './character-view.api';
import * as logger from '../../logger';
import * as cmWrapBoxEpisodicItemActions from '../cm-wrap-box-episodic-items/cm-wrap-box-episodic-item.actions';

export function* update({
	episodicId,
	prodId,
	changes,
}: actions.UpdateMultipleAction) {
	try {
		const result: ChangeUpdateResponse = yield call(
			api.update,
			episodicId,
			prodId,
			changes
		);
		if (result.updated.Change) {
			yield put(actions.updateComplete(result.updated.Change));
		}
		if (result.created.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.updateComplete(
					result.created.CmWrapBoxEpisodicItem
				)
			);
		}
	} catch (err) {
		yield put(actions.updateFailed(err));
		logger.responseError(err, 'loading the characters', true);
	}
}

function* saga() {
	yield takeLatest(actions.UPDATE_CHANGE_NUM, update);
}

export default saga;
