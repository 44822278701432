import { Production } from 'sos-models';

export const SET_PROD_DROPDOWN =
	'[AddBackgroundCharactersToSceneModal] Set Prod Dropdown';
type SET_PROD_DROPDOWN = typeof SET_PROD_DROPDOWN;

export const SET_BG_CHAR_COUNT =
	'[AddBackgroundCharactersToSceneModal] Set Bg Char Count';
type SET_BG_CHAR_COUNT = typeof SET_BG_CHAR_COUNT;

export const CLEAR_BG_CHAR_SEARCH =
	'[AddBackgroundCharactersToSceneModal] Clear Bg Char Search';
type CLEAR_BG_CHAR_SEARCH = typeof CLEAR_BG_CHAR_SEARCH;

export interface SetProdDropdownAction {
	type: SET_PROD_DROPDOWN;
	prod: Production;
}

export interface SetBgCharCountAction {
	type: SET_BG_CHAR_COUNT;
	epCharId: number;
	sceneId: number;
	count: number;
}

export interface ClearBgCharSearchAction {
	type: CLEAR_BG_CHAR_SEARCH;
}

export function setBgCharCount(
	epCharId: number,
	sceneId: number,
	count: number
): SetBgCharCountAction {
	return {
		epCharId,
		sceneId,
		count,
		type: SET_BG_CHAR_COUNT,
	};
}

export function setProdDropdown(prod: Production): SetProdDropdownAction {
	return {
		prod,
		type: SET_PROD_DROPDOWN,
	};
}

export function clearBgCharSearch() {
	return {
		type: CLEAR_BG_CHAR_SEARCH,
	};
}

export type Action =
	| SetBgCharCountAction
	| SetProdDropdownAction
	| ClearBgCharSearchAction;
