import { omit, without } from 'lodash';
import { normalize } from 'normalizr';
import { DEPARTMENTS, HaChangeScene, SortOrder } from 'sos-models';
import * as actions from './ha-change-scene.actions';
import * as charSceneActions from '../char-scenes/char-scene.actions';
import * as deptChangeSceneActions from '../dept-change-scenes/dept-change-scene.actions';
import * as haChangeActions from '../ha-changes/ha-change.actions';
import { haChangeSceneSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createComplete,
	createReducer,
	destroyComplete,
	failed,
	listComplete,
	loading,
	uniqueArrayMerge,
} from '../utils';
import { SceneSortBy } from '@synconset/sorting';

export interface State extends BaseReducerState<HaChangeScene> {
	createAndDeleteExistingStatus: ActionStatus;
	createHaChangeBySceneStatus: ActionStatus;
	getForHaChangeSceneStatus: ActionStatus;
	getForHaChangeStatus: ActionStatus;
	listForEpCharStatus: ActionStatus;
	sortBy: SceneSortBy;
	sortOrder: SortOrder;
	updateStatus: ActionStatus;
}

export const initial: State = {
	createAndDeleteExistingStatus: ActionStatus.Inactive,
	createHaChangeBySceneStatus: ActionStatus.Inactive,
	entities: {},
	getForHaChangeSceneStatus: ActionStatus.Inactive,
	getForHaChangeStatus: ActionStatus.Inactive,
	ids: [],
	listForEpCharStatus: ActionStatus.Inactive,
	sortBy: 'name',
	sortOrder: 'asc',
	updateStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	| actions.Action
	| charSceneActions.Action
	| haChangeActions.Action
	| deptChangeSceneActions.Action
>(initial, {
	[actions.LIST_COMPLETE]: listComplete<
		HaChangeScene,
		State,
		actions.ListCompleteAction
	>('haChangeScenes', haChangeSceneSchema),
	[actions.GET_HA_CHANGE_SCENES_FOR_SCENE]: loading<State>(
		'getForHaChangeSceneStatus'
	),
	[actions.GET_HA_CHANGE_SCENES_FOR_SCENE_COMPLETE]: getHaChangesForSceneComplete,
	[actions.GET_HA_CHANGE_SCENES_FOR_SCENE_FAILED]: failed<State>(
		'getForHaChangeSceneStatus'
	),
	[actions.LIST_FOR_EP_CHAR]: loading<State>('listForEpCharStatus'),
	[actions.LIST_FOR_EP_CHAR_COMPLETE]: listForEpCharComplete,
	[actions.LIST_FOR_EP_CHAR_FAILED]: failed<State>('listForEpCharStatus'),
	[actions.CREATE_AND_DELETE_EXISTING]: loading<State>(
		'createAndDeleteExistingStatus'
	),
	[actions.CREATE_AND_DELETE_EXISTING_COMPLETE]: createAndDeleteExistingComplete,
	[actions.CREATE_AND_DELETE_EXISTING_FAILED]: failed<State>(
		'createAndDeleteExistingStatus'
	),
	[haChangeActions.CREATE_HA_CHANGE_BY_SCENE]: loading<State>(
		'createHaChangeBySceneStatus'
	),
	[haChangeActions.CREATE_HA_CHANGE_BY_SCENE_COMPLETE]: createHaChangeBySceneComplete,
	[haChangeActions.CREATE_HA_CHANGE_BY_SCENE_FAILED]: failed<State>(
		'createHaChangeBySceneStatus'
	),
	[actions.CREATE_COMPLETE]: createComplete<
		HaChangeScene,
		State,
		actions.CreateCompleteAction
	>('haChangeScenes', haChangeSceneSchema),
	[actions.DELETE_COMPLETE]: destroyComplete<
		HaChangeScene,
		State,
		actions.DeleteCompleteAction
	>('haChangeScenes'),
	[actions.UPDATE]: loading<State>('updateStatus'),
	[actions.UPDATE_COMPLETE]: updateComplete,
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
	[charSceneActions.DELETE_CHANGE_SCENE_AND_CHAR_SCENE_BY_SCENE_ID]: deleteHaChangeSceneAndCharSceneForScene,
	[deptChangeSceneActions.DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID_COMPLETE]: deleteHaChangeSceneByIdAndDeptIdComplete,
	[deptChangeSceneActions.SET_SORTING]: setSorting,
});

function deleteHaChangeSceneByIdAndDeptIdComplete(
	state: State,
	{
		changeSceneId,
		deptId,
	}: deptChangeSceneActions.DeleteChangeSceneByIdAndDeptIdCompleteAction
): State {
	if (deptId !== DEPARTMENTS.HA) {
		return state;
	}
	const entities = omit(state.entities, changeSceneId);
	const ids = without(state.ids, changeSceneId);
	return {
		...state,
		entities,
		ids,
	};
}

function deleteHaChangeSceneAndCharSceneForScene(
	state: State,
	{ sceneId }: charSceneActions.DeleteChangeSceneAndCharSceneBySceneIdAction
): State {
	const ids = Object.keys(state.entities)
		.filter(key => state.entities[key].scene_id === sceneId)
		.map(id => Number(id));
	const entities = omit(state.entities, ids);

	return {
		...state,
		entities,
		ids,
	};
}

function getHaChangesForSceneComplete(
	state: State,
	action: actions.GetHaChangeScenesForSceneCompleteAction
): State {
	const normalized = normalize(action.haChangeScenes, [haChangeSceneSchema]);

	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.haChangeScenes,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		getForHaChangeSceneStatus: ActionStatus.Complete,
	};
}

function createAndDeleteExistingComplete(
	state: State,
	{ created, deleted, updated }: actions.CreateAndDeleteExistingCompleteAction
): State {
	const normalizedCreatedHaChangeScenes = normalize(created.HaChangeScene, [
		haChangeSceneSchema,
	]);
	const normalizedUpdatedHaChangeScenes = normalize(updated.HaChangeScene, [
		haChangeSceneSchema,
	]);
	const updatedEntities: { [id: number]: HaChangeScene } = {
		...state.entities,
		...normalizedCreatedHaChangeScenes.entities.haChangeScenes,
	};
	const deletedIds: number[] = [];
	deleted.HaChangeScene.forEach(cs => {
		deletedIds.push(cs.id);
		updatedEntities[cs.id] = null;
	});
	const ids: number[] = without(state.ids, ...deletedIds).concat(
		normalizedCreatedHaChangeScenes.result
	);

	return {
		...state,
		entities: {
			...updatedEntities,
			...normalizedUpdatedHaChangeScenes.entities.haChangeScenes,
		},
		ids,
		createAndDeleteExistingStatus: ActionStatus.Complete,
	};
}

function createHaChangeBySceneComplete(
	state: State,
	{ created, deleted }: haChangeActions.CreateHaChangeBySceneCompleteAction
): State {
	const normalized = normalize(created.HaChangeScene, [haChangeSceneSchema]);
	const updatedEntities = {
		...state.entities,
		...normalized.entities.haChangeScenes,
	};
	const deletedIds: number[] = [];
	deleted.HaChangeScene.forEach(cs => {
		deletedIds.push(cs.id);
		updatedEntities[cs.id] = null;
	});
	return {
		...state,
		entities: updatedEntities,
		ids: without(state.ids.concat(normalized.result), ...deletedIds),
		createHaChangeBySceneStatus: ActionStatus.Complete,
	};
}

function setSorting(
	state: State,
	{ departmentId, sorting }: deptChangeSceneActions.SetSortingAction
): State {
	// DX grid sends up sorting info as an array. For scenes we're only ever
	// going to have one element in that array. If not, it's a problem.
	if (departmentId !== DEPARTMENTS.HA) {
		return state;
	}
	const sortInfo = sorting[0];
	return {
		...state,
		sortBy: sortInfo.columnName,
		sortOrder: sortInfo.direction,
	};
}

function updateComplete(
	state: State,
	action: actions.UpdateCompleteAction
): State {
	const normalized = normalize(action.updated, haChangeSceneSchema);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.haChangeScenes,
		},
		updateStatus: ActionStatus.Complete,
	};
}

function listForEpCharComplete(
	state: State,
	action: actions.ListForEpCharCompleteAction
): State {
	const normalized = normalize(action.haChangeScenes, [haChangeSceneSchema]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.haChangeScenes,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		listForEpCharStatus: ActionStatus.Complete,
	};
}
