import { SetPieceSet } from 'sos-models';

export const CREATE_COMPLETE = '[SetPieceSet] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const DESTROY_COMPLETE = '[SetPieceSet] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const LIST_COMPLETE = '[SetPieceSet] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export interface CreateCompleteAction {
	setPieceSets: SetPieceSet[];
	type: CREATE_COMPLETE;
}

export interface DestroyCompleteAction {
	setPieceSets: SetPieceSet[];
	type: DESTROY_COMPLETE;
}

export interface ListCompleteAction {
	setPieceSets: SetPieceSet[];
	type: LIST_COMPLETE;
}

export function createComplete(
	setPieceSets: SetPieceSet[]
): CreateCompleteAction {
	return {
		setPieceSets,
		type: CREATE_COMPLETE,
	};
}

export function destroyComplete(
	setPieceSets: SetPieceSet[]
): DestroyCompleteAction {
	return {
		setPieceSets,
		type: DESTROY_COMPLETE,
	};
}

export function listComplete(setPieceSets: SetPieceSet[]): ListCompleteAction {
	return {
		setPieceSets,
		type: LIST_COMPLETE,
	};
}

export type Action =
	| CreateCompleteAction
	| DestroyCompleteAction
	| ListCompleteAction;
