export const FILTER = '[WrapBoxView] Filter';
type FILTER = typeof FILTER;

export interface ListOptions {
	episodicCharacterIds?: number[];
	setIds?: number[];
}

export interface FilterAction {
	options: ListOptions;
	type: FILTER;
}

export function filter(options: ListOptions): FilterAction {
	return {
		options,
		type: FILTER,
	};
}

export type Action = FilterAction;
