import { normalize } from 'normalizr';
import { ChangeLook, DeptEntityId } from 'sos-models';
import * as changeLookActions from './change-look.actions';
import { changeLookSchema } from '../schemas';
import {
	ActionStatus,
	complete,
	createReducer,
	failed,
	loading,
	NormalizationOutput,
} from '../utils';

export interface State {
	createStatus: ActionStatus;
	currentId: DeptEntityId;
	deleteStatus: ActionStatus;
	getStatus: ActionStatus;
	setCurrentStatus: ActionStatus;
	updateStatus: ActionStatus;
	current: {
		departmentId: number;
		changeLookId: number;
		next: number;
		previous: number;
	};
}

const initial: State = {
	createStatus: ActionStatus.Inactive,
	currentId: { id: null, department: null },
	deleteStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
	setCurrentStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
	current: {
		departmentId: null,
		changeLookId: null,
		next: null,
		previous: null,
	},
};

export const reducer = createReducer<State, changeLookActions.Action>(initial, {
	[changeLookActions.CREATE]: loading<State>('createStatus'),
	[changeLookActions.CREATE_COMPLETE]: complete<State>('createStatus'),
	[changeLookActions.CREATE_FAILED]: failed<State>('createStatus'),
	[changeLookActions.GET]: loading<State>('getStatus'),
	[changeLookActions.GET_COMPLETE]: getComplete,
	[changeLookActions.GET_FAILED]: failed<State>('getStatus'),
	[changeLookActions.SET_CURRENT]: setCurrent,
	[changeLookActions.UPDATE]: loading<State>('updateStatus'),
	[changeLookActions.UPDATE_COMPLETE]: complete<State>('updateStatus'),
	[changeLookActions.UPDATE_FAILED]: failed<State>('updateStatus'),
	[changeLookActions.DELETE]: loading<State>('deleteStatus'),
	[changeLookActions.DELETE_COMPLETE]: complete<State>('deleteStatus'),
	[changeLookActions.DELETE_FAILED]: failed<State>('deleteStatus'),
});

function setCurrent(
	state: State,
	action: changeLookActions.SetCurrentAction
): State {
	return {
		...state,
		currentId: {
			id: action.changeLookId,
			department: action.departmentId,
		},
		setCurrentStatus: ActionStatus.Complete,
	};
}

function getComplete(
	state: State,
	action: changeLookActions.GetCompleteAction
): State {
	const { departmentId, next, previous } = action;
	const normalized: NormalizationOutput<ChangeLook> = normalize(
		action.changeLooks,
		[changeLookSchema]
	);
	return {
		...state,
		getStatus: ActionStatus.Complete,
		currentId: {
			id: normalized.result[0],
			department: departmentId,
		},
		current: {
			changeLookId: normalized.result[0],
			departmentId,
			next,
			previous,
		},
	};
}
