import { MuLookPictureDeleteResponse } from 'sos-models';
import axios from '../../config/axios';

export async function destroy(
	episodicId: number,
	muLookPictureId: number
): Promise<MuLookPictureDeleteResponse> {
	const { data } = await axios.delete(
		`/api/episodics/${episodicId}/mu-look-pictures/${muLookPictureId}`
	);
	return data;
}
