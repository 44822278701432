import { AHDeptPermissions, DEPARTMENTS } from 'sos-models';
import * as actions from './asset-hub.actions';
import {
	ActionStatus,
	complete,
	createReducer,
	failed,
	loading,
} from '../utils';
import { omit } from 'lodash';

export interface State {
	deptPermissions: AHDeptPermissions;
	ahFranchiseId: number;
	exportStatus: ActionStatus;
	loading: ActionStatus;
}

const initial: State = {
	deptPermissions: {
		[DEPARTMENTS.CM]: false,
		[DEPARTMENTS.PR]: false,
		[DEPARTMENTS.SET]: false,
	},
	ahFranchiseId: null,
	exportStatus: ActionStatus.Inactive,
	loading: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.EXPORT_TO_ASSET_HUB]: loading<State>('exportStatus'),
	[actions.EXPORT_TO_ASSET_HUB_COMPLETE]: complete<State>('exportStatus'),
	[actions.EXPORT_TO_ASSET_HUB_FAILED]: failed<State>('exportStatus'),
	[actions.GET_PERMISSIONS]: loading<State>('loading'),
	[actions.GET_PERMISSIONS_COMPLETE]: getComplete,
	[actions.GET_PERMISSIONS_FAILED]: failed<State>('loading'),
});

function getComplete(
	state: State,
	action: actions.GetPermissionsCompleteAction
): State {
	return {
		...state,
		deptPermissions: omit(action.permissions, 'franchiseId'),
		ahFranchiseId: action.permissions.franchiseId,
		loading: ActionStatus.Complete,
	};
}
