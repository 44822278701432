import * as account from './accounts/account.actions';
import * as activityFeed from './activity-feed/activity-feed.actions';
import * as activityMonitor from './activity-monitor/activity-monitor.actions';
import * as actorIndex from './actor-index/actor-index.actions';
import * as actorTableColumn from './actor-table-columns/actor-table-column.actions';
import * as addBackgroundCharactersToSceneModal from './add-background-characters-to-scene-modal/add-background-characters-to-scene-modal.actions';
import * as addExistingInventoryModal from './add-existing-inventory-modal/add-existing-inventory-modal.actions';
import * as assetHub from './asset-hub/asset-hub.actions';
import * as changeEpisodicItem from './change-episodic-items/change-episodic-item.actions';
import * as changeViewInventoryTableColumn from './change-inventory-table-columns/change-inventory-table-column.actions';
import * as changeLookPicture from './change-look-pictures/change-look-picture.actions';
import * as changeLook from './change-looks/change-look.actions';
import * as changeSceneTableColumn from './change-scene-table-columns/change-scene-table-column.actions';
import * as changeScene from './change-scenes/change-scene.actions';
import * as change from './changes/change.actions';
import * as characterPropTableColumn from './char-prop-table-columns/char-prop-table-column.actions';
import * as characterScene from './char-scenes/char-scene.actions';
import * as charScene from './char-scenes/char-scene.actions';
import * as characterIndex from './character-index/character-index.actions';
import * as characterProp from './character-props/character-prop.actions';
import * as characterView from './character-view/character-view.actions';
import * as character from './characters/character.actions';
import * as cmBrand from './cm-brands/cm-brand.actions';
import * as cmCategory from './cm-categories/cm-category.actions';
import * as cmChangePicture from './cm-change-pictures/cm-change-picture.actions';
import * as cmEpisodicActor from './cm-episodic-actors/cm-episodic-actor.actions';
import * as cmEpisodicCharacterNote from './cm-episodic-character-notes/cm-episodic-character-note.actions';
import * as cmEpisodicItemPicture from './cm-episodic-item-pictures/cm-episodic-item-picture.actions';
import * as cmSceneNote from './cm-scene-notes/cm-scene-note.actions';
import * as cmStatus from './cm-statuses/cm-status.actions';
import * as cmStorageLocation from './cm-storage-locations/cm-storage-location.actions';
import * as cmWrapBoxEpisodicItem from './cm-wrap-box-episodic-items/cm-wrap-box-episodic-item.actions';
import * as department from './departments/department.actions';
import * as deptChangeScene from './dept-change-scenes/dept-change-scene.actions';
import * as episodicActorPicture from './episodic-actor-pictures/episodic-actor-picture.actions';
import * as episodicActor from './episodic-actors/episodic-actor.actions';
import * as episodicCharacterNote from './episodic-character-notes/episodic-character-note.actions';
import * as episodicCharacterPictures from './episodic-character-pictures/episodic-character-picture.actions';
import * as episodicCharacter from './episodic-characters/episodic-character.actions';
import * as episodicFlag from './episodic-flags/episodic-flag.actions';
import * as episodicItem from './episodic-items/episodic-item.actions';
import * as episodicProp from './episodic-props/episodic-prop.actions';
import * as episodic from './episodics/episodic.actions';
import * as appFeature from './app-features/app-feature.actions';
import * as galleryView from './gallery-view/gallery-view.actions';
import * as haChangeFieldName from './ha-change-field-names/ha-change-field-names.actions';
import * as haChangeScene from './ha-change-scenes/ha-change-scene.actions';
import * as haChange from './ha-changes/ha-change.actions';
import * as haEpisodicActor from './ha-episodic-actors/ha-episodic-actor.actions';
import * as haEpisodicCharacterNote from './ha-episodic-character-notes/ha-episodic-character-note.actions';
import * as haLookPicture from './ha-look-pictures/ha-look-picture.actions';
import * as haSceneNote from './ha-scene-notes/ha-scene-note.actions';
import * as inventoryFieldSetting from './inventory-field-settings/inventory-field-setting.actions';
import * as inventoryIndexColumn from './inventory-index-columns/inventory-index-column.actions';
import * as inventoryPicture from './inventory-pictures/inventory-picture.actions';
import * as inventorySatellite from './inventory-satellites/inventory-satellite.actions';
import * as inventoryView from './inventory/inventory-view.actions';
import * as inventory from './inventory/inventory.actions';
import * as muChangeFieldName from './mu-change-field-names/mu-change-field-names.actions';
import * as muChangeScene from './mu-change-scenes/mu-change-scene.actions';
import * as muChange from './mu-changes/mu-change.actions';
import * as muEpisodicActor from './mu-episodic-actors/mu-episodic-actor.actions';
import * as muEpisodicCharacterNote from './mu-episodic-character-notes/mu-episodic-character-note.actions';
import * as muLookPicture from './mu-look-pictures/mu-look-picture.actions';
import * as muSceneNote from './mu-scene-notes/mu-scene-note.actions';
import * as navbar from './navbar/navbar.actions';
import * as permission from './permissions/permission.actions';
import * as pictureScene from './picture-scenes/picture-scene.actions';
import * as picture from './pictures/picture.actions';
import * as prEpisodicActor from './pr-episodic-actors/pr-episodic-actor.actions';
import * as prEpisodicCharacterNote from './pr-episodic-character-notes/pr-episodic-character-note.actions';
import * as prEpisodicPropPicture from './pr-episodic-prop-pictures/pr-episodic-prop-picture.actions';
import * as prSceneNote from './pr-scene-notes/pr-scene-note.actions';
import * as prWrapBoxEpisodicProp from './pr-wrap-box-episodic-props/pr-wrap-box-episodic-prop.actions';
import * as production from './productions/production.actions';
import * as propCategory from './prop-categories/prop-category.actions';
import * as propSceneCharScene from './prop-scene-char-scenes/prop-scene-char-scene.actions';
import * as propSceneTableColumn from './prop-scene-table-columns/prop-scene-table-column.actions';
import * as propScene from './prop-scenes/prop-scene.actions';
import * as propStatusColor from './prop-status-colors/prop-status-color.actions';
import * as propStatus from './prop-statuses/prop-status.actions';
import * as propStorageLocation from './prop-storage-locations/prop-storage-location.actions';
import * as sceneNote from './scene-notes/scene-note.actions';
import * as sceneTableColumn from './scene-table-columns/scene-table-column.actions';
import * as sceneView from './scenes/scene-view.actions';
import * as scene from './scenes/scene.actions';
import * as scriptLocation from './script-locations/script-location.actions';
import * as setPicture from './set-pictures/set-picture.actions';
import * as setPiecePicture from './set-piece-pictures/set-piece-picture.actions';
import * as setPieceSet from './set-piece-sets/set-piece-set.actions';
import * as setPieceStatusA from './set-piece-statuses/set-piece-status.actions';
import * as setPieceTypeA from './set-piece-types/set-piece-type.actions';
import * as setPiece from './set-pieces/set-piece.actions';
import * as setProdA from './set-prods/set-prod.actions';
import * as setScene from './set-scenes/set-scene.actions';
import * as setStorageLocation from './set-storage-locations/set-storage-location.actions';
import * as setWrapBoxSetPiece from './set-wrap-box-set-pieces/set-wrap-box-set-piece.actions';
import * as setA from './sets/set.actions';
import * as site from './site/site.actions';
import * as table from './tables/table.actions';
import * as universalSearch from './universal-search/universal-search.actions';
import * as userSetting from './user-settings/user-setting.actions';
import * as user from './users/user.actions';
import * as vendorA from './vendors/vendor.actions';
import * as wrapBoxInvColumns from './wrap-box-inventory-table-columns/wrap-box-inventory-column.actions';
import * as wrapBoxView from './wrap-boxes/wrap-box-view.actions';
import * as wrapBox from './wrap-boxes/wrap-box.actions';

export const accountActions = account;
export const activityFeedActions = activityFeed;
export const activityMonitorActions = activityMonitor;
export const actorIndexActions = actorIndex;
export const actorTableColumnActions = actorTableColumn;
export const addBackgroundCharactersToSceneModalActions = addBackgroundCharactersToSceneModal;
export const addExistingInventoryModalActions = addExistingInventoryModal;
export const assetHubActions = assetHub;
export const changeActions = change;
export const changeEpisodicItemActions = changeEpisodicItem;
export const changeLookActions = changeLook;
export const changeLookPictureActions = changeLookPicture;
export const changeSceneTableColumnActions = changeSceneTableColumn;
export const changeViewInventoryTableColumnActions = changeViewInventoryTableColumn;
export const changeSceneActions = changeScene;
export const characterActions = character;
export const characterIndexActions = characterIndex;
export const characterPropActions = characterProp;
export const characterPropTableColumnActions = characterPropTableColumn;
export const characterSceneActions = characterScene;
export const characterViewActions = characterView;
export const charSceneActions = charScene;
export const cmBrandActions = cmBrand;
export const cmEpisodicActorActions = cmEpisodicActor;
export const cmCategoryActions = cmCategory;
export const cmChangePictureActions = cmChangePicture;
export const cmEpisodicCharacterNoteActions = cmEpisodicCharacterNote;
export const cmEpisodicItemPictureActions = cmEpisodicItemPicture;
export const cmSceneNoteActions = cmSceneNote;
export const cmStatusActions = cmStatus;
export const cmStorageLocationActions = cmStorageLocation;
export const cmWrapBoxEpisodicItemActions = cmWrapBoxEpisodicItem;
export const departmentActions = department;
export const deptChangeSceneActions = deptChangeScene;
export const episodicActions = episodic;
export const episodicActorActions = episodicActor;
export const episodicActorPictureActions = episodicActorPicture;
export const episodicCharacterActions = episodicCharacter;
export const episodicCharacterNoteActions = episodicCharacterNote;
export const episodicCharacterPictureActions = episodicCharacterPictures;
export const episodicFlagActions = episodicFlag;
export const episodicItemActions = episodicItem;
export const episodicPropActions = episodicProp;
export const appFeatureActions = appFeature;
export const galleryViewActions = galleryView;
export const haEpisodicActorActions = haEpisodicActor;
export const haChangeActions = haChange;
export const haChangeFieldNameActions = haChangeFieldName;
export const haChangeSceneActions = haChangeScene;
export const haEpisodicCharacterNoteActions = haEpisodicCharacterNote;
export const haLookPictureActions = haLookPicture;
export const haSceneNoteActions = haSceneNote;
export const inventoryActions = inventory;
export const inventoryFieldSettingActions = inventoryFieldSetting;
export const inventoryIndexColumnActions = inventoryIndexColumn;
export const inventoryPictureActions = inventoryPicture;
export const inventorySatelliteActions = inventorySatellite;
export const inventoryViewActions = inventoryView;
export const muEpisodicActorActions = muEpisodicActor;
export const muChangeActions = muChange;
export const muChangeFieldNameActions = muChangeFieldName;
export const muChangeSceneActions = muChangeScene;
export const muEpisodicCharacterNoteActions = muEpisodicCharacterNote;
export const muLookPictureActions = muLookPicture;
export const muSceneNoteActions = muSceneNote;
export const navbarActions = navbar;
export const permissionActions = permission;
export const pictureActions = picture;
export const pictureSceneActions = pictureScene;
export const productionActions = production;
export const propCategoryActions = propCategory;
export const prEpisodicActorActions = prEpisodicActor;
export const prEpisodicCharacterNoteActions = prEpisodicCharacterNote;
export const prEpisodicPropPictureActions = prEpisodicPropPicture;
export const propSceneActions = propScene;
export const propSceneTableColumnActions = propSceneTableColumn;
export const propSceneCharSceneActions = propSceneCharScene;
export const propStatusActions = propStatus;
export const propStatusColorActions = propStatusColor;
export const propStorageLocationActions = propStorageLocation;
export const prSceneNoteActions = prSceneNote;
export const prWrapBoxEpisodicPropActions = prWrapBoxEpisodicProp;
export const sceneActions = scene;
export const sceneNoteActions = sceneNote;
export const sceneTableColumnActions = sceneTableColumn;
export const sceneViewActions = sceneView;
export const setPictureActions = setPicture;
export const scriptLocationActions = scriptLocation;
export const setActions = setA;
export const setPieceActions = setPiece;
export const setPiecePictureActions = setPiecePicture;
export const setPieceSetActions = setPieceSet;
export const setPieceStatusActions = setPieceStatusA;
export const setPieceTypeActions = setPieceTypeA;
export const setProdActions = setProdA;
export const setSceneActions = setScene;
export const setStorageLocationActions = setStorageLocation;
export const setWrapBoxSetPieceActions = setWrapBoxSetPiece;
export const siteActions = site;
export const tableActions = table;
export const universalSearchActions = universalSearch;
export const userActions = user;
export const userSettingActions = userSetting;
export const vendorActions = vendorA;
export const wrapBoxActions = wrapBox;
export const wrapBoxViewActions = wrapBoxView;
export const wrapBoxInventoryTableColumnActions = wrapBoxInvColumns;
