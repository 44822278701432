import { PrWrapBoxEpisodicProp } from 'sos-models';

export const LIST_COMPLETE = '[Prop Wrap Box Episodic Prop] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const CREATE_COMPLETE = '[Prop Wrap Box Episodic Prop] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const DESTROY_COMPLETE =
	'[Prop Wrap Box Episodic Prop] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const UPDATE_COMPLETE = '[Prop Wrap Box Episodic Prop] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export interface CreateCompleteAction {
	type: CREATE_COMPLETE;
	prWrapBoxEpisodicProps: PrWrapBoxEpisodicProp[];
}
export interface ListCompleteAction {
	type: LIST_COMPLETE;
	prWrapBoxEpisodicProps: PrWrapBoxEpisodicProp[];
}

export interface UpdateCompleteAction {
	type: UPDATE_COMPLETE;
	prWrapBoxEpisodicProps: PrWrapBoxEpisodicProp[];
}

export interface DestroyCompleteAction {
	type: DESTROY_COMPLETE;
	prWrapBoxEpisodicProps: PrWrapBoxEpisodicProp[];
}

export function createComplete(
	prWrapBoxEpisodicProps: PrWrapBoxEpisodicProp[]
): CreateCompleteAction {
	return {
		prWrapBoxEpisodicProps,
		type: CREATE_COMPLETE,
	};
}

export function listComplete(
	prWrapBoxEpisodicProps: PrWrapBoxEpisodicProp[]
): ListCompleteAction {
	return {
		prWrapBoxEpisodicProps,
		type: LIST_COMPLETE,
	};
}

export function updateComplete(
	prWrapBoxEpisodicProps: PrWrapBoxEpisodicProp[]
): UpdateCompleteAction {
	return {
		prWrapBoxEpisodicProps,
		type: UPDATE_COMPLETE,
	};
}

export function destroyComplete(
	prWrapBoxEpisodicProps: PrWrapBoxEpisodicProp[]
): DestroyCompleteAction {
	return {
		prWrapBoxEpisodicProps,
		type: DESTROY_COMPLETE,
	};
}

export type Action =
	| CreateCompleteAction
	| ListCompleteAction
	| UpdateCompleteAction
	| DestroyCompleteAction;
