import { CmCategory } from 'sos-models';
import axios from '../../config/axios';

export function create(
	cmCategory: Partial<CmCategory>
): Promise<Partial<CmCategory>> {
	return axios
		.post<Partial<CmCategory>>(`/api/cm-categories`, cmCategory)
		.then((response) => response.data);
}

export function destroy(cmCategory: Partial<CmCategory>) {
	return axios
		.delete(`/api/cm-categories/${cmCategory.id}`)
		.then((response) => response.data);
}

export function update(
	cmCategory: Partial<CmCategory>
): Promise<Partial<CmCategory>> {
	return axios
		.put<Partial<CmCategory>>(`/api/cm-categories/${cmCategory.id}`, cmCategory)
		.then((response) => response.data);
}
