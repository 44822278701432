import { omit } from 'lodash';
import { normalize } from 'normalizr';
import { DEPARTMENTS, EpisodicItem } from 'sos-models';
import * as episodicItemActions from './episodic-item.actions';
import * as changeEpisodicItemActions from '../change-episodic-items/change-episodic-item.actions';
import * as inventoryActions from '../inventory/inventory.actions';
import { episodicItemSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createReducer,
	failed,
	listComplete,
	loading,
	uniqueArrayMerge,
} from '../utils';

export interface State extends BaseReducerState<EpisodicItem> {
	createChangeEpItemStatus: ActionStatus;
	currentId: number;
	getStatus: ActionStatus;
	listStatus: ActionStatus;
	removeStatus: ActionStatus;
}

const initial: State = {
	createChangeEpItemStatus: ActionStatus.Inactive,
	currentId: null,
	entities: {},
	getStatus: ActionStatus.Inactive,
	ids: [],
	listStatus: ActionStatus.Inactive,
	removeStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	| episodicItemActions.Action
	| changeEpisodicItemActions.Action
	| inventoryActions.Action
>(initial, {
	[episodicItemActions.LIST_COMPLETE]: listComplete<
		EpisodicItem,
		State,
		episodicItemActions.ListCompleteAction
	>('inventory', episodicItemSchema),
	[episodicItemActions.LIST_FOR_EP_CHAR]: loading<State>('listStatus'),
	[episodicItemActions.LIST_FOR_EP_CHAR_FAILED]: failed<State>('listStatus'),
	[episodicItemActions.LIST_NO_CLOSET]: loading<State>('listStatus'),
	[episodicItemActions.LIST_NO_CLOSET_FAILED]: failed<State>('listStatus'),
	[inventoryActions.LIST]: loading<State>('listStatus'),
	[inventoryActions.LIST_COMPLETE]: listInvComplete,
	[inventoryActions.LIST_FAILED]: failed<State>('listStatus'),
	[inventoryActions.GET]: loading<State>('getStatus'),
	[inventoryActions.GET_COMPLETE]: getComplete,
	[inventoryActions.GET_FAILED]: failed<State>('getStatus'),
	[inventoryActions.UPDATE_COMPLETE]: updateComplete,
	[inventoryActions.CREATE_COMPLETE]: createComplete,
	[inventoryActions.REMOVE]: loading<State>('removeStatus'),
	[inventoryActions.REMOVE_COMPLETE]: removeComplete,
	[inventoryActions.REMOVE_FAILED]: failed<State>('removeStatus'),
});

function listInvComplete(
	state: State,
	{ inventory }: inventoryActions.ListCompleteAction
): State {
	const normalized = normalize(
		inventory.filter((inv) => {
			return inv.department === DEPARTMENTS.CM;
		}),
		[episodicItemSchema]
	);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.episodicItems,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		listStatus: ActionStatus.Complete,
	};
}

function getComplete(
	state: State,
	{ inventory }: inventoryActions.GetCompleteAction
): State {
	if (inventory.department !== DEPARTMENTS.CM) {
		return {
			...state,
			getStatus: ActionStatus.Complete,
		};
	}
	const normalized = normalize(inventory, episodicItemSchema);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.episodicItems,
		},
		currentId: normalized.result,
		getStatus: ActionStatus.Complete,
	};
}

function createComplete(
	state: State,
	{ inventory }: inventoryActions.CreateCompleteAction
): State {
	const episodicItems = inventory.filter(
		(inv) => inv.department === DEPARTMENTS.CM
	);
	const normalized = normalize(episodicItems, [episodicItemSchema]);
	const ids = uniqueArrayMerge(state.ids, normalized.result);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.episodicItems,
		},
		ids,
	};
}

function updateComplete(
	state: State,
	{ inventory }: inventoryActions.UpdateCompleteAction
): State {
	const items = [];
	inventory.forEach((inv) => {
		if (inv.department === DEPARTMENTS.CM) {
			items.push(inv);
		}
	});
	if (!items.length) {
		return state;
	}

	const normalized = normalize(items, [episodicItemSchema]);

	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.episodicItems,
		},
	};
}

function removeComplete(
	state: State,
	{ id, department }: inventoryActions.RemoveCompleteAction
): State {
	return {
		...state,
		entities:
			department === DEPARTMENTS.CM ? omit(state.entities, id) : state.entities,
	};
}
