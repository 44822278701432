import {
	SetPiecesForSetListResponse,
	SetPiecesWithoutSetListResponse,
} from 'sos-models';
import axios from '../../config/axios';

export async function listSetPiecesForSet(
	episodicId: number,
	setId: number
): Promise<SetPiecesForSetListResponse> {
	return await axios
		.get<SetPiecesForSetListResponse>(
			`/api/episodics/${episodicId}/sets/${setId}/set-pieces`
		)
		.then((response) => response.data);
}

export async function listNoSetSetPieces(
	episodicId: number
): Promise<SetPiecesWithoutSetListResponse> {
	return await axios
		.get<SetPiecesWithoutSetListResponse>(
			`/api/episodics/${episodicId}/no-set-set-pieces`
		)
		.then((response) => response.data);
}
