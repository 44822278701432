// import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { AppFeatureUpdateResponse } from 'sos-models';
import * as actions from './app-feature.actions';
import * as api from './app-feature.api';
import * as logger from '../../logger';

export function* update({ appFeatureId, enable }: actions.UpdateAction) {
	try {
		const results: AppFeatureUpdateResponse = yield call(
			api.update,
			appFeatureId,
			enable
		);
		if (results.updated.AppFeature) {
			yield put(actions.updateComplete(results.updated.AppFeature));
		}
	} catch (err) {
		yield put(actions.updateFailed(err));
		logger.error(err);
	}
}

export function* list() {
	try {
		const appFeatures = yield call(api.list);
		yield put(actions.listComplete(appFeatures));
	} catch (err) {
		yield put(actions.listFailed(err));
		if (err.response) {
			// TODO: toast possibly toast or use the above action to save error
			// message from http is in err.response.data.message
			logger.log(err.response.data.message);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeEvery(actions.UPDATE, update);
	yield takeLatest(actions.LIST, list);
}

export default saga;
