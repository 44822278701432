import { uniq } from 'lodash';
import { normalize } from 'normalizr';
import { CmEpisodicItemPicture, DEPARTMENTS } from 'sos-models';
import * as actions from './cm-episodic-item-picture.actions';
import * as inventoryPictureActions from '../inventory-pictures/inventory-picture.actions';
import { cmEpisodicItemPictureSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createReducer,
	destroyComplete,
	failed,
	listComplete,
	loading,
} from '../utils';

export interface State extends BaseReducerState<CmEpisodicItemPicture> {
	entities: { [id: number]: CmEpisodicItemPicture };
	ids: number[];
	destroyStatus: ActionStatus;
	listStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	destroyStatus: ActionStatus.Inactive,
	listStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	actions.Action | inventoryPictureActions.Action
>(initial, {
	[actions.DESTROY]: loading<State>('destroyStatus'),
	[actions.DESTROY_COMPLETE]: destroyComplete<
		CmEpisodicItemPicture,
		State,
		actions.DestroyCompleteAction
	>('cmEpisodicItemPicture'),
	[actions.DESTROY_FAILED]: failed<State>('destroyStatus'),
	[actions.LIST_COMPLETE]: listComplete<
		CmEpisodicItemPicture,
		State,
		actions.ListCompleteAction
	>('cmEpisodicItemPictures', cmEpisodicItemPictureSchema),
	[inventoryPictureActions.LIST]: inventoryList,
	[inventoryPictureActions.LIST_COMPLETE]: inventoryListComplete,
	[inventoryPictureActions.LIST_FAILED]: inventoryListFailed,
});

function inventoryList(
	state: State,
	action: inventoryPictureActions.ListAction
): State {
	if (action.departmentId === DEPARTMENTS.CM) {
		return {
			...state,
			listStatus: ActionStatus.Loading,
		};
	}
	return state;
}

function inventoryListComplete(
	state: State,
	action: inventoryPictureActions.ListCompleteAction
): State {
	if (action.departmentId !== DEPARTMENTS.CM) {
		return {
			...state,
			listStatus: ActionStatus.Complete,
		};
	}
	const normalized = normalize(action.inventoryPictures, [
		cmEpisodicItemPictureSchema,
	]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.cmEpisodicItemPictures,
		},
		ids: uniq([...state.ids, ...normalized.result]),
		listStatus: ActionStatus.Complete,
	};
}

function inventoryListFailed(
	state: State,
	action: inventoryPictureActions.ListFailedAction
): State {
	if (action.departmentId === DEPARTMENTS.CM) {
		return {
			...state,
			listStatus: ActionStatus.Failed,
		};
	}
	return state;
}
