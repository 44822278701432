import { EpisodicActorPicture } from 'sos-models';

export const LIST_COMPLETE = '[Episodic Actor Picture] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export interface ListCompleteAction {
	episodicActorPictures: EpisodicActorPicture[];
	type: LIST_COMPLETE;
}

export function listComplete(
	episodicActorPictures: EpisodicActorPicture[]
): ListCompleteAction {
	return {
		episodicActorPictures,
		type: LIST_COMPLETE,
	};
}

export type Action = ListCompleteAction;
