import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { SetListResponse } from 'sos-models';
import * as actions from './set.actions';
import * as api from './set.api';
import * as logger from '../../logger';

export function* exportToCSV({
	episodicId,
	prodIds
}: actions.ExportToCSVAction) {
	try {
		const results = yield call(
			api.exportToCSV,
			episodicId,
			prodIds
		);
		yield put(actions.exportToCSVComplete(results));
	} catch (err) {
		yield put(actions.exportToCSVFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

export function* list({ episodicId }: actions.ListAction) {
	try {
		const result: SetListResponse = yield call(api.list, episodicId);
		if (result.listed.Set) {
			yield put(actions.listComplete(result.listed.Set));
		}
	} catch (err) {
		yield put(actions.listFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading the sets. Please refresh to try again.'
			);
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeLatest(actions.EXPORT_TO_CSV, exportToCSV);
	yield takeLatest(actions.LIST, list);
}

export default saga;
