import {
	CreateDeleteChangeScene,
	DEPARTMENTS,
	MuChange,
	MuChangeScene,
} from 'sos-models';
import axios from '../../config/axios';

export function getForScene(
	prodId: number,
	sceneId: number
): Promise<Partial<MuChangeScene>[]> {
	return axios
		.get<Partial<MuChangeScene>[]>(
			`/api/productions/${prodId}/scenes/${sceneId}/mu-change-scenes`
		)
		.then((response) => response.data);
}

export function getForEpCharId(
	episodicId: number,
	epCharId: number
): Promise<Partial<MuChangeScene>[]> {
	return axios
		.get<Partial<MuChangeScene>[]>(
			`/api/episodics/${episodicId}/episodic-characters/${epCharId}/mu-change-scenes`
		)
		.then((response) => response.data);
}

export function createAndDeleteExisting(
	episodicId: number,
	department: DEPARTMENTS,
	data: CreateDeleteChangeScene[]
): Promise<{
	created: { MuChangeScene: MuChangeScene[] };
	deleted: { MuChangeScene: MuChangeScene[] };
	updated: { MuChange: MuChange[] };
}> {
	return axios
		.post(`/api/episodics/${episodicId}/change-scenes/${department}`, data)
		.then((response) => response.data);
}

export function update(
	episodicId: number,
	changeScene: Partial<MuChangeScene>
): Promise<MuChangeScene> {
	return axios
		.put(
			`/api/episodics/${episodicId}/change-scenes/${DEPARTMENTS.MU}`,
			changeScene
		)
		.then((response) => response.data);
}
