import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	PropSceneByEpCharListResponse,
	PropSceneCreateResponse,
	PropSceneCreateWithEpisodicPropResponse,
	PropSceneDeleteResponse,
} from 'sos-models';
import * as actions from './prop-scene.actions';
import * as api from './prop-scene.api';
import * as logger from '../../logger';
import {
	characterSceneActions,
	episodicPropActions,
	inventoryActions,
	propSceneCharSceneActions,
} from '../actions';

export function* getForEpChar({
	episodicId,
	episodicCharacterId,
}: actions.GetForEpCharAction) {
	try {
		const { listed }: PropSceneByEpCharListResponse = yield call(
			api.getForEpChar,
			episodicId,
			episodicCharacterId
		);
		yield put(
			actions.getForEpCharComplete(listed.PropScene, episodicCharacterId)
		);
		yield put(episodicPropActions.listComplete(listed.EpisodicProp));
		yield put(
			propSceneCharSceneActions.listComplete(listed.PropSceneCharScene)
		);
		yield put(characterSceneActions.listComplete(listed.CharScene));
		yield put(episodicPropActions.listComplete(listed.EpisodicProp));
	} catch (err) {
		yield put(actions.getForEpCharFailed(err));
		logger.responseError(err, 'loading the prop scenes', true);
	}
}

export function* create({
	episodicId,
	episodicPropId,
	sceneIds,
}: actions.CreateAction) {
	try {
		const result: PropSceneCreateResponse = yield call(
			api.create,
			episodicId,
			episodicPropId,
			sceneIds
		);
		yield put(actions.createComplete(result.created.PropScene));
	} catch (err) {
		yield put(actions.createFailed(err));
		logger.responseError(err, 'creating the prop scenes', true);
	}
}

export function* createMultiple({
	episodicId,
	episodicPropIds,
	sceneIds,
	epCharId,
}: actions.CreateMultipleAction) {
	try {
		const result: PropSceneCreateResponse = yield call(
			api.createMultiple,
			episodicId,
			episodicPropIds,
			sceneIds,
			epCharId
		);
		yield put(
			actions.createMultipleComplete(
				result.created.PropScene,
				result.created.PropSceneCharScene
			)
		);
		yield put(actions.createComplete(result.created.PropScene));
	} catch (err) {
		yield put(actions.createMultipleFailed(err));
		logger.responseError(err, 'creating the prop scenes', true);
	}
}

export function* createByScene({
	episodicId,
	sceneId,
	originalSceneId,
	epPropIds,
}: actions.CreateBySceneAction) {
	try {
		const result: PropSceneCreateResponse = yield call(
			api.createByScene,
			episodicId,
			sceneId,
			originalSceneId,
			epPropIds
		);
		yield put(actions.createComplete(result.created.PropScene));
		yield put(
			propSceneCharSceneActions.createComplete(
				result.created.PropSceneCharScene
			)
		);
	} catch (err) {
		yield put(actions.createFailed(err));
		logger.responseError(err, 'creating the prop scenes', true);
	}
}

export function* createSceneEpisodicProp({
	sceneId,
	prodId,
	episodicId,
	episodicProp,
}: actions.CreateSceneEpisodicPropAction) {
	try {
		const result: PropSceneCreateWithEpisodicPropResponse = yield call(
			api.createSceneEpisodicProp,
			sceneId,
			prodId,
			episodicId,
			episodicProp
		);
		yield put(inventoryActions.createComplete(result.created.EpisodicProp));
		yield put(actions.createComplete(result.created.PropScene));
	} catch (err) {
		yield put(actions.createFailed(err));
		logger.responseError(err, 'creating the prop scenes', true);
	}
}

export function* destroy({
	episodicId,
	episodicPropId,
	sceneId,
}: actions.DestroyAction) {
	try {
		const result: PropSceneDeleteResponse = yield call(
			api.destroy,
			episodicId,
			episodicPropId,
			sceneId
		);
		yield put(actions.destroyComplete(result.destroyed.PropScene));
		yield put(
			propSceneCharSceneActions.destroyComplete(
				result.destroyed.PropSceneCharScene
			)
		);
	} catch (err) {
		yield put(actions.destroyFailed(err));
		logger.responseError(err, 'deleting the prop scenes', true);
	}
}

function* saga() {
	yield takeLatest(actions.GET_FOR_EP_CHAR, getForEpChar);
	yield takeEvery(actions.CREATE, create);
	yield takeEvery(actions.CREATE_MULTIPLE, createMultiple);
	yield takeEvery(actions.CREATE_BY_SCENE, createByScene);
	yield takeEvery(actions.CREATE_SCENE_EPISODIC_PROP, createSceneEpisodicProp);
	yield takeEvery(actions.DESTROY, destroy);
}

export default saga;
