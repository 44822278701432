import { call, put, takeLatest } from 'redux-saga/effects';
import { PermissionGetResponse } from 'sos-models';
import * as permissionActions from './permission.actions';
import * as api from './permission.api';
import { history } from '../../history';
import * as logger from '../../logger';
import * as userSettingActions from '../user-settings/user-setting.actions';
import * as userActions from '../users/user.actions';

export function* get({ episodicId }: permissionActions.GetAction) {
	try {
		const { listed }: PermissionGetResponse = yield call(api.get, episodicId);
		if (listed.Permission) {
			yield put(permissionActions.getComplete(listed.Permission));
		}
		if (listed.UserSetting) {
			yield put(userSettingActions.getComplete(listed.UserSetting));
		}
		if (listed.User) {
			yield put(userActions.getComplete(listed.User[0]));
		}
	} catch (err) {
		yield put(permissionActions.getFailed(err));
		if (err.response) {
			logger.log(err.response.data.message);
		} else {
			logger.error(err);
		}
		history.push('/app/permission-error');
	}
}

function* saga() {
	yield takeLatest(permissionActions.GET, get);
}

export default saga;
