import { UserSetting, UserSettingUpdateResponse } from 'sos-models';
import axios from '../../config/axios';

export async function update(
	episodicId: number,
	userSetting: Partial<UserSetting>
): Promise<UserSettingUpdateResponse> {
	return axios
		.put(`/api/episodics/${episodicId}/user-settings`, userSetting)
		.then(({ data }) => data);
}
