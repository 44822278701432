import { EpisodicProp } from 'sos-models';

export const DELETE_BY_SCENE_ID = '[PropScene] Delete By Scene Id';
type DELETE_BY_SCENE_ID = typeof DELETE_BY_SCENE_ID;

export const EXPORT_TO_CSV = '[Episodic Prop] Export to CSV';
type EXPORT_TO_CSV = typeof EXPORT_TO_CSV;

export const EXPORT_TO_CSV_FAILED = '[Episodic Prop] Export to CSV Failed';
type EXPORT_TO_CSV_FAILED = typeof EXPORT_TO_CSV_FAILED;

export const EXPORT_TO_CSV_COMPLETE = '[Episodic Prop] Export to CSV Complete';
type EXPORT_TO_CSV_COMPLETE = typeof EXPORT_TO_CSV_COMPLETE;

export const GET_COMPLETE = '[EpisodicProp] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const GET_FOR_CHAR_SCENES = '[Episodic Prop] Get For Char Scenes';
type GET_FOR_CHAR_SCENES = typeof GET_FOR_CHAR_SCENES;

export const GET_FOR_CHAR_SCENES_COMPLETE =
	'[Episodic Prop] Get For Char Scenes Complete';
type GET_FOR_CHAR_SCENES_COMPLETE = typeof GET_FOR_CHAR_SCENES_COMPLETE;

export const GET_FOR_CHAR_SCENES_FAILED =
	'[Episodic Props] Get For Char Scenes Failed';
type GET_FOR_CHAR_SCENES_FAILED = typeof GET_FOR_CHAR_SCENES_FAILED;

export const LIST_COMPLETE = '[Episodic Prop] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FOR_SCENE = '[Episodic Prop] List For Scene';
type LIST_FOR_SCENE = typeof LIST_FOR_SCENE;

export const LIST_FOR_SCENE_FAILED = '[Episodic Props] List For Scene Failed';
type LIST_FOR_SCENE_FAILED = typeof LIST_FOR_SCENE_FAILED;

export const LIST_NO_CLOSET = '[Episodic Props] List for No Closet';
type LIST_NO_CLOSET = typeof LIST_NO_CLOSET;

export const LIST_NO_CLOSET_FAILED =
	'[Episodic Props] List for No Closet Failed';
type LIST_NO_CLOSET_FAILED = typeof LIST_NO_CLOSET_FAILED;

export interface GetCompleteAction {
	episodicProp: EpisodicProp;
	type: GET_COMPLETE;
}

export interface DeleteBySceneIdAction {
	type: DELETE_BY_SCENE_ID;
	sceneId: number;
}

export interface ExportToCSVAction {
	episodicId: number;
	prodId: number;
	sortQuery?: string;
	type: EXPORT_TO_CSV;
}

export interface ExportToCSVCompleteAction {
	fileContent: string;
	type: EXPORT_TO_CSV_COMPLETE;
}

export interface ExportToCSVFailedAction {
	error: Error;
	type: EXPORT_TO_CSV_FAILED;
}

export interface GetForCharScenesAction {
	episodicId: number;
	episodicCharacterId: number;
	type: GET_FOR_CHAR_SCENES;
}

export interface GetForCharScenesCompleteAction {
	episodicProps: EpisodicProp[];
	type: GET_FOR_CHAR_SCENES_COMPLETE;
}

export interface GetForCharScenesFailedAction {
	error: Error;
	type: GET_FOR_CHAR_SCENES_FAILED;
}

export interface ListCompleteAction {
	inventory: EpisodicProp[];
	type: LIST_COMPLETE;
}

export interface ListForSceneAction {
	episodicId: number;
	sceneId: number;
	type: LIST_FOR_SCENE;
}

export interface ListForSceneFailedAction {
	error: Error;
	type: LIST_FOR_SCENE_FAILED;
}

export interface ListNoClosetAction {
	type: LIST_NO_CLOSET;
	episodicId: number;
}

export interface ListNoClosetFailedAction {
	type: LIST_NO_CLOSET_FAILED;
	error: Error;
}

export function getComplete(episodicProp: EpisodicProp): GetCompleteAction {
	return {
		episodicProp,
		type: GET_COMPLETE,
	};
}

export function deleteBySceneId(sceneId: number): DeleteBySceneIdAction {
	return {
		sceneId,
		type: DELETE_BY_SCENE_ID,
	};
}

export function exportToCSV(
	episodicId: number,
	prodId: number,
	sortQuery?: string
): ExportToCSVAction {
	return {
		episodicId,
		prodId,
		sortQuery,
		type: EXPORT_TO_CSV,
	};
}

export function exportToCSVComplete(
	fileContent: string
): ExportToCSVCompleteAction {
	return {
		fileContent,
		type: EXPORT_TO_CSV_COMPLETE,
	};
}

export function exportToCSVFailed(error: Error): ExportToCSVFailedAction {
	return {
		error,
		type: EXPORT_TO_CSV_FAILED,
	};
}

export function getForCharScenes(
	episodicId: number,
	episodicCharacterId: number
): GetForCharScenesAction {
	return {
		episodicId,
		episodicCharacterId,
		type: GET_FOR_CHAR_SCENES,
	};
}

export function getForCharScenesComplete(
	episodicProps: EpisodicProp[]
): GetForCharScenesCompleteAction {
	return {
		episodicProps,
		type: GET_FOR_CHAR_SCENES_COMPLETE,
	};
}

export function getForCharScenesFailed(
	error: Error
): GetForCharScenesFailedAction {
	return {
		error,
		type: GET_FOR_CHAR_SCENES_FAILED,
	};
}

export function listComplete(inventory: EpisodicProp[]): ListCompleteAction {
	return {
		inventory,
		type: LIST_COMPLETE,
	};
}

export function listForScene(
	sceneId: number,
	episodicId: number
): ListForSceneAction {
	return {
		episodicId,
		sceneId,
		type: LIST_FOR_SCENE,
	};
}

export function listForSceneFailed(error: Error): ListForSceneFailedAction {
	return {
		error,
		type: LIST_FOR_SCENE_FAILED,
	};
}

export function listNoCloset(episodicId: number): ListNoClosetAction {
	return {
		type: LIST_NO_CLOSET,
		episodicId,
	};
}

export function listNoClosetFailed(error: Error): ListNoClosetFailedAction {
	return {
		type: LIST_NO_CLOSET_FAILED,
		error,
	};
}

export type Action =
	| GetCompleteAction
	| DeleteBySceneIdAction
	| ExportToCSVAction
	| ExportToCSVCompleteAction
	| ExportToCSVFailedAction
	| GetForCharScenesAction
	| GetForCharScenesCompleteAction
	| GetForCharScenesFailedAction
	| ListCompleteAction
	| ListForSceneAction
	| ListForSceneFailedAction
	| ListNoClosetAction
	| ListNoClosetFailedAction;
