import { INVENTORY_INDEX_COLUMNS, TableColumn } from 'sos-models';
import { buildColumnArray } from '../inventory-index-columns/inventory-index-columns.data';

export const CHAR_PROP_TABLE_COLUMNS: {
	[name: string]: TableColumn;
} = INVENTORY_INDEX_COLUMNS;

export const defaultSelections = [
	'name',
	'brand',
	'description',
	'is_personal',
	'episode',
];

export const defaultHidden = [
	'size',
	'category_id',
	'asset_num',
	'color',
	'unit_price',
	'total_cost',
	'quantity_notes',
	'set_ids',
	'purchase_type',
	'status_id',
	'vendor_id',
	'sku',
	'start_date',
	'end_date',
	'wrap_box_id',
	'episodic_character_id',
	'storage_location_id',
	'quantity',
	'disposition',
	'disposition_notes',
	'hero',
	'department',
	'photos',
];
const colData = buildColumnArray(INVENTORY_INDEX_COLUMNS);

export const inputColumns = colData.inputCols;
export const selectColumns = colData.selectCols;
export const multiSelectColumns = colData.multiSelectCols;
export const currencyColumns = colData.currencyCols;
export const numberColumns = colData.numberCols;
export const dateColumns = colData.dateCols;
export const checkboxColumns = colData.checkboxCols;
export let CHAR_PROP_TABLE_COLUMNS_ARRAY = colData.colArray;
