import { PropStorageLocation } from 'sos-models';
import axios from '../../config/axios';

export function create(
	propStorageLocation: Partial<PropStorageLocation>
): Promise<Partial<PropStorageLocation>> {
	return axios
		.post<Partial<PropStorageLocation>>(
			`/api/prop-storage-locations`,
			propStorageLocation
		)
		.then((response) => response.data);
}

export function destroy(propStorageLocation: Partial<PropStorageLocation>) {
	return axios
		.delete(`/api/prop-storage-locations/${propStorageLocation.id}`)
		.then((response) => response.data);
}

export function update(
	propStorageLocation: Partial<PropStorageLocation>
): Promise<Partial<PropStorageLocation>> {
	return axios
		.put<Partial<PropStorageLocation>>(
			`/api/prop-storage-locations/${propStorageLocation.id}`,
			propStorageLocation
		)
		.then((response) => response.data);
}
