import { CmWrapBoxEpisodicItem } from 'sos-models';
import * as cmWrapBoxEpisodicItemActions from './cm-wrap-box-episodic-item.actions';
import { cmWrapBoxEpisodicItemSchema } from '../schemas';
import {
	ActionStatus,
	createComplete,
	createReducer,
	destroyComplete,
	listComplete,
	NormalizedEntityMapping,
	updateComplete,
} from '../utils';

export interface State {
	listStatus: ActionStatus;
	createStatus: ActionStatus;
	destroyStatus: ActionStatus;
	ids: number[];
	entities: NormalizedEntityMapping<CmWrapBoxEpisodicItem>;
}

export const initial: State = {
	listStatus: ActionStatus.Inactive,
	createStatus: ActionStatus.Inactive,
	destroyStatus: ActionStatus.Inactive,
	entities: {},
	ids: [],
};

export const reducer = createReducer<
	State,
	cmWrapBoxEpisodicItemActions.Action
>(initial, {
	[cmWrapBoxEpisodicItemActions.LIST_COMPLETE]: listComplete<
		CmWrapBoxEpisodicItem,
		State,
		cmWrapBoxEpisodicItemActions.ListCompleteAction
	>('cmWrapBoxEpisodicItems', cmWrapBoxEpisodicItemSchema),
	[cmWrapBoxEpisodicItemActions.CREATE_COMPLETE]: createComplete<
		CmWrapBoxEpisodicItem,
		State,
		cmWrapBoxEpisodicItemActions.CreateCompleteAction
	>('cmWrapBoxEpisodicItems', cmWrapBoxEpisodicItemSchema),
	[cmWrapBoxEpisodicItemActions.DESTROY_COMPLETE]: destroyComplete<
		CmWrapBoxEpisodicItem,
		State,
		cmWrapBoxEpisodicItemActions.DestroyCompleteAction
	>('cmWrapBoxEpisodicItems'),
	[cmWrapBoxEpisodicItemActions.UPDATE_COMPLETE]: updateComplete<
		CmWrapBoxEpisodicItem,
		State,
		cmWrapBoxEpisodicItemActions.UpdateCompleteAction
	>('cmWrapBoxEpisodicItems', cmWrapBoxEpisodicItemSchema),
});
