import { DeptChangeLookPictures } from 'sos-models';

export const LIST = '[ChangeLookPicture] List';
type LIST = typeof LIST;

export const LIST_FAILED = '[ChangeLookPicture] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const LIST_COMPLETE = '[ChangeLookPicture] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FOR_EP_CHAR = '[ChangeLookPicture] List For Ep Char';
type LIST_FOR_EP_CHAR = typeof LIST_FOR_EP_CHAR;

export const LIST_FOR_EP_CHAR_COMPLETE =
	'[ChangeLookPicture] List For Ep Char Copmlete';
type LIST_FOR_EP_CHAR_COMPLETE = typeof LIST_FOR_EP_CHAR_COMPLETE;

export const LIST_FOR_EP_CHAR_FAILED =
	'[ChangeLookPicture] List For Ep Char Failed';
type LIST_FOR_EP_CHAR_FAILED = typeof LIST_FOR_EP_CHAR_FAILED;

export interface ListForEpCharAction {
	episodicId: number;
	episodicCharacterId: number;
	type: LIST_FOR_EP_CHAR;
}

export interface ListForEpCharCompleteAction {
	changeLookPictures: DeptChangeLookPictures;
	type: LIST_FOR_EP_CHAR_COMPLETE;
}

export interface ListForEpCharFailedAction {
	error: Error;
	type: LIST_FOR_EP_CHAR_FAILED;
}

export interface ListAction {
	episodicId: number;
	departmentId: number;
	changeLookId: number;
	type: LIST;
}

export interface ListCompleteAction {
	type: LIST_COMPLETE;
}

export interface ListFailedAction {
	departmentId: number;
	error: Error;
	type: LIST_FAILED;
}

export function listForEpChar(
	episodicId: number,
	episodicCharacterId: number
): ListForEpCharAction {
	return {
		episodicId,
		episodicCharacterId,
		type: LIST_FOR_EP_CHAR,
	};
}

export function listForEpCharComplete(
	changeLookPictures: DeptChangeLookPictures
): ListForEpCharCompleteAction {
	return {
		changeLookPictures,
		type: LIST_FOR_EP_CHAR_COMPLETE,
	};
}

export function listForEpCharFailed(error: Error): ListForEpCharFailedAction {
	return {
		error,
		type: LIST_FOR_EP_CHAR_FAILED,
	};
}

export function list(
	episodicId: number,
	departmentId: number,
	changeLookId: number
): ListAction {
	return {
		episodicId,
		departmentId,
		changeLookId,
		type: LIST,
	};
}

export function listComplete(): ListCompleteAction {
	return {
		type: LIST_COMPLETE,
	};
}

export function listFailed(
	departmentId: number,
	error: Error
): ListFailedAction {
	return {
		departmentId,
		error,
		type: LIST_FAILED,
	};
}

export type Action =
	| ListAction
	| ListCompleteAction
	| ListFailedAction
	| ListForEpCharAction
	| ListForEpCharCompleteAction
	| ListForEpCharFailedAction;
