import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
	ChangeEpisodicItemCreateResponse,
	ChangeEpisodicItemDeleteResponse,
	ChangeEpisodicItemUpdateResponse,
	EpisodicItemsForChangeListResponse,
} from 'sos-models';
import * as actions from './change-episodic-item.actions';
import * as api from './change-episodic-item.api';
import * as logger from '../../logger';
import * as epItemActions from '../episodic-items/episodic-item.actions';
import {
	wrapBoxActions,
	cmWrapBoxEpisodicItemActions,
	inventoryActions,
} from '../actions';

export function* create({
	changeId,
	prodId,
	episodicId,
	episodicItemId,
}: actions.CreateAction) {
	try {
		const result: ChangeEpisodicItemCreateResponse = yield call(
			api.create,
			changeId,
			prodId,
			episodicId,
			episodicItemId
		);
		yield put(actions.createComplete(result.created.ChangeEpisodicItem));
		if (result.created.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.createComplete(
					result.created.CmWrapBoxEpisodicItem
				)
			);
		}
		if (result.updated.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.updateComplete(
					result.updated.CmWrapBoxEpisodicItem
				)
			);
		}
		toast.success('Item was added to change.');
	} catch (err) {
		yield put(actions.createFailed(err));
		toast.error('There was an error creating the relation.');
		logger.responseError(err, 'Creating this relation from change/look', true);
	}
}

export function* update({
	changeId,
	prodId,
	episodicId,
	payload,
}: actions.UpdateAction) {
	try {
		const result: ChangeEpisodicItemUpdateResponse = yield call(
			api.update,
			episodicId,
			prodId,
			changeId,
			payload
		);
		yield put(actions.updateComplete(result.updated.ChangeEpisodicItem));
	} catch (err) {
		yield put(actions.updateFailed(err));
		toast.error('There was an error updating change episodic item.');
		logger.responseError(err, 'Updating this relation from change/look', true);
	}
}

export function* createChangeEpisodicItem({
	changeId,
	prodId,
	episodicId,
	episodicItem,
}: actions.CreateChangeEpisodicItemAction) {
	try {
		const result: ChangeEpisodicItemCreateResponse = yield call(
			api.createChangeEpisodicItem,
			changeId,
			prodId,
			episodicId,
			episodicItem
		);
		if (result.created.EpisodicItem) {
			yield put(inventoryActions.createComplete(result.created.EpisodicItem));
		}
		if (result.created.ChangeEpisodicItem) {
			yield put(
				actions.createChangeEpisodicItemComplete(
					result.created.ChangeEpisodicItem
				)
			);
		}
		if (result.created.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.createComplete(
					result.created.CmWrapBoxEpisodicItem
				)
			);
		}
		toast.success('Item was added to change.');
	} catch (err) {
		yield put(actions.createChangeEpisodicItemFailed(err));
		toast.error('There was an error creating the inventory item.');
		logger.responseError(err, 'Creating this inventory from change/look', true);
	}
}

export function* deleteChangeEpisodicItem({
	changeId,
	prodId,
	episodicId,
	episodicItemId,
}: actions.DeleteChangeEpisodicItemAction) {
	try {
		const { destroyed }: ChangeEpisodicItemDeleteResponse = yield call(
			api.deleteChangeEpisodicItem,
			changeId,
			prodId,
			episodicId,
			episodicItemId
		);
		yield put(
			actions.deleteChangeEpisodicItemComplete(destroyed.ChangeEpisodicItem)
		);
	} catch (err) {
		yield put(actions.deleteChangeEpisodicItemFailed(err));
		toast.error('There was an error deleting the inventory item.');
		logger.responseError(err, 'deleting this inventory from change/look', true);
	}
}

export function* getEpisodicItemsForChange({
	episodicId,
	changeId,
}: actions.GetEpisodicItemsForChangeAction) {
	try {
		const result: EpisodicItemsForChangeListResponse = yield call(
			api.getEpisodicItemsForChange,
			episodicId,
			changeId
		);

		if (result.listed.ChangeEpisodicItem) {
			yield put(actions.listComplete(result.listed.ChangeEpisodicItem));
		}
		if (result.listed.EpisodicItem) {
			yield put(epItemActions.listComplete(result.listed.EpisodicItem));
		}
		if (result.listed.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.listComplete(
					result.listed.CmWrapBoxEpisodicItem
				)
			);
		}
		if (result.listed.CmWrapBox) {
			yield put(wrapBoxActions.listComplete(result.listed));
		}
	} catch (err) {
		yield put(actions.getEpisodicItemsForChangeFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading episodic items for change. Please refresh to try again.'
			);
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeEvery(actions.CREATE, create);
	yield takeEvery(actions.UPDATE, update);
	yield takeEvery(
		actions.CREATE_CHANGE_EPISODIC_ITEM,
		createChangeEpisodicItem
	);
	yield takeEvery(
		actions.DELETE_CHANGE_EPISODIC_ITEM,
		deleteChangeEpisodicItem
	);
	yield takeEvery(actions.GET_ITEMS_FOR_CHANGE, getEpisodicItemsForChange);
}

export default saga;
