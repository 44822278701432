import { DEPARTMENTS, PRODUCTS } from 'sos-models';
import * as actions from './navbar.actions';
import { createReducer } from '../utils';

export interface State {
	currentBudgetDept: DEPARTMENTS;
	currentBudgetProductionId: number;
	product: PRODUCTS;
	isReportsBannerClosed: boolean;
}

export const initial: State = {
	currentBudgetDept: null,
	currentBudgetProductionId: null,
	product: PRODUCTS.CONTINUITY,
	isReportsBannerClosed: null,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.SET_PRODUCT]: setProduct,
	[actions.SET_PRODUCTION]: setProduction,
	[actions.SET_DEPT]: setDept,
	[actions.SET_REPORTS_BANNER_CLOSED]: setIsReportBannerClosed,
});

function setDept(state: State, { dept }: actions.SetDeptAction): State {
	return {
		...state,
		currentBudgetDept: dept,
	};
}

function setProduct(
	state: State,
	{ product }: actions.SetProductAction
): State {
	return {
		...state,
		product,
	};
}

function setProduction(
	state: State,
	{ productionId }: actions.SetProductionAction
): State {
	return {
		...state,
		currentBudgetProductionId: productionId,
	};
}

function setIsReportBannerClosed(
	state: State,
	{ isClosed }: actions.SetIsReportBannerClosed
): State {
	return {
		...state,
		isReportsBannerClosed: isClosed,
	};
}
