import { CmChangePictureDeleteResponse } from 'sos-models';
import axios from '../../config/axios';

export async function destroy(
	episodicId: number,
	cmChangePictureId: number
): Promise<CmChangePictureDeleteResponse> {
	const { data } = await axios.delete<CmChangePictureDeleteResponse>(
		`/api/episodics/${episodicId}/cm-change-pictures/${cmChangePictureId}`
	);
	return data;
}
