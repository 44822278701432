import { CmBrand } from 'sos-models';
import * as actions from './cm-brand.actions';
import { cmBrandSchema } from '../schemas';
import {
	ActionStatus,
	createReducer,
	failed,
	listComplete,
	loading,
} from '../utils';

export interface State {
	entities: { [id: number]: CmBrand };
	ids: number[];
	listStatus: ActionStatus;
}

export const initial: State = {
	entities: {},
	ids: [],
	listStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.LIST]: loading<State>('listStatus'),
	[actions.LIST_COMPLETE]: listComplete<
		CmBrand,
		State,
		actions.ListCompleteAction
	>('cmBrands', cmBrandSchema),
	[actions.LIST_FAILED]: failed<State>('listStatus'),
});
