import { Sorting } from '@devexpress/dx-react-grid';
import {
	DEPARTMENTS,
	DeptSpecificChangeLookDeleteResponse,
	HaChange,
	HaChangeScene,
} from 'sos-models';

export const GET = '[HaChange] Get]';
type GET = typeof GET;

export const GET_COMPLETE = '[HaChange] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const GET_FAILED = '[HaChange] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export const LIST_COMPLETE = '[HaChange] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const DELETE_COMPLETE = '[HaChange] Delete Complete';
type DELETE_COMPLETE = typeof DELETE_COMPLETE;

export const UPDATE = '[HaChange] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[HaChange] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[HaChange] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const GET_HA_CHANGES_FOR_CHANGE_LOOK =
	'[HaChange] Get HaChanges For Change Look';
type GET_HA_CHANGES_FOR_CHANGE_LOOK = typeof GET_HA_CHANGES_FOR_CHANGE_LOOK;

export const GET_HA_CHANGES_FOR_CHANGE_LOOK_COMPLETE =
	'[HaChange] Get HaChanges For Change Look Complete';
type GET_HA_CHANGES_FOR_CHANGE_LOOK_COMPLETE = typeof GET_HA_CHANGES_FOR_CHANGE_LOOK_COMPLETE;

export const GET_HA_CHANGES_FOR_CHANGE_LOOK_FAILED =
	'[HaChange] Get HaChanges For Change Look Failed';
type GET_HA_CHANGES_FOR_CHANGE_LOOK_FAILED = typeof GET_HA_CHANGES_FOR_CHANGE_LOOK_FAILED;

export const GET_HA_CHANGES_FOR_EP_CHAR =
	'[HaChange] Get HaChanges For Ep Char';
type GET_HA_CHANGES_FOR_EP_CHAR = typeof GET_HA_CHANGES_FOR_EP_CHAR;

export const GET_HA_CHANGES_FOR_EP_CHAR_COMPLETE =
	'[HaChange] Get HaChanges For Ep Char Complete';
type GET_HA_CHANGES_FOR_EP_CHAR_COMPLETE = typeof GET_HA_CHANGES_FOR_EP_CHAR_COMPLETE;

export const GET_HA_CHANGES_FOR_EP_CHAR_FAILED =
	'[HaChange] Get HaChanges For Ep Char Failed';
type GET_HA_CHANGES_FOR_EP_CHAR_FAILED = typeof GET_HA_CHANGES_FOR_EP_CHAR_FAILED;

export const DELETE_HA_CHANGE_FOR_EP_CHAR =
	'[HaChange] Delete Ha Change For Ep Char';
type DELETE_HA_CHANGE_FOR_EP_CHAR = typeof DELETE_HA_CHANGE_FOR_EP_CHAR;

export const DELETE_HA_CHANGE_FOR_EP_CHAR_COMPLETE =
	'[HaChange] Delete Ha Change For Ep Char Complete';
type DELETE_HA_CHANGE_FOR_EP_CHAR_COMPLETE = typeof DELETE_HA_CHANGE_FOR_EP_CHAR_COMPLETE;

export const DELETE_HA_CHANGE_FOR_EP_CHAR_FAILED =
	'[HaChange] Delete Ha Change For Ep Char Failed';
type DELETE_HA_CHANGE_FOR_EP_CHAR_FAILED = typeof DELETE_HA_CHANGE_FOR_EP_CHAR_FAILED;

export const GET_HA_CHANGES_FOR_SCENE = '[HaChange] Get HaChanges For Scene';
type GET_HA_CHANGES_FOR_SCENE = typeof GET_HA_CHANGES_FOR_SCENE;

export const GET_HA_CHANGES_FOR_SCENE_COMPLETE =
	'[HaChange] Get HaChanges For Scene Complete';
type GET_HA_CHANGES_FOR_SCENE_COMPLETE = typeof GET_HA_CHANGES_FOR_SCENE_COMPLETE;

export const GET_HA_CHANGES_FOR_SCENE_FAILED =
	'[HaChange] Get HaChanges For Scene Failed';
type GET_HA_CHANGES_FOR_SCENE_FAILED = typeof GET_HA_CHANGES_FOR_SCENE_FAILED;

export const CREATE_HA_CHANGE = '[HaChange] Create Ha Change';
type CREATE_HA_CHANGE = typeof CREATE_HA_CHANGE;

export const CREATE_HA_CHANGE_COMPLETE = '[HaChange] Create Ha Change Complete';
type CREATE_HA_CHANGE_COMPLETE = typeof CREATE_HA_CHANGE_COMPLETE;

export const CREATE_HA_CHANGE_FAILED = '[HaChange] Create Ha Change Failed';
type CREATE_HA_CHANGE_FAILED = typeof CREATE_HA_CHANGE_FAILED;

export const CREATE_HA_CHANGE_BY_SCENE = '[HaChange] Create HaChange By Scene';
type CREATE_HA_CHANGE_BY_SCENE = typeof CREATE_HA_CHANGE_BY_SCENE;

export const CREATE_HA_CHANGE_BY_SCENE_COMPLETE =
	'[HaChange] Create HaChange By Scene Complete';
type CREATE_HA_CHANGE_BY_SCENE_COMPLETE = typeof CREATE_HA_CHANGE_BY_SCENE_COMPLETE;

export const CREATE_HA_CHANGE_BY_SCENE_FAILED =
	'[HaChange] Create HaChange By Scene Failed';
type CREATE_HA_CHANGE_BY_SCENE_FAILED = typeof CREATE_HA_CHANGE_BY_SCENE_FAILED;

export const SET_SORTING = '[HaChange] Set Sorting';
type SET_SORTING = typeof SET_SORTING;

export interface GetAction {
	haChangeId: number;
	type: GET;
}

export interface GetCompleteAction {
	haChange: HaChange;
	type: GET_COMPLETE;
}

export interface GetFailedAction {
	error: Error;
	type: GET_FAILED;
}

export interface ListCompleteAction {
	haChanges: HaChange[];
	type: LIST_COMPLETE;
}

export interface DeleteCompleteAction {
	type: DELETE_COMPLETE;
	haChanges: HaChange[];
}

export interface UpdateAction {
	type: UPDATE;
	episodicId: number;
	prodId: number;
	haChanges: Partial<HaChange>[];
}

export interface UpdateCompleteAction {
	type: UPDATE_COMPLETE;
	haChanges: HaChange[];
}

export interface UpdateFailedAction {
	type: UPDATE_FAILED;
	error: Error;
}

export interface GetHaChangesForChangeLookAction {
	changeLookId: number;
	type: GET_HA_CHANGES_FOR_CHANGE_LOOK;
}

export interface GetHaChangesForChangeLookCompleteAction {
	haChanges: HaChange[];
	type: GET_HA_CHANGES_FOR_CHANGE_LOOK_COMPLETE;
}

export interface GetHaChangesForChangeLookFailedAction {
	error: Error;
	type: GET_HA_CHANGES_FOR_CHANGE_LOOK_FAILED;
}

export interface GetHaChangesForEpCharAction {
	type: GET_HA_CHANGES_FOR_EP_CHAR;
	episodicId: number;
	epCharId: number;
}

export interface GetHaChangesForEpCharCompleteAction {
	type: GET_HA_CHANGES_FOR_EP_CHAR_COMPLETE;
	haChanges: HaChange[];
	epCharId: number;
}

export interface GetHaChangesForEpCharFailedAction {
	type: GET_HA_CHANGES_FOR_EP_CHAR_FAILED;
	error: Error;
}

export interface DeleteHaChangeForEpCharAction {
	type: DELETE_HA_CHANGE_FOR_EP_CHAR;
	episodicId: number;
	epCharId: number;
	haChangeId: number;
}

export interface DeleteHaChangeForEpCharCompleteAction {
	type: DELETE_HA_CHANGE_FOR_EP_CHAR_COMPLETE;
	epCharId: number;
	response: DeptSpecificChangeLookDeleteResponse<DEPARTMENTS.HA>;
}

export interface DeleteHaChangeForEpCharFailedAction {
	type: DELETE_HA_CHANGE_FOR_EP_CHAR_FAILED;
	error: Error;
}

export interface GetHaChangesForSceneAction {
	type: GET_HA_CHANGES_FOR_SCENE;
	prodId: number;
	sceneId: number;
}

export interface GetHaChangesForSceneCompleteAction {
	type: GET_HA_CHANGES_FOR_SCENE_COMPLETE;
	haChanges: HaChange[];
	prodId: number;
}

export interface GetHaChangesForSceneFailedAction {
	type: GET_HA_CHANGES_FOR_SCENE_FAILED;
	error: Error;
}

export interface CreateHaChangeAction {
	type: CREATE_HA_CHANGE;
	episodicId: number;
	change: Partial<HaChange>;
}

export interface CreateHaChangeCompleteAction {
	type: CREATE_HA_CHANGE_COMPLETE;
	change: HaChange[];
}

export interface CreateHaChangeFailedAction {
	type: CREATE_HA_CHANGE_FAILED;
	error: Error;
}

export interface CreateHaChangeBySceneAction {
	type: CREATE_HA_CHANGE_BY_SCENE;
	episodicId: number;
	change: Partial<HaChange>;
	oldChange?: HaChange;
	sceneId: number;
}

export interface CreateHaChangeBySceneCompleteAction {
	type: CREATE_HA_CHANGE_BY_SCENE_COMPLETE;
	created: {
		HaChange: HaChange[];
		HaChangeScene: HaChangeScene[];
	};
	deleted: {
		HaChangeScene: HaChangeScene[];
	};
	updated: {
		HaChange: HaChange[];
	};
}

export interface CreateHaChangeBySceneFailedAction {
	type: CREATE_HA_CHANGE_BY_SCENE_FAILED;
	error: Error;
}

export interface SetSortingAction {
	sorting: Sorting[];
	type: SET_SORTING;
}

export function get(haChangeId: number): GetAction {
	return {
		haChangeId,
		type: GET,
	};
}

export function getComplete(haChange: HaChange): GetCompleteAction {
	return {
		haChange,
		type: GET_COMPLETE,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	};
}

export function listComplete(haChanges: HaChange[]): ListCompleteAction {
	return {
		haChanges,
		type: LIST_COMPLETE,
	};
}

export function deleteComplete(haChanges: HaChange[]): DeleteCompleteAction {
	return {
		haChanges,
		type: DELETE_COMPLETE,
	};
}

export function update(
	episodicId: number,
	prodId: number,
	haChanges: Partial<HaChange>[]
): UpdateAction {
	return {
		episodicId,
		prodId,
		haChanges,
		type: UPDATE,
	};
}

export function updateComplete(haChanges: HaChange[]): UpdateCompleteAction {
	return {
		haChanges,
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function getHaChangesForChangeLook(
	changeLookId: number
): GetHaChangesForChangeLookAction {
	return {
		changeLookId,
		type: GET_HA_CHANGES_FOR_CHANGE_LOOK,
	};
}

export function getHaChangesForChangeLookComplete(
	haChanges: HaChange[]
): GetHaChangesForChangeLookCompleteAction {
	return {
		haChanges,
		type: GET_HA_CHANGES_FOR_CHANGE_LOOK_COMPLETE,
	};
}

export function getHaChangesForChangeLookFailed(
	error: Error
): GetHaChangesForChangeLookFailedAction {
	return {
		error,
		type: GET_HA_CHANGES_FOR_CHANGE_LOOK_FAILED,
	};
}

export function getHaChangesForScene(
	prodId: number,
	sceneId: number
): GetHaChangesForSceneAction {
	return {
		prodId,
		sceneId,
		type: GET_HA_CHANGES_FOR_SCENE,
	};
}

export function getHaChangesForSceneComplete(
	haChanges: HaChange[],
	prodId: number
): GetHaChangesForSceneCompleteAction {
	return {
		haChanges,
		prodId,
		type: GET_HA_CHANGES_FOR_SCENE_COMPLETE,
	};
}

export function getHaChangesForSceneFailed(
	error: Error
): GetHaChangesForSceneFailedAction {
	return {
		error,
		type: GET_HA_CHANGES_FOR_SCENE_FAILED,
	};
}

export function getHaChangesForEpChar(
	episodicId: number,
	epCharId: number
): GetHaChangesForEpCharAction {
	return {
		episodicId,
		epCharId,
		type: GET_HA_CHANGES_FOR_EP_CHAR,
	};
}

export function getHaChangesForEpCharComplete(
	haChanges: HaChange[],
	epCharId: number
): GetHaChangesForEpCharCompleteAction {
	return {
		haChanges,
		epCharId,
		type: GET_HA_CHANGES_FOR_EP_CHAR_COMPLETE,
	};
}

export function getHaChangesForEpCharFailed(
	error: Error
): GetHaChangesForEpCharFailedAction {
	return {
		error,
		type: GET_HA_CHANGES_FOR_EP_CHAR_FAILED,
	};
}

export function deleteHaChangeForEpChar(
	episodicId: number,
	epCharId: number,
	haChangeId: number
): DeleteHaChangeForEpCharAction {
	return {
		episodicId,
		epCharId,
		haChangeId,
		type: DELETE_HA_CHANGE_FOR_EP_CHAR,
	};
}

export function deleteHaChangeForEpCharComplete(
	epCharId: number,
	response: DeptSpecificChangeLookDeleteResponse<DEPARTMENTS.HA>
): DeleteHaChangeForEpCharCompleteAction {
	return {
		epCharId,
		response,
		type: DELETE_HA_CHANGE_FOR_EP_CHAR_COMPLETE,
	};
}

export function deleteHaChangeForEpCharFailed(
	error: Error
): DeleteHaChangeForEpCharFailedAction {
	return {
		error,
		type: DELETE_HA_CHANGE_FOR_EP_CHAR_FAILED,
	};
}

export function createHaChange(
	episodicId: number,
	change: Partial<HaChange>
): CreateHaChangeAction {
	return {
		episodicId,
		change,
		type: CREATE_HA_CHANGE,
	};
}

export function createHaChangeComplete(
	change: HaChange[]
): CreateHaChangeCompleteAction {
	return {
		change,
		type: CREATE_HA_CHANGE_COMPLETE,
	};
}

export function createHaChangeFailed(error: Error): CreateHaChangeFailedAction {
	return {
		error,
		type: CREATE_HA_CHANGE_FAILED,
	};
}

export function createHaChangeByScene(
	episodicId: number,
	change: Partial<HaChange>,
	sceneId: number,
	oldChange?: HaChange
): CreateHaChangeBySceneAction {
	return {
		episodicId,
		change,
		oldChange,
		sceneId,
		type: CREATE_HA_CHANGE_BY_SCENE,
	};
}

export function createHaChangeBySceneComplete(
	created: { HaChange: HaChange[]; HaChangeScene: HaChangeScene[] },
	deleted: { HaChangeScene: HaChangeScene[] },
	updated: { HaChange: HaChange[] }
): CreateHaChangeBySceneCompleteAction {
	return {
		created,
		deleted,
		updated,
		type: CREATE_HA_CHANGE_BY_SCENE_COMPLETE,
	};
}

export function createHaChangeBySceneFailed(
	error: Error
): CreateHaChangeBySceneFailedAction {
	return {
		error,
		type: CREATE_HA_CHANGE_BY_SCENE_FAILED,
	};
}

export function setSorting(sorting: Sorting[]): SetSortingAction {
	return {
		sorting,
		type: SET_SORTING,
	};
}

export type Action =
	| GetAction
	| GetCompleteAction
	| GetFailedAction
	| ListCompleteAction
	| DeleteCompleteAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction
	| GetHaChangesForChangeLookAction
	| GetHaChangesForChangeLookCompleteAction
	| GetHaChangesForChangeLookFailedAction
	| GetHaChangesForEpCharAction
	| GetHaChangesForEpCharCompleteAction
	| GetHaChangesForEpCharFailedAction
	| DeleteHaChangeForEpCharAction
	| DeleteHaChangeForEpCharCompleteAction
	| DeleteHaChangeForEpCharFailedAction
	| GetHaChangesForSceneAction
	| GetHaChangesForSceneCompleteAction
	| GetHaChangesForSceneFailedAction
	| CreateHaChangeAction
	| CreateHaChangeCompleteAction
	| CreateHaChangeFailedAction
	| CreateHaChangeBySceneAction
	| CreateHaChangeBySceneCompleteAction
	| CreateHaChangeBySceneFailedAction
	| SetSortingAction;
