import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './inventory-satellite.actions';
import * as api from './inventory-satellite.api';
import * as logger from '../../logger';

export function* list({ episodicId }: actions.ListAction) {
	try {
		const inventorySatellites = yield call(api.list, episodicId);
		yield put(actions.listComplete(inventorySatellites));
	} catch (err) {
		yield put(actions.listFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading the inventory satellites. Please refresh to try again.'
			);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeLatest(actions.LIST, list);
}

export default saga;
