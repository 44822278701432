import { PictureScene } from 'sos-models';

export const DESTROY = '[CM Change Pictures] Destroy';
type DESTROY = typeof DESTROY;

export const DELETE_BY_SCENE_ID = '[PictureScene] Delete By Scene Id';
type DELETE_BY_SCENE_ID = typeof DELETE_BY_SCENE_ID;

export const DESTROY_COMPLETE = '[PictureScene] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const DESTROY_FAILED = '[CM Change Pictures] Destroy Failed';
type DESTROY_FAILED = typeof DESTROY_FAILED;

export const LIST = '[PictureScene] List';
type LIST = typeof LIST;

export const LIST_COMPLETE = '[PictureScene] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FAILED = '[PictureScene] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export interface DestroyAction {
	pictureSceneId: number;
	episodicId: number;
	type: DESTROY;
}

export interface DeleteBySceneIdAction {
	sceneId: number;
	type: DELETE_BY_SCENE_ID;
}

export interface DestroyCompleteAction {
	pictureScene: PictureScene[];
	type: DESTROY_COMPLETE;
}

export interface DestroyFailedAction {
	error: Error;
	type: DESTROY_FAILED;
}

export interface ListAction {
	prodId: number;
	sceneId: number;
	type: LIST;
}

export interface ListCompleteAction {
	pictureScenes: PictureScene[];
	type: LIST_COMPLETE;
}

export interface ListFailedAction {
	error: Error;
	type: LIST_FAILED;
}

export function destroy(
	episodicId: number,
	pictureSceneId: number
): DestroyAction {
	return {
		episodicId,
		pictureSceneId,
		type: DESTROY,
	};
}

export function deleteBySceneId(sceneId: number): DeleteBySceneIdAction {
	return {
		sceneId,
		type: DELETE_BY_SCENE_ID,
	};
}

export function destroyComplete(
	pictureScene: PictureScene[]
): DestroyCompleteAction {
	return {
		pictureScene,
		type: DESTROY_COMPLETE,
	};
}

export function destroyFailed(error: Error): DestroyFailedAction {
	return {
		error,
		type: DESTROY_FAILED,
	};
}

export function list(prodId: number, sceneId: number): ListAction {
	return {
		prodId,
		sceneId,
		type: LIST,
	};
}

export function listComplete(
	pictureScenes: PictureScene[]
): ListCompleteAction {
	return {
		pictureScenes,
		type: LIST_COMPLETE,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export type Action =
	| DestroyAction
	| DeleteBySceneIdAction
	| DestroyCompleteAction
	| DestroyFailedAction
	| ListAction
	| ListCompleteAction
	| ListFailedAction;
