import { call, put, takeLatest } from 'redux-saga/effects';
import {
	SetPiecesForSetListResponse,
	SetPiecesWithoutSetListResponse,
} from 'sos-models';
import * as actions from './set-piece.actions';
import * as api from './set-piece.api';
import * as logger from '../../logger';
import { setPieceActions, setPieceSetActions } from '../actions';

export function* listForSet({ episodicId, setId }: actions.ListForSetAction) {
	try {
		const result: SetPiecesForSetListResponse = yield call(
			api.listSetPiecesForSet,
			episodicId,
			setId
		);
		if (result.listed.SetPiece) {
			yield put(setPieceActions.listComplete(result.listed.SetPiece));
		}
		if (result.listed.SetPieceSet) {
			yield put(setPieceSetActions.listComplete(result.listed.SetPieceSet));
		}
	} catch (err) {
		yield put(actions.listForSetFailed(err));
		logger.responseError(err, 'loading the set pieces', true);
	}
}

export function* listNoSet({ episodicId }: actions.ListNoSetAction) {
	try {
		const result: SetPiecesWithoutSetListResponse = yield call(
			api.listNoSetSetPieces,
			episodicId
		);
		if (result.listed.SetPiece) {
			yield put(setPieceActions.listComplete(result.listed.SetPiece));
		}
	} catch (err) {
		yield put(actions.listNoSetFailed(err));
		logger.responseError(err, 'loading the set pieces', true);
	}
}

function* saga() {
	yield takeLatest(actions.LIST_FOR_SET, listForSet);
	yield takeLatest(actions.LIST_NO_SET, listNoSet);
}

export default saga;
