import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import { CmBrandListResponse } from 'sos-models';
import * as actions from './cm-brand.actions';
import * as api from './cm-brand.api';
import * as logger from '../../logger';

export function* list({ episodicId }: actions.ListAction) {
	try {
		const { listed }: CmBrandListResponse = yield call(api.list, episodicId);
		yield put(actions.listComplete(listed.CmBrand));
	} catch (err) {
		yield put(actions.listFailed(err));
		if (err.response) {
			toast.error('There was an error listing the brand. Please try again.');
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeEvery(actions.LIST, list);
}

export default saga;
