import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	DEPARTMENTS,
	DeptSpecificChangeLookCreateResponse,
	DeptSpecificChangeLookDeleteResponse,
	DeptSpecificChangeLookGetResponse,
	HaChangeScene,
	HaChangeUpdateResponse,
} from 'sos-models';
import * as actions from './ha-change.actions';
import * as api from './ha-change.api';
import * as logger from '../../logger';
import {
	changeLookActions,
	deptChangeSceneActions,
	haChangeSceneActions,
} from '../actions';

export function* getForEpChar({
	episodicId,
	epCharId,
}: actions.GetHaChangesForEpCharAction) {
	try {
		const {
			listed,
		}: DeptSpecificChangeLookGetResponse<DEPARTMENTS.HA> = yield call(
			api.getForEpChar,
			episodicId,
			epCharId
		);
		if (listed.HaChange) {
			yield put(
				actions.getHaChangesForEpCharComplete(listed.HaChange, epCharId)
			);
		}
	} catch (err) {
		yield put(actions.getHaChangesForEpCharFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading the change table. Please refresh to try again.'
			);
		} else {
			logger.error(err);
		}
	}
}

export function* deleteForEpChar({
	episodicId,
	epCharId,
	haChangeId,
}: actions.DeleteHaChangeForEpCharAction) {
	try {
		const result: DeptSpecificChangeLookDeleteResponse<DEPARTMENTS.HA> = yield call(
			api.deleteForEpChar,
			episodicId,
			epCharId,
			haChangeId
		);
		const { destroyed } = result as DeptSpecificChangeLookDeleteResponse<
			DEPARTMENTS.HA
		>;
		yield put(actions.deleteHaChangeForEpCharComplete(epCharId, result));
		yield put(
			changeLookActions.deleteComplete(destroyed.HaChange, DEPARTMENTS.HA)
		);

		if (destroyed.HaChangeScene) {
			yield put(haChangeSceneActions.deleteComplete(destroyed.HaChangeScene));
			yield destroyed.HaChangeScene.forEach((haChangeScene: HaChangeScene) => {
				put(
					deptChangeSceneActions.deleteChangeSceneByIdAndDeptIdComplete(
						haChangeScene.id,
						DEPARTMENTS.HA
					)
				);
			});
		}
	} catch (err) {
		yield put(actions.deleteHaChangeForEpCharFailed(err));
		logger.responseError(err, 'deleting this change', true);
	}
}

export function* getHaChangesForScene({
	prodId,
	sceneId,
}: actions.GetHaChangesForSceneAction) {
	try {
		const {
			listed,
		}: DeptSpecificChangeLookGetResponse<DEPARTMENTS.HA> = yield call(
			api.getHaChangesForScene,
			prodId,
			sceneId
		);
		yield put(actions.getHaChangesForSceneComplete(listed.HaChange, prodId));
	} catch (err) {
		yield put(actions.getHaChangesForSceneFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading the looks table. Please refresh to try again.'
			);
		} else {
			logger.error(err);
		}
	}
}

export function* create({ episodicId, change }: actions.CreateHaChangeAction) {
	try {
		const res: DeptSpecificChangeLookCreateResponse<DEPARTMENTS.HA> = yield call(
			api.create,
			episodicId,
			DEPARTMENTS.HA,
			change
		);
		yield put(actions.createHaChangeComplete(res.created.HaChange));
	} catch (err) {
		yield put(actions.createHaChangeFailed(err));
		logger.responseError(err, 'creating this look', true);
	}
}

export function* createByScene({
	episodicId,
	change,
	sceneId,
	oldChange,
}: actions.CreateHaChangeBySceneAction) {
	try {
		const { created, deleted, updated } = yield call(
			api.createByScene,
			episodicId,
			DEPARTMENTS.HA,
			change,
			sceneId,
			oldChange
		);
		yield put(actions.createHaChangeBySceneComplete(created, deleted, updated));
	} catch (err) {
		yield put(actions.createHaChangeBySceneFailed(err));
		logger.responseError(err, 'creating this look', true);
	}
}

export function* update({
	episodicId,
	prodId,
	haChanges,
}: actions.UpdateAction) {
	try {
		const { updated }: HaChangeUpdateResponse = yield call(
			api.update,
			episodicId,
			prodId,
			haChanges
		);
		if (updated.HaChange) {
			yield put(actions.updateComplete(updated.HaChange));
		}
	} catch (err) {
		yield put(actions.updateFailed(err));
		if (err.response) {
			toast.error('There was an error updating this hair look.');
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeLatest(actions.GET_HA_CHANGES_FOR_EP_CHAR, getForEpChar);
	yield takeLatest(actions.DELETE_HA_CHANGE_FOR_EP_CHAR, deleteForEpChar);
	yield takeLatest(actions.GET_HA_CHANGES_FOR_SCENE, getHaChangesForScene);
	yield takeLatest(actions.CREATE_HA_CHANGE, create);
	yield takeLatest(actions.CREATE_HA_CHANGE_BY_SCENE, createByScene);
	yield takeEvery(actions.UPDATE, update);
}

export default saga;
