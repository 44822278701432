import { DeptEntityId } from 'sos-models';
import * as actions from './inventory-view.actions';
import { createReducer } from '../utils';

export interface State {
	inventoryId: DeptEntityId;
}

export const initial: State = {
	inventoryId: {
		id: null,
		department: null,
	},
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.SET_CURRENT]: setCurrent,
});

function setCurrent(
	state: State,
	{ id, departmentId }: actions.SetCurrentAction
): State {
	return {
		...state,
		inventoryId: {
			id,
			department: departmentId,
		},
	};
}
