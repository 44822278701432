import { Sorting } from '@devexpress/dx-react-grid';

export const SET_PAGING_PRIMARY = '[Character Index] Set Paging Primary';
type SET_PAGING_PRIMARY = typeof SET_PAGING_PRIMARY;

export const SET_PAGING_BACKGROUND = '[Character Index] Set Paging Background';
type SET_PAGING_BACKGROUND = typeof SET_PAGING_BACKGROUND;

export const SET_SORTING_PRIMARY = '[Character Index] Set Sorting Primary';
type SET_SORTING_PRIMARY = typeof SET_SORTING_PRIMARY;

export const SET_SORTING_BACKGROUND =
	'[Character Index] Set Sorting Background';
type SET_SORTING_BACKGROUND = typeof SET_SORTING_BACKGROUND;

export type PageSize = 25 | 50 | 100;

export interface SetPagingPrimaryAction {
	page: number;
	pageSize: PageSize;
	type: SET_PAGING_PRIMARY;
}

export interface SetPagingBackgroundAction {
	page: number;
	pageSize: PageSize;
	type: SET_PAGING_BACKGROUND;
}

export interface SetSortingPrimaryAction {
	sorting: Sorting[];
	type: SET_SORTING_PRIMARY;
}

export interface SetSortingBackgroundAction {
	sorting: Sorting[];
	type: SET_SORTING_BACKGROUND;
}

export function setPagingPrimary(
	page: number,
	pageSize: PageSize
): SetPagingPrimaryAction {
	return {
		page,
		pageSize,
		type: SET_PAGING_PRIMARY,
	};
}

export function setPagingBackground(
	page: number,
	pageSize: PageSize
): SetPagingBackgroundAction {
	return {
		page,
		pageSize,
		type: SET_PAGING_BACKGROUND,
	};
}

export function setSortingPrimary(sorting: Sorting[]): SetSortingPrimaryAction {
	return {
		sorting,
		type: SET_SORTING_PRIMARY,
	};
}

export function setSortingBackground(
	sorting: Sorting[]
): SetSortingBackgroundAction {
	return {
		sorting,
		type: SET_SORTING_BACKGROUND,
	};
}

export type Action =
	| SetPagingPrimaryAction
	| SetPagingBackgroundAction
	| SetSortingPrimaryAction
	| SetSortingBackgroundAction;
