import {
	AddCharsToSceneResponse,
	CharScene,
	CreateEpCharForSceneResponse,
	DeleteCharSceneByEpCharIdAndSceneIdResponse,
	EpisodicActor,
	EpisodicCharacter,
	UpdateCharScenesResponse,
	UpdatePropSceneCharScenesResponse,
} from 'sos-models';
import axios from '../../config/axios';

export async function addBgCharToScene(
	episodicId: number,
	epCharId: number,
	sceneId: number,
	bgCount: number
): Promise<AddCharsToSceneResponse> {
	const { data } = await axios.post(
		`/api/episodics/${episodicId}/bg-char-scene`,
		{
			epCharId,
			sceneIds: [sceneId],
			bgCount,
		}
	);
	return data;
}

export async function addEpCharToScenes(
	episodicId: number,
	epCharId: number,
	sceneIds: number[]
): Promise<Partial<EpisodicCharacter>> {
	const {
		data,
	} = await axios.post(
		`/api/episodics/${episodicId}/episodic-characters/${epCharId}/char-scenes`,
		{ sceneIds }
	);
	return data;
}

export async function addEpCharsToScene(
	episodicId: number,
	sceneId: number,
	epCharIds: number[]
): Promise<AddCharsToSceneResponse> {
	const {
		data,
	} = await axios.post(
		`/api/episodics/${episodicId}/scenes/${sceneId}/char-scenes`,
		{ epCharIds }
	);
	return data;
}

export async function createEpCharForScene(
	episodicId: number,
	sceneId: number,
	epChar: Partial<EpisodicCharacter>,
	epActor: Partial<EpisodicActor>
): Promise<CreateEpCharForSceneResponse> {
	const { data } = await axios.post(
		`/api/episodics/${episodicId}/scenes/${sceneId}/episodic-character`,
		{
			epActor,
			epChar,
		}
	);
	return data;
}

export async function deleteByEpCharIdAndSceneId(
	episodicId: number,
	epCharId: number,
	sceneId: number
): Promise<DeleteCharSceneByEpCharIdAndSceneIdResponse> {
	const { data } = await axios.delete(
		`/api/episodics/${episodicId}/episodic-characters/${epCharId}/scenes/${sceneId}/char-scenes`
	);
	return data;
}

export async function update(
	episodicId: number,
	charScenes: CharScene[]
): Promise<UpdateCharScenesResponse> {
	const { data } = await axios.put(`/api/episodics/${episodicId}/char-scenes`, {
		charScenes,
	});
	return data;
}

export async function updatePropSceneCharScenesForEpChar(
	episodicId: number,
	epCharId: number,
	charScenes: CharScene[]
): Promise<UpdatePropSceneCharScenesResponse> {
	const {
		data,
	} = await axios.put(
		`/api/episodics/${episodicId}/episodic-characters/${epCharId}/prop-scene-char-scenes`,
		{ charScenes }
	);
	return data;
}
