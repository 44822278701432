import { DEPARTMENTS } from 'sos-models';
import * as actions from './department.actions';
import { createReducer } from '../utils';
import { setSelectedDepartments } from '../../logger';

export interface State {
	selectedIds: DEPARTMENTS[];
}

const initial: State = {
	selectedIds: [],
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.SELECT]: select,
});

function select(state: State, action: actions.SelectAction): State {
	setSelectedDepartments(action.selectedIds);
	return {
		...state,
		selectedIds: action.selectedIds,
	};
}
