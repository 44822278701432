export const SELECT = '[Departments] Select';

import { DEPARTMENTS } from 'sos-models';

type SELECT = typeof SELECT;

export interface SelectAction {
	type: SELECT;
	selectedIds: DEPARTMENTS[];
}

export function select(selectedIds: DEPARTMENTS[]): SelectAction {
	return {
		selectedIds,
		type: SELECT,
	};
}

export type Action = SelectAction;
