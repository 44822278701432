import { Sorting } from '@devexpress/dx-react-grid';
import { DEPARTMENTS } from 'sos-models';

export const SET_CURRENT_DEPT = '[Scene View] Set Current Department';
type SET_CURRENT_DEPT = typeof SET_CURRENT_DEPT;

export const SET_PRINCIPAL_SORTING = '[Scene View] Set Principal Sorting';
type SET_PRINCIPAL_SORTING = typeof SET_PRINCIPAL_SORTING;

export const SET_BACKGROUND_SORTING = '[Scene View] Set Background Sorting';
type SET_BACKGROUND_SORTING = typeof SET_BACKGROUND_SORTING;

export interface SetCurrentDeptAction {
	departmentId: DEPARTMENTS;
	type: SET_CURRENT_DEPT;
}

export function setCurrentDepartment(
	departmentId: DEPARTMENTS
): SetCurrentDeptAction {
	return {
		departmentId,
		type: SET_CURRENT_DEPT,
	};
}

export interface SetPrincipalSortingAction {
	sorting: Sorting[];
	type: SET_PRINCIPAL_SORTING;
}

export interface SetBackgroundSortingAction {
	sorting: Sorting[];
	type: SET_BACKGROUND_SORTING;
}

export function setPrincipalSorting(
	sorting: Sorting[]
): SetPrincipalSortingAction {
	return {
		sorting,
		type: SET_PRINCIPAL_SORTING,
	};
}

export function setBackgroundSorting(
	sorting: Sorting[]
): SetBackgroundSortingAction {
	return {
		sorting,
		type: SET_BACKGROUND_SORTING,
	};
}

export type Action =
	| SetCurrentDeptAction
	| SetPrincipalSortingAction
	| SetBackgroundSortingAction;
