import { omit } from 'lodash';
import { normalize } from 'normalizr';
import { DEPARTMENTS, SetPiece } from 'sos-models';
import * as setPieceActions from './set-piece.actions';
import * as inventoryActions from '../inventory/inventory.actions';
import { setPieceSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createReducer,
	failed,
	loading,
	uniqueArrayMerge,
} from '../utils';

export interface State extends BaseReducerState<SetPiece> {
	currentId: number;
	getStatus: ActionStatus;
	listStatus: ActionStatus;
	removeStatus: ActionStatus;
}

const initial: State = {
	currentId: null,
	entities: {},
	getStatus: ActionStatus.Inactive,
	ids: [],
	listStatus: ActionStatus.Inactive,
	removeStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, inventoryActions.Action>(initial, {
	[setPieceActions.LIST_COMPLETE]: listComplete,
	[inventoryActions.LIST]: loading<State>('listStatus'),
	[inventoryActions.LIST_COMPLETE]: listComplete,
	[inventoryActions.LIST_FAILED]: failed<State>('listStatus'),
	[inventoryActions.GET]: loading<State>('getStatus'),
	[inventoryActions.GET_COMPLETE]: getComplete,
	[inventoryActions.GET_FAILED]: failed<State>('getStatus'),
	[inventoryActions.UPDATE_COMPLETE]: updateComplete,
	[inventoryActions.CREATE_COMPLETE]: createComplete,
	[inventoryActions.REMOVE]: loading<State>('removeStatus'),
	[inventoryActions.REMOVE_COMPLETE]: removeComplete,
	[inventoryActions.REMOVE_FAILED]: failed<State>('removeStatus'),
});

function listComplete(
	state: State,
	action: inventoryActions.ListCompleteAction
): State {
	const normalized = normalize(
		action.inventory.filter((inv) => {
			return inv.department === DEPARTMENTS.SET;
		}),
		[setPieceSchema]
	);
	const ids = uniqueArrayMerge(state.ids, normalized.result);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.setPieces,
		},
		ids,
		listStatus: ActionStatus.Complete,
	};
}

function getComplete(
	state: State,
	{ inventory }: inventoryActions.GetCompleteAction
): State {
	if (inventory.department !== DEPARTMENTS.SET) {
		return {
			...state,
			getStatus: ActionStatus.Complete,
		};
	}
	const normalized = normalize(inventory, setPieceSchema);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.setPieces,
		},
		currentId: normalized.result,
		getStatus: ActionStatus.Complete,
	};
}

function createComplete(
	state: State,
	{ inventory }: inventoryActions.CreateCompleteAction
): State {
	const setPieces = inventory.filter(
		(inv) => inv.department === DEPARTMENTS.SET
	);
	const normalized = normalize(setPieces, [setPieceSchema]);
	const ids = uniqueArrayMerge(state.ids, normalized.result);

	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.setPieces,
		},
		ids,
	};
}

function updateComplete(
	state: State,
	{ inventory }: inventoryActions.UpdateCompleteAction
): State {
	const setPieces = [];
	inventory.forEach((inv) => {
		if (inv.department === DEPARTMENTS.SET) {
			setPieces.push(inv);
		}
	});
	if (!setPieces.length) {
		return state;
	}

	const normalized = normalize(setPieces, [setPieceSchema]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.setPieces,
		},
	};
}

function removeComplete(
	state: State,
	{ id, department }: inventoryActions.RemoveCompleteAction
): State {
	return {
		...state,
		entities:
			department === DEPARTMENTS.SET
				? omit(state.entities, id)
				: state.entities,
	};
}
