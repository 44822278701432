import { ColumnWidth } from 'sos-models';

export const SELECT = '[SceneTableColumns] Select';
type SELECT = typeof SELECT;

export const DESELECT = '[SceneTableColumns] deselect';
type DESELECT = typeof DESELECT;

export const REORDER = '[SceneTableColumns] Reorder';
type REORDER = typeof REORDER;

export const SET_WIDTHS = '[SceneTableColumns] Set Widths';
type SET_WIDTHS = typeof SET_WIDTHS;

export interface SelectAction {
	type: SELECT;
	column: string;
}

export interface DeselectAction {
	type: DESELECT;
	column: string;
}

export interface ReorderAction {
	type: REORDER;
	ids: string[];
}

export interface SetWidthsAction {
	type: SET_WIDTHS;
	widths: ColumnWidth[];
}

export function select(column: string): SelectAction {
	return {
		column,
		type: SELECT,
	};
}

export function deselect(column: string): DeselectAction {
	return {
		column,
		type: DESELECT,
	};
}

export function reorder(ids: string[]): ReorderAction {
	return {
		ids,
		type: REORDER,
	};
}

export function setWidths(widths: ColumnWidth[]): SetWidthsAction {
	return {
		widths,
		type: SET_WIDTHS,
	};
}

export type Action =
	| SelectAction
	| DeselectAction
	| ReorderAction
	| SetWidthsAction;
