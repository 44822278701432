import {
	DEPARTMENTS,
	DeptSpecificChangeLookDeleteResponse,
	DeptSpecificChangeLookGetResponse,
	MuChange,
	MuChangeScene,
	MuChangeUpdateResponse,
} from 'sos-models';
import axios from '../../config/axios';

export async function getForEpChar(
	episodicId: number,
	epCharId: number
): Promise<DeptSpecificChangeLookGetResponse<DEPARTMENTS.MU>> {
	const { data } = await axios.get<
		DeptSpecificChangeLookGetResponse<DEPARTMENTS.MU>
	>(`/api/episodics/${episodicId}/episodic-characters/${epCharId}/mu-changes`);
	return data;
}

export async function deleteForEpChar(
	episodicId: number,
	epCharId: number,
	muChangeId: number
): Promise<DeptSpecificChangeLookDeleteResponse<DEPARTMENTS.MU>> {
	const { data } = await axios.delete(
		`/api/episodics/${episodicId}/episodic-characters/${epCharId}/mu-changes/${muChangeId}`
	);
	return data;
}

export async function getMuChangesForScene(
	prodId: number,
	sceneId: number
): Promise<Partial<MuChange>[]> {
	const { data } = await axios.get<Partial<MuChange>[]>(
		`/api/productions/${prodId}/scenes/${sceneId}/mu-changes`
	);
	return data;
}

export async function create(
	episodicId: number,
	department: DEPARTMENTS,
	change: Partial<MuChange>
): Promise<Partial<MuChange>> {
	const { data } = await axios.post<Partial<MuChange>>(
		`/api/episodics/${episodicId}/change-looks/${department}`,
		change
	);
	return data;
}

export async function createByScene(
	episodicId: number,
	department: DEPARTMENTS,
	change: Partial<MuChange>,
	sceneId: number,
	oldChange?: MuChange
): Promise<{
	created: { MuChange: MuChange[]; MuChangeScene: MuChangeScene[] };
	deleted: { MuChangeScene: MuChangeScene[] };
	updated: { MuChange: MuChange[] };
}> {
	const {
		data,
	} = await axios.post(
		`/api/episodics/${episodicId}/change-looks/${department}/scenes/${sceneId}`,
		{ change, oldChange }
	);
	return data;
}

export async function update(
	episodicId: number,
	prodId: number,
	muChanges: Partial<MuChange>[]
): Promise<MuChangeUpdateResponse> {
	const { data } = await axios.put(
		`/api/episodics/${episodicId}/productions/${prodId}/mu-changes`,
		muChanges
	);
	return data;
}
