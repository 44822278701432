import {
	AnyChange,
	ChangeLookDeleteResponse,
	ChangeLookGetResponse,
	ChangeLookUpdateResponse,
	DEPARTMENTS,
} from 'sos-models';
import axios from '../../config/axios';

export async function create(
	changeLook: Partial<AnyChange>,
	departmentId: DEPARTMENTS,
	episodicId: number
): Promise<AnyChange> {
	const { data } = await axios.post<AnyChange>(
		`/api/episodics/${episodicId}/change-looks/${departmentId}`,
		changeLook
	);
	return data;
}

export async function deleteChangeLook(
	changeLookId: number,
	departmentId: DEPARTMENTS,
	episodicId: number
): Promise<ChangeLookDeleteResponse> {
	const { data } = await axios.delete(
		`/api/episodics/${episodicId}/change-looks/${departmentId}/${changeLookId}`
	);
	return data;
}

export async function get(
	changeLookId: number,
	departmentId: DEPARTMENTS,
	episodicId: number
): Promise<ChangeLookGetResponse> {
	const { data } = await axios.get<ChangeLookGetResponse>(
		`/api/episodics/${episodicId}/change-looks/${departmentId}/${changeLookId}`
	);
	return data;
}

export async function update(
	changeLook: Partial<AnyChange>,
	departmentId: DEPARTMENTS,
	episodicId: number
): Promise<ChangeLookUpdateResponse> {
	const { data } = await axios.put<ChangeLookUpdateResponse>(
		`/api/episodics/${episodicId}/change-looks/${departmentId}/${changeLook.id}`,
		changeLook
	);
	return data;
}
