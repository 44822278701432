import {
	Character,
	EpisodicActor,
	EpisodicCharacter,
	EpisodicCharacterCreateResponse,
	EpisodicCharacterDestroyResponse,
	EpisodicCharacterExportToNewSeasonCheckReponse,
	EpisodicCharacterExportToNewSeasonReponse,
	EpisodicCharacterGetResponse,
	EpisodicCharacterListResponse,
	EpisodicCharacterMergePayload,
	EpisodicCharacterMergeResponse,
	EpisodicCharacterUpdateCharNumsResponse,
	EpisodicCharacterUpdateResponse,
	UpdateMultipleEpCharPayload,
} from 'sos-models';
import axios from '../../config/axios';

export interface CountDependenciesData {
	changeCount: number;
	haLookCount: number;
	muLookCount: number;
}

export async function getCountDependenciesInProds(
	episodicId: number,
	epCharId: number,
	prodIds: number[]
): Promise<CountDependenciesData> {
	const { data } = await axios.get<CountDependenciesData>(
		`/api/episodics/${episodicId}/episodic-characters/${epCharId}/count-dependencies`,
		{
			params: {
				prodIds,
			},
		}
	);
	return data;
}

export async function create(
	episodicId: number,
	epChar: Partial<EpisodicCharacter>,
	epActor: Partial<EpisodicActor>,
	prodIds: number[],
	sceneIds: number[]
): Promise<EpisodicCharacterCreateResponse> {
	const { data } = await axios.post<EpisodicCharacterCreateResponse>(
		`/api/episodics/${episodicId}/episodic-characters`,
		{
			epChar,
			epActor,
			prodIds,
			sceneIds,
		}
	);
	return data;
}

export async function destroy(
	episodicId: number,
	episodicCharacterId: number
): Promise<EpisodicCharacterDestroyResponse> {
	const { data } = await axios.delete<EpisodicCharacterDestroyResponse>(
		`/api/episodics/${episodicId}/episodic-characters/${episodicCharacterId}`
	);
	return data;
}

export async function exportToCSV(
	episodicId: number,
	prodIds: number[],
	isPrimary: boolean
) {
	return await axios
		.get(
			`/api/episodics/${episodicId}/productions/${prodIds}/episodic-characters/export`,
			{
				params: {
					isPrimary,
					prodIds
				},
				responseType: 'arraybuffer',
			}
		)
		.then((response) => response.data);
}

export const exportToNewSeason = async (
	episodicId: number,
	newEpisodicId: number,
	epCharIds: number[],
	checkIfExported: boolean
): Promise<
	| EpisodicCharacterExportToNewSeasonReponse
	| EpisodicCharacterExportToNewSeasonCheckReponse
> => {
	const { data } = await axios.put<EpisodicCharacterExportToNewSeasonReponse>(
		`/api/episodics/${episodicId}/episodic-characters/export-to-new-season/${newEpisodicId}`,
		{
			epCharIds,
			checkIfExported,
		}
	);
	return data;
};

export async function list(
	episodicId: number
): Promise<EpisodicCharacterListResponse> {
	const { data } = await axios.get<EpisodicCharacterListResponse>(
		`/api/episodics/${episodicId}/episodic-characters`
	);
	return data;
}

export async function listForEpActor(
	episodicId: number,
	epActorId: number
): Promise<Partial<EpisodicCharacter>[]> {
	const { data } = await axios.get<Partial<EpisodicCharacter>[]>(
		`/api/episodics/${episodicId}/episodic-actors/${epActorId}/episodic-characters`
	);
	return data;
}

export async function listForScene(
	episodicId: number,
	sceneId: number
): Promise<Partial<EpisodicCharacter>[]> {
	const { data } = await axios.get<Partial<EpisodicCharacter>[]>(
		`/api/episodics/${episodicId}/scene/${sceneId}/episodic-characters`
	);
	return data;
}

export async function get(
	episodicCharacterId: number,
	episodicId: number,
	productionIds: number[]
): Promise<EpisodicCharacterGetResponse> {
	const { data } = await axios.get<EpisodicCharacterGetResponse>(
		`/api/episodics/${episodicId}/episodic-characters/${episodicCharacterId}`,
		{
			params: {
				productionIds,
			},
		}
	);
	return data;
}

export async function update(
	episodicId: number,
	epChar: Partial<EpisodicCharacter>,
	epActor: Partial<EpisodicActor>,
	addedProdIds: number[],
	removedProdIds: number[],
	addedSceneIds: number[],
	removedSceneIds: number[]
): Promise<EpisodicCharacterUpdateResponse> {
	const { data } = await axios.put<EpisodicCharacterUpdateResponse>(
		`/api/episodics/${episodicId}/episodic-characters/${epChar.id}`,
		{
			epChar,
			epActor,
			addedProdIds,
			removedProdIds,
			addedSceneIds,
			removedSceneIds,
		}
	);
	return data;
}

export async function updateMultiple(
	episodicId: number,
	payload: UpdateMultipleEpCharPayload
): Promise<EpisodicCharacterUpdateResponse> {
	const { data } = await axios.put<EpisodicCharacterUpdateResponse>(
		`/api/episodics/${episodicId}/episodic-characters`,
		payload
	);
	return data;
}

export async function updateCharNums(
	episodicId: number,
	episodicCharacter: Partial<EpisodicCharacter>,
	characters: Partial<Character>[]
): Promise<EpisodicCharacterUpdateCharNumsResponse> {
	const { data } = await axios.put<EpisodicCharacterUpdateCharNumsResponse>(
		`/api/episodics/${episodicId}/episodic-characters/${episodicCharacter.id}/char-nums`,
		{ episodicCharacter, characters }
	);
	return data;
}

export async function getDeleteCounts(
	episodicId: number,
	episodicCharacterId: number
) {
	const { data } = await axios.get(
		`/api/episodics/${episodicId}/episodic-characters/${episodicCharacterId}/delete-counts`
	);
	return data;
}

export async function getMergeConflicts(
	episodicId: number,
	firstEpCharId: number,
	secondEpCharId: number
) {
	const { data } = await axios.get(
		`/api/episodics/${episodicId}/episodic-characters/${firstEpCharId}/merge`,
		{
			params: {
				secondEpCharId,
			},
		}
	);
	return data;
}

export async function merge(
	episodicId: number,
	firstEpCharId: number,
	secondEpCharId: number,
	payload: EpisodicCharacterMergePayload
): Promise<EpisodicCharacterMergeResponse> {
	const { data } = await axios.put(
		`/api/episodics/${episodicId}/episodic-characters/${firstEpCharId}/merge`,
		{
			payload,
			secondEpCharId,
		}
	);
	return data;
}
