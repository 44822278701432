import { Vendor } from 'sos-models';

export const CREATE = '[Vendors] Create';
type CREATE = typeof CREATE;

export const CREATE_FAILED = '[Vendors] Create Failed';
type CREATE_FAILED = typeof CREATE_FAILED;

export const CREATE_COMPLETE = '[Vendors] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const LIST = '[Vendors] List';
type LIST = typeof LIST;

export const LIST_FAILED = '[Vendors] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const LIST_COMPLETE = '[Vendors] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export interface CreateAction {
	vendor: Vendor;
	episodicId: number;
	department: number;
	type: CREATE;
}

export interface CreateFailedAction {
	error: Error;
	type: CREATE_FAILED;
}

export interface CreateCompleteAction {
	vendor: Vendor;
	type: CREATE_COMPLETE;
}

export interface ListAction {
	episodicId: number;
	type: LIST;
}

export interface ListFailedAction {
	type: LIST_FAILED;
	error: Error;
}

export interface ListCompleteAction {
	type: LIST_COMPLETE;
	vendors: Vendor[];
}

export function create(
	vendor: Vendor,
	episodicId: number,
	department: number
): CreateAction {
	return {
		vendor,
		episodicId,
		department,
		type: CREATE,
	};
}

export function createFailed(error: Error): CreateFailedAction {
	return {
		error,
		type: CREATE_FAILED,
	};
}

export function createComplete(vendor: Vendor): CreateCompleteAction {
	return {
		vendor,
		type: CREATE_COMPLETE,
	};
}

export function list(episodicId: number): ListAction {
	return {
		episodicId,
		type: LIST,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export function listComplete(vendors: Vendor[]): ListCompleteAction {
	return {
		vendors,
		type: LIST_COMPLETE,
	};
}

export type Action =
	| CreateCompleteAction
	| CreateFailedAction
	| CreateCompleteAction
	| ListAction
	| ListFailedAction
	| ListCompleteAction;
