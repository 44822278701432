import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	EpisodicCharacterDepartmentNoteCreateResponse,
	EpisodicCharacterDepartmentNoteUpdateResponse,
} from 'sos-models';
import * as actions from './episodic-character-note.actions';
import * as api from './episodic-character-note.api';
import * as logger from '../../logger';
import {
	cmEpisodicCharacterNoteActions,
	haEpisodicCharacterNoteActions,
	muEpisodicCharacterNoteActions,
	prEpisodicCharacterNoteActions,
} from '../actions';

export function* create({
	episodicId,
	episodicCharacterNotes,
}: actions.CreateAction) {
	try {
		const {
			created,
		}: EpisodicCharacterDepartmentNoteCreateResponse = yield call(
			api.create,
			episodicId,
			episodicCharacterNotes
		);
		yield put(actions.createComplete());
		if (created.CmEpisodicCharacterNote.length) {
			yield put(
				cmEpisodicCharacterNoteActions.createComplete(
					created.CmEpisodicCharacterNote
				)
			);
		}
		if (created.HaEpisodicCharacterNote.length) {
			yield put(
				haEpisodicCharacterNoteActions.createComplete(
					created.HaEpisodicCharacterNote
				)
			);
		}
		if (created.MuEpisodicCharacterNote.length) {
			yield put(
				muEpisodicCharacterNoteActions.createComplete(
					created.MuEpisodicCharacterNote
				)
			);
		}
		if (created.PrEpisodicCharacterNote.length) {
			yield put(
				prEpisodicCharacterNoteActions.createComplete(
					created.PrEpisodicCharacterNote
				)
			);
		}
	} catch (err) {
		yield put(actions.createFailed(err));
		logger.responseError(err, 'creating the character note');
	}
}

export function* list({ episodicId, epCharId }: actions.ListAction) {
	try {
		const {
			cmEpCharNote,
			haEpCharNote,
			muEpCharNote,
			prEpCharNote,
		} = yield call(api.list, episodicId, epCharId);
		yield put(actions.listComplete());

		if (cmEpCharNote) {
			yield put(cmEpisodicCharacterNoteActions.listComplete([cmEpCharNote]));
		}
		if (haEpCharNote) {
			yield put(haEpisodicCharacterNoteActions.listComplete([haEpCharNote]));
		}
		if (muEpCharNote) {
			yield put(muEpisodicCharacterNoteActions.listComplete([muEpCharNote]));
		}
		if (prEpCharNote) {
			yield put(prEpisodicCharacterNoteActions.listComplete([prEpCharNote]));
		}
	} catch (err) {
		yield put(actions.listFailed(err));
		logger.responseError(err, 'loading the episodic character notes', true);
	}
}

export function* update({
	episodicId,
	episodicCharacterNotes,
}: actions.UpdateAction) {
	try {
		const {
			updated,
		}: EpisodicCharacterDepartmentNoteUpdateResponse = yield call(
			api.update,
			episodicId,
			episodicCharacterNotes
		);
		yield put(actions.updateComplete());
		if (updated.CmEpisodicCharacterNote.length) {
			yield put(
				cmEpisodicCharacterNoteActions.updateComplete(
					updated.CmEpisodicCharacterNote
				)
			);
		}

		if (updated.HaEpisodicCharacterNote.length) {
			yield put(
				haEpisodicCharacterNoteActions.updateComplete(
					updated.HaEpisodicCharacterNote
				)
			);
		}

		if (updated.MuEpisodicCharacterNote.length) {
			yield put(
				muEpisodicCharacterNoteActions.updateComplete(
					updated.MuEpisodicCharacterNote
				)
			);
		}

		if (updated.PrEpisodicCharacterNote.length) {
			yield put(
				prEpisodicCharacterNoteActions.updateComplete(
					updated.PrEpisodicCharacterNote
				)
			);
		}
	} catch (err) {
		yield put(actions.updateFailed(err));
		logger.responseError(err, 'saving the character note');
	}
}

function* saga() {
	yield takeEvery(actions.CREATE, create);
	yield takeLatest(actions.LIST, list);
	yield takeEvery(actions.UPDATE, update);
}

export default saga;
