import { Department } from 'sos-models';

export default [
	new Department({
		id: 1,
		name: 'Costumes',
		abbrev: 'CM',
		color: 'rgba(144, 19, 254, 0.5)',
		permType: 'cmPermission',
	}),
	new Department({
		id: 2,
		name: 'Hair',
		abbrev: 'HA',
		color: 'rgba(245, 166, 35, 0.5)',
		permType: 'haPermission',
	}),
	new Department({
		id: 3,
		name: 'Makeup',
		abbrev: 'MU',
		color: 'rgba(208, 2, 27, 0.5)',
		permType: 'muPermission',
	}),
	new Department({
		id: 5,
		name: 'Set Dec',
		abbrev: 'SET',
		color: 'rgba(65, 117, 5, 0.5)',
		permType: 'setPermission',
	}),
	new Department({
		id: 6,
		name: 'Props',
		abbrev: 'PR',
		color: 'rgba(74, 144, 226, 0.5)',
		permType: 'prPermission',
	}),
];
