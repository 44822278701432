import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
	PictureSceneDeleteResponse,
	PictureSceneListResponse,
} from 'sos-models';
import * as actions from './picture-scene.actions';
import * as api from './picture-scene.api';
import * as logger from '../../logger';
import { pictureActions } from '../actions';

export function* destroy({
	pictureSceneId,
	episodicId,
}: actions.DestroyAction) {
	try {
		const picture: PictureSceneDeleteResponse = yield call(
			api.destroy,
			episodicId,
			pictureSceneId
		);
		yield put(actions.destroyComplete(picture.destroyed.PictureScene));
	} catch (err) {
		yield put(actions.destroyFailed(err));
		logger.responseError(err, 'loading the picture', true);
	}
}

export function* list({ prodId, sceneId }: actions.ListAction) {
	try {
		const results: PictureSceneListResponse = yield call(
			api.list,
			prodId,
			sceneId
		);
		if (results.listed.PictureScene) {
			yield put(actions.listComplete(results.listed.PictureScene));
		}
		if (results.listed.Picture) {
			yield put(pictureActions.listComplete(results.listed.Picture));
		}
	} catch (err) {
		yield put(actions.listFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeLatest(actions.DESTROY, destroy);
	yield takeLatest(actions.LIST, list);
}

export default saga;
