import { normalize } from 'normalizr';
import { ProdPermission } from 'sos-models';
import * as permissionActions from '../permissions/permission.actions';
import { prodPermissionSchema } from '../schemas';
import { ActionStatus, createReducer, uniqueArrayMerge } from '../utils';

export interface State {
	entities: { [id: number]: ProdPermission };
	ids: number[];
	loading: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	loading: ActionStatus.Inactive,
};

export const reducer = createReducer<State, permissionActions.Action>(initial, {
	[permissionActions.GET]: getPermission,
	[permissionActions.GET_COMPLETE]: getPermissionComplete,
	[permissionActions.GET_FAILED]: getPermissionFailed,
});

function getPermission(
	state: State,
	action: permissionActions.GetAction
): State {
	return {
		...state,
		loading: ActionStatus.Loading,
	};
}

function getPermissionComplete(
	state: State,
	action: permissionActions.GetCompleteAction
): State {
	let prodPermissions = [];
	action.permissions.forEach((p) => {
		prodPermissions = [...prodPermissions, ...p.prodPermissions];
	});
	const normalized = normalize(prodPermissions, [prodPermissionSchema]);
	return {
		...state,
		entities: { ...state.entities, ...normalized.entities.prodPermissions },
		ids: uniqueArrayMerge(state.ids, normalized.result),
		loading: ActionStatus.Complete,
	};
}

function getPermissionFailed(state: State): State {
	return {
		...state,
		loading: ActionStatus.Failed,
	};
}
