import { UnknownAction } from 'redux';
import { DEPARTMENTS, PRODUCTS } from 'sos-models';

export const SET_DEPT = '[Navbar] Set Department';
type SET_DEPT = typeof SET_DEPT;

export const SET_PRODUCT = '[Navbar] Set Product';
type SET_PRODUCT = typeof SET_PRODUCT;

export const SET_PRODUCTION = '[Navbar] Set Production';
type SET_PRODUCTION = typeof SET_PRODUCTION;

export const SET_REPORTS_BANNER_CLOSED = '[Navbar] Set Report Banner Closed';
type SET_REPORTS_BANNER_CLOSED = typeof SET_REPORTS_BANNER_CLOSED;

export interface SetDeptAction {
	dept: DEPARTMENTS;
	type: SET_DEPT;
}

export interface SetProductAction extends UnknownAction {
	product: PRODUCTS;
	type: SET_PRODUCT;
}

export interface SetProductionAction {
	productionId: number;
	type: SET_PRODUCTION;
}

export interface SetIsReportBannerClosed {
	isClosed: boolean;
	type: SET_REPORTS_BANNER_CLOSED;
}

export function setDept(dept: DEPARTMENTS): SetDeptAction {
	return {
		dept,
		type: SET_DEPT,
	};
}

export function setProduct(product: PRODUCTS): SetProductAction {
	return {
		product,
		type: SET_PRODUCT,
	};
}

export function setProduction(productionId: number): SetProductionAction {
	return {
		productionId,
		type: SET_PRODUCTION,
	};
}

export function setIsReportBannerClosed(
	isClosed: boolean
): SetIsReportBannerClosed {
	return {
		isClosed,
		type: SET_REPORTS_BANNER_CLOSED,
	};
}

export type Action =
	| SetDeptAction
	| SetProductAction
	| SetProductionAction
	| SetIsReportBannerClosed;
