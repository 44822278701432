import { Picture, PictureRelationEntities, PictureUpload } from 'sos-models';

export const GET = '[Picture] Get';
type GET = typeof GET;

export const GET_COMPLETE = '[Picture] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const GET_FAILED = '[Picture] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export const GET_CURRENT_FOR_LIST = '[Picture] Get Current For List';
type GET_CURRENT_FOR_LIST = typeof GET_CURRENT_FOR_LIST;

export const GET_CURRENT_FOR_LIST_COMPLETE =
	'[Picture] Get Current For List Complete';
type GET_CURRENT_FOR_LIST_COMPLETE = typeof GET_CURRENT_FOR_LIST_COMPLETE;

export const GET_CURRENT_FOR_LIST_FAILED =
	'[Picture] Get Current For List Failed';
type GET_CURRENT_FOR_LIST_FAILED = typeof GET_CURRENT_FOR_LIST_FAILED;

export const DESTROY = '[Picture] Destroy';
type DESTROY = typeof DESTROY;

export const DESTROY_COMPLETE = '[Picture] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const DESTROY_FAILED = '[Picture] Destroy Failed';
type DESTROY_FAILED = typeof DESTROY_FAILED;

export const LIST_PHOTO_TAGS = '[Picture] List Photo Tags';
type LIST_PHOTO_TAGS = typeof LIST_PHOTO_TAGS;

export const LIST_PHOTO_TAGS_COMPLETE = '[Picture] List Photo Tags Complete';
type LIST_PHOTO_TAGS_COMPLETE = typeof LIST_PHOTO_TAGS_COMPLETE;

export const LIST_PHOTO_TAGS_FAILED = '[Picture] List Photo Tags Failed';
type LIST_PHOTO_TAGS_FAILED = typeof LIST_PHOTO_TAGS_FAILED;

export const LIST_COMPLETE = '[Picture] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const ROTATE = '[Picture] Rotate';
type ROTATE = typeof ROTATE;

export const ROTATE_COMPLETE = '[Picture] Rotate Complete';
type ROTATE_COMPLETE = typeof ROTATE_COMPLETE;

export const ROTATE_FAILED = '[Picture] Rotate Failed';
type ROTATE_FAILED = typeof ROTATE_FAILED;

export const SET_MAIN_PHOTO = '[Picture] Set Main Photo';
type SET_MAIN_PHOTO = typeof SET_MAIN_PHOTO;

export const SET_MAIN_PHOTO_COMPLETE = '[Picture] Set Main Photo Complete';
type SET_MAIN_PHOTO_COMPLETE = typeof SET_MAIN_PHOTO_COMPLETE;

export const SET_MAIN_PHOTO_FAILED = '[Picture] Set Main Photo Failed';
type SET_MAIN_PHOTO_FAILED = typeof SET_MAIN_PHOTO_FAILED;

export const UPDATE = '[Picture] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[Picture] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[Picture] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const UPLOAD = '[Picture] Upload Pictures';
type UPLOAD = typeof UPLOAD;

export const UPLOAD_COMPLETE = '[Picture] Upload Picture Complete';
type UPLOAD_COMPLETE = typeof UPLOAD_COMPLETE;

export const UPLOAD_FAILED = '[Picture] Upload Pictures Failed';
type UPLOAD_FAILED = typeof UPLOAD_FAILED;

export interface GetAction {
	episodicId: number;
	pictureId: number;
	type: GET;
}

export interface GetCompleteAction {
	picture: Picture;
	type: GET_COMPLETE;
}

export interface GetFailedAction {
	error: Error;
	type: GET_FAILED;
}

export interface GetCurrentForListAction {
	episodicId: number;
	pictureId: number;
	type: GET_CURRENT_FOR_LIST;
}

export interface GetCurrentForListCompleteAction {
	picture: Picture;
	type: GET_CURRENT_FOR_LIST_COMPLETE;
}

export interface GetCurrentForListFailedAction {
	error: Error;
	type: GET_CURRENT_FOR_LIST_FAILED;
}

export interface DestroyAction {
	episodicId: number;
	pictureId: number;
	type: DESTROY;
}

export interface DestroyCompleteAction {
	picture: Picture[];
	type: DESTROY_COMPLETE;
}

export interface DestroyFailedAction {
	error: Error;
	type: DESTROY_FAILED;
}

export interface ListPhotoTagsAction {
	episodicId: number;
	pictureId: number;
	type: LIST_PHOTO_TAGS;
}

export interface ListPhotoTagsCompleteAction {
	type: LIST_PHOTO_TAGS_COMPLETE;
}

export interface ListPhotoTagsFailedAction {
	error: Error;
	type: LIST_PHOTO_TAGS_FAILED;
}

export interface ListCompleteAction {
	pictures: Picture[];
	type: LIST_COMPLETE;
}

export interface RotateAction {
	episodicId: number;
	pictureId: number;
	type: ROTATE;
}

export interface RotateCompleteAction {
	picture: Picture;
	type: ROTATE_COMPLETE;
}

export interface RotateFailedAction {
	error: Error;
	type: ROTATE_FAILED;
}

export interface SetMainPhotoAction {
	episodicId: number;
	pictureId: number;
	pictureRelationEntity: PictureRelationEntities;
	type: SET_MAIN_PHOTO;
}

export interface SetMainPhotoCompleteAction {
	type: SET_MAIN_PHOTO_COMPLETE;
}

export interface SetMainPhotoFailedAction {
	error: Error;
	type: SET_MAIN_PHOTO_FAILED;
}

export interface UpdateAction {
	episodicId: number;
	picture: Partial<Picture>;
	type: UPDATE;
}

export interface UpdateCompleteAction {
	picture: Picture;
	type: UPDATE_COMPLETE;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export interface UploadAction {
	departmentId: number;
	episodicId: number;
	pictureUploadBody: PictureUpload;
	type: UPLOAD;
}

export interface UploadFailedAction {
	error: Error;
	type: UPLOAD_FAILED;
}

export interface UploadCompleteAction {
	type: UPLOAD_COMPLETE;
}

export function get(episodicId: number, pictureId: number): GetAction {
	return {
		episodicId,
		pictureId,
		type: GET,
	};
}

export function getComplete(picture: Picture): GetCompleteAction {
	return {
		picture,
		type: GET_COMPLETE,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	};
}

export function getCurrentForList(
	episodicId: number,
	pictureId: number
): GetCurrentForListAction {
	return {
		episodicId,
		pictureId,
		type: GET_CURRENT_FOR_LIST,
	};
}

export function getCurrentForListComplete(
	picture: Picture
): GetCurrentForListCompleteAction {
	return {
		picture,
		type: GET_CURRENT_FOR_LIST_COMPLETE,
	};
}

export function getCurrentForListFailed(
	error: Error
): GetCurrentForListFailedAction {
	return {
		error,
		type: GET_CURRENT_FOR_LIST_FAILED,
	};
}

export function destroy(episodicId: number, pictureId: number): DestroyAction {
	return {
		episodicId,
		pictureId,
		type: DESTROY,
	};
}

export function destroyComplete(picture: Picture[]): DestroyCompleteAction {
	return {
		picture,
		type: DESTROY_COMPLETE,
	};
}

export function destroyFailed(error: Error): DestroyFailedAction {
	return {
		error,
		type: DESTROY_FAILED,
	};
}

export function listPhotoTags(
	episodicId: number,
	pictureId: number
): ListPhotoTagsAction {
	return {
		episodicId,
		pictureId,
		type: LIST_PHOTO_TAGS,
	};
}

export function listPhotoTagsComplete(): ListPhotoTagsCompleteAction {
	return {
		type: LIST_PHOTO_TAGS_COMPLETE,
	};
}

export function listPhotoTagsFailed(error: Error): ListPhotoTagsFailedAction {
	return {
		error,
		type: LIST_PHOTO_TAGS_FAILED,
	};
}

export function listComplete(pictures: Picture[]): ListCompleteAction {
	return {
		pictures,
		type: LIST_COMPLETE,
	};
}

export function rotatePhoto(
	episodicId: number,
	pictureId: number
): RotateAction {
	return {
		episodicId,
		pictureId,
		type: ROTATE,
	};
}

export function rotateComplete(picture: Picture): RotateCompleteAction {
	return {
		picture,
		type: ROTATE_COMPLETE,
	};
}

export function rotateFailed(error: Error): RotateFailedAction {
	return {
		error,
		type: ROTATE_FAILED,
	};
}

export function setMainPhoto(
	episodicId: number,
	pictureId: number,
	pictureRelationEntity: PictureRelationEntities
): SetMainPhotoAction {
	return {
		episodicId,
		pictureId,
		pictureRelationEntity,
		type: SET_MAIN_PHOTO,
	};
}

export function setMainPhotoComplete(): SetMainPhotoCompleteAction {
	return {
		type: SET_MAIN_PHOTO_COMPLETE,
	};
}

export function setMainPhotoFailed(error: Error): SetMainPhotoFailedAction {
	return {
		error,
		type: SET_MAIN_PHOTO_FAILED,
	};
}

export function update(
	episodicId: number,
	picture: Partial<Picture>
): UpdateAction {
	return {
		episodicId,
		picture,
		type: UPDATE,
	};
}

export function updateComplete(picture: Picture): UpdateCompleteAction {
	return {
		picture,
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function upload(
	departmentId: number,
	episodicId: number,
	pictureUploadBody: PictureUpload
): UploadAction {
	return {
		departmentId,
		episodicId,
		pictureUploadBody,
		type: UPLOAD,
	};
}

export function uploadComplete(): UploadCompleteAction {
	return {
		type: UPLOAD_COMPLETE,
	};
}

export function uploadFailed(error: Error): UploadFailedAction {
	return {
		error,
		type: UPLOAD_FAILED,
	};
}

export type Action =
	| GetAction
	| GetCompleteAction
	| GetFailedAction
	| GetCurrentForListAction
	| GetCurrentForListCompleteAction
	| GetCurrentForListFailedAction
	| DestroyAction
	| DestroyCompleteAction
	| DestroyFailedAction
	| ListPhotoTagsAction
	| ListPhotoTagsCompleteAction
	| ListPhotoTagsFailedAction
	| ListCompleteAction
	| RotateAction
	| RotateCompleteAction
	| RotateFailedAction
	| SetMainPhotoAction
	| SetMainPhotoCompleteAction
	| SetMainPhotoFailedAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction
	| UploadAction
	| UploadCompleteAction
	| UploadFailedAction;
