import { InventoryFieldSetting } from 'sos-models';

export const GET = '[InventoryFieldSetting] Get';
type GET = typeof GET;

export const GET_COMPLETE = '[InventoryFieldSetting] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const GET_FAILED = '[InventoryFieldSetting] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export const UPDATE = '[InventoryFieldSetting] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[InventoryFieldSetting] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[InventoryFieldSetting] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export interface GetAction {
	type: GET;
}

export interface GetFailedAction {
	type: GET_FAILED;
	error: Error;
}

export interface GetCompleteAction {
	type: GET_COMPLETE;
	inventoryFieldSetting: InventoryFieldSetting;
}

export interface UpdateAction {
	type: UPDATE;
	inventoryFieldSetting: Partial<InventoryFieldSetting>;
}

export interface UpdateFailedAction {
	type: UPDATE_FAILED;
	error: Error;
}

export interface UpdateCompleteAction {
	type: UPDATE_COMPLETE;
	inventoryFieldSetting: InventoryFieldSetting;
}

export function get(): GetAction {
	return {
		type: GET,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	};
}

export function getComplete(
	inventoryFieldSetting: InventoryFieldSetting
): GetCompleteAction {
	return {
		inventoryFieldSetting,
		type: GET_COMPLETE,
	};
}

export function update(
	inventoryFieldSetting: Partial<InventoryFieldSetting>
): UpdateAction {
	return {
		inventoryFieldSetting,
		type: UPDATE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function updateComplete(
	inventoryFieldSetting: InventoryFieldSetting
): UpdateCompleteAction {
	return {
		inventoryFieldSetting,
		type: UPDATE_COMPLETE,
	};
}

export type Action =
	| GetAction
	| GetFailedAction
	| GetCompleteAction
	| UpdateAction
	| UpdateFailedAction
	| UpdateCompleteAction;
