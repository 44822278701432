import {
	EpisodicItemsForCharacterListResponse,
	EpisodicItemsWithoutCharacterListResponse,
} from 'sos-models';
import axios from '../../config/axios';

export async function listEpItemsForEpChar(
	episodicId: number,
	epCharId: number
): Promise<EpisodicItemsForCharacterListResponse> {
	return await axios
		.get<EpisodicItemsForCharacterListResponse>(
			`/api/episodics/${episodicId}/episodic-characters/${epCharId}/episodic-items`
		)
		.then((response) => response.data);
}

export async function listNoClosetEpItems(
	episodicId: number
): Promise<EpisodicItemsForCharacterListResponse> {
	return await axios
		.get<EpisodicItemsWithoutCharacterListResponse>(
			`/api/episodics/${episodicId}/no-closet-episodic-items`
		)
		.then((response) => response.data);
}
