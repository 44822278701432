import { omit } from 'lodash';
import { without } from 'lodash';
import { normalize } from 'normalizr';
import { PropCategory } from 'sos-models';
import * as propCategoryActions from './prop-category.actions';
import * as inventorySatelliteActions from '../inventory-satellites/inventory-satellite.actions';
import * as inventoryActions from '../inventory/inventory.actions';
import { propCategorySchema } from '../schemas';
import {
	ActionStatus,
	createComplete,
	createReducer,
	failed,
	loading,
} from '../utils';

export interface State {
	entities: { [id: number]: PropCategory };
	ids: number[];
	createStatus: ActionStatus;
	destroyStatus: ActionStatus;
	listStatus: ActionStatus;
	getStatus: ActionStatus;
	updateStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	createStatus: ActionStatus.Inactive,
	destroyStatus: ActionStatus.Inactive,
	listStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	| propCategoryActions.Action
	| inventoryActions.Action
	| inventorySatelliteActions.Action
>(initial, {
	[inventorySatelliteActions.LIST]: loading<State>('listStatus'),
	[inventorySatelliteActions.LIST_COMPLETE]: listComplete,
	[inventorySatelliteActions.LIST_FAILED]: failed<State>('listStatus'),
	[propCategoryActions.CREATE]: loading<State>('createStatus'),
	[propCategoryActions.CREATE_COMPLETE]: createComplete<
		PropCategory,
		State,
		propCategoryActions.CreateCompleteAction
	>('propCategory', propCategorySchema),
	[propCategoryActions.CREATE_FAILED]: failed<State>('createStatus'),
	[propCategoryActions.DESTROY]: loading<State>('destroyStatus'),
	[propCategoryActions.DESTROY_COMPLETE]: destroyComplete,
	[propCategoryActions.DESTROY_FAILED]: failed<State>('destroyStatus'),
	[propCategoryActions.UPDATE]: loading<State>('updateStatus'),
	[propCategoryActions.UPDATE_COMPLETE]: updateComplete,
	[propCategoryActions.UPDATE_FAILED]: failed<State>('updateStatus'),
});

function listComplete(
	state: State,
	action: inventorySatelliteActions.ListCompleteAction
): State {
	if (!action.inventorySatellites.propCategories) {
		return {
			...state,
			listStatus: ActionStatus.Complete,
		};
	}
	const normalized = normalize(action.inventorySatellites.propCategories, [
		propCategorySchema,
	]);
	return {
		...state,
		entities: normalized.entities.propCategories,
		ids: normalized.result,
		listStatus: ActionStatus.Complete,
	};
}

function destroyComplete(
	state: State,
	{ propCategory }: propCategoryActions.DestroyCompleteAction
): State {
	const entities = omit(state.entities, propCategory.id);
	const ids = without(state.ids, propCategory.id);
	return {
		...state,
		entities,
		ids,
		destroyStatus: ActionStatus.Complete,
	};
}

function updateComplete(
	state: State,
	action: propCategoryActions.UpdateCompleteAction
): State {
	const normalized = normalize(action.propCategory, propCategorySchema);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.propCategories,
		},
		updateStatus: ActionStatus.Complete,
	};
}
