import { EpisodicPropsForCharacterListResponse } from 'sos-models';
import axios from '../../config/axios';

export async function list(
	episodicCharacterId: number,
	episodicId: number
): Promise<EpisodicPropsForCharacterListResponse> {
	const { data } = await axios.get<EpisodicPropsForCharacterListResponse>(
		`/api/episodics/${episodicId}/character/${episodicCharacterId}/props`
	);
	return data;
}
