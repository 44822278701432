import { normalize } from 'normalizr';
import * as actions from './inventory-field-setting.actions';
import { InventoryFieldSetting } from 'sos-models';
import { inventoryFieldSettingSchema } from '../schemas';
import { ActionStatus, createReducer, failed, loading } from '../utils';

export interface State {
	entity: InventoryFieldSetting;
	getStatus: ActionStatus;
	updateStatus: ActionStatus;
}

const initial: State = {
	entity: null,
	getStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.GET]: loading<State>('getStatus'),
	[actions.GET_COMPLETE]: getComplete,
	[actions.GET_FAILED]: failed<State>('getStatus'),
	[actions.UPDATE]: loading<State>('updateStatus'),
	[actions.UPDATE_COMPLETE]: updateComplete,
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
});

function getComplete(state: State, action: actions.GetCompleteAction): State {
	const normalized = normalize(
		action.inventoryFieldSetting,
		inventoryFieldSettingSchema
	);
	return {
		...state,
		entity: normalized.entities.inventoryFieldSettings[normalized.result],
		getStatus: ActionStatus.Complete,
	};
}

function updateComplete(
	state: State,
	action: actions.UpdateCompleteAction
): State {
	const normalized = normalize(
		action.inventoryFieldSetting,
		inventoryFieldSettingSchema
	);
	return {
		...state,
		entity: normalized.entities.inventoryFieldSettings[normalized.result],
		updateStatus: ActionStatus.Complete,
	};
}
