let hosts = require('../../../yii/hosts.json');

export class IFrameService {
	static id = 'i_frame_nate';

	static getOrigin() {
		if (process.env.SOS_ENV === 'local') {
			return `http://${hosts[process.env.SOS_ENV]}`;
		} else {
			return `https://${hosts[process.env.SOS_ENV]}`;
		}
	}

	// tslint:disable-next-line
	static sendMessage(msg: { type: string; [key: string]: any }) {
		// tslint:disable-next-line
		const iframe: any = document.getElementById(IFrameService.id);
		if (iframe) {
			iframe.contentWindow.postMessage(
				JSON.stringify(msg),
				IFrameService.getOrigin()
			);
		} else if (window.top.location.href.includes('angular')) {
			window.dispatchEvent(new CustomEvent('navBarChange', { detail: msg }));
		}
	}
}
