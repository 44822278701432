import {
	PhotoTagListResponse,
	Picture,
	PictureDeleteResponse,
	PictureGetResponse,
	PictureRelationEntities,
	PictureSetMainPhotoResponse,
	PictureUpdateResponse,
	PictureUpload,
} from 'sos-models';
import axios from '../../config/axios';

export async function destroy(
	episodicId: number,
	pictureId: number
): Promise<PictureDeleteResponse> {
	const { data } = await axios.delete(
		`/api/episodics/${episodicId}/pictures/${pictureId}`
	);
	return data;
}

export async function get(
	episodicId: number,
	pictureId: number
): Promise<PictureGetResponse> {
	const { data } = await axios.get<PictureGetResponse>(
		`/api/episodics/${episodicId}/pictures/${pictureId}`
	);
	return data;
}

export async function listPhotoTags(
	episodicId: number,
	pictureId: number
): Promise<PhotoTagListResponse> {
	const { data } = await axios.get<PhotoTagListResponse>(
		`/api/episodics/${episodicId}/pictures/${pictureId}/tags`
	);
	return data;
}

export async function rotate(
	episodicId: number,
	pictureId: number
): Promise<PictureUpdateResponse> {
	const { data } = await axios.put<PictureUpdateResponse>(
		`/api/episodics/${episodicId}/pictures/${pictureId}/rotate`
	);
	return data;
}

export async function setMainPhoto(
	episodicId: number,
	pictureId: number,
	pictureRelationEntity: PictureRelationEntities
): Promise<PictureSetMainPhotoResponse> {
	const { data } = await axios.put<PictureSetMainPhotoResponse>(
		`/api/episodics/${episodicId}/pictures/${pictureId}/main`,
		pictureRelationEntity
	);
	return data;
}

export async function update(
	episodicId: number,
	picture: Partial<Picture>
): Promise<PictureUpdateResponse> {
	const { data } = await axios.put<PictureUpdateResponse>(
		`/api/episodics/${episodicId}/pictures/${picture.id}`,
		picture
	);
	return data;
}

export async function upload(
	departmentId: number,
	episodicId: number,
	pictureUploadBody: PictureUpload
) {
	return await axios.post(
		`/api/episodics/${episodicId}/pictures/${departmentId}`,
		pictureUploadBody
	);
}
