import { PrEpisodicPropPicture } from 'sos-models';

export const DESTROY = '[PR Episodic Prop Picture Pictures] Destroy';
type DESTROY = typeof DESTROY;

export const DESTROY_COMPLETE = '[PR Episodic Prop Picture] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const DESTROY_FAILED =
	'[PR Episodic Prop Picture Pictures] Destroy Failed';
type DESTROY_FAILED = typeof DESTROY_FAILED;

export const LIST_COMPLETE = '[PR Episodic Prop Pictures] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export interface DestroyAction {
	prEpisodicPropPictureId: number;
	episodicId: number;
	type: DESTROY;
}

export interface DestroyCompleteAction {
	prEpisodicPropPicture: PrEpisodicPropPicture[];
	type: DESTROY_COMPLETE;
}

export interface DestroyFailedAction {
	error: Error;
	type: DESTROY_FAILED;
}

export interface ListCompleteAction {
	prEpisodicPropPictures: PrEpisodicPropPicture[];
	type: LIST_COMPLETE;
}

export function destroy(
	episodicId: number,
	prEpisodicPropPictureId: number
): DestroyAction {
	return {
		episodicId,
		prEpisodicPropPictureId,
		type: DESTROY,
	};
}

export function destroyComplete(
	prEpisodicPropPicture: PrEpisodicPropPicture[]
): DestroyCompleteAction {
	return {
		prEpisodicPropPicture,
		type: DESTROY_COMPLETE,
	};
}

export function destroyFailed(error: Error): DestroyFailedAction {
	return {
		error,
		type: DESTROY_FAILED,
	};
}

export function listComplete(
	prEpisodicPropPictures: PrEpisodicPropPicture[]
): ListCompleteAction {
	return {
		prEpisodicPropPictures,
		type: LIST_COMPLETE,
	};
}

export type Action =
	| DestroyAction
	| DestroyCompleteAction
	| DestroyFailedAction
	| ListCompleteAction;
