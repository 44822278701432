import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	ScriptLocationCreateResponse,
	ScriptLocationListResponse,
	ScriptLocationUpdateResponse,
	ScriptLocationDeleteResponse,
} from 'sos-models';
import * as actions from './script-location.actions';
import * as api from './script-location.api';
import * as logger from '../../logger';

export function* list({ episodicId }: actions.ListAction) {
	try {
		const result: ScriptLocationListResponse = yield call(api.list, episodicId);
		yield put(actions.listComplete(result.listed.ScriptLocation));
	} catch (err) {
		yield put(actions.listFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading the script locations.  Please refresh to try again.'
			);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

export function* create({ episodicId, scriptLocations }: actions.CreateAction) {
	try {
		const result: ScriptLocationCreateResponse = yield call(
			api.create,
			episodicId,
			scriptLocations
		);
		yield put(actions.createComplete(result.created.ScriptLocation));
	} catch (err) {
		yield put(actions.createFailed(err));
		if (err.response) {
			toast.error(
				'There was an error creating the script locations.  Please refresh to try again.'
			);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

export function* update({ episodicId, scriptLocations }: actions.UpdateAction) {
	try {
		const result: ScriptLocationUpdateResponse = yield call(
			api.update,
			episodicId,
			scriptLocations
		);
		yield put(actions.updateComplete(result.updated.ScriptLocations));
	} catch (err) {
		yield put(actions.updateFailed(err));
		if (err.response) {
			toast.error(
				'There was an error updating the script locations.  Please refresh to try again.'
			);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

export function* destroy({
	episodicId,
	scriptLocationId,
}: actions.DestroyAction) {
	try {
		const result: ScriptLocationDeleteResponse = yield call(
			api.destroy,
			episodicId,
			scriptLocationId
		);

		yield put(actions.destroyComplete(result.destroyed.ScriptLocations));
	} catch (err) {
		yield put(actions.destroyFailed(err));
		if (err.response) {
			toast.error(
				'There was an error deleting the script locations.  Please refresh to try again.'
			);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeLatest(actions.LIST, list);
	yield takeEvery(actions.CREATE, create);
	yield takeLatest(actions.UPDATE, update);
	yield takeEvery(actions.DESTROY, destroy);
}

export default saga;
