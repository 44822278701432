import { HaSceneNote } from 'sos-models';
import * as actions from './ha-scene-note.actions';
import { haSceneNoteSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createComplete,
	createReducer,
	destroyComplete,
	getComplete,
	updateComplete,
} from '../utils';

export interface State extends BaseReducerState<HaSceneNote> {
	createStatus: ActionStatus;
	updateStatus: ActionStatus;
	getStatus: ActionStatus;
	destroyStatus: ActionStatus;
}

const initial: State = {
	ids: [],
	entities: {},
	createStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
	destroyStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.CREATE_COMPLETE]: createComplete<
		HaSceneNote,
		State,
		actions.CreateCompleteAction
	>('haSceneNote', haSceneNoteSchema),
	[actions.GET_COMPLETE]: getComplete<
		HaSceneNote,
		State,
		actions.GetCompleteAction
	>('haSceneNote', haSceneNoteSchema),
	[actions.UPDATE_COMPLETE]: updateComplete<
		HaSceneNote,
		State,
		actions.UpdateCompleteAction
	>('haSceneNote', haSceneNoteSchema),
	[actions.DESTROY_COMPLETE]: destroyComplete<
		HaSceneNote,
		State,
		actions.DestroyCompleteAction
	>('haSceneNote'),
});
