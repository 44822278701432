import { User } from 'sos-models';
// import axios from '../../config/axios';

export function get() {
	return new User({
		id: 60,
		email: 'nate.almeida@gmail.com',
	});
	// return axios.get<Partial<User>>('/api/users')
	// 	.then(response => new User(response.data));
}
