import { MuChangeFieldNames } from 'sos-models';

export const GET = '[MuChangeFieldNames] Get';
type GET = typeof GET;

export const GET_COMPLETE = '[MuChangeFieldNames] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const GET_FAILED = '[MuChangeFieldNames] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export interface GetAction {
	episodicId: number;
	type: GET;
}

export interface GetCompleteAction {
	muChangeFieldName: MuChangeFieldNames;
	type: GET_COMPLETE;
}

export interface GetFailedAction {
	error: Error;
	type: GET_FAILED;
}

export function get(episodicId: number): GetAction {
	return {
		episodicId,
		type: GET,
	};
}

export function getComplete(
	muChangeFieldName: MuChangeFieldNames
): GetCompleteAction {
	return {
		muChangeFieldName,
		type: GET_COMPLETE,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	};
}

export type Action = GetAction | GetCompleteAction | GetFailedAction;
