import { Set } from 'sos-models';

export const EXPORT_TO_CSV = '[Sets] Export to CSV';
type EXPORT_TO_CSV = typeof EXPORT_TO_CSV;

export const EXPORT_TO_CSV_FAILED = '[Sets] Export to CSV Failed';
type EXPORT_TO_CSV_FAILED = typeof EXPORT_TO_CSV_FAILED;

export const EXPORT_TO_CSV_COMPLETE = '[Sets] Export to CSV Complete';
type EXPORT_TO_CSV_COMPLETE = typeof EXPORT_TO_CSV_COMPLETE;

export const LIST = '[Sets] List';
type LIST = typeof LIST;

export const LIST_FAILED = '[Sets] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const LIST_COMPLETE = '[Sets] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export interface ExportToCSVAction {
	episodicId: number;
	prodIds: number[];
	type: EXPORT_TO_CSV;
}

export interface ExportToCSVCompleteAction {
	fileContent: string;
	type: EXPORT_TO_CSV_COMPLETE;
}

export interface ExportToCSVFailedAction {
	error: Error;
	type: EXPORT_TO_CSV_FAILED;
}

export interface ListAction {
	episodicId: number;
	type: LIST;
}

export interface ListFailedAction {
	type: LIST_FAILED;
	error: Error;
}

export interface ListCompleteAction {
	type: LIST_COMPLETE;
	sets: Set[];
}

export function exportToCSV(
	episodicId: number,
	prodIds: number[]
): ExportToCSVAction {
	return {
		episodicId,
		prodIds,
		type: EXPORT_TO_CSV,
	};
}

export function exportToCSVComplete(
	fileContent: string
): ExportToCSVCompleteAction {
	return {
		fileContent,
		type: EXPORT_TO_CSV_COMPLETE,
	};
}

export function exportToCSVFailed(error: Error): ExportToCSVFailedAction {
	return {
		error,
		type: EXPORT_TO_CSV_FAILED
	};
}

export function list(episodicId: number): ListAction {
	return {
		episodicId,
		type: LIST,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export function listComplete(sets: Set[]): ListCompleteAction {
	return {
		sets,
		type: LIST_COMPLETE,
	};
}

export type Action =
	ExportToCSVAction
	| ExportToCSVCompleteAction
	| ExportToCSVFailedAction
	| ListAction
	| ListFailedAction
	| ListCompleteAction;
