import {
	BgCharSearchResponse,
	DEPARTMENTS,
	UniversalSearchResponse,
} from 'sos-models';
import axios from '../../config/axios';

export async function search(
	episodicId: number,
	productions: number[],
	departments: DEPARTMENTS[],
	query: string
): Promise<UniversalSearchResponse> {
	const { data } = await axios.get<UniversalSearchResponse>(
		`/api/episodics/${episodicId}/search`,
		{
			params: {
				query,
				productions,
				departments,
			},
		}
	);
	return data;
}

export async function searchBackgroundCharacters(
	episodicId: number,
	productions: number[],
	departments: DEPARTMENTS[],
	sceneId: number,
	query: string
): Promise<BgCharSearchResponse> {
	const { data } = await axios.get<BgCharSearchResponse>(
		`/api/episodics/${episodicId}/search-bg-chars`,
		{
			params: {
				query,
				productions,
				departments,
				sceneId,
			},
		}
	);
	return data;
}
