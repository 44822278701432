import { State } from '..';

export const getState = (state: State) => state.episodics;
export const getEntities = (state: State) => state.episodics.entities;
export const getGetStatus = (state: State) => state.episodics.getStatus;
export const getListStatus = (state: State) => state.episodics.listStatus;
export const getUpdateStatus = (state: State) => state.episodics.updateStatus;
export const getIds = (state: State) => state.episodics.ids;
export const getCurrentId = (state: State) => state.episodics.currentId;
export const getCurrentLogoPictureId = (state: State) =>
	state.episodics.currentLogoPictureId;
export const getPictureCountsByDept = (state: State) =>
	state.episodics.pictureCountsByDept;
