import {
	EpisodicProp,
	EpisodicPropsForCharacterListResponse,
	EpisodicPropsWithoutCharacterListResponse,
	ScenePropListResponse,
} from 'sos-models';
import axios from '../../config/axios';

export async function exportToCSV(
	episodicId: number,
	prodId: number,
	sortQuery?: string
) {
	console.log('we claling this?');
	let queryParams = [];
	const pushQueryParams = (queryParam, queryValue) => {
		queryParams.push(
			`${queryParam}=${encodeURIComponent(queryValue.toString())}`
		);
	};

	if (sortQuery) {
		pushQueryParams('sortQuery', sortQuery);
	}

	return await axios
		.get(
			`/api/episodics/${episodicId}/productions/${prodId}/episodic-props/export${
				queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
			}`,
			{
				responseType: 'arraybuffer',
			}
		)
		.then((response) => response.data);
}

export function getForCharScenes(
	episodicId: number,
	episodicCharacterId: number
) {
	return axios
		.get<Partial<EpisodicProp>[]>(
			`/api/episodics/${episodicId}/episodic-characters/${episodicCharacterId}/scenes/episodic-props`
		)
		.then((response) => response.data);
}

export async function listForScene(
	sceneId: number,
	episodicId: number
): Promise<ScenePropListResponse> {
	return await axios
		.get<ScenePropListResponse>(
			`/api/episodics/${episodicId}/scenes/${sceneId}/episodic-props`
		)
		.then((response) => response.data);
}

export async function listNoClosetEpProps(
	episodicId: number
): Promise<EpisodicPropsForCharacterListResponse> {
	return await axios
		.get<EpisodicPropsWithoutCharacterListResponse>(
			`/api/episodics/${episodicId}/no-closet-episodic-props`
		)
		.then((response) => response.data);
}
