import { UnknownAction } from 'redux';
import { Account } from 'sos-models';

export const LIST_COMPLETE = '[Accounts] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export interface ListCompleteAction extends UnknownAction {
	type: LIST_COMPLETE;
	accounts: Account[];
}

export function listComplete(accounts: Account[]): ListCompleteAction {
	return {
		type: LIST_COMPLETE,
		accounts,
	};
}

export type Action = ListCompleteAction;
