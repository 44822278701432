import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './mu-change-field-names.actions';
import * as api from './mu-change-field-names.api';
import * as logger from '../../logger';

export function* get({ episodicId }: actions.GetAction) {
	try {
		const fieldNames = yield call(api.get, episodicId);
		yield put(actions.getComplete(fieldNames));
	} catch (err) {
		yield put(actions.getFailed(err));
		logger.responseError(err, 'loading the ha change field names', true);
	}
}

function* saga() {
	yield takeLatest(actions.GET, get);
}

export default saga;
