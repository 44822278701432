import { call, put, takeLatest } from 'redux-saga/effects';
import { CmEpisodicItemPictureDeleteResponse } from 'sos-models';
import * as actions from './cm-episodic-item-picture.actions';
import * as api from './cm-episodic-item-picture.api';
import * as logger from '../../logger';

export function* destroy({
	cmEpisodicItemPictureId,
	episodicId,
}: actions.DestroyAction) {
	try {
		const result: CmEpisodicItemPictureDeleteResponse = yield call(
			api.destroy,
			episodicId,
			cmEpisodicItemPictureId
		);
		yield put(actions.destroyComplete(result.destroyed.CmEpisodicItemPicture));
	} catch (err) {
		yield put(actions.destroyFailed(err));
		logger.responseError(err, 'loading the picture', true);
	}
}

function* saga() {
	yield takeLatest(actions.DESTROY, destroy);
}

export default saga;
