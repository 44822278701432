export const LOGOUT = '[Site] Logout';
type LOGOUT = typeof LOGOUT;

export const LOGOUT_COMPLETE = '[Site] Logout Complete';
type LOGOUT_COMPLETE = typeof LOGOUT_COMPLETE;

export const LOGOUT_FAILED = '[Site] Logout Failed';
type LOGOUT_FAILED = typeof LOGOUT_FAILED;

export interface LogoutAction {
	type: LOGOUT;
}

export interface LogoutCompleteAction {
	type: LOGOUT_COMPLETE;
}

export interface LogoutFailedAction {
	type: LOGOUT_FAILED;
	error: Error;
}

export function logout(): LogoutAction {
	return {
		type: LOGOUT,
	};
}

export function logoutComplete(): LogoutCompleteAction {
	return {
		type: LOGOUT_COMPLETE,
	};
}

export function logoutFailed(error: Error): LogoutFailedAction {
	return {
		error,
		type: LOGOUT_FAILED,
	};
}

export type Action = LogoutAction | LogoutCompleteAction | LogoutFailedAction;
