import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	ChangeLookCreateResponse,
	ChangeLookDeleteResponse,
	ChangeLookGetResponse,
	ChangeScene,
	DEPARTMENTS,
	DeptSpecificChangeLookCreateResponse,
	DeptSpecificChangeLookDeleteResponse,
	DeptSpecificChangeLookGetResponse,
	DeptSpecificChangeLookUpdateResponse,
	HaChangeScene,
	MuChangeScene,
} from 'sos-models';
import * as changeLookActions from './change-look.actions';
import * as api from './change-look.api';
import * as logger from '../../logger';
import {
	changeActions,
	changeEpisodicItemActions,
	changeSceneActions,
	deptChangeSceneActions,
	haChangeActions,
	haChangeSceneActions,
	muChangeActions,
	muChangeSceneActions,
	wrapBoxActions,
} from '../actions';

export function* deleteChangeLook({
	changeLookId,
	departmentId,
	episodicId,
}: changeLookActions.DeleteAction) {
	try {
		const result: ChangeLookDeleteResponse = yield call(
			api.deleteChangeLook,
			changeLookId,
			departmentId,
			episodicId
		);
		if (departmentId === DEPARTMENTS.CM) {
			const { destroyed } = result as DeptSpecificChangeLookDeleteResponse<
				DEPARTMENTS.CM
			>;
			yield put(
				changeLookActions.deleteComplete(destroyed.Change, departmentId)
			);
			yield put(changeActions.deleteComplete(destroyed.Change));

			if (destroyed.ChangeScene) {
				yield put(changeSceneActions.deleteComplete(destroyed.ChangeScene));
				yield destroyed.ChangeScene.forEach((changeScene: ChangeScene) => {
					put(
						deptChangeSceneActions.deleteChangeSceneByIdAndDeptIdComplete(
							changeScene.id,
							departmentId
						)
					);
				});
			}
			if (destroyed.ChangeEpisodicItem) {
				yield put(
					changeEpisodicItemActions.deleteChangeEpisodicItemComplete(
						destroyed.ChangeEpisodicItem
					)
				);
			}
		} else if (departmentId === DEPARTMENTS.HA) {
			const { destroyed } = result as DeptSpecificChangeLookDeleteResponse<
				DEPARTMENTS.HA
			>;
			yield put(
				changeLookActions.deleteComplete(destroyed.HaChange, departmentId)
			);
			yield put(haChangeActions.deleteComplete(destroyed.HaChange));

			if (destroyed.HaChangeScene) {
				yield put(haChangeSceneActions.deleteComplete(destroyed.HaChangeScene));
				yield destroyed.HaChangeScene.forEach(
					(haChangeScene: HaChangeScene) => {
						put(
							deptChangeSceneActions.deleteChangeSceneByIdAndDeptIdComplete(
								haChangeScene.id,
								departmentId
							)
						);
					}
				);
			}
		} else if (departmentId === DEPARTMENTS.MU) {
			const { destroyed } = result as DeptSpecificChangeLookDeleteResponse<
				DEPARTMENTS.MU
			>;
			yield put(
				changeLookActions.deleteComplete(destroyed.MuChange, departmentId)
			);
			yield put(muChangeActions.deleteComplete(destroyed.MuChange));

			if (destroyed.MuChangeScene) {
				yield put(muChangeSceneActions.deleteComplete(destroyed.MuChangeScene));
				yield destroyed.MuChangeScene.forEach(
					(muChangeScene: MuChangeScene) => {
						put(
							deptChangeSceneActions.deleteChangeSceneByIdAndDeptIdComplete(
								muChangeScene.id,
								departmentId
							)
						);
					}
				);
			}
		}
	} catch (err) {
		yield put(changeLookActions.deleteFailed(err));
		if (err.response) {
			toast.error(
				'There was an error deleting the change/look. Please refresh to try again.'
			);
		} else {
			logger.responseError(err, 'deleting the change/look', true);
		}
	}
}

export function* create({
	changeLook,
	departmentId,
	episodicId,
}: changeLookActions.CreateAction) {
	try {
		const result: ChangeLookCreateResponse = yield call(
			api.create,
			changeLook,
			departmentId,
			episodicId
		);

		yield put(changeLookActions.createComplete());
		switch (departmentId) {
			case DEPARTMENTS.CM: {
				const { created } = result as DeptSpecificChangeLookCreateResponse<
					DEPARTMENTS.CM
				>;
				yield put(changeActions.createChangeComplete(created.Change));
				break;
			}
			case DEPARTMENTS.HA: {
				const { created } = result as DeptSpecificChangeLookCreateResponse<
					DEPARTMENTS.HA
				>;
				yield put(haChangeActions.createHaChangeComplete(created.HaChange));
				break;
			}
			case DEPARTMENTS.MU: {
				const { created } = result as DeptSpecificChangeLookCreateResponse<
					DEPARTMENTS.MU
				>;
				yield put(muChangeActions.createMuChangeComplete(created.MuChange));
				break;
			}
			default:
				break;
		}
	} catch (err) {
		yield put(changeLookActions.createFailed(err));
		if (err.response) {
			toast.error(
				err.response.data.message ||
					'There was an error creating the change/look. Please refresh to try again'
			);
		} else {
			logger.responseError(err, 'creating the change/look', true);
		}
	}
}

export function* get({
	changeLookId,
	departmentId,
	episodicId,
}: changeLookActions.GetAction) {
	try {
		const result: ChangeLookGetResponse = yield call(
			api.get,
			changeLookId,
			departmentId,
			episodicId
		);
		if (departmentId === DEPARTMENTS.CM) {
			const {
				listed,
				previous,
				next,
			} = result as DeptSpecificChangeLookGetResponse<DEPARTMENTS.CM>;
			if (listed.Change) {
				yield put(
					changeLookActions.getComplete(
						listed.Change,
						departmentId,
						next,
						previous
					)
				);
				yield put(changeActions.getComplete(listed.Change[0]));
			}
			if (listed.CmWrapBox) {
				yield put(
					wrapBoxActions.getComplete(
						{ CmWrapBox: listed.CmWrapBox },
						DEPARTMENTS.CM
					)
				);
			}
		}
		if (departmentId === DEPARTMENTS.HA) {
			const {
				listed,
				previous,
				next,
			} = result as DeptSpecificChangeLookGetResponse<DEPARTMENTS.HA>;
			if (listed.HaChange) {
				yield put(
					changeLookActions.getComplete(
						listed.HaChange,
						departmentId,
						next,
						previous
					)
				);
				yield put(haChangeActions.getComplete(listed.HaChange[0]));
			}
		}
		if (departmentId === DEPARTMENTS.MU) {
			const {
				listed,
				previous,
				next,
			} = result as DeptSpecificChangeLookGetResponse<DEPARTMENTS.MU>;
			if (listed.MuChange) {
				yield put(
					changeLookActions.getComplete(
						listed.MuChange,
						departmentId,
						next,
						previous
					)
				);
				yield put(muChangeActions.getComplete(listed.MuChange[0]));
			}
		}
	} catch (err) {
		yield put(changeLookActions.getFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading the change/look. Please refresh to try again.'
			);
		} else {
			logger.responseError(err, 'loading the change/look', true);
		}
	}
}

export function* update({
	changeLook,
	departmentId,
	episodicId,
}: changeLookActions.UpdateAction) {
	try {
		if (departmentId === DEPARTMENTS.CM) {
			const {
				updated,
			}: DeptSpecificChangeLookUpdateResponse<DEPARTMENTS.CM> = yield call(
				api.update,
				changeLook,
				departmentId,
				episodicId
			);

			if (updated.Change) {
				yield put(changeLookActions.updateComplete());
				yield put(changeActions.updateComplete(updated.Change));
			}
		} else if (departmentId === DEPARTMENTS.HA) {
			const {
				updated,
			}: DeptSpecificChangeLookUpdateResponse<DEPARTMENTS.HA> = yield call(
				api.update,
				changeLook,
				departmentId,
				episodicId
			);

			if (updated.HaChange) {
				yield put(changeLookActions.updateComplete());
				yield put(haChangeActions.updateComplete(updated.HaChange));
			}
		} else if (departmentId === DEPARTMENTS.MU) {
			const {
				updated,
			}: DeptSpecificChangeLookUpdateResponse<DEPARTMENTS.MU> = yield call(
				api.update,
				changeLook,
				departmentId,
				episodicId
			);

			if (updated.MuChange) {
				yield put(changeLookActions.updateComplete());
				yield put(muChangeActions.updateComplete(updated.MuChange));
			}
		}
	} catch (err) {
		yield put(changeLookActions.updateFailed(err));
		if (err.response) {
			if (err.response.data && err.response.data.message) {
				toast.error(err.response.data.message);
			} else {
				toast.error(
					'There was an error updating the change/look. Please refresh to try again'
				);
			}
		} else {
			logger.responseError(err, 'updating the change/look', true);
		}
	}
}

function* saga() {
	yield takeEvery(changeLookActions.CREATE, create);
	yield takeLatest(changeLookActions.GET, get);
	yield takeLatest(changeLookActions.DELETE, deleteChangeLook);
	yield takeEvery(changeLookActions.UPDATE, update);
}

export default saga;
