import { CmChangePicture } from 'sos-models';

export const DESTROY = '[CmChangePicture] Destroy';
type DESTROY = typeof DESTROY;

export const DESTROY_COMPLETE = '[CmChangePicture] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const DESTROY_FAILED = '[CmChangePicture] Destroy Failed';
type DESTROY_FAILED = typeof DESTROY_FAILED;

export const LIST_COMPLETE = '[CmChangePicture] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FOR_EP_ITEM = '[CmChangePicture] List For Ep Item';
type LIST_FOR_EP_ITEM = typeof LIST_FOR_EP_ITEM;

export const LIST_FOR_EP_ITEM_COMPLETE =
	'[CmChangePicture] List For Ep Item Complete';
type LIST_FOR_EP_ITEM_COMPLETE = typeof LIST_FOR_EP_ITEM_COMPLETE;

export const LIST_FOR_EP_ITEM_FAILED =
	'[CmChangePicture] List For Ep Item Failed';
type LIST_FOR_EP_ITEM_FAILED = typeof LIST_FOR_EP_ITEM_FAILED;

export interface DestroyAction {
	cmChangePictureId: number;
	episodicId: number;
	type: DESTROY;
}

export interface DestroyCompleteAction {
	cmChangePicture: CmChangePicture[];
	type: DESTROY_COMPLETE;
}

export interface DestroyFailedAction {
	error: Error;
	type: DESTROY_FAILED;
}

export interface ListCompleteAction {
	cmChangePictures: CmChangePicture[];
	type: LIST_COMPLETE;
}

export function destroy(
	episodicId: number,
	cmChangePictureId: number
): DestroyAction {
	return {
		cmChangePictureId,
		episodicId,
		type: DESTROY,
	};
}

export function destroyComplete(
	cmChangePicture: CmChangePicture[]
): DestroyCompleteAction {
	return {
		cmChangePicture,
		type: DESTROY_COMPLETE,
	};
}

export function destroyFailed(error: Error): DestroyFailedAction {
	return {
		error,
		type: DESTROY_FAILED,
	};
}

export function listComplete(
	cmChangePictures: CmChangePicture[]
): ListCompleteAction {
	return {
		cmChangePictures,
		type: LIST_COMPLETE,
	};
}

export type Action =
	| DestroyAction
	| DestroyCompleteAction
	| DestroyFailedAction
	| ListCompleteAction;
