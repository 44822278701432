import { omit } from 'lodash';
import {
	FIELD_TYPES,
	TableColumn,
	WRAP_BOX_INVENTORY_COLUMNS,
} from 'sos-models';

export const defaultSelections = [
	'main_photo_id',
	'name',
	'brand',
	'color',
	'description',
	'quantity_in_box',
	'status_id',
	'episodic_character_id',
	'used_by',
	'use_notes',
];

export const defaultHidden = Object.keys(
	omit(WRAP_BOX_INVENTORY_COLUMNS, defaultSelections)
);

export interface ColumnData {
	inputCols?: string[];
	selectCols?: string[];
	multiSelectCols?: string[];
	currencyCols?: string[];
	numberCols?: string[];
	dateCols?: string[];
	checkboxCols?: string[];
	colArray: TableColumn[];
}

export const buildColumnArray = (indexColumns: {
	[name: string]: TableColumn;
}): ColumnData => {
	const inputCols = [];
	const selectCols = [];
	const multiSelectCols = [];
	const currencyCols = [];
	const numberCols = [];
	const dateCols = [];
	const checkboxCols = [];
	let colArray = [];

	for (const key in indexColumns) {
		if (indexColumns.hasOwnProperty(key)) {
			const col = indexColumns[key];

			colArray.push(col);

			switch (col.type) {
				case FIELD_TYPES.DATE:
					dateCols.push(col.canonical_name);
					break;
				case FIELD_TYPES.INPUT:
					inputCols.push(col.canonical_name);
					break;
				case FIELD_TYPES.NUMBER:
					numberCols.push(col.canonical_name);
					break;
				case FIELD_TYPES.CURRENCY:
					currencyCols.push(col.canonical_name);
					break;
				case FIELD_TYPES.SELECT:
					selectCols.push(col.canonical_name);
					break;
				case FIELD_TYPES.MULTISELECT:
					multiSelectCols.push(col.canonical_name);
					break;
				case FIELD_TYPES.CHECKBOX:
					checkboxCols.push(col.canonical_name);
					break;
				default:
					break;
			}
		}
	}

	colArray = colArray.sort((a, b) => {
		return a.name.localeCompare(b.name);
	});

	return {
		inputCols,
		selectCols,
		multiSelectCols,
		currencyCols,
		numberCols,
		dateCols,
		checkboxCols,
		colArray,
	};
};

const colData = buildColumnArray(WRAP_BOX_INVENTORY_COLUMNS);

export const inputColumns = colData.inputCols;
export const selectColumns = colData.selectCols;
export const multiSelectColumns = colData.multiSelectCols;
export const currencyColumns = colData.currencyCols;
export const numberColumns = colData.numberCols;
export const dateColumns = colData.dateCols;
export const checkboxColumns = colData.checkboxCols;
export let WRAP_BOX_INVENTORY_COLUMNS_ARRAY = colData.colArray;
