import {
	EpisodicCharacterPictureDeleteResponse,
	EpisodicCharacterPictureForEpisodicListReponse,
	EpisodicCharacterPictureListResponse,
} from 'sos-models';
import axios from '../../config/axios';

export async function destroy(
	episodicId: number,
	episodicCharacterPictureId: number
): Promise<EpisodicCharacterPictureDeleteResponse> {
	const { data } = await axios.delete(
		`/api/episodics/${episodicId}/episodic-character-pictures/${episodicCharacterPictureId}`
	);
	return data;
}

export const listForEpChar = async (
	episodicId: number,
	epCharId: number
): Promise<EpisodicCharacterPictureListResponse> => {
	const { data } = await axios.get<EpisodicCharacterPictureListResponse>(
		`/api/episodics/${episodicId}/episodic-characters/${epCharId}/pictures`
	);
	return data;
};

export async function listForEpisodic(
	episodicId: number
): Promise<EpisodicCharacterPictureForEpisodicListReponse> {
	const { data } = await axios.get<
		EpisodicCharacterPictureForEpisodicListReponse
	>(`/api/episodics/${episodicId}/episodic-character-pictures`);
	return data;
}
