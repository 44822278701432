export const SET_EDIT_STATUS = '[GalleryView] Set Edit Status';
type SET_EDIT_STATUS = typeof SET_EDIT_STATUS;

export interface SetEditStatusAction {
	pictureId: number;
	status: boolean;
	type: SET_EDIT_STATUS;
}

export function setEditStatus(
	pictureId: number,
	status: boolean
): SetEditStatusAction {
	return {
		pictureId,
		status,
		type: SET_EDIT_STATUS,
	};
}

export type Action = SetEditStatusAction;
