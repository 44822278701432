import { HaLookPictureDeleteResponse } from 'sos-models';
import axios from '../../config/axios';

export async function destroy(
	episodicId: number,
	haLookPictureId: number
): Promise<HaLookPictureDeleteResponse> {
	const { data } = await axios.delete(
		`/api/episodics/${episodicId}/ha-look-pictures/${haLookPictureId}`
	);
	return data;
}
