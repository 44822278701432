import { DEPARTMENTS } from 'sos-models';
import * as actions from './add-existing-inventory-modal.actions';
import { AddExistingInventoryModalTypes } from '../../common/modal/add-existing-inventory/AddExistingInventoryModal';
import { createReducer } from '@reduxjs/toolkit';

export interface State {
	selectedCharacterId: number;
	selectedDepartmentId: DEPARTMENTS;
	selectedModalType: AddExistingInventoryModalTypes;
	selectedSetId: number;
}

const initial: State = {
	selectedCharacterId: null,
	selectedDepartmentId: null,
	selectedModalType: null,
	selectedSetId: null,
};

export const reducer = createReducer(initial, (builder) =>
	builder
		.addCase(actions.SELECT_CHARACTER, selectCharacterId)
		.addCase(
			actions.SELECT_DEPARTMENT_AND_MODAL_TYPE,
			selectDepartmentAndModalType
		)
		.addCase(actions.SELECT_SET, selectSetId)
);

function selectCharacterId(
	state: State,
	action: actions.SelectCharacterAction
): State {
	if (action.epCharId === state.selectedCharacterId) {
		return state;
	}
	return {
		...state,
		selectedCharacterId: action.epCharId,
	};
}

function selectDepartmentAndModalType(
	state: State,
	action: actions.SelectDepartmentAction
): State {
	if (
		action.deptId === state.selectedCharacterId &&
		action.modalType === state.selectedModalType
	) {
		return state;
	}
	return {
		...state,
		selectedDepartmentId: action.deptId,
		selectedModalType: action.modalType,
	};
}

function selectSetId(state: State, action: actions.SelectSetAction): State {
	if (action.setId === state.selectedCharacterId) {
		return state;
	}
	return {
		...state,
		selectedSetId: action.setId,
	};
}
