import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	EpisodicActorCreateResponse,
	EpisodicActorGetResponse,
	EpisodicActorListResponse,
	EpisodicActorUpdateNotesReponse,
	EpisodicActorUpdateReponse,
} from 'sos-models';
import * as actions from './episodic-actor.actions';
import * as api from './episodic-actor.api';
import * as logger from '../../logger';
import {
	cmEpisodicActorActions,
	episodicActorPictureActions,
	episodicCharacterActions,
	haEpisodicActorActions,
	muEpisodicActorActions,
	pictureActions,
	prEpisodicActorActions,
} from '../actions';

export function* create({
	episodicId,
	episodicActors,
	epCharIds,
}: actions.CreateAction) {
	try {
		const results: EpisodicActorCreateResponse = yield call(
			api.create,
			episodicId,
			episodicActors,
			epCharIds
		);
		yield put(actions.createComplete(results.created.EpisodicActor));
		yield put(
			cmEpisodicActorActions.createComplete(results.created.CmEpisodicActor)
		);
		yield put(
			haEpisodicActorActions.createComplete(results.created.HaEpisodicActor)
		);
		yield put(
			muEpisodicActorActions.createComplete(results.created.MuEpisodicActor)
		);
		yield put(
			prEpisodicActorActions.createComplete(results.created.PrEpisodicActor)
		);
		if (results.updated.EpisodicCharacter) {
			yield put(
				episodicCharacterActions.updateComplete(
					results.updated.EpisodicCharacter
				)
			);
		}
	} catch (err) {
		yield put(actions.createFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* exportToNewSeason({
	episodicId,
	newEpisodicId,
	episodicActorIds,
	checkIfExported,
}: actions.ExportToNewSeasonAction) {
	if (!checkIfExported) {
		try {
			yield call(
				api.exportToNewSeason,
				episodicId,
				newEpisodicId,
				episodicActorIds,
				checkIfExported
			);
			yield put(actions.exportToNewSeasonComplete());
			toast.success('Export completed');
		} catch (err) {
			yield put(actions.exportToNewSeasonFailed(err));
			if (err.response) {
				toast.error(err.response.data.message);
			} else {
				logger.error(err);
			}
		}
	}
}

export function* get({ episodicId, episodicActorId }: actions.GetAction) {
	try {
		const { listed }: EpisodicActorGetResponse = yield call(
			api.get,
			episodicId,
			episodicActorId
		);
		if (listed.EpisodicActor) {
			yield put(actions.getComplete(listed.EpisodicActor[0]));
		}
		if (listed.CmEpisodicActor) {
			yield put(cmEpisodicActorActions.listComplete(listed.CmEpisodicActor));
		}
		if (listed.HaEpisodicActor) {
			yield put(haEpisodicActorActions.listComplete(listed.HaEpisodicActor));
		}
		if (listed.MuEpisodicActor) {
			yield put(muEpisodicActorActions.listComplete(listed.MuEpisodicActor));
		}
		if (listed.PrEpisodicActor) {
			yield put(prEpisodicActorActions.listComplete(listed.PrEpisodicActor));
		}
		if (listed.EpisodicActorPicture) {
			yield put(
				episodicActorPictureActions.listComplete(listed.EpisodicActorPicture)
			);
		}
		if (listed.Picture) {
			yield put(pictureActions.listComplete(listed.Picture));
		}
	} catch (err) {
		yield put(actions.getFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* destroy({
	episodicId,
	episodicActorId,
}: actions.DestroyAction) {
	try {
		const result = yield call(api.destroy, episodicId, episodicActorId);
		yield put(actions.destroyComplete(result));
	} catch (err) {
		yield put(actions.destroyFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* list({ episodicId }: actions.ListAction) {
	try {
		const { listed }: EpisodicActorListResponse = yield call(
			api.list,
			episodicId
		);
		if (listed.EpisodicActor) {
			yield put(actions.listComplete(listed.EpisodicActor));
		}
	} catch (err) {
		yield put(actions.listFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* update({
	episodicId,
	episodicActors,
	epActorIdToEpCharIds,
	haEpisodicActors,
	muEpisodicActors,
}: actions.UpdateAction) {
	try {
		const { updated }: EpisodicActorUpdateReponse = yield call(
			api.update,
			episodicId,
			episodicActors,
			epActorIdToEpCharIds,
			haEpisodicActors,
			muEpisodicActors
		);
		if (updated.EpisodicActor) {
			yield put(actions.updateComplete(updated.EpisodicActor));
		}
		if (updated.EpisodicCharacter) {
			yield put(
				episodicCharacterActions.updateComplete(updated.EpisodicCharacter)
			);
		}
		if (updated.HaEpisodicActor) {
			yield put(haEpisodicActorActions.updateComplete(updated.HaEpisodicActor));
		}
		if (updated.MuEpisodicActor) {
			yield put(muEpisodicActorActions.updateComplete(updated.MuEpisodicActor));
		}
	} catch (err) {
		yield put(actions.updateFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* updateNotes({
	episodicId,
	episodicActorId,
	episodicActorNotes,
}: actions.UpdateNotesAction) {
	try {
		const { updated }: EpisodicActorUpdateNotesReponse = yield call(
			api.updateNotes,
			episodicId,
			episodicActorId,
			episodicActorNotes
		);
		yield put(actions.updateNotesComplete());
		if (updated.CmEpisodicActor) {
			yield put(cmEpisodicActorActions.updateComplete(updated.CmEpisodicActor));
		}
		if (updated.HaEpisodicActor) {
			yield put(haEpisodicActorActions.updateComplete(updated.HaEpisodicActor));
		}
		if (updated.MuEpisodicActor) {
			yield put(muEpisodicActorActions.updateComplete(updated.MuEpisodicActor));
		}
		if (updated.PrEpisodicActor) {
			yield put(prEpisodicActorActions.updateComplete(updated.PrEpisodicActor));
		}
	} catch (err) {
		yield put(actions.updateNotesFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeLatest(actions.GET, get);
	yield takeLatest(actions.LIST, list);
	yield takeLatest(actions.DESTROY, destroy);
	yield takeEvery(actions.CREATE, create);
	yield takeEvery(actions.EXPORT_TO_NEW_SEASON, exportToNewSeason);
	yield takeEvery(actions.UPDATE, update);
	yield takeEvery(actions.UPDATE_NOTES, updateNotes);
}

export default saga;
