import { EditStateType, TableType } from 'sos-models';
import * as actions from './table.actions';
import { createReducer } from '../utils';

export interface State {
	editState: EditStateType;
	tableType: TableType;
}

const initial: State = {
	editState: EditStateType.None,
	tableType: TableType.None,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.SET_EDIT_ALL_TYPE]: setEditAllType,
});

function setEditAllType(
	state: State,
	{ tableType, editState }: actions.SetEditAllTypeAction
): State {
	return {
		...state,
		tableType,
		editState,
	};
}
