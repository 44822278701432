import { normalize } from 'normalizr';
import { User } from 'sos-models';
import * as actions from './user.actions';
import { userSchema } from '../schemas';
import { ActionStatus, createReducer } from '../utils';
import { setUser } from '../../logger';

export interface State {
	entities: { [id: number]: User };
	ids: number[];
	userId: number;
	loading: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	userId: null,
	loading: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.GET]: get,
	[actions.GET_COMPLETE]: getComplete,
	[actions.GET_FAILED]: getFailed,
});

function get(state: State): State {
	return {
		...state,
		loading: ActionStatus.Loading,
	};
}

function getComplete(state: State, action: actions.GetCompleteAction): State {
	const normalized = normalize(action.user, userSchema);
	setUser(action.user);
	return {
		...state,
		entities: { ...state.entities, ...normalized.entities.users },
		ids: [...state.ids, normalized.result],
		userId: action.user.id,
		loading: ActionStatus.Complete,
	};
}

function getFailed(state: State): State {
	return {
		...state,
		loading: ActionStatus.Failed,
	};
}
