import { FIELD_TYPES, TableColumn } from 'sos-models';

export const ACTOR_INDEX_COLUMNS: { [name: string]: TableColumn } = {
	main_photo_id: {
		name: ' ',
		canonical_name: 'main_photo_id',
		width: 55,
		type: null,
	},
	name: {
		name: 'Name',
		canonical_name: 'name',
		width: 100,
		type: FIELD_TYPES.COMBO,
	},
	char_ids: {
		name: 'Character(s)',
		canonical_name: 'char_ids',
		width: 100,
		type: FIELD_TYPES.MULTISELECT,
	},
	gender: {
		name: 'Gender',
		canonical_name: 'gender',
		width: 50,
		type: FIELD_TYPES.SELECT,
	},
	age: {
		name: 'Age',
		canonical_name: 'age',
		width: 50,
		type: FIELD_TYPES.INPUT,
	},
	next_fitting: {
		name: 'Next Fitting',
		canonical_name: 'next_fitting',
		width: 100,
		type: FIELD_TYPES.COMBO,
	},
	start_work_date: {
		name: 'Start Work',
		canonical_name: 'start_work_date',
		width: 100,
		type: FIELD_TYPES.DATE,
	},
	notes: {
		name: 'Notes',
		canonical_name: 'notes',
		width: 200,
		type: FIELD_TYPES.TEXTAREA,
	},
};

export const inputColumns = [];
export const selectColumns = [];
export const multiSelectColumns = [];
export const currencyColumns = [];
export const numberColumns = [];
export const dateColumns = [];
export const checkboxColumns = [];
export const textareaColumns = [];
export const ACTOR_INDEX_COLUMNS_ARRAY = [];

for (const key in ACTOR_INDEX_COLUMNS) {
	if (ACTOR_INDEX_COLUMNS.hasOwnProperty(key)) {
		const col = ACTOR_INDEX_COLUMNS[key];

		ACTOR_INDEX_COLUMNS_ARRAY.push(col);

		switch (col.type) {
			case FIELD_TYPES.DATE:
				dateColumns.push(col.canonical_name);
				break;
			case FIELD_TYPES.INPUT:
				inputColumns.push(col.canonical_name);
				break;
			case FIELD_TYPES.NUMBER:
				numberColumns.push(col.canonical_name);
				break;
			case FIELD_TYPES.CURRENCY:
				currencyColumns.push(col.canonical_name);
				break;
			case FIELD_TYPES.SELECT:
				selectColumns.push(col.canonical_name);
				break;
			case FIELD_TYPES.MULTISELECT:
				multiSelectColumns.push(col.canonical_name);
				break;
			case FIELD_TYPES.CHECKBOX:
				checkboxColumns.push(col.canonical_name);
				break;
			case FIELD_TYPES.TEXTAREA:
				textareaColumns.push(col.canonical_name);
				break;
			default:
				break;
		}
	}
}
