import { UnknownAction } from 'redux';
import { User } from 'sos-models';

export const GET = '[Users] Get';
type GET = typeof GET;
export const GET_FAILED = '[Users] Get Failed';
type GET_FAILED = typeof GET_FAILED;
export const GET_COMPLETE = '[Users] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export interface GetAction extends UnknownAction {
	type: GET;
}
export interface GetFailedAction extends UnknownAction {
	type: GET_FAILED;
	error: Error;
}
export interface GetCompleteAction extends UnknownAction {
	type: GET_COMPLETE;
	user: User;
}

export function get(): GetAction {
	return { type: GET };
}
export function getFailed(error: Error): GetFailedAction {
	return {
		type: GET_FAILED,
		error,
	};
}
export function getComplete(user: User): GetCompleteAction {
	return {
		type: GET_COMPLETE,
		user,
	};
}

export type Action = GetAction | GetFailedAction | GetCompleteAction;
