import {
	compact,
	Dictionary,
	flatten,
	get,
	isArray,
	NumericDictionary,
	omit,
	overEvery,
	uniq,
	without,
} from 'lodash';
import * as memoizeOne from 'memoize-one';
import { normalize, schema } from 'normalizr';
import { createSelector, Selector } from 'reselect';
import {
	Base,
	ChangeScene,
	DEPARTMENTS,
	DeptEntityId,
	EpisodicBase,
	EpisodicItem,
	EpisodicProp,
	HaChangeScene,
	MuChangeScene,
	ProdBase,
	Production,
	SetPiece,
} from 'sos-models';
import { GenPartial } from 'typeormgen';
import { State as StoreState } from './';
import depts from './departments/data';
import { episodicSelectors, productionSelectors } from './root-selectors';

import {
	createReducer as rtkCreateReducer,
	CaseReducer,
} from '@reduxjs/toolkit';

/**
 * memoizeOne is a default export which for some TS reason breaks tests or the build depending on how you import it.
 * This is the easiest solution if you need to use memoize in a tested component.
 * See: https://github.com/alexreardon/memoize-one/issues/37
 */
export const memoize: typeof memoizeOne.default =
	typeof memoizeOne.default === 'function'
		? memoizeOne.default
		: (memoizeOne as unknown as typeof memoizeOne.default);

export enum ActionStatus {
	Loading,
	Complete,
	Failed,
	Inactive,
}

export interface Action {
	type: string;
}

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ActionWithoutType<T extends Action> = Omit<T, 'type'>;

export type NormalizedEntityMapping<T> = NumericDictionary<T>;
export type NormalizationOutput<T> = {
	entities: { [schemaName: string]: NormalizedEntityMapping<T> };
	result: number | number[];
};

type State = object;
export type Reducer<T extends State, U extends Action> = (
	state: T,
	action: U
) => T;

export interface BaseReducerState<T> {
	entities: NormalizedEntityMapping<T>;
	ids: number[];
}

type Constructor<T> = { new (data?: GenPartial<T>): T };

export function actionCompleted(
	prevStatus: ActionStatus,
	status?: ActionStatus
): boolean {
	if (typeof status === 'undefined') {
		return prevStatus === ActionStatus.Complete;
	}

	return (
		(prevStatus === ActionStatus.Loading ||
			prevStatus === ActionStatus.Inactive) &&
		status === ActionStatus.Complete
	);
}

export function actionFailed(
	prevStatus: ActionStatus,
	status?: ActionStatus
): boolean {
	if (typeof status === 'undefined') {
		return prevStatus === ActionStatus.Failed;
	}

	return prevStatus === ActionStatus.Loading && status === ActionStatus.Failed;
}

export function actionFinished(
	prevStatus: ActionStatus,
	status?: ActionStatus
): boolean {
	if (typeof status === 'undefined') {
		return (
			prevStatus === ActionStatus.Complete || prevStatus === ActionStatus.Failed
		);
	}

	return (
		prevStatus === ActionStatus.Loading &&
		(status === ActionStatus.Complete || status === ActionStatus.Failed)
	);
}

export function optionSelected<T>(
	option: T,
	prevOptions: T[],
	currentOptions: T[]
): boolean {
	return !prevOptions.includes(option) && currentOptions.includes(option);
}

// export function createReducer<T extends State, P extends Action>(
// 	initialState: T,
// 	handlers: { [type: string]: Reducer<T, P> }
// ) {
// 	return function reducer(state: T = initialState, action: P): T {
// 		if (handlers.hasOwnProperty(action.type)) {
// 			return handlers[action.type](state, action);
// 		} else {
// 			return state;
// 		}
// 	};
// }

export function createReducer<T extends State, P extends Action>(
	initial: T,
	handlers: { [type: string]: CaseReducer<T, P> }
) {
	return rtkCreateReducer(initial, (builder) => {
		Object.keys(handlers).forEach((type) => {
			builder.addCase(type, handlers[type]);
		});

		return builder;
	});
}

export function createManyToManyMapping<
	T extends Base,
	U extends Base,
	V extends Base,
>(
	leftHandEntities: NormalizedEntityMapping<T>,
	rightHandEntities: NormalizedEntityMapping<U>,
	relationEntities: V[],
	leftForeignKeyName: string,
	rightForeignKeyName: string,
	idTransformer: (entity: T) => string | number = (entity: T) => entity.id
): Dictionary<U[]> {
	const mapping: { [id: string]: U[] } = {};
	for (const join of relationEntities) {
		const leftEntityId: number = join[leftForeignKeyName];
		const leftEntity: T = leftHandEntities[leftEntityId];
		if (!leftEntity) {
			continue;
		}
		const leftEntityMappingId: string | number = idTransformer(leftEntity);
		if (!mapping[leftEntityMappingId]) {
			mapping[leftEntityMappingId] = [];
		}
		const rightHandEntity: U = rightHandEntities[join[rightForeignKeyName]];
		if (rightHandEntity) {
			mapping[leftEntityMappingId].push(rightHandEntity);
		}
	}
	return mapping;
}

export function failed<T extends State>(key: keyof T): (state: T) => T {
	return (state: T): T => {
		return Object.assign({}, state, { [key]: ActionStatus.Failed });
	};
}

export function loading<T extends State>(key: keyof T): (state: T) => T {
	return (state: T): T => {
		return Object.assign({}, state, { [key]: ActionStatus.Loading });
	};
}

export function complete<T extends State>(key: keyof T): (state: T) => T {
	return (state: T): T => {
		return Object.assign({}, state, { [key]: ActionStatus.Complete });
	};
}

/**
 * @description Normalizes the sought entities, then returns the state with the normalized entities
 * @param {string} entityName - The key mapping to the entity or entities in the action
 * @param {Object} entitySchema - The schema of the specified entity
 * @returns An object with the state, the sought entities, an array of the sought entities' ids, and sets listStatus to Complete
 */
export function listComplete<
	T extends Base,
	U extends BaseReducerState<T>,
	V extends Action,
>(
	entityName: keyof ActionWithoutType<V>,
	entitySchema: schema.Entity
): Reducer<U, V> {
	return function (state: U, action: V): U {
		const normalized: NormalizationOutput<T> = normalize(action[entityName], [
			entitySchema,
		]);
		return {
			...state,
			entities: {
				...state.entities,
				...normalized.entities[entitySchema.key],
			},
			ids: uniqueArrayMerge(state.ids, normalized.result as number[]),
			listStatus: ActionStatus.Complete,
		};
	};
}

/**
 * @description Normalizes created entity, then returns the created entity added to state
 * @param {string} entityName - The key mapping to the entity or entities in the action
 * @param {Object} entitySchema - The schema of the specified entity
 * @returns An object with the state, the state's entities along with the created entity, an array of unique entity ids, and sets createStatus to Complete
 */
export function createComplete<
	T extends Base,
	U extends BaseReducerState<T>,
	V extends Action,
>(
	entityName: keyof ActionWithoutType<V>,
	entitySchema: schema.Entity,
	statusName?: keyof U
): Reducer<U, V> {
	return function (state: U, action: V): U {
		const normalized: NormalizationOutput<T> = normalize(
			action[entityName],
			isArray(action[entityName]) ? [entitySchema] : entitySchema
		);
		return {
			...state,
			entities: {
				...state.entities,
				...normalized.entities[entitySchema.key],
			},
			ids: uniqueArrayMerge(
				state.ids,
				flatten([normalized.result]) as number[]
			),
			[statusName ? statusName : 'createStatus']: ActionStatus.Complete,
		};
	};
}

/**
 * @description Gathers sought entities' ids, then returns the state with the sought entities
 * @param {string} entityName - The key mapping to the entity or entities in the action
 * @param {schema.Entity} entitySchema - The schema of the specified entity
 * @returns An object with the state, the state's entities along with the sought entities, an array of unique entity ids, and sets getStatus to Complete
 */
export function getComplete<
	T extends Base,
	U extends BaseReducerState<T>,
	V extends Action,
>(
	entityName: keyof ActionWithoutType<V>,
	entitySchema: schema.Entity
): Reducer<U, V> {
	return function (state: U, action: V): U {
		const normalized: NormalizationOutput<T> = normalize(
			action[entityName],
			isArray(action[entityName]) ? [entitySchema] : entitySchema
		);
		return {
			...state,
			entities: {
				...state.entities,
				...normalized.entities[entitySchema.key],
			},
			ids: uniqueArrayMerge(state.ids, flatten([normalized.result])),
			currentId: isArray(normalized.result)
				? normalized.result[0]
				: normalized.result,
			getStatus: ActionStatus.Complete,
		};
	};
}

/**
 * @description Normalizes the updated entities, then returns the state with the normalized entities
 * @param {string} entityName - The key mapping to the entity or entities in the action
 * @param {Object} entitySchema - The schema of the specified entity
 * @returns An object with the state, the state entities along with the updated entities, and sets updateStatus to Complete
 */
export function updateComplete<
	T extends Base,
	U extends BaseReducerState<T>,
	V extends Action,
>(
	entityName: keyof ActionWithoutType<V>,
	entitySchema: schema.Entity
): Reducer<U, V> {
	return function (state: U, action: V): U {
		const normalized: NormalizationOutput<T> = normalize(
			action[entityName],
			isArray(action[entityName]) ? [entitySchema] : entitySchema
		);
		return {
			...state,
			entities: {
				...state.entities,
				...normalized.entities[entitySchema.key],
			},
			ids: uniqueArrayMerge(state.ids, flatten([normalized.result])),
			updateStatus: ActionStatus.Complete,
		};
	};
}

/**
 * @description Gathers deleted entities' ids, then returns the state without those deleted entities
 * @param {string} entityName - The key mapping to the entity or entities in the action
 * @returns An object with the state, the state's entities without the deleted entities, an array of entity ids without the deleted entities' ids, and sets deletedStatus to Complete
 */
export function destroyComplete<
	T extends Base,
	U extends BaseReducerState<T>,
	V extends Action,
>(entityName: keyof ActionWithoutType<V>): Reducer<U, V> {
	return function (state: U, action: V): U {
		const destroyed: T | T[] = action[entityName] as unknown as T | T[];
		let destroyedIds: number[];
		if (isArray(destroyed)) {
			destroyedIds = (destroyed as T[]).map(({ id }: T) => id);
		} else {
			destroyedIds = [(destroyed as T).id];
		}
		const entities: NormalizedEntityMapping<T> = omit(
			state.entities,
			destroyedIds
		);
		const ids = without(state.ids, ...destroyedIds);
		return {
			...state,
			entities,
			ids,
			destroyStatus: ActionStatus.Complete,
		};
	};
}

/**
 * Runs a map function and a filter function while looping over an array once.
 * Also automatically filters out undefined and null
 */
export function mapFilter<T, U = string | number>(
	keys: U[],
	fn: (key: U) => T,
	filterFn?: (thing: T) => boolean
): T[] {
	const results = [];
	keys.forEach((key) => {
		const result = fn(key);
		if (
			typeof result !== 'undefined' &&
			result !== null &&
			(!filterFn || filterFn(result))
		) {
			results.push(result);
		}
	});

	return results;
}

/**
 * Same as mapFilter, but calls new construct() on each data once the data has
 * passed through the filters
 */
export function mapFilterNew<T, U = string | number>(
	construct: Constructor<T>,
	keys: U[],
	fn: (key: U) => T,
	filterFn?: (thing: T) => boolean
): T[] {
	const results = [];
	keys.forEach((key) => {
		const result = fn(key);
		if (
			typeof result !== 'undefined' &&
			result !== null &&
			(!filterFn || filterFn(result))
		) {
			results.push(new construct(result as T & GenPartial<T>));
		}
	});

	return results;
}

interface CreateGetAllSelectorProps<S extends State, T extends Base> {
	construct: Constructor<T>;
	getIds: Selector<S, number[]>;
	getEntities: Selector<S, NormalizedEntityMapping<T>>;
	filterFn?: (thing: T) => boolean;
}

/**
 * @description generates a getAll selector from an entity's getIds and getEntities selectors
 * @type { S } the store state
 * @type { T } the type of the entity
 *
 * @param { Constructor<T>} construct - a constructor of T - type objects
 * @param { Selector<S, number[]>} getIds - a selector that gets the ids of the entites from the store
 * @param { Selector<S, NormalizedEntityMapping<T>>} getEntities - a selector that gets a normalized entity mapping from the store
 *
 * @returns a selector that returns an array of all T entities currently in the store
 */
export const createGetAllSelector = <S extends StoreState, T extends Base>({
	construct,
	getIds,
	getEntities,
	filterFn,
}: CreateGetAllSelectorProps<S, T>) =>
	createSelector(
		getIds,
		getEntities,
		episodicSelectors.getCurrentId,
		productionSelectors.getEntities,
		(
			ids: number[],
			entities: NormalizedEntityMapping<T>,
			episodicId: number,
			prodEnts: NormalizedEntityMapping<Production>
		): T[] =>
			mapFilterNew(
				construct,
				ids,
				(id) => entities[id],
				overEvery(
					compact([
						(entity) =>
							entity.hasOwnProperty('episodic_id')
								? (entity as unknown as EpisodicBase).episodic_id === episodicId
								: entity.hasOwnProperty('prod_id')
									? get(prodEnts, [
											(entity as unknown as ProdBase).prod_id,
											'episodic_id',
										]) === episodicId
									: true,
						filterFn,
					])
				)
			)
	);

interface CreateGetAllForCurrentSelectorProps<S extends State, T extends Base> {
	construct?: Constructor<T>;
	getIds: Selector<S, number[]>;
	getEntities: Selector<S, NormalizedEntityMapping<T>>;
	getCurrentId: Selector<S, number>;
	foreignKey: keyof T;
}

/**
 * @description generates a getAll selector from an entity's getIds and getEntities selectors
 * @type {S} the store state
 * @type {T} the type of the entity
 *
 * @param {Constructor<T>} construct - a constructor of T-type objects
 * @param {Selector<S, number[]>} getIds - a selector that gets the ids of the entites from the store
 * @param {Selector<S, NormalizedEntityMapping<T>>} getEntities - a selector that gets a normalized entity mapping from the store
 * @param {Selector<S, number>} getCurrentId - a selector the gets the id of the currently viewed entity
 * @param {keyof T} foreignKey - the foreign key in the T entity that references the id of the type of currently viewed entity
 *
 * @returns a selector that returns an array of all T entities currently in the store belonging to the currently viewed entity
 */
export const createGetAllForCurrentSelector = <
	S extends State,
	T extends Base,
>({
	construct,
	getIds,
	getEntities,
	getCurrentId,
	foreignKey,
}: CreateGetAllForCurrentSelectorProps<S, T>) =>
	createSelector(
		getIds,
		getEntities,
		getCurrentId,
		(
			ids: number[],
			entities: NormalizedEntityMapping<T>,
			currentId: number
		) => {
			return mapFilterNew(
				construct,
				ids,
				(id) => entities[id],
				(entity: T) => Number(entity[foreignKey]) === currentId
			);
		}
	);

interface CreateGetCurrentSelectorProps<S extends State, T> {
	construct?: Constructor<T>;
	getEntities: Selector<S, NormalizedEntityMapping<T>>;
	getCurrentId: Selector<S, number>;
}

/**
 * @description generates a getCurrent selector from an entity's getCurrentId and getEntities selectors
 * @type {S} the store state
 * @type {T} the type of the entity
 *
 * @param {Constructor<T>} construct - a constructor of T-type objects
 * @param {Selector<S, NormalizedEntityMapping<T>>} getEntities - a selector that gets a normalized entity mapping from the store
 * @param {Selector<S, number>} getCurrentId - a selector the gets the id of the currently viewed entity
 * @param {keyof T} foreignKey - the foreign key in the T entity that references the id of the type of currently viewed entity
 *
 * @returns a selector that returns the current T entity currently in the store belonging to the current view
 */
export const createGetCurrentSelector = <S extends State, T>({
	construct,
	getEntities,
	getCurrentId,
}: CreateGetCurrentSelectorProps<S, T>) =>
	createSelector(
		getEntities,
		getCurrentId,
		(entities: NormalizedEntityMapping<T>, currentId: number) => {
			if (currentId) {
				return new construct(entities[currentId] as T & GenPartial<T>);
			}
			return null;
		}
	);

/**
 * @description generates a getAll selector from an entity's getIds and getEntities selectors
 * @type {S} the store state
 * @type {T} the type of the related entity (with a foreign key reference an id of a U entity)
 * @type {U} the type of the entity found in the output of the returned selector
 *
 * @param {Constructor<T>} construct - a constructor of T-type objects
 * @param {Selector<S, T[]>} getAllRelations - a selector that gets the related entities from the store
 * @param {Selector<S, NormalizedEntityMapping<U>>} getEntities - a selector that gets a normalized entity mapping from the store
 * @param {keyof T} foreignKey - the foreign key in the T entity that references the id of a U entity
 *
 * @returns a selector that returns an array of U entities mapped from the T entities selected from the passed T entity selector
 */
export const createMapFromRelationsSelector = <
	S extends State,
	T extends Base,
	U extends Base,
>(
	construct: Constructor<U>,
	getAllRelations: Selector<S, T[]>,
	getEntities: Selector<S, NormalizedEntityMapping<U>>,
	foreignKey: keyof T
) =>
	createSelector(
		getAllRelations,
		getEntities,
		(relations: T[], entities: NormalizedEntityMapping<U>): U[] =>
			mapFilterNew(
				construct,
				relations,
				(relation) => entities[Number(relation[foreignKey])]
			)
	);

export function arrayEqual<T>(arr1: Array<T>, arr2: Array<T>) {
	if (arr1.length !== arr2.length) {
		return false;
	}

	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) {
			return false;
		}
	}

	return true;
}

export function getInventoryForDept(
	{ department, id }: DeptEntityId,
	epItems: NormalizedEntityMapping<EpisodicItem>,
	epProps: NormalizedEntityMapping<EpisodicProp>,
	setPieces: NormalizedEntityMapping<SetPiece>
) {
	if (department && id) {
		if (department === DEPARTMENTS.CM && epItems[id]) {
			return new EpisodicItem(epItems[id]);
		} else if (department === DEPARTMENTS.PR && epProps[id]) {
			return new EpisodicProp(epProps[id]);
		} else if (department === DEPARTMENTS.SET && setPieces[id]) {
			return new SetPiece(setPieces[id]);
		}
	}

	return null;
}

export function getChangeLookScenesForDept(
	{ id, department }: DeptEntityId,
	changeScenes: ChangeScene[],
	haChangeScenes: HaChangeScene[],
	muChangeScenes: MuChangeScene[]
): (ChangeScene | HaChangeScene | MuChangeScene)[] {
	if (department && id) {
		if (department === DEPARTMENTS.CM && changeScenes[id]) {
			return changeScenes.filter((cs) => cs.change_id === id);
		} else if (department === DEPARTMENTS.HA && haChangeScenes[id]) {
			return haChangeScenes.filter((cs) => cs.change_id === id);
		} else if (department === DEPARTMENTS.MU && muChangeScenes[id]) {
			return muChangeScenes.filter((cs) => cs.change_id === id);
		}
	}

	return null;
}

/**
 * Takes the existing array and merges it with new entries, without duplicates
 */
export function uniqueArrayMerge<T = number>(
	existing: T[],
	newEntries: T[]
): T[] {
	return uniq([...existing, ...newEntries]);
}

export function sortAndCombine(values: string[]) {
	const nums = values
		.filter((value) => !isNaN(Number(value)))
		.map((n) => Number(n))
		.sort((a, b) => a - b);
	const letters = values.filter((value) => isNaN(Number(value)));
	return [...nums, ...letters];
}

export function getDepartmentNames(deptIds: DEPARTMENTS[]) {
	return depts.filter((d) => deptIds.includes(d.id)).map((d) => d.name);
}

export function andList(arr: string[]) {
	const clone = [...arr];

	if (clone.length > 1) {
		const last = clone.pop();
		return `${clone.join(', ')} and ${last}`;
	} else {
		return clone[0];
	}
}
