import { DEPARTMENTS } from 'sos-models';
import * as sceneViewActions from './scene-view.actions';
import * as sceneActions from '../scenes/scene.actions';
import { createReducer } from '../utils';

export interface State {
	currentDeptId: DEPARTMENTS;
	next: number;
	previous: number;
	principalSortBy: string;
	principalSortOrder: 'asc' | 'desc';
	backgroundSortBy: string;
	backgroundSortOrder: 'asc' | 'desc';
}

const initial: State = {
	currentDeptId: null,
	next: null,
	previous: null,
	principalSortBy: 'character',
	principalSortOrder: 'asc',
	backgroundSortBy: 'character',
	backgroundSortOrder: 'asc',
};

export const reducer = createReducer<
	State,
	sceneViewActions.Action | sceneActions.Action
>(initial, {
	[sceneViewActions.SET_CURRENT_DEPT]: setSelectedPictureDeptId,
	[sceneActions.GET_COMPLETE]: getComplete,
	[sceneViewActions.SET_PRINCIPAL_SORTING]: setPrincipalSorting,
	[sceneViewActions.SET_BACKGROUND_SORTING]: setBackgroundSorting,
});

function setSelectedPictureDeptId(
	state: State,
	{ departmentId }: sceneViewActions.SetCurrentDeptAction
): State {
	return {
		...state,
		currentDeptId: departmentId,
	};
}

function getComplete(
	state: State,
	{ previous, next }: sceneActions.GetCompleteAction
): State {
	return {
		...state,
		previous,
		next,
	};
}

function setPrincipalSorting(
	state: State,
	{ sorting }: sceneViewActions.SetPrincipalSortingAction
): State {
	const sortInfo = sorting[0];
	return {
		...state,
		principalSortBy: sortInfo.columnName,
		principalSortOrder: sortInfo.direction,
	};
}
function setBackgroundSorting(
	state: State,
	{ sorting }: sceneViewActions.SetBackgroundSortingAction
): State {
	const sortInfo = sorting[0];
	return {
		...state,
		backgroundSortBy: sortInfo.columnName,
		backgroundSortOrder: sortInfo.direction,
	};
}
