import { CmStorageLocation } from 'sos-models';
import axios from '../../config/axios';

export function create(
	cmStorageLocation: Partial<CmStorageLocation>
): Promise<Partial<CmStorageLocation>> {
	return axios
		.post<Partial<CmStorageLocation>>(
			`/api/cm-storage-locations`,
			cmStorageLocation
		)
		.then((response) => response.data);
}

export function destroy(cmStorageLocation: Partial<CmStorageLocation>) {
	return axios
		.delete(`/api/cm-storage-locations/${cmStorageLocation.id}`)
		.then((response) => response.data);
}

export function update(
	cmStorageLocation: Partial<CmStorageLocation>
): Promise<Partial<CmStorageLocation>> {
	return axios
		.put<Partial<CmStorageLocation>>(
			`/api/cm-storage-locations/${cmStorageLocation.id}`,
			cmStorageLocation
		)
		.then((response) => response.data);
}
