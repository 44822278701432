import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	EpisodicGetDeptPermsResponse,
	EpisodicGetResponse,
	EpisodicUpdateResponse,
	EpisodicUpdateTitlesResponse,
} from 'sos-models';
import * as actions from './episodic.actions';
import * as api from './episodic.api';
import * as logger from '../../logger';
import {
	accountActions,
	episodicFlagActions,
	pictureActions,
} from '../actions';

export function* update({ episodic }: actions.UpdateAction) {
	try {
		const results: EpisodicUpdateResponse = yield call(api.update, episodic);
		if (results.updated.Episodic) {
			yield put(actions.updateComplete(results.updated.Episodic));
		}
	} catch (err) {
		yield put(actions.updateFailed(err));
		logger.error(err);
	}
}

export function* updateTitles({
	episodic,
	productions,
}: actions.UpdateTitlesAction) {
	try {
		const results: EpisodicUpdateTitlesResponse = yield call(
			api.updateTitles,
			episodic,
			productions
		);
		if (results.updated.Episodic) {
			yield put(actions.updateComplete(results.updated.Episodic));
		}
		if (results.updated.Production) {
			yield put(
				actions.updateTitlesComplete(
					results.updated.Episodic,
					results.updated.Production
				)
			);
		}
	} catch (err) {
		yield put(actions.updateTitlesFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* list() {
	try {
		const episodics = yield call(api.list);
		yield put(actions.listComplete(episodics));
	} catch (err) {
		yield put(actions.listFailed(err));
		if (err.response) {
			// TODO: toast possibly toast or use the above action to save error
			// message from http is in err.response.data.message
			logger.log(err.response.data.message);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

export function* listSeasons({ episodicId }: actions.ListSeasonsAction) {
	try {
		const { listed }: EpisodicGetResponse = yield call(
			api.listSeasons,
			episodicId
		);
		yield put(actions.listComplete(listed.Episodic));
	} catch (err) {
		yield put(actions.listFailed(err));
		if (err.response) {
			// TODO: toast possibly toast or use the above action to save error
			// message from http is in err.response.data.message
			logger.log(err.response.data.message);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

export function* get({ id }: actions.GetAction) {
	try {
		const { listed }: EpisodicGetResponse = yield call(api.get, id);
		yield put(actions.getComplete(listed.Episodic[0]));
		if (listed.Episodic[0].logo) {
			yield put(pictureActions.listComplete(listed.Episodic[0].logo));
		}
		if (listed.Account) {
			yield put(accountActions.listComplete(listed.Account));
		}
		if (listed.EpisodicFlag) {
			yield put(episodicFlagActions.listComplete(listed.EpisodicFlag));
		}
	} catch (err) {
		yield put(actions.getFailed(err));
		if (err.response) {
			logger.log(err.response.data.message);
		} else {
			logger.error(err);
		}
		window.location.replace(`/core?r=productions/index`);
	}
}

export function* getDeptPerms({ id }: actions.GetDeptPermsAction) {
	try {
		const { listed }: EpisodicGetDeptPermsResponse = yield call(
			api.getDeptPerms,
			id
		);
		yield put(actions.getDeptPermsComplete(listed));
	} catch (err) {
		yield put(actions.getDeptPermsFailed(err));
		if (err.response) {
			logger.log(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* getPictureCounts({ id }: actions.GetPictureCountsAction) {
	try {
		const result = yield call(api.getPictureCounts, id);
		if (result.listed.pictureCountsByDept) {
			yield put(
				actions.getPictureCountsComplete(result.listed.pictureCountsByDept)
			);
		}
	} catch (err) {
		yield put(actions.getPictureCountsFailed(err));
		if (err.response) {
			logger.log(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeEvery(actions.UPDATE, update);
	yield takeEvery(actions.UPDATE_TITLES, updateTitles);
	yield takeLatest(actions.LIST, list);
	yield takeLatest(actions.LIST_SEASONS, listSeasons);
	yield takeLatest(actions.GET, get);
	yield takeLatest(actions.GET_DEPT_PERMS, getDeptPerms);
	yield takeLatest(actions.GET_PICTURE_COUNTS, getPictureCounts);
}

export default saga;
