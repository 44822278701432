import { EpisodicCharacterPicture } from 'sos-models';

export const DESTROY = '[Episodic Character Picture] Destroy';
type DESTROY = typeof DESTROY;

export const DESTROY_COMPLETE = '[Episodic Character Picture] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const DESTROY_FAILED = '[Episodic Character Picture] Destroy Failed';
type DESTROY_FAILED = typeof DESTROY_FAILED;

export const LIST_COMPLETE = '[Episodic Character Picture] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FOR_EPISODIC =
	'[Episodic Character Picture] List For Episodic';
type LIST_FOR_EPISODIC = typeof LIST_FOR_EPISODIC;

export const LIST_FOR_EPISODIC_COMPLETE =
	'[Episodic Character Picture] List For Episodic Complete';
type LIST_FOR_EPISODIC_COMPLETE = typeof LIST_FOR_EPISODIC_COMPLETE;

export const LIST_FOR_EPISODIC_FAILED =
	'[Episodic Character Picture] List For Episodic Failed';
type LIST_FOR_EPISODIC_FAILED = typeof LIST_FOR_EPISODIC_FAILED;

export const LIST_FOR_EP_CHAR = '[Episodic Character Pictures] ListForEpChar';
type LIST_FOR_EP_CHAR = typeof LIST_FOR_EP_CHAR;

export const LIST_FOR_EP_CHAR_COMPLETE =
	'[Episodic Character Pictures] ListForEpChar Complete';
type LIST_FOR_EP_CHAR_COMPLETE = typeof LIST_FOR_EP_CHAR_COMPLETE;

export const LIST_FOR_EP_CHAR_FAILED =
	'[Episodic Character Pictures] ListForEpChar Failed';
type LIST_FOR_EP_CHAR_FAILED = typeof LIST_FOR_EP_CHAR_FAILED;

export interface ListForEpisodicAction {
	episodicId: number;
	type: LIST_FOR_EPISODIC;
}

export interface ListForEpisodicCompleteAction {
	episodicCharacterPictures: EpisodicCharacterPicture[];
	type: LIST_FOR_EPISODIC_COMPLETE;
}

export interface ListForEpisodicFailedAction {
	error: Error;
	type: LIST_FOR_EPISODIC_FAILED;
}

export interface DestroyAction {
	episodicCharacterPictureId: number;
	episodicId: number;
	type: DESTROY;
}

export interface DestroyCompleteAction {
	episodicCharacterPictures: EpisodicCharacterPicture[];
	type: DESTROY_COMPLETE;
}

export interface DestroyFailedAction {
	error: Error;
	type: DESTROY_FAILED;
}

export interface ListForEpCharAction {
	episodicId: number;
	epCharId: number;
	type: LIST_FOR_EP_CHAR;
}

export interface ListForEpCharCompleteAction {
	episodicCharacterPictures: EpisodicCharacterPicture[];
	type: LIST_FOR_EP_CHAR_COMPLETE;
}

export interface ListForEpCharFailedAction {
	type: LIST_FOR_EP_CHAR_FAILED;
	error: Error;
}

export interface ListCompleteAction {
	episodicCharacterPictures: EpisodicCharacterPicture[];
	type: LIST_COMPLETE;
}

export function listForEpisodic(episodicId: number): ListForEpisodicAction {
	return {
		episodicId,
		type: LIST_FOR_EPISODIC,
	};
}

export function listForEpisodicComplete(
	episodicCharacterPictures: EpisodicCharacterPicture[]
): ListForEpisodicCompleteAction {
	return {
		episodicCharacterPictures,
		type: LIST_FOR_EPISODIC_COMPLETE,
	};
}

export function listForEpisodicFailed(
	error: Error
): ListForEpisodicFailedAction {
	return {
		error,
		type: LIST_FOR_EPISODIC_FAILED,
	};
}

export function destroy(
	episodicId: number,
	episodicCharacterPictureId: number
): DestroyAction {
	return {
		episodicCharacterPictureId,
		episodicId,
		type: DESTROY,
	};
}

export function destroyComplete(
	episodicCharacterPictures: EpisodicCharacterPicture[]
): DestroyCompleteAction {
	return {
		episodicCharacterPictures,
		type: DESTROY_COMPLETE,
	};
}

export function destroyFailed(error: Error): DestroyFailedAction {
	return {
		error,
		type: DESTROY_FAILED,
	};
}

export function listComplete(
	episodicCharacterPictures: EpisodicCharacterPicture[]
): ListCompleteAction {
	return {
		episodicCharacterPictures,
		type: LIST_COMPLETE,
	};
}

export function listForEpChar(
	episodicId: number,
	epCharId: number
): ListForEpCharAction {
	return {
		episodicId,
		epCharId,
		type: LIST_FOR_EP_CHAR,
	};
}

export function listForEpCharComplete(
	episodicCharacterPictures: EpisodicCharacterPicture[]
): ListForEpCharCompleteAction {
	return {
		episodicCharacterPictures,
		type: LIST_FOR_EP_CHAR_COMPLETE,
	};
}

export function listForEpCharFailed(error: Error): ListForEpCharFailedAction {
	return {
		error,
		type: LIST_FOR_EP_CHAR_FAILED,
	};
}

export type Action =
	| DestroyAction
	| DestroyCompleteAction
	| DestroyFailedAction
	| ListCompleteAction
	| ListForEpCharAction
	| ListForEpCharCompleteAction
	| ListForEpCharFailedAction
	| ListForEpisodicAction
	| ListForEpisodicCompleteAction
	| ListForEpisodicFailedAction;
