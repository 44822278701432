import { EpisodicProp, DEPARTMENTS } from 'sos-models';
import * as epPropActions from './episodic-prop.actions';
import * as characterPropActions from '../character-props/character-prop.actions';
import * as inventoryActions from '../inventory/inventory.actions';
import { episodicPropSchema } from '../schemas';
import { listComplete, updateComplete, uniqueArrayMerge } from '../utils';
import {
	ActionStatus,
	BaseReducerState,
	createReducer,
	failed,
	loading,
} from '../utils';
import { normalize } from 'normalizr';
import { omit } from 'lodash';
import { saveAs } from 'file-saver';

export interface State extends BaseReducerState<EpisodicProp> {
	currentId: number;
	exportStatus: ActionStatus;
	listStatus: ActionStatus;
	getStatus: ActionStatus;
	removeStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	currentId: null,
	exportStatus: ActionStatus.Inactive,
	listStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
	removeStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	characterPropActions.Action | epPropActions.Action | inventoryActions.Action
>(initial, {
	[epPropActions.EXPORT_TO_CSV]: loading<State>('exportStatus'),
	[epPropActions.EXPORT_TO_CSV_COMPLETE]: exportToCSVComplete,
	[epPropActions.EXPORT_TO_CSV_FAILED]: failed<State>('exportStatus'),
	[epPropActions.GET_FOR_CHAR_SCENES]: loading<State>('getStatus'),
	[epPropActions.GET_FOR_CHAR_SCENES_COMPLETE]: listComplete<
		EpisodicProp,
		State,
		epPropActions.GetForCharScenesCompleteAction
	>('episodicProps', episodicPropSchema),
	[epPropActions.GET_FOR_CHAR_SCENES_FAILED]: failed<State>('getStatus'),
	[inventoryActions.GET_COMPLETE]: getComplete,
	[inventoryActions.CREATE_COMPLETE]: createComplete,
	[inventoryActions.UPDATE_COMPLETE]: updateComplete<
		EpisodicProp,
		State,
		inventoryActions.UpdateCompleteAction
	>('inventory', episodicPropSchema),
	[inventoryActions.REMOVE_COMPLETE]: removeComplete,
	[epPropActions.LIST_FOR_SCENE]: loading<State>('listStatus'),
	[epPropActions.LIST_FOR_SCENE_FAILED]: failed<State>('listStatus'),
	[epPropActions.LIST_COMPLETE]: listComplete<
		EpisodicProp,
		State,
		epPropActions.ListCompleteAction
	>('inventory', episodicPropSchema),
	[characterPropActions.LIST_COMPLETE]: listComplete<
		EpisodicProp,
		State,
		characterPropActions.ListCompleteAction
	>('props', episodicPropSchema),
});

function exportToCSVComplete(
	state: State,
	{ fileContent }: epPropActions.ExportToCSVCompleteAction
) {
	const blob = new Blob([fileContent], { type: 'text/csv' });
	saveAs(blob, 'Props_Export.csv');

	return {
		...state,
		exportStatus: ActionStatus.Complete,
	};
}

function createComplete(
	state: State,
	{ inventory }: inventoryActions.CreateCompleteAction
): State {
	const episodicProps = inventory.filter(
		(inv) => inv.department === DEPARTMENTS.PR
	);
	const normalized = normalize(episodicProps, [episodicPropSchema]);
	const ids = uniqueArrayMerge(state.ids, normalized.result);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.episodicProps,
		},
		ids,
	};
}

function getComplete(
	state: State,
	{ inventory }: inventoryActions.GetCompleteAction
): State {
	if (inventory.department !== DEPARTMENTS.PR) {
		return {
			...state,
			getStatus: ActionStatus.Complete,
		};
	}
	const normalized = normalize(inventory, episodicPropSchema);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.episodicProps,
		},
		currentId: normalized.result,
		getStatus: ActionStatus.Complete,
	};
}

function removeComplete(
	state: State,
	{ id, department }: inventoryActions.RemoveCompleteAction
): State {
	return {
		...state,
		entities:
			department === DEPARTMENTS.PR ? omit(state.entities, id) : state.entities,
	};
}
