import { CmStorageLocation } from 'sos-models';

export const CREATE = '[CmStorageLocation] Create';
type CREATE = typeof CREATE;

export const CREATE_FAILED = '[CmStorageLocation] Create Failed';
type CREATE_FAILED = typeof CREATE_FAILED;

export const CREATE_COMPLETE = '[CmStorageLocation] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const DESTROY = '[CmStorageLocation] Destroy';
type DESTROY = typeof DESTROY;

export const DESTROY_FAILED = '[CmStorageLocation] Destroy Failed';
type DESTROY_FAILED = typeof DESTROY_FAILED;

export const DESTROY_COMPLETE = '[CmStorageLocation] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const GET = '[CmStorageLocation] Get';
type GET = typeof GET;

export const GET_COMPLETE = '[CmStorageLocation] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const GET_FAILED = '[CmStorageLocation] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export const UPDATE = '[CmStorageLocation] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_FAILED = '[CmStorageLocation] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const UPDATE_COMPLETE = '[CmStorageLocation] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export interface CreateAction {
	cmStorageLocation: CmStorageLocation;
	type: CREATE;
}

export interface CreateFailedAction {
	error: Error;
	type: CREATE_FAILED;
}

export interface CreateCompleteAction {
	cmStorageLocation: CmStorageLocation;
	type: CREATE_COMPLETE;
}

export interface DestroyAction {
	cmStorageLocation: CmStorageLocation;
	type: DESTROY;
}

export interface DestroyFailedAction {
	error: Error;
	type: DESTROY_FAILED;
}

export interface DestroyCompleteAction {
	cmStorageLocation: CmStorageLocation;
	type: DESTROY_COMPLETE;
}

export interface GetAction {
	episodicId: number;
	cmStorageLocationId: number;
	type: GET;
}

export interface GetCompleteAction {
	cmStorageLocation: CmStorageLocation;
	type: GET_COMPLETE;
}

export interface GetFailedAction {
	error: Error;
	type: GET_FAILED;
}

export interface UpdateAction {
	cmStorageLocation: CmStorageLocation;
	type: UPDATE;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export interface UpdateCompleteAction {
	cmStorageLocation: CmStorageLocation;
	type: UPDATE_COMPLETE;
}

export function create(cmStorageLocation: CmStorageLocation): CreateAction {
	return {
		cmStorageLocation,
		type: CREATE,
	};
}

export function createFailed(error: Error): CreateFailedAction {
	return {
		error,
		type: CREATE_FAILED,
	};
}

export function createComplete(
	cmStorageLocation: CmStorageLocation
): CreateCompleteAction {
	return {
		cmStorageLocation,
		type: CREATE_COMPLETE,
	};
}

export function destroy(cmStorageLocation: CmStorageLocation): DestroyAction {
	return {
		cmStorageLocation,
		type: DESTROY,
	};
}

export function destroyFailed(error: Error): DestroyFailedAction {
	return {
		error,
		type: DESTROY_FAILED,
	};
}

export function destroyComplete(
	cmStorageLocation: CmStorageLocation
): DestroyCompleteAction {
	return {
		cmStorageLocation,
		type: DESTROY_COMPLETE,
	};
}

export function get(
	episodicId: number,
	cmStorageLocationId: number
): GetAction {
	return {
		episodicId,
		cmStorageLocationId,
		type: GET,
	};
}

export function getComplete(
	cmStorageLocation: CmStorageLocation
): GetCompleteAction {
	return {
		cmStorageLocation,
		type: GET_COMPLETE,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	};
}

export function update(cmStorageLocation: CmStorageLocation): UpdateAction {
	return {
		cmStorageLocation,
		type: UPDATE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function updateComplete(
	cmStorageLocation: CmStorageLocation
): UpdateCompleteAction {
	return {
		cmStorageLocation,
		type: UPDATE_COMPLETE,
	};
}

export type Action =
	| CreateAction
	| CreateFailedAction
	| CreateCompleteAction
	| DestroyAction
	| DestroyFailedAction
	| DestroyCompleteAction
	| GetAction
	| GetCompleteAction
	| GetFailedAction
	| UpdateAction
	| UpdateFailedAction
	| UpdateCompleteAction;
