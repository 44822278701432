import { AnyChangeScene } from 'sos-models';
import { SceneSorting } from '@synconset/sorting';

export const GET = '[DeptChangeScene] Get';
type GET = typeof GET;

export const GET_FAILED = '[DeptChangeScene] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export const UPDATE = '[DeptChangeScene] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_FAILED = '[DeptChangeScene] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID =
	'[DeptChangeScene] Delete Change Scene By Id and Dept Id';
type DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID = typeof DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID;

export const DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID_COMPLETE =
	'[DeptChangeScene] Delete Change Scene By Id and Dept Id Complete';
type DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID_COMPLETE = typeof DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID_COMPLETE;

export const DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID_FAILED =
	'[DeptChangeScene] Delete Change Scene By Id and Dept Id Failed';
type DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID_FAILED = typeof DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID_FAILED;

export const LIST_FOR_EP_CHAR = '[DeptChangeScene] List For Ep Char';
type LIST_FOR_EP_CHAR = typeof LIST_FOR_EP_CHAR;

export const LIST_FOR_EP_CHAR_COMPLETE =
	'[DeptChangeScene] List For Ep Char Complete';
type LIST_FOR_EP_CHAR_COMPLETE = typeof LIST_FOR_EP_CHAR_COMPLETE;

export const LIST_FOR_EP_CHAR_FAILED =
	'[DeptChangeScene] List For Ep Char Failed';
type LIST_FOR_EP_CHAR_FAILED = typeof LIST_FOR_EP_CHAR_FAILED;

export const SET_SORTING = '[DeptChangeScene] Set Sorting';
type SET_SORTING = typeof SET_SORTING;

export interface DeleteChangeSceneByIdAndDeptIdAction {
	episodicId: number;
	deptId: number;
	changeSceneId: number;
	type: DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID;
}

export interface DeleteChangeSceneByIdAndDeptIdCompleteAction {
	changeSceneId: number;
	deptId: number;
	type: DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID_COMPLETE;
}

export interface DeleteChangeSceneByIdAndDeptIdFailedAction {
	error: Error;
	type: DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID_FAILED;
}

export interface GetAction {
	deptChangeSceneId: number;
	departmentId: number;
	episodicId: number;
	type: GET;
}

export interface GetFailedAction {
	error: Error;
	type: GET_FAILED;
}

export interface UpdateAction {
	deptChangeScenes: AnyChangeScene[];
	departmentId: number;
	episodicId: number;
	type: UPDATE;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export interface ListForEpCharAction {
	episodicId: number;
	episodicCharacterId: number;
	departmentId: number;
	type: LIST_FOR_EP_CHAR;
}

export interface ListForEpCharCompleteAction {
	changeScenes: AnyChangeScene[];
	type: LIST_FOR_EP_CHAR_COMPLETE;
}

export interface ListForEpCharFailedAction {
	error: Error;
	type: LIST_FOR_EP_CHAR_FAILED;
}

export interface SetSortingAction {
	departmentId: number;
	sorting: SceneSorting[];
	type: SET_SORTING;
}

export function get(
	deptChangeSceneId: number,
	departmentId: number,
	episodicId: number
): GetAction {
	return {
		deptChangeSceneId,
		departmentId,
		episodicId,
		type: GET,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	};
}

export function update(
	episodicId: number,
	departmentId: number,
	deptChangeScenes: AnyChangeScene[]
): UpdateAction {
	return {
		deptChangeScenes,
		departmentId,
		episodicId,
		type: UPDATE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function deleteChangeSceneByIdAndDeptId(
	episodicId: number,
	deptId: number,
	changeSceneId: number
): DeleteChangeSceneByIdAndDeptIdAction {
	return {
		episodicId,
		deptId,
		changeSceneId,
		type: DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID,
	};
}

export function deleteChangeSceneByIdAndDeptIdComplete(
	changeSceneId: number,
	deptId: number
): DeleteChangeSceneByIdAndDeptIdCompleteAction {
	return {
		changeSceneId,
		deptId,
		type: DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID_COMPLETE,
	};
}

export function deleteChangeSceneByIdAndDeptIdFailed(
	error: Error
): DeleteChangeSceneByIdAndDeptIdFailedAction {
	return {
		error,
		type: DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID_FAILED,
	};
}

export function listForEpChar(
	episodicId: number,
	episodicCharacterId: number,
	departmentId: number
): ListForEpCharAction {
	return {
		episodicId,
		episodicCharacterId,
		departmentId,
		type: LIST_FOR_EP_CHAR,
	};
}

export function listForEpCharComplete(
	changeScenes: AnyChangeScene[]
): ListForEpCharCompleteAction {
	return {
		changeScenes,
		type: LIST_FOR_EP_CHAR_COMPLETE,
	};
}

export function listForEpCharFailed(error: Error): ListForEpCharFailedAction {
	return {
		error,
		type: LIST_FOR_EP_CHAR_FAILED,
	};
}

export function setSorting(
	departmentId: number,
	sorting: SceneSorting[]
): SetSortingAction {
	return {
		departmentId,
		sorting,
		type: SET_SORTING,
	};
}

export type Action =
	| GetAction
	| GetFailedAction
	| SetSortingAction
	| UpdateAction
	| UpdateFailedAction
	| DeleteChangeSceneByIdAndDeptIdAction
	| DeleteChangeSceneByIdAndDeptIdCompleteAction
	| DeleteChangeSceneByIdAndDeptIdFailedAction
	| ListForEpCharAction
	| ListForEpCharCompleteAction
	| ListForEpCharFailedAction;
