import { InventoryPicture, Picture } from 'sos-models';

export const DESTROY_COMPLETE = '[InventoryPicture] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const LIST = '[Inventory Picture] List';
type LIST = typeof LIST;

export const LIST_FAILED = '[Inventory Picture] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const LIST_COMPLETE = '[Inventory Picture] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export interface DestroyCompleteAction {
	inventoryPicture: InventoryPicture[];
	departmentId: number;
	type: DESTROY_COMPLETE;
}

export interface ListAction {
	episodicId: number;
	departmentId: number;
	inventoryId: number;
	type: LIST;
}

export interface ListCompleteAction {
	departmentId: number;
	inventoryPictures: InventoryPicture[];
	pictures: Picture[];
	type: LIST_COMPLETE;
}

export interface ListFailedAction {
	departmentId: number;
	error: Error;
	type: LIST_FAILED;
}

export function destroyComplete(
	inventoryPicture: InventoryPicture[],
	departmentId: number
): DestroyCompleteAction {
	return {
		inventoryPicture,
		departmentId,
		type: DESTROY_COMPLETE,
	};
}

export function list(
	episodicId: number,
	departmentId: number,
	inventoryId: number
): ListAction {
	return {
		episodicId,
		departmentId,
		inventoryId,
		type: LIST,
	};
}

export function listComplete(
	inventoryPictures: InventoryPicture[],
	pictures: Picture[],
	departmentId: number
): ListCompleteAction {
	return {
		departmentId,
		pictures,
		inventoryPictures,
		type: LIST_COMPLETE,
	};
}

export function listFailed(
	departmentId: number,
	error: Error
): ListFailedAction {
	return {
		departmentId,
		error,
		type: LIST_FAILED,
	};
}

export type Action =
	| DestroyCompleteAction
	| ListAction
	| ListCompleteAction
	| ListFailedAction;
