import { omit, without } from 'lodash';
import { normalize } from 'normalizr';
import { DEPARTMENTS, MuChangeScene, SortOrder } from 'sos-models';
import * as actions from './mu-change-scene.actions';
import * as charSceneActions from '../char-scenes/char-scene.actions';
import * as deptChangeSceneActions from '../dept-change-scenes/dept-change-scene.actions';
import * as muChangeActions from '../mu-changes/mu-change.actions';
import { muChangeSceneSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createComplete,
	createReducer,
	destroyComplete,
	failed,
	listComplete,
	loading,
	uniqueArrayMerge,
} from '../utils';
import { SceneSortBy } from '@synconset/sorting';

export interface State extends BaseReducerState<MuChangeScene> {
	createAndDeleteExistingStatus: ActionStatus;
	createMuChangeBySceneStatus: ActionStatus;
	getForMuChangeSceneStatus: ActionStatus;
	getForMuChangeStatus: ActionStatus;
	listForEpCharStatus: ActionStatus;
	sortBy: SceneSortBy;
	sortOrder: SortOrder;
	updateStatus: ActionStatus;
}

export const initial: State = {
	createAndDeleteExistingStatus: ActionStatus.Inactive,
	createMuChangeBySceneStatus: ActionStatus.Inactive,
	entities: {},
	getForMuChangeSceneStatus: ActionStatus.Inactive,
	getForMuChangeStatus: ActionStatus.Inactive,
	ids: [],
	listForEpCharStatus: ActionStatus.Inactive,
	sortBy: 'name',
	sortOrder: 'asc',
	updateStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	| actions.Action
	| charSceneActions.Action
	| muChangeActions.Action
	| deptChangeSceneActions.Action
>(initial, {
	[actions.CREATE_COMPLETE]: createComplete<
		MuChangeScene,
		State,
		actions.CreateCompleteAction
	>('muChangeScenes', muChangeSceneSchema),
	[actions.DELETE_COMPLETE]: destroyComplete<
		MuChangeScene,
		State,
		actions.DeleteCompleteAction
	>('muChangeScenes'),
	[actions.LIST_COMPLETE]: listComplete<
		MuChangeScene,
		State,
		actions.ListCompleteAction
	>('muChangeScenes', muChangeSceneSchema),
	[actions.GET_MU_CHANGE_SCENES_FOR_SCENE]: loading<State>(
		'getForMuChangeSceneStatus'
	),
	[actions.GET_MU_CHANGE_SCENES_FOR_SCENE_COMPLETE]: getMuSceneChangesForSceneComplete,
	[actions.GET_MU_CHANGE_SCENES_FOR_SCENE_FAILED]: failed<State>(
		'getForMuChangeSceneStatus'
	),
	[actions.LIST_FOR_EP_CHAR]: loading<State>('listForEpCharStatus'),
	[actions.LIST_FOR_EP_CHAR_COMPLETE]: listForEpCharComplete,
	[actions.LIST_FOR_EP_CHAR_FAILED]: failed<State>('listForEpCharStatus'),
	[actions.CREATE_AND_DELETE_EXISTING]: loading<State>(
		'createAndDeleteExistingStatus'
	),
	[actions.CREATE_AND_DELETE_EXISTING_COMPLETE]: createAndDeleteExistingComplete,
	[actions.CREATE_AND_DELETE_EXISTING_FAILED]: failed<State>(
		'createAndDeleteExistingStatus'
	),
	[muChangeActions.CREATE_MU_CHANGE_BY_SCENE]: loading<State>(
		'createMuChangeBySceneStatus'
	),
	[muChangeActions.CREATE_MU_CHANGE_BY_SCENE_COMPLETE]: createMuChangeBySceneComplete,
	[muChangeActions.CREATE_MU_CHANGE_BY_SCENE_FAILED]: failed<State>(
		'createMuChangeBySceneStatus'
	),
	[actions.UPDATE]: loading<State>('updateStatus'),
	[actions.UPDATE_COMPLETE]: updateComplete,
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
	[charSceneActions.DELETE_CHANGE_SCENE_AND_CHAR_SCENE_BY_SCENE_ID]: deleteMuChangeSceneAndCharSceneForScene,
	[deptChangeSceneActions.DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID_COMPLETE]: deleteMuChangeSceneByIdAndDeptIdComplete,
	[deptChangeSceneActions.SET_SORTING]: setSorting,
});

function deleteMuChangeSceneByIdAndDeptIdComplete(
	state: State,
	{
		changeSceneId,
		deptId,
	}: deptChangeSceneActions.DeleteChangeSceneByIdAndDeptIdCompleteAction
): State {
	if (deptId !== DEPARTMENTS.MU) {
		return state;
	}
	const entities = omit(state.entities, changeSceneId);
	const ids = without(state.ids, changeSceneId);
	return {
		...state,
		entities,
		ids,
	};
}

function deleteMuChangeSceneAndCharSceneForScene(
	state: State,
	{ sceneId }: charSceneActions.DeleteChangeSceneAndCharSceneBySceneIdAction
): State {
	const ids = Object.keys(state.entities)
		.filter(key => state.entities[key].scene_id === sceneId)
		.map(id => Number(id));
	const entities = omit(state.entities, ids);

	return {
		...state,
		entities,
		ids,
	};
}

function getMuSceneChangesForSceneComplete(
	state: State,
	action: actions.GetMuChangeScenesForSceneCompleteAction
): State {
	const normalized = normalize(action.muChangeScenes, [muChangeSceneSchema]);

	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.muChangeScenes,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		getForMuChangeSceneStatus: ActionStatus.Complete,
	};
}

function createAndDeleteExistingComplete(
	state: State,
	{ created, deleted, updated }: actions.CreateAndDeleteExistingCompleteAction
): State {
	const normalizedCreatedMuChangeScenes = normalize(created.MuChangeScene, [
		muChangeSceneSchema,
	]);
	const normalizedUpdatedMuChangeScenes = normalize(updated.MuChangeScene, [
		muChangeSceneSchema,
	]);
	const updatedEntities: { [id: number]: MuChangeScene } = {
		...state.entities,
		...normalizedCreatedMuChangeScenes.entities.muChangeScenes,
	};
	const deletedIds: number[] = [];
	deleted.MuChangeScene.forEach(cs => {
		deletedIds.push(cs.id);
		updatedEntities[cs.id] = null;
	});
	const ids: number[] = without(state.ids, ...deletedIds).concat(
		normalizedCreatedMuChangeScenes.result
	);

	return {
		...state,
		entities: {
			...updatedEntities,
			...normalizedUpdatedMuChangeScenes.entities.muChangeScenes,
		},
		ids,
		createAndDeleteExistingStatus: ActionStatus.Complete,
	};
}

function createMuChangeBySceneComplete(
	state: State,
	{ created, deleted }: muChangeActions.CreateMuChangeBySceneCompleteAction
): State {
	const normalized = normalize(created.MuChangeScene, [muChangeSceneSchema]);
	const updatedEntities = {
		...state.entities,
		...normalized.entities.muChangeScenes,
	};
	const deletedIds: number[] = [];
	deleted.MuChangeScene.forEach(cs => {
		deletedIds.push(cs.id);
		updatedEntities[cs.id] = null;
	});
	return {
		...state,
		entities: updatedEntities,
		ids: without(state.ids.concat(normalized.result), ...deletedIds),
		createMuChangeBySceneStatus: ActionStatus.Complete,
	};
}

function setSorting(
	state: State,
	{ departmentId, sorting }: deptChangeSceneActions.SetSortingAction
): State {
	// DX grid sends up sorting info as an array. For scenes we're only ever
	// going to have one element in that array. If not, it's a problem.
	if (departmentId !== DEPARTMENTS.MU) {
		return state;
	}
	const sortInfo = sorting[0];
	return {
		...state,
		sortBy: sortInfo.columnName,
		sortOrder: sortInfo.direction,
	};
}

function updateComplete(
	state: State,
	action: actions.UpdateCompleteAction
): State {
	const normalized = normalize(action.updated, muChangeSceneSchema);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.muChangeScenes,
		},
		updateStatus: ActionStatus.Complete,
	};
}

function listForEpCharComplete(
	state: State,
	action: actions.ListForEpCharCompleteAction
): State {
	const normalized = normalize(action.muChangeScenes, [muChangeSceneSchema]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.muChangeScenes,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		listForEpCharStatus: ActionStatus.Complete,
	};
}
