import { SetScene } from 'sos-models';
import * as actions from './set-scene.actions';
import { setSceneSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createComplete,
	createReducer,
	destroyComplete,
	failed,
	listComplete,
	loading,
} from '../utils';

export interface State extends BaseReducerState<SetScene> {
	listStatus: ActionStatus;
	createStatus: ActionStatus;
	destroyStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	listStatus: ActionStatus.Inactive,
	createStatus: ActionStatus.Inactive,
	destroyStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.CREATE_COMPLETE]: createComplete<
		SetScene,
		State,
		actions.CreateCompleteAction
	>('setScenes', setSceneSchema),
	[actions.DELETE_COMPLETE]: destroyComplete<
		SetScene,
		State,
		actions.DeleteCompleteAction
	>('setScenes'),
	[actions.LIST]: loading<State>('listStatus'),
	[actions.LIST_COMPLETE]: listComplete<
		SetScene,
		State,
		actions.ListCompleteAction
	>('setScenes', setSceneSchema),
	[actions.LIST_FAILED]: failed<State>('listStatus'),
});
