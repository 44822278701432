import * as actions from './scene-note.actions';
import {
	ActionStatus,
	complete,
	createReducer,
	failed,
	loading,
} from '../utils';

export interface State {
	listStatus: ActionStatus;
	updateStatus: ActionStatus;
}

const initial: State = {
	listStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.LIST]: loading<State>('listStatus'),
	[actions.LIST_COMPLETE]: complete<State>('listStatus'),
	[actions.LIST_FAILED]: failed<State>('listStatus'),
	[actions.UPDATE]: loading<State>('updateStatus'),
	[actions.UPDATE_COMPLETE]: complete<State>('updateStatus'),
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
});
