import { ColumnWidth, TableColumn } from 'sos-models';
import { createReducer, CaseReducer } from '@reduxjs/toolkit';

export interface SelectAction {
	type: string;
	column: string;
}

export interface DeselectAction {
	type: string;
	column: string;
}

export interface ReorderAction {
	type: string;
	ids: string[];
}

export interface SetWidthsAction {
	type: string;
	widths: ColumnWidth[];
}

export interface State {
	ids: string[];
	hidden: string[];
	widths: { [name: string]: TableColumn };
}

type Action = SelectAction | DeselectAction | ReorderAction | SetWidthsAction;

interface ColumnActions {
	SELECT: string;
	DESELECT: string;
	REORDER: string;
	SET_WIDTHS: string;
}

export function createColumnReducer<T extends State, P extends Action>(
	initial: T,
	actions: ColumnActions,
	handlers: { [type: string]: CaseReducer<T, P> } = {}
) {
	return createReducer(initial, (builder) => {
		builder
			.addCase(actions.SELECT, select)
			.addCase(actions.DESELECT, deselect)
			.addCase(actions.REORDER, reorder)
			.addCase(actions.SET_WIDTHS, setWidths);
		Object.keys(handlers).forEach((type) => {
			builder.addCase(type, handlers[type]);
		});

		return builder;
	});
	// return createReducer<T, Action>(initial, {
	// 	...handlers,
	// 	[actions.SELECT]: select,
	// 	[actions.DESELECT]: deselect,
	// 	[actions.REORDER]: reorder,
	// 	[actions.SET_WIDTHS]: setWidths,
	// });
}

function deselect<T extends State>(state: T, { column }: SelectAction): T {
	const hidden = [...state.hidden];
	hidden.push(column);
	return Object.assign({}, state, { hidden });
}

function select<T extends State>(state: T, { column }: DeselectAction): T {
	const hidden = state.hidden.filter((sel) => sel !== column);
	return Object.assign({}, state, { hidden });
}

function reorder<T extends State>(state: T, { ids }: ReorderAction): T {
	return Object.assign({}, state, { ids });
}

function setWidths<T extends State>(state: T, { widths }: SetWidthsAction): T {
	const newWidths = {};
	widths.forEach((col) => {
		newWidths[col.columnName] = { width: col.width };
	});
	return Object.assign({}, state, { widths: newWidths });
}
