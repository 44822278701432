import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from './set-storage-location.actions';
import * as api from './set-storage-location.api';
import * as logger from '../../logger';

export function* create({ setStorageLocation }: actions.CreateAction) {
	try {
		const created = yield call(api.create, setStorageLocation);
		yield put(actions.createComplete(created));
	} catch (err) {
		yield put(actions.createFailed(err));
		if (err.response) {
			toast.error(
				'There was an error saving the storage location.  Please try again.'
			);
		} else {
			logger.error(err);
		}
	}
}

export function* destroy({ setStorageLocation }: actions.DestroyAction) {
	try {
		yield call(api.destroy, setStorageLocation);
		yield put(actions.destroyComplete(setStorageLocation));
	} catch (err) {
		yield put(actions.destroyFailed(err));
		if (err.response) {
			toast.error(
				'There was an error deleting the storage location.  Please try again.'
			);
		} else {
			logger.error(err);
		}
	}
}

export function* update({ setStorageLocation }: actions.UpdateAction) {
	try {
		const updated = yield call(api.update, setStorageLocation);
		yield put(actions.updateComplete(updated));
	} catch (err) {
		yield put(actions.updateFailed(err));
		if (err.response) {
			toast.error(
				'There was an error saving the storage location.  Please try again.'
			);
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeEvery(actions.CREATE, create);
	yield takeEvery(actions.DESTROY, destroy);
	yield takeEvery(actions.UPDATE, update);
}

export default saga;
