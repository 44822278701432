import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { SceneNoteMultiDeptUpdateResponse } from 'sos-models';
import * as actions from './scene-note.actions';
import * as api from './scene-note.api';
import * as logger from '../../logger';
import * as cmSceneNoteActions from '../cm-scene-notes/cm-scene-note.actions';
import * as haSceneNoteActions from '../ha-scene-notes/ha-scene-note.actions';
import * as muSceneNoteActions from '../mu-scene-notes/mu-scene-note.actions';
import * as prSceneNoteActions from '../pr-scene-notes/pr-scene-note.actions';

export function* list({ prodId, sceneId }: actions.ListAction) {
	try {
		const { cmSceneNote, haSceneNote, muSceneNote, prSceneNote } = yield call(
			api.list,
			prodId,
			sceneId
		);
		yield put(actions.listComplete());
		if (cmSceneNote) {
			yield put(cmSceneNoteActions.getComplete(cmSceneNote));
		}
		if (haSceneNote) {
			yield put(haSceneNoteActions.getComplete(haSceneNote));
		}
		if (muSceneNote) {
			yield put(muSceneNoteActions.getComplete(muSceneNote));
		}
		if (prSceneNote) {
			yield put(prSceneNoteActions.getComplete(prSceneNote));
		}
	} catch (err) {
		yield put(actions.listFailed(err));
		logger.responseError(err, 'loading the scene notes', true);
	}
}

export function* update({
	episodicId,
	prodId,
	sceneId,
	sceneNotes,
}: actions.UpdateAction) {
	try {
		const result: SceneNoteMultiDeptUpdateResponse = yield call(
			api.update,
			episodicId,
			prodId,
			sceneId,
			sceneNotes
		);
		yield put(actions.updateComplete());
		// created scene notes
		if (result.created.CmSceneNote && result.created.CmSceneNote.length) {
			yield put(
				cmSceneNoteActions.createComplete(result.created.CmSceneNote[0])
			);
		}
		if (result.created.HaSceneNote && result.created.HaSceneNote.length) {
			yield put(
				haSceneNoteActions.createComplete(result.created.HaSceneNote[0])
			);
		}
		if (result.created.MuSceneNote && result.created.MuSceneNote.length) {
			yield put(
				muSceneNoteActions.createComplete(result.created.MuSceneNote[0])
			);
		}
		if (result.created.PrSceneNote && result.created.PrSceneNote.length) {
			yield put(
				prSceneNoteActions.createComplete(result.created.PrSceneNote[0])
			);
		}
		// updated scene notes
		if (result.updated.CmSceneNote && result.updated.CmSceneNote.length) {
			yield put(
				cmSceneNoteActions.updateComplete(result.updated.CmSceneNote[0])
			);
		}
		if (result.updated.HaSceneNote && result.updated.HaSceneNote.length) {
			yield put(
				haSceneNoteActions.updateComplete(result.updated.HaSceneNote[0])
			);
		}
		if (result.updated.MuSceneNote && result.updated.MuSceneNote.length) {
			yield put(
				muSceneNoteActions.updateComplete(result.updated.MuSceneNote[0])
			);
		}
		if (result.updated.PrSceneNote && result.updated.PrSceneNote.length) {
			yield put(
				prSceneNoteActions.updateComplete(result.updated.PrSceneNote[0])
			);
		}
	} catch (err) {
		yield put(actions.updateFailed(err));
		logger.responseError(err, 'saving the category');
	}
}

function* saga() {
	yield takeLatest(actions.LIST, list);
	yield takeEvery(actions.UPDATE, update);
}

export default saga;
