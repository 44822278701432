import {
	Episodic,
	EpisodicGetDeptPermsResponse,
	EpisodicGetPictureCountResponse,
	EpisodicGetResponse,
	EpisodicUpdateResponse,
	EpisodicUpdateTitlesResponse,
	Production,
} from 'sos-models';
import axios from '../../config/axios';

export async function list(): Promise<Episodic[]> {
	return axios
		.get<Episodic[]>('/api/episodics')
		.then((response) => response.data);
}

export async function listSeasons(id: number): Promise<Episodic[]> {
	return axios
		.get<Episodic[]>(`/api/episodics/${id}/seasons`)
		.then((response) => response.data);
}

export async function setCurrent(id: number): Promise<string> {
	return await axios
		.post<string>('/api/episodics/current', { id })
		.then((response) => response.data);
}

export async function get(id?: number): Promise<EpisodicGetResponse> {
	let path: number | string = id;
	if (!path) {
		path = 'current';
	}
	return await axios
		.get<EpisodicGetResponse>(`/api/episodics/${path}`)
		.then((response) => response.data);
}

export async function getDeptPerms(
	id: number
): Promise<EpisodicGetDeptPermsResponse> {
	return await axios
		.get<EpisodicGetDeptPermsResponse>(`/api/episodics/${id}/dept-perms`)
		.then((response) => response.data);
}

export async function getPictureCounts(
	id: number
): Promise<EpisodicGetPictureCountResponse> {
	return await axios
		.get<EpisodicGetPictureCountResponse>(`/api/episodics/${id}/picture-counts`)
		.then((response) => response.data);
}

export async function update(
	episodic: Partial<Episodic>
): Promise<EpisodicUpdateResponse> {
	return await axios
		.put<EpisodicUpdateResponse>(`/api/episodics/${episodic.id}`, episodic)
		.then((response) => response.data);
}

export async function updateTitles(
	episodic: Episodic,
	productions: Production[]
): Promise<EpisodicUpdateTitlesResponse> {
	return await axios
		.put<EpisodicUpdateTitlesResponse>(`/api/episodics/${episodic.id}/titles`, {
			episodic,
			productions,
		})
		.then((response) => response.data);
}
