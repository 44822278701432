import { PartialDeptSceneNotes } from 'sos-models';

export const LIST = '[Scene Notes] List';
type LIST = typeof LIST;

export const LIST_FAILED = '[Scene Notes] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const LIST_COMPLETE = '[Scene Notes] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const UPDATE = '[Scene Note] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_FAILED = '[Scene Note] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const UPDATE_COMPLETE = '[Scene Note] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export interface ListAction {
	prodId: number;
	sceneId: number;
	type: LIST;
}

export interface ListCompleteAction {
	type: LIST_COMPLETE;
}

export interface ListFailedAction {
	type: LIST_FAILED;
	error: Error;
}

export interface UpdateAction {
	episodicId: number;
	prodId: number;
	sceneId: number;
	sceneNotes: PartialDeptSceneNotes;
	type: UPDATE;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export interface UpdateCompleteAction {
	type: UPDATE_COMPLETE;
}

export function list(prodId: number, sceneId: number): ListAction {
	return {
		prodId,
		sceneId,
		type: LIST,
	};
}

export function listComplete(): ListCompleteAction {
	return {
		type: LIST_COMPLETE,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export function update(
	episodicId: number,
	prodId: number,
	sceneId: number,
	sceneNotes: PartialDeptSceneNotes
): UpdateAction {
	return {
		episodicId,
		prodId,
		sceneId,
		sceneNotes,
		type: UPDATE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function updateComplete(): UpdateCompleteAction {
	return {
		type: UPDATE_COMPLETE,
	};
}

export type Action =
	| ListAction
	| ListCompleteAction
	| ListFailedAction
	| UpdateAction
	| UpdateFailedAction
	| UpdateCompleteAction;
