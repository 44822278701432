import { omit } from 'lodash';
import { FIELD_TYPES, TableColumn } from 'sos-models';
import { buildColumnArray } from '../inventory-index-columns/inventory-index-columns.data';

export const CHANGE_VIEW_INVENTORY_TABLE_COLUMNS: {
	[name: string]: TableColumn;
} = {
	custom_sort_index: {
		canonical_name: 'custom_sort_index',
		column: 0,
		name: '  ',
		type: null,
		width: 50,
	},
	main_photo_id: {
		canonical_name: 'main_photo_id',
		column: 0,
		name: ' ',
		type: null,
		width: 100,
	},
	name: {
		canonical_name: 'name',
		column: 0,
		name: 'Name',
		type: FIELD_TYPES.INPUT,
		maxLength: 255,
		width: 150,
	},
	description: {
		canonical_name: 'description',
		column: 0,
		name: 'Description',
		type: FIELD_TYPES.TEXTAREA,
		maxLength: 1000,
		width: 150,
	},
	brand: {
		canonical_name: 'brand',
		column: 0,
		name: 'Brand',
		type: FIELD_TYPES.INPUT,
		maxLength: 255,
		width: 150,
	},
	color: {
		canonical_name: 'color',
		column: 0,
		name: 'Color',
		type: FIELD_TYPES.INPUT,
		maxLength: 255,
		width: 150,
	},
	quantity: {
		canonical_name: 'quantity',
		column: 1,
		name: 'Quantity',
		type: FIELD_TYPES.NUMBER,
		width: 150,
	},
	episodic_character_id: {
		name: 'Character',
		canonical_name: 'episodic_character_id',
		width: 150,
		type: FIELD_TYPES.SELECT,
		column: 1,
	},
	set_ids: {
		name: 'Sets',
		canonical_name: 'set_ids',
		width: 150,
		type: FIELD_TYPES.MULTISELECT,
		column: 1,
	},
	status_id: {
		name: 'Status',
		canonical_name: 'status_id',
		width: 150,
		type: FIELD_TYPES.SELECT,
		column: 1,
	},
	size: {
		canonical_name: 'size',
		column: 0,
		name: 'Size',
		placeholder: 'Size / Dimensions',
		type: FIELD_TYPES.INPUT,
		maxLength: 255,
		width: 150,
	},
	category_id: {
		canonical_name: 'category_id',
		column: 4,
		name: 'Category / Type',
		type: FIELD_TYPES.SELECT,
		width: 150,
	},
	asset_num: {
		canonical_name: 'asset_num',
		column: 1,
		name: 'Asset #',
		type: FIELD_TYPES.INPUT,
		maxLength: 255,
		width: 150,
	},
	unit_price: {
		canonical_name: 'unit_price',
		column: 1,
		name: 'Unit Price',
		type: FIELD_TYPES.CURRENCY,
		width: 150,
	},
	total_cost: {
		canonical_name: 'total_cost',
		column: 1,
		name: 'Total Cost',
		type: FIELD_TYPES.CURRENCY,
		width: 150,
	},
	quantity_notes: {
		canonical_name: 'quantity_notes',
		column: 1,
		name: 'Qty. Note',
		type: FIELD_TYPES.INPUT,
		maxLength: 255,
		width: 150,
	},
	purchase_type: {
		canonical_name: 'purchase_type',
		column: 2,
		name: 'Purchase Type',
		type: FIELD_TYPES.SELECT,
		width: 150,
	},
	vendor_id: {
		canonical_name: 'vendor_id',
		column: 2,
		name: 'Vendor',
		type: FIELD_TYPES.SELECT,
		width: 150,
	},
	sku: {
		canonical_name: 'sku',
		column: 2,
		name: 'Sku / Barcode',
		type: FIELD_TYPES.INPUT,
		maxLength: 255,
		width: 150,
	},
	start_date: {
		canonical_name: 'start_date',
		column: 2,
		name: 'Start Date',
		type: FIELD_TYPES.DATE,
		width: 150,
	},
	end_date: {
		canonical_name: 'end_date',
		column: 2,
		name: 'End Date',
		type: FIELD_TYPES.DATE,
		width: 150,
	},
	wrap_box_id: {
		canonical_name: 'wrap_box_id',
		column: 4,
		name: 'Wrap Boxes',
		type: FIELD_TYPES.MULTISELECT,
		width: 200,
	},
	storage_location_id: {
		canonical_name: 'storage_location_id',
		column: 4,
		name: 'Storage Location',
		type: FIELD_TYPES.SELECT,
		width: 150,
	},
	disposition: {
		canonical_name: 'disposition',
		column: 4,
		name: 'Disposition',
		type: FIELD_TYPES.SELECT,
		width: 150,
	},
	disposition_notes: {
		canonical_name: 'disposition_notes',
		column: 1,
		name: 'Disposition Notes',
		type: FIELD_TYPES.INPUT,
		maxLength: 255,
		width: 150,
	},
	department: {
		name: 'Department',
		canonical_name: 'department',
		width: 150,
		type: FIELD_TYPES.SELECT,
		column: 4,
	},
};

export const defaultSelections = [
	'main_photo_id',
	'custom_sort_index',
	'name',
	'brand',
	'color',
	'vendor_id',
];

export const defaultHidden = Object.keys(
	omit(CHANGE_VIEW_INVENTORY_TABLE_COLUMNS, defaultSelections)
);

const colData = buildColumnArray(CHANGE_VIEW_INVENTORY_TABLE_COLUMNS);

export const inputColumns = colData.inputCols;
export const selectColumns = colData.selectCols;
export const multiSelectColumns = colData.multiSelectCols;
export const currencyColumns = colData.currencyCols;
export const numberColumns = colData.numberCols;
export const dateColumns = colData.dateCols;
export const checkboxColumns = colData.checkboxCols;
export const CHANGE_VIEW_INVENTORY_TABLE_COLUMNS_ARRAY = colData.colArray;
