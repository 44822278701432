import { omit, without } from 'lodash';
import { normalize } from 'normalizr';
import { ChangeScene, DEPARTMENTS, SortOrder } from 'sos-models';
import * as actions from './change-scene.actions';
import * as changeActions from '../changes/change.actions';
import * as charSceneActions from '../char-scenes/char-scene.actions';
import * as deptChangeSceneActions from '../dept-change-scenes/dept-change-scene.actions';
import { changeSceneSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createComplete,
	createReducer,
	destroyComplete,
	failed,
	listComplete,
	loading,
	uniqueArrayMerge,
} from '../utils';
import { SceneSortBy } from '@synconset/sorting';

export interface State extends BaseReducerState<ChangeScene> {
	changeNum: { [key: string]: any }; // tslint:disable-line:no-any
	createAndDeleteExistingStatus: ActionStatus;
	createChangeBySceneStatus: ActionStatus;
	getForChangeStatus: ActionStatus;
	getForSceneStatus: ActionStatus;
	listForEpCharStatus: ActionStatus;
	sortBy: SceneSortBy;
	sortOrder: SortOrder;
	updateStatus: ActionStatus;
}

export const initial: State = {
	changeNum: {},
	createAndDeleteExistingStatus: ActionStatus.Inactive,
	createChangeBySceneStatus: ActionStatus.Inactive,
	entities: {},
	getForChangeStatus: ActionStatus.Inactive,
	getForSceneStatus: ActionStatus.Inactive,
	ids: [],
	listForEpCharStatus: ActionStatus.Inactive,
	sortBy: 'name',
	sortOrder: 'asc',
	updateStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	| actions.Action
	| charSceneActions.Action
	| changeActions.Action
	| deptChangeSceneActions.Action
>(initial, {
	[actions.CREATE_AND_DELETE_EXISTING]: loading<State>(
		'createAndDeleteExistingStatus'
	),
	[actions.CREATE_AND_DELETE_EXISTING_COMPLETE]: createAndDeleteExistingComplete,
	[actions.CREATE_AND_DELETE_EXISTING_FAILED]: failed<State>(
		'createAndDeleteExistingStatus'
	),
	[actions.CREATE_COMPLETE]: createComplete<
		ChangeScene,
		State,
		actions.CreateCompleteAction
	>('changeScenes', changeSceneSchema),
	[actions.DELETE_COMPLETE]: destroyComplete<
		ChangeScene,
		State,
		actions.DeleteCompleteAction
	>('changeScenes'),
	[actions.LIST_COMPLETE]: listComplete<
		ChangeScene,
		State,
		actions.ListCompleteAction
	>('changeScenes', changeSceneSchema),
	[actions.GET_CHANGE_SCENES_FOR_SCENE]: loading<State>('getForSceneStatus'),
	[actions.GET_CHANGE_SCENES_FOR_SCENE_COMPLETE]: getChangeScenesForSceneComplete,
	[actions.GET_CHANGE_SCENES_FOR_SCENE_FAILED]: failed<State>(
		'getForSceneStatus'
	),
	[actions.LIST_FOR_EP_CHAR]: loading<State>('listForEpCharStatus'),
	[actions.LIST_FOR_EP_CHAR_COMPLETE]: listForEpCharComplete,
	[actions.LIST_FOR_EP_CHAR_FAILED]: failed<State>('listForEpCharStatus'),
	[actions.SET_CHANGE_NUM]: setChangeNum,
	[deptChangeSceneActions.SET_SORTING]: setSorting,
	[actions.UPDATE]: loading<State>('updateStatus'),
	[actions.UPDATE_COMPLETE]: updateComplete,
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
	[changeActions.CREATE_CHANGE_BY_SCENE]: loading<State>(
		'createChangeBySceneStatus'
	),
	[changeActions.CREATE_CHANGE_BY_SCENE_COMPLETE]: createChangeBySceneComplete,
	[changeActions.CREATE_CHANGE_BY_SCENE_FAILED]: failed<State>(
		'createChangeBySceneStatus'
	),
	[charSceneActions.DELETE_CHANGE_SCENE_AND_CHAR_SCENE_BY_SCENE_ID]: deleteChangeSceneAndCharSceneForScene,
	[deptChangeSceneActions.DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID_COMPLETE]: deleteChangeSceneByIdAndDeptIdComplete,
});

function deleteChangeSceneByIdAndDeptIdComplete(
	state: State,
	action: deptChangeSceneActions.DeleteChangeSceneByIdAndDeptIdCompleteAction
): State {
	const { changeSceneId, deptId } = action;
	if (deptId !== DEPARTMENTS.CM) {
		return state;
	}
	const entities = omit(state.entities, changeSceneId);
	const ids = without(state.ids, changeSceneId);
	return {
		...state,
		entities,
		ids,
	};
}

function deleteChangeSceneAndCharSceneForScene(
	state: State,
	{ sceneId }: charSceneActions.DeleteChangeSceneAndCharSceneBySceneIdAction
): State {
	const ids = Object.keys(state.entities)
		.filter(key => state.entities[key].scene_id === sceneId)
		.map(id => Number(id));
	const entities = omit(state.entities, ids);

	return {
		...state,
		entities,
		ids,
	};
}

function getChangeScenesForSceneComplete(
	state: State,
	action: actions.GetChangeScenesForSceneCompleteAction
): State {
	const normalized = normalize(action.changeScenes, [changeSceneSchema]);

	return {
		...state,
		entities: {
			...(state.entities || {}),
			...normalized.entities.changeScenes,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		getForSceneStatus: ActionStatus.Complete,
	};
}

function createAndDeleteExistingComplete(
	state: State,
	{ created, deleted, updated }: actions.CreateAndDeleteExistingCompleteAction
): State {
	const normalizedCreatedChangeScenes = normalize(created.ChangeScene, [
		changeSceneSchema,
	]);
	const normalizedUpdatedChangeScenes = normalize(updated.ChangeScene, [
		changeSceneSchema,
	]);
	const updatedEntities: { [id: number]: ChangeScene } = {
		...state.entities,
		...normalizedCreatedChangeScenes.entities.changeScenes,
	};
	const deletedIds: number[] = [];
	deleted.ChangeScene.forEach(cs => {
		deletedIds.push(cs.id);
		updatedEntities[cs.id] = null;
	});
	const ids: number[] = without(state.ids, ...deletedIds).concat(
		normalizedCreatedChangeScenes.result
	);
	return {
		...state,
		entities: {
			...updatedEntities,
			...normalizedUpdatedChangeScenes.entities.changeScenes,
		},
		ids,
		createAndDeleteExistingStatus: ActionStatus.Complete,
	};
}

function createChangeBySceneComplete(
	state: State,
	{ created, deleted }: changeActions.CreateChangeBySceneCompleteAction
): State {
	const normalized = normalize(created.ChangeScene, [changeSceneSchema]);
	const updatedEntities = {
		...state.entities,
		...normalized.entities.changeScenes,
	};
	const deletedIds: number[] = [];
	deleted.ChangeScene.forEach(cs => {
		if (cs) {
			deletedIds.push(cs.id);
			updatedEntities[cs.id] = null;
		}
	});
	return {
		...state,
		entities: updatedEntities,
		ids: without(state.ids.concat(normalized.result), ...deletedIds),
		createChangeBySceneStatus: ActionStatus.Complete,
	};
}

function setSorting(
	state: State,
	{ departmentId, sorting }: deptChangeSceneActions.SetSortingAction
): State {
	// DX grid sends up sorting info as an array. For scenes we're only ever
	// going to have one element in that array. If not, it's a problem.
	if (departmentId !== DEPARTMENTS.CM) {
		return state;
	}
	const sortInfo = sorting[0];
	return {
		...state,
		sortBy: sortInfo.columnName,
		sortOrder: sortInfo.direction,
	};
}

function updateComplete(
	state: State,
	action: actions.UpdateCompleteAction
): State {
	const normalized = normalize(action.updated, changeSceneSchema);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.changeScenes,
		},
		updateStatus: ActionStatus.Complete,
	};
}

function listForEpCharComplete(
	state: State,
	action: actions.ListForEpCharCompleteAction
): State {
	const normalized = normalize(action.changeScenes, [changeSceneSchema]);

	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.changeScenes,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		listForEpCharStatus: ActionStatus.Complete,
	};
}

function setChangeNum(state: State, action: actions.SetChangeNum): State {
	return {
		...state,
		changeNum: action.rowChange,
	};
}
