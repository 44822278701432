import { call, put, takeLatest } from 'redux-saga/effects';
import { ActivityCardListResponse } from 'sos-models';
import * as logger from '../../logger';
import * as actions from './activity-feed.actions';
import * as api from './activity-feed.api';

export function* get({ offset, count, episodicId }: actions.GetAction) {
	try {
		const results: ActivityCardListResponse = yield call(
			api.get,
			offset,
			count,
			episodicId
		);
		yield put(
			actions.getComplete(
				results.listed,
				offset,
			)
		);
	} catch (err) {
		yield put(actions.getFailed(err));
		if (err.response) {
			logger.log(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeLatest(actions.GET, get);
}

export default saga;
