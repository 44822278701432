import { PrSceneNote } from 'sos-models';
import * as actions from './pr-scene-note.actions';
import { prSceneNoteSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createComplete,
	createReducer,
	destroyComplete,
	getComplete,
	updateComplete,
} from '../utils';

export interface State extends BaseReducerState<PrSceneNote> {
	createStatus: ActionStatus;
	updateStatus: ActionStatus;
	getStatus: ActionStatus;
	destroyStatus: ActionStatus;
}

const initial: State = {
	ids: [],
	entities: {},
	createStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
	destroyStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.CREATE_COMPLETE]: createComplete<
		PrSceneNote,
		State,
		actions.CreateCompleteAction
	>('prSceneNote', prSceneNoteSchema),
	[actions.GET_COMPLETE]: getComplete<
		PrSceneNote,
		State,
		actions.GetCompleteAction
	>('prSceneNote', prSceneNoteSchema),
	[actions.UPDATE_COMPLETE]: updateComplete<
		PrSceneNote,
		State,
		actions.UpdateCompleteAction
	>('prSceneNote', prSceneNoteSchema),
	[actions.DESTROY_COMPLETE]: destroyComplete<
		PrSceneNote,
		State,
		actions.DestroyCompleteAction
	>('prSceneNote'),
});
