import { CmEpisodicActor } from 'sos-models';
import * as actions from './cm-episodic-actor.actions';
import { cmEpisodicActorSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createReducer,
	listComplete,
	updateComplete,
	createComplete,
} from '../utils';

export interface State extends BaseReducerState<CmEpisodicActor> {
	createStatus: ActionStatus;
	listStatus: ActionStatus;
	updateStatus: ActionStatus;
}

const initial: State = {
	createStatus: ActionStatus.Inactive,
	ids: [],
	entities: {},
	listStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.CREATE_COMPLETE]: createComplete<
		CmEpisodicActor,
		State,
		actions.CreateCompleteAction
	>('cmEpisodicActors', cmEpisodicActorSchema),
	[actions.LIST_COMPLETE]: listComplete<
		CmEpisodicActor,
		State,
		actions.ListCompleteAction
	>('cmEpisodicActors', cmEpisodicActorSchema),
	[actions.UPDATE_COMPLETE]: updateComplete<
		CmEpisodicActor,
		State,
		actions.UpdateCompleteAction
	>('cmEpisodicActors', cmEpisodicActorSchema),
});
