import { AnyChange, ChangeLook, DEPARTMENTS } from 'sos-models';

export const DELETE = '[ChangeLook] Delete';
type DELETE = typeof DELETE;

export const DELETE_COMPLETE = '[ChangeLook] Delete Complete';
type DELETE_COMPLETE = typeof DELETE_COMPLETE;

export const DELETE_FAILED = '[ChangeLook] Delete Failed';
type DELETE_FAILED = typeof DELETE_FAILED;

export const GET = '[ChangeLook] Get';
type GET = typeof GET;

export const GET_COMPLETE = '[ChangeLook] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const GET_FAILED = '[ChangeLook] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export const SET_CURRENT = '[ChangeLook] Set Current';
type SET_CURRENT = typeof SET_CURRENT;

export const UPDATE = '[ChangeLook] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[ChangeLook] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[ChangeLook] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const CREATE = '[ChangeLook] Create';
type CREATE = typeof CREATE;

export const CREATE_COMPLETE = '[ChangeLook] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const CREATE_FAILED = '[ChangeLook] Create Failed';
type CREATE_FAILED = typeof CREATE_FAILED;

export interface DeleteAction {
	changeLookId: number;
	departmentId: number;
	episodicId: number;
	type: DELETE;
}

export interface DeleteCompleteAction {
	changeLooks: AnyChange[];
	departmentId: DEPARTMENTS;
	type: DELETE_COMPLETE;
}

export interface DeleteFailedAction {
	error: Error;
	type: DELETE_FAILED;
}

export interface GetAction {
	changeLookId: number;
	departmentId: DEPARTMENTS;
	episodicId: number;
	type: GET;
}

export interface GetCompleteAction {
	changeLooks: ChangeLook[];
	departmentId: number;
	next: number;
	previous: number;
	type: GET_COMPLETE;
}

export interface GetFailedAction {
	error: Error;
	type: GET_FAILED;
}

export interface SetCurrentAction {
	changeLookId: number;
	departmentId: DEPARTMENTS;
	type: SET_CURRENT;
}

export interface UpdateAction {
	changeLook: Partial<AnyChange>;
	departmentId: DEPARTMENTS;
	episodicId: number;
	type: UPDATE;
}

export interface UpdateCompleteAction {
	type: UPDATE_COMPLETE;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export interface CreateAction {
	changeLook: Partial<AnyChange>;
	departmentId: DEPARTMENTS;
	episodicId: number;
	type: CREATE;
}

export interface CreateCompleteAction {
	type: CREATE_COMPLETE;
}

export interface CreateFailedAction {
	error: Error;
	type: CREATE_FAILED;
}

export function deleteChangeLook(
	changeLookId: number,
	departmentId: DEPARTMENTS,
	episodicId: number
): DeleteAction {
	return {
		changeLookId,
		departmentId,
		episodicId,
		type: DELETE,
	};
}

export function deleteComplete(
	changeLooks: AnyChange[],
	departmentId: DEPARTMENTS
): DeleteCompleteAction {
	return {
		changeLooks,
		departmentId,
		type: DELETE_COMPLETE,
	};
}

export function deleteFailed(error: Error): DeleteFailedAction {
	return {
		error,
		type: DELETE_FAILED,
	};
}

export function get(
	changeLookId: number,
	departmentId: DEPARTMENTS,
	episodicId: number
): GetAction {
	return {
		changeLookId,
		departmentId,
		episodicId,
		type: GET,
	};
}

export function getComplete(
	changeLooks: ChangeLook[],
	departmentId: number,
	next: number,
	previous: number
): GetCompleteAction {
	return {
		changeLooks,
		departmentId,
		next,
		previous,
		type: GET_COMPLETE,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	};
}

export function setCurrent(
	changeLookId: number,
	departmentId: DEPARTMENTS
): SetCurrentAction {
	return {
		changeLookId,
		departmentId,
		type: SET_CURRENT,
	};
}

export function update(
	changeLook: Partial<AnyChange>,
	departmentId: DEPARTMENTS,
	episodicId: number
): UpdateAction {
	return {
		changeLook,
		departmentId,
		episodicId,
		type: UPDATE,
	};
}

export function updateComplete(): UpdateCompleteAction {
	return {
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function create(
	changeLook: Partial<AnyChange>,
	departmentId: DEPARTMENTS,
	episodicId: number
): CreateAction {
	return {
		changeLook,
		departmentId,
		episodicId,
		type: CREATE,
	};
}

export function createComplete(): CreateCompleteAction {
	return {
		type: CREATE_COMPLETE,
	};
}

export function createFailed(error: Error): CreateFailedAction {
	return {
		error,
		type: CREATE_FAILED,
	};
}

export type Action =
	| GetAction
	| DeleteAction
	| DeleteCompleteAction
	| DeleteFailedAction
	| GetCompleteAction
	| GetFailedAction
	| SetCurrentAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction
	| CreateAction
	| CreateCompleteAction
	| CreateFailedAction;
