import { toast } from 'react-toastify';
import { call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	DEPARTMENTS,
	DeptSpecificInventoryCreateResponse,
	DeptSpecificWrapBoxInventoryCreateResponse,
	DeptSpecificWrapBoxInventoryListResponse,
	DeptSpecificWrapBoxInventoryRelationsResponse,
	DeptSpecificWrapBoxInventoryUpdateResponse,
	InventoryCreateResponse,
	InventoryListResponse,
	InventoryRemoveFromWrapBoxResponse,
	InventoryUpdateResponse,
	WrapBoxInventoryCreateResponse,
	WrapBoxInventoryListRelationsResponse,
	WrapBoxInventoryListResponse,
	WrapBoxInventoryUpdateResponse,
} from 'sos-models';
import * as actions from './inventory.actions';
import * as api from './inventory.api';
import * as logger from '../../logger';
import {
	changeActions,
	changeEpisodicItemActions,
	cmEpisodicItemPictureActions,
	cmWrapBoxEpisodicItemActions,
	episodicCharacterActions,
	episodicItemActions,
	episodicPropActions,
	inventoryActions,
	prEpisodicPropPictureActions,
	propSceneActions,
	propSceneCharSceneActions,
	prWrapBoxEpisodicPropActions,
	setActions,
	setPieceActions,
	setPiecePictureActions,
	setPieceSetActions,
	setWrapBoxSetPieceActions,
	wrapBoxActions,
} from '../actions';

export function* list({ episodicId, options }: actions.ListAction) {
	try {
		if (options.search) {
			yield delay(500);
		}
		const results: InventoryListResponse = yield call(
			api.list,
			episodicId,
			options
		);
		const { listed } = results; 

		yield put(
			actions.listComplete(listed.Inventory, results.totalCount, options)
		);
		yield put(wrapBoxActions.listComplete(listed));

		if (listed.Change) {
			yield put(changeActions.listComplete(listed.Change));
		}
		if (listed.ChangeEpisodicItem) {
			yield put(
				changeEpisodicItemActions.listComplete(listed.ChangeEpisodicItem)
			);
		}
		if (listed.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.listComplete(listed.CmWrapBoxEpisodicItem)
			);
		}
		if (listed.EpisodicItem) {
			yield put(episodicItemActions.listComplete(listed.EpisodicItem));
		}
		if (listed.EpisodicProp) {
			yield put(episodicPropActions.listComplete(listed.EpisodicProp));
		}
		if (listed.PrWrapBoxEpisodicProp) {
			yield put(
				prWrapBoxEpisodicPropActions.listComplete(listed.PrWrapBoxEpisodicProp)
			);
		}
		if (listed.Set) {
			yield put(setActions.listComplete(listed.Set));
		}
		if (listed.SetPiece) {
			yield put(setPieceActions.listComplete(listed.SetPiece));
		}
		if (listed.SetPieceSet) {
			yield put(setPieceSetActions.listComplete(listed.SetPieceSet));
		}
		if (listed.SetWrapBoxSetPiece) {
			yield put(
				setWrapBoxSetPieceActions.listComplete(listed.SetWrapBoxSetPiece)
			);
		}
	} catch (err) {
		yield put(actions.listFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

export function* listForWrapBox({
	episodicId,
	department,
	wrapBoxId,
}: actions.ListForWrapBoxAction) {
	try {
		const results: WrapBoxInventoryListResponse = yield call(
			api.listForWrapBox,
			episodicId,
			department,
			wrapBoxId
		);

		if (department === DEPARTMENTS.CM) {
			const { listed } = results as DeptSpecificWrapBoxInventoryListResponse<
				DEPARTMENTS.CM
			>;

			if (listed.CmEpisodicItemPicture) {
				yield put(
					cmEpisodicItemPictureActions.listComplete(
						listed.CmEpisodicItemPicture
					)
				);
			}
			if (listed.CmWrapBoxEpisodicItem) {
				yield put(
					cmWrapBoxEpisodicItemActions.listComplete(
						listed.CmWrapBoxEpisodicItem
					)
				);
			}
			if (listed.EpisodicCharacter) {
				yield put(
					episodicCharacterActions.listComplete(listed.EpisodicCharacter)
				);
			}
			if (listed.EpisodicItem) {
				yield put(episodicItemActions.listComplete(listed.EpisodicItem));
			}
			if (listed.Change) {
				yield put(changeActions.listComplete(listed.Change));
			}
			if (listed.ChangeEpisodicItem) {
				yield put(
					changeEpisodicItemActions.listComplete(listed.ChangeEpisodicItem)
				);
			}
		}
		if (department === DEPARTMENTS.PR) {
			const { listed } = results as DeptSpecificWrapBoxInventoryListResponse<
				DEPARTMENTS.PR
			>;

			if (listed.PrEpisodicPropPicture) {
				yield put(
					prEpisodicPropPictureActions.listComplete(
						listed.PrEpisodicPropPicture
					)
				);
			}
			if (listed.EpisodicProp) {
				yield put(episodicPropActions.listComplete(listed.EpisodicProp));
			}
			if (listed.EpisodicCharacter) {
				yield put(
					episodicCharacterActions.listComplete(listed.EpisodicCharacter)
				);
			}
			if (listed.PrWrapBoxEpisodicProp) {
				yield put(
					prWrapBoxEpisodicPropActions.listComplete(
						listed.PrWrapBoxEpisodicProp
					)
				);
			}
		}
		if (department === DEPARTMENTS.SET) {
			const { listed } = results as DeptSpecificWrapBoxInventoryListResponse<
				DEPARTMENTS.SET
			>;
			if (listed.Set) {
				yield put(setActions.listComplete(listed.Set));
			}
			if (listed.SetPiece) {
				yield put(setPieceActions.listComplete(listed.SetPiece));
			}
			if (listed.SetPiecePicture) {
				yield put(setPiecePictureActions.listComplete(listed.SetPiecePicture));
			}
			if (listed.SetPieceSet) {
				yield put(setPieceSetActions.listComplete(listed.SetPieceSet));
			}
			if (listed.SetWrapBoxSetPiece) {
				yield put(
					setWrapBoxSetPieceActions.listComplete(listed.SetWrapBoxSetPiece)
				);
			}
		}
	} catch (err) {
		yield put(actions.listForWrapBoxFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

export function* listWrapBoxInventoryRelations({
	episodicId,
	department,
	artifactId,
}: actions.ListWrapBoxInventoryRelationsAction) {
	try {
		const results: WrapBoxInventoryListRelationsResponse = yield call(
			api.listWrapBoxInventoryRelations,
			episodicId,
			department,
			artifactId
		);
		const cmResults = results as DeptSpecificWrapBoxInventoryRelationsResponse<
			DEPARTMENTS.CM
		>;
		const prResults = results as DeptSpecificWrapBoxInventoryRelationsResponse<
			DEPARTMENTS.PR
		>;
		const setResults = results as DeptSpecificWrapBoxInventoryRelationsResponse<
			DEPARTMENTS.SET
		>;

		if (cmResults.listed.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.listComplete(
					cmResults.listed.CmWrapBoxEpisodicItem
				)
			);
		}
		if (prResults.listed.PrWrapBoxEpisodicProp) {
			yield put(
				prWrapBoxEpisodicPropActions.listComplete(
					prResults.listed.PrWrapBoxEpisodicProp
				)
			);
		}
		if (setResults.listed.SetWrapBoxSetPiece) {
			yield put(
				setWrapBoxSetPieceActions.listComplete(
					setResults.listed.SetWrapBoxSetPiece
				)
			);
		}
	} catch (err) {
		yield put(actions.listWrapBoxInventoryRelationsFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

export function* exportToNewSeason({
	episodicId,
	newEpisodicId,
	epItemIds,
	epPropIds,
	setPieceIds,
	checkIfExported,
	allPagesSelected,
	options,
}: actions.ExportToNewSeasonAction) {
	if (!checkIfExported) {
		try {
			yield call(
				api.exportToNewSeason,
				episodicId,
				newEpisodicId,
				epItemIds,
				epPropIds,
				setPieceIds,
				checkIfExported,
				allPagesSelected,
				options
			);
			yield put(actions.exportToNewSeasonComplete());
			toast.success('Export completed');
		} catch (err) {
			yield put(actions.exportToNewSeasonFailed(err));
			if (err.response) {
				toast.error(err.response.data.message);
			} else {
				logger.error(err);
			}
		}
	}
}

export function* get({ id, episodicId, department }: actions.GetAction) {
	try {
		const inventory = yield call(api.get, id, episodicId, department);
		if (inventory) {
			yield put(actions.getComplete(inventory, department));
		}

		if (department === DEPARTMENTS.CM) {
			yield put(episodicItemActions.getComplete(inventory));
		} else if (department === DEPARTMENTS.SET) {
			yield put(setPieceActions.getComplete(inventory));
		} else if (department === DEPARTMENTS.PR) {
			yield put(episodicPropActions.getComplete(inventory));
		}
	} catch (err) {
		yield put(actions.getFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* update({ episodicId, inventory }: actions.UpdateAction) {
	try {
		const results: InventoryUpdateResponse = yield call(
			api.update,
			episodicId,
			inventory
		);
		if (results.updated.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.updateComplete(
					results.updated.CmWrapBoxEpisodicItem
				)
			);
		}
		if (results.updated.EpisodicItem) {
			yield put(actions.updateComplete(results.updated.EpisodicItem));
		}
		if (results.updated.EpisodicProp) {
			yield put(actions.updateComplete(results.updated.EpisodicProp));
		}
		if (results.updated.PropScene) {
			yield put(propSceneActions.updateComplete(results.updated.PropScene));
		}
		if (results.created.PropSceneCharScene) {
			yield put(
				propSceneCharSceneActions.createComplete(
					results.created.PropSceneCharScene
				)
			);
		}
		if (results.destroyed.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.destroyComplete(
					results.destroyed.CmWrapBoxEpisodicItem
				)
			);
		}
		if (results.destroyed.PropSceneCharScene) {
			yield put(
				propSceneCharSceneActions.destroyComplete(
					results.destroyed.PropSceneCharScene
				)
			);
		}
		if (results.destroyed.PrWrapBoxEpisodicProp) {
			yield put(
				prWrapBoxEpisodicPropActions.destroyComplete(
					results.destroyed.PrWrapBoxEpisodicProp
				)
			);
		}
		if (results.destroyed.SetWrapBoxSetPiece) {
			yield put(
				setWrapBoxSetPieceActions.destroyComplete(
					results.destroyed.SetWrapBoxSetPiece
				)
			);
		}
		if (results.updated.PrWrapBoxEpisodicProp) {
			yield put(
				prWrapBoxEpisodicPropActions.updateComplete(
					results.updated.PrWrapBoxEpisodicProp
				)
			);
		}
		if (results.updated.SetPiece) {
			yield put(actions.updateComplete(results.updated.SetPiece));
		}
		if (results.created.SetPieceSet) {
			yield put(setPieceSetActions.createComplete(results.created.SetPieceSet));
		}
		if (results.destroyed.SetPieceSet) {
			yield put(
				setPieceSetActions.destroyComplete(results.destroyed.SetPieceSet)
			);
		}
		if (results.updated.SetWrapBoxSetPiece) {
			yield put(
				setWrapBoxSetPieceActions.updateComplete(
					results.updated.SetWrapBoxSetPiece
				)
			);
		}
	} catch (err) {
		yield put(actions.updateFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* remove({ id, episodicId, department }: actions.RemoveAction) {
	try {
		yield call(api.remove, id, episodicId, department);
		yield put(actions.removeComplete(id, department));
	} catch (err) {
		yield put(actions.removeFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* removeFromWrapBox({
	episodicId,
	wrapBoxId,
	department,
	inventoryId,
}: actions.RemoveFromWrapBoxAction) {
	try {
		const results: InventoryRemoveFromWrapBoxResponse = yield call(
			api.removeFromWrapBox,
			episodicId,
			wrapBoxId,
			department,
			inventoryId
		);

		if (results.destroyed.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.destroyComplete(
					results.destroyed.CmWrapBoxEpisodicItem
				)
			);
		}
		if (results.destroyed.PrWrapBoxEpisodicProp) {
			yield put(
				prWrapBoxEpisodicPropActions.destroyComplete(
					results.destroyed.PrWrapBoxEpisodicProp
				)
			);
		}
		if (results.destroyed.SetWrapBoxSetPiece) {
			yield put(
				setWrapBoxSetPieceActions.destroyComplete(
					results.destroyed.SetWrapBoxSetPiece
				)
			);
		}

		yield put(actions.removeFromWrapBoxComplete());
	} catch (err) {
		yield put(actions.removeFromWrapBoxFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* create({
	episodicId,
	inventory,
	setIds,
}: actions.CreateAction) {
	try {
		let results: InventoryCreateResponse;
		if (setIds) {
			results = yield call(api.create, episodicId, inventory, setIds);
		} else {
			results = yield call(api.create, episodicId, inventory);
		}
		const cmResults = results as DeptSpecificInventoryCreateResponse<
			DEPARTMENTS.CM
		>;
		const prResults = results as DeptSpecificInventoryCreateResponse<
			DEPARTMENTS.PR
		>;
		const setResults = results as DeptSpecificInventoryCreateResponse<
			DEPARTMENTS.SET
		>;

		if (cmResults.created.EpisodicItem) {
			yield put(actions.createComplete(cmResults.created.EpisodicItem));
		}
		if (cmResults.created.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.createComplete(
					cmResults.created.CmWrapBoxEpisodicItem
				)
			);
		}
		if (prResults.created.EpisodicProp) {
			yield put(actions.createComplete(prResults.created.EpisodicProp));
		}
		if (prResults.created.PrWrapBoxEpisodicProp) {
			yield put(
				prWrapBoxEpisodicPropActions.createComplete(
					prResults.created.PrWrapBoxEpisodicProp
				)
			);
		}
		if (setResults.created.SetPiece) {
			yield put(actions.createComplete(setResults.created.SetPiece));
		}
		if (setResults.created.SetPieceSet) {
			yield put(
				setPieceSetActions.createComplete(setResults.created.SetPieceSet)
			);
		}
		if (setResults.created.SetWrapBoxSetPiece) {
			yield put(
				setWrapBoxSetPieceActions.createComplete(
					setResults.created.SetWrapBoxSetPiece
				)
			);
		}
	} catch (err) {
		yield put(actions.createFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* exportInventory({
	episodicId,
	options,
	reportType,
}: actions.ExportAction) {
	try {
		if (options.search) {
			yield delay(500);
		}
		const results = yield call(
			api.exportInventory,
			episodicId,
			options,
			reportType
		);
		yield put(actions.exportComplete(results, reportType));
	} catch (err) {
		yield put(actions.exportFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

export function* createWrapBoxInventory({
	episodicId,
	departmentId,
	inventoryId,
	wrapBoxPayload,
}: actions.CreateWrapBoxInventoryAction) {
	try {
		const results: WrapBoxInventoryCreateResponse = yield call(
			api.createWrapBoxInventory,
			episodicId,
			departmentId,
			inventoryId,
			wrapBoxPayload
		);
		const cmResults: DeptSpecificWrapBoxInventoryCreateResponse<DEPARTMENTS.CM> = results as DeptSpecificWrapBoxInventoryCreateResponse<
			DEPARTMENTS.CM
		>;
		const prResults: DeptSpecificWrapBoxInventoryCreateResponse<DEPARTMENTS.PR> = results as DeptSpecificWrapBoxInventoryCreateResponse<
			DEPARTMENTS.PR
		>;
		const setResults: DeptSpecificWrapBoxInventoryCreateResponse<DEPARTMENTS.SET> = results as DeptSpecificWrapBoxInventoryCreateResponse<
			DEPARTMENTS.SET
		>;

		yield put(inventoryActions.createWrapBoxInventoryComplete());

		if (cmResults.created.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.createComplete(
					cmResults.created.CmWrapBoxEpisodicItem
				)
			);
		}
		if (prResults.created.PrWrapBoxEpisodicProp) {
			yield put(
				prWrapBoxEpisodicPropActions.createComplete(
					prResults.created.PrWrapBoxEpisodicProp
				)
			);
		}
		if (setResults.created.SetWrapBoxSetPiece) {
			yield put(
				setWrapBoxSetPieceActions.createComplete(
					setResults.created.SetWrapBoxSetPiece
				)
			);
		}
		if (cmResults.created.CmWrapBox) {
			yield put(
				wrapBoxActions.createComplete(DEPARTMENTS.CM, cmResults.created)
			);
		}
		if (prResults.created.PrWrapBox) {
			yield put(
				wrapBoxActions.createComplete(DEPARTMENTS.PR, prResults.created)
			);
		}
		if (setResults.created.SetWrapBox) {
			yield put(
				wrapBoxActions.createComplete(DEPARTMENTS.SET, setResults.created)
			);
		}
	} catch (err) {
		yield put(actions.createWrapBoxInventoryFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

export function* updateWrapBoxInventory({
	episodicId,
	departmentId,
	inventoryId,
	wrapBoxPayload,
}: actions.UpdateWrapBoxInventoryAction) {
	try {
		const results: WrapBoxInventoryUpdateResponse = yield call(
			api.updateWrapBoxInventory,
			episodicId,
			departmentId,
			inventoryId,
			wrapBoxPayload
		);
		const cmResults: DeptSpecificWrapBoxInventoryUpdateResponse<DEPARTMENTS.CM> = results as DeptSpecificWrapBoxInventoryUpdateResponse<
			DEPARTMENTS.CM
		>;
		const prResults: DeptSpecificWrapBoxInventoryUpdateResponse<DEPARTMENTS.PR> = results as DeptSpecificWrapBoxInventoryUpdateResponse<
			DEPARTMENTS.PR
		>;
		const setResults: DeptSpecificWrapBoxInventoryUpdateResponse<DEPARTMENTS.SET> = results as DeptSpecificWrapBoxInventoryUpdateResponse<
			DEPARTMENTS.SET
		>;

		yield put(inventoryActions.updateWrapBoxInventoryComplete());

		if (cmResults.updated.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.updateComplete(
					cmResults.updated.CmWrapBoxEpisodicItem
				)
			);
		}
		if (prResults.updated.PrWrapBoxEpisodicProp) {
			yield put(
				prWrapBoxEpisodicPropActions.updateComplete(
					prResults.updated.PrWrapBoxEpisodicProp
				)
			);
		}
		if (setResults.updated.SetWrapBoxSetPiece) {
			yield put(
				setWrapBoxSetPieceActions.updateComplete(
					setResults.updated.SetWrapBoxSetPiece
				)
			);
		}
		if (cmResults.created.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.createComplete(
					cmResults.created.CmWrapBoxEpisodicItem
				)
			);
		}
		if (prResults.created.PrWrapBoxEpisodicProp) {
			yield put(
				prWrapBoxEpisodicPropActions.createComplete(
					prResults.created.PrWrapBoxEpisodicProp
				)
			);
		}
		if (setResults.created.SetWrapBoxSetPiece) {
			yield put(
				setWrapBoxSetPieceActions.createComplete(
					setResults.created.SetWrapBoxSetPiece
				)
			);
		}
		if (cmResults.destroyed.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.destroyComplete(
					cmResults.destroyed.CmWrapBoxEpisodicItem
				)
			);
		}
		if (prResults.destroyed.PrWrapBoxEpisodicProp) {
			yield put(
				prWrapBoxEpisodicPropActions.destroyComplete(
					prResults.destroyed.PrWrapBoxEpisodicProp
				)
			);
		}
		if (setResults.destroyed.SetWrapBoxSetPiece) {
			yield put(
				setWrapBoxSetPieceActions.destroyComplete(
					setResults.destroyed.SetWrapBoxSetPiece
				)
			);
		}
		if (cmResults.created.CmWrapBox) {
			yield put(
				wrapBoxActions.createComplete(DEPARTMENTS.CM, cmResults.created)
			);
		}
		if (prResults.created.PrWrapBox) {
			yield put(
				wrapBoxActions.createComplete(DEPARTMENTS.PR, prResults.created)
			);
		}
		if (setResults.created.SetWrapBox) {
			yield put(
				wrapBoxActions.createComplete(DEPARTMENTS.SET, setResults.created)
			);
		}
	} catch (err) {
		yield put(actions.updateWrapBoxInventoryFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeLatest(actions.LIST, list);
	yield takeLatest(actions.LIST_FOR_WRAP_BOX, listForWrapBox);
	yield takeLatest(
		actions.LIST_WRAP_BOX_INVENTORY_RELATIONS,
		listWrapBoxInventoryRelations
	);
	yield takeLatest(actions.GET, get);
	yield takeEvery(actions.EXPORT_TO_NEW_SEASON, exportToNewSeason);
	yield takeEvery(actions.UPDATE, update);
	yield takeEvery(actions.CREATE, create);
	yield takeEvery(actions.REMOVE, remove);
	yield takeEvery(actions.REMOVE_FROM_WRAP_BOX, removeFromWrapBox);
	yield takeLatest(actions.EXPORT_INVENTORY, exportInventory);
	yield takeLatest(actions.CREATE_INVENTORY_WRAP_BOX, createWrapBoxInventory);
	yield takeLatest(actions.UPDATE_INVENTORY_WRAP_BOX, updateWrapBoxInventory);
}

export default saga;
