import { AnyChangeScene, ChangeSceneGetResponse } from 'sos-models';
import axios from '../../config/axios';

export async function deleteChangeSceneByIdAndDeptId(
	episodicId: number,
	departmentId: number,
	changeSceneId: number
): Promise<AnyChangeScene> {
	const { data } = await axios.delete(
		`/api/episodics/${episodicId}/dept-change-scenes/${departmentId}/${changeSceneId}`
	);
	return data;
}

export async function get(
	changeLookId: number,
	departmentId: number,
	episodicId: number
): Promise<AnyChangeScene[]> {
	const { data } = await axios.get<AnyChangeScene[]>(
		`/api/episodics/${episodicId}/dept-change-scenes/${departmentId}/${changeLookId}`
	);
	return data;
}

export async function update(
	changeScenes: Partial<AnyChangeScene[]>,
	departmentId: number,
	episodicId: number
): Promise<AnyChangeScene> {
	const { data } = await axios.put<AnyChangeScene>(
		`/api/episodics/${episodicId}/dept-change-scenes/${departmentId}`,
		changeScenes
	);
	return data;
}

export async function listForEpChar(
	episodicId: number,
	episodicCharacterId: number,
	departmentId: number
): Promise<ChangeSceneGetResponse> {
	const { data } = await axios.get(
		`/api/episodics/${episodicId}/episodic-characters/${episodicCharacterId}/dept-change-scenes/${departmentId}`
	);
	return data;
}
