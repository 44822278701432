import { saveAs } from 'file-saver';
import { SortOrder, WrapBox } from 'sos-models';
import * as actions from './wrap-box.actions';
import * as departmentActions from '../departments/department.actions';
import {
	ActionStatus,
	complete,
	createReducer,
	failed,
	loading,
} from '../utils';

export interface State {
	entities: { [id: number]: WrapBox };
	exportStatus: ActionStatus;
	destroyStatus: ActionStatus;
	ids: number[];
	sortBy: string;
	sortOrder: SortOrder;
}

export const initial: State = {
	entities: {},
	exportStatus: ActionStatus.Inactive,
	destroyStatus: ActionStatus.Inactive,
	ids: [],
	sortBy: 'name',
	sortOrder: 'desc',
};

export const reducer = createReducer<
	State,
	actions.Action | departmentActions.Action
>(initial, {
	[actions.SET_SORTING]: setSorting,
	[actions.DESTROY]: loading<State>('destroyStatus'),
	[actions.DESTROY_COMPLETE]: complete<State>('destroyStatus'),
	[actions.DESTROY_FAILED]: failed<State>('destroyStatus'),
	[actions.EXPORT_WRAP_BOXES]: loading<State>('exportStatus'),
	[actions.EXPORT_COMPLETE]: exportComplete,
	[actions.EXPORT_FAILED]: failed<State>('exportStatus'),
});

function exportComplete(state: State, { csv }: actions.ExportCompleteAction) {
	const blob = new Blob([csv], { type: 'text/csv' });
	saveAs(blob, 'Wrap_Box_Export.csv');
	return state;
}

function setSorting(
	state: State,
	{ sorting }: actions.SetSortingAction
): State {
	const sortInfo = sorting[0];
	return {
		...state,
		sortBy: sortInfo.columnName,
		sortOrder: sortInfo.direction,
	};
}
