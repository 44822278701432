import { CmSceneNote } from 'sos-models';

export const CREATE_COMPLETE = '[CM Scene Note] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const GET_COMPLETE = '[CM Scene Note] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const UPDATE_COMPLETE = '[CM Scene Note] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const DESTROY_COMPLETE = '[CM Scene Note] Destroy Complete';
type DESTROY_COMPLETE = typeof UPDATE_COMPLETE;

export interface CreateCompleteAction {
	cmSceneNote: CmSceneNote;
	type: CREATE_COMPLETE;
}

export interface GetCompleteAction {
	cmSceneNote: CmSceneNote;
	type: GET_COMPLETE;
}

export interface UpdateCompleteAction {
	cmSceneNote: CmSceneNote;
	type: UPDATE_COMPLETE;
}

export interface DestroyCompleteAction {
	cmSceneNote: CmSceneNote;
	type: DESTROY_COMPLETE;
}

export function createComplete(cmSceneNote: CmSceneNote): CreateCompleteAction {
	return {
		cmSceneNote,
		type: CREATE_COMPLETE,
	};
}
export function getComplete(cmSceneNote: CmSceneNote): GetCompleteAction {
	return {
		cmSceneNote,
		type: GET_COMPLETE,
	};
}

export function updateComplete(cmSceneNote: CmSceneNote): UpdateCompleteAction {
	return {
		cmSceneNote,
		type: UPDATE_COMPLETE,
	};
}

export function destroyComplete(
	cmSceneNote: CmSceneNote
): DestroyCompleteAction {
	return {
		cmSceneNote,
		type: UPDATE_COMPLETE,
	};
}

export type Action =
	| CreateCompleteAction
	| GetCompleteAction
	| UpdateCompleteAction
	| DestroyCompleteAction;
