import { CmEpisodicItemPictureDeleteResponse } from 'sos-models';
import axios from '../../config/axios';

export async function destroy(
	episodicId: number,
	cmEpisodicItemPictureId: number
): Promise<CmEpisodicItemPictureDeleteResponse> {
	const { data } = await axios.delete(
		`/api/episodics/${episodicId}/cm-episodic-item-pictures/${cmEpisodicItemPictureId}`
	);
	return data;
}
