import { normalize } from 'normalizr';
import { SetStorageLocation } from 'sos-models';
import * as actions from './set-storage-location.actions';
import * as inventorySatelliteActions from '../inventory-satellites/inventory-satellite.actions';
import * as inventoryActions from '../inventory/inventory.actions';
import { setStorageLocationSchema } from '../schemas';
import {
	ActionStatus,
	createComplete,
	createReducer,
	destroyComplete,
	failed,
	loading,
	NormalizedEntityMapping,
	updateComplete,
} from '../utils';

export interface State {
	createStatus: ActionStatus;
	currentId: number;
	destroyStatus: ActionStatus;
	entities: NormalizedEntityMapping<SetStorageLocation>;
	getStatus: ActionStatus;
	ids: number[];
	listStatus: ActionStatus;
	updateStatus: ActionStatus;
}

const initial: State = {
	createStatus: ActionStatus.Inactive,
	currentId: null,
	destroyStatus: ActionStatus.Inactive,
	entities: {},
	getStatus: ActionStatus.Inactive,
	ids: [],
	listStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	actions.Action | inventoryActions.Action | inventorySatelliteActions.Action
>(initial, {
	[inventorySatelliteActions.LIST]: loading<State>('listStatus'),
	[inventorySatelliteActions.LIST_COMPLETE]: listComplete,
	[inventorySatelliteActions.LIST_FAILED]: failed<State>('listStatus'),
	[actions.CREATE]: loading<State>('createStatus'),
	[actions.CREATE_COMPLETE]: createComplete<
		SetStorageLocation,
		State,
		actions.CreateCompleteAction
	>('setStorageLocation', setStorageLocationSchema),
	[actions.CREATE_FAILED]: failed<State>('createStatus'),
	[actions.DESTROY]: loading<State>('destroyStatus'),
	[actions.DESTROY_COMPLETE]: destroyComplete<
		SetStorageLocation,
		State,
		actions.DestroyCompleteAction
	>('setStorageLocation'),
	[actions.DESTROY_FAILED]: failed<State>('destroyStatus'),
	[actions.UPDATE]: loading<State>('updateStatus'),
	[actions.UPDATE_COMPLETE]: updateComplete<
		SetStorageLocation,
		State,
		actions.UpdateCompleteAction
	>('setStorageLocation', setStorageLocationSchema),
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
});

function listComplete(
	state: State,
	action: inventorySatelliteActions.ListCompleteAction
): State {
	if (!action.inventorySatellites.setStorageLocations) {
		return {
			...state,
			listStatus: ActionStatus.Complete,
		};
	}
	const normalized = normalize(action.inventorySatellites.setStorageLocations, [
		setStorageLocationSchema,
	]);
	return {
		...state,
		entities: normalized.entities.setStorageLocations,
		ids: normalized.result,
		listStatus: ActionStatus.Complete,
	};
}
