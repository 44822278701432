import { SetPieceStatus } from 'sos-models';
import axios from '../../config/axios';

export function create(
	setPieceStatus: Partial<SetPieceStatus>
): Promise<Partial<SetPieceStatus>> {
	return axios
		.post<Partial<SetPieceStatus>>(`/api/set-piece-statuses`, setPieceStatus)
		.then((response) => response.data);
}

export function destroy(setPieceStatus: Partial<SetPieceStatus>) {
	return axios
		.delete(`/api/set-piece-statuses/${setPieceStatus.id}`)
		.then((response) => response.data);
}

export function update(
	setPieceStatus: Partial<SetPieceStatus>
): Promise<Partial<SetPieceStatus>> {
	return axios
		.put<Partial<SetPieceStatus>>(
			`/api/set-piece-statuses/${setPieceStatus.id}`,
			setPieceStatus
		)
		.then((response) => response.data);
}
