import { SceneSorting } from '@synconset/sorting';
import { CharScene, EpisodicActor, EpisodicCharacter } from 'sos-models';

export const ADD_BG_CHAR_TO_SCENE =
	'[CharScene] Add Background Episodic Characters to Scene';
type ADD_BG_CHAR_TO_SCENE = typeof ADD_BG_CHAR_TO_SCENE;

export const ADD_BG_CHAR_TO_SCENE_COMPLETE =
	'[CharScene] Add Background Episodic Characters to Scene Complete';
type ADD_BG_CHAR_TO_SCENE_COMPLETE = typeof ADD_BG_CHAR_TO_SCENE_COMPLETE;

export const ADD_BG_CHAR_TO_SCENE_FAILED =
	'[CharScene] Add Background Episodic Characters to Scene Failed';
type ADD_BG_CHAR_TO_SCENE_FAILED = typeof ADD_BG_CHAR_TO_SCENE_FAILED;

export const ADD_EP_CHAR_TO_SCENES =
	'[CharScene] Add Episodic Character to Scenes';
type ADD_EP_CHAR_TO_SCENES = typeof ADD_EP_CHAR_TO_SCENES;

export const ADD_EP_CHAR_TO_SCENES_COMPLETE =
	'[CharScene] Add Episodic Character to Scenes Complete';
type ADD_EP_CHAR_TO_SCENES_COMPLETE = typeof ADD_EP_CHAR_TO_SCENES_COMPLETE;

export const ADD_EP_CHAR_TO_SCENES_FAILED =
	'[CharScene] Add Episodic Character to Scenes Failed';
type ADD_EP_CHAR_TO_SCENES_FAILED = typeof ADD_EP_CHAR_TO_SCENES_FAILED;

export const ADD_EP_CHARS_TO_SCENE =
	'[CharScene] Add Episodic Characters to Scene';
type ADD_EP_CHARS_TO_SCENE = typeof ADD_EP_CHARS_TO_SCENE;

export const ADD_EP_CHARS_TO_SCENE_COMPLETE =
	'[CharScene] Add Episodic Characters to Scene Complete';
type ADD_EP_CHARS_TO_SCENE_COMPLETE = typeof ADD_EP_CHARS_TO_SCENE_COMPLETE;

export const ADD_EP_CHARS_TO_SCENE_FAILED =
	'[CharScene] Add Episodic Characters to Scene Failed';
type ADD_EP_CHARS_TO_SCENE_FAILED = typeof ADD_EP_CHARS_TO_SCENE_FAILED;

export const CREATE_EP_CHAR_FOR_SCENE =
	'[CharScene] Create New Episodic Character for Scene';
type CREATE_EP_CHAR_FOR_SCENE = typeof CREATE_EP_CHAR_FOR_SCENE;

export const CREATE_EP_CHAR_FOR_SCENE_COMPLETE =
	'[CharScene] Create New Episodic Character for Scene Complete';
type CREATE_EP_CHAR_FOR_SCENE_COMPLETE = typeof CREATE_EP_CHAR_FOR_SCENE_COMPLETE;

export const CREATE_EP_CHAR_FOR_SCENE_FAILED =
	'[CharScene] Create New Episodic Character for Scene Failed';
type CREATE_EP_CHAR_FOR_SCENE_FAILED = typeof CREATE_EP_CHAR_FOR_SCENE_FAILED;

export const CREATE_COMPLETE = '[CharScene] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const DELETE_COMPLETE = '[CharScene] Delete Complete';
type DELETE_COMPLETE = typeof DELETE_COMPLETE;

export const DELETE_CHANGE_SCENE_AND_CHAR_SCENE_BY_SCENE_ID =
	'[CharScene] Delete ChangeScene And CharScene By Scene Id';
type DELETE_CHANGE_SCENE_AND_CHAR_SCENE_BY_SCENE_ID = typeof DELETE_CHANGE_SCENE_AND_CHAR_SCENE_BY_SCENE_ID;

export const DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID =
	'[CharScene] Delete CharScene By EpChar Id And Scene Id';
type DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID = typeof DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID;

export const DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_COMPLETE =
	'[CharScene] Delete CharScene By EpChar Id And Scene Id Complete';
type DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_COMPLETE = typeof DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_COMPLETE;

export const DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_FAILED =
	'[CharScene] Delete CharScene By EpChar Id And Scene Id Failed';
type DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_FAILED = typeof DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_FAILED;

export const LIST_COMPLETE = '[CharScene] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const SET_SORTING = '[CharScene] Set Sorting';
type SET_SORTING = typeof SET_SORTING;

export const UPDATE = '[CharScene] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[CharScene] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[CharScene] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const UPDATE_PROP_SCENE_CHAR_SCENES_FOR_EP_CHAR =
	'[CharScene] Update PropSceneCharScenes for EpChar';
type UPDATE_PROP_SCENE_CHAR_SCENES_FOR_EP_CHAR = typeof UPDATE_PROP_SCENE_CHAR_SCENES_FOR_EP_CHAR;

export const UPDATE_PROP_SCENE_CHAR_SCENES_FOR_EP_CHAR_FAILED =
	'[CharScene] Update PropSceneCharScenes for EpChar Failed';
type UPDATE_PROP_SCENE_CHAR_SCENES_FOR_EP_CHAR_FAILED = typeof UPDATE_PROP_SCENE_CHAR_SCENES_FOR_EP_CHAR_FAILED;

export const UPDATE_PROP_SCENE_CHAR_SCENES_FOR_EP_CHAR_COMPLETE =
	'[CharScene] Update PropSceneCharScenes for EpChar Complete';
type UPDATE_PROP_SCENE_CHAR_SCENES_FOR_EP_CHAR_COMPLETE = typeof UPDATE_PROP_SCENE_CHAR_SCENES_FOR_EP_CHAR_COMPLETE;

export interface AddBgCharToSceneAction {
	type: ADD_BG_CHAR_TO_SCENE;
	episodicId: number;
	epCharId: number;
	sceneId: number;
	bgCount: number;
}

export interface AddBgCharToSceneCompleteAction {
	type: ADD_BG_CHAR_TO_SCENE_COMPLETE;
	charScenes: CharScene[];
}

export interface AddBgCharToSceneFailedAction {
	type: ADD_BG_CHAR_TO_SCENE_FAILED;
	error: Error;
}

export interface AddEpCharToScenesAction {
	type: ADD_EP_CHAR_TO_SCENES;
	episodicId: number;
	epCharId: number;
	sceneIds: number[];
}

export interface AddEpCharToScenesCompleteAction {
	type: ADD_EP_CHAR_TO_SCENES_COMPLETE;
}

export interface AddEpCharToScenesFailedAction {
	type: ADD_EP_CHAR_TO_SCENES_FAILED;
	error: Error;
}

export interface AddEpCharsToSceneAction {
	type: ADD_EP_CHARS_TO_SCENE;
	episodicId: number;
	epCharIds: number[];
	sceneId: number;
}

export interface AddEpCharsToSceneCompleteAction {
	type: ADD_EP_CHARS_TO_SCENE_COMPLETE;
}

export interface AddEpCharsToSceneFailedAction {
	type: ADD_EP_CHARS_TO_SCENE_FAILED;
	error: Error;
}

export interface CreateCompleteAction {
	charScenes: CharScene[];
	type: CREATE_COMPLETE;
}

export interface CreateEpCharForSceneAction {
	episodicId: number;
	sceneId: number;
	epChar: Partial<EpisodicCharacter>;
	epActor: Partial<EpisodicActor>;
	type: CREATE_EP_CHAR_FOR_SCENE;
}

export interface CreateEpCharForSceneCompleteAction {
	type: CREATE_EP_CHAR_FOR_SCENE_COMPLETE;
}

export interface CreateEpCharForSceneFailedAction {
	error: Error;
	type: CREATE_EP_CHAR_FOR_SCENE_FAILED;
}

export interface DeleteCompleteAction {
	charScenes: CharScene[];
	type: DELETE_COMPLETE;
}

export interface DeleteChangeSceneAndCharSceneBySceneIdAction {
	type: DELETE_CHANGE_SCENE_AND_CHAR_SCENE_BY_SCENE_ID;
	sceneId: number;
}

export interface DeleteCharSceneByEpCharIdAndSceneIdAction {
	type: DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID;
	episodicId: number;
	epCharId: number;
	sceneId: number;
}

export interface DeleteCharSceneByEpCharIdAndSceneIdCompleteAction {
	type: DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_COMPLETE;
}

export interface DeleteCharSceneByEpCharIdAndSceneIdFailedAction {
	type: DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_FAILED;
	error: Error;
}

export interface ListCompleteAction {
	type: LIST_COMPLETE;
	charScenes: CharScene[];
}

export interface SetSortingAction {
	sorting: SceneSorting[];
	type: SET_SORTING;
}

export interface UpdateAction {
	charScenes: CharScene[];
	episodicId: number;
	type: UPDATE;
}

export interface UpdateCompleteAction {
	charScenes: CharScene[];
	type: UPDATE_COMPLETE;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export interface UpdatePropSceneCharScenesForEpCharAction {
	type: UPDATE_PROP_SCENE_CHAR_SCENES_FOR_EP_CHAR;
	episodicId: number;
	epCharId: number;
	charScenes: CharScene[];
}

export interface UpdatePropSceneCharScenesForEpCharCompleteAction {
	type: UPDATE_PROP_SCENE_CHAR_SCENES_FOR_EP_CHAR_COMPLETE;
}

export interface UpdatePropSceneCharScenesForEpCharFailedAction {
	type: UPDATE_PROP_SCENE_CHAR_SCENES_FOR_EP_CHAR_FAILED;
	error: Error;
}

export function addBgCharToScene(
	episodicId: number,
	epCharId: number,
	sceneId: number,
	bgCount: number
): AddBgCharToSceneAction {
	return {
		type: ADD_BG_CHAR_TO_SCENE,
		episodicId,
		epCharId,
		sceneId,
		bgCount,
	};
}

export function addBgCharToSceneComplete(
	charScenes: CharScene[]
): AddBgCharToSceneCompleteAction {
	return {
		type: ADD_BG_CHAR_TO_SCENE_COMPLETE,
		charScenes,
	};
}

export function addBgCharToSceneFailed(
	error: Error
): AddBgCharToSceneFailedAction {
	return {
		type: ADD_BG_CHAR_TO_SCENE_FAILED,
		error,
	};
}

export function addEpCharToScenes(
	episodicId: number,
	epCharId: number,
	sceneIds: number[]
): AddEpCharToScenesAction {
	return {
		type: ADD_EP_CHAR_TO_SCENES,
		episodicId,
		epCharId,
		sceneIds,
	};
}

export function addEpCharToScenesComplete(): AddEpCharToScenesCompleteAction {
	return {
		type: ADD_EP_CHAR_TO_SCENES_COMPLETE,
	};
}

export function addEpCharToScenesFailed(
	error: Error
): AddEpCharToScenesFailedAction {
	return {
		type: ADD_EP_CHAR_TO_SCENES_FAILED,
		error,
	};
}

export function addEpCharsToScene(
	episodicId: number,
	sceneId: number,
	epCharIds: number[]
): AddEpCharsToSceneAction {
	return {
		type: ADD_EP_CHARS_TO_SCENE,
		episodicId,
		epCharIds,
		sceneId,
	};
}

export function addEpCharsToSceneComplete(): AddEpCharsToSceneCompleteAction {
	return {
		type: ADD_EP_CHARS_TO_SCENE_COMPLETE,
	};
}

export function addEpCharsToSceneFailed(
	error: Error
): AddEpCharsToSceneFailedAction {
	return {
		type: ADD_EP_CHARS_TO_SCENE_FAILED,
		error,
	};
}

export function createComplete(charScenes: CharScene[]): CreateCompleteAction {
	return {
		charScenes,
		type: CREATE_COMPLETE,
	};
}

export function createEpCharForScene(
	episodicId: number,
	sceneId: number,
	epChar: Partial<EpisodicCharacter>,
	epActor: Partial<EpisodicActor>
): CreateEpCharForSceneAction {
	return {
		episodicId,
		sceneId,
		epChar,
		epActor,
		type: CREATE_EP_CHAR_FOR_SCENE,
	};
}

export function createEpCharForSceneCompleteAction(): CreateEpCharForSceneCompleteAction {
	return {
		type: CREATE_EP_CHAR_FOR_SCENE_COMPLETE,
	};
}

export function createEpCharForSceneFailedAction(
	error: Error
): CreateEpCharForSceneFailedAction {
	return {
		error,
		type: CREATE_EP_CHAR_FOR_SCENE_FAILED,
	};
}

export function deleteComplete(charScenes: CharScene[]): DeleteCompleteAction {
	return {
		charScenes,
		type: DELETE_COMPLETE,
	};
}

export function deleteChangeSceneAndCharSceneByScene(
	sceneId: number
): DeleteChangeSceneAndCharSceneBySceneIdAction {
	return {
		type: DELETE_CHANGE_SCENE_AND_CHAR_SCENE_BY_SCENE_ID,
		sceneId,
	};
}

export function deleteCharSceneByEpCharIdAndSceneId(
	episodicId: number,
	epCharId: number,
	sceneId: number
): DeleteCharSceneByEpCharIdAndSceneIdAction {
	return {
		episodicId,
		epCharId,
		sceneId,
		type: DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID,
	};
}

export function deleteCharSceneByEpCharIdAndSceneIdComplete(): DeleteCharSceneByEpCharIdAndSceneIdCompleteAction {
	return {
		type: DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_COMPLETE,
	};
}

export function deleteCharSceneByEpCharIdAndSceneIdFailed(
	error: Error
): DeleteCharSceneByEpCharIdAndSceneIdFailedAction {
	return {
		error,
		type: DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_FAILED,
	};
}

export function listComplete(charScenes: CharScene[]): ListCompleteAction {
	return {
		charScenes,
		type: LIST_COMPLETE,
	};
}

export function setSorting(sorting: SceneSorting[]): SetSortingAction {
	return {
		sorting,
		type: SET_SORTING,
	};
}

export function update(
	episodicId: number,
	charScenes: CharScene[]
): UpdateAction {
	return {
		episodicId,
		charScenes,
		type: UPDATE,
	};
}

export function updateComplete(charScenes: CharScene[]): UpdateCompleteAction {
	return {
		charScenes,
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function updatePropSceneCharScenesForEpChar(
	episodicId: number,
	epCharId: number,
	charScenes: CharScene[]
): UpdatePropSceneCharScenesForEpCharAction {
	return {
		episodicId,
		epCharId,
		charScenes,
		type: UPDATE_PROP_SCENE_CHAR_SCENES_FOR_EP_CHAR,
	};
}

export function updatePropSceneCharScenesForEpCharComplete(): UpdatePropSceneCharScenesForEpCharCompleteAction {
	return {
		type: UPDATE_PROP_SCENE_CHAR_SCENES_FOR_EP_CHAR_COMPLETE,
	};
}

export function updatePropSceneCharScenesForEpCharFailed(
	error: Error
): UpdatePropSceneCharScenesForEpCharFailedAction {
	return {
		type: UPDATE_PROP_SCENE_CHAR_SCENES_FOR_EP_CHAR_FAILED,
		error,
	};
}

export type Action =
	| AddBgCharToSceneAction
	| AddBgCharToSceneCompleteAction
	| AddBgCharToSceneFailedAction
	| AddEpCharToScenesAction
	| AddEpCharToScenesCompleteAction
	| AddEpCharToScenesFailedAction
	| AddEpCharsToSceneAction
	| AddEpCharsToSceneAction
	| AddEpCharsToSceneFailedAction
	| CreateCompleteAction
	| CreateEpCharForSceneAction
	| CreateEpCharForSceneCompleteAction
	| CreateEpCharForSceneFailedAction
	| DeleteCompleteAction
	| DeleteCharSceneByEpCharIdAndSceneIdAction
	| DeleteCharSceneByEpCharIdAndSceneIdCompleteAction
	| DeleteCharSceneByEpCharIdAndSceneIdFailedAction
	| DeleteChangeSceneAndCharSceneBySceneIdAction
	| ListCompleteAction
	| SetSortingAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction
	| UpdatePropSceneCharScenesForEpCharAction
	| UpdatePropSceneCharScenesForEpCharCompleteAction
	| UpdatePropSceneCharScenesForEpCharFailedAction;
