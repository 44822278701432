import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './inventory-field-setting.actions';
import * as api from './inventory-field-setting.api';
import * as logger from '../../logger';

export function* get() {
	try {
		const inventoryFieldSetting = yield call(api.get);
		yield put(actions.getComplete(inventoryFieldSetting));
	} catch (err) {
		yield put(actions.getFailed(err));
		if (err.response) {
			logger.log(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* update({ inventoryFieldSetting }: actions.UpdateAction) {
	try {
		const updatedInventoryFieldSetting = yield call(
			api.update,
			inventoryFieldSetting
		);
		yield put(actions.updateComplete(updatedInventoryFieldSetting));
	} catch (err) {
		yield put(actions.updateFailed(err));
		if (err.response) {
			logger.log(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeLatest(actions.GET, get);
	yield takeLatest(actions.UPDATE, update);
}

export default saga;
