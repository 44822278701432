import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	PhotoTagListResponse,
	PictureDeleteResponse,
	PictureGetResponse,
	PictureSetMainPhotoResponse,
	PictureUpdateResponse,
} from 'sos-models';
import * as actions from './picture.actions';
import * as api from './picture.api';
import * as logger from '../../logger';
import {
	changeActions,
	cmChangePictureActions,
	cmEpisodicItemPictureActions,
	episodicCharacterActions,
	episodicCharacterPictureActions,
	episodicItemActions,
	episodicPropActions,
	haChangeActions,
	haLookPictureActions,
	muChangeActions,
	muLookPictureActions,
	pictureSceneActions,
	prEpisodicPropPictureActions,
	sceneActions,
	setActions,
	setPictureActions,
	setPieceActions,
	setPiecePictureActions,
} from '../actions';

export function* destroy({ episodicId, pictureId }: actions.DestroyAction) {
	try {
		const { destroyed }: PictureDeleteResponse = yield call(
			api.destroy,
			episodicId,
			pictureId
		);

		if (destroyed.Picture) {
			yield put(actions.destroyComplete(destroyed.Picture));
		}
		if (destroyed.EpisodicCharacterPicture) {
			yield put(
				episodicCharacterPictureActions.destroyComplete(
					destroyed.EpisodicCharacterPicture
				)
			);
		}
		if (destroyed.CmEpisodicItemPicture) {
			yield put(
				cmEpisodicItemPictureActions.destroyComplete(
					destroyed.CmEpisodicItemPicture
				)
			);
		}
		if (destroyed.CmChangePicture) {
			yield put(
				cmChangePictureActions.destroyComplete(destroyed.CmChangePicture)
			);
		}
		if (destroyed.PictureScene) {
			yield put(pictureSceneActions.destroyComplete(destroyed.PictureScene));
		}
		if (destroyed.PrEpisodicPropPicture) {
			yield put(
				prEpisodicPropPictureActions.destroyComplete(
					destroyed.PrEpisodicPropPicture
				)
			);
		}
		if (destroyed.SetPicture) {
			yield put(setPictureActions.destroyComplete(destroyed.SetPicture));
		}
		if (destroyed.SetPiecePicture) {
			yield put(
				setPiecePictureActions.destroyComplete(destroyed.SetPiecePicture)
			);
		}
		if (destroyed.HaLookPicture) {
			yield put(haLookPictureActions.destroyComplete(destroyed.HaLookPicture));
		}
		if (destroyed.MuLookPicture) {
			yield put(muLookPictureActions.destroyComplete(destroyed.MuLookPicture));
		}
	} catch (err) {
		yield put(actions.destroyFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* get({ episodicId, pictureId }: actions.GetAction) {
	try {
		const { listed }: PictureGetResponse = yield call(
			api.get,
			episodicId,
			pictureId
		);
		if (listed.Picture) {
			yield put(actions.getComplete(listed.Picture[0]));
		}
	} catch (err) {
		yield put(actions.getFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* getCurrentForList({
	episodicId,
	pictureId,
}: actions.GetAction) {
	try {
		const { listed }: PictureGetResponse = yield call(
			api.get,
			episodicId,
			pictureId
		);
		if (listed.Picture) {
			yield put(actions.getCurrentForListComplete(listed.Picture[0]));
		}
	} catch (err) {
		yield put(actions.getCurrentForListFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* listPhotoTags({
	episodicId,
	pictureId,
}: actions.ListPhotoTagsAction) {
	try {
		const { listed }: PhotoTagListResponse = yield call(
			api.listPhotoTags,
			episodicId,
			pictureId
		);
		yield put(actions.listPhotoTagsComplete());
		if (listed.Change) {
			yield put(changeActions.listComplete(listed.Change));
		}
		if (listed.CmChangePicture) {
			yield put(cmChangePictureActions.listComplete(listed.CmChangePicture));
		}
		if (listed.CmEpisodicItemPicture) {
			yield put(
				cmEpisodicItemPictureActions.listComplete(listed.CmEpisodicItemPicture)
			);
		}
		if (listed.EpisodicCharacter) {
			yield put(
				episodicCharacterActions.listComplete(listed.EpisodicCharacter)
			);
		}
		if (listed.EpisodicCharacterPicture) {
			yield put(
				episodicCharacterPictureActions.listComplete(
					listed.EpisodicCharacterPicture
				)
			);
		}
		if (listed.EpisodicItem) {
			yield put(episodicItemActions.listComplete(listed.EpisodicItem));
		}
		if (listed.EpisodicProp) {
			yield put(episodicPropActions.listComplete(listed.EpisodicProp));
		}
		if (listed.HaChange) {
			yield put(haChangeActions.listComplete(listed.HaChange));
		}
		if (listed.HaLookPicture) {
			yield put(haLookPictureActions.listComplete(listed.HaLookPicture));
		}
		if (listed.MuChange) {
			yield put(muChangeActions.listComplete(listed.MuChange));
		}
		if (listed.MuLookPicture) {
			yield put(muLookPictureActions.listComplete(listed.MuLookPicture));
		}
		if (listed.PictureScene) {
			yield put(pictureSceneActions.listComplete(listed.PictureScene));
		}
		if (listed.PrEpisodicPropPicture) {
			yield put(
				prEpisodicPropPictureActions.listComplete(listed.PrEpisodicPropPicture)
			);
		}
		if (listed.Scene) {
			yield put(sceneActions.listComplete(listed.Scene));
		}
		if (listed.Set) {
			yield put(setActions.listComplete(listed.Set));
		}
		if (listed.SetPicture) {
			yield put(setPictureActions.listComplete(listed.SetPicture));
		}
		if (listed.SetPiece) {
			yield put(setPieceActions.listComplete(listed.SetPiece));
		}
		if (listed.SetPiecePicture) {
			yield put(setPiecePictureActions.listComplete(listed.SetPiecePicture));
		}
	} catch (err) {
		yield put(actions.listPhotoTagsFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* rotate({ episodicId, pictureId }: actions.RotateAction) {
	try {
		const { updated }: PictureUpdateResponse = yield call(
			api.rotate,
			episodicId,
			pictureId
		);
		if (updated.Picture) {
			yield put(actions.rotateComplete(updated.Picture[0]));
		}
	} catch (err) {
		yield put(actions.rotateFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* setMainPhoto({
	episodicId,
	pictureId,
	pictureRelationEntity,
}: actions.SetMainPhotoAction) {
	try {
		const { updated }: PictureSetMainPhotoResponse = yield call(
			api.setMainPhoto,
			episodicId,
			pictureId,
			pictureRelationEntity
		);
		yield put(actions.setMainPhotoComplete());
		if (updated.CmChangePicture) {
			yield put(cmChangePictureActions.listComplete(updated.CmChangePicture));
		}
		if (updated.CmEpisodicItemPicture) {
			yield put(
				cmEpisodicItemPictureActions.listComplete(updated.CmEpisodicItemPicture)
			);
		}
		if (updated.EpisodicCharacterPicture) {
			yield put(
				episodicCharacterPictureActions.listComplete(
					updated.EpisodicCharacterPicture
				)
			);
		}
		if (updated.HaLookPicture) {
			yield put(haLookPictureActions.listComplete(updated.HaLookPicture));
		}
		if (updated.MuLookPicture) {
			yield put(muLookPictureActions.listComplete(updated.MuLookPicture));
		}
		if (updated.PictureScene) {
			yield put(pictureSceneActions.listComplete(updated.PictureScene));
		}
		if (updated.PrEpisodicPropPicture) {
			yield put(
				prEpisodicPropPictureActions.listComplete(updated.PrEpisodicPropPicture)
			);
		}
		if (updated.SetPiecePicture) {
			yield put(setPiecePictureActions.listComplete(updated.SetPiecePicture));
		}
		if (updated.SetPicture) {
			yield put(setPictureActions.listComplete(updated.SetPicture));
		}
	} catch (err) {
		yield put(actions.setMainPhotoFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* update({ episodicId, picture }: actions.UpdateAction) {
	try {
		const { updated }: PictureUpdateResponse = yield call(
			api.update,
			episodicId,
			picture
		);
		if (updated.Picture) {
			yield put(actions.updateComplete(updated.Picture[0]));
		}
	} catch (err) {
		yield put(actions.updateFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* upload({
	departmentId,
	episodicId,
	pictureUploadBody,
}: actions.UploadAction) {
	try {
		yield call(api.upload, departmentId, episodicId, pictureUploadBody);
		yield put(actions.uploadComplete());
	} catch (err) {
		yield put(actions.uploadFailed(err));
		logger.error(err);
		if (err.response) {
			toast.error(err.response.data.message);
		}
	}
}

export default function* saga() {
	yield takeLatest(actions.GET, get);
	yield takeLatest(actions.GET_CURRENT_FOR_LIST, getCurrentForList);
	yield takeEvery(actions.DESTROY, destroy);
	yield takeEvery(actions.UPDATE, update);
	yield takeEvery(actions.ROTATE, rotate);
	yield takeEvery(actions.SET_MAIN_PHOTO, setMainPhoto);
	yield takeLatest(actions.LIST_PHOTO_TAGS, listPhotoTags);
	yield takeEvery(actions.UPLOAD, upload);
}
