import { normalize } from 'normalizr';
import { MuChangeFieldNames } from 'sos-models';
import * as actions from './mu-change-field-names.actions';
import { muChangeFieldNameSchema } from '../schemas';
import { ActionStatus, createReducer, failed, loading } from '../utils';

export interface State {
	entities: { [id: number]: MuChangeFieldNames };
	ids: number[];
	getStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	getStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.GET]: loading<State>('getStatus'),
	[actions.GET_COMPLETE]: getComplete,
	[actions.GET_FAILED]: failed<State>('getStatus'),
});

function getComplete(state: State, action: actions.GetCompleteAction): State {
	const normalized = normalize(action.muChangeFieldName, [
		muChangeFieldNameSchema,
	]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.muChangeFieldName,
		},
		ids: normalized.result,
		getStatus: ActionStatus.Complete,
	};
}
