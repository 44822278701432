import { without } from 'lodash';
import { FIELD_TYPES, TableColumn } from 'sos-models';

export const SCENE_INDEX_COLUMNS: { [name: string]: TableColumn } = {
	name: {
		name: 'Scene #',
		canonical_name: 'name',
		width: 150,
		type: FIELD_TYPES.INPUT,
	},
	script_date: {
		name: 'Script Day',
		canonical_name: 'script_date',
		width: 100,
		type: FIELD_TYPES.COMBO,
	},
	scene_location_id: {
		name: 'Script Loc.',
		canonical_name: 'scene_location_id',
		width: 150,
		type: FIELD_TYPES.SELECT,
	},
	description: {
		name: 'Scene Description',
		canonical_name: 'description',
		width: 200,
		type: FIELD_TYPES.INPUT,
	},
	primary_ids: {
		name: 'Principals',
		canonical_name: 'primary_ids',
		width: 150,
		type: FIELD_TYPES.MULTISELECT,
	},
	background_ids: {
		name: 'Background',
		canonical_name: 'background_ids',
		width: 150,
		type: FIELD_TYPES.MULTISELECT,
	},
	set_ids: {
		name: 'Sets',
		canonical_name: 'set_ids',
		width: 150,
		type: FIELD_TYPES.MULTISELECT,
	},
	shoot_day: {
		name: 'Shoot Date',
		canonical_name: 'shoot_day',
		width: 150,
		type: FIELD_TYPES.DATE,
	},
	page_length: {
		name: 'Page Count',
		canonical_name: 'page_length',
		width: 100,
		type: FIELD_TYPES.INPUT,
	},
	omit: {
		name: 'Omit',
		canonical_name: 'omit',
		width: 100,
		type: FIELD_TYPES.CHECKBOX,
	},
};

export const OTHER_SCENE_INDEX_COLUMNS: { [name: string]: string } = {
	name: 'name',
	episode_number: 'episode_number',
};

export const indexDefaultSelections = [
	'name',
	'script_date',
	'scene_location_id',
	'description',
	'primary_ids',
	'shoot_day',
];

export const indexDefaultHidden = without(
	Object.keys(SCENE_INDEX_COLUMNS),
	...indexDefaultSelections
);

export const inputColumns: string[] = [];
export const selectColumns: string[] = [];
export const multiSelectColumns: string[] = [];
export const currencyColumns: string[] = [];
export const numberColumns: string[] = [];
export const dateColumns: string[] = [];
export const checkboxColumns: string[] = [];
export const SCENE_INDEX_COLUMNS_ARRAY: TableColumn[] = [];
export const PROPS_SCENE_TABLE_COLUMNS_ARRAY = [
	SCENE_INDEX_COLUMNS.name,
	SCENE_INDEX_COLUMNS.script_date,
	SCENE_INDEX_COLUMNS.scene_location_id,
	SCENE_INDEX_COLUMNS.description,
	SCENE_INDEX_COLUMNS.shoot_day,
];

for (const key in SCENE_INDEX_COLUMNS) {
	if (SCENE_INDEX_COLUMNS.hasOwnProperty(key)) {
		const col = SCENE_INDEX_COLUMNS[key];

		SCENE_INDEX_COLUMNS_ARRAY.push(col);

		switch (col.type) {
			case FIELD_TYPES.DATE:
				dateColumns.push(col.canonical_name);
				break;
			case FIELD_TYPES.INPUT:
				inputColumns.push(col.canonical_name);
				break;
			case FIELD_TYPES.NUMBER:
				numberColumns.push(col.canonical_name);
				break;
			case FIELD_TYPES.CURRENCY:
				currencyColumns.push(col.canonical_name);
				break;
			case FIELD_TYPES.SELECT:
				selectColumns.push(col.canonical_name);
				break;
			case FIELD_TYPES.MULTISELECT:
				multiSelectColumns.push(col.canonical_name);
				break;
			case FIELD_TYPES.CHECKBOX:
				checkboxColumns.push(col.canonical_name);
				break;
			default:
				break;
		}
	}
}
