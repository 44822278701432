import { normalize } from 'normalizr';
import { HaChangeFieldNames } from 'sos-models';
import * as actions from './ha-change-field-names.actions';
import { haChangeFieldNameSchema } from '../schemas';
import { ActionStatus, createReducer, failed, loading } from '../utils';

export interface State {
	entities: { [id: number]: HaChangeFieldNames };
	ids: number[];
	getStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	getStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.GET]: loading<State>('getStatus'),
	[actions.GET_COMPLETE]: getComplete,
	[actions.GET_FAILED]: failed<State>('getStatus'),
});

function getComplete(state: State, action: actions.GetCompleteAction): State {
	const normalized = normalize(action.haChangeFieldName, [
		haChangeFieldNameSchema,
	]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.haChangeFieldName,
		},
		ids: normalized.result,
		getStatus: ActionStatus.Complete,
	};
}
