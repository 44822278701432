import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './set-prod.actions';
import * as api from './set-prod.api';
import * as logger from '../../logger';

export function* list({ episodicId }: actions.ListAction) {
	try {
		const setProds = yield call(api.list, episodicId);
		yield put(actions.listComplete(setProds));
	} catch (err) {
		yield put(actions.listFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading the set table. Please refresh to try again.'
			);
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeLatest(actions.LIST, list);
}

export default saga;
