import { SceneSortBy } from '@synconset/sorting';
import { CharScene, SortOrder } from 'sos-models';
import * as actions from './char-scene.actions';
import { charSceneSchema } from '../schemas';
import {
	ActionStatus,
	complete,
	createComplete,
	createReducer,
	destroyComplete,
	failed,
	listComplete,
	loading,
	updateComplete,
} from '../utils';

export interface State {
	entities: { [id: number]: CharScene };
	ids: number[];
	destroyStatus: ActionStatus;
	listStatus: ActionStatus;
	addBgCharsToSceneStatus: ActionStatus;
	addEpCharsToSceneStatus: ActionStatus;
	addEpCharToScenesStatus: ActionStatus;
	createEpCharForSceneStatus: ActionStatus;
	removeEpCharsFromSceneStatus: ActionStatus;
	sortBy: SceneSortBy;
	sortOrder: SortOrder;
}

const initial: State = {
	entities: {},
	ids: [],
	destroyStatus: ActionStatus.Inactive,
	listStatus: ActionStatus.Inactive,
	addEpCharsToSceneStatus: ActionStatus.Inactive,
	addEpCharToScenesStatus: ActionStatus.Inactive,
	addBgCharsToSceneStatus: ActionStatus.Inactive,
	removeEpCharsFromSceneStatus: ActionStatus.Inactive,
	createEpCharForSceneStatus: ActionStatus.Inactive,
	sortBy: 'name',
	sortOrder: 'asc',
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.ADD_BG_CHAR_TO_SCENE]: loading<State>('addBgCharsToSceneStatus'),
	[actions.ADD_BG_CHAR_TO_SCENE_COMPLETE]: createComplete<
		CharScene,
		State,
		actions.AddBgCharToSceneCompleteAction
	>('charScenes', charSceneSchema),
	[actions.ADD_BG_CHAR_TO_SCENE_FAILED]: failed<State>(
		'addBgCharsToSceneStatus'
	),
	[actions.ADD_EP_CHARS_TO_SCENE]: loading<State>('addEpCharsToSceneStatus'),
	[actions.ADD_EP_CHARS_TO_SCENE_FAILED]: failed<State>(
		'addEpCharsToSceneStatus'
	),
	[actions.ADD_EP_CHARS_TO_SCENE_COMPLETE]: complete<State>(
		'addEpCharsToSceneStatus'
	),
	[actions.ADD_EP_CHAR_TO_SCENES]: loading<State>('addEpCharToScenesStatus'),
	[actions.ADD_EP_CHAR_TO_SCENES_COMPLETE]: complete<State>(
		'addEpCharToScenesStatus'
	),
	[actions.ADD_EP_CHAR_TO_SCENES_FAILED]: failed<State>(
		'addEpCharToScenesStatus'
	),
	[actions.CREATE_COMPLETE]: createComplete<
		CharScene,
		State,
		actions.CreateCompleteAction
	>('charScenes', charSceneSchema),
	[actions.CREATE_EP_CHAR_FOR_SCENE]: loading<State>(
		'createEpCharForSceneStatus'
	),
	[actions.CREATE_EP_CHAR_FOR_SCENE_FAILED]: failed<State>(
		'createEpCharForSceneStatus'
	),
	[actions.CREATE_EP_CHAR_FOR_SCENE_COMPLETE]: complete<State>(
		'createEpCharForSceneStatus'
	),
	[actions.DELETE_COMPLETE]: destroyComplete<
		CharScene,
		State,
		actions.DeleteCompleteAction
	>('charScenes'),
	[actions.DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID]: loading<State>(
		'removeEpCharsFromSceneStatus'
	),
	[actions.DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_FAILED]: failed<State>(
		'removeEpCharsFromSceneStatus'
	),
	[actions.DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_COMPLETE]: complete<
		State
	>('removeEpCharsFromSceneStatus'),
	[actions.LIST_COMPLETE]: listComplete<
		CharScene,
		State,
		actions.ListCompleteAction
	>('charScenes', charSceneSchema),
	[actions.SET_SORTING]: setSorting,
	[actions.UPDATE_COMPLETE]: updateComplete<
		CharScene,
		State,
		actions.ListCompleteAction
	>('charScenes', charSceneSchema),
});

function setSorting(
	state: State,
	{ sorting }: actions.SetSortingAction
): State {
	// DX grid sends up sorting info as an array. For scenes we're only ever
	// going to have one element in that array. If not, it's a problem.
	const sortInfo = sorting[0];
	return {
		...state,
		sortBy: sortInfo.columnName,
		sortOrder: sortInfo.direction,
	};
}
