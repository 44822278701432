import { EpisodicActor } from 'sos-models';
import * as actions from './episodic-actor.actions';
import * as episodicCharacterActions from '../episodic-characters/episodic-character.actions';
import { episodicActorSchema } from '../schemas';
import {
	ActionStatus,
	createComplete,
	createReducer,
	destroyComplete,
	failed,
	complete,
	getComplete,
	listComplete,
	loading,
	NormalizedEntityMapping,
	updateComplete,
} from '../utils';

export interface State {
	createStatus: ActionStatus;
	currentId: number;
	destroyStatus: ActionStatus;
	entities: NormalizedEntityMapping<EpisodicActor>;
	exportToNewSeasonStatus: ActionStatus;
	getStatus: ActionStatus;
	ids: number[];
	listStatus: ActionStatus;
	setCurrentStatus: ActionStatus;
	updateStatus: ActionStatus;
}

export const initial: State = {
	createStatus: ActionStatus.Inactive,
	currentId: null,
	destroyStatus: ActionStatus.Inactive,
	entities: {},
	exportToNewSeasonStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
	ids: [],
	listStatus: ActionStatus.Inactive,
	setCurrentStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	actions.Action | episodicCharacterActions.Action
>(initial, {
	[actions.GET]: loading<State>('getStatus'),
	[actions.GET_COMPLETE]: getComplete<
		EpisodicActor,
		State,
		actions.GetCompleteAction
	>('episodicActor', episodicActorSchema),
	[actions.GET_FAILED]: failed<State>('getStatus'),
	[actions.CREATE]: loading<State>('createStatus'),
	[actions.CREATE_COMPLETE]: createComplete<
		EpisodicActor,
		State,
		actions.CreateCompleteAction
	>('episodicActors', episodicActorSchema),
	[actions.CREATE_FAILED]: failed<State>('createStatus'),
	[actions.DESTROY]: loading<State>('destroyStatus'),
	[actions.DESTROY_COMPLETE]: destroyComplete<
		EpisodicActor,
		State,
		actions.DestroyCompleteAction
	>('episodicActor'),
	[actions.DESTROY_FAILED]: failed<State>('destroyStatus'),
	[actions.EXPORT_TO_NEW_SEASON]: loading<State>('exportToNewSeasonStatus'),
	[actions.EXPORT_TO_NEW_SEASON_COMPLETE]: complete<State>(
		'exportToNewSeasonStatus'
	),
	[actions.EXPORT_TO_NEW_SEASON_FAILED]: failed<State>(
		'exportToNewSeasonStatus'
	),
	[actions.LIST]: loading<State>('listStatus'),
	[actions.LIST_COMPLETE]: listComplete<
		EpisodicActor,
		State,
		actions.ListCompleteAction
	>('episodicActors', episodicActorSchema),
	[actions.LIST_FAILED]: failed<State>('listStatus'),
	[actions.SET_CURRENT]: setCurrent,
	[actions.UPDATE]: loading<State>('updateStatus'),
	[actions.UPDATE_COMPLETE]: updateComplete<
		EpisodicActor,
		State,
		actions.UpdateCompleteAction
	>('episodicActors', episodicActorSchema),
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
});

function setCurrent(
	state: State,
	{ episodicActorId }: actions.SetCurrentAction
): State {
	return {
		...state,
		currentId: episodicActorId,
		setCurrentStatus: ActionStatus.Complete,
	};
}
