import { omit, without } from 'lodash';
import { normalize } from 'normalizr';
import { DEPARTMENTS, MuChange } from 'sos-models';
import * as actions from './mu-change.actions';
import * as changeLookActions from '../change-looks/change-look.actions';
import * as muChangeSceneActions from '../mu-change-scenes/mu-change-scene.actions';
import { muChangeSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createComplete,
	createReducer,
	failed,
	getComplete,
	listComplete,
	loading,
	uniqueArrayMerge,
	updateComplete,
} from '../utils';
import { SortOrder } from '@synconset/sorting';

export interface State extends BaseReducerState<MuChange> {
	createAndDeleteExistingStatus: ActionStatus;
	createStatus: ActionStatus;
	createMuChangeBySceneStatus: ActionStatus;
	deleteMuChangeForEpCharStatus: ActionStatus;
	getForEpCharStatus: ActionStatus;
	getMuChangesForChangeLookStatus: ActionStatus;
	getMuChangesForSceneStatus: ActionStatus;
	getStatus: ActionStatus;
	muChangeIdsForEpCharId: { [epCharId: number]: number[] };
	listStatus: ActionStatus;
	updateStatus: ActionStatus;
	sortBy: string;
	sortOrder: SortOrder;
}

export const initial: State = {
	createAndDeleteExistingStatus: ActionStatus.Inactive,
	createStatus: ActionStatus.Inactive,
	createMuChangeBySceneStatus: ActionStatus.Inactive,
	deleteMuChangeForEpCharStatus: ActionStatus.Inactive,
	entities: {},
	getForEpCharStatus: ActionStatus.Inactive,
	getMuChangesForChangeLookStatus: ActionStatus.Inactive,
	getMuChangesForSceneStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
	ids: [],
	muChangeIdsForEpCharId: {},
	listStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
	sortBy: 'change_num',
	sortOrder: 'asc',
};

export const reducer = createReducer<
	State,
	actions.Action | muChangeSceneActions.Action | changeLookActions.Action
>(initial, {
	[actions.GET_COMPLETE]: getComplete<
		MuChange,
		State,
		actions.GetCompleteAction
	>('muChange', muChangeSchema),
	[actions.CREATE_MU_CHANGE_COMPLETE]: createComplete<
		MuChange,
		State,
		actions.CreateMuChangeCompleteAction
	>('change', muChangeSchema),
	[actions.DELETE_COMPLETE]: (
		state: State,
		action: actions.DeleteCompleteAction
	) => {
		if (action.hasOwnProperty('departmentId')) {
			return deleteChangeLookComplete(
				state,
				action as unknown as changeLookActions.DeleteCompleteAction
			);
		}
		return deleteComplete(state, action);
	},
	[actions.GET]: loading<State>('getStatus'),
	[actions.GET_FAILED]: failed<State>('getStatus'),
	[actions.LIST_COMPLETE]: listComplete<
		MuChange,
		State,
		actions.ListCompleteAction
	>('muChanges', muChangeSchema),

	[actions.GET_MU_CHANGES_FOR_EP_CHAR]: loading<State>('getForEpCharStatus'),
	[actions.GET_MU_CHANGES_FOR_CHANGE_LOOK]: loading<State>(
		'getMuChangesForChangeLookStatus'
	),
	[actions.GET_MU_CHANGES_FOR_CHANGE_LOOK_COMPLETE]: getForChangeLookComplete,
	[actions.GET_MU_CHANGES_FOR_CHANGE_LOOK_FAILED]: failed<State>(
		'getMuChangesForChangeLookStatus'
	),
	[actions.GET_MU_CHANGES_FOR_EP_CHAR_COMPLETE]: getForEpCharComplete,
	[actions.GET_MU_CHANGES_FOR_EP_CHAR_FAILED]:
		failed<State>('getForEpCharStatus'),
	[actions.DELETE_MU_CHANGE_FOR_EP_CHAR]: loading<State>(
		'deleteMuChangeForEpCharStatus'
	),
	[actions.DELETE_MU_CHANGE_FOR_EP_CHAR_COMPLETE]:
		deleteMuChangeForEpCharComplete,
	[actions.DELETE_MU_CHANGE_FOR_EP_CHAR_FAILED]: failed<State>(
		'deleteMuChangeForEpCharStatus'
	),
	[actions.GET_MU_CHANGES_FOR_SCENE]: loading<State>(
		'getMuChangesForSceneStatus'
	),
	[actions.GET_MU_CHANGES_FOR_SCENE_COMPLETE]: getMuChangesForSceneComplete,
	[actions.GET_MU_CHANGES_FOR_SCENE_FAILED]: failed<State>(
		'getMuChangesForSceneStatus'
	),
	[actions.CREATE_MU_CHANGE]: loading<State>('createStatus'),
	[actions.CREATE_MU_CHANGE_FAILED]: failed<State>('createStatus'),
	[actions.CREATE_MU_CHANGE_BY_SCENE]: loading<State>(
		'createMuChangeBySceneStatus'
	),
	[actions.CREATE_MU_CHANGE_BY_SCENE_COMPLETE]: createMuChangeBySceneComplete,
	[actions.CREATE_MU_CHANGE_BY_SCENE_FAILED]: failed<State>(
		'createMuChangeBySceneStatus'
	),
	[actions.SET_SORTING]: setSorting,
	[changeLookActions.CREATE]: loading<State>('createStatus'),
	[muChangeSceneActions.CREATE_AND_DELETE_EXISTING]: loading<State>(
		'createAndDeleteExistingStatus'
	),
	[muChangeSceneActions.CREATE_AND_DELETE_EXISTING_COMPLETE]:
		createAndDeleteExistingComplete,
	[muChangeSceneActions.CREATE_AND_DELETE_EXISTING_FAILED]: failed<State>(
		'createAndDeleteExistingStatus'
	),
	[actions.UPDATE]: loading<State>('updateStatus'),
	[actions.UPDATE_COMPLETE]: updateComplete<
		MuChange,
		State,
		actions.UpdateCompleteAction
	>('muChanges', muChangeSchema),
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
});

function deleteComplete(
	state: State,
	action: actions.DeleteCompleteAction
): State {
	const muChangeIds = action.muChanges.map((muc) => muc.id);
	const ids = without(state.ids, ...muChangeIds);
	const entities = omit(state.entities, muChangeIds);
	const muChangeIdsForEpCharId = {};
	Object.keys(state.muChangeIdsForEpCharId).forEach((epItemId) => {
		muChangeIdsForEpCharId[epItemId] = without(
			state.muChangeIdsForEpCharId[epItemId],
			...muChangeIds
		);
	});
	return {
		...state,
		entities,
		ids,
		muChangeIdsForEpCharId,
	};
}

function getForChangeLookComplete(
	state: State,
	action: actions.GetMuChangesForChangeLookCompleteAction
): State {
	const normalized = normalize(action.muChanges, [muChangeSchema]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.muChanges,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		getMuChangesForChangeLookStatus: ActionStatus.Complete,
	};
}

function getForEpCharComplete(
	state: State,
	action: actions.GetMuChangesForEpCharCompleteAction
): State {
	const normalized = normalize(action.muChanges, [muChangeSchema]);
	const muChangeIdsForEpCharId = { ...state.muChangeIdsForEpCharId };
	muChangeIdsForEpCharId[action.epCharId] = normalized.result;

	return {
		...state,
		entities: {
			//...state.entities,
			...normalized.entities.muChanges,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		muChangeIdsForEpCharId,
		getForEpCharStatus: ActionStatus.Complete,
	};
}

function deleteMuChangeForEpCharComplete(
	state: State,
	{ response: { destroyed } }: actions.DeleteMuChangeForEpCharCompleteAction
): State {
	const entities: { [id: number]: MuChange } = omit(
		state.entities,
		destroyed.MuChange.map((mc) => mc.id)
	);
	const deletedIds = Object.keys(destroyed.MuChange).map(
		(id) => destroyed.MuChange[id].id
	);

	const muChangeIdsForEpCharId = {};
	Object.keys(state.muChangeIdsForEpCharId).forEach((epItemId) => {
		muChangeIdsForEpCharId[epItemId] = state.muChangeIdsForEpCharId[
			epItemId
		].filter((id) => !deletedIds.includes(id));
	});
	return {
		...state,
		entities,
		ids: state.ids.filter((id) => !deletedIds.includes(id)),
		muChangeIdsForEpCharId,
		deleteMuChangeForEpCharStatus: ActionStatus.Complete,
	};
}

function getMuChangesForSceneComplete(
	state: State,
	action: actions.GetMuChangesForSceneCompleteAction
): State {
	const normalized = normalize(action.muChanges, [muChangeSchema]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.muChanges,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		getMuChangesForSceneStatus: ActionStatus.Complete,
	};
}

function createMuChangeBySceneComplete(
	state: State,
	{ created, updated }: actions.CreateMuChangeBySceneCompleteAction
): State {
	const normalizedCreated = normalize(created.MuChange, [muChangeSchema]);
	const normalizedUpdated = normalize(updated.MuChange, [muChangeSchema]);
	const createdUpdatedIds = uniqueArrayMerge(
		normalizedCreated.result,
		normalizedUpdated.result
	);

	return {
		...state,
		entities: {
			...state.entities,
			...normalizedCreated.entities.muChanges,
			...normalizedUpdated.entities.muChanges,
		},
		ids: uniqueArrayMerge(state.ids, createdUpdatedIds),
		createMuChangeBySceneStatus: ActionStatus.Complete,
	};
}

function createAndDeleteExistingComplete(
	state: State,
	{ updated }: muChangeSceneActions.CreateAndDeleteExistingCompleteAction
): State {
	const normalized = normalize(updated.MuChange, [muChangeSchema]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.muChanges,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		createAndDeleteExistingStatus: ActionStatus.Complete,
	};
}

function deleteChangeLookComplete(
	state: State,
	{ changeLooks, departmentId }: changeLookActions.DeleteCompleteAction
): State {
	if (departmentId !== DEPARTMENTS.MU) {
		return state;
	}
	const lookIds = changeLooks.map((cl) => cl.id);
	const ids = without(state.ids, ...lookIds);
	const entities = omit(state.entities, lookIds);
	return {
		...state,
		entities: {
			...state.entities,
			...entities,
		},
		ids,
	};
}

function setSorting(
	state: State,
	{ sorting }: actions.SetSortingAction
): State {
	const sortInfo = sorting[0];
	return {
		...state,
		sortBy: sortInfo.columnName,
		sortOrder: sortInfo.direction,
	};
}
