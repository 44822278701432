import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './character.actions';
import * as api from './character.api';
import * as logger from '../../logger';

export function* list({ episodicId }: actions.ListAction) {
	try {
		const characters = yield call(api.list, episodicId);
		yield put(actions.listComplete(characters));
	} catch (err) {
		yield put(actions.listFailed(err));
		logger.responseError(err, 'loading the characters', true);
	}
}

function* saga() {
	yield takeLatest(actions.LIST, list);
}

export default saga;
