import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	SceneCreateResponse,
	SceneDeleteResponse,
	SceneGetResponse,
	SceneListResponse,
	ScenesForEpisodicPropListResponse,
	SceneUpdateResponse,
} from 'sos-models';
import * as sceneActions from './scene.actions';
import * as api from './scene.api';
import * as logger from '../../logger';
import * as changeSceneActions from '../change-scenes/change-scene.actions';
import * as charSceneActions from '../char-scenes/char-scene.actions';
import * as characterActions from '../characters/character.actions';
import * as cmSceneNoteActions from '../cm-scene-notes/cm-scene-note.actions';
import * as episodicActorActions from '../episodic-actors/episodic-actor.actions';
import * as episodicCharacterActions from '../episodic-characters/episodic-character.actions';
import * as haChangeSceneActions from '../ha-change-scenes/ha-change-scene.actions';
import * as haSceneNoteActions from '../ha-scene-notes/ha-scene-note.actions';
import * as muChangeSceneActions from '../mu-change-scenes/mu-change-scene.actions';
import * as muSceneNoteActions from '../mu-scene-notes/mu-scene-note.actions';
import * as pictureSceneActions from '../picture-scenes/picture-scene.actions';
import * as prSceneNoteActions from '../pr-scene-notes/pr-scene-note.actions';
import * as propSceneCharSceneActions from '../prop-scene-char-scenes/prop-scene-char-scene.actions';
import * as propSceneActions from '../prop-scenes/prop-scene.actions';
import * as scriptLocationActions from '../script-locations/script-location.actions';
import * as setSceneActions from '../set-scenes/set-scene.actions';
import * as setActions from '../sets/set.actions';

export function* create({
	episodicId,
	scene,
	scriptLocation,
	primaryIds,
	backgroundIds,
	setIds,
}: sceneActions.CreateAction) {
	try {
		const { created, updated }: SceneCreateResponse = yield call(
			api.create,
			episodicId,
			scene,
			scriptLocation,
			primaryIds,
			backgroundIds,
			setIds
		);
		if (created.Character) {
			yield put(characterActions.createComplete(created.Character));
		}
		if (created.CharScene) {
			yield put(charSceneActions.createComplete(created.CharScene));
		}
		if (created.Scene) {
			yield put(sceneActions.createComplete(created.Scene));
		}
		if (created.ScriptLocation) {
			yield put(scriptLocationActions.createComplete(created.ScriptLocation));
		}
		if (created.SetScene) {
			yield put(setSceneActions.createComplete(created.SetScene));
		}
		if (updated.EpisodicCharacter) {
			yield put(
				episodicCharacterActions.updateComplete(updated.EpisodicCharacter)
			);
		}
		toast.success('Scene was created');
	} catch (err) {
		yield put(sceneActions.createFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* duplicate({
	episodicId,
	prodId,
	sceneId,
	name,
}: sceneActions.DuplicateAction) {
	try {
		const { created, updated }: SceneCreateResponse = yield call(
			api.duplicate,
			episodicId,
			prodId,
			sceneId,
			name
		);
		if (created.ChangeScene && created.ChangeScene.length) {
			yield put(changeSceneActions.createComplete(created.ChangeScene));
		}
		if (created.CharScene && created.CharScene.length) {
			yield put(charSceneActions.createComplete(created.CharScene));
		}
		if (created.CmSceneNote && created.CmSceneNote.length) {
			yield put(cmSceneNoteActions.createComplete(created.CmSceneNote[0]));
		}
		if (created.HaChangeScene && created.HaChangeScene.length) {
			yield put(haChangeSceneActions.createComplete(created.HaChangeScene));
		}
		if (created.HaSceneNote && created.HaSceneNote.length) {
			yield put(haSceneNoteActions.createComplete(created.HaSceneNote[0]));
		}
		if (created.MuChangeScene && created.MuChangeScene.length) {
			yield put(muChangeSceneActions.createComplete(created.MuChangeScene));
		}
		if (created.MuSceneNote && created.MuSceneNote.length) {
			yield put(muSceneNoteActions.createComplete(created.MuSceneNote[0]));
		}
		if (created.PropSceneCharScene && created.PropSceneCharScene.length) {
			yield put(
				propSceneCharSceneActions.createComplete(created.PropSceneCharScene)
			);
		}
		if (created.PropScene && created.PropScene.length) {
			yield put(propSceneActions.createComplete(created.PropScene));
		}
		if (created.PrSceneNote && created.PrSceneNote.length) {
			yield put(prSceneNoteActions.createComplete(created.PrSceneNote[0]));
		}
		if (created.Scene && created.Scene.length) {
			yield put(sceneActions.createComplete(created.Scene));
		}
		if (created.SetScene && created.SetScene.length) {
			yield put(setSceneActions.createComplete(created.SetScene));
		}
		if (updated.EpisodicCharacter) {
			yield put(
				episodicCharacterActions.updateComplete(updated.EpisodicCharacter)
			);
		}
	} catch (err) {
		yield put(sceneActions.createFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export function* exportScenes({
	episodicId,
	prodIds
}: sceneActions.ExportScenesAction) {
	try {
		const results = yield call(
			api.exportScenes,
			episodicId,
			prodIds
		);
		yield put(sceneActions.exportScenesComplete(results));
	} catch (err) {
		yield put(sceneActions.exportScenesFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}
export function* list({ episodicId }: sceneActions.ListAction) {
	try {
		const { listed }: SceneListResponse = yield call(api.list, episodicId);
		if (listed.Scene) {
			yield put(sceneActions.listComplete(listed.Scene));
		}
		if (listed.Set) {
			yield put(setActions.listComplete(listed.Set));
		}
		if (listed.SetScene) {
			yield put(setSceneActions.listComplete(listed.SetScene));
		}
		if (listed.CharScene) {
			yield put(charSceneActions.listComplete(listed.CharScene));
		}
		if (listed.EpisodicCharacter) {
			yield put(
				episodicCharacterActions.listComplete(listed.EpisodicCharacter)
			);
		}
		if (listed.ScriptLocation) {
			yield put(scriptLocationActions.listComplete(listed.ScriptLocation));
		}
	} catch (err) {
		yield put(sceneActions.listFailed(err));
		logger.responseError(err, 'loading the scenes', true);
	}
}

export function* destroy({ sceneId, episodicId }: sceneActions.DestroyAction) {
	try {
		const result: SceneDeleteResponse = yield call(
			api.destroy,
			sceneId,
			episodicId
		);

		yield put(sceneActions.destroyComplete(result.destroyed.Scene));
		yield put(charSceneActions.deleteComplete(result.destroyed.CharScene));
		yield put(
			haChangeSceneActions.deleteComplete(result.destroyed.HaChangeScene)
		);
		yield put(
			muChangeSceneActions.deleteComplete(result.destroyed.MuChangeScene)
		);
		yield put(propSceneActions.destroyComplete(result.destroyed.PropScene));
		yield put(
			propSceneCharSceneActions.destroyComplete(
				result.destroyed.PropSceneCharScene
			)
		);
		yield put(setSceneActions.deleteComplete(result.destroyed.SetScene));

		if (result.destroyed.CmSceneNote && result.destroyed.CmSceneNote[0]) {
			yield put(
				cmSceneNoteActions.destroyComplete(result.destroyed.CmSceneNote[0])
			);
		}
		if (result.destroyed.HaSceneNote && result.destroyed.HaSceneNote[0]) {
			yield put(
				haSceneNoteActions.destroyComplete(result.destroyed.HaSceneNote[0])
			);
		}
		if (result.destroyed.MuSceneNote && result.destroyed.MuSceneNote[0]) {
			yield put(
				muSceneNoteActions.destroyComplete(result.destroyed.MuSceneNote[0])
			);
		}
		if (result.destroyed.PrSceneNote && result.destroyed.PrSceneNote[0]) {
			yield put(
				prSceneNoteActions.destroyComplete(result.destroyed.PrSceneNote[0])
			);
		}
		yield put(pictureSceneActions.deleteBySceneId(sceneId));
	} catch (err) {
		yield put(sceneActions.destroyFailed(err));
		logger.responseError(err, 'deleting this scene', true);
	}
}

export function* update({
	episodicId,
	scenes,
	addedEpCharIds,
	removedEpCharIds,
	addedSetIds,
	removedSetIds,
}: sceneActions.UpdateAction) {
	try {
		const { updated, created, destroyed }: SceneUpdateResponse = yield call(
			api.update,
			episodicId,
			scenes,
			addedEpCharIds,
			removedEpCharIds,
			addedSetIds,
			removedSetIds
		);
		yield put(sceneActions.updateComplete(updated.Scene));
		if (updated.EpisodicCharacter) {
			yield put(
				episodicCharacterActions.updateComplete(updated.EpisodicCharacter)
			);
		}
		if (created.SetScene) {
			yield put(setSceneActions.createComplete(created.SetScene));
		}
		if (destroyed.SetScene) {
			yield put(setSceneActions.deleteComplete(destroyed.SetScene));
		}

		if (created.CharScene) {
			yield put(charSceneActions.createComplete(created.CharScene));
		}
		if (destroyed.CharScene) {
			yield put(charSceneActions.deleteComplete(destroyed.CharScene));
		}

		if (created.ScriptLocation) {
			yield put(scriptLocationActions.createComplete(created.ScriptLocation));
		}
	} catch (err) {
		yield put(sceneActions.updateFailed(err));
		logger.responseError(err, 'updating the scenes', true);
	}
}

export function* getEpisodesForProp({
	episodicId,
	departmentId,
	epPropId,
}: sceneActions.GetEpisodesForPropAction) {
	try {
		const response: ScenesForEpisodicPropListResponse = yield call(
			api.getForPropId,
			episodicId,
			departmentId,
			epPropId
		);
		yield put(sceneActions.getEpisodesForPropComplete());
		yield put(sceneActions.listComplete(response.listed.Scene));
		yield put(propSceneActions.listComplete(response.listed.PropScene));
	} catch (err) {
		yield put(sceneActions.getEpisodesForPropFailed(err));
		logger.responseError(err, 'loading the props scene table', true);
	}
}

export function* get({
	sceneId,
	episodicId,
	productionIds,
}: sceneActions.GetAction) {
	try {
		const { listed, previous, next }: SceneGetResponse = yield call(
			api.get,
			sceneId,
			episodicId,
			productionIds
		);
		if (listed.Scene) {
			yield put(sceneActions.getComplete(listed.Scene, previous, next));
		}
		if (listed.CharScene) {
			yield put(charSceneActions.listComplete(listed.CharScene));
		}
		if (listed.Character) {
			yield put(characterActions.listComplete(listed.Character));
		}
		if (listed.EpisodicCharacter) {
			yield put(
				episodicCharacterActions.listComplete(listed.EpisodicCharacter)
			);
		}
		if (listed.EpisodicActor) {
			yield put(episodicActorActions.listComplete(listed.EpisodicActor));
		}
	} catch (err) {
		yield put(sceneActions.getFailed(err));
		logger.responseError(err, 'loading the scenes', true);
	}
}

function* saga() {
	yield takeEvery(sceneActions.CREATE, create);
	yield takeEvery(sceneActions.DUPLICATE, duplicate);
	yield takeLatest(sceneActions.EXPORT_SCENES, exportScenes);
	yield takeLatest(sceneActions.LIST, list);
	yield takeEvery(sceneActions.DESTROY, destroy);
	yield takeEvery(sceneActions.UPDATE, update);
	yield takeLatest(sceneActions.GET_EPISODES_FOR_PROP, getEpisodesForProp);
	yield takeLatest(sceneActions.GET, get);
}

export default saga;
