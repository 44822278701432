import { DEPARTMENTS } from 'sos-models';

export const SET_CURRENT = '[Inventory View] Set Current';
type SET_CURRENT = typeof SET_CURRENT;

export interface SetCurrentAction {
	type: SET_CURRENT;
	id: number;
	departmentId: DEPARTMENTS;
}

export function setCurrent(
	id: number,
	departmentId: DEPARTMENTS
): SetCurrentAction {
	return {
		id,
		departmentId,
		type: SET_CURRENT,
	};
}

export type Action = SetCurrentAction;
