import { SortOrder } from 'sos-models';
import * as actions from './character-index.actions';
import { createReducer } from '../utils';

export interface State {
	backgroundPage: number;
	backgroundPageSize: actions.PageSize;
	backgroundSortBy: string;
	backgroundSortOrder: SortOrder;
	primaryPage: number;
	primaryPageSize: actions.PageSize;
	primarySortBy: string;
	primarySortOrder: SortOrder;
}

const initial: State = {
	backgroundPage: 0,
	backgroundPageSize: 25,
	backgroundSortBy: 'first_name',
	backgroundSortOrder: 'asc',
	primaryPage: 0,
	primaryPageSize: 25,
	primarySortBy: 'first_name',
	primarySortOrder: 'asc',
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.SET_PAGING_PRIMARY]: setPagingPrimary,
	[actions.SET_PAGING_BACKGROUND]: setPagingBackground,
	[actions.SET_SORTING_PRIMARY]: setSortingPrimary,
	[actions.SET_SORTING_BACKGROUND]: setSortingBackground,
});

function setPagingPrimary(
	state: State,
	{ page, pageSize }: actions.SetPagingPrimaryAction
): State {
	return {
		...state,
		primaryPage: page,
		primaryPageSize: pageSize,
	};
}

function setPagingBackground(
	state: State,
	{ page, pageSize }: actions.SetPagingBackgroundAction
): State {
	return {
		...state,
		backgroundPage: page,
		backgroundPageSize: pageSize,
	};
}

function setSortingPrimary(
	state: State,
	{ sorting }: actions.SetSortingPrimaryAction
): State {
	const sortInfo = sorting[0];
	return {
		...state,
		primarySortBy: sortInfo.columnName,
		primarySortOrder: sortInfo.direction,
	};
}

function setSortingBackground(
	state: State,
	{ sorting }: actions.SetSortingBackgroundAction
): State {
	const sortInfo = sorting[0];
	return {
		...state,
		backgroundSortBy: sortInfo.columnName,
		backgroundSortOrder: sortInfo.direction,
	};
}
