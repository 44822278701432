import { EpisodicCharacter, Production } from 'sos-models';
import * as actions from './add-background-characters-to-scene-modal.actions';
import * as univeralSearchActions from '../universal-search/universal-search.actions';
import { ActionStatus, createReducer, failed, loading } from '../utils';

export interface State {
	selectedProd: Production;
	bgEpChars: EpisodicCharacter[];
	backgroundCountsByCharAndSceneId: { [complexId: string]: number };
	searchBgCharStatus: ActionStatus;
}

const initial: State = {
	selectedProd: null,
	bgEpChars: [],
	backgroundCountsByCharAndSceneId: {},
	searchBgCharStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	actions.Action | univeralSearchActions.Action
>(initial, {
	[actions.SET_BG_CHAR_COUNT]: setBgCharCount,
	[actions.SET_PROD_DROPDOWN]: setProdDropDown,
	[actions.CLEAR_BG_CHAR_SEARCH]: clearBgCharSearch,
	[univeralSearchActions.SEARCH_BG_CHARS]: loading<State>('searchBgCharStatus'),
	[univeralSearchActions.SEARCH_BG_CHARS_COMPLETE]: searchBgCharsComplete,
	[univeralSearchActions.SEARCH_BG_CHARS_FAILED]: failed<State>(
		'searchBgCharStatus'
	),
});

function setBgCharCount(
	state: State,
	action: actions.SetBgCharCountAction
): State {
	let backgroundCountsByCharAndSceneId = {
		...state.backgroundCountsByCharAndSceneId,
	};
	backgroundCountsByCharAndSceneId[`${action.epCharId}.${action.sceneId}`] =
		action.count;
	return {
		...state,
		backgroundCountsByCharAndSceneId,
	};
}

function setProdDropDown(
	state: State,
	action: actions.SetProdDropdownAction
): State {
	return {
		...state,
		selectedProd: action.prod,
	};
}

function searchBgCharsComplete(
	state: State,
	{ epChars }: univeralSearchActions.SearchBackgroundCharactersCompleteAction
): State {
	return {
		...state,
		bgEpChars: epChars,
		searchBgCharStatus: ActionStatus.Complete,
	};
}

function clearBgCharSearch(
	state: State,
	_action: actions.ClearBgCharSearchAction
) {
	return {
		...state,
		bgEpChars: [],
		searchBgCharStatus: ActionStatus.Inactive,
	};
}
