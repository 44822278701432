import { call, put, takeEvery } from 'redux-saga/effects';
import { PrEpisodicPropPictureDeleteResponse } from 'sos-models';
import * as actions from './pr-episodic-prop-picture.actions';
import * as api from './pr-episodic-prop-picture.api';
import * as logger from '../../logger';

export function* destroy({
	prEpisodicPropPictureId,
	episodicId,
}: actions.DestroyAction) {
	try {
		const result: PrEpisodicPropPictureDeleteResponse = yield call(
			api.destroy,
			episodicId,
			prEpisodicPropPictureId
		);
		yield put(actions.destroyComplete(result.destroyed.PrEpisodicPropPicture));
	} catch (err) {
		yield put(actions.destroyFailed(err));
		logger.responseError(err, 'loading the picture', true);
	}
}

function* saga() {
	yield takeEvery(actions.DESTROY, destroy);
}

export default saga;
