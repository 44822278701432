import { SetStorageLocation } from 'sos-models';
import axios from '../../config/axios';

export function create(
	setStorageLocation: Partial<SetStorageLocation>
): Promise<Partial<SetStorageLocation>> {
	return axios
		.post<Partial<SetStorageLocation>>(
			`/api/set-storage-locations`,
			setStorageLocation
		)
		.then((response) => response.data);
}

export function destroy(setStorageLocation: Partial<SetStorageLocation>) {
	return axios
		.delete(`/api/set-storage-locations/${setStorageLocation.id}`)
		.then((response) => response.data);
}

export function update(
	setStorageLocation: Partial<SetStorageLocation>
): Promise<Partial<SetStorageLocation>> {
	return axios
		.put<Partial<SetStorageLocation>>(
			`/api/set-storage-locations/${setStorageLocation.id}`,
			setStorageLocation
		)
		.then((response) => response.data);
}
