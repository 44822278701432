import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as actions from './vendor.actions';
import * as api from './vendor.api';
import * as logger from '../../logger';

export function* create({
	vendor,
	episodicId,
	department,
}: actions.CreateAction) {
	try {
		const createdVendor = yield call(
			api.create,
			vendor,
			episodicId,
			department
		);
		yield put(actions.createComplete(createdVendor));
	} catch (err) {
		yield put(actions.createFailed(err));
		logger.responseError(err, 'saving the vendor');
	}
}

export function* list({ episodicId }: actions.ListAction) {
	try {
		const vendors = yield call(api.list, episodicId);
		yield put(actions.listComplete(vendors));
	} catch (err) {
		yield put(actions.listFailed(err));
		logger.responseError(err, 'loading the vendors', true);
	}
}

function* saga() {
	yield takeEvery(actions.CREATE, create);
	yield takeLatest(actions.LIST, list);
}

export default saga;
