import {
	DEPARTMENTS,
	InventorySatellite,
	InventorySatellitesList,
} from 'sos-models';
import axios from '../../config/axios';

export async function list(
	episodicId: number
): Promise<InventorySatellitesList> {
	const { data } = await axios.get<InventorySatellitesList>(
		`/api/episodics/${episodicId}/inventory-satellites`
	);
	return data;
}

export async function create(
	episodicId: number,
	department: DEPARTMENTS,
	data: {}
): Promise<Partial<InventorySatellite>> {
	return await axios
		.post<Partial<InventorySatellite>>(
			`/api/episodics/${episodicId}/inventory-satellites`,
			data
		)
		.then((response) => response.data);
}
