import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import * as actions from './set-piece-status.actions';
import * as api from './set-piece-status.api';
import * as logger from '../../logger';

export function* create({ setPieceStatus }: actions.CreateAction) {
	try {
		const createdCmStatus = yield call(api.create, setPieceStatus);
		yield put(actions.createComplete(createdCmStatus));
	} catch (err) {
		yield put(actions.createFailed(err));
		if (err.response) {
			toast.error('There was an error saving the status.  Please try again.');
		} else {
			logger.error(err);
		}
	}
}

export function* destroy({ setPieceStatus }: actions.DestroyAction) {
	try {
		yield call(api.destroy, setPieceStatus);
		yield put(actions.destroyComplete(setPieceStatus));
	} catch (err) {
		yield put(actions.destroyFailed(err));
		if (err.response) {
			toast.error('There was an error deleting the status.  Please try again.');
		} else {
			logger.error(err);
		}
	}
}

export function* update({ setPieceStatus }: actions.UpdateAction) {
	try {
		const updatedCmStatus = yield call(api.update, setPieceStatus);
		yield put(actions.updateComplete(updatedCmStatus));
	} catch (err) {
		yield put(actions.updateFailed(err));
		if (err.response) {
			toast.error('There was an error saving the status.  Please try again.');
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeEvery(actions.CREATE, create);
	yield takeEvery(actions.DESTROY, destroy);
	yield takeEvery(actions.UPDATE, update);
}

export default saga;
