import { EpisodicFlag } from 'sos-models';

export const LIST_COMPLETE = '[EpisodicFlags] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export interface ListCompleteAction {
	type: LIST_COMPLETE;
	episodicFlags: EpisodicFlag[];
}

export function listComplete(
	episodicFlags: EpisodicFlag[]
): ListCompleteAction {
	return {
		type: LIST_COMPLETE,
		episodicFlags,
	};
}

export type Action = ListCompleteAction;
