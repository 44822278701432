import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import * as qs from 'qs';
import { CookieService } from '../services/cookie.service';

// tslint:disable-next-line
axios.defaults.paramsSerializer = function (params: any) {
	return qs.stringify(params, { arrayFormat: 'repeat' });
};

export default axios;

// tslint:disable-next-line
axios.interceptors.response.use(
	function (response: AxiosResponse<any>) {
		return response;
		// tslint:disable-next-line
	},
	function (error: any) {
		// Do something with response error
		if (
			error.response.status === 401 &&
			!window.location.href.includes('core?r=site/login') &&
			!window.location.href.includes('core?r=users/sessions')
		) {
			return (window.location.href = `/core?r=site/login&redirect=${window.location.href}`);
		}
		return Promise.reject(error);
	}
);

// tslint:disable-next-line
axios.interceptors.request.use(function (config: InternalAxiosRequestConfig) {
	if (['delete', 'post', 'patch', 'put'].includes(config.method)) {
		const xsrfString = CookieService.get('YII_CSRF_TOKEN');
		if (xsrfString) {
			config.headers['X-CSRF-Token'] = encodeURIComponent(xsrfString);
		}
	}

	return config;
});
