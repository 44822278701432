import { UnknownAction } from 'redux';
import { UserSetting } from 'sos-models';

export const GET = '[UserSetting] Get';
type GET = typeof GET;

export const GET_COMPLETE = '[UserSetting] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const GET_FAILED = '[UserSetting] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export const UPDATE = '[UserSetting] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[UserSetting] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[UserSetting] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export interface GetAction {
	episodicId: number;
	type: GET;
}

export interface GetCompleteAction extends UnknownAction {
	type: GET_COMPLETE;
	userSettings: UserSetting[];
}

export interface GetFailedAction {
	error: Error;
	type: GET_FAILED;
}

export interface UpdateAction {
	episodicId: number;
	type: UPDATE;
	userSetting: Partial<UserSetting>;
}

export interface UpdateCompleteAction {
	type: UPDATE_COMPLETE;
	userSetting: UserSetting;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export function get(episodicId: number): GetAction {
	return {
		episodicId,
		type: GET,
	};
}

export function getComplete(userSettings: UserSetting[]): GetCompleteAction {
	return {
		userSettings,
		type: GET_COMPLETE,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	};
}

export function update(
	episodicId: number,
	userSetting: Partial<UserSetting>
): UpdateAction {
	return {
		episodicId,
		userSetting,
		type: UPDATE,
	};
}

export function updateComplete(userSetting: UserSetting): UpdateCompleteAction {
	return {
		userSetting,
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export type Action =
	| GetAction
	| GetCompleteAction
	| GetFailedAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction;
