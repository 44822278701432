import {
	PartialDeptSceneNotes,
	SceneNote,
	SceneNoteMultiDeptUpdateResponse,
} from 'sos-models';
import axios from '../../config/axios';

export async function list(
	prodId: number,
	sceneId: number
): Promise<Partial<SceneNote>[]> {
	const response = await axios.get<Partial<SceneNote>[]>(
		`/api/productions/${prodId}/scenes/${sceneId}/scene-notes`
	);
	return response.data;
}

export async function update(
	episodicId: number,
	prodId: number,
	sceneId: number,
	sceneNotes: PartialDeptSceneNotes
): Promise<SceneNoteMultiDeptUpdateResponse> {
	const response = await axios.put<SceneNoteMultiDeptUpdateResponse>(
		`/api/episodics/${episodicId}/productions/${prodId}/scenes/${sceneId}/scene-notes`,
		sceneNotes
	);
	return response.data;
}
