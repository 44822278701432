import * as actions from './gallery-view.actions';
import { createReducer } from '../utils';

export interface State {
	currentEditStatus: { pictureId: number; status: boolean };
}

const initial: State = {
	currentEditStatus: { pictureId: null, status: false },
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.SET_EDIT_STATUS]: setCurrentEditStatus,
});

function setCurrentEditStatus(
	state: State,
	{ pictureId, status }: actions.Action
): State {
	return {
		...state,
		currentEditStatus: {
			pictureId,
			status,
		},
	};
}
