import { call, put, takeLatest } from 'redux-saga/effects';
import {
	EpisodicItemsForCharacterListResponse,
	EpisodicItemsWithoutCharacterListResponse,
} from 'sos-models';
import * as actions from './episodic-item.actions';
import * as api from './episodic-item.api';
import * as logger from '../../logger';
import {
	episodicItemActions,
	cmWrapBoxEpisodicItemActions,
	wrapBoxActions,
} from '../actions';

export function* listForEpChar({
	episodicId,
	epCharId,
}: actions.ListForEpCharAction) {
	try {
		const result: EpisodicItemsForCharacterListResponse = yield call(
			api.listEpItemsForEpChar,
			episodicId,
			epCharId
		);
		if (result.listed.EpisodicItem) {
			yield put(episodicItemActions.listComplete(result.listed.EpisodicItem));
		}
		if (result.listed.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.listComplete(
					result.listed.CmWrapBoxEpisodicItem
				)
			);
		}
		if (result.listed.CmWrapBox) {
			yield put(wrapBoxActions.listComplete(result.listed));
		}
	} catch (err) {
		yield put(actions.listForEpCharFailed(err));
		logger.responseError(err, 'loading the episodic items', true);
	}
}

export function* listNoCloset({ episodicId }: actions.ListNoClosetAction) {
	try {
		const result: EpisodicItemsWithoutCharacterListResponse = yield call(
			api.listNoClosetEpItems,
			episodicId
		);
		if (result.listed.EpisodicItem) {
			yield put(episodicItemActions.listComplete(result.listed.EpisodicItem));
		}
		if (result.listed.CmWrapBoxEpisodicItem) {
			yield put(
				cmWrapBoxEpisodicItemActions.listComplete(
					result.listed.CmWrapBoxEpisodicItem
				)
			);
		}
		if (result.listed.CmWrapBox) {
			yield put(wrapBoxActions.listComplete(result.listed));
		}
	} catch (err) {
		yield put(actions.listNoClosetFailed(err));
		logger.responseError(err, 'loading the episodic items', true);
	}
}

function* saga() {
	yield takeLatest(actions.LIST_FOR_EP_CHAR, listForEpChar);
	yield takeLatest(actions.LIST_NO_CLOSET, listNoCloset);
}

export default saga;
