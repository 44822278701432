import { omit, without } from 'lodash';
import { normalize } from 'normalizr';
import { DEPARTMENTS, HaChange, SortOrder } from 'sos-models';
import * as actions from './ha-change.actions';
import * as changeLookActions from '../change-looks/change-look.actions';
import * as haChangeSceneActions from '../ha-change-scenes/ha-change-scene.actions';
import { haChangeSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createComplete,
	createReducer,
	failed,
	getComplete,
	listComplete,
	loading,
	uniqueArrayMerge,
	updateComplete,
} from '../utils';

export interface State extends BaseReducerState<HaChange> {
	createAndDeleteExistingStatus: ActionStatus;
	createStatus: ActionStatus;
	createHaChangeBySceneStatus: ActionStatus;
	deleteHaChangeForEpCharStatus: ActionStatus;
	getForEpCharStatus: ActionStatus;
	getHaChangesForChangeLookStatus: ActionStatus;
	getHaChangesForSceneStatus: ActionStatus;
	getStatus: ActionStatus;
	updateStatus: ActionStatus;
	haChangeIdsForEpCharId: { [epCharId: number]: number[] };
	listStatus: ActionStatus.Inactive;
	sortBy: string;
	sortOrder: SortOrder;
}

export const initial: State = {
	createAndDeleteExistingStatus: ActionStatus.Inactive,
	createStatus: ActionStatus.Inactive,
	createHaChangeBySceneStatus: ActionStatus.Inactive,
	deleteHaChangeForEpCharStatus: ActionStatus.Inactive,
	entities: {},
	getForEpCharStatus: ActionStatus.Inactive,
	getHaChangesForChangeLookStatus: ActionStatus.Inactive,
	getHaChangesForSceneStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
	haChangeIdsForEpCharId: {},
	ids: [],
	listStatus: ActionStatus.Inactive,
	sortBy: 'change_num',
	sortOrder: 'asc',
};

export const reducer = createReducer<
	State,
	actions.Action | haChangeSceneActions.Action | changeLookActions.Action
>(initial, {
	[actions.GET_COMPLETE]: getComplete<
		HaChange,
		State,
		actions.GetCompleteAction
	>('haChange', haChangeSchema),
	[actions.CREATE_HA_CHANGE_COMPLETE]: createComplete<
		HaChange,
		State,
		actions.CreateHaChangeCompleteAction
	>('change', haChangeSchema),
	[actions.DELETE_COMPLETE]: (
		state: State,
		action: actions.DeleteCompleteAction
	) => {
		if (action.hasOwnProperty('departmentId')) {
			return deleteChangeLookComplete(
				state,
				action as unknown as changeLookActions.DeleteCompleteAction
			);
		}
		return deleteComplete(state, action);
	},
	[actions.GET]: loading<State>('getStatus'),
	[actions.GET_FAILED]: failed<State>('getStatus'),
	[actions.LIST_COMPLETE]: listComplete<
		HaChange,
		State,
		actions.ListCompleteAction
	>('haChanges', haChangeSchema),

	[actions.GET_HA_CHANGES_FOR_EP_CHAR]: loading<State>('getForEpCharStatus'),
	[actions.GET_HA_CHANGES_FOR_CHANGE_LOOK]: loading<State>(
		'getHaChangesForChangeLookStatus'
	),
	[actions.GET_HA_CHANGES_FOR_CHANGE_LOOK_COMPLETE]: getForChangeLookComplete,
	[actions.GET_HA_CHANGES_FOR_CHANGE_LOOK_FAILED]: failed<State>(
		'getHaChangesForChangeLookStatus'
	),
	[actions.GET_HA_CHANGES_FOR_EP_CHAR_COMPLETE]: getForEpCharComplete,
	[actions.GET_HA_CHANGES_FOR_EP_CHAR_FAILED]:
		failed<State>('getForEpCharStatus'),
	[actions.DELETE_HA_CHANGE_FOR_EP_CHAR]: loading<State>(
		'deleteHaChangeForEpCharStatus'
	),
	[actions.DELETE_HA_CHANGE_FOR_EP_CHAR_COMPLETE]:
		deleteHaChangeForEpCharComplete,
	[actions.DELETE_HA_CHANGE_FOR_EP_CHAR_FAILED]: failed<State>(
		'deleteHaChangeForEpCharStatus'
	),
	[actions.GET_HA_CHANGES_FOR_SCENE]: loading<State>(
		'getHaChangesForSceneStatus'
	),
	[actions.GET_HA_CHANGES_FOR_SCENE_COMPLETE]: getHaChangesForSceneComplete,
	[actions.GET_HA_CHANGES_FOR_SCENE_FAILED]: failed<State>(
		'getHaChangesForSceneStatus'
	),
	[actions.CREATE_HA_CHANGE]: loading<State>('createStatus'),
	[actions.CREATE_HA_CHANGE_FAILED]: failed<State>('createStatus'),
	[actions.CREATE_HA_CHANGE_BY_SCENE]: loading<State>(
		'createHaChangeBySceneStatus'
	),
	[actions.CREATE_HA_CHANGE_BY_SCENE_COMPLETE]: createHaChangeBySceneComplete,
	[actions.CREATE_HA_CHANGE_BY_SCENE_FAILED]: failed<State>(
		'createHaChangeBySceneStatus'
	),
	[actions.SET_SORTING]: setSorting,
	[haChangeSceneActions.CREATE_AND_DELETE_EXISTING]: loading<State>(
		'createAndDeleteExistingStatus'
	),
	[haChangeSceneActions.CREATE_AND_DELETE_EXISTING_COMPLETE]:
		createAndDeleteExistingComplete,
	[haChangeSceneActions.CREATE_AND_DELETE_EXISTING_FAILED]: failed<State>(
		'createAndDeleteExistingStatus'
	),
	[changeLookActions.CREATE]: loading<State>('createStatus'),
	[actions.UPDATE]: loading<State>('updateStatus'),
	[actions.UPDATE_COMPLETE]: updateComplete<
		HaChange,
		State,
		actions.UpdateCompleteAction
	>('haChanges', haChangeSchema),
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
});

function deleteComplete(
	state: State,
	action: actions.DeleteCompleteAction
): State {
	const haChangeIds = action.haChanges.map((hac) => hac.id);
	const ids = without(state.ids, ...haChangeIds);
	const entities = omit(state.entities, haChangeIds);
	const haChangeIdsForEpCharId = {};
	Object.keys(state.haChangeIdsForEpCharId).forEach((epItemId) => {
		haChangeIdsForEpCharId[epItemId] = without(
			state.haChangeIdsForEpCharId[epItemId],
			...haChangeIds
		);
	});
	return {
		...state,
		entities,
		ids,
		haChangeIdsForEpCharId,
	};
}

function getForChangeLookComplete(
	state: State,
	action: actions.GetHaChangesForChangeLookCompleteAction
): State {
	const normalized = normalize(action.haChanges, [haChangeSchema]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.haChanges,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		getForEpCharStatus: ActionStatus.Complete,
	};
}

function getForEpCharComplete(
	state: State,
	action: actions.GetHaChangesForEpCharCompleteAction
): State {
	const normalized = normalize(action.haChanges, [haChangeSchema]);
	const haChangeIdsForEpCharId = { ...state.haChangeIdsForEpCharId };
	haChangeIdsForEpCharId[action.epCharId] = normalized.result;

	return {
		...state,
		entities: {
			//...state.entities,
			...normalized.entities.haChanges,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		haChangeIdsForEpCharId,
		getForEpCharStatus: ActionStatus.Complete,
	};
}

function deleteHaChangeForEpCharComplete(
	state: State,
	{ response: { destroyed } }: actions.DeleteHaChangeForEpCharCompleteAction
): State {
	const entities: { [id: number]: HaChange } = omit(
		state.entities,
		destroyed.HaChange.map((hc) => hc.id)
	);
	const deletedIds = Object.keys(destroyed.HaChange).map(
		(id) => destroyed.HaChange[id].id
	);

	const haChangeIdsForEpCharId = {};
	Object.keys(state.haChangeIdsForEpCharId).forEach((epItemId) => {
		haChangeIdsForEpCharId[epItemId] = state.haChangeIdsForEpCharId[
			epItemId
		].filter((id) => !deletedIds.includes(id));
	});
	return {
		...state,
		entities,
		ids: state.ids.filter((id) => !deletedIds.includes(id)),
		haChangeIdsForEpCharId,
		deleteHaChangeForEpCharStatus: ActionStatus.Complete,
	};
}

function getHaChangesForSceneComplete(
	state: State,
	action: actions.GetHaChangesForSceneCompleteAction
): State {
	const normalized = normalize(action.haChanges, [haChangeSchema]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.haChanges,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		getHaChangesForSceneStatus: ActionStatus.Complete,
	};
}

function createHaChangeBySceneComplete(
	state: State,
	{ created, updated }: actions.CreateHaChangeBySceneCompleteAction
): State {
	const normalizedCreated = normalize(created.HaChange, [haChangeSchema]);
	const normalizedUpdated = normalize(updated.HaChange, [haChangeSchema]);
	const createdUpdatedIds = uniqueArrayMerge(
		normalizedCreated.result,
		normalizedUpdated.result
	);

	return {
		...state,
		entities: {
			...state.entities,
			...normalizedCreated.entities.haChanges,
			...normalizedUpdated.entities.haChanges,
		},
		ids: uniqueArrayMerge(state.ids, createdUpdatedIds),
		createHaChangeBySceneStatus: ActionStatus.Complete,
	};
}

function createAndDeleteExistingComplete(
	state: State,
	{ updated }: haChangeSceneActions.CreateAndDeleteExistingCompleteAction
): State {
	const normalized = normalize(updated.HaChange, [haChangeSchema]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.haChanges,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		createAndDeleteExistingStatus: ActionStatus.Complete,
	};
}

function deleteChangeLookComplete(
	state: State,
	{ changeLooks, departmentId }: changeLookActions.DeleteCompleteAction
): State {
	if (departmentId !== DEPARTMENTS.HA) {
		return state;
	}
	const lookIds = changeLooks.map((cl) => cl.id);
	const ids = without(state.ids, ...lookIds);
	const entities = omit(state.entities, lookIds);
	return {
		...state,
		entities: {
			...state.entities,
			...entities,
		},
		ids,
	};
}

function setSorting(
	state: State,
	{ sorting }: actions.SetSortingAction
): State {
	const sortInfo = sorting[0];
	return {
		...state,
		sortBy: sortInfo.columnName,
		sortOrder: sortInfo.direction,
	};
}
