import { UnknownAction } from 'redux';
import { ActivityCard } from 'sos-models';

export const GET = '[Activity Feeds] Get';
type GET = typeof GET;

export const GET_COMPLETE = '[Activity Feeds] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const GET_FAILED = '[Activity Feeds] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export interface GetAction extends UnknownAction {
	episodicId?: number;
	offset: number;
	count: number;
	type: GET;
}

export interface GetFailedAction extends UnknownAction {
	error: Error;
	type: GET_FAILED;
}

export interface GetCompleteAction extends UnknownAction {
	activityCards: ActivityCard[];
	offset: number;
	type: GET_COMPLETE;
}

export function get(
	offset: number,
	count: number,
	episodicId?: number
): GetAction {
	return {
		type: GET,
		episodicId,
		offset,
		count,
	}
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	}
}

export function getComplete(
	activityCards: ActivityCard[],
	offset: number
): GetCompleteAction {
	return {
		activityCards,
		offset,
		type: GET_COMPLETE,
	}
}

export type Action = GetAction | GetFailedAction | GetCompleteAction;
