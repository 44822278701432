import { toast } from 'react-toastify';
import { call, takeEvery, put } from 'redux-saga/effects';
import * as actions from './cm-wrap-box-episodic-item.actions';
import * as api from './cm-wrap-box-episodic-item.api';
import * as logger from '../../logger';

export function* update({
	episodicId,
	cmWrapBoxEpisodicItems,
}: actions.UpdateAction) {
	try {
		const results = yield call(
			api.update,
			episodicId,
			cmWrapBoxEpisodicItems
		);
		yield put(actions.updateComplete(results.updated.CmWrapBoxEpisodicItem));
	} catch (err) {
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeEvery(actions.UPDATE, update);
}

export default saga;
