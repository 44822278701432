import { ScriptLocation } from 'sos-models';

export const LIST = '[ScriptLocation] List';
type LIST = typeof LIST;

export const LIST_FAILED = '[ScriptLocation] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const LIST_COMPLETE = '[ScriptLocation] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const DESTROY = '[ScriptLocation] Destroy';
type DESTROY = typeof DESTROY;

export const DESTROY_FAILED = '[ScriptLocation] Destroy Failed';
type DESTROY_FAILED = typeof DESTROY_FAILED;

export const DESTROY_COMPLETE = '[ScriptLocation] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const CREATE = '[ScriptLocation] Create';
type CREATE = typeof CREATE;

export const CREATE_COMPLETE = '[ScriptLocation] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const CREATE_FAILED = '[ScriptLocation] Create Failed';
type CREATE_FAILED = typeof CREATE_FAILED;

export const UPDATE = '[ScriptLocation] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[ScriptLocation] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[ScriptLocation] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const SET_CURRENT = '[ScriptLocation] Set Current';
type SET_CURRENT = typeof SET_CURRENT;

export interface CreateAction {
	episodicId: number;
	scriptLocations: Partial<ScriptLocation>[];
	type: CREATE;
}

export interface CreateCompleteAction {
	scriptLocations: ScriptLocation[];
	type: CREATE_COMPLETE;
}

export interface CreateFailedAction {
	error: Error;
	type: CREATE_FAILED;
}

export interface UpdateAction {
	episodicId: number;
	scriptLocations: Partial<ScriptLocation>[];
	type: UPDATE;
}

export interface UpdateCompleteAction {
	scriptLocations: ScriptLocation[];
	type: UPDATE_COMPLETE;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export interface ListAction {
	episodicId: number;
	type: LIST;
}

export interface ListFailedAction {
	error: Error;
	type: LIST_FAILED;
}

export interface ListCompleteAction {
	scriptLocations: ScriptLocation[];
	type: LIST_COMPLETE;
}

export interface SetCurrentAction {
	scriptLocationId: number;
	type: SET_CURRENT;
}

export interface DestroyAction {
	scriptLocationId: number;
	episodicId: number;
	type: DESTROY;
}

export interface DestroyCompleteAction {
	scriptLocations: ScriptLocation[];
	type: DESTROY_COMPLETE;
}

export interface DestroyFailedAction {
	error: Error;
	type: DESTROY_FAILED;
}

export function create(
	episodicId: number,
	scriptLocations: Partial<ScriptLocation>[]
): CreateAction {
	return {
		episodicId,
		scriptLocations,
		type: CREATE,
	};
}

export function createComplete(
	scriptLocations: ScriptLocation[]
): CreateCompleteAction {
	return {
		scriptLocations,
		type: CREATE_COMPLETE,
	};
}

export function createFailed(error: Error): CreateFailedAction {
	return {
		error,
		type: CREATE_FAILED,
	};
}

export function update(
	episodicId: number,
	scriptLocations: Partial<ScriptLocation>[]
): UpdateAction {
	return {
		episodicId,
		scriptLocations,
		type: UPDATE,
	};
}

export function updateComplete(
	scriptLocations: ScriptLocation[]
): UpdateCompleteAction {
	return {
		scriptLocations,
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function list(episodicId: number): ListAction {
	return {
		episodicId,
		type: LIST,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export function listComplete(
	scriptLocations: ScriptLocation[]
): ListCompleteAction {
	return {
		scriptLocations,
		type: LIST_COMPLETE,
	};
}

export function setCurrent(scriptLocationId: number): SetCurrentAction {
	return {
		scriptLocationId,
		type: SET_CURRENT,
	};
}

export function destroy(
	episodicId: number,
	scriptLocationId: number
): DestroyAction {
	return {
		scriptLocationId,
		episodicId,
		type: DESTROY,
	};
}

export function destroyComplete(
	scriptLocations: ScriptLocation[]
): DestroyCompleteAction {
	return {
		scriptLocations,
		type: DESTROY_COMPLETE,
	};
}

export function destroyFailed(error: Error): DestroyFailedAction {
	return {
		error,
		type: DESTROY_FAILED,
	};
}

export type Action =
	| ListAction
	| ListFailedAction
	| ListCompleteAction
	| CreateAction
	| CreateCompleteAction
	| CreateFailedAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction
	| DestroyAction
	| DestroyCompleteAction
	| DestroyFailedAction
	| SetCurrentAction;
