import { SetPiecePictureDeleteResponse } from 'sos-models';
import axios from '../../config/axios';

export async function destroy(
	episodicId: number,
	setPiecePictureId: number
): Promise<SetPiecePictureDeleteResponse> {
	const { data } = await axios.delete(
		`/api/episodics/${episodicId}/set-piece-pictures/${setPiecePictureId}`
	);
	return data;
}
