import { EpisodicProp } from 'sos-models';

export const LIST = '[Character Props] List';
type LIST = typeof LIST;

export const LIST_FAILED = '[Character Props] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const LIST_COMPLETE = '[Character Props] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export interface ListAction {
	episodicId: number;
	episodicCharId: number;
	type: LIST;
}

export interface ListFailedAction {
	type: LIST_FAILED;
	error: Error;
}

export interface ListCompleteAction {
	type: LIST_COMPLETE;
	props: EpisodicProp[];
}

export function list(episodicCharId: number, episodicId: number): ListAction {
	return {
		episodicId,
		episodicCharId,
		type: LIST,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export function listComplete(props: EpisodicProp[]): ListCompleteAction {
	return {
		props,
		type: LIST_COMPLETE,
	};
}

export type Action = ListAction | ListFailedAction | ListCompleteAction;
