import { CmBrand } from 'sos-models';

export const LIST = '[Cm Brands] List';
type LIST = typeof LIST;

export const LIST_COMPLETE = '[Cm Brands] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FAILED = '[Cm Brands] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export interface ListAction {
	episodicId: number;
	type: LIST;
}

export interface ListCompleteAction {
	cmBrands: CmBrand[];
	type: LIST_COMPLETE;
}

export interface ListFailedAction {
	error: Error;
	type: LIST_FAILED;
}

export function list(episodicId: number): ListAction {
	return {
		episodicId,
		type: LIST,
	};
}

export function listComplete(cmBrands: CmBrand[]): ListCompleteAction {
	return {
		cmBrands,
		type: LIST_COMPLETE,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export type Action = ListAction | ListCompleteAction | ListFailedAction;
