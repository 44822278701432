import { toast } from 'react-toastify';
import {
	call,
	put,
	putResolve,
	takeEvery,
	takeLatest,
} from 'redux-saga/effects';
import {
	EpisodicCharacterCreateResponse,
	EpisodicCharacterDestroyResponse,
	EpisodicCharacterGetResponse,
	EpisodicCharacterListResponse,
	EpisodicCharacterMergeConflictGetResponse,
	EpisodicCharacterMergeResponse,
	EpisodicCharacterUpdateCharNumsResponse,
	EpisodicCharacterUpdateResponse,
	pluralize,
} from 'sos-models';
import * as actions from './episodic-character.actions';
import * as api from './episodic-character.api';
import * as logger from '../../logger';
import * as changeSceneActions from '../change-scenes/change-scene.actions';
import * as changeActions from '../changes/change.actions';
import * as charSceneActions from '../char-scenes/char-scene.actions';
import * as characterActions from '../characters/character.actions';
import * as episodicActorActions from '../episodic-actors/episodic-actor.actions';
import * as haChangeSceneActions from '../ha-change-scenes/ha-change-scene.actions';
import * as haChangeActions from '../ha-changes/ha-change.actions';
import * as inventoryActions from '../inventory/inventory.actions';
import * as muChangeSceneActions from '../mu-change-scenes/mu-change-scene.actions';
import * as muChangeActions from '../mu-changes/mu-change.actions';
import * as propSceneCharSceneActions from '../prop-scene-char-scenes/prop-scene-char-scene.actions';
import * as propSceneActions from '../prop-scenes/prop-scene.actions';

export function* merge({
	episodicId,
	firstEpCharId,
	secondEpCharId,
	resolution,
}: actions.MergeAction) {
	try {
		const results: EpisodicCharacterMergeResponse = yield call(
			api.merge,
			episodicId,
			firstEpCharId,
			secondEpCharId,
			resolution
		);
		if (results) {
			yield putResolve(actions.mergeComplete(resolution));
		}
	} catch (err) {
		yield putResolve(actions.mergeFailed(err));
		logger.responseError(err, 'merging the characters', true);
	}
}

export function* exportToCSV({
	episodicId,
	prodIds,
	isPrimary
}: actions.ExportToCSVAction) {
	try {
		const results = yield call(
			api.exportToCSV,
			episodicId,
			prodIds,
			isPrimary
		);

		if (isPrimary === true) {
			yield put(actions.exportToCSVComplete(results));
		}
		else {
			yield put(actions.exportToCSVSecondaryComplete(results));
		}
	} catch (err) {
		yield put(actions.exportToCSVFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

export function* getMergeConflicts({
	episodicId,
	firstEpCharId,
	secondEpCharId,
}: actions.GetMergeConflictsAction) {
	try {
		const results: EpisodicCharacterMergeConflictGetResponse = yield call(
			api.getMergeConflicts,
			episodicId,
			firstEpCharId,
			secondEpCharId
		);
		if (results) {
			yield putResolve(actions.getMergeConflictsComplete(results));
		}
	} catch (err) {
		yield putResolve(actions.getMergeConflictsFailed(err));
		logger.responseError(
			err,
			'loading the merge conflicts for characters',
			true
		);
	}
}

export function* list({ episodicId }: actions.ListAction) {
	try {
		const result: EpisodicCharacterListResponse = yield call(
			api.list,
			episodicId
		);
		yield put(actions.listComplete(result.listed.EpisodicCharacter));
		if (result.listed.Character) {
			yield put(characterActions.listComplete(result.listed.Character));
		}
		if (result.listed.EpisodicActor) {
			yield put(episodicActorActions.listComplete(result.listed.EpisodicActor));
		}
	} catch (err) {
		yield put(actions.listFailed(err));
		logger.responseError(err, 'loading the characters', true);
	}
}

export function* listForEpActor({
	episodicId,
	episodicActorId,
}: actions.ListForEpActorAction) {
	try {
		const episodicCharacters = yield call(
			api.listForEpActor,
			episodicId,
			episodicActorId
		);
		yield put(actions.listForEpActorComplete(episodicCharacters));
	} catch (err) {
		yield put(actions.listForEpActorFailed(err));
		logger.responseError(
			err,
			`loading the characters for episodic actor ${episodicActorId}`,
			true
		);
	}
}

export function* exportToNewSeason({
	episodicId,
	newEpisodicId,
	epCharIds,
	checkIfExported,
}: actions.ExportToNewSeasonAction) {
	if (!checkIfExported) {
		try {
			yield call(
				api.exportToNewSeason,
				episodicId,
				newEpisodicId,
				epCharIds,
				checkIfExported
			);
			yield put(actions.exportToNewSeasonComplete());
			toast.success('Export completed');
		} catch (err) {
			yield put(actions.exportToNewSeasonFailed(err));
			if (err.response) {
				toast.error(err.response.data.message);
			} else {
				logger.error(err);
			}
		}
	}
}

export function* get({
	episodicCharacterId,
	episodicId,
	productionIds,
}: actions.GetAction) {
	try {
		const result: EpisodicCharacterGetResponse = yield call(
			api.get,
			episodicCharacterId,
			episodicId,
			productionIds
		);
		yield put(
			actions.getComplete(
				result.listed.EpisodicCharacter,
				result.previous,
				result.next
			)
		);
		if (result.listed.Character) {
			yield put(characterActions.listComplete(result.listed.Character));
		}
		if (result.listed.EpisodicActor) {
			yield put(episodicActorActions.listComplete(result.listed.EpisodicActor));
		}
	} catch (err) {
		yield put(actions.getFailed(err));
		logger.responseError(err, 'loading the character', true);
	}
}

export function* destroy({
	episodicId,
	episodicCharacterId,
}: actions.DestroyAction) {
	try {
		const { destroyed, updated }: EpisodicCharacterDestroyResponse = yield call(
			api.destroy,
			episodicId,
			episodicCharacterId
		);
		if (destroyed.EpisodicCharacter) {
			yield put(actions.destroyComplete(destroyed.EpisodicCharacter));
		}
		if (destroyed.ChangeScene) {
			yield put(changeSceneActions.deleteComplete(destroyed.ChangeScene));
		}
		if (destroyed.Character) {
			yield put(characterActions.deleteComplete(destroyed.Character));
		}
		if (destroyed.CharScene) {
			yield put(charSceneActions.deleteComplete(destroyed.CharScene));
		}
		if (destroyed.HaChangeScene) {
			yield put(haChangeSceneActions.deleteComplete(destroyed.HaChangeScene));
		}
		if (destroyed.MuChangeScene) {
			yield put(muChangeSceneActions.deleteComplete(destroyed.MuChangeScene));
		}
		if (destroyed.PropScene) {
			yield put(propSceneActions.destroyComplete(destroyed.PropScene));
		}
		if (destroyed.PropSceneCharScene) {
			yield put(
				propSceneCharSceneActions.destroyComplete(destroyed.PropSceneCharScene)
			);
		}
		if (updated.EpisodicItem || updated.EpisodicProp) {
			const updatedInv = [
				...(updated.EpisodicItem ? updated.EpisodicItem : []),
				...(updated.EpisodicProp ? updated.EpisodicProp : []),
			];
			yield put(inventoryActions.updateComplete(updatedInv));
		}
	} catch (err) {
		yield put(actions.destroyFailed(err));
		logger.responseError(err, 'deleting the character', true);
	}
}

export function* update({
	episodicId,
	episodicCharacter,
	episodicActor,
	addedProdIds,
	removedProdIds,
	addedSceneIds,
	removedSceneIds,
}: actions.UpdateAction) {
	try {
		const results: EpisodicCharacterUpdateResponse = yield call(
			api.update,
			episodicId,
			episodicCharacter,
			episodicActor,
			addedProdIds,
			removedProdIds,
			addedSceneIds,
			removedSceneIds
		);
		yield put(actions.updateComplete(results.updated.EpisodicCharacter));
		yield put(characterActions.createComplete(results.created.Character));
		if (results.created.CharScene) {
			yield put(charSceneActions.createComplete(results.created.CharScene));
		}
		if (results.created.EpisodicActor) {
			yield put(
				episodicActorActions.createComplete(results.created.EpisodicActor)
			);
		}
		if (results.destroyed.ChangeScene) {
			yield put(
				changeSceneActions.deleteComplete(results.destroyed.ChangeScene)
			);
		}
		if (results.destroyed.Character) {
			yield put(characterActions.deleteComplete(results.destroyed.Character));
		}
		if (results.destroyed.CharScene) {
			yield put(charSceneActions.deleteComplete(results.destroyed.CharScene));
		}
		if (results.destroyed.HaChangeScene) {
			yield put(
				haChangeSceneActions.deleteComplete(results.destroyed.HaChangeScene)
			);
		}
		if (results.destroyed.MuChangeScene) {
			yield put(
				muChangeSceneActions.deleteComplete(results.destroyed.MuChangeScene)
			);
		}
		if (results.destroyed.PropScene) {
			yield put(propSceneActions.destroyComplete(results.destroyed.PropScene));
		}
		if (results.destroyed.PropSceneCharScene) {
			yield put(
				propSceneCharSceneActions.destroyComplete(
					results.destroyed.PropSceneCharScene
				)
			);
		}
	} catch (err) {
		yield put(actions.updateFailed(err));
		logger.responseError(err, 'updating the character', true);
	}
}

export function* updateMultiple({
	episodicId,
	payload,
}: actions.UpdateMultipleAction) {
	try {
		const results: EpisodicCharacterUpdateResponse = yield call(
			api.updateMultiple,
			episodicId,
			payload
		);
		yield put(actions.updateComplete(results.updated.EpisodicCharacter));

		if (results.created.EpisodicActor) {
			yield put(
				episodicActorActions.createComplete(results.created.EpisodicActor)
			);
		}
		if (results.created.CharScene) {
			yield put(charSceneActions.createComplete(results.created.CharScene));
		}
		if (results.created.Character) {
			yield put(characterActions.createComplete(results.created.Character));
		}
		if (results.updated.Character) {
			yield put(characterActions.updateComplete(results.updated.Character));
		}
		if (results.updated.Change) {
			yield put(changeActions.updateComplete(results.updated.Change));
		}
		if (results.updated.HaChange) {
			yield put(haChangeActions.updateComplete(results.updated.HaChange));
		}
		if (results.updated.MuChange) {
			yield put(muChangeActions.updateComplete(results.updated.MuChange));
		}
		if (results.destroyed.Change) {
			yield put(changeActions.deleteComplete(results.destroyed.Change));
		}
		if (results.destroyed.ChangeScene) {
			yield put(
				changeSceneActions.deleteComplete(results.destroyed.ChangeScene)
			);
		}
		if (results.destroyed.Character) {
			yield put(characterActions.deleteComplete(results.destroyed.Character));
		}
		if (results.destroyed.HaChange) {
			yield put(haChangeActions.deleteComplete(results.destroyed.HaChange));
		}
		if (results.destroyed.HaChangeScene) {
			yield put(
				haChangeSceneActions.deleteComplete(results.destroyed.HaChangeScene)
			);
		}
		if (results.destroyed.MuChange) {
			yield put(muChangeActions.deleteComplete(results.destroyed.MuChange));
		}
		if (results.destroyed.MuChangeScene) {
			yield put(
				muChangeSceneActions.deleteComplete(results.destroyed.MuChangeScene)
			);
		}
		if (results.destroyed.PropScene) {
			yield put(propSceneActions.destroyComplete(results.destroyed.PropScene));
		}
	} catch (err) {
		yield put(actions.updateFailed(err));
		logger.responseError(
			err,
			`updating the ${pluralize(
				'character',
				payload.episodicCharacters.length
			)}`,
			true
		);
	}
}

export function* updateCharNums({
	episodicId,
	episodicCharacter,
	characters,
}: actions.UpdateCharNumsAction) {
	try {
		const results: EpisodicCharacterUpdateCharNumsResponse = yield call(
			api.updateCharNums,
			episodicId,
			episodicCharacter,
			characters
		);
		yield put(actions.updateComplete(results.updated.EpisodicCharacter));
		if (results.updated.Character) {
			yield put(characterActions.updateComplete(results.updated.Character));
		}
	} catch (err) {
		yield put(actions.updateFailed(err));
		logger.responseError(err, `updating the character`, true);
	}
}

export function* create({
	episodicId,
	episodicCharacter,
	epActor,
	prodIds,
	sceneIds,
}: actions.CreateAction) {
	try {
		const { created }: EpisodicCharacterCreateResponse = yield call(
			api.create,
			episodicId,
			episodicCharacter,
			epActor,
			prodIds,
			sceneIds
		);
		yield put(actions.createComplete(created.EpisodicCharacter));
		if (created.EpisodicActor) {
			yield put(episodicActorActions.createComplete(created.EpisodicActor));
		}
		if (created.Character) {
			yield put(characterActions.createComplete(created.Character));
		}
	} catch (err) {
		yield put(actions.createFailed(err));
		logger.responseError(err, 'loading the character', true);
	}
}

function* saga() {
	yield takeLatest(actions.LIST, list);
	yield takeLatest(actions.LIST_FOR_EP_ACTOR, listForEpActor);
	yield takeEvery(actions.EXPORT_TO_CSV, exportToCSV);
	yield takeLatest(actions.GET, get);
	yield takeLatest(actions.DESTROY, destroy);
	yield takeEvery(actions.EXPORT_TO_NEW_SEASON, exportToNewSeason);
	yield takeEvery(actions.UPDATE, update);
	yield takeEvery(actions.UPDATE_MULTIPLE, updateMultiple);
	yield takeEvery(actions.UPDATE_CHAR_NUMS, updateCharNums);
	yield takeEvery(actions.CREATE, create);
	yield takeLatest(actions.GET_MERGE_CONFLICTS, getMergeConflicts);
	yield takeEvery(actions.MERGE, merge);
}

export default saga;
