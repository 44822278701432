import { call, put, takeLatest } from 'redux-saga/effects';
import {
	ChangeLookPictureForEpCharListResponse,
	ChangeLookPictureListResponse,
	DEPARTMENTS,
	DeptSpecificChangeLookPictureListResponse,
} from 'sos-models';
import * as actions from './change-look-picture.actions';
import * as api from './change-look-picture.api';
import * as logger from '../../logger';
import {
	changeLookPictureActions,
	cmChangePictureActions,
	haLookPictureActions,
	muLookPictureActions,
	pictureActions,
} from '../actions';

export function* list({
	episodicId,
	departmentId,
	changeLookId,
}: actions.ListAction) {
	try {
		const result: ChangeLookPictureListResponse = yield call(
			api.list,
			episodicId,
			departmentId,
			changeLookId
		);
		if (departmentId === DEPARTMENTS.CM) {
			const { listed } = result as DeptSpecificChangeLookPictureListResponse<
				DEPARTMENTS.CM
			>;
			if (listed.CmChangePicture) {
				yield put(cmChangePictureActions.listComplete(listed.CmChangePicture));
			}
		} else if (departmentId === DEPARTMENTS.HA) {
			const { listed } = result as DeptSpecificChangeLookPictureListResponse<
				DEPARTMENTS.HA
			>;
			if (listed.HaLookPicture) {
				yield put(haLookPictureActions.listComplete(listed.HaLookPicture));
			}
		} else if (departmentId === DEPARTMENTS.MU) {
			const { listed } = result as DeptSpecificChangeLookPictureListResponse<
				DEPARTMENTS.MU
			>;
			if (listed.MuLookPicture) {
				yield put(muLookPictureActions.listComplete(listed.MuLookPicture));
			}
		}
		yield put(pictureActions.listComplete(result.listed.Picture));
		yield put(actions.listComplete());
	} catch (err) {
		yield put(actions.listFailed(departmentId, err));
		logger.responseError(err, 'loading change look pictures', true);
	}
}

export function* listForEpChar({
	episodicId,
	episodicCharacterId,
}: actions.ListForEpCharAction) {
	try {
		const { listed }: ChangeLookPictureForEpCharListResponse = yield call(
			api.listForEpChar,
			episodicId,
			episodicCharacterId
		);
		if (listed.CmChangePicture) {
			yield put(cmChangePictureActions.listComplete(listed.CmChangePicture));
		}

		if (listed.HaLookPicture) {
			yield put(haLookPictureActions.listComplete(listed.HaLookPicture));
		}

		if (listed.MuLookPicture) {
			yield put(muLookPictureActions.listComplete(listed.MuLookPicture));
		}

		yield put(changeLookPictureActions.listForEpCharComplete(listed));
	} catch (err) {
		yield put(actions.listForEpCharFailed(err));
		logger.responseError(err, 'loading the change/look pictures', true);
	}
}

function* saga() {
	yield takeLatest(actions.LIST, list);
	yield takeLatest(actions.LIST_FOR_EP_CHAR, listForEpChar);
}

export default saga;
