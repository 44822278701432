import { all } from 'redux-saga/effects';
import activityFeedSaga from './activity-feed/activity-feed.saga';
import activityMonitorSaga from './activity-monitor/activity-monitor.saga';
import assetHubSaga from './asset-hub/asset-hub.saga';
import changeEpisodicItemSaga from './change-episodic-items/change-episodic-item.saga';
import changeLookPictureSaga from './change-look-pictures/change-look-picture.saga';
import changeLookSaga from './change-looks/change-look.saga';
import changeSceneSaga from './change-scenes/change-scene.saga';
import changeSaga from './changes/change.saga';
import charSceneSaga from './char-scenes/char-scene.saga';
import characterViewSaga from './character-view/character-view.saga';
import characterSaga from './characters/character.saga';
import cmBrandSaga from './cm-brands/cm-brand.saga';
import cmCategorySaga from './cm-categories/cm-category.saga';
import cmChangePictureSaga from './cm-change-pictures/cm-change-picture.saga';
import cmEpisodicItemPictureSaga from './cm-episodic-item-pictures/cm-episodic-item-picture.saga';
import cmWrapBoxEpisodicItemSaga from './cm-wrap-box-episodic-items/cm-wrap-box-episodic-item.saga';
import cmStatusSaga from './cm-statuses/cm-status.saga';
import cmStorageLocationSaga from './cm-storage-locations/cm-storage-location.saga';
import departmentSaga from './departments/department.saga';
import deptChangeSceneSaga from './dept-change-scenes/dept-change-scene.saga';
import episodicActorSaga from './episodic-actors/episodic-actor.saga';
import episodicCharacterNoteSaga from './episodic-character-notes/episodic-character-note.saga';
import episodicCharacterPictureSaga from './episodic-character-pictures/episodic-character-picture.saga';
import episodicCharacterSaga from './episodic-characters/episodic-character.saga';
import episodicItemSaga from './episodic-items/episodic-item.saga';
import episodicPropSaga from './episodic-props/episodic-prop.saga';
import episodicSaga from './episodics/episodic.saga';
import appFeatureSaga from './app-features/app-feature.saga';
import haChangeFieldNameSaga from './ha-change-field-names/ha-change-field-names.saga';
import haChangeSceneSaga from './ha-change-scenes/ha-change-scene.saga';
import haChangeSaga from './ha-changes/ha-change.saga';
import haLookPictureSaga from './ha-look-pictures/ha-look-picture.saga';
import inventoryFieldSettingSaga from './inventory-field-settings/inventory-field-setting.saga';
import inventoryPictureSaga from './inventory-pictures/inventory-picture.saga';
import inventorySatellitesSaga from './inventory-satellites/inventory-satellite.saga';
import inventorySaga from './inventory/inventory.saga';
import muChangeFieldNameSaga from './mu-change-field-names/mu-change-field-names.saga';
import muChangeSceneSaga from './mu-change-scenes/mu-change-scene.saga';
import muChangeSaga from './mu-changes/mu-change.saga';
import muLookPictureSaga from './mu-look-pictures/mu-look-picture.saga';
import permissionSaga from './permissions/permission.saga';
import pictureSceneSaga from './picture-scenes/picture-scene.saga';
import pictureSaga from './pictures/picture.saga';
import prEpisodicPropPictureSaga from './pr-episodic-prop-pictures/pr-episodic-prop-picture.saga';
import productionSaga from './productions/production.saga';
import propCategorySaga from './prop-categories/prop-category.saga';
import propSceneSaga from './prop-scenes/prop-scene.saga';
import propStatusColorSaga from './prop-status-colors/prop-status-color.saga';
import propStatusSaga from './prop-statuses/prop-status.saga';
import propStorageLocationSaga from './prop-storage-locations/prop-storage-location.saga';
import sceneNoteSaga from './scene-notes/scene-note.saga';
import sceneSaga from './scenes/scene.saga';
import scriptLocationSaga from './script-locations/script-location.saga';
import setPictureSaga from './set-pictures/set-picture.saga';
import setPiecePictureSaga from './set-piece-pictures/set-piece-picture.saga';
import setPieceStatusSaga from './set-piece-statuses/set-piece-status.saga';
import setPieceTypeSaga from './set-piece-types/set-piece-type.saga';
import setPieceSaga from './set-pieces/set-piece.saga';
import setProdSaga from './set-prods/set-prod.saga';
import setStorageLocationSaga from './set-storage-locations/set-storage-location.saga';
import setSaga from './sets/set.saga';
import siteSaga from './site/site.saga';
import universalSearchSaga from './universal-search/universal-search.saga';
import userSettingSaga from './user-settings/user-setting.saga';
import userSaga from './users/user.saga';
import vendorSaga from './vendors/vendor.saga';
import wrapBoxSaga from './wrap-boxes/wrap-box.saga';

function* rootSaga() {
	yield all([
		activityFeedSaga(),
		activityMonitorSaga(),
		assetHubSaga(),
		changeLookSaga(),
		changeLookPictureSaga(),
		changeEpisodicItemSaga(),
		changeSaga(),
		changeSceneSaga(),
		characterSaga(),
		characterViewSaga(),
		charSceneSaga(),
		cmBrandSaga(),
		cmCategorySaga(),
		cmChangePictureSaga(),
		cmEpisodicItemPictureSaga(),
		cmWrapBoxEpisodicItemSaga(),
		cmStatusSaga(),
		cmStorageLocationSaga(),
		departmentSaga(),
		deptChangeSceneSaga(),
		episodicActorSaga(),
		episodicCharacterNoteSaga(),
		episodicCharacterPictureSaga(),
		episodicCharacterSaga(),
		episodicItemSaga(),
		episodicPropSaga(),
		episodicSaga(),
		appFeatureSaga(),
		haChangeFieldNameSaga(),
		haChangeSaga(),
		haChangeSceneSaga(),
		haLookPictureSaga(),
		inventoryFieldSettingSaga(),
		inventoryPictureSaga(),
		inventorySaga(),
		inventorySatellitesSaga(),
		muChangeFieldNameSaga(),
		muChangeSaga(),
		muChangeSceneSaga(),
		muLookPictureSaga(),
		permissionSaga(),
		pictureSaga(),
		pictureSceneSaga(),
		prEpisodicPropPictureSaga(),
		productionSaga(),
		propCategorySaga(),
		propSceneSaga(),
		propStatusColorSaga(),
		propStatusSaga(),
		propStorageLocationSaga(),
		sceneNoteSaga(),
		sceneSaga(),
		scriptLocationSaga(),
		setPictureSaga(),
		setPiecePictureSaga(),
		setPieceSaga(),
		setPieceStatusSaga(),
		setPieceTypeSaga(),
		setProdSaga(),
		setSaga(),
		siteSaga(),
		setStorageLocationSaga(),
		universalSearchSaga(),
		userSaga(),
		userSettingSaga(),
		vendorSaga(),
		wrapBoxSaga(),
	]);
}

export default rootSaga;
