import * as changeLookPictureActions from './change-look-picture.actions';
import {
	ActionStatus,
	complete,
	createReducer,
	failed,
	loading,
} from '../utils';

export interface State {
	listStatus: ActionStatus;
	listForEpCharStatus: ActionStatus;
}

const initial: State = {
	listStatus: ActionStatus.Inactive,
	listForEpCharStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, changeLookPictureActions.Action>(
	initial,
	{
		[changeLookPictureActions.LIST]: loading<State>('listStatus'),
		[changeLookPictureActions.LIST_COMPLETE]: complete<State>('listStatus'),
		[changeLookPictureActions.LIST_FAILED]: failed<State>('listStatus'),
		[changeLookPictureActions.LIST_FOR_EP_CHAR]: loading<State>(
			'listForEpCharStatus'
		),
		[changeLookPictureActions.LIST_FOR_EP_CHAR_COMPLETE]: complete<State>(
			'listForEpCharStatus'
		),
	}
);
