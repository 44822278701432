import { CreateDeleteChangeScene, MuChange, MuChangeScene } from 'sos-models';

export const CREATE_COMPLETE = '[MuChangeScene] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const DELETE_COMPLETE = '[MuChangeScene] Delete Complete';
type DELETE_COMPLETE = typeof DELETE_COMPLETE;

export const GET_MU_CHANGE_SCENES_FOR_SCENE =
	'[MuChangeScene] Get Makeup Changes For Scene';
type GET_MU_CHANGE_SCENES_FOR_SCENE = typeof GET_MU_CHANGE_SCENES_FOR_SCENE;

export const GET_MU_CHANGE_SCENES_FOR_SCENE_COMPLETE =
	'[MuChangeScene] Get Makeup Changes For Scene Complete';
type GET_MU_CHANGE_SCENES_FOR_SCENE_COMPLETE = typeof GET_MU_CHANGE_SCENES_FOR_SCENE_COMPLETE;

export const GET_MU_CHANGE_SCENES_FOR_SCENE_FAILED =
	'[MuChangeScene] Get Makeup Changes For Scene Failed';
type GET_MU_CHANGE_SCENES_FOR_SCENE_FAILED = typeof GET_MU_CHANGE_SCENES_FOR_SCENE_FAILED;

export const LIST_COMPLETE = '[MuChangeScene] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FOR_EP_CHAR = '[MuChangeScene] List For Episodic Character';
type LIST_FOR_EP_CHAR = typeof LIST_FOR_EP_CHAR;

export const LIST_FOR_EP_CHAR_COMPLETE =
	'[MuChangeScene] List For Episodic Character Complete';
type LIST_FOR_EP_CHAR_COMPLETE = typeof LIST_FOR_EP_CHAR_COMPLETE;

export const LIST_FOR_EP_CHAR_FAILED =
	'[MuChangeScene] List For Episodic Character Failed';
type LIST_FOR_EP_CHAR_FAILED = typeof LIST_FOR_EP_CHAR_FAILED;

export const CREATE_AND_DELETE_EXISTING =
	'[MuChangeScene] Create MuChangeScene And Delete Existing';
type CREATE_AND_DELETE_EXISTING = typeof CREATE_AND_DELETE_EXISTING;

export const CREATE_AND_DELETE_EXISTING_COMPLETE =
	'[MuChangeScene] Create MuChangeScene And Delete Existing Complete';
type CREATE_AND_DELETE_EXISTING_COMPLETE = typeof CREATE_AND_DELETE_EXISTING_COMPLETE;

export const CREATE_AND_DELETE_EXISTING_FAILED =
	'[MuChangeScene] Create MuChangeScene And Delete Existing Failed';
type CREATE_AND_DELETE_EXISTING_FAILED = typeof CREATE_AND_DELETE_EXISTING_FAILED;

export const UPDATE = '[MuChangeScene] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[MuChangeScene] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[MuChangeScene] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_COMPLETE =
	'[MuChangeScene] Delete CharScene By EpChar Id And Scene Id Complete';
type DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_COMPLETE = typeof DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_COMPLETE;

export interface CreateCompleteAction {
	muChangeScenes: MuChangeScene[];
	type: CREATE_COMPLETE;
}

export interface DeleteCompleteAction {
	type: DELETE_COMPLETE;
	muChangeScenes: MuChangeScene[];
}

export interface GetMuChangeScenesForSceneAction {
	type: GET_MU_CHANGE_SCENES_FOR_SCENE;
	prodId: number;
	sceneId: number;
}

export interface GetMuChangeScenesForSceneCompleteAction {
	type: GET_MU_CHANGE_SCENES_FOR_SCENE_COMPLETE;
	muChangeScenes: MuChangeScene[];
	sceneId: number;
}

export interface GetMuChangeScenesForSceneFailedAction {
	type: GET_MU_CHANGE_SCENES_FOR_SCENE_FAILED;
	error: Error;
}

export interface ListCompleteAction {
	muChangeScenes: MuChangeScene[];
	type: LIST_COMPLETE;
}

export interface ListForEpCharAction {
	epCharId: number;
	episodicId: number;
	type: LIST_FOR_EP_CHAR;
}

export interface ListForEpCharCompleteAction {
	muChangeScenes: MuChangeScene[];
	type: LIST_FOR_EP_CHAR_COMPLETE;
}

export interface ListForEpCharFailedAction {
	error: Error;
	type: LIST_FOR_EP_CHAR_FAILED;
}

export interface CreateAndDeleteExistingAction {
	type: CREATE_AND_DELETE_EXISTING;
	episodicId: number;
	changeScenes: CreateDeleteChangeScene[];
}

export interface CreateAndDeleteExistingCompleteAction {
	type: CREATE_AND_DELETE_EXISTING_COMPLETE;
	created: { MuChangeScene: MuChangeScene[] };
	deleted: { MuChangeScene: MuChangeScene[] };
	updated: {
		MuChange: MuChange[];
		MuChangeScene: MuChangeScene[];
	};
}

export interface CreateAndDeleteExistingFailedAction {
	type: CREATE_AND_DELETE_EXISTING_FAILED;
	error: Error;
}

export interface UpdateAction {
	type: UPDATE;
	episodicId: number;
	changeScene: Partial<MuChangeScene>;
}

export interface UpdateCompleteAction {
	type: UPDATE_COMPLETE;
	updated: MuChangeScene;
}

export interface UpdateFailedAction {
	type: UPDATE_FAILED;
	error: Error;
}

export function createComplete(
	muChangeScenes: MuChangeScene[]
): CreateCompleteAction {
	return {
		muChangeScenes,
		type: CREATE_COMPLETE,
	};
}

export function deleteComplete(
	muChangeScenes: MuChangeScene[]
): DeleteCompleteAction {
	return {
		muChangeScenes,
		type: DELETE_COMPLETE,
	};
}

export function getMuChangeScenesForScene(
	prodId: number,
	sceneId: number
): GetMuChangeScenesForSceneAction {
	return {
		prodId,
		sceneId,
		type: GET_MU_CHANGE_SCENES_FOR_SCENE,
	};
}

export function getMuChangeScenesForSceneComplete(
	muChangeScenes: MuChangeScene[],
	sceneId: number
): GetMuChangeScenesForSceneCompleteAction {
	return {
		muChangeScenes,
		sceneId,
		type: GET_MU_CHANGE_SCENES_FOR_SCENE_COMPLETE,
	};
}

export function getMuChangeScenesForSceneFailed(
	error: Error
): GetMuChangeScenesForSceneFailedAction {
	return {
		error,
		type: GET_MU_CHANGE_SCENES_FOR_SCENE_FAILED,
	};
}

export function createAndDeleteExisting(
	episodicId: number,
	changeScenes: CreateDeleteChangeScene[]
): CreateAndDeleteExistingAction {
	return {
		episodicId,
		changeScenes,
		type: CREATE_AND_DELETE_EXISTING,
	};
}

export function createAndDeleteExistingComplete(
	created: { MuChangeScene: MuChangeScene[] },
	deleted: { MuChangeScene: MuChangeScene[] },
	updated: { MuChange: MuChange[]; MuChangeScene: MuChangeScene[] }
): CreateAndDeleteExistingCompleteAction {
	return {
		created,
		deleted,
		updated,
		type: CREATE_AND_DELETE_EXISTING_COMPLETE,
	};
}

export function createAndDeleteExistingFailed(
	error: Error
): CreateAndDeleteExistingFailedAction {
	return {
		error,
		type: CREATE_AND_DELETE_EXISTING_FAILED,
	};
}

export function update(
	episodicId: number,
	changeScene: Partial<MuChangeScene>
): UpdateAction {
	return {
		episodicId,
		changeScene,
		type: UPDATE,
	};
}

export function updateComplete(updated: MuChangeScene): UpdateCompleteAction {
	return {
		updated,
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function listComplete(
	muChangeScenes: MuChangeScene[]
): ListCompleteAction {
	return {
		muChangeScenes,
		type: LIST_COMPLETE,
	};
}

export function listForEpChar(
	episodicId: number,
	epCharId: number
): ListForEpCharAction {
	return {
		episodicId,
		epCharId,
		type: LIST_FOR_EP_CHAR,
	};
}

export function listForEpCharComplete(
	muChangeScenes: MuChangeScene[]
): ListForEpCharCompleteAction {
	return {
		muChangeScenes,
		type: LIST_FOR_EP_CHAR_COMPLETE,
	};
}

export function listForEpCharFailed(error: Error): ListForEpCharFailedAction {
	return {
		error,
		type: LIST_FOR_EP_CHAR_FAILED,
	};
}

export type Action =
	| CreateCompleteAction
	| DeleteCompleteAction
	| GetMuChangeScenesForSceneAction
	| GetMuChangeScenesForSceneCompleteAction
	| GetMuChangeScenesForSceneFailedAction
	| ListCompleteAction
	| ListForEpCharAction
	| ListForEpCharCompleteAction
	| ListForEpCharFailedAction
	| CreateAndDeleteExistingAction
	| CreateAndDeleteExistingCompleteAction
	| CreateAndDeleteExistingFailedAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction;
