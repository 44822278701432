import { TableColumn } from 'sos-models';
import {
	ACTOR_INDEX_COLUMNS,
	ACTOR_INDEX_COLUMNS_ARRAY,
} from './actor-index-columns.data';
import * as actions from './actor-table-column.actions';
import { createColumnReducer } from '../column.reducer';

export interface State {
	ids: string[];
	hidden: string[];
	widths: { [name: string]: TableColumn };
}

const initial: State = {
	ids: ACTOR_INDEX_COLUMNS_ARRAY.map((col: TableColumn) => col.canonical_name),
	hidden: [],
	widths: ACTOR_INDEX_COLUMNS,
};

export const reducer = createColumnReducer<State, actions.Action>(
	initial,
	actions
);
