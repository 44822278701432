import { UnknownAction } from 'redux';
import {
	CmPermission,
	Episodic,
	HaPermission,
	MuPermission,
	Production,
	PrPermission,
	SetPermission,
} from 'sos-models';

export const UPDATE = '[Episodic] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[Episodic] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[Episodic] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const UPDATE_TITLES = '[Episodic] Update Titles';
type UPDATE_TITLES = typeof UPDATE_TITLES;

export const UPDATE_TITLES_COMPLETE = '[Episodic] Update Titles Complete';
type UPDATE_TITLES_COMPLETE = typeof UPDATE_TITLES_COMPLETE;

export const UPDATE_TITLES_FAILED = '[Episodic] Update Titles Failed';
type UPDATE_TITLES_FAILED = typeof UPDATE_TITLES_FAILED;

export const LIST = '[Episodics] List';
type LIST = typeof LIST;

export const LIST_FAILED = '[Episodics] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const LIST_COMPLETE = '[Episodics] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_SEASONS = '[Episodics] List Seasons';
type LIST_SEASONS = typeof LIST_SEASONS;

export const GET = '[Episodics] Get';
type GET = typeof GET;

export const GET_COMPLETE = '[Episodics] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const GET_FAILED = '[Episodics] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export const GET_DEPT_PERMS = '[Episodics] Get Dept Perms';
type GET_DEPT_PERMS = typeof GET_DEPT_PERMS;

export const GET_DEPT_PERMS_COMPLETE = '[Episodics] Get Dept Perms Complete';
type GET_DEPT_PERMS_COMPLETE = typeof GET_DEPT_PERMS_COMPLETE;

export const GET_DEPT_PERMS_FAILED = '[Episodics] Get Dept Perms Failed';
type GET_DEPT_PERMS_FAILED = typeof GET_DEPT_PERMS_FAILED;

export const GET_PICTURE_COUNTS = '[Episodics] Get Picture Counts';
type GET_PICTURE_COUNTS = typeof GET_PICTURE_COUNTS;

export const GET_PICTURE_COUNTS_COMPLETE =
	'[Episodics] Get Picture Counts Complete';
type GET_PICTURE_COUNTS_COMPLETE = typeof GET_PICTURE_COUNTS_COMPLETE;

export const GET_PICTURE_COUNTS_FAILED =
	'[Episodics] Get Picture Counts Failed';
type GET_PICTURE_COUNTS_FAILED = typeof GET_PICTURE_COUNTS_FAILED;

export const DESTROY_COMPLETE = '[Episodics] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export interface UpdateAction {
	episodic: Partial<Episodic>;
	type: UPDATE;
}

export interface UpdateCompleteAction {
	episodics: Episodic[];
	type: UPDATE_COMPLETE;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export interface UpdateTitlesAction {
	episodic: Episodic;
	productions: Production[];
	type: UPDATE_TITLES;
}

export interface UpdateTitlesCompleteAction {
	episodics: Episodic[];
	productions: Production[];
	type: UPDATE_TITLES_COMPLETE;
}

export interface UpdateTitlesFailedAction {
	error: Error;
	type: UPDATE_TITLES_FAILED;
}

export interface ListAction {
	type: LIST;
}

export interface ListFailedAction {
	error: Error;
	type: LIST_FAILED;
}

export interface ListCompleteAction {
	episodics: Episodic[];
	type: LIST_COMPLETE;
}

export interface ListSeasonsAction {
	episodicId: number;
	type: LIST_SEASONS;
}

export interface GetAction extends UnknownAction {
	id?: number;
	type: GET;
}

export interface GetFailedAction {
	error: Error;
	type: GET_FAILED;
}

export interface GetCompleteAction extends UnknownAction {
	episodic: Episodic;
	type: GET_COMPLETE;
}

export interface GetDeptPermsAction {
	id: number;
	type: GET_DEPT_PERMS;
}

export interface GetDeptPermsFailedAction {
	error: Error;
	type: GET_DEPT_PERMS_FAILED;
}

export interface DestroyCompleteAction {
	type: DESTROY_COMPLETE;
}

export interface GetDeptPermsCompleteAction {
	permissions: {
		CmPermission: CmPermission[];
		HaPermission: HaPermission[];
		MuPermission: MuPermission[];
		SetPermission: SetPermission[];
		PrPermission: PrPermission[];
	};
	type: GET_DEPT_PERMS_COMPLETE;
}

export interface GetPictureCountsAction {
	id?: number;
	type: GET_PICTURE_COUNTS;
}

export interface GetPictureCountsFailedAction {
	error: Error;
	type: GET_PICTURE_COUNTS_FAILED;
}

export interface GetPictureCountsCompleteAction {
	pictureCountsByDept: { [dept: string]: number };
	type: GET_PICTURE_COUNTS_COMPLETE;
}

export function update(episodic: Partial<Episodic>): UpdateAction {
	return {
		episodic,
		type: UPDATE,
	};
}

export function updateComplete(episodics: Episodic[]): UpdateCompleteAction {
	return {
		episodics,
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function updateTitles(
	episodic: Episodic,
	productions: Production[]
): UpdateTitlesAction {
	return {
		episodic,
		productions,
		type: UPDATE_TITLES,
	};
}

export function updateTitlesComplete(
	episodics: Episodic[],
	productions: Production[]
): UpdateTitlesCompleteAction {
	return {
		episodics,
		productions,
		type: UPDATE_TITLES_COMPLETE,
	};
}

export function updateTitlesFailed(error: Error): UpdateTitlesFailedAction {
	return {
		error,
		type: UPDATE_TITLES_FAILED,
	};
}

export function list(): ListAction {
	return {
		type: LIST,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export function listComplete(episodics: Episodic[]): ListCompleteAction {
	return {
		episodics,
		type: LIST_COMPLETE,
	};
}

export function listSeasons(episodicId: number): ListSeasonsAction {
	return {
		type: LIST_SEASONS,
		episodicId,
	};
}

export function get(id?: number): GetAction {
	return {
		type: GET,
		id,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	};
}

export function getComplete(episodic: Episodic): GetCompleteAction {
	return {
		episodic,
		type: GET_COMPLETE,
	};
}

export function getDeptPerms(id: number): GetDeptPermsAction {
	return {
		type: GET_DEPT_PERMS,
		id,
	};
}

export function getDeptPermsFailed(error: Error): GetDeptPermsFailedAction {
	return {
		error,
		type: GET_DEPT_PERMS_FAILED,
	};
}

export function destroyComplete(): DestroyCompleteAction {
	return {
		type: DESTROY_COMPLETE,
	};
}
export function getDeptPermsComplete(permissions: {
	CmPermission: CmPermission[];
	HaPermission: HaPermission[];
	MuPermission: MuPermission[];
	SetPermission: SetPermission[];
	PrPermission: PrPermission[];
}): GetDeptPermsCompleteAction {
	return {
		permissions,
		type: GET_DEPT_PERMS_COMPLETE,
	};
}

export function getPictureCounts(id?: number): GetPictureCountsAction {
	return {
		type: GET_PICTURE_COUNTS,
		id,
	};
}

export function getPictureCountsFailed(
	error: Error
): GetPictureCountsFailedAction {
	return {
		error,
		type: GET_PICTURE_COUNTS_FAILED,
	};
}

export function getPictureCountsComplete(pictureCountsByDept: {
	[dept: string]: number;
}): GetPictureCountsCompleteAction {
	return {
		pictureCountsByDept,
		type: GET_PICTURE_COUNTS_COMPLETE,
	};
}

export type Action =
	| ListAction
	| ListFailedAction
	| ListCompleteAction
	| ListSeasonsAction
	| GetAction
	| GetFailedAction
	| GetCompleteAction
	| GetDeptPermsAction
	| GetDeptPermsCompleteAction
	| GetDeptPermsFailedAction
	| GetPictureCountsAction
	| GetPictureCountsCompleteAction
	| GetPictureCountsFailedAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction
	| UpdateTitlesAction
	| UpdateTitlesCompleteAction
	| UpdateTitlesFailedAction
	| DestroyCompleteAction;
