import {
	CmWrapBoxEpisodicItem,
	CmWrapBoxEpisodicItemUpdateResponse,
} from 'sos-models';
import axios from '../../config/axios';

export async function update(
	episodicId: number,
	cmWrapBoxEpisodicItems: CmWrapBoxEpisodicItem[]
): Promise<CmWrapBoxEpisodicItemUpdateResponse> {
	return await axios
		.put<CmWrapBoxEpisodicItemUpdateResponse>(
			`/api/episodics/${episodicId}/cm-wrap-box-episodic-item`,
			{ cmWrapBoxEpisodicItems: cmWrapBoxEpisodicItems }
		)
		.then((response) => response.data);
}
