import { normalize } from 'normalizr';
import { PropStatus } from 'sos-models';
import * as propStatusActions from './prop-status.actions';
import * as inventorySatelliteActions from '../inventory-satellites/inventory-satellite.actions';
import * as inventoryActions from '../inventory/inventory.actions';
import { propStatusSchema } from '../schemas';
import {
	ActionStatus,
	createComplete,
	createReducer,
	destroyComplete,
	failed,
	loading,
	updateComplete,
} from '../utils';

export interface State {
	entities: { [id: number]: PropStatus };
	ids: number[];
	createStatus: ActionStatus;
	destroyStatus: ActionStatus;
	listStatus: ActionStatus;
	getStatus: ActionStatus;
	updateStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	createStatus: ActionStatus.Inactive,
	destroyStatus: ActionStatus.Inactive,
	listStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	| inventoryActions.Action
	| inventorySatelliteActions.Action
	| propStatusActions.Action
>(initial, {
	[inventorySatelliteActions.LIST]: loading<State>('listStatus'),
	[inventorySatelliteActions.LIST_COMPLETE]: listComplete,
	[inventorySatelliteActions.LIST_FAILED]: failed<State>('listStatus'),
	[propStatusActions.CREATE]: loading<State>('createStatus'),
	[propStatusActions.CREATE_COMPLETE]: createComplete<
		PropStatus,
		State,
		propStatusActions.CreateCompleteAction
	>('propStatus', propStatusSchema),
	[propStatusActions.CREATE_FAILED]: failed<State>('createStatus'),
	[propStatusActions.DESTROY]: loading<State>('destroyStatus'),
	[propStatusActions.DESTROY_COMPLETE]: destroyComplete<
		PropStatus,
		State,
		propStatusActions.DestroyCompleteAction
	>('propStatus'),
	[propStatusActions.DESTROY_FAILED]: failed<State>('destroyStatus'),
	[propStatusActions.UPDATE]: loading<State>('updateStatus'),
	[propStatusActions.UPDATE_COMPLETE]: updateComplete<
		PropStatus,
		State,
		propStatusActions.UpdateCompleteAction
	>('propStatus', propStatusSchema),
	[propStatusActions.UPDATE_FAILED]: failed<State>('updateStatus'),
});

function listComplete(
	state: State,
	action: inventorySatelliteActions.ListCompleteAction
): State {
	if (!action.inventorySatellites.propStatuses) {
		return {
			...state,
			listStatus: ActionStatus.Complete,
		};
	}
	const normalized = normalize(action.inventorySatellites.propStatuses, [
		propStatusSchema,
	]);
	return {
		...state,
		entities: normalized.entities.propStatuses,
		ids: normalized.result,
		listStatus: ActionStatus.Complete,
	};
}
