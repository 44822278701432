import { MuEpisodicCharacterNote } from 'sos-models';
import * as actions from './mu-episodic-character-note.actions';
import { muEpisodicCharacterNoteSchema } from '../schemas';
import {
	ActionStatus,
	createComplete,
	createReducer,
	listComplete,
	updateComplete,
} from '../utils';

export interface State {
	entities: { [id: number]: MuEpisodicCharacterNote };
	ids: number[];
	createStatus: ActionStatus;
	listStatus: ActionStatus;
	updateStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	createStatus: ActionStatus.Inactive,
	listStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.CREATE_COMPLETE]: createComplete<
		MuEpisodicCharacterNote,
		State,
		actions.CreateCompleteAction
	>('episodicCharacterNotes', muEpisodicCharacterNoteSchema),
	[actions.LIST_COMPLETE]: listComplete<
		MuEpisodicCharacterNote,
		State,
		actions.ListCompleteAction
	>('episodicCharacterNotes', muEpisodicCharacterNoteSchema),
	[actions.UPDATE_COMPLETE]: updateComplete<
		MuEpisodicCharacterNote,
		State,
		actions.UpdateCompleteAction
	>('episodicCharacterNotes', muEpisodicCharacterNoteSchema),
});
