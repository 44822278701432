import {
	PictureSceneDeleteResponse,
	PictureSceneListResponse,
} from 'sos-models';
import axios from '../../config/axios';

export async function destroy(
	episodicId: number,
	pictureSceneId: number
): Promise<PictureSceneDeleteResponse> {
	const { data } = await axios.delete(
		`/api/episodics/${episodicId}/picture-scenes/${pictureSceneId}`
	);
	return data;
}

export function list(
	prodId: number,
	sceneId: number
): Promise<PictureSceneListResponse> {
	return axios
		.get<PictureSceneListResponse>(
			`/api/productions/${prodId}/scenes/${sceneId}/picture-scenes`
		)
		.then((response) => response.data);
}
