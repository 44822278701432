import { InventoryFieldSetting } from 'sos-models';
import axios from '../../config/axios';

export function get(): Promise<InventoryFieldSetting> {
	return axios
		.get<Partial<InventoryFieldSetting>>(`/api/inventory-field-settings`)
		.then((response) => new InventoryFieldSetting(response.data));
}

export function update(
	inventoryFieldSetting: Partial<InventoryFieldSetting>
): Promise<InventoryFieldSetting> {
	return axios
		.post<Partial<InventoryFieldSetting>>(
			`/api/inventory-field-settings`,
			inventoryFieldSetting
		)
		.then((response) => new InventoryFieldSetting(response.data));
}
