import { AHExportPayload, AHDeptPermissionsResponse } from 'sos-models';
import axios from '../../config/axios';

interface ExportReturn {
	epItemsImported: number;
	epPropsImported: number;
	setPiecesExported: number;
}
export async function exportToAssetHub(
	episodicId: number,
	payload: AHExportPayload
): Promise<ExportReturn> {
	return axios
		.post(`/api/asset-hub/episodic/${episodicId}/export`, payload)
		.then((response) => response.data);
}

export async function getPermissions(
	episodicId: number
): Promise<AHDeptPermissionsResponse> {
	return axios
		.get<AHDeptPermissionsResponse>(
			`/api/asset-hub/episodic/${episodicId}/permissions`
		)
		.then((response) => response.data);
}
