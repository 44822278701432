import { TableColumn } from 'sos-models';
import * as actions from './change-inventory-table-column.actions';
import {
	CHANGE_VIEW_INVENTORY_TABLE_COLUMNS,
	CHANGE_VIEW_INVENTORY_TABLE_COLUMNS_ARRAY,
	defaultHidden,
} from './change-inventory-table-columns.data';
import { createColumnReducer } from '../column.reducer';

export interface State {
	ids: string[];
	hidden: string[];
	widths: { [name: string]: TableColumn };
}

const initial: State = {
	ids: CHANGE_VIEW_INVENTORY_TABLE_COLUMNS_ARRAY.map(
		(col) => col.canonical_name
	),
	hidden: defaultHidden,
	widths: CHANGE_VIEW_INVENTORY_TABLE_COLUMNS,
};

export const reducer = createColumnReducer(initial, actions);
