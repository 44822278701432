import { DEPARTMENTS, DeptWrapBoxes, SetWrapBox } from 'sos-models';
import { setWrapBoxSchema } from '../schemas';
import { NormalizationOutput } from '../utils';
import {
	BaseWrapBoxReducerState,
	createWrapBoxReducer,
} from '../wrap-boxes/dept-wrap-box-base.reducer';

export interface State extends BaseWrapBoxReducerState<SetWrapBox> {}

export const reducer = createWrapBoxReducer<SetWrapBox>(
	(wrapBoxes: DeptWrapBoxes) => wrapBoxes.SetWrapBox,
	(normalized: NormalizationOutput<SetWrapBox>) =>
		normalized.entities.setWrapBoxes,
	setWrapBoxSchema,
	DEPARTMENTS.SET
);
