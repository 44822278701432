import { SetWrapBoxSetPiece } from 'sos-models';

export const CREATE_COMPLETE = '[SetWrapBoxSetPiece] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const LIST_COMPLETE = '[SetWrapBoxSetPiece] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const UPDATE_COMPLETE = '[SetWrapBoxSetPiece] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const DESTROY_COMPLETE = '[SetWrapBoxSetPiece] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export interface CreateCompleteAction {
	type: CREATE_COMPLETE;
	setWrapBoxSetPieces: SetWrapBoxSetPiece[];
}
export interface ListCompleteAction {
	type: LIST_COMPLETE;
	setWrapBoxSetPieces: SetWrapBoxSetPiece[];
}

export interface UpdateCompleteAction {
	type: UPDATE_COMPLETE;
	setWrapBoxSetPieces: SetWrapBoxSetPiece[];
}

export interface DestroyCompleteAction {
	type: DESTROY_COMPLETE;
	setWrapBoxSetPieces: SetWrapBoxSetPiece[];
}

export function createComplete(
	setWrapBoxSetPieces: SetWrapBoxSetPiece[]
): CreateCompleteAction {
	return {
		setWrapBoxSetPieces,
		type: CREATE_COMPLETE,
	};
}

export function listComplete(
	setWrapBoxSetPieces: SetWrapBoxSetPiece[]
): ListCompleteAction {
	return {
		setWrapBoxSetPieces,
		type: LIST_COMPLETE,
	};
}

export function updateComplete(
	setWrapBoxSetPieces: SetWrapBoxSetPiece[]
): UpdateCompleteAction {
	return {
		setWrapBoxSetPieces,
		type: UPDATE_COMPLETE,
	};
}

export function destroyComplete(
	setWrapBoxSetPieces: SetWrapBoxSetPiece[]
): DestroyCompleteAction {
	return {
		setWrapBoxSetPieces,
		type: DESTROY_COMPLETE,
	};
}

export type Action =
	| ListCompleteAction
	| UpdateCompleteAction
	| CreateCompleteAction
	| DestroyCompleteAction;
