import { UnknownAction } from 'redux';

export const SELECT = '[Productions] Select';
type SELECT = typeof SELECT;

export const SELECT_ALL = '[Productions] Select All';
type SELECT_ALL = typeof SELECT_ALL;

export const DE_SELECT_ALL = '[Productions] Deselect All';
type DE_SELECT_ALL = typeof DE_SELECT_ALL;

export const CREATE_COMPLETE = '[Productions] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export interface SelectAction extends UnknownAction {
	type: SELECT;
	episodicId: number;
	selectedIds: number[];
	initial?: boolean;
}

export interface SelectAllAction {
	type: SELECT_ALL;
	selectedIds?: number[];
	episodicId: number;
}

export interface DeSelectAllAction {
	type: DE_SELECT_ALL;
	episodicId: number;
}

export interface CreateCompleteAction {
	type: CREATE_COMPLETE;
	episodicId: number;
	newProdId: number;
}

export function select(
	episodicId: number,
	selectedIds: number[],
	initial?: boolean
): SelectAction {
	return {
		episodicId,
		selectedIds,
		initial,
		type: SELECT,
	};
}

export function selectAll(
	episodicId: number,
	selectedIds?: number[]
): SelectAllAction {
	return {
		episodicId,
		selectedIds,
		type: SELECT_ALL,
	};
}

export function deSelectAll(episodicId: number): DeSelectAllAction {
	return {
		episodicId,
		type: DE_SELECT_ALL,
	};
}

export function createComplete(
	episodicId: number,
	newProdId: number
): CreateCompleteAction {
	return {
		episodicId,
		newProdId,
		type: CREATE_COMPLETE,
	};
}

export type Action =
	| SelectAction
	| CreateCompleteAction
	| SelectAllAction
	| DeSelectAllAction;
