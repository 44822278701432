import { SetListResponse } from 'sos-models';
import axios from '../../config/axios';

export async function exportToCSV(
	episodicId: number,
	prodIds: number[]
) {
	return await axios
		.get(
			`/api/episodics/${episodicId}/productions/${prodIds}/sets/export`,
			{
				params: [prodIds],
				responseType: 'arraybuffer',
			}
		)
		.then((response) => response.data);
}

export async function list(episodicId: number): Promise<SetListResponse> {
	return axios
		.get<SetListResponse>(`/api/episodics/${episodicId}/sets`)
		.then((response) => response.data);
}
