import { Set } from 'sos-models';
import * as actions from './set.actions';
import { setSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createReducer,
	failed,
	listComplete,
	loading,
} from '../utils';
import { saveAs } from 'file-saver';

export interface State extends BaseReducerState<Set> {
	exportStatus: ActionStatus;
	listStatus: ActionStatus;
	getStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	exportStatus: ActionStatus.Inactive,
	listStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.EXPORT_TO_CSV]: loading<State>('exportStatus'),
	[actions.EXPORT_TO_CSV_COMPLETE]: exportToCSVComplete,
	[actions.EXPORT_TO_CSV_FAILED]: failed<State>('exportStatus'),
	[actions.LIST]: loading<State>('listStatus'),
	[actions.LIST_COMPLETE]: listComplete<Set, State, actions.ListCompleteAction>(
		'sets',
		setSchema
	),
	[actions.LIST_FAILED]: failed<State>('listStatus'),
});

function exportToCSVComplete(
	state: State,
	{ fileContent }: actions.ExportToCSVCompleteAction
) {
	const blob = new Blob([fileContent], { type: 'text/csv' });
	saveAs(blob, 'Sets_Export.csv');

	return {
		...state,
		exportStatus: ActionStatus.Complete,
	};
}