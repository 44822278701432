import { normalize } from 'normalizr';
import { PropScene } from 'sos-models';
import * as actions from './prop-scene.actions';
import { propSceneSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	complete,
	createComplete,
	createReducer,
	destroyComplete,
	failed,
	listComplete,
	loading,
	uniqueArrayMerge,
} from '../utils';

export interface State extends BaseReducerState<PropScene> {
	propScenesForChar: { [epCharId: number]: number[] };
	createStatus: ActionStatus;
	createMultipleStatus: ActionStatus;
	getForEpCharStatus: ActionStatus;
	destroyStatus: ActionStatus;
	listStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	propScenesForChar: {},
	createStatus: ActionStatus.Inactive,
	createMultipleStatus: ActionStatus.Inactive,
	getForEpCharStatus: ActionStatus.Inactive,
	destroyStatus: ActionStatus.Inactive,
	listStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.CREATE]: loading<State>('createStatus'),
	[actions.CREATE_BY_SCENE]: loading<State>('createStatus'),
	[actions.CREATE_COMPLETE]: createComplete<
		PropScene,
		State,
		actions.CreateCompleteAction
	>('propScenes', propSceneSchema),
	[actions.CREATE_FAILED]: failed<State>('createStatus'),
	[actions.CREATE_MULTIPLE]: loading<State>('createMultipleStatus'),
	[actions.CREATE_MULTIPLE_COMPLETE]: complete<State>('createMultipleStatus'),
	[actions.CREATE_MULTIPLE_FAILED]: failed<State>('createMultipleStatus'),
	[actions.LIST_COMPLETE]: listComplete<
		PropScene,
		State,
		actions.ListCompleteAction
	>('propScenes', propSceneSchema),
	[actions.GET_FOR_EP_CHAR]: loading<State>('getForEpCharStatus'),
	[actions.GET_FOR_EP_CHAR_COMPLETE]: getForEpCharComplete,
	[actions.GET_FOR_EP_CHAR_FAILED]: failed<State>('getForEpCharStatus'),
	[actions.DESTROY]: loading<State>('destroyStatus'),
	[actions.DESTROY_FAILED]: failed<State>('destroyStatus'),
	[actions.DESTROY_COMPLETE]: destroyComplete<
		PropScene,
		State,
		actions.DestroyCompleteAction
	>('propScenes'),
});

function getForEpCharComplete(
	state: State,
	{ propScenes, episodicCharacterId }: actions.GetForEpCharCompleteAction
): State {
	const normalized = normalize(propScenes, [propSceneSchema]);

	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.propScenes,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		propScenesForChar: {
			...state.propScenesForChar,
			[episodicCharacterId]: normalized.result,
		},
	};
}
