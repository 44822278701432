import { DEPARTMENTS, DeptWrapBoxes, PrWrapBox } from 'sos-models';
import { prWrapBoxSchema } from '../schemas';
import { NormalizationOutput } from '../utils';
import {
	BaseWrapBoxReducerState,
	createWrapBoxReducer,
} from '../wrap-boxes/dept-wrap-box-base.reducer';

export interface State extends BaseWrapBoxReducerState<PrWrapBox> {}

export const reducer = createWrapBoxReducer<PrWrapBox>(
	(wrapBoxes: DeptWrapBoxes) => wrapBoxes.PrWrapBox,
	(normalized: NormalizationOutput<PrWrapBox>) =>
		normalized.entities.prWrapBoxes,
	prWrapBoxSchema,
	DEPARTMENTS.PR
);
