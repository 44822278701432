import { AppFeature, AppFeatureUpdateResponse } from 'sos-models';
import axios from '../../config/axios';

export async function list(): Promise<AppFeature[]> {
	return await axios
		.get<AppFeature[]>('/api/app-features')
		.then((response) => response.data);
}

export async function update(
	appFeatureId: number,
	enable: boolean
): Promise<AppFeatureUpdateResponse> {
	return await axios
		.put<AppFeatureUpdateResponse>(
			`/api/app-features/${appFeatureId}/${enable}`
		)
		.then((response) => response.data);
}
