import { SceneSorting } from '@synconset/sorting';
import { Moment } from 'moment';
import { UnknownAction } from 'redux';
import { PartialScene, Scene, ScriptLocation } from 'sos-models';

export const CREATE = '[Scene] Add to Scenes';
type CREATE = typeof CREATE;

export const CREATE_COMPLETE = '[Scene] Create complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const CREATE_FAILED = '[Scene] Create failed';
type CREATE_FAILED = typeof CREATE_FAILED;

export const DUPLICATE = '[Scene] Duplicate Scene';
type DUPLICATE = typeof DUPLICATE;

export const DUPLICATE_COMPLETE = '[Scene] Duplicate complete';
type DUPLICATE_COMPLETE = typeof DUPLICATE_COMPLETE;

export const DUPLICATE_FAILED = '[Scene] Duplicate failed';
type DUPLICATE_FAILED = typeof DUPLICATE_FAILED;

export const EXPORT_SCENES = '[Scene] Export Scenes';
type EXPORT_SCENES = typeof EXPORT_SCENES;

export const EXPORT_SCENES_FAILED = '[Scene] Export Scenes Failed';
type EXPORT_SCENES_FAILED = typeof EXPORT_SCENES_FAILED;

export const EXPORT_SCENES_COMPLETE = '[Scene] Export Scenes Complete';
type EXPORT_SCENES_COMPLETE = typeof EXPORT_SCENES_COMPLETE;

export const LIST = '[Scene] List';
type LIST = typeof LIST;

export const LIST_FAILED = '[Scene] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const LIST_COMPLETE = '[Scene] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const DESTROY = '[Scene] Destroy';
type DESTROY = typeof DESTROY;

export const DESTROY_FAILED = '[Scene] Destroy Failed';
type DESTROY_FAILED = typeof DESTROY_FAILED;

export const DESTROY_COMPLETE = '[Scene] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const UPDATE = '[Scene] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[Scene] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[Scene] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const SET_SORTING = '[Scene] Set Sorting';
type SET_SORTING = typeof SET_SORTING;

export const GET_EPISODES_FOR_PROP = '[Scene] Get Episodes For Prop';
type GET_EPISODES_FOR_PROP = typeof GET_EPISODES_FOR_PROP;

export const GET_EPISODES_FOR_PROP_COMPLETE =
	'[Scene] Get Episodes For Prop Complete';
type GET_EPISODES_FOR_PROP_COMPLETE = typeof GET_EPISODES_FOR_PROP_COMPLETE;

export const GET_EPISODES_FOR_PROP_FAILED =
	'[Scene] Get Episodes For Prop Failed';
type GET_EPISODES_FOR_PROP_FAILED = typeof GET_EPISODES_FOR_PROP_FAILED;

export const GET_COMPLETE = '[Scene] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const GET_FAILED = '[Scene] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export const GET = '[Scene] Get';
type GET = typeof GET;

export const SET_CURRENT = '[Scene] Set Current';
type SET_CURRENT = typeof SET_CURRENT;

export const SET_FILTER = '[Scene] Set Filter';
type SET_FILTER = typeof SET_FILTER;

export interface CreateAction {
	episodicId: number;
	scene: PartialScene;
	scriptLocation: Partial<ScriptLocation>;
	primaryIds: number[];
	backgroundIds: number[];
	setIds: number[];
	type: CREATE;
}

export interface CreateCompleteAction {
	scenes: Scene[];
	type: CREATE_COMPLETE;
}

export interface CreateFailedAction {
	error: Error;
	type: CREATE_FAILED;
}

export interface DuplicateAction {
	episodicId: number;
	prodId: number;
	sceneId: number;
	name: string;
	type: DUPLICATE;
}

export interface DuplicateCompleteAction {
	scenes: Scene[];
	type: DUPLICATE_COMPLETE;
}

export interface DuplicateFailedAction {
	error: Error;
	type: DUPLICATE_FAILED;
}

export interface ListAction extends UnknownAction {
	episodicId: number;
	type: LIST;
}

export interface ListCompleteAction {
	type: LIST_COMPLETE;
	scenes: Scene[];
}

export interface ListFailedAction {
	type: LIST_FAILED;
	error: Error;
}

export interface DestroyAction {
	sceneId: number;
	episodicId: number;
	type: DESTROY;
}

export interface DestroyCompleteAction {
	scenes: Scene[];
	type: DESTROY_COMPLETE;
}

export interface DestroyFailedAction {
	error: Error;
	type: DESTROY_FAILED;
}

export interface UpdateAction {
	episodicId: number;
	scenes: PartialScene[];
	addedEpCharIds: { [sceneId: number]: number[] };
	removedEpCharIds: { [sceneId: number]: number[] };
	addedSetIds: { [sceneId: number]: number[] };
	removedSetIds: { [sceneId: number]: number[] };
	type: UPDATE;
}

export interface UpdateCompleteAction {
	scenes: Scene[];
	type: UPDATE_COMPLETE;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export interface SetSortingAction {
	sorting: SceneSorting[];
	type: SET_SORTING;
}

export interface ExportScenesAction {
	episodicId: number;
	prodIds: number[];
	type: EXPORT_SCENES;
}

export interface ExportScenesCompleteAction {
	fileContent: string;
	type: EXPORT_SCENES_COMPLETE;
}

export interface ExportScenesFailedAction {
	error: Error;
	type: EXPORT_SCENES_FAILED;
}

export interface GetEpisodesForPropAction {
	departmentId: number;
	episodicId: number;
	epPropId: number;
	type: GET_EPISODES_FOR_PROP;
}

export interface GetEpisodesForPropCompleteAction {
	type: GET_EPISODES_FOR_PROP_COMPLETE;
}

export interface GetEpisodesForPropFailedAction {
	error: Error;
	type: GET_EPISODES_FOR_PROP_FAILED;
}

export interface GetCompleteAction {
	scenes: Scene[];
	previous: number;
	next: number;
	type: GET_COMPLETE;
}

export interface GetAction {
	episodicId: number;
	sceneId: number;
	productionIds: string;
	type: GET;
}

export interface GetFailedAction {
	error: Error;
	type: GET_FAILED;
}

export interface SetCurrentAction {
	sceneId: number;
	type: SET_CURRENT;
}

export interface SetFilterAction {
	fromDateFilter: Moment;
	toDateFilter: Moment;
	hideOmittedFilter: boolean;
	type: SET_FILTER;
}

export function create(
	episodicId: number,
	scene: PartialScene,
	scriptLocation: Partial<ScriptLocation>,
	primaryIds: number[],
	backgroundIds: number[],
	setIds: number[]
): CreateAction {
	return {
		episodicId,
		scene,
		scriptLocation,
		primaryIds,
		backgroundIds,
		setIds,
		type: CREATE,
	};
}

export function createComplete(scenes: Scene[]): CreateCompleteAction {
	return {
		scenes,
		type: CREATE_COMPLETE,
	};
}

export function createFailed(error: Error): CreateFailedAction {
	return {
		error,
		type: CREATE_FAILED,
	};
}

export function duplicate(
	episodicId: number,
	prodId: number,
	sceneId: number,
	name: string
): DuplicateAction {
	return {
		episodicId,
		prodId,
		sceneId,
		name,
		type: DUPLICATE,
	};
}

export function duplicateComplete(scenes: Scene[]): DuplicateCompleteAction {
	return {
		scenes,
		type: DUPLICATE_COMPLETE,
	};
}

export function duplicateFailed(error: Error): DuplicateFailedAction {
	return {
		error,
		type: DUPLICATE_FAILED,
	};
}

export function exportScenes(
	episodicId: number,
	prodIds: number[]
): ExportScenesAction {
	return {
		episodicId,
		prodIds,
		type: EXPORT_SCENES,
	};
}

export function exportScenesComplete(
	fileContent: string
): ExportScenesCompleteAction {
	return {
		fileContent,
		type: EXPORT_SCENES_COMPLETE,
	};
}

export function exportScenesFailed(error: Error): ExportScenesFailedAction {
	return {
		error,
		type: EXPORT_SCENES_FAILED,
	};
}

export function list(episodicId: number): ListAction {
	return {
		episodicId,
		type: LIST,
	};
}

export function listComplete(scenes: Scene[]): ListCompleteAction {
	return {
		scenes,
		type: LIST_COMPLETE,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export function destroy(sceneId: number, episodicId: number): DestroyAction {
	return {
		sceneId,
		episodicId,
		type: DESTROY,
	};
}

export function destroyComplete(scenes: Scene[]): DestroyCompleteAction {
	return {
		scenes,
		type: DESTROY_COMPLETE,
	};
}

export function destroyFailed(error: Error): DestroyFailedAction {
	return {
		error,
		type: DESTROY_FAILED,
	};
}

export function update(
	episodicId: number,
	scenes: PartialScene[],
	addedEpCharIds: { [sceneId: number]: number[] },
	removedEpCharIds: { [sceneId: number]: number[] },
	addedSetIds: { [sceneId: number]: number[] },
	removedSetIds: { [sceneId: number]: number[] }
): UpdateAction {
	return {
		episodicId,
		scenes,
		addedEpCharIds,
		removedEpCharIds,
		addedSetIds,
		removedSetIds,
		type: UPDATE,
	};
}

export function updateComplete(scenes: Scene[]): UpdateCompleteAction {
	return {
		scenes,
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function setSorting(sorting: SceneSorting[]): SetSortingAction {
	return {
		sorting,
		type: SET_SORTING,
	};
}

export function getEpisodesForProp(
	episodicId: number,
	departmentId: number,
	epPropId: number
): GetEpisodesForPropAction {
	return {
		episodicId,
		departmentId,
		epPropId,
		type: GET_EPISODES_FOR_PROP,
	};
}

export function getEpisodesForPropComplete(): GetEpisodesForPropCompleteAction {
	return {
		type: GET_EPISODES_FOR_PROP_COMPLETE,
	};
}

export function getEpisodesForPropFailed(
	error: Error
): GetEpisodesForPropFailedAction {
	return {
		error,
		type: GET_EPISODES_FOR_PROP_FAILED,
	};
}

export function getComplete(
	scenes: Scene[],
	previous: number,
	next: number
): GetCompleteAction {
	return {
		scenes,
		previous,
		next,
		type: GET_COMPLETE,
	};
}

export function get(
	sceneId: number,
	episodicId: number,
	productionIds: string
): GetAction {
	return {
		sceneId,
		episodicId,
		productionIds,
		type: GET,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	};
}

export function setCurrent(sceneId: number): SetCurrentAction {
	return {
		sceneId,
		type: SET_CURRENT,
	};
}

export function setFilter(
	fromDateFilter: Moment,
	toDateFilter: Moment,
	hideOmittedFilter: boolean
): SetFilterAction {
	return {
		fromDateFilter,
		toDateFilter,
		hideOmittedFilter,
		type: SET_FILTER,
	};
}

export type Action =
	| CreateAction
	| CreateCompleteAction
	| CreateFailedAction
	| DuplicateAction
	| DuplicateCompleteAction
	| DuplicateFailedAction
	| ExportScenesAction
	| ExportScenesCompleteAction
	| ExportScenesFailedAction
	| GetFailedAction
	| GetAction
	| GetCompleteAction
	| GetEpisodesForPropAction
	| GetEpisodesForPropCompleteAction
	| GetEpisodesForPropFailedAction
	| ListAction
	| ListCompleteAction
	| ListFailedAction
	| DestroyAction
	| DestroyCompleteAction
	| DestroyFailedAction
	| SetCurrentAction
	| SetFilterAction
	| SetSortingAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction;
