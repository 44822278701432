import { call, put, takeLatest } from 'redux-saga/effects';
import {
	DEPARTMENTS,
	DeptSpecificInventoryPictureListResponse,
	InventoryPictureListResponse,
} from 'sos-models';
import * as actions from './inventory-picture.actions';
import * as api from './inventory-picture.api';
import * as logger from '../../logger';

export function* list({
	episodicId,
	departmentId,
	inventoryId,
}: actions.ListAction) {
	try {
		const response: InventoryPictureListResponse = yield call(
			api.list,
			episodicId,
			departmentId,
			inventoryId
		);
		if (departmentId === DEPARTMENTS.CM) {
			const { listed } = response as DeptSpecificInventoryPictureListResponse<
				DEPARTMENTS.CM
			>;
			yield put(
				actions.listComplete(
					listed.CmEpisodicItemPicture,
					listed.Picture,
					departmentId
				)
			);
		} else if (departmentId === DEPARTMENTS.SET) {
			const { listed } = response as DeptSpecificInventoryPictureListResponse<
				DEPARTMENTS.SET
			>;
			yield put(
				actions.listComplete(
					listed.SetPiecePicture,
					listed.Picture,
					departmentId
				)
			);
		} else if (departmentId === DEPARTMENTS.PR) {
			const { listed } = response as DeptSpecificInventoryPictureListResponse<
				DEPARTMENTS.PR
			>;
			yield put(
				actions.listComplete(
					listed.PrEpisodicPropPicture,
					listed.Picture,
					departmentId
				)
			);
		}
	} catch (err) {
		yield put(actions.listFailed(departmentId, err));
		logger.responseError(err, 'loading inventory pictures', true);
	}
}

function* saga() {
	yield takeLatest(actions.LIST, list);
}

export default saga;
