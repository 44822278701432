import { PropSceneCharScene } from 'sos-models';

export const CREATE_COMPLETE = '[Prop Scene Char Scene] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const DESTROY_COMPLETE = '[Prop Scene Char Scene] Delete Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const LIST_COMPLETE = '[Prop Scene Char Scene] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export interface CreateCompleteAction {
	type: CREATE_COMPLETE;
	propSceneCharScenes: PropSceneCharScene[];
}

export interface DestroyCompleteAction {
	type: DESTROY_COMPLETE;
	propSceneCharScenes: PropSceneCharScene[];
}

export interface ListCompleteAction {
	type: LIST_COMPLETE;
	propSceneCharScenes: PropSceneCharScene[];
}

export function createComplete(
	propSceneCharScenes: PropSceneCharScene[]
): CreateCompleteAction {
	return {
		propSceneCharScenes,
		type: CREATE_COMPLETE,
	};
}

export function destroyComplete(
	propSceneCharScenes: PropSceneCharScene[]
): DestroyCompleteAction {
	return {
		propSceneCharScenes,
		type: DESTROY_COMPLETE,
	};
}

export function listComplete(
	propSceneCharScenes: PropSceneCharScene[]
): ListCompleteAction {
	return {
		propSceneCharScenes,
		type: LIST_COMPLETE,
	};
}

export type Action =
	| CreateCompleteAction
	| DestroyCompleteAction
	| ListCompleteAction;
