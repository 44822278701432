import { State as ColumnState } from '../store/column.reducer';
import { difference } from 'lodash';

/**
 * When we add a new column, it breaks the table since it's not in hidden and there's no width.
 * To fix this, we add the new column/s to ids and hidden
 */
export function handleNewlyAddedColumns(
	initial: ColumnState,
	persisted: ColumnState
) {
	if (
		isActuallyColumnData(initial) &&
		initial.ids.length !== persisted.ids.length
	) {
		const newState = { ...persisted };
		const newIds = difference(initial.ids, persisted.ids);
		newState.ids = [...persisted.ids, ...newIds];
		newState.hidden = [...persisted.hidden, ...newIds];

		return newState;
	}

	return persisted;
}

function isActuallyColumnData(initial: ColumnState) {
	return (
		initial.hasOwnProperty('ids') &&
		initial.hasOwnProperty('hidden') &&
		initial.hasOwnProperty('widths')
	);
}
