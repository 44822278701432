import { CreateDeleteChangeScene, HaChange, HaChangeScene } from 'sos-models';

export const CREATE_COMPLETE = '[HaChangeScene] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const DELETE_COMPLETE = '[HaChangeScene] Delete Complete';
type DELETE_COMPLETE = typeof DELETE_COMPLETE;

export const GET_HA_CHANGE_SCENES_FOR_SCENE =
	'[HaChangeScene] Get Hair Changes For Scene';
type GET_HA_CHANGE_SCENES_FOR_SCENE = typeof GET_HA_CHANGE_SCENES_FOR_SCENE;

export const GET_HA_CHANGE_SCENES_FOR_SCENE_COMPLETE =
	'[HaChangeScene] Get Hair Changes For Scene Complete';
type GET_HA_CHANGE_SCENES_FOR_SCENE_COMPLETE = typeof GET_HA_CHANGE_SCENES_FOR_SCENE_COMPLETE;

export const GET_HA_CHANGE_SCENES_FOR_SCENE_FAILED =
	'[HaChangeScene] Get Hair Changes For Scene Failed';
type GET_HA_CHANGE_SCENES_FOR_SCENE_FAILED = typeof GET_HA_CHANGE_SCENES_FOR_SCENE_FAILED;

export const LIST_COMPLETE = '[HaChangeScene] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FOR_EP_CHAR = '[HaChangeScene] List For Episodic Character';
type LIST_FOR_EP_CHAR = typeof LIST_FOR_EP_CHAR;

export const LIST_FOR_EP_CHAR_COMPLETE =
	'[HaChangeScene] List For Episodic Character Complete';
type LIST_FOR_EP_CHAR_COMPLETE = typeof LIST_FOR_EP_CHAR_COMPLETE;

export const LIST_FOR_EP_CHAR_FAILED =
	'[HaChangeScene] List For Episodic Character Failed';
type LIST_FOR_EP_CHAR_FAILED = typeof LIST_FOR_EP_CHAR_FAILED;

export const CREATE_AND_DELETE_EXISTING =
	'[HaChangeScene] Create HaChangeScene And Delete Existing';
type CREATE_AND_DELETE_EXISTING = typeof CREATE_AND_DELETE_EXISTING;

export const CREATE_AND_DELETE_EXISTING_COMPLETE =
	'[HaChangeScene] Create HaChangeScene And Delete Existing Complete';
type CREATE_AND_DELETE_EXISTING_COMPLETE = typeof CREATE_AND_DELETE_EXISTING_COMPLETE;

export const CREATE_AND_DELETE_EXISTING_FAILED =
	'[HaChangeScene] Create HaChangeScene And Delete Existing Failed';
type CREATE_AND_DELETE_EXISTING_FAILED = typeof CREATE_AND_DELETE_EXISTING_FAILED;

export const UPDATE = '[HaChangeScene] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[HaChangeScene] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[HaChangeScene] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_COMPLETE =
	'[HaChangeScene] Delete CharScene By EpChar Id And Scene Id Complete';
type DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_COMPLETE = typeof DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_COMPLETE;

export interface CreateCompleteAction {
	haChangeScenes: HaChangeScene[];
	type: CREATE_COMPLETE;
}

export interface DeleteCompleteAction {
	type: DELETE_COMPLETE;
	haChangeScenes: HaChangeScene[];
}

export interface GetHaChangeScenesForSceneAction {
	type: GET_HA_CHANGE_SCENES_FOR_SCENE;
	prodId: number;
	sceneId: number;
}

export interface GetHaChangeScenesForSceneCompleteAction {
	type: GET_HA_CHANGE_SCENES_FOR_SCENE_COMPLETE;
	haChangeScenes: HaChangeScene[];
	sceneId: number;
}

export interface GetHaChangeScenesForSceneFailedAction {
	type: GET_HA_CHANGE_SCENES_FOR_SCENE_FAILED;
	error: Error;
}

export interface ListCompleteAction {
	haChangeScenes: HaChangeScene[];
	type: LIST_COMPLETE;
}

export interface ListForEpCharAction {
	type: LIST_FOR_EP_CHAR;
	episodicId: number;
	epCharId: number;
}

export interface ListForEpCharCompleteAction {
	type: LIST_FOR_EP_CHAR_COMPLETE;
	haChangeScenes: HaChangeScene[];
}

export interface ListForEpCharFailedAction {
	type: LIST_FOR_EP_CHAR_FAILED;
	error: Error;
}

export interface CreateAndDeleteExistingAction {
	type: CREATE_AND_DELETE_EXISTING;
	episodicId: number;
	changeScenes: CreateDeleteChangeScene[];
}

export interface CreateAndDeleteExistingCompleteAction {
	type: CREATE_AND_DELETE_EXISTING_COMPLETE;
	created: { HaChangeScene: HaChangeScene[] };
	deleted: { HaChangeScene: HaChangeScene[] };
	updated: {
		HaChange: HaChange[];
		HaChangeScene: HaChangeScene[];
	};
}

export interface CreateAndDeleteExistingFailedAction {
	type: CREATE_AND_DELETE_EXISTING_FAILED;
	error: Error;
}

export interface UpdateAction {
	type: UPDATE;
	episodicId: number;
	changeScene: Partial<HaChangeScene>;
}

export interface UpdateCompleteAction {
	type: UPDATE_COMPLETE;
	updated: HaChangeScene;
}

export interface UpdateFailedAction {
	type: UPDATE_FAILED;
	error: Error;
}

export function createComplete(
	haChangeScenes: HaChangeScene[]
): CreateCompleteAction {
	return {
		haChangeScenes,
		type: CREATE_COMPLETE,
	};
}

export function deleteComplete(
	haChangeScenes: HaChangeScene[]
): DeleteCompleteAction {
	return {
		haChangeScenes,
		type: DELETE_COMPLETE,
	};
}

export function getHaChangeScenesForScene(
	prodId: number,
	sceneId: number
): GetHaChangeScenesForSceneAction {
	return {
		prodId,
		sceneId,
		type: GET_HA_CHANGE_SCENES_FOR_SCENE,
	};
}

export function getHaChangeScenesForSceneComplete(
	haChangeScenes: HaChangeScene[],
	sceneId: number
): GetHaChangeScenesForSceneCompleteAction {
	return {
		haChangeScenes,
		sceneId,
		type: GET_HA_CHANGE_SCENES_FOR_SCENE_COMPLETE,
	};
}

export function getHaChangeScenesForSceneFailed(
	error: Error
): GetHaChangeScenesForSceneFailedAction {
	return {
		error,
		type: GET_HA_CHANGE_SCENES_FOR_SCENE_FAILED,
	};
}

export function createAndDeleteExisting(
	episodicId: number,
	changeScenes: CreateDeleteChangeScene[]
): CreateAndDeleteExistingAction {
	return {
		episodicId,
		changeScenes,
		type: CREATE_AND_DELETE_EXISTING,
	};
}

export function createAndDeleteExistingComplete(
	created: { HaChangeScene: HaChangeScene[] },
	deleted: { HaChangeScene: HaChangeScene[] },
	updated: { HaChange: HaChange[]; HaChangeScene: HaChangeScene[] }
): CreateAndDeleteExistingCompleteAction {
	return {
		created,
		deleted,
		updated,
		type: CREATE_AND_DELETE_EXISTING_COMPLETE,
	};
}

export function createAndDeleteExistingFailed(
	error: Error
): CreateAndDeleteExistingFailedAction {
	return {
		error,
		type: CREATE_AND_DELETE_EXISTING_FAILED,
	};
}

export function update(
	episodicId: number,
	changeScene: Partial<HaChangeScene>
): UpdateAction {
	return {
		episodicId,
		changeScene,
		type: UPDATE,
	};
}

export function updateComplete(updated: HaChangeScene): UpdateCompleteAction {
	return {
		updated,
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function listComplete(
	haChangeScenes: HaChangeScene[]
): ListCompleteAction {
	return {
		haChangeScenes,
		type: LIST_COMPLETE,
	};
}

export function listForEpChar(
	episodicId: number,
	epCharId: number
): ListForEpCharAction {
	return {
		episodicId,
		epCharId,
		type: LIST_FOR_EP_CHAR,
	};
}

export function listForEpCharComplete(
	haChangeScenes: HaChangeScene[]
): ListForEpCharCompleteAction {
	return {
		haChangeScenes,
		type: LIST_FOR_EP_CHAR_COMPLETE,
	};
}

export function listForEpCharFailed(error: Error): ListForEpCharFailedAction {
	return {
		error,
		type: LIST_FOR_EP_CHAR_FAILED,
	};
}

export type Action =
	| CreateCompleteAction
	| DeleteCompleteAction
	| GetHaChangeScenesForSceneAction
	| GetHaChangeScenesForSceneCompleteAction
	| GetHaChangeScenesForSceneFailedAction
	| ListCompleteAction
	| ListForEpCharAction
	| ListForEpCharCompleteAction
	| ListForEpCharFailedAction
	| CreateAndDeleteExistingAction
	| CreateAndDeleteExistingCompleteAction
	| CreateAndDeleteExistingFailedAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction;
