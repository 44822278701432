import { Reducer } from 'redux';
import { UserSetting } from 'sos-models';
import * as actions from './user-setting.actions';
import { userSettingSchema } from '../schemas';
import {
	ActionStatus,
	createReducer,
	failed,
	getComplete,
	loading,
	NormalizedEntityMapping,
	updateComplete,
} from '../utils';

export interface State {
	createStatus: ActionStatus;
	currentId: number;
	deleteStatus: ActionStatus;
	entities: NormalizedEntityMapping<UserSetting>;
	getStatus: ActionStatus;
	ids: number[];
	listStatus: ActionStatus;
	updateStatus: ActionStatus;
}

const initial: State = {
	createStatus: ActionStatus.Inactive,
	currentId: null,
	deleteStatus: ActionStatus.Inactive,
	entities: {},
	getStatus: ActionStatus.Inactive,
	ids: [],
	listStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
};

export const reducer: Reducer<State> = createReducer<State, actions.Action>(
	initial,
	{
		[actions.GET_COMPLETE]: getComplete<
			UserSetting,
			State,
			actions.GetCompleteAction
		>('userSettings', userSettingSchema),
		[actions.GET_FAILED]: failed<State>('getStatus'),
		[actions.GET]: loading<State>('getStatus'),
		[actions.UPDATE_COMPLETE]: updateComplete<
			UserSetting,
			State,
			actions.UpdateCompleteAction
		>('userSetting', userSettingSchema),
		[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
		[actions.UPDATE]: loading<State>('updateStatus'),
	}
);
