import { call, put, takeLatest } from 'redux-saga/effects';
import { SetPiecePictureDeleteResponse } from 'sos-models';
import * as actions from './set-piece-picture.actions';
import * as api from './set-piece-picture.api';
import * as logger from '../../logger';

export function* destroy({
	setPiecePictureId,
	episodicId,
}: actions.DestroyAction) {
	try {
		const result: SetPiecePictureDeleteResponse = yield call(
			api.destroy,
			episodicId,
			setPiecePictureId
		);
		yield put(actions.destroyComplete(result.destroyed.SetPiecePicture));
	} catch (err) {
		yield put(actions.destroyFailed(err));
		logger.responseError(err, 'loading the picture', true);
	}
}

function* saga() {
	yield takeLatest(actions.DESTROY, destroy);
}

export default saga;
