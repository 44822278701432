import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import { User } from 'sos-models';

export const useSentry =
	process.env.SOS_ENV !== 'local' || process.env.SENTRY_DEBUG === 'true';

export function log(msg: string | Error) {
	if (
		process.env.CI !== 'true' &&
		(process.env.SOS_ENV === 'local' || process.env.SOS_ENV === 'dev')
	) {
		console.log(msg); // tslint:disable-line
	}
}

export function setEpisodic(episodicId: number) {
	if (process.env.CI !== 'true' && useSentry) {
		Sentry.configureScope((scope) => {
			scope.setTag('episodic_id', episodicId ? episodicId.toString() : null);
		});
	}
}

export function setSelectedDepartments(departments: number[]) {
	if (process.env.CI !== 'true' && useSentry) {
		Sentry.configureScope((scope) => {
			scope.setExtra(
				'departments',
				departments && departments.length ? JSON.stringify(departments) : null
			);
		});
	}
}

export function setSelectedProductions(productionIds: number[]) {
	if (process.env.CI !== 'true' && useSentry) {
		Sentry.configureScope((scope) => {
			scope.setExtra(
				'production_ids',
				productionIds && productionIds.length
					? JSON.stringify(productionIds)
					: null
			);
		});
	}
}

export function setUser(user: User) {
	if (process.env.CI !== 'true' && useSentry) {
		Sentry.configureScope((scope) => {
			scope.setUser({
				id: user.id.toString(),
				email: user.email,
			});
		});
	}
}

export function unsetUser() {
	if (process.env.CI !== 'true' && useSentry) {
		Sentry.configureScope((scope) => scope.setUser(null));
	}
}

export function error(err: string | Error, data?: object) {
	if (process.env.CI !== 'true') {
		if (useSentry) {
			Sentry.withScope((scope) => {
				scope.setExtras({data});
				Sentry.captureException(err);
			});
		}

		if (process.env.SOS_ENV === 'local' || process.env.SOS_ENV === 'dev') {
			console.error(err); // tslint:disable-line
		}
	}
}

/**
 * Handles saga Errors
 */
export function responseError(err, errMessage: string, needRefresh?: boolean) {
	// tslint:disable-line
	if (err.response) {
		toast.error(
			`There was an error ${errMessage}. Please ${
				needRefresh ? 'refresh to ' : ''
			}try again.`
		);
	} else {
		// the error is not from the ajax call
		error(err);
	}
}
