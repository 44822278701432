import { normalize } from 'normalizr';
import { CmCategory } from 'sos-models';
import * as cmCategoryActions from './cm-category.actions';
import * as inventorySatelliteActions from '../inventory-satellites/inventory-satellite.actions';
import * as inventoryActions from '../inventory/inventory.actions';
import { cmCategorySchema } from '../schemas';
import {
	ActionStatus,
	createComplete,
	createReducer,
	destroyComplete,
	failed,
	loading,
	updateComplete,
} from '../utils';

export interface State {
	entities: { [id: number]: CmCategory };
	ids: number[];
	createStatus: ActionStatus;
	destroyStatus: ActionStatus;
	listStatus: ActionStatus;
	getStatus: ActionStatus;
	updateStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	createStatus: ActionStatus.Inactive,
	destroyStatus: ActionStatus.Inactive,
	listStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	| cmCategoryActions.Action
	| inventoryActions.Action
	| inventorySatelliteActions.Action
>(initial, {
	[inventorySatelliteActions.LIST]: loading<State>('listStatus'),
	[inventorySatelliteActions.LIST_COMPLETE]: listComplete,
	[inventorySatelliteActions.LIST_FAILED]: failed<State>('listStatus'),
	[cmCategoryActions.CREATE]: loading<State>('createStatus'),
	[cmCategoryActions.CREATE_COMPLETE]: createComplete<
		CmCategory,
		State,
		cmCategoryActions.CreateCompleteAction
	>('cmCategory', cmCategorySchema),
	[cmCategoryActions.CREATE_FAILED]: failed<State>('createStatus'),
	[cmCategoryActions.DESTROY]: loading<State>('destroyStatus'),
	[cmCategoryActions.DESTROY_COMPLETE]: destroyComplete<
		CmCategory,
		State,
		cmCategoryActions.DestroyCompleteAction
	>('cmCategory'),
	[cmCategoryActions.DESTROY_FAILED]: failed<State>('destroyStatus'),
	[cmCategoryActions.UPDATE]: loading<State>('updateStatus'),
	[cmCategoryActions.UPDATE_COMPLETE]: updateComplete<
		CmCategory,
		State,
		cmCategoryActions.UpdateCompleteAction
	>('cmCategory', cmCategorySchema),
	[cmCategoryActions.UPDATE_FAILED]: failed<State>('updateStatus'),
});

function listComplete(
	state: State,
	action: inventorySatelliteActions.ListCompleteAction
): State {
	if (!action.inventorySatellites.cmCategories) {
		return {
			...state,
			listStatus: ActionStatus.Complete,
		};
	}
	const normalized = normalize(action.inventorySatellites.cmCategories, [
		cmCategorySchema,
	]);
	return {
		...state,
		entities: normalized.entities.cmCategories,
		ids: normalized.result,
		listStatus: ActionStatus.Complete,
	};
}
