import {
	DEPARTMENTS,
	DeptSpecificChangeLookDeleteResponse,
	DeptSpecificChangeLookGetResponse,
	HaChange,
	HaChangeScene,
	HaChangeUpdateResponse,
} from 'sos-models';
import axios from '../../config/axios';

export async function getForEpChar(
	episodicId: number,
	epCharId: number
): Promise<DeptSpecificChangeLookGetResponse<DEPARTMENTS.HA>> {
	const { data } = await axios.get<
		DeptSpecificChangeLookGetResponse<DEPARTMENTS.HA>
	>(`/api/episodics/${episodicId}/episodic-characters/${epCharId}/ha-changes`);
	return data;
}

export async function deleteForEpChar(
	episodicId: number,
	epCharId: number,
	haChangeId: number
): Promise<DeptSpecificChangeLookDeleteResponse<DEPARTMENTS.HA>> {
	const { data } = await axios.delete(
		`/api/episodics/${episodicId}/episodic-characters/${epCharId}/ha-changes/${haChangeId}`
	);
	return data;
}

export async function getHaChangesForScene(
	prodId: number,
	sceneId: number
): Promise<DeptSpecificChangeLookGetResponse<DEPARTMENTS.HA>> {
	const { data } = await axios.get<
		DeptSpecificChangeLookGetResponse<DEPARTMENTS.HA>
	>(`/api/productions/${prodId}/scenes/${sceneId}/ha-changes`);
	return data;
}

export async function create(
	episodicId: number,
	department: DEPARTMENTS,
	change: Partial<HaChange>
): Promise<Partial<HaChange>> {
	const { data } = await axios.post<Partial<HaChange>>(
		`/api/episodics/${episodicId}/change-looks/${department}`,
		change
	);
	return data;
}

export async function createByScene(
	episodicId: number,
	department: DEPARTMENTS,
	change: Partial<HaChange>,
	sceneId: number,
	oldChange?: HaChange
): Promise<{
	created: { HaChange: HaChange[]; HaChangeScene: HaChangeScene[] };
	deleted: { HaChangeScene: HaChangeScene[] };
	updated: { HaChange: HaChange[] };
}> {
	const {
		data,
	} = await axios.post(
		`/api/episodics/${episodicId}/change-looks/${department}/scenes/${sceneId}`,
		{ change, oldChange }
	);
	return data;
}

export async function update(
	episodicId: number,
	prodId: number,
	haChanges: Partial<HaChange>[]
): Promise<HaChangeUpdateResponse> {
	const { data } = await axios.put(
		`/api/episodics/${episodicId}/productions/${prodId}/ha-changes`,
		haChanges
	);
	return data;
}
