import { Sorting } from '@devexpress/dx-react-grid';
import {
	DEPARTMENTS,
	DeptSpecificChangeLookDeleteResponse,
	MuChange,
	MuChangeScene,
} from 'sos-models';

export const GET = '[MuChange] Get';
type GET = typeof GET;

export const GET_COMPLETE = '[MuChange] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const GET_FAILED = '[MuChange] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export const LIST_COMPLETE = '[MuChange] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const DELETE_COMPLETE = '[MuChange] Delete Complete';
type DELETE_COMPLETE = typeof DELETE_COMPLETE;

export const UPDATE = '[MuChange] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[MuChange] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[MuChange] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const GET_MU_CHANGES_FOR_CHANGE_LOOK =
	'[MuChange] Get MuChanges for Change Look';
type GET_MU_CHANGES_FOR_CHANGE_LOOK = typeof GET_MU_CHANGES_FOR_CHANGE_LOOK;

export const GET_MU_CHANGES_FOR_CHANGE_LOOK_COMPLETE =
	'[MuCMunge] Get MuChanges For Change Look Complete';
type GET_MU_CHANGES_FOR_CHANGE_LOOK_COMPLETE = typeof GET_MU_CHANGES_FOR_CHANGE_LOOK_COMPLETE;

export const GET_MU_CHANGES_FOR_CHANGE_LOOK_FAILED =
	'[MuChange] Get MuChanges For Change Look Failed';
type GET_MU_CHANGES_FOR_CHANGE_LOOK_FAILED = typeof GET_MU_CHANGES_FOR_CHANGE_LOOK_FAILED;

export const GET_MU_CHANGES_FOR_EP_CHAR =
	'[MuChange] Get MuChanges For Ep Char';
type GET_MU_CHANGES_FOR_EP_CHAR = typeof GET_MU_CHANGES_FOR_EP_CHAR;

export const GET_MU_CHANGES_FOR_EP_CHAR_COMPLETE =
	'[MuChange] Get MuChanges For Ep Char Complete';
type GET_MU_CHANGES_FOR_EP_CHAR_COMPLETE = typeof GET_MU_CHANGES_FOR_EP_CHAR_COMPLETE;

export const GET_MU_CHANGES_FOR_EP_CHAR_FAILED =
	'[MuChange] Get MuChanges For Ep Char Failed';
type GET_MU_CHANGES_FOR_EP_CHAR_FAILED = typeof GET_MU_CHANGES_FOR_EP_CHAR_FAILED;

export const DELETE_MU_CHANGE_FOR_EP_CHAR =
	'[MuChange] Delete Mu Change For Ep Char';
type DELETE_MU_CHANGE_FOR_EP_CHAR = typeof DELETE_MU_CHANGE_FOR_EP_CHAR;

export const DELETE_MU_CHANGE_FOR_EP_CHAR_COMPLETE =
	'[MuChange] Delete Mu Change For Ep Char Complete';
type DELETE_MU_CHANGE_FOR_EP_CHAR_COMPLETE = typeof DELETE_MU_CHANGE_FOR_EP_CHAR_COMPLETE;

export const DELETE_MU_CHANGE_FOR_EP_CHAR_FAILED =
	'[MuChange] Delete Mu Change For Ep Char Failed';
type DELETE_MU_CHANGE_FOR_EP_CHAR_FAILED = typeof DELETE_MU_CHANGE_FOR_EP_CHAR_FAILED;

export const GET_MU_CHANGES_FOR_SCENE = '[MuChange] Get MuChanges For Scene';
type GET_MU_CHANGES_FOR_SCENE = typeof GET_MU_CHANGES_FOR_SCENE;

export const GET_MU_CHANGES_FOR_SCENE_COMPLETE =
	'[MuChange] Get MuChanges For Scene Complete';
type GET_MU_CHANGES_FOR_SCENE_COMPLETE = typeof GET_MU_CHANGES_FOR_SCENE_COMPLETE;

export const GET_MU_CHANGES_FOR_SCENE_FAILED =
	'[MuChange] Get MuChanges For Scene Failed';
type GET_MU_CHANGES_FOR_SCENE_FAILED = typeof GET_MU_CHANGES_FOR_SCENE_FAILED;

export const CREATE_MU_CHANGE = '[MuChange] Create MuChange';
type CREATE_MU_CHANGE = typeof CREATE_MU_CHANGE;

export const CREATE_MU_CHANGE_COMPLETE = '[MuChange] Create MuChange Complete';
type CREATE_MU_CHANGE_COMPLETE = typeof CREATE_MU_CHANGE_COMPLETE;

export const CREATE_MU_CHANGE_FAILED = '[MuChange] Create MuChange Failed';
type CREATE_MU_CHANGE_FAILED = typeof CREATE_MU_CHANGE_FAILED;

export const CREATE_MU_CHANGE_BY_SCENE = '[MuChange] Create MuChange By Scene';
type CREATE_MU_CHANGE_BY_SCENE = typeof CREATE_MU_CHANGE_BY_SCENE;

export const CREATE_MU_CHANGE_BY_SCENE_COMPLETE =
	'[MuChange] Create MuChange By Scene Complete';
type CREATE_MU_CHANGE_BY_SCENE_COMPLETE = typeof CREATE_MU_CHANGE_BY_SCENE_COMPLETE;

export const CREATE_MU_CHANGE_BY_SCENE_FAILED =
	'[MuChange] Create MuChange By Scene Failed';
type CREATE_MU_CHANGE_BY_SCENE_FAILED = typeof CREATE_MU_CHANGE_BY_SCENE_FAILED;

export const SET_SORTING = '[MuChange] Set Sorting';
type SET_SORTING = typeof SET_SORTING;

export interface GetAction {
	muChangeId: number;
	type: GET;
}

export interface GetCompleteAction {
	muChange: MuChange;
	type: GET_COMPLETE;
}

export interface GetFailedAction {
	error: Error;
	type: GET_FAILED;
}

export interface ListCompleteAction {
	muChanges: MuChange[];
	type: LIST_COMPLETE;
}

export interface DeleteCompleteAction {
	type: DELETE_COMPLETE;
	muChanges: MuChange[];
}

export interface UpdateAction {
	type: UPDATE;
	episodicId: number;
	prodId: number;
	muChanges: Partial<MuChange>[];
}

export interface UpdateCompleteAction {
	type: UPDATE_COMPLETE;
	muChanges: MuChange[];
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export interface GetMuChangesForChangeLookAction {
	changeLookId: number;
	type: GET_MU_CHANGES_FOR_CHANGE_LOOK;
}

export interface GetMuChangesForChangeLookCompleteAction {
	muChanges: MuChange[];
	type: GET_MU_CHANGES_FOR_CHANGE_LOOK_COMPLETE;
}

export interface GetMuChangesForChangeLookFailedAction {
	error: Error;
	type: GET_MU_CHANGES_FOR_CHANGE_LOOK_FAILED;
}

export interface GetMuChangesForSceneAction {
	type: GET_MU_CHANGES_FOR_SCENE;
	prodId: number;
	sceneId: number;
}

export interface GetMuChangesForSceneCompleteAction {
	type: GET_MU_CHANGES_FOR_SCENE_COMPLETE;
	muChanges: MuChange[];
	prodId: number;
}

export interface GetMuChangesForSceneFailedAction {
	type: GET_MU_CHANGES_FOR_SCENE_FAILED;
	error: Error;
}

export interface GetMuChangesForEpCharAction {
	type: GET_MU_CHANGES_FOR_EP_CHAR;
	episodicId: number;
	epCharId: number;
}

export interface GetMuChangesForEpCharCompleteAction {
	type: GET_MU_CHANGES_FOR_EP_CHAR_COMPLETE;
	muChanges: MuChange[];
	epCharId: number;
}

export interface GetMuChangesForEpCharFailedAction {
	type: GET_MU_CHANGES_FOR_EP_CHAR_FAILED;
	error: Error;
}

export interface DeleteMuChangeForEpCharAction {
	type: DELETE_MU_CHANGE_FOR_EP_CHAR;
	episodicId: number;
	epCharId: number;
	muChangeId: number;
}

export interface DeleteMuChangeForEpCharCompleteAction {
	type: DELETE_MU_CHANGE_FOR_EP_CHAR_COMPLETE;
	epCharId: number;
	response: DeptSpecificChangeLookDeleteResponse<DEPARTMENTS.MU>;
}

export interface DeleteMuChangeForEpCharFailedAction {
	type: DELETE_MU_CHANGE_FOR_EP_CHAR_FAILED;
	error: Error;
}

export interface CreateMuChangeAction {
	type: CREATE_MU_CHANGE;
	episodicId: number;
	change: Partial<MuChange>;
}

export interface CreateMuChangeCompleteAction {
	type: CREATE_MU_CHANGE_COMPLETE;
	change: MuChange[];
}

export interface CreateMuChangeFailedAction {
	type: CREATE_MU_CHANGE_FAILED;
	error: Error;
}

export interface CreateMuChangeBySceneAction {
	type: CREATE_MU_CHANGE_BY_SCENE;
	episodicId: number;
	change: Partial<MuChange>;
	oldChange?: MuChange;
	sceneId: number;
}

export interface CreateMuChangeBySceneCompleteAction {
	type: CREATE_MU_CHANGE_BY_SCENE_COMPLETE;
	created: {
		MuChange: MuChange[];
		MuChangeScene: MuChangeScene[];
	};
	deleted: {
		MuChangeScene: MuChangeScene[];
	};
	updated: {
		MuChange: MuChange[];
	};
}

export interface CreateMuChangeBySceneFailedAction {
	type: CREATE_MU_CHANGE_BY_SCENE_FAILED;
	error: Error;
}

export interface SetSortingAction {
	sorting: Sorting[];
	type: SET_SORTING;
}

export function get(muChangeId: number): GetAction {
	return {
		muChangeId,
		type: GET,
	};
}

export function getComplete(muChange: MuChange): GetCompleteAction {
	return {
		muChange,
		type: GET_COMPLETE,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	};
}

export function listComplete(muChanges: MuChange[]): ListCompleteAction {
	return {
		muChanges,
		type: LIST_COMPLETE,
	};
}

export function deleteComplete(muChanges: MuChange[]): DeleteCompleteAction {
	return {
		muChanges,
		type: DELETE_COMPLETE,
	};
}

export function update(
	episodicId: number,
	prodId: number,
	muChanges: Partial<MuChange>[]
): UpdateAction {
	return {
		episodicId,
		prodId,
		muChanges,
		type: UPDATE,
	};
}

export function updateComplete(muChanges: MuChange[]): UpdateCompleteAction {
	return {
		muChanges,
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function getMuChangesForChangeLook(
	changeLookId: number
): GetMuChangesForChangeLookAction {
	return {
		changeLookId,
		type: GET_MU_CHANGES_FOR_CHANGE_LOOK,
	};
}

export function getMuChangesForChangeLookComplete(
	muChanges: MuChange[]
): GetMuChangesForChangeLookCompleteAction {
	return {
		muChanges,
		type: GET_MU_CHANGES_FOR_CHANGE_LOOK_COMPLETE,
	};
}

export function getMuChangesForChangeLookFailedAction(
	error: Error
): GetMuChangesForChangeLookFailedAction {
	return {
		error,
		type: GET_MU_CHANGES_FOR_CHANGE_LOOK_FAILED,
	};
}

export function getMuChangesForScene(
	prodId: number,
	sceneId: number
): GetMuChangesForSceneAction {
	return {
		prodId,
		sceneId,
		type: GET_MU_CHANGES_FOR_SCENE,
	};
}

export function getMuChangesForSceneComplete(
	muChanges: MuChange[],
	prodId: number
): GetMuChangesForSceneCompleteAction {
	return {
		muChanges,
		prodId,
		type: GET_MU_CHANGES_FOR_SCENE_COMPLETE,
	};
}

export function getMuChangesForSceneFailed(
	error: Error
): GetMuChangesForSceneFailedAction {
	return {
		error,
		type: GET_MU_CHANGES_FOR_SCENE_FAILED,
	};
}

export function getMuChangesForEpChar(
	episodicId: number,
	epCharId: number
): GetMuChangesForEpCharAction {
	return {
		episodicId,
		epCharId,
		type: GET_MU_CHANGES_FOR_EP_CHAR,
	};
}

export function getMuChangesForEpCharComplete(
	muChanges: MuChange[],
	epCharId: number
): GetMuChangesForEpCharCompleteAction {
	return {
		muChanges,
		epCharId,
		type: GET_MU_CHANGES_FOR_EP_CHAR_COMPLETE,
	};
}

export function getMuChangesForEpCharFailed(
	error: Error
): GetMuChangesForEpCharFailedAction {
	return {
		error,
		type: GET_MU_CHANGES_FOR_EP_CHAR_FAILED,
	};
}

export function deleteMuChangeForEpChar(
	episodicId: number,
	epCharId: number,
	muChangeId: number
): DeleteMuChangeForEpCharAction {
	return {
		episodicId,
		epCharId,
		muChangeId,
		type: DELETE_MU_CHANGE_FOR_EP_CHAR,
	};
}

export function deleteMuChangeForEpCharComplete(
	epCharId: number,
	response: DeptSpecificChangeLookDeleteResponse<DEPARTMENTS.MU>
): DeleteMuChangeForEpCharCompleteAction {
	return {
		epCharId,
		response,
		type: DELETE_MU_CHANGE_FOR_EP_CHAR_COMPLETE,
	};
}

export function deleteMuChangeForEpCharFailed(
	error: Error
): DeleteMuChangeForEpCharFailedAction {
	return {
		error,
		type: DELETE_MU_CHANGE_FOR_EP_CHAR_FAILED,
	};
}

export function createMuChange(
	episodicId: number,
	change: Partial<MuChange>
): CreateMuChangeAction {
	return {
		episodicId,
		change,
		type: CREATE_MU_CHANGE,
	};
}

export function createMuChangeComplete(
	change: MuChange[]
): CreateMuChangeCompleteAction {
	return {
		change,
		type: CREATE_MU_CHANGE_COMPLETE,
	};
}

export function createMuChangeFailed(error: Error): CreateMuChangeFailedAction {
	return {
		error,
		type: CREATE_MU_CHANGE_FAILED,
	};
}

export function createChangeByScene(
	episodicId: number,
	change: Partial<MuChange>,
	sceneId: number,
	oldChange?: MuChange
): CreateMuChangeBySceneAction {
	return {
		episodicId,
		change,
		sceneId,
		oldChange,
		type: CREATE_MU_CHANGE_BY_SCENE,
	};
}

export function createMuChangeBySceneComplete(
	created: { MuChange: MuChange[]; MuChangeScene: MuChangeScene[] },
	deleted: { MuChangeScene: MuChangeScene[] },
	updated: { MuChange: MuChange[] }
): CreateMuChangeBySceneCompleteAction {
	return {
		created,
		deleted,
		updated,
		type: CREATE_MU_CHANGE_BY_SCENE_COMPLETE,
	};
}

export function createChangeBySceneFailed(
	error: Error
): CreateMuChangeBySceneFailedAction {
	return {
		error,
		type: CREATE_MU_CHANGE_BY_SCENE_FAILED,
	};
}

export function setSorting(sorting: Sorting[]): SetSortingAction {
	return {
		sorting,
		type: SET_SORTING,
	};
}

export type Action =
	| GetAction
	| GetCompleteAction
	| GetFailedAction
	| ListCompleteAction
	| DeleteCompleteAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction
	| GetMuChangesForChangeLookAction
	| GetMuChangesForChangeLookCompleteAction
	| GetMuChangesForChangeLookFailedAction
	| GetMuChangesForEpCharAction
	| GetMuChangesForEpCharCompleteAction
	| GetMuChangesForEpCharFailedAction
	| GetMuChangesForSceneAction
	| GetMuChangesForSceneCompleteAction
	| GetMuChangesForSceneFailedAction
	| DeleteMuChangeForEpCharAction
	| DeleteMuChangeForEpCharCompleteAction
	| DeleteMuChangeForEpCharFailedAction
	| CreateMuChangeAction
	| CreateMuChangeCompleteAction
	| CreateMuChangeFailedAction
	| CreateMuChangeBySceneAction
	| CreateMuChangeBySceneCompleteAction
	| CreateMuChangeBySceneFailedAction
	| SetSortingAction;
