import { PROP_SCENE_COLUMNS, TableColumn } from 'sos-models';
import * as actions from './prop-scene-table-column.actions';
import {
	defaultHidden,
	PROP_SCENE_COLUMNS_ARRAY,
} from './prop-scene-table-columns.data';
import { createColumnReducer } from '../column.reducer';

export interface State {
	ids: string[];
	hidden: string[];
	widths: { [name: string]: TableColumn };
}

const initial: State = {
	ids: PROP_SCENE_COLUMNS_ARRAY.map((col) => col.canonical_name),
	hidden: defaultHidden,
	widths: PROP_SCENE_COLUMNS,
};

export const reducer = createColumnReducer(initial, actions);
