import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { pluralize } from 'sos-models';
import * as actions from './asset-hub.actions';
import * as api from './asset-hub.api';
import * as logger from '../../logger';
import { inventoryActions } from '../actions';

export function* exportToAssetHub({
	episodicId,
	payload,
}: actions.ExportToAssetHubAction) {
	try {
		const results = yield call(api.exportToAssetHub, episodicId, payload);
		if (results) {
			yield put(actions.exportToAssetHubComplete());

			let invExportedCount = 0;
			if (results.updated.EpisodicItem) {
				yield put(
					inventoryActions.updateComplete(results.updated.EpisodicItem)
				);
				invExportedCount += results.updated.EpisodicItem.length;
			}
			if (results.updated.EpisodicProp) {
				yield put(
					inventoryActions.updateComplete(results.updated.EpisodicProp)
				);
				invExportedCount += results.updated.EpisodicProp.length;
			}
			if (results.updated.SetPiece) {
				yield put(inventoryActions.updateComplete(results.updated.SetPiece));
				invExportedCount += results.updated.SetPiece.length;
			}

			toast.success(
				`Successfully exported ${invExportedCount} inventory ${pluralize(
					'piece',
					invExportedCount
				)} to AssetHub.`
			);
		}
	} catch (err) {
		yield put(actions.exportToAssetHubFailed(err));
		if (err && err.response && err.response.status === 504) {
			toast.info(
				'Your export is taking longer than expected. We are still working on it, your assets will appear in Asset Hub soon.',
				{ autoClose: false }
			);
		} else if (err.response) {
			toast.error(
				'There was an error exporting these pieces of inventory to AssetHub.'
			);
		} else {
			logger.error(err);
		}
	}
}

export function* getPermissions({ episodicId }: actions.GetPermissionsAction) {
	try {
		const result = yield call(api.getPermissions, episodicId);
		if (result) {
			yield put(actions.getPermissionsComplete(result));
		}
	} catch (err) {
		yield put(actions.getPermissionsFailed(err));
		if (err.response) {
			toast.error(
				'There was an error checking AssetHub Permissions. Please refresh to try again.'
			);
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeEvery(actions.EXPORT_TO_ASSET_HUB, exportToAssetHub);
	yield takeLatest(actions.GET_PERMISSIONS, getPermissions);
}

export default saga;
