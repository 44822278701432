import { call, put, takeLatest } from 'redux-saga/effects';
import {
	EpisodicCharacterPictureDeleteResponse,
	EpisodicCharacterPictureForEpisodicListReponse,
	EpisodicCharacterPictureListResponse,
} from 'sos-models';
import * as actions from './episodic-character-picture.actions';
import * as api from './episodic-character-picture.api';
import * as logger from '../../logger';
import {
	cmChangePictureActions,
	haLookPictureActions,
	muLookPictureActions,
	pictureActions,
	pictureSceneActions,
} from '../actions';

export function* listForEpisodic({
	episodicId,
}: actions.ListForEpisodicAction) {
	try {
		const {
			listed,
		}: EpisodicCharacterPictureForEpisodicListReponse = yield call(
			api.listForEpisodic,
			episodicId
		);
		if (listed.EpisodicCharacterPicture) {
			yield put(
				actions.listForEpisodicComplete(listed.EpisodicCharacterPicture)
			);
		}
	} catch (err) {
		yield put(actions.listForEpisodicFailed(err));
		logger.responseError(err, 'loading the pictures', true);
	}
}

export function* destroy({
	episodicCharacterPictureId,
	episodicId,
}: actions.DestroyAction) {
	try {
		const result: EpisodicCharacterPictureDeleteResponse = yield call(
			api.destroy,
			episodicId,
			episodicCharacterPictureId
		);
		yield put(
			actions.destroyComplete(result.destroyed.EpisodicCharacterPicture)
		);
	} catch (err) {
		yield put(actions.destroyFailed(err));
		logger.responseError(err, 'loading the picture', true);
	}
}

export function* listForEpChar({
	episodicId,
	epCharId,
}: actions.ListForEpCharAction) {
	try {
		const result: EpisodicCharacterPictureListResponse = yield call(
			api.listForEpChar,
			episodicId,
			epCharId
		);
		if (result.listed.EpisodicCharacterPicture) {
			yield put(
				actions.listForEpCharComplete(result.listed.EpisodicCharacterPicture)
			);
		}
		if (result.listed.Picture) {
			yield put(pictureActions.listComplete(result.listed.Picture));
		}
		if (result.listed.CmChangePicture) {
			yield put(
				cmChangePictureActions.listComplete(result.listed.CmChangePicture)
			);
		}
		if (result.listed.HaLookPicture) {
			yield put(haLookPictureActions.listComplete(result.listed.HaLookPicture));
		}
		if (result.listed.MuLookPicture) {
			yield put(muLookPictureActions.listComplete(result.listed.MuLookPicture));
		}
		if (result.listed.PictureScene) {
			yield put(pictureSceneActions.listComplete(result.listed.PictureScene));
		}
	} catch (err) {
		yield put(actions.listForEpCharFailed(err));
		logger.responseError(err, 'loading the episodic character pictures', true);
	}
}

function* saga() {
	yield takeLatest(actions.LIST_FOR_EPISODIC, listForEpisodic);
	yield takeLatest(actions.DESTROY, destroy);
	yield takeLatest(actions.LIST_FOR_EP_CHAR, listForEpChar);
}

export default saga;
