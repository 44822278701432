import { toast } from 'react-toastify';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import * as actions from './activity-monitor.actions';
import * as api from './activity-monitor.api';
import * as logger from '../../logger';

export function* active() {
	try {
		yield call(api.heartbeat);
	} catch (err) {
		if (err.response) {
			toast.error('There was an error with continuing the session.');
		} else {
			logger.responseError(err, 'heartbeat', true);
		}
	}
}

export function* trackActivitySaga() {
	// const sixtySeconds = 60000;
	const twentyMin = 1200000;
	// const tenMin = 600000;
	yield delay(twentyMin);
	yield put({ type: actions.INACTIVE });

	yield delay(780000);
	const response = yield call(api.isSessionActive);
	if (response['data']) {
		yield put({ type: actions.INACTIVE });
	} else {
		yield put({ type: actions.EXPIRED });
	}
}

function* saga() {
	yield takeLatest(actions.ACTIVE, active);
	yield takeLatest(
		(action) =>
			action.type !== actions.INACTIVE && action.type !== actions.EXPIRED,
		trackActivitySaga
	);
}

export default saga;
