import { takeLatest } from 'redux-saga/effects';
import * as actions from './department.actions';
import { IFrameService } from '../../services/iframe.service';

export function* select({ selectedIds }: actions.SelectAction) {
	yield IFrameService.sendMessage({ type: actions.SELECT, selectedIds });
}

function* saga() {
	yield takeLatest(actions.SELECT, select);
}

export default saga;
