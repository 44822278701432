import { ChangeEpisodicItem, EpisodicItem } from 'sos-models';
import { GenPartial } from 'typeormgen';

export const CREATE = '[Change Episodic Item] Create';
type CREATE = typeof CREATE;

export const CREATE_COMPLETE = '[Change Episodic Item] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const CREATE_FAILED = '[Change Episodic Item] Create Failed';
type CREATE_FAILED = typeof CREATE_FAILED;

export const UPDATE = '[Change Episodic Item] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[Change Episodic Item] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[Change Episodic Item] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const CREATE_CHANGE_EPISODIC_ITEM =
	'[Change Episodic Item] Create With New Episodic Item';
type CREATE_CHANGE_EPISODIC_ITEM = typeof CREATE_CHANGE_EPISODIC_ITEM;

export const CREATE_CHANGE_EPISODIC_ITEM_COMPLETE =
	'[Change Episodic Item] Create With New Episodic Item Complete';
type CREATE_CHANGE_EPISODIC_ITEM_COMPLETE = typeof CREATE_CHANGE_EPISODIC_ITEM_COMPLETE;

export const CREATE_CHANGE_EPISODIC_ITEM_FAILED =
	'[Change Episodic Item] Create With New Episodic Item Failed';
type CREATE_CHANGE_EPISODIC_ITEM_FAILED = typeof CREATE_CHANGE_EPISODIC_ITEM_FAILED;

export const DELETE_CHANGE_EPISODIC_ITEM = '[Change Episodic Item] Delete';
type DELETE_CHANGE_EPISODIC_ITEM = typeof DELETE_CHANGE_EPISODIC_ITEM;

export const DELETE_CHANGE_EPISODIC_ITEM_COMPLETE =
	'[Change Episodic Item] Delete Complete';
type DELETE_CHANGE_EPISODIC_ITEM_COMPLETE = typeof DELETE_CHANGE_EPISODIC_ITEM_COMPLETE;

export const DELETE_CHANGE_EPISODIC_ITEM_FAILED =
	'[Change Episodic Item] Delete Failed';
type DELETE_CHANGE_EPISODIC_ITEM_FAILED = typeof DELETE_CHANGE_EPISODIC_ITEM_FAILED;

export const GET_ITEMS_FOR_CHANGE =
	'[Change Episodic Item] Get Items for Change';
type GET_ITEMS_FOR_CHANGE = typeof GET_ITEMS_FOR_CHANGE;

export const GET_ITEMS_FOR_CHANGE_COMPLETE =
	'[Change Episodic Item] Get Items for Change Complete';
type GET_ITEMS_FOR_CHANGE_COMPLETE = typeof GET_ITEMS_FOR_CHANGE_COMPLETE;

export const GET_ITEMS_FOR_CHANGE_FAILED =
	'[Change Episodic Item] Get Items for Change Failed';
type GET_ITEMS_FOR_CHANGE_FAILED = typeof GET_ITEMS_FOR_CHANGE_FAILED;

export const LIST_COMPLETE = '[Change Episodic Item] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export interface CreateAction {
	changeId: number;
	prodId: number;
	episodicId: number;
	episodicItemId: number;
	type: CREATE;
}

export interface CreateCompleteAction {
	changeEpisodicItems: ChangeEpisodicItem[];
	type: CREATE_COMPLETE;
}

export interface CreateFailedAction {
	type: CREATE_FAILED;
	error: Error;
}

export interface UpdateAction {
	changeId: number;
	prodId: number;
	episodicId: number;
	payload: GenPartial<ChangeEpisodicItem>[];
	type: UPDATE;
}

export interface UpdateCompleteAction {
	changeEpisodicItems: ChangeEpisodicItem[];
	type: UPDATE_COMPLETE;
}

export interface UpdateFailedAction {
	type: UPDATE_FAILED;
	error: Error;
}

export interface CreateChangeEpisodicItemAction {
	changeId: number;
	prodId: number;
	episodicId: number;
	episodicItem: EpisodicItem;
	type: CREATE_CHANGE_EPISODIC_ITEM;
}

export interface CreateChangeEpisodicItemCompleteAction {
	changeEpisodicItems: ChangeEpisodicItem[];
	type: CREATE_CHANGE_EPISODIC_ITEM_COMPLETE;
}

export interface CreateChangeEpisodicItemFailedAction {
	type: CREATE_CHANGE_EPISODIC_ITEM_FAILED;
	error: Error;
}

export interface DeleteChangeEpisodicItemAction {
	changeId: number;
	episodicId: number;
	prodId: number;
	episodicItemId: number;
	type: DELETE_CHANGE_EPISODIC_ITEM;
}

export interface DeleteChangeEpisodicItemCompleteAction {
	changeEpisodicItems: ChangeEpisodicItem[];
	type: DELETE_CHANGE_EPISODIC_ITEM_COMPLETE;
}

export interface DeleteChangeEpisodicItemFailedAction {
	type: DELETE_CHANGE_EPISODIC_ITEM_FAILED;
	error: Error;
}

export interface GetEpisodicItemsForChangeAction {
	type: GET_ITEMS_FOR_CHANGE;
	episodicId: number;
	changeId: number;
}

export interface GetEpisodicItemsForChangeCompleteAction {
	type: GET_ITEMS_FOR_CHANGE_COMPLETE;
	changeEpItems: EpisodicItem[];
	changeId: number;
}

export interface GetEpisodicItemsForChangeFailedAction {
	type: GET_ITEMS_FOR_CHANGE_FAILED;
	error: Error;
}

export interface ListCompleteAction {
	changeEpisodicItems: ChangeEpisodicItem[];
	type: LIST_COMPLETE;
}

export function create(
	changeId: number,
	prodId: number,
	episodicId: number,
	episodicItemId: number
): CreateAction {
	return {
		changeId,
		prodId,
		episodicId,
		episodicItemId,
		type: CREATE,
	};
}

export function createComplete(
	changeEpisodicItems: ChangeEpisodicItem[]
): CreateCompleteAction {
	return {
		changeEpisodicItems,
		type: CREATE_COMPLETE,
	};
}

export function createFailed(error: Error): CreateFailedAction {
	return {
		type: CREATE_FAILED,
		error: error,
	};
}

export function update(
	episodicId: number,
	prodId: number,
	changeId: number,
	payload: GenPartial<ChangeEpisodicItem>[]
): UpdateAction {
	return {
		changeId,
		prodId,
		episodicId,
		payload,
		type: UPDATE,
	};
}

export function updateComplete(
	changeEpisodicItems: ChangeEpisodicItem[]
): UpdateCompleteAction {
	return {
		changeEpisodicItems,
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		type: UPDATE_FAILED,
		error: error,
	};
}

export function createChangeEpisodicItem(
	changeId: number,
	prodId: number,
	episodicId: number,
	episodicItem: EpisodicItem
): CreateChangeEpisodicItemAction {
	return {
		changeId,
		prodId,
		episodicId,
		episodicItem,
		type: CREATE_CHANGE_EPISODIC_ITEM,
	};
}

export function createChangeEpisodicItemComplete(
	changeEpisodicItems: ChangeEpisodicItem[]
): CreateChangeEpisodicItemCompleteAction {
	return {
		changeEpisodicItems,
		type: CREATE_CHANGE_EPISODIC_ITEM_COMPLETE,
	};
}

export function createChangeEpisodicItemFailed(
	error: Error
): CreateChangeEpisodicItemFailedAction {
	return {
		type: CREATE_CHANGE_EPISODIC_ITEM_FAILED,
		error: error,
	};
}

export function deleteChangeEpisodicItem(
	changeId: number,
	prodId: number,
	episodicId: number,
	episodicItemId: number
): DeleteChangeEpisodicItemAction {
	return {
		changeId,
		episodicId,
		prodId,
		episodicItemId,
		type: DELETE_CHANGE_EPISODIC_ITEM,
	};
}

export function deleteChangeEpisodicItemComplete(
	changeEpisodicItems: ChangeEpisodicItem[]
): DeleteChangeEpisodicItemCompleteAction {
	return {
		changeEpisodicItems,
		type: DELETE_CHANGE_EPISODIC_ITEM_COMPLETE,
	};
}

export function deleteChangeEpisodicItemFailed(
	error: Error
): DeleteChangeEpisodicItemFailedAction {
	return {
		type: DELETE_CHANGE_EPISODIC_ITEM_FAILED,
		error: error,
	};
}

export function getEpisodicItemsForChange(
	episodicId: number,
	changeId: number
): GetEpisodicItemsForChangeAction {
	return {
		episodicId,
		changeId,
		type: GET_ITEMS_FOR_CHANGE,
	};
}

export function getEpisodicItemsForChangeComplete(
	changeEpItems: EpisodicItem[],
	changeId: number
): GetEpisodicItemsForChangeCompleteAction {
	return {
		changeEpItems,
		changeId,
		type: GET_ITEMS_FOR_CHANGE_COMPLETE,
	};
}

export function getEpisodicItemsForChangeFailed(
	error: Error
): GetEpisodicItemsForChangeFailedAction {
	return {
		error,
		type: GET_ITEMS_FOR_CHANGE_FAILED,
	};
}

export function listComplete(
	changeEpisodicItems: ChangeEpisodicItem[]
): ListCompleteAction {
	return {
		changeEpisodicItems,
		type: LIST_COMPLETE,
	};
}

export type Action =
	| CreateAction
	| CreateCompleteAction
	| CreateFailedAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction
	| CreateChangeEpisodicItemAction
	| CreateChangeEpisodicItemCompleteAction
	| CreateChangeEpisodicItemFailedAction
	| DeleteChangeEpisodicItemAction
	| DeleteChangeEpisodicItemCompleteAction
	| DeleteChangeEpisodicItemFailedAction
	| GetEpisodicItemsForChangeAction
	| GetEpisodicItemsForChangeCompleteAction
	| GetEpisodicItemsForChangeFailedAction
	| ListCompleteAction;
