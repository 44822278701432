import { omit } from 'lodash';
import { FIELD_TYPES, INVENTORY_INDEX_COLUMNS, TableColumn } from 'sos-models';

export const defaultSelections = [
	'main_photo_id',
	'name',
	'description',
	'brand',
	'color',
	'quantity',
	'episodic_character_id',
	'set_ids',
	'status_id',
];

export const defaultHidden = Object.keys(
	omit(INVENTORY_INDEX_COLUMNS, defaultSelections)
);

export interface ColumnData {
	inputCols?: string[];
	selectCols?: string[];
	multiSelectCols?: string[];
	currencyCols?: string[];
	numberCols?: string[];
	dateCols?: string[];
	checkboxCols?: string[];
	textareaCols?: string[];
	colArray: TableColumn[];
}

export const buildColumnArray = (indexColumns: {
	[name: string]: TableColumn;
}): ColumnData => {
	const inputCols = [];
	const selectCols = [];
	const multiSelectCols = [];
	const currencyCols = [];
	const numberCols = [];
	const dateCols = [];
	const checkboxCols = [];
	const textareaCols = [];
	let colArray = [];

	for (const key in indexColumns) {
		if (indexColumns.hasOwnProperty(key)) {
			const col = indexColumns[key];

			colArray.push(col);

			switch (col.type) {
				case FIELD_TYPES.DATE:
					dateCols.push(col.canonical_name);
					break;
				case FIELD_TYPES.INPUT:
					inputCols.push(col.canonical_name);
					break;
				case FIELD_TYPES.NUMBER:
					numberCols.push(col.canonical_name);
					break;
				case FIELD_TYPES.CURRENCY:
					currencyCols.push(col.canonical_name);
					break;
				case FIELD_TYPES.SELECT:
					selectCols.push(col.canonical_name);
					break;
				case FIELD_TYPES.MULTISELECT:
					multiSelectCols.push(col.canonical_name);
					break;
				case FIELD_TYPES.CHECKBOX:
					checkboxCols.push(col.canonical_name);
					break;
				case FIELD_TYPES.TEXTAREA:
					textareaCols.push(col.canonical_name);
					break;
				default:
					break;
			}
		}
	}

	return {
		inputCols,
		selectCols,
		multiSelectCols,
		currencyCols,
		numberCols,
		dateCols,
		checkboxCols,
		textareaCols,
		colArray,
	};
};

const colData = buildColumnArray(INVENTORY_INDEX_COLUMNS);

export const inputColumns = colData.inputCols;
export const selectColumns = colData.selectCols;
export const multiSelectColumns = colData.multiSelectCols;
export const currencyColumns = colData.currencyCols;
export const numberColumns = colData.numberCols;
export const dateColumns = colData.dateCols;
export const checkboxColumns = colData.checkboxCols;
export let INVENTORY_INDEX_COLUMNS_ARRAY = colData.colArray;
