import {
	EpisodicActor,
	EpisodicActorExportToNewSeasonCheckReponse,
	EpisodicActorExportToNewSeasonReponse,
	EpisodicActorGetResponse,
	EpisodicActorListResponse,
	EpisodicActorUpdateNotesReponse,
	HaEpisodicActor,
	moment,
	MuEpisodicActor,
	PartialDeptEpisodicActorNotes,
} from 'sos-models';
import { timeTransformer } from 'typeormgen';
import axios from '../../config/axios';

export async function create(
	episodicId: number,
	episodicActors: Partial<EpisodicActor>[],
	epCharIds: number[]
): Promise<Partial<EpisodicActor>[]> {
	const epActors = convertFittingTime(episodicActors);
	const { data } = await axios.post<Partial<EpisodicActor>[]>(
		`/api/episodics/${episodicId}/episodic-actors`,
		{
			episodicActors: epActors,
			epCharIds,
		}
	);
	return data;
}

export const list = async (
	episodicId: number
): Promise<EpisodicActorListResponse> => {
	const { data } = await axios.get<EpisodicActorListResponse>(
		`/api/episodics/${episodicId}/episodic-actors`
	);
	return data;
}; 

export const exportToNewSeason = async (
	episodicId: number,
	newEpisodicId: number,
	episodicActorIds: number[],
	checkIfExported: boolean
): Promise<
	| EpisodicActorExportToNewSeasonReponse
	| EpisodicActorExportToNewSeasonCheckReponse
> => {
	const { data } = await axios.put<EpisodicActorExportToNewSeasonReponse>(
		`/api/episodics/${episodicId}/episodic-actors/export-to-new-season/${newEpisodicId}`,
		{
			episodicActorIds,
			checkIfExported,
		}
	);
	return data;
};

export const get = async (
	episodicId: number,
	episodicActorId: number
): Promise<EpisodicActorGetResponse> => {
	const { data } = await axios.get<EpisodicActorGetResponse>(
		`/api/episodics/${episodicId}/episodic-actors/${episodicActorId}`
	);
	return data;
};

export async function update(
	episodicId: number,
	episodicActors: Partial<EpisodicActor>[],
	epActorIdToEpCharIds: { [id: number]: number[] },
	haEpisodicActors: Partial<HaEpisodicActor>[],
	muEpisodicActors: Partial<MuEpisodicActor>[]
): Promise<Partial<EpisodicActor>[]> {
	const epActors = convertFittingTime(episodicActors);
	const { data } = await axios.put(
		`/api/episodics/${episodicId}/episodic-actors`,
		{
			episodicActors: epActors,
			epActorIdToEpCharIds,
			haEpisodicActors,
			muEpisodicActors,
		}
	);
	return data;
}

export async function updateNotes(
	episodicId: number,
	episodicActorId: number,
	episodicActorNotes: PartialDeptEpisodicActorNotes
): Promise<EpisodicActorUpdateNotesReponse> {
	const { data } = await axios.put(
		`/api/episodics/${episodicId}/episodic-actors/${episodicActorId}/notes`,
		{
			episodicActorNotes,
		}
	);
	return data;
}

export async function destroy(
	episodicId: number,
	episodicActorId: number
): Promise<EpisodicActor> {
	return axios
		.delete(`/api/episodics/${episodicId}/episodic-actors/${episodicActorId}`)
		.then((response) => response.data);
}

/**
 * Axios calls JSON.stringify on objects in its default requestTransformer, which converts moments to UTC ISO Strings.
 * We want to send this up with the timezone offset since it's stored as the selected local time
 */
function convertFittingTime(episodicActors: Partial<EpisodicActor>[]) {
	const format = 'YYYY-MM-DDTHH:mm:ss.SSS';
	return episodicActors.map((epA) => {
		if (epA.fitting_time) {
			let fitting_time = moment.isMoment(epA.fitting_time)
				? epA.fitting_time.format(format)
				: timeTransformer.from(epA.fitting_time).format(format);
			return { ...epA, fitting_time };
		}
		return epA;
	});
}
