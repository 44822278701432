import { SetScene } from 'sos-models';

export const CREATE_COMPLETE = '[Set Scenes] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const DELETE_COMPLETE = '[Set Scenes] Delete Complete';
type DELETE_COMPLETE = typeof DELETE_COMPLETE;

export const LIST = '[Set Scenes] List';
type LIST = typeof LIST;

export const LIST_COMPLETE = '[Set Scenes] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FAILED = '[Set Scenes] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export interface CreateCompleteAction {
	setScenes: SetScene[];
	type: CREATE_COMPLETE;
}

export interface DeleteCompleteAction {
	setScenes: SetScene[];
	type: DELETE_COMPLETE;
}

export interface ListAction {
	type: LIST;
	episodicId: number;
}

export interface ListCompleteAction {
	type: LIST_COMPLETE;
	setScenes: SetScene[];
}

export interface ListFailedAction {
	type: LIST_FAILED;
	error: Error;
}

export function createComplete(setScenes: SetScene[]): CreateCompleteAction {
	return {
		setScenes,
		type: CREATE_COMPLETE,
	};
}

export function deleteComplete(setScenes: SetScene[]): DeleteCompleteAction {
	return {
		setScenes,
		type: DELETE_COMPLETE,
	};
}

export function list(episodicId: number): ListAction {
	return {
		episodicId,
		type: LIST,
	};
}

export function listComplete(setScenes: SetScene[]): ListCompleteAction {
	return {
		setScenes,
		type: LIST_COMPLETE,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export type Action =
	| CreateCompleteAction
	| DeleteCompleteAction
	| ListAction
	| ListCompleteAction
	| ListFailedAction;
