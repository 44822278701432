import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './prop-status-color.actions';
import * as api from './prop-status-color.api';
import * as logger from '../../logger';

let fetched = false;

export function* list() {
	if (!fetched) {
		try {
			const propStatusColors = yield call(api.list);
			yield put(actions.listComplete(propStatusColors));
			fetched = true;
		} catch (err) {
			yield put(actions.listFailed(err));
			if (err.response) {
				toast.error(
					'There was an error loading status colors.  Please refresh to try again.'
				);
			} else {
				// the error is not from the ajax call
				logger.error(err);
			}
		}
	}
}

function* saga() {
	yield takeLatest(actions.LIST, list);
}

export default saga;
