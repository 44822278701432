import { AHExportPayload, AHDeptPermissionsResponse } from 'sos-models';

export const EXPORT_TO_ASSET_HUB = '[Asset Hub] Export to Asset Hub';
type EXPORT_TO_ASSET_HUB = typeof EXPORT_TO_ASSET_HUB;

export const EXPORT_TO_ASSET_HUB_FAILED =
	'[Asset Hub] Export to Asset Hub Failed';
type EXPORT_TO_ASSET_HUB_FAILED = typeof EXPORT_TO_ASSET_HUB_FAILED;

export const EXPORT_TO_ASSET_HUB_COMPLETE =
	'[Asset Hub] Export to Asset Hub Complete';
type EXPORT_TO_ASSET_HUB_COMPLETE = typeof EXPORT_TO_ASSET_HUB_COMPLETE;

export const GET_PERMISSIONS = '[Asset Hub] Get Permissions';
type GET_PERMISSIONS = typeof GET_PERMISSIONS;

export const GET_PERMISSIONS_FAILED = '[Asset Hub] Get Permissions Failed';
type GET_PERMISSIONS_FAILED = typeof GET_PERMISSIONS_FAILED;

export const GET_PERMISSIONS_COMPLETE = '[Asset Hub] Get Permissions Complete';
type GET_PERMISSIONS_COMPLETE = typeof GET_PERMISSIONS_COMPLETE;

export interface ExportToAssetHubAction {
	episodicId: number;
	payload: AHExportPayload;
	type: EXPORT_TO_ASSET_HUB;
}

export interface ExportToAssetHubFailedAction {
	error: Error;
	type: EXPORT_TO_ASSET_HUB_FAILED;
}

export interface ExportToAssetHubCompleteAction {
	type: EXPORT_TO_ASSET_HUB_COMPLETE;
}

export interface GetPermissionsAction {
	episodicId: number;
	type: GET_PERMISSIONS;
}

export interface GetPermissionsFailedAction {
	error: Error;
	type: GET_PERMISSIONS_FAILED;
}

export interface GetPermissionsCompleteAction {
	permissions: AHDeptPermissionsResponse;
	type: GET_PERMISSIONS_COMPLETE;
}

export function exportToAssetHub(
	episodicId: number,
	payload: AHExportPayload
): ExportToAssetHubAction {
	return {
		episodicId,
		payload,
		type: EXPORT_TO_ASSET_HUB,
	};
}

export function exportToAssetHubFailed(
	error: Error
): ExportToAssetHubFailedAction {
	return {
		error,
		type: EXPORT_TO_ASSET_HUB_FAILED,
	};
}

export function exportToAssetHubComplete(): ExportToAssetHubCompleteAction {
	return {
		type: EXPORT_TO_ASSET_HUB_COMPLETE,
	};
}

export function getPermissions(episodicId: number): GetPermissionsAction {
	return {
		type: GET_PERMISSIONS,
		episodicId,
	};
}

export function getPermissionsFailed(error: Error): GetPermissionsFailedAction {
	return {
		type: GET_PERMISSIONS_FAILED,
		error,
	};
}

export function getPermissionsComplete(
	permissions: AHDeptPermissionsResponse
): GetPermissionsCompleteAction {
	return {
		type: GET_PERMISSIONS_COMPLETE,
		permissions,
	};
}

export type Action =
	| GetPermissionsAction
	| GetPermissionsFailedAction
	| GetPermissionsCompleteAction
	| ExportToAssetHubAction
	| ExportToAssetHubFailedAction
	| ExportToAssetHubCompleteAction;
