import { ScriptLocation } from 'sos-models';
import * as actions from './script-location.actions';
import { scriptLocationSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createComplete,
	createReducer,
	updateComplete,
	destroyComplete,
	failed,
	listComplete,
	loading,
} from '../utils';

export interface State extends BaseReducerState<ScriptLocation> {
	listStatus: ActionStatus;
	getStatus: ActionStatus;
	createStatus: ActionStatus;
	updateStatus: ActionStatus;
	destroyStatus: ActionStatus;
	currentId: number;
}

const initial: State = {
	createStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
	entities: {},
	getStatus: ActionStatus.Inactive,
	ids: [],
	listStatus: ActionStatus.Inactive,
	destroyStatus: ActionStatus.Inactive,
	currentId: null,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.CREATE]: loading<State>('createStatus'),
	[actions.CREATE_COMPLETE]: createComplete<
		ScriptLocation,
		State,
		actions.CreateCompleteAction
	>('scriptLocations', scriptLocationSchema),
	[actions.CREATE_FAILED]: failed<State>('createStatus'),
	[actions.LIST]: loading<State>('listStatus'),
	[actions.LIST_COMPLETE]: listComplete<
		ScriptLocation,
		State,
		actions.ListCompleteAction
	>('scriptLocations', scriptLocationSchema),
	[actions.LIST_FAILED]: failed<State>('listStatus'),
	[actions.DESTROY]: loading<State>('destroyStatus'),
	[actions.DESTROY_COMPLETE]: destroyComplete<
		ScriptLocation,
		State,
		actions.DestroyCompleteAction
	>('scriptLocations'),
	[actions.DESTROY_FAILED]: failed<State>('destroyStatus'),
	[actions.UPDATE]: loading<State>('createStatus'),
	[actions.UPDATE_COMPLETE]: updateComplete<
		ScriptLocation,
		State,
		actions.UpdateCompleteAction
	>('scriptLocations', scriptLocationSchema),
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
	[actions.SET_CURRENT]: setCurrent,
});

function setCurrent(
	state: State,
	{ scriptLocationId }: actions.SetCurrentAction
): State {
	return {
		...state,
		currentId: scriptLocationId,
	};
}
