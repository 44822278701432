import { PictureScene } from 'sos-models';
import * as actions from './picture-scene.actions';
import { pictureSceneSchema } from '../schemas';
import {
	ActionStatus,
	createReducer,
	destroyComplete,
	failed,
	listComplete,
	loading,
} from '../utils';

export interface State {
	entities: { [id: number]: PictureScene };
	ids: number[];
	destroyStatus: ActionStatus;
	listStatus: ActionStatus;
}

export const initial: State = {
	entities: {},
	ids: [],
	destroyStatus: ActionStatus.Inactive,
	listStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.DELETE_BY_SCENE_ID]: deleteBySceneId,
	[actions.DESTROY]: loading<State>('destroyStatus'),
	[actions.DESTROY_COMPLETE]: destroyComplete<
		PictureScene,
		State,
		actions.DestroyCompleteAction
	>('pictureScene'),
	[actions.DESTROY_FAILED]: failed<State>('destroyStatus'),
	[actions.LIST]: loading<State>('listStatus'),
	[actions.LIST_COMPLETE]: listComplete<
		PictureScene,
		State,
		actions.ListCompleteAction
	>('pictureScenes', pictureSceneSchema),
	[actions.LIST_FAILED]: failed<State>('listStatus'),
});

function deleteBySceneId(
	state: State,
	{ sceneId }: actions.DeleteBySceneIdAction
) {
	const entities = { ...state.entities };
	Object.keys(entities).forEach((k) => {
		if (entities[k].scene_id === sceneId) {
			delete entities[k];
		}
	});
	const ids = Object.keys(entities).map((k) => Number(k));

	return {
		...state,
		entities: {
			...state.entities,
			...entities,
		},
		ids,
	};
}
