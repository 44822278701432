import { normalize } from 'normalizr';
import { Change, DEPARTMENTS } from 'sos-models';
import * as actions from './character-view.actions';
import * as changeActions from '../changes/change.actions';
import { modifyChangeNumbers } from '../changes/change.utils';
import * as episodicCharacterActions from '../episodic-characters/episodic-character.actions';
import { changeSchema } from '../schemas';
import {
	ActionStatus,
	createReducer,
	failed,
	loading,
	uniqueArrayMerge,
} from '../utils';

export interface State {
	changeEntities: { [id: number]: Change };
	changeIds: number[];
	changesUpdateStatus: ActionStatus;
	currentDeptIdForPhotoSidebar: DEPARTMENTS;
	currentDeptIdForChangePropTables: DEPARTMENTS;
	next: number;
	previous: number;
}

const initial: State = {
	changeEntities: {},
	changeIds: [],
	changesUpdateStatus: ActionStatus.Inactive,
	currentDeptIdForPhotoSidebar: null,
	currentDeptIdForChangePropTables: null,
	previous: null,
	next: null,
};

export const reducer = createReducer<
	State,
	actions.Action | changeActions.Action | episodicCharacterActions.Action
>(initial, {
	[actions.CANCEL_CM_CHANGE_EDIT]: resetChanges,
	[actions.MODIFY_CHANGE_NUM]: modifyChangeNum,
	[actions.SET_CURRENT_DEPT_FOR_PHOTO_SIDEBAR]: setSelectedPictureDeptId,
	[actions.SET_CURRENT_DEPT_FOR_CHANGE_PROP_TABLES]: setSelectedChangePropTablesDeptId,
	[actions.UPDATE_CHANGE_NUM]: loading<State>('changesUpdateStatus'),
	[actions.UPDATE_CHANGE_NUM_COMPLETE]: updateChangesComplete,
	[actions.UPDATE_CHANGE_NUM_FAILED]: failed<State>('changesUpdateStatus'),
	[episodicCharacterActions.GET_COMPLETE]: setNextPrevious,
	[changeActions.GET_CHANGES_FOR_EP_CHAR_COMPLETE]: getChanges,
});

function setSelectedPictureDeptId(
	state: State,
	{ departmentId }: actions.SetCurrentDeptForPhotoSidebarAction
): State {
	return {
		...state,
		currentDeptIdForPhotoSidebar: departmentId,
	};
}

function setSelectedChangePropTablesDeptId(
	state: State,
	{ departmentId }: actions.SetCurrentDeptForChangePropTablesAction
): State {
	return {
		...state,
		currentDeptIdForChangePropTables: departmentId,
	};
}

function updateChangesComplete(
	state: State,
	action: actions.UpdateMultipleCompleteAction
): State {
	const normalized = normalize(action.changes, [changeSchema]);
	return {
		...state,
		changeEntities: {
			...state.changeEntities,
			...normalized.entities.changes,
		},
		changeIds: uniqueArrayMerge(state.changeIds, normalized.result),
	};
}

function getChanges(
	state: State,
	action: changeActions.GetChangesForEpCharCompleteAction
): State {
	const normalized = normalize(action.changes, [changeSchema]);
	return {
		...state,
		changeEntities: {
			...state.changeEntities,
			...normalized.entities.changes,
		},
		changeIds: uniqueArrayMerge(state.changeIds, normalized.result),
	};
}

function resetChanges(
	state: State,
	action: actions.CancelCmChangeAction
): State {
	const normalized = normalize(action.changes, [changeSchema]);
	return {
		...state,
		changeEntities: {
			...state.changeEntities,
			...normalized.entities.changes,
		},
		changeIds: uniqueArrayMerge(state.changeIds, normalized.result),
	};
}

function modifyChangeNum(
	state: State,
	action: actions.ModifyChangeNumAction
): State {
	const dirtyChanges = modifyChangeNumbers(
		state.changeEntities,
		action.changeId,
		action.prodId,
		action.isIncrement
	);
	return {
		...state,
		changeEntities: dirtyChanges,
	};
}

function setNextPrevious(
	state: State,
	{ next, previous }: episodicCharacterActions.GetCompleteAction
): State {
	return {
		...state,
		previous,
		next,
	};
}
