import { EpisodicFlag } from 'sos-models';
import * as actions from './episodic-flag.actions';
import { episodicFlagSchema } from '../schemas';
import {
	BaseReducerState,
	createReducer,
	listComplete,
	NormalizedEntityMapping,
} from '../utils';

export interface State extends BaseReducerState<EpisodicFlag> {
	entities: NormalizedEntityMapping<EpisodicFlag>;
	ids: number[];
}

const initial: State = {
	entities: {},
	ids: [],
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.LIST_COMPLETE]: listComplete<
		EpisodicFlag,
		State,
		actions.ListCompleteAction
	>('episodicFlags', episodicFlagSchema),
});
