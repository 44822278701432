import { PrEpisodicCharacterNote } from 'sos-models';

export const CREATE_COMPLETE = '[PR Episodic Character Note] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const LIST_COMPLETE = '[PR Episodic Character Notes] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const UPDATE_COMPLETE = '[PR Episodic Character Note] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export interface CreateCompleteAction {
	episodicCharacterNotes: PrEpisodicCharacterNote[];
	type: CREATE_COMPLETE;
}

export interface ListCompleteAction {
	episodicCharacterNotes: PrEpisodicCharacterNote[];
	type: LIST_COMPLETE;
}

export interface UpdateCompleteAction {
	episodicCharacterNotes: PrEpisodicCharacterNote[];
	type: UPDATE_COMPLETE;
}

export function createComplete(
	episodicCharacterNotes: PrEpisodicCharacterNote[]
): CreateCompleteAction {
	return {
		episodicCharacterNotes,
		type: CREATE_COMPLETE,
	};
}

export function listComplete(
	episodicCharacterNotes: PrEpisodicCharacterNote[]
): ListCompleteAction {
	return {
		episodicCharacterNotes,
		type: LIST_COMPLETE,
	};
}

export function updateComplete(
	episodicCharacterNotes: PrEpisodicCharacterNote[]
): UpdateCompleteAction {
	return {
		episodicCharacterNotes,
		type: UPDATE_COMPLETE,
	};
}

export type Action =
	| CreateCompleteAction
	| ListCompleteAction
	| UpdateCompleteAction;
