import {
	AnyWrapBox,
	DeptWrapBoxes,
	PartialDeptWrapBoxes,
	WrapBoxCreateResponse,
	WrapBoxGetResponse,
	WrapBoxListResponse,
} from 'sos-models';
import { ListOptions } from './wrap-box.actions';
import axios from '../../config/axios';

export async function create(
	episodicId: number,
	departmentId: number,
	wrapBox: Partial<AnyWrapBox>
): Promise<WrapBoxCreateResponse> {
	return await axios
		.post<WrapBoxCreateResponse>(
			`/api/episodics/${episodicId}/wrap-boxes/${departmentId}`,
			wrapBox
		)
		.then((response) => response.data);
}

export async function get(
	episodicId: number,
	departmentId: number,
	wrapBoxId: number
): Promise<WrapBoxGetResponse> {
	const { data } = await axios.get<WrapBoxGetResponse>(
		`/api/episodics/${episodicId}/wrap-boxes/${departmentId}/${wrapBoxId}`
	);
	return data;
}

export async function list(episodicId: number): Promise<WrapBoxListResponse> {
	return await axios
		.get<WrapBoxListResponse>(`/api/episodics/${episodicId}/wrap-boxes`)
		.then((response) => response.data);
}

export async function update(
	episodicId: number,
	deptWrapBoxes: PartialDeptWrapBoxes
): Promise<DeptWrapBoxes> {
	const { data } = await axios.put<DeptWrapBoxes>(
		`/api/episodics/${episodicId}/wrap-boxes`,
		deptWrapBoxes
	);
	return data;
}

export async function exportWrapBoxes(
	episodicId: number,
	options: ListOptions
) {
	return await axios
		.get(`/api/episodics/${episodicId}/wrap-boxes/export`, {
			params: options,
		})
		.then((response) => response.data);
}

export async function destroy(
	episodicId: number,
	departmentId: number,
	wrapBoxId: number
): Promise<AnyWrapBox> {
	return await axios
		.delete(
			`/api/episodics/${episodicId}/wrap-boxes/${departmentId}/${wrapBoxId}`
		)
		.then((response) => response.data);
}
