import { CmWrapBox, DEPARTMENTS, DeptWrapBoxes } from 'sos-models';
import { cmWrapBoxSchema } from '../schemas';
import { NormalizationOutput } from '../utils';
import {
	BaseWrapBoxReducerState,
	createWrapBoxReducer,
} from '../wrap-boxes/dept-wrap-box-base.reducer';

export interface State extends BaseWrapBoxReducerState<CmWrapBox> {}

export const reducer = createWrapBoxReducer<CmWrapBox>(
	(wrapBoxes: DeptWrapBoxes) => wrapBoxes.CmWrapBox,
	(normalized: NormalizationOutput<CmWrapBox>) =>
		normalized.entities.cmWrapBoxes,
	cmWrapBoxSchema,
	DEPARTMENTS.CM
);
