import { DeptEntityId } from 'sos-models';
import * as wrapBoxViewActions from './wrap-box-view.actions';
import * as wrapBoxActions from './wrap-box.actions';
import { createReducer } from '../utils';

export interface State {
	episodicCharacterIds: number[];
	wrapBoxId: DeptEntityId;
	setIds: number[];
}

export const initial: State = {
	wrapBoxId: {
		department: null,
		id: null,
	},
	episodicCharacterIds: [],
	setIds: [],
};

export const reducer = createReducer<
	State,
	wrapBoxActions.Action | wrapBoxViewActions.Action
>(initial, {
	[wrapBoxActions.SET_CURRENT]: setCurrent,
	[wrapBoxViewActions.FILTER]: setFilter,
});

function setCurrent(
	state: State,
	action: wrapBoxActions.SetCurrentAction
): State {
	return {
		...state,
		wrapBoxId: {
			department: action.currentDepartmentId,
			id: action.currentId,
		},
	};
}

function setFilter(
	state: State,
	{ options }: wrapBoxViewActions.FilterAction
): State {
	return {
		...state,
		episodicCharacterIds: options.episodicCharacterIds,
		setIds: options.setIds,
	};
}
