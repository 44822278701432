import { SortOrder } from 'sos-models';
import * as actions from './actor-index.actions';
import { createReducer } from '@reduxjs/toolkit';

export interface State {
	SortBy: string;
	SortOrder: SortOrder;
}

const initial: State = {
	SortBy: 'name',
	SortOrder: 'asc',
};

export const reducer = createReducer(initial, (builder) =>
	builder.addCase(actions.SET_SORTING, setSorting)
);

function setSorting(
	state: State,
	{ sorting }: actions.SetSortingAction
): State {
	const sortInfo = sorting[0];
	return {
		...state,
		SortBy: sortInfo.columnName,
		SortOrder: sortInfo.direction,
	};
}
