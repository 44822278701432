import { UnknownAction } from 'redux';
import { Character } from 'sos-models';

export const CREATE_COMPLETE = '[Character] Create complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const DELETE_COMPLETE = '[Characters] Delete Complete';
type DELETE_COMPLETE = typeof DELETE_COMPLETE;

export const LIST = '[Characters] List';
type LIST = typeof LIST;

export const LIST_FAILED = '[Characters] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const LIST_COMPLETE = '[Characters] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const UPDATE_COMPLETE = '[Characters] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export interface CreateCompleteAction {
	characters: Character[];
	type: CREATE_COMPLETE;
}

export interface DeleteCompleteAction {
	characters: Character[];
	type: DELETE_COMPLETE;
}

export interface ListAction {
	episodicId: number;
	type: LIST;
}

export interface ListFailedAction {
	type: LIST_FAILED;
	error: Error;
}

export interface ListCompleteAction extends UnknownAction {
	type: LIST_COMPLETE;
	characters: Character[];
}

export interface UpdateCompleteAction {
	type: UPDATE_COMPLETE;
	characters: Character[];
}

export function createComplete(characters: Character[]): CreateCompleteAction {
	return {
		characters,
		type: CREATE_COMPLETE,
	};
}

export function deleteComplete(characters: Character[]): DeleteCompleteAction {
	return {
		characters,
		type: DELETE_COMPLETE,
	};
}

export function list(episodicId: number): ListAction {
	return {
		episodicId,
		type: LIST,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export function listComplete(characters: Character[]): ListCompleteAction {
	return {
		characters,
		type: LIST_COMPLETE,
	};
}

export function updateComplete(characters: Character[]): UpdateCompleteAction {
	return {
		characters,
		type: UPDATE_COMPLETE,
	};
}

export type Action =
	| CreateCompleteAction
	| DeleteCompleteAction
	| ListAction
	| ListFailedAction
	| ListCompleteAction
	| UpdateCompleteAction;
