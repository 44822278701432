import { HaSceneNote } from 'sos-models';

export const CREATE_COMPLETE = '[HA Scene Note] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const GET_COMPLETE = '[HA Scene Note] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const UPDATE_COMPLETE = '[HA Scene Note] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const DESTROY_COMPLETE = '[HA Scene Note] Destroy Complete';
type DESTROY_COMPLETE = typeof UPDATE_COMPLETE;

export interface CreateCompleteAction {
	haSceneNote: HaSceneNote;
	type: CREATE_COMPLETE;
}

export interface GetCompleteAction {
	haSceneNote: HaSceneNote;
	type: GET_COMPLETE;
}

export interface UpdateCompleteAction {
	haSceneNote: HaSceneNote;
	type: UPDATE_COMPLETE;
}

export interface DestroyCompleteAction {
	haSceneNote: HaSceneNote;
	type: DESTROY_COMPLETE;
}

export function createComplete(haSceneNote: HaSceneNote): CreateCompleteAction {
	return {
		haSceneNote,
		type: CREATE_COMPLETE,
	};
}
export function getComplete(haSceneNote: HaSceneNote): GetCompleteAction {
	return {
		haSceneNote,
		type: GET_COMPLETE,
	};
}

export function updateComplete(haSceneNote: HaSceneNote): UpdateCompleteAction {
	return {
		haSceneNote,
		type: UPDATE_COMPLETE,
	};
}

export function destroyComplete(
	haSceneNote: HaSceneNote
): DestroyCompleteAction {
	return {
		haSceneNote,
		type: UPDATE_COMPLETE,
	};
}

export type Action =
	| CreateCompleteAction
	| GetCompleteAction
	| UpdateCompleteAction
	| DestroyCompleteAction;
