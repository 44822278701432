import * as actions from './activity-monitor.actions';
import { createReducer } from '@reduxjs/toolkit';

export interface State {
	active: boolean;
	expired: boolean;
}

export const initial: State = {
	active: true,
	expired: false,
};

export const reducer = createReducer(initial, (builder) =>
	builder
		.addCase(actions.ACTIVE, active)
		.addCase(actions.EXPIRED, expired)
		.addCase(actions.INACTIVE, inactive)
);

function active(state: State): State {
	return {
		...state,
		active: true,
	};
}

function expired(state: State): State {
	return {
		...state,
		expired: true,
	};
}

function inactive(state: State): State {
	return {
		...state,
		active: false,
	};
}
