import { PrSceneNote } from 'sos-models';

export const CREATE_COMPLETE = '[PR Scene Note] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const GET_COMPLETE = '[PR Scene Note] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const UPDATE_COMPLETE = '[PR Scene Note] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const DESTROY_COMPLETE = '[PR Scene Note] Destroy Complete';
type DESTROY_COMPLETE = typeof UPDATE_COMPLETE;

export interface CreateCompleteAction {
	prSceneNote: PrSceneNote;
	type: CREATE_COMPLETE;
}

export interface GetCompleteAction {
	prSceneNote: PrSceneNote;
	type: GET_COMPLETE;
}

export interface UpdateCompleteAction {
	prSceneNote: PrSceneNote;
	type: UPDATE_COMPLETE;
}

export interface DestroyCompleteAction {
	prSceneNote: PrSceneNote;
	type: DESTROY_COMPLETE;
}

export function createComplete(prSceneNote: PrSceneNote): CreateCompleteAction {
	return {
		prSceneNote,
		type: CREATE_COMPLETE,
	};
}
export function getComplete(prSceneNote: PrSceneNote): GetCompleteAction {
	return {
		prSceneNote,
		type: GET_COMPLETE,
	};
}

export function updateComplete(prSceneNote: PrSceneNote): UpdateCompleteAction {
	return {
		prSceneNote,
		type: UPDATE_COMPLETE,
	};
}

export function destroyComplete(
	prSceneNote: PrSceneNote
): DestroyCompleteAction {
	return {
		prSceneNote,
		type: UPDATE_COMPLETE,
	};
}

export type Action =
	| CreateCompleteAction
	| GetCompleteAction
	| UpdateCompleteAction
	| DestroyCompleteAction;
