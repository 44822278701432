import { difference } from 'lodash';
import { normalize } from 'normalizr';
import { Permission } from 'sos-models';
import * as actions from './permission.actions';
import { permissionSchema } from '../schemas';
import {
	ActionStatus,
	createReducer,
	failed,
	loading,
	uniqueArrayMerge,
} from '../utils';

export interface State {
	entities: { [id: number]: Permission };
	ids: number[];
	loading: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	loading: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.GET]: loading<State>('loading'),
	[actions.GET_COMPLETE]: getComplete,
	[actions.GET_FAILED]: failed<State>('loading'),
});

function getComplete(state: State, action: actions.GetCompleteAction): State {
	const normalized = normalize(action.permissions, [permissionSchema]);
	const removedIds = difference(state.ids, normalized.result);
	if (removedIds.length > 0) {
		window.location.reload();
	}
	return {
		...state,
		entities: { ...state.entities, ...normalized.entities.permissions },
		ids: uniqueArrayMerge(state.ids, normalized.result),
		loading: ActionStatus.Complete,
	};
}
