import { MuLookPicture } from 'sos-models';
import * as actions from './mu-look-picture.actions';
import { muLookPictureSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createReducer,
	destroyComplete,
	failed,
	listComplete,
	loading,
} from '../utils';

export interface State extends BaseReducerState<MuLookPicture> {
	entities: { [id: number]: MuLookPicture };
	ids: number[];
	destroyStatus: ActionStatus;
	listStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	destroyStatus: ActionStatus.Inactive,
	listStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.DESTROY]: loading<State>('destroyStatus'),
	[actions.DESTROY_COMPLETE]: destroyComplete<
		MuLookPicture,
		State,
		actions.DestroyCompleteAction
	>('muLookPicture'),
	[actions.DESTROY_FAILED]: failed<State>('destroyStatus'),
	[actions.LIST_COMPLETE]: listComplete<
		MuLookPicture,
		State,
		actions.ListCompleteAction
	>('muLookPictures', muLookPictureSchema),
});
