import { PropStatus } from 'sos-models';
import axios from '../../config/axios';

export function create(
	propStatus: Partial<PropStatus>
): Promise<Partial<PropStatus>> {
	return axios
		.post<Partial<PropStatus>>(`/api/prop-statuses`, propStatus)
		.then((response) => response.data);
}

export function destroy(propStatus: Partial<PropStatus>) {
	return axios
		.delete(`/api/prop-statuses/${propStatus.id}`)
		.then((response) => response.data);
}

export function update(
	propStatus: Partial<PropStatus>
): Promise<Partial<PropStatus>> {
	return axios
		.put<Partial<PropStatus>>(`/api/prop-statuses/${propStatus.id}`, propStatus)
		.then((response) => response.data);
}
