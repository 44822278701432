import { SetPieceSet } from 'sos-models';
import * as actions from './set-piece-set.actions';
import { setPieceSetSchema } from '../schemas';
import {
	ActionStatus,
	createComplete,
	createReducer,
	destroyComplete,
	listComplete,
	NormalizedEntityMapping,
} from '../utils';

export interface State {
	entities: NormalizedEntityMapping<SetPieceSet>;
	ids: number[];
	createStatus: ActionStatus;
	listStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	createStatus: ActionStatus.Inactive,
	listStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.LIST_COMPLETE]: listComplete<
		SetPieceSet,
		State,
		actions.ListCompleteAction
	>('setPieceSets', setPieceSetSchema),
	[actions.DESTROY_COMPLETE]: destroyComplete<
		SetPieceSet,
		State,
		actions.DestroyCompleteAction
	>('setPieceSets'),
	[actions.CREATE_COMPLETE]: createComplete<
		SetPieceSet,
		State,
		actions.CreateCompleteAction
	>('setPieceSets', setPieceSetSchema),
});
