import {
	Change,
	ChangeScene,
	CreateDeleteChangeScene,
	DEPARTMENTS,
} from 'sos-models';
import axios from '../../config/axios';

export async function getForScene(
	prodId: number,
	sceneId: number
): Promise<Partial<ChangeScene>[]> {
	return axios
		.get<Partial<ChangeScene>[]>(
			`/api/productions/${prodId}/scenes/${sceneId}/change-scenes`
		)
		.then((response) => response.data);
}

export async function getForEpCharId(
	episodicId: number,
	epCharId: number
): Promise<Partial<ChangeScene>[]> {
	return axios
		.get<Partial<ChangeScene>[]>(
			`/api/episodics/${episodicId}/episodic-characters/${epCharId}/change-scenes`
		)
		.then((response) => response.data);
}

export async function createAndDeleteExisting(
	episodicId: number,
	department: DEPARTMENTS,
	data: CreateDeleteChangeScene[]
): Promise<{
	created: { ChangeScene: ChangeScene[] };
	deleted: { ChangeScene: ChangeScene[] };
	updated: { Change: Change[] };
}> {
	return axios
		.post(`/api/episodics/${episodicId}/change-scenes/${department}`, data)
		.then((response) => response.data);
}

export async function update(
	episodicId: number,
	changeScene: Partial<ChangeScene>
): Promise<ChangeScene> {
	return axios
		.put(
			`/api/episodics/${episodicId}/change-scenes/${DEPARTMENTS.CM}`,
			changeScene
		)
		.then((response) => response.data);
}
