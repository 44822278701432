import { DEPARTMENTS } from 'sos-models';
import { AddExistingInventoryModalTypes } from '../../common/modal/add-existing-inventory/AddExistingInventoryModal';

export const SELECT_CHARACTER = '[AddExistingInventoryModal] Select Character';
type SELECT_CHARACTER = typeof SELECT_CHARACTER;

export const SELECT_DEPARTMENT_AND_MODAL_TYPE =
	'[AddExistingInventoryModal] Select Department And Modal Type';
type SELECT_DEPARTMENT_AND_MODAL_TYPE = typeof SELECT_DEPARTMENT_AND_MODAL_TYPE;

export const SELECT_SET = '[AddExistingInventoryModal] Select Set';
type SELECT_SET = typeof SELECT_SET;

export interface SelectCharacterAction {
	type: SELECT_CHARACTER;
	epCharId: number;
}

export interface SelectDepartmentAction {
	type: SELECT_DEPARTMENT_AND_MODAL_TYPE;
	deptId: DEPARTMENTS;
	modalType: AddExistingInventoryModalTypes;
}

export interface SelectSetAction {
	type: SELECT_SET;
	setId: number;
}

export function selectCharacter(epCharId: number): SelectCharacterAction {
	return {
		epCharId,
		type: SELECT_CHARACTER,
	};
}

export function selectDepartmentAndModalType(
	deptId: DEPARTMENTS,
	modalType: AddExistingInventoryModalTypes
): SelectDepartmentAction {
	return {
		deptId,
		modalType,
		type: SELECT_DEPARTMENT_AND_MODAL_TYPE,
	};
}

export function selectSet(setId: number): SelectSetAction {
	return {
		setId,
		type: SELECT_SET,
	};
}

export type Action =
	| SelectCharacterAction
	| SelectDepartmentAction
	| SelectSetAction;
