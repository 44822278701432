import { find } from 'lodash';
import { toast } from 'react-toastify';
import { Change } from 'sos-models';
import { NormalizedEntityMapping } from '../utils';

interface ChangeData {
	changeNumber: string;
	changeInteger: number;
	changeString: string;
	changeNumberArray: string[];
	changeId: string;
}

export function modifyChangeNumbers(
	changes: NormalizedEntityMapping<Change>,
	changeId: number,
	prodId: number,
	isIncrement: boolean
) {
	let change: Change,
		targetChangeData: ChangeData = checkChangeNumber(
			changes[changeId].change_num,
			changeId.toString()
		),
		add = isIncrement ? 1 : -1,
		toUpdate = [],
		collisionChange = null;
	const changeIndex: NormalizedEntityMapping<ChangeData> = {};

	if (isNaN(targetChangeData.changeInteger)) {
		const errMessage = `"${changes[changeId].change_num}" is not a valid change number, cannot increment or decrement`;
		toast.error(errMessage);
		return changes;
	}

	for (const key in changes) {
		if (changes.hasOwnProperty(key) && prodId === changes[key].prod_id) {
			change = changes[key];
			const changeData = checkChangeNumber(change.change_num, key);
			if (!isNaN(changeData.changeInteger)) {
				changeIndex[key] = changeData;
			}
		}
	}

	toUpdate = buildChangesUpdates(targetChangeData, add, changeIndex);
	if (checkNegative(toUpdate) !== null) {
		toast.error('Decrementing cannot create negative change numbers.');
	} else if (collisionChange === null) {
		return updatedChangeEntities(toUpdate, changes);
	}
	return changes;
}

function buildChangesUpdates(
	targetChangeData: ChangeData,
	add: number,
	changeIndex: NormalizedEntityMapping<ChangeData>
): ChangeData[] {
	const belowChanges = [];
	const aboveChanges = [];
	const dataToUpdate = [];
	for (var key in changeIndex) {
		if (changeIndex.hasOwnProperty(key)) {
			if (changeIndex[key].changeInteger < targetChangeData.changeInteger) {
				belowChanges.push(changeIndex[key]);
			} else {
				aboveChanges.push(changeIndex[key]);
			}
		}
	}
	const existingChangeNums = belowChanges.map((c) => c.changeNumber);
	const targetUpdate = updateChange(targetChangeData, add);

	if (existingChangeNums.includes(targetUpdate.changeNumber)) {
		toast.error(
			'Decrementing will result in two changes called Change ' +
				targetUpdate.changeNumber
		);
		return [];
	}

	aboveChanges.forEach((change) => {
		dataToUpdate.push(updateChange(change, add));
	});

	return dataToUpdate;
}

function updateChange(changeData: ChangeData, add: number) {
	if (typeof changeData.changeInteger === 'undefined') {
		changeData.changeNumber = changeData.changeNumber + add;
	} else {
		changeData.changeInteger = changeData.changeInteger + add;
		changeData.changeNumber =
			changeData.changeInteger.toString() + changeData.changeString;
	}
	return changeData;
}

function checkChangeNumber(changeNumber: string, changeId: string): ChangeData {
	let changeData: ChangeData = {
		changeInteger: null,
		changeNumber,
		changeId,
		changeString: '',
		changeNumberArray: numberBreakup(changeNumber),
	};
	changeData.changeInteger = parseInt(changeData.changeNumberArray[0], 10);
	changeData.changeNumberArray.shift();
	changeData.changeString = changeData.changeNumberArray.join('');
	return changeData;
}

function numberBreakup(t: string) {
	return t.match(/[a-zA-Z]+|[0-9]+/g);
}

function checkNegative(changes: { [id: number]: ChangeData }) {
	var key, obj;
	for (key in changes) {
		if (changes.hasOwnProperty(key)) {
			obj = changes[key];
			if (typeof obj.changeInteger !== 'undefined') {
				if (obj.changeInteger < 0) {
					return obj.defaultChangeNumber;
				}
			} else {
				if (obj.changeInteger < 0) {
					return obj.defaultChangeNumber;
				}
			}
		}
	}
	return null;
}

// returns the final dictionary of changes
function updatedChangeEntities(
	toUpdate: ChangeData[],
	changes: { [id: number]: Change }
) {
	let newChanges: { [id: number]: Change } = {};
	let newChangeData;
	for (var key in changes) {
		if (changes.hasOwnProperty(key)) {
			newChangeData = find(toUpdate, (cd) => cd.changeId === key);
			if (newChangeData) {
				newChanges[key] = new Change({
					...changes[newChangeData.changeId],
					change_num: newChangeData.changeNumber,
				});
			} else {
				newChanges[key] = changes[key];
			}
		}
	}

	return newChanges;
}
