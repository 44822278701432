import { UniversalSearchHit } from 'sos-models';
import * as univeralSearchActions from './universal-search.actions';
import { ActionStatus, createReducer, failed, loading } from '../utils';

export interface State {
	searchHits: UniversalSearchHit[];
	searchStatus: ActionStatus;
}

const initial: State = {
	searchHits: [],
	searchStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, univeralSearchActions.Action>(
	initial,
	{
		[univeralSearchActions.SEARCH]: loading<State>('searchStatus'),
		[univeralSearchActions.SEARCH_COMPLETE]: searchComplete,
		[univeralSearchActions.SEARCH_FAILED]: failed<State>('searchStatus'),
	}
);

function searchComplete(
	_state: State,
	{ searchHits }: univeralSearchActions.SearchCompleteAction
): State {
	return {
		..._state,
		searchHits,
		searchStatus: ActionStatus.Complete,
	};
}
