import { Change, ChangeUpdateResponse } from 'sos-models';
import axios from '../../config/axios';

export async function update(
	episodicId: number,
	prodId: number,
	changes: Change[]
): Promise<ChangeUpdateResponse> {
	return axios
		.put<ChangeUpdateResponse>(
			`/api/episodics/${episodicId}/productions/${prodId}/changes`,
			changes
		)
		.then((response) => response.data);
}
