import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	AddCharsToSceneResponse,
	CreateEpCharForSceneResponse,
	DeleteCharSceneByEpCharIdAndSceneIdResponse,
	UpdateCharScenesResponse,
	UpdatePropSceneCharScenesResponse,
} from 'sos-models';
import * as actions from './char-scene.actions';
import * as api from './char-scene.api';
import * as logger from '../../logger';
import {
	changeActions,
	changeSceneActions,
	characterActions,
	episodicActorActions,
	episodicCharacterActions,
	haChangeActions,
	haChangeSceneActions,
	muChangeActions,
	muChangeSceneActions,
	propSceneActions,
	propSceneCharSceneActions,
	sceneActions,
} from '../actions';

export function* addBgCharToScene({
	episodicId,
	epCharId,
	sceneId,
	bgCount,
}: actions.AddBgCharToSceneAction) {
	try {
		const { created, updated }: AddCharsToSceneResponse = yield call(
			api.addBgCharToScene,
			episodicId,
			epCharId,
			sceneId,
			bgCount
		);
		yield put(actions.addBgCharToSceneComplete(created.CharScene));
		if (created.Character.length) {
			yield put(characterActions.createComplete(created.Character));
		}
		if (updated.EpisodicCharacter.length) {
			yield put(
				episodicCharacterActions.updateComplete(updated.EpisodicCharacter)
			);
		}
		if (updated.Scene.length) {
			yield put(sceneActions.updateComplete(updated.Scene));
		}
	} catch (err) {
		yield put(actions.addBgCharToSceneFailed(err));
		logger.responseError(err, 'deleting this charScene', true);
	}
}

export function* deleteByEpCharIdAndSceneId({
	episodicId,
	epCharId,
	sceneId,
}: actions.DeleteCharSceneByEpCharIdAndSceneIdAction) {
	try {
		const results: DeleteCharSceneByEpCharIdAndSceneIdResponse = yield call(
			api.deleteByEpCharIdAndSceneId,
			episodicId,
			epCharId,
			sceneId
		);
		yield put(actions.deleteCharSceneByEpCharIdAndSceneIdComplete());
		yield put(actions.deleteComplete(results.destroyed.CharScene));
		if (results.destroyed.ChangeScene) {
			yield put(
				changeSceneActions.deleteComplete(results.destroyed.ChangeScene)
			);
		}
		if (results.destroyed.HaChangeScene) {
			yield put(
				haChangeSceneActions.deleteComplete(results.destroyed.HaChangeScene)
			);
		}
		if (results.destroyed.MuChangeScene) {
			yield put(
				muChangeSceneActions.deleteComplete(results.destroyed.MuChangeScene)
			);
		}
		if (results.destroyed.PropScene) {
			yield put(propSceneActions.destroyComplete(results.destroyed.PropScene));
		}
		if (results.destroyed.PropSceneCharScene) {
			yield put(
				propSceneCharSceneActions.destroyComplete(
					results.destroyed.PropSceneCharScene
				)
			);
		}
		if (results.updated.Change) {
			yield put(changeActions.updateComplete(results.updated.Change));
		}
		if (results.updated.EpisodicCharacter) {
			yield put(
				episodicCharacterActions.updateComplete(
					results.updated.EpisodicCharacter
				)
			);
		}
		if (results.updated.HaChange) {
			yield put(haChangeActions.updateComplete(results.updated.HaChange));
		}
		if (results.updated.MuChange) {
			yield put(muChangeActions.updateComplete(results.updated.MuChange));
		}
		if (results.updated.Scene) {
			yield put(sceneActions.updateComplete(results.updated.Scene));
		}
	} catch (err) {
		yield put(actions.deleteCharSceneByEpCharIdAndSceneIdFailed(err));
		logger.responseError(err, 'deleting this charScene', true);
	}
}

export function* addEpCharToScenes({
	episodicId,
	epCharId,
	sceneIds,
}: actions.AddEpCharToScenesAction) {
	try {
		const { created, updated }: AddCharsToSceneResponse = yield call(
			api.addEpCharToScenes,
			episodicId,
			epCharId,
			sceneIds
		);
		yield put(actions.createComplete(created.CharScene));
		if (created.Character.length) {
			yield put(characterActions.createComplete(created.Character));
		}
		if (updated.EpisodicCharacter.length) {
			yield put(
				episodicCharacterActions.updateComplete(updated.EpisodicCharacter)
			);
		}
		if (updated.Scene.length) {
			yield put(sceneActions.updateComplete(updated.Scene));
		}
		yield put(actions.addEpCharToScenesComplete());
	} catch (err) {
		yield put(actions.addEpCharToScenesFailed(err));
		if (err.response) {
			toast.error(
				'There was an error adding this character to the selected scenes.'
			);
		} else {
			logger.error(err);
		}
	}
}

export function* addEpCharsToScene({
	episodicId,
	sceneId,
	epCharIds,
}: actions.AddEpCharsToSceneAction) {
	try {
		const { created, updated }: AddCharsToSceneResponse = yield call(
			api.addEpCharsToScene,
			episodicId,
			sceneId,
			epCharIds
		);
		yield put(actions.createComplete(created.CharScene));
		if (created.Character.length) {
			yield put(characterActions.createComplete(created.Character));
		}
		if (updated.EpisodicCharacter.length) {
			yield put(
				episodicCharacterActions.updateComplete(updated.EpisodicCharacter)
			);
		}
		if (updated.Scene.length) {
			yield put(sceneActions.updateComplete(updated.Scene));
		}
		yield put(actions.addEpCharsToSceneComplete());
	} catch (err) {
		yield put(actions.addEpCharToScenesFailed(err));
		if (err.response) {
			toast.error(
				'There was an error adding this character to the selected scenes.'
			);
		} else {
			logger.error(err);
		}
	}
}

export function* createEpCharForScene({
	episodicId,
	sceneId,
	epChar,
	epActor,
}: actions.CreateEpCharForSceneAction) {
	try {
		const { created, updated }: CreateEpCharForSceneResponse = yield call(
			api.createEpCharForScene,
			episodicId,
			sceneId,
			epChar,
			epActor
		);
		const { Character, CharScene, EpisodicActor, EpisodicCharacter } = created;
		yield put(actions.createEpCharForSceneCompleteAction());
		yield put(episodicCharacterActions.listComplete(EpisodicCharacter));
		if (EpisodicActor) {
			yield put(episodicActorActions.createComplete(EpisodicActor));
		}
		yield put(characterActions.listComplete(Character));
		yield put(actions.listComplete(CharScene));
		if (updated && updated.Scene && updated.Scene.length) {
			yield put(sceneActions.updateComplete(updated.Scene));
		}
	} catch (err) {
		yield put(actions.createEpCharForSceneFailedAction(err));
		if (err.response) {
			toast.error('There was an error creating this character for the scene.');
		} else {
			logger.error(err);
		}
	}
}

export function* update({ episodicId, charScenes }: actions.UpdateAction) {
	try {
		const { updated }: UpdateCharScenesResponse = yield call(
			api.update,
			episodicId,
			charScenes
		);
		yield put(actions.updateComplete(updated.CharScene));
	} catch (err) {
		yield put(actions.updateFailed(err));
		if (err.response) {
			toast.error('There was an error updating the char-scene');
		} else {
			logger.error(err);
		}
	}
}

export function* udpatePropSceneCharScenesForEpChar({
	episodicId,
	epCharId,
	charScenes,
}: actions.UpdatePropSceneCharScenesForEpCharAction) {
	try {
		const {
			created,
			destroyed,
		}: UpdatePropSceneCharScenesResponse = yield call(
			api.updatePropSceneCharScenesForEpChar,
			episodicId,
			epCharId,
			charScenes
		);
		yield put(actions.updatePropSceneCharScenesForEpCharComplete());
		yield put(
			propSceneCharSceneActions.createComplete(created.PropSceneCharScene)
		);
		yield put(
			propSceneCharSceneActions.destroyComplete(destroyed.PropSceneCharScene)
		);
	} catch (err) {
		yield put(actions.createEpCharForSceneFailedAction(err));
		if (err.response) {
			toast.error('There was an error updating prop-scenes for this character');
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeEvery(actions.ADD_BG_CHAR_TO_SCENE, addBgCharToScene);
	yield takeEvery(
		actions.DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID,
		deleteByEpCharIdAndSceneId
	);
	yield takeLatest(actions.ADD_EP_CHAR_TO_SCENES, addEpCharToScenes);
	yield takeLatest(actions.ADD_EP_CHARS_TO_SCENE, addEpCharsToScene);
	yield takeLatest(actions.CREATE_EP_CHAR_FOR_SCENE, createEpCharForScene);
	yield takeEvery(
		actions.UPDATE_PROP_SCENE_CHAR_SCENES_FOR_EP_CHAR,
		udpatePropSceneCharScenesForEpChar
	);
	yield takeLatest(actions.UPDATE, update);
}

export default saga;
