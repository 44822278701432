import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	ChangeSceneGetResponse,
	DEPARTMENTS,
	DeptSpecificChangeSceneGetResponse,
} from 'sos-models';
import * as actions from './dept-change-scene.actions';
import * as api from './dept-change-scene.api';
import * as logger from '../../logger';
import {
	changeSceneActions,
	haChangeSceneActions,
	muChangeSceneActions,
} from '../actions';
import * as sceneActions from '../scenes/scene.actions';

export function* deleteByIdAndDeptId({
	episodicId,
	deptId,
	changeSceneId,
}: actions.DeleteChangeSceneByIdAndDeptIdAction) {
	try {
		yield call(
			api.deleteChangeSceneByIdAndDeptId,
			episodicId,
			deptId,
			changeSceneId
		);
		yield put(
			actions.deleteChangeSceneByIdAndDeptIdComplete(changeSceneId, deptId)
		);
	} catch (err) {
		yield put(actions.deleteChangeSceneByIdAndDeptIdFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading the dept change scenes. Please refresh to try again.'
			);
		} else {
			logger.responseError(err, 'loading the dept change scenes', true);
		}
	}
}

export function* get({
	deptChangeSceneId,
	departmentId,
	episodicId,
}: actions.GetAction) {
	try {
		const deptChangeScenes = yield call(
			api.get,
			deptChangeSceneId,
			departmentId,
			episodicId
		);
		if (departmentId === DEPARTMENTS.CM) {
			yield put(changeSceneActions.listComplete(deptChangeScenes));
		} else if (departmentId === DEPARTMENTS.HA) {
			yield put(haChangeSceneActions.listComplete(deptChangeScenes));
		} else if (departmentId === DEPARTMENTS.MU) {
			yield put(muChangeSceneActions.listComplete(deptChangeScenes));
		}
	} catch (err) {
		yield put(actions.getFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading the dept change scenes. Please refresh to try again.'
			);
		} else {
			logger.responseError(err, 'loading the dept change scenes', true);
		}
	}
}

export function* update({
	deptChangeScenes,
	departmentId,
	episodicId,
}: actions.UpdateAction) {
	try {
		const dcs = yield call(
			api.update,
			deptChangeScenes,
			departmentId,
			episodicId
		);
		if (dcs && dcs[0]) {
			if (departmentId === DEPARTMENTS.CM) {
				yield put(changeSceneActions.updateComplete(dcs[0]));
			} else if (departmentId === DEPARTMENTS.HA) {
				yield put(haChangeSceneActions.updateComplete(dcs[0]));
			} else if (departmentId === DEPARTMENTS.MU) {
				yield put(muChangeSceneActions.updateComplete(dcs[0]));
			}
		}
	} catch (err) {
		yield put(actions.updateFailed(err));
		if (err.response) {
			toast.error(
				'There was an error saving the dept change scenes. Please refresh to try again.'
			);
		} else {
			logger.responseError(err, 'saving the dept change scenes', true);
		}
	}
}

export function* listForEpChar({
	episodicId,
	episodicCharacterId,
	departmentId,
}: actions.ListForEpCharAction) {
	try {
		const response: ChangeSceneGetResponse = yield call(
			api.listForEpChar,
			episodicId,
			episodicCharacterId,
			departmentId
		);

		if (response.listed.Scene) {
			yield put(sceneActions.listComplete(response.listed.Scene));
		}

		switch (departmentId) {
			case DEPARTMENTS.CM: {
				const {
					listed,
				}: DeptSpecificChangeSceneGetResponse<DEPARTMENTS.CM> = response as DeptSpecificChangeSceneGetResponse<
					DEPARTMENTS.CM
				>;

				if (listed.ChangeScene) {
					yield put(
						changeSceneActions.listForEpCharComplete(listed.ChangeScene)
					);
				}
				break;
			}
			case DEPARTMENTS.HA: {
				const {
					listed,
				}: DeptSpecificChangeSceneGetResponse<DEPARTMENTS.HA> = response as DeptSpecificChangeSceneGetResponse<
					DEPARTMENTS.HA
				>;

				if (listed.HaChangeScene) {
					yield put(
						haChangeSceneActions.listForEpCharComplete(listed.HaChangeScene)
					);
				}
				break;
			}
			case DEPARTMENTS.MU: {
				const {
					listed,
				}: DeptSpecificChangeSceneGetResponse<DEPARTMENTS.MU> = response as DeptSpecificChangeSceneGetResponse<
					DEPARTMENTS.MU
				>;

				if (listed.MuChangeScene) {
					yield put(
						muChangeSceneActions.listForEpCharComplete(listed.MuChangeScene)
					);
				}
				break;
			}
			default:
				return null;
		}
	} catch (err) {
		yield put(actions.listForEpCharFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading the dept change scenes. Please refresh to try again.'
			);
		} else {
			logger.responseError(err, 'loading the dept change scenes', true);
		}
	}
}

function* saga() {
	yield takeLatest(
		actions.DELETE_CHANGE_SCENE_BY_ID_AND_DEPT_ID,
		deleteByIdAndDeptId
	);
	yield takeLatest(actions.GET, get);
	yield takeEvery(actions.LIST_FOR_EP_CHAR, listForEpChar);
	yield takeEvery(actions.UPDATE, update);
}

export default saga;
