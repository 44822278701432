import {
	EpisodicProp,
	PropScene,
	PropSceneCreateResponse,
	PropSceneCreateWithEpisodicPropResponse,
	PropSceneDeleteResponse,
} from 'sos-models';
import axios from '../../config/axios';

export async function getForEpChar(
	episodicId: number,
	epCharId: number
): Promise<PropScene[]> {
	return axios
		.get<PropScene[]>(
			`/api/episodics/${episodicId}/episodic-characters/${epCharId}/prop-scenes`
		)
		.then((response) => response.data);
}

export async function create(
	episodicId: number,
	episodicPropId: number,
	sceneIds: number[]
): Promise<PropSceneCreateResponse> {
	const { data } = await axios.post<PropSceneCreateResponse>(
		`/api/episodics/${episodicId}/episodic-props/${episodicPropId}/prop-scenes`,
		{ sceneIds }
	);
	return data;
}

export async function createMultiple(
	episodicId: number,
	episodicPropIds: number[],
	sceneIds: number[],
	epCharId?: number
): Promise<PropSceneCreateResponse> {
	const { data } = await axios.post<PropSceneCreateResponse>(
		`/api/episodics/${episodicId}/episodic-props/prop-scenes`,
		{ episodicPropIds, sceneIds, epCharId }
	);
	return data;
}

export async function createByScene(
	episodicId: number,
	sceneId: number,
	originalSceneId: number,
	epPropIds: number[]
): Promise<PropSceneCreateResponse> {
	const { data } = await axios.post<PropSceneCreateResponse>(
		`/api/episodics/${episodicId}/scene/${sceneId}/prop-scenes`,
		{ epPropIds, originalSceneId }
	);
	return data;
}

export async function createSceneEpisodicProp(
	sceneId: number,
	prodId: number,
	episodicId: number,
	episodicProp: EpisodicProp
): Promise<PropSceneCreateWithEpisodicPropResponse> {
	const { data } = await axios.post<PropSceneCreateWithEpisodicPropResponse>(
		`/api/episodics/${episodicId}/productions/${prodId}/scenes/${sceneId}/scene-episodic-props`,
		episodicProp
	);
	return data;
}

export async function destroy(
	episodicId: number,
	episodicPropId: number,
	sceneId: number
): Promise<PropSceneDeleteResponse> {
	return await axios
		.delete(
			`/api/episodics/${episodicId}/prop-scenes/${episodicPropId}/${sceneId}`
		)
		.then((response) => response.data);
}
