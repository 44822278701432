import { PropStatusColor } from 'sos-models';

export const LIST = '[Prop Status Colors] List';
type LIST = typeof LIST;

export const LIST_FAILED = '[Prop Status Colors] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const LIST_COMPLETE = '[Prop Status Colors] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export interface ListAction {
	type: LIST;
}

export interface ListFailedAction {
	type: LIST_FAILED;
	error: Error;
}

export interface ListCompleteAction {
	type: LIST_COMPLETE;
	propStatusColors: PropStatusColor[];
}

export function list(): ListAction {
	return {
		type: LIST,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export function listComplete(
	propStatusColors: PropStatusColor[]
): ListCompleteAction {
	return {
		propStatusColors,
		type: LIST_COMPLETE,
	};
}

export type Action = ListAction | ListFailedAction | ListCompleteAction;
