import { saveAs } from 'file-saver';
import { every } from 'lodash';
import { DEPARTMENTS, DeptEntityId, REPORT_TYPES, SortOrder } from 'sos-models';
import * as actions from './inventory.actions';
import { propSceneActions } from '../actions';
import * as changeEpisodicItemActions from '../change-episodic-items/change-episodic-item.actions';
import * as departmentActions from '../departments/department.actions';
import {
	ActionStatus,
	arrayEqual,
	complete,
	createReducer,
	failed,
	loading,
} from '../utils';

export interface State {
	currentPage: number;
	epCharIdsByEpisodicId: { [episodicId: number]: number[] };
	listStatus: ActionStatus;
	listForWrapBoxStatus: ActionStatus;
	getStatus: ActionStatus;
	updateStatus: ActionStatus;
	createStatus: ActionStatus;
	exportStatus: ActionStatus;
	exportToNewSeasonStatus: ActionStatus;
	pages: DeptEntityId[][];
	pageSize: number;
	removeStatus: ActionStatus;
	removeForWrapBoxStatus: ActionStatus;
	setIdsByEpisodicId: { [episodicId: number]: number[] };
	sortBy: string[];
	sortOrder: SortOrder[];
	search: string;
	searchColumns: string[];
	selectedColumns: string[];
	totalCount: number;
	updateForWrapBoxStatus: ActionStatus;
}

export const initial: State = {
	currentPage: 0,
	epCharIdsByEpisodicId: {},
	listStatus: ActionStatus.Inactive,
	listForWrapBoxStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
	createStatus: ActionStatus.Inactive,
	exportStatus: ActionStatus.Inactive,
	exportToNewSeasonStatus: ActionStatus.Inactive,
	pages: [],
	pageSize: 50,
	removeStatus: ActionStatus.Inactive,
	removeForWrapBoxStatus: ActionStatus.Inactive,
	setIdsByEpisodicId: {},
	sortBy: ['last_updated_time'],
	sortOrder: ['desc'],
	search: '',
	searchColumns: [],
	selectedColumns: [],
	totalCount: 0,
	updateForWrapBoxStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<
	State,
	actions.Action | departmentActions.Action | changeEpisodicItemActions.Action
>(initial, {
	[actions.LIST]: list,
	[actions.LIST_COMPLETE]: listComplete,
	[actions.LIST_FAILED]: failed<State>('listStatus'),
	[actions.LIST_FOR_WRAP_BOX]: loading<State>('listForWrapBoxStatus'),
	[actions.LIST_FOR_WRAP_BOX_FAILED]: failed<State>('listForWrapBoxStatus'),
	[actions.EXPORT_TO_NEW_SEASON]: loading<State>('exportToNewSeasonStatus'),
	[actions.EXPORT_TO_NEW_SEASON_COMPLETE]: complete<State>(
		'exportToNewSeasonStatus'
	),
	[actions.EXPORT_TO_NEW_SEASON_FAILED]: failed<State>(
		'exportToNewSeasonStatus'
	),
	[actions.GET]: loading<State>('getStatus'),
	[actions.GET_COMPLETE]: getComplete,
	[actions.GET_FAILED]: failed<State>('getStatus'),
	[actions.REMOVE]: loading<State>('removeStatus'),
	[actions.REMOVE_COMPLETE]: removeComplete,
	[actions.REMOVE_FAILED]: failed<State>('removeStatus'),
	[actions.REMOVE_FROM_WRAP_BOX]: loading<State>('removeForWrapBoxStatus'),
	[actions.REMOVE_FROM_WRAP_BOX_COMPLETE]: complete<State>(
		'removeForWrapBoxStatus'
	),
	[actions.REMOVE_FROM_WRAP_BOX_FAILED]: failed<State>(
		'removeForWrapBoxStatus'
	),
	[actions.RESET_CREATE_STATUS]: resetCreateStatus,
	[actions.UPDATE]: loading<State>('updateStatus'),
	[actions.UPDATE_COMPLETE]: updateComplete,
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
	[actions.UPDATE_INVENTORY_WRAP_BOX]: loading<State>('updateForWrapBoxStatus'),
	[actions.UPDATE_INVENTORY_WRAP_BOX_FAILED]: failed<State>(
		'updateForWrapBoxStatus'
	),
	[actions.UPDATE_INVENTORY_WRAP_BOX_COMPLETE]: updateInventoryWrapBoxComplete,
	[actions.CREATE]: loading<State>('createStatus'),
	[actions.CREATE_COMPLETE]: createComplete,
	[actions.CREATE_FAILED]: failed<State>('createStatus'),
	[actions.EXPORT_INVENTORY]: loading<State>('exportStatus'),
	[actions.EXPORT_COMPLETE]: exportComplete,
	[actions.EXPORT_FAILED]: failed<State>('exportStatus'),
	[departmentActions.SELECT]: deptSelectComplete,
	[propSceneActions.CREATE_SCENE_EPISODIC_PROP]: loading<State>('createStatus'),
	[changeEpisodicItemActions.CREATE_CHANGE_EPISODIC_ITEM]: loading<State>(
		'createStatus'
	),
	[changeEpisodicItemActions.CREATE_CHANGE_EPISODIC_ITEM_COMPLETE]: complete<
		State
	>('createStatus'),
	[changeEpisodicItemActions.CREATE_CHANGE_EPISODIC_ITEM_FAILED]: failed<State>(
		'createStatus'
	),
});

function deptSelectComplete(
	state: State,
	{ selectedIds }: departmentActions.SelectAction
) {
	if (
		selectedIds.length === 0 ||
		every(
			selectedIds,
			(id) => [DEPARTMENTS.HA, DEPARTMENTS.MU].indexOf(id) >= 0
		)
	) {
		return {
			...state,
			currentPage: 0,
			pages: [],
		};
	}
	return state;
}

function getComplete(state: State) {
	return {
		...state,
		getStatus: ActionStatus.Complete,
	};
}

function list(state: State, { options }: actions.ListAction): State {
	let pages = state.pages;

	if (
		options.pageSize !== state.pageSize ||
		!arrayEqual(options.sortBy, state.sortBy) ||
		!arrayEqual(options.sortOrder, state.sortOrder)
	) {
		pages = [];
	}
	return {
		...state,
		...options,
		listStatus: ActionStatus.Loading,
		pages,
	};
}

function listComplete(
	state: State,
	{ inventory, totalCount, options }: actions.ListCompleteAction
): State {
	const {
		episodicId,
		episodicCharacterIds,
		page, //currentPage
		pageSize,
		setIds,
		sortBy,
		sortOrder,
		search,
	} = options;
	let pages = [];
	for (let pageCount = 0; pageCount <= page; pageCount++) {
		if (pageCount === page) {
			pages[pageCount] = inventory.map((inv) => ({
				department: inv.department,
				id: inv.id,
			}));
		} else {
			pages[pageCount] = [];
		}
	}
	pages[page] = inventory.map((inv) => ({
		department: inv.department,
		id: inv.id,
	}));
	let currentPage = options.page;
	if (currentPage > Math.ceil(totalCount / pageSize) - 1) {
		currentPage = 0;
	}
	return {
		...state,
		currentPage: currentPage,
		epCharIdsByEpisodicId: {
			...state.epCharIdsByEpisodicId,
			[episodicId]: episodicCharacterIds,
		},
		listStatus: ActionStatus.Complete,
		pages,
		pageSize,
		setIdsByEpisodicId: {
			...state.setIdsByEpisodicId,
			[episodicId]: setIds,
		},
		sortBy,
		sortOrder,
		search,
		totalCount,
	};
}

function removeComplete(
	state: State,
	{ id, department }: actions.RemoveCompleteAction
): State {
	let pages: DeptEntityId[][] = [];

	state.pages.forEach((page) => {
		//make sure there is no null or undefined item
		let filteredPageNoNull = page.filter((x) => x !== undefined && x !== null);
		pages.push(
			filteredPageNoNull.filter(
				(invId) => invId.id !== id || invId.department !== department
			)
		);
	});

	return {
		...state,
		pages,
		removeStatus: ActionStatus.Complete,
	};
}

function updateComplete(state: State) {
	return { ...state, updateStatus: ActionStatus.Complete };
}

function updateInventoryWrapBoxComplete(state: State): State {
	return { ...state, updateForWrapBoxStatus: ActionStatus.Complete };
}

function createComplete(
	state: State,
	{ inventory }: actions.CreateCompleteAction
) {
	const newIds = inventory.map((inv) => {
		return { department: inv.department, id: inv.id };
	});

	const ids = state.pages[state.currentPage]
		? [...newIds, ...state.pages[state.currentPage]]
		: [...newIds];

	const pages = [...state.pages];
	pages[state.currentPage] = ids;

	return {
		...state,
		pages,
		createStatus: ActionStatus.Complete,
	};
}

function exportComplete(
	state: State,
	{ fileContent, reportType }: actions.ExportCompleteAction
) {
	if (reportType === REPORT_TYPES.CSV) {
		const blob = new Blob([fileContent], { type: 'text/csv' });
		saveAs(blob, 'Inventory_Export.csv');
	} else if (reportType === REPORT_TYPES.PDF) {
		const blob = new Blob([fileContent], { type: 'application/pdf' });
		saveAs(blob, 'Inventory_Export.pdf');
	}
	return {
		...state,
		exportStatus: ActionStatus.Complete,
	};
}

function resetCreateStatus(state: State) {
	return {
		...state,
		createStatus: ActionStatus.Inactive,
	};
}
