import { normalize } from 'normalizr';
import { DepartmentSetting } from 'sos-models';
import * as episodicActions from '../episodics/episodic.actions';
import { departmentSettingSchema } from '../schemas';
import { ActionStatus, createReducer, failed, loading } from '../utils';

export interface State {
	entities: { [id: number]: DepartmentSetting };
	ids: number[];
	loading: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	loading: ActionStatus.Inactive,
};

export const reducer = createReducer<State, episodicActions.Action>(initial, {
	[episodicActions.GET]: loading<State>('loading'),
	[episodicActions.GET_COMPLETE]: getEpisodicComplete,
	[episodicActions.GET_FAILED]: failed<State>('loading'),
});

function getEpisodicComplete(
	state: State,
	action: episodicActions.GetCompleteAction
): State {
	if (typeof action.episodic.departmentSettings === 'undefined') {
		// should never happen
		return state;
	}
	const normalized = normalize(action.episodic.departmentSettings, [
		departmentSettingSchema,
	]);

	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.departmentSettings,
		},
		ids: [...normalized.result],
		loading: ActionStatus.Complete,
	};
}
