import {
	CreateDeleteChangeScene,
	DEPARTMENTS,
	HaChange,
	HaChangeScene,
} from 'sos-models';
import axios from '../../config/axios';

export function getForScene(
	prodId: number,
	sceneId: number
): Promise<Partial<HaChangeScene>[]> {
	return axios
		.get<Partial<HaChangeScene>[]>(
			`/api/productions/${prodId}/scenes/${sceneId}/ha-change-scenes`
		)
		.then((response) => response.data);
}

export function getForEpCharId(
	episodicId: number,
	epCharId: number
): Promise<Partial<HaChangeScene>[]> {
	return axios
		.get<Partial<HaChangeScene>[]>(
			`/api/episodics/${episodicId}/episodic-characters/${epCharId}/ha-change-scenes`
		)
		.then((response) => response.data);
}

export function createAndDeleteExisting(
	episodicId: number,
	department: DEPARTMENTS,
	data: CreateDeleteChangeScene[]
): Promise<{
	created: { HaChangeScene: HaChangeScene[] };
	deleted: { HaChangeScene: HaChangeScene[] };
	updated: { HaChange: HaChange[] };
}> {
	return axios
		.post(`/api/episodics/${episodicId}/change-scenes/${department}`, data)
		.then((response) => response.data);
}

export function update(
	episodicId: number,
	changeScene: Partial<HaChangeScene>
): Promise<HaChangeScene> {
	return axios
		.put(
			`/api/episodics/${episodicId}/change-scenes/${DEPARTMENTS.HA}`,
			changeScene
		)
		.then((response) => response.data);
}
