import { takeEvery, takeLatest } from 'redux-saga/effects';
import * as actions from './production.actions';
import { IFrameService } from '../../services/iframe.service';

export function* select({ selectedIds, initial }: actions.SelectAction) {
	if (!initial) {
		yield IFrameService.sendMessage({ type: actions.SELECT, selectedIds });
	}
}

export function* selectAll({ selectedIds }: actions.SelectAllAction) {
	yield IFrameService.sendMessage({ type: actions.SELECT_ALL, selectedIds });
}

export function* deSelectAll({}: actions.DeSelectAllAction) {
	yield IFrameService.sendMessage({ type: actions.DE_SELECT_ALL });
}

function* saga() {
	yield takeLatest(actions.SELECT, select);
	yield takeEvery(actions.SELECT_ALL, selectAll);
	yield takeEvery(actions.DE_SELECT_ALL, deSelectAll);
}

export default saga;
