import * as cookie from 'cookie';

export class CookieService {
	private static get cookie() {
		if (document && document.cookie) {
			return document.cookie;
		}
		return '';
	}

	static get(key: string) {
		const cookies = cookie.parse(this.cookie);
		if (cookies && cookies[key]) {
			return cookies[key];
		}
		return null;
	}
}
