import { Change, ChangeScene, CreateDeleteChangeScene } from 'sos-models';

export const CREATE_AND_DELETE_EXISTING =
	'[ChangeScene] Create ChangeScene And Delete Existing';
type CREATE_AND_DELETE_EXISTING = typeof CREATE_AND_DELETE_EXISTING;

export const CREATE_AND_DELETE_EXISTING_COMPLETE =
	'[ChangeScene] Create ChangeScene And Delete Existing Complete';
type CREATE_AND_DELETE_EXISTING_COMPLETE = typeof CREATE_AND_DELETE_EXISTING_COMPLETE;

export const CREATE_AND_DELETE_EXISTING_FAILED =
	'[ChangeScene] Create ChangeScene And Delete Existing Failed';
type CREATE_AND_DELETE_EXISTING_FAILED = typeof CREATE_AND_DELETE_EXISTING_FAILED;

export const CREATE_COMPLETE = '[ChangeScene] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_COMPLETE =
	'[ChangeScene] Delete CharScene By EpChar Id And Scene Id Complete';
type DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_COMPLETE = typeof DELETE_CHAR_SCENE_BY_EP_CHAR_ID_AND_SCENE_ID_COMPLETE;

export const DELETE_COMPLETE = '[ChangeScene] Delete Complete';
type DELETE_COMPLETE = typeof DELETE_COMPLETE;

export const GET_CHANGE_SCENES_FOR_SCENE =
	'[ChangeScene] Get Changes For Scene';
type GET_CHANGE_SCENES_FOR_SCENE = typeof GET_CHANGE_SCENES_FOR_SCENE;

export const GET_CHANGE_SCENES_FOR_SCENE_COMPLETE =
	'[ChangeScene] Get Changes For Scene Complete';
type GET_CHANGE_SCENES_FOR_SCENE_COMPLETE = typeof GET_CHANGE_SCENES_FOR_SCENE_COMPLETE;

export const GET_CHANGE_SCENES_FOR_SCENE_FAILED =
	'[ChangeScene] Get Changes For Scene Failed';
type GET_CHANGE_SCENES_FOR_SCENE_FAILED = typeof GET_CHANGE_SCENES_FOR_SCENE_FAILED;

export const LIST_COMPLETE = '[ChangeScene] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FOR_EP_CHAR = '[ChangeScene] List For Episodic Character';
type LIST_FOR_EP_CHAR = typeof LIST_FOR_EP_CHAR;

export const LIST_FOR_EP_CHAR_COMPLETE =
	'[ChangeScene] List For Episodic Character Complete';
type LIST_FOR_EP_CHAR_COMPLETE = typeof LIST_FOR_EP_CHAR_COMPLETE;

export const LIST_FOR_EP_CHAR_FAILED =
	'[ChangeScene] List For Episodic Character Failed';
type LIST_FOR_EP_CHAR_FAILED = typeof LIST_FOR_EP_CHAR_FAILED;

export const SET_CHANGE_NUM = '[ChangeScene] Set Change Num';
type SET_CHANGE_NUM = typeof SET_CHANGE_NUM;

export const UPDATE = '[ChangeScene] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[ChangeScene] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[ChangeScene] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export interface CreateAndDeleteExistingAction {
	type: CREATE_AND_DELETE_EXISTING;
	episodicId: number;
	changeScenes: CreateDeleteChangeScene[];
}

export interface CreateAndDeleteExistingCompleteAction {
	type: CREATE_AND_DELETE_EXISTING_COMPLETE;
	created: { ChangeScene: ChangeScene[] };
	deleted: { ChangeScene: ChangeScene[] };
	updated: {
		Change: Change[];
		ChangeScene: ChangeScene[];
	};
}

export interface CreateAndDeleteExistingFailedAction {
	type: CREATE_AND_DELETE_EXISTING_FAILED;
	error: Error;
}

export interface CreateCompleteAction {
	changeScenes: ChangeScene[];
	type: CREATE_COMPLETE;
}

export interface DeleteCompleteAction {
	type: DELETE_COMPLETE;
	changeScenes: ChangeScene[];
}

export interface GetChangeScenesForSceneAction {
	type: GET_CHANGE_SCENES_FOR_SCENE;
	prodId: number;
	sceneId: number;
}

export interface GetChangeScenesForSceneCompleteAction {
	type: GET_CHANGE_SCENES_FOR_SCENE_COMPLETE;
	changeScenes: ChangeScene[];
	sceneId: number;
}

export interface GetChangeScenesForSceneFailedAction {
	type: GET_CHANGE_SCENES_FOR_SCENE_FAILED;
	error: Error;
}

export interface ListCompleteAction {
	changeScenes: ChangeScene[];
	type: LIST_COMPLETE;
}

export interface ListForEpCharAction {
	epCharId: number;
	episodicId: number;
	type: LIST_FOR_EP_CHAR;
}

export interface ListForEpCharCompleteAction {
	changeScenes: ChangeScene[];
	type: LIST_FOR_EP_CHAR_COMPLETE;
}

export interface ListForEpCharFailedAction {
	error: Error;
	type: LIST_FOR_EP_CHAR_FAILED;
}

export interface SetChangeNum {
	type: SET_CHANGE_NUM;
	rowChange: { [key: string]: any }; // tslint:disable-line:no-any
}

export interface UpdateAction {
	type: UPDATE;
	episodicId: number;
	changeScene: Partial<ChangeScene>;
}

export interface UpdateCompleteAction {
	type: UPDATE_COMPLETE;
	updated: ChangeScene;
}

export interface UpdateFailedAction {
	type: UPDATE_FAILED;
	error: Error;
}

export function createComplete(
	changeScenes: ChangeScene[]
): CreateCompleteAction {
	return {
		changeScenes,
		type: CREATE_COMPLETE,
	};
}

export function deleteComplete(
	changeScenes: ChangeScene[]
): DeleteCompleteAction {
	return {
		changeScenes,
		type: DELETE_COMPLETE,
	};
}

export function getChangeScenesForScene(
	prodId: number,
	sceneId: number
): GetChangeScenesForSceneAction {
	return {
		prodId,
		sceneId,
		type: GET_CHANGE_SCENES_FOR_SCENE,
	};
}

export function getChangeScenesForSceneComplete(
	changeScenes: ChangeScene[],
	sceneId: number
): GetChangeScenesForSceneCompleteAction {
	return {
		changeScenes,
		sceneId,
		type: GET_CHANGE_SCENES_FOR_SCENE_COMPLETE,
	};
}

export function getChangeScenesForSceneFailed(
	error: Error
): GetChangeScenesForSceneFailedAction {
	return {
		error,
		type: GET_CHANGE_SCENES_FOR_SCENE_FAILED,
	};
}

export function listComplete(changeScenes: ChangeScene[]): ListCompleteAction {
	return {
		changeScenes,
		type: LIST_COMPLETE,
	};
}

export function listForEpChar(
	episodicId: number,
	epCharId: number
): ListForEpCharAction {
	return {
		episodicId,
		epCharId,
		type: LIST_FOR_EP_CHAR,
	};
}

export function listForEpCharComplete(
	changeScenes: ChangeScene[]
): ListForEpCharCompleteAction {
	return {
		changeScenes,
		type: LIST_FOR_EP_CHAR_COMPLETE,
	};
}

export function listForEpCharFailed(error: Error): ListForEpCharFailedAction {
	return {
		error,
		type: LIST_FOR_EP_CHAR_FAILED,
	};
}

export function createAndDeleteExisting(
	episodicId: number,
	changeScenes: CreateDeleteChangeScene[]
): CreateAndDeleteExistingAction {
	return {
		episodicId,
		changeScenes,
		type: CREATE_AND_DELETE_EXISTING,
	};
}

export function createAndDeleteExistingComplete(
	created: { ChangeScene: ChangeScene[] },
	deleted: { ChangeScene: ChangeScene[] },
	updated: { Change: Change[]; ChangeScene: ChangeScene[] }
): CreateAndDeleteExistingCompleteAction {
	return {
		created,
		deleted,
		updated,
		type: CREATE_AND_DELETE_EXISTING_COMPLETE,
	};
}

export function createAndDeleteExistingFailed(
	error: Error
): CreateAndDeleteExistingFailedAction {
	return {
		error,
		type: CREATE_AND_DELETE_EXISTING_FAILED,
	};
}

export function setChangeNum(rowChange: { [key: string]: any }): SetChangeNum {
	// tslint:disable-line:no-any
	return {
		type: SET_CHANGE_NUM,
		rowChange,
	};
}

export function update(
	episodicId: number,
	changeScene: Partial<ChangeScene>
): UpdateAction {
	return {
		episodicId,
		changeScene,
		type: UPDATE,
	};
}

export function updateComplete(updated: ChangeScene): UpdateCompleteAction {
	return {
		updated,
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export type Action =
	| CreateCompleteAction
	| DeleteCompleteAction
	| GetChangeScenesForSceneAction
	| GetChangeScenesForSceneCompleteAction
	| GetChangeScenesForSceneFailedAction
	| ListCompleteAction
	| ListForEpCharAction
	| ListForEpCharCompleteAction
	| ListForEpCharFailedAction
	| CreateAndDeleteExistingAction
	| CreateAndDeleteExistingCompleteAction
	| CreateAndDeleteExistingFailedAction
	| SetChangeNum
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction;
