import { SetPieceType } from 'sos-models';

export const CREATE = '[Set Piece Type] Create';
type CREATE = typeof CREATE;

export const CREATE_FAILED = '[Set Piece Type] Create Failed';
type CREATE_FAILED = typeof CREATE_FAILED;

export const CREATE_COMPLETE = '[Set Piece Type] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const DESTROY = '[Set Piece Type] Destroy';
type DESTROY = typeof DESTROY;

export const DESTROY_FAILED = '[Set Piece Type] Destroy Failed';
type DESTROY_FAILED = typeof DESTROY_FAILED;

export const DESTROY_COMPLETE = '[Set Piece Type] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const UPDATE = '[Set Piece Type] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_FAILED = '[Set Piece Type] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const UPDATE_COMPLETE = '[Set Piece Type] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export interface CreateAction {
	setPieceType: SetPieceType;
	type: CREATE;
}

export interface CreateFailedAction {
	error: Error;
	type: CREATE_FAILED;
}

export interface CreateCompleteAction {
	setPieceType: SetPieceType;
	type: CREATE_COMPLETE;
}

export interface DestroyAction {
	setPieceType: SetPieceType;
	type: DESTROY;
}

export interface DestroyFailedAction {
	error: Error;
	type: DESTROY_FAILED;
}

export interface DestroyCompleteAction {
	setPieceType: SetPieceType;
	type: DESTROY_COMPLETE;
}

export interface UpdateAction {
	setPieceType: SetPieceType;
	type: UPDATE;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export interface UpdateCompleteAction {
	setPieceType: SetPieceType;
	type: UPDATE_COMPLETE;
}

export function create(setPieceType: SetPieceType): CreateAction {
	return {
		setPieceType,
		type: CREATE,
	};
}

export function createFailed(error: Error): CreateFailedAction {
	return {
		error,
		type: CREATE_FAILED,
	};
}

export function createComplete(
	setPieceType: SetPieceType
): CreateCompleteAction {
	return {
		setPieceType,
		type: CREATE_COMPLETE,
	};
}

export function destroy(setPieceType: SetPieceType): DestroyAction {
	return {
		setPieceType,
		type: DESTROY,
	};
}

export function destroyFailed(error: Error): DestroyFailedAction {
	return {
		error,
		type: DESTROY_FAILED,
	};
}

export function destroyComplete(
	setPieceType: SetPieceType
): DestroyCompleteAction {
	return {
		setPieceType,
		type: DESTROY_COMPLETE,
	};
}

export function update(setPieceType: SetPieceType): UpdateAction {
	return {
		setPieceType,
		type: UPDATE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function updateComplete(
	setPieceType: SetPieceType
): UpdateCompleteAction {
	return {
		setPieceType,
		type: UPDATE_COMPLETE,
	};
}

export type Action =
	| CreateAction
	| CreateFailedAction
	| CreateCompleteAction
	| DestroyAction
	| DestroyFailedAction
	| DestroyCompleteAction
	| UpdateAction
	| UpdateFailedAction
	| UpdateCompleteAction;
