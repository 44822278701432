import { call, put, takeLatest } from 'redux-saga/effects';
import {
	EpisodicPropsForCharacterListResponse,
	EpisodicPropsWithoutCharacterListResponse,
	ScenePropListResponse,
} from 'sos-models';
import * as actions from './episodic-prop.actions';
import * as api from './episodic-prop.api';
import * as logger from '../../logger';
import * as characterPropActions from '../character-props/character-prop.actions';
import * as characterPropApi from '../character-props/character-prop.api';
import * as propSceneCharSceneActions from '../prop-scene-char-scenes/prop-scene-char-scene.actions';
import * as propSceneActions from '../prop-scenes/prop-scene.actions';
import { toast } from 'react-toastify';

export function* exportToCSV({
	episodicId,
	prodId,
	sortQuery,
}: actions.ExportToCSVAction) {
	try {
		const results = yield call(api.exportToCSV, episodicId, prodId, sortQuery);
		yield put(actions.exportToCSVComplete(results));
	} catch (err) {
		yield put(actions.exportToCSVFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			// the error is not from the ajax call
			logger.error(err);
		}
	}
}

export function* getForCharScenes({
	episodicId,
	episodicCharacterId,
}: actions.GetForCharScenesAction) {
	try {
		const episodicProps = yield call(
			api.getForCharScenes,
			episodicId,
			episodicCharacterId
		);
		yield put(actions.getForCharScenesComplete(episodicProps));
	} catch (err) {
		yield put(actions.getForCharScenesFailed(err));
		logger.responseError(err, 'loading the episodic props', true);
	}
}

export function* listForScene({
	episodicId,
	sceneId,
}: actions.ListForSceneAction) {
	try {
		const result: ScenePropListResponse = yield call(
			api.listForScene,
			sceneId,
			episodicId
		);

		yield put(actions.listComplete(result.listed.EpisodicProp));
		yield put(propSceneActions.listComplete(result.listed.PropScene));
		yield put(
			propSceneCharSceneActions.listComplete(result.listed.PropSceneCharScene)
		);
	} catch (err) {
		yield put(actions.listForSceneFailed(err));
		logger.responseError(err, 'loading episodic props for scene', true);
	}
}

export function* listNoCloset({ episodicId }: actions.ListNoClosetAction) {
	try {
		const result: EpisodicPropsWithoutCharacterListResponse = yield call(
			api.listNoClosetEpProps,
			episodicId
		);
		if (result.listed.EpisodicProp) {
			yield put(actions.listComplete(result.listed.EpisodicProp));
		}
	} catch (err) {
		yield put(actions.listNoClosetFailed(err));
		logger.responseError(err, 'loading the episodic props', true);
	}
}

export function* listForEpisodicCharacter({
	episodicId,
	episodicCharId,
}: characterPropActions.ListAction) {
	try {
		const { listed }: EpisodicPropsForCharacterListResponse = yield call(
			characterPropApi.list,
			episodicCharId,
			episodicId
		);
		yield put(characterPropActions.listComplete(listed.EpisodicProp));
	} catch (err) {
		yield put(characterPropActions.listFailed(err));
		logger.responseError(err, 'loading the character props', true);
	}
}

function* saga() {
	yield takeLatest(actions.EXPORT_TO_CSV, exportToCSV);
	yield takeLatest(actions.GET_FOR_CHAR_SCENES, getForCharScenes);
	yield takeLatest(actions.LIST_FOR_SCENE, listForScene);
	yield takeLatest(actions.LIST_NO_CLOSET, listNoCloset);
	yield takeLatest(characterPropActions.LIST, listForEpisodicCharacter);
}

export default saga;
