export var setupIntercom = function (env) {
	var app_id;
	if (process.env.SOS_ENV === 'prod') {
		app_id = 'twidi4l3';
	} else {
		app_id = 'atuozw3k';
	}
	window.intercomSettings = {
		app_id: app_id
	};

	(function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/' + app_id; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();

	(function(w, u, d) { if (typeof u !== "function") { var i = function() { i.c(arguments) }; i.q = []; i.c = function(args) { i.q.push(args) }; w.Upscope = i; var l = function() { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://code.upscope.io/YgMLPTYAZrC67owgqDKekpLF.js'; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; l(); } })(window, window.Upscope, document);

	Upscope('init', {
		uniqueId: $('#intercom_user_id').val(),
		identities: [$('#intercom_name').val(), $('#intercom_user_email').val()],
		requireAuthorizationForSession: true,
		onSessionRequest: function(cb) {
			console.info('Upscope session request received');
			cb(confirm('SyncOnSet is requesting access to view your screen. Allow?'));
		}
	});
};
