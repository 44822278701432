import {
	Change,
	ChangeScene,
	ChangeUpdateResponse,
	DEPARTMENTS,
	DeptSpecificChangeLookDeleteResponse,
	DeptSpecificChangeLookGetResponse,
} from 'sos-models';
import axios from '../../config/axios';

export async function create(
	episodicId: number,
	department: DEPARTMENTS,
	change: Partial<Change>
): Promise<Partial<Change>> {
	const { data } = await axios.post<Partial<Change>>(
		`/api/episodics/${episodicId}/change-looks/${department}`,
		change
	);
	return data;
}

export async function createByScene(
	episodicId: number,
	department: DEPARTMENTS,
	change: Partial<Change>,
	sceneId: number,
	oldChange?: Change
): Promise<{
	created: { Change: Change[]; ChangeScene: ChangeScene[] };
	deleted: { ChangeScene: ChangeScene[] };
	updated: { Change: Change[] };
}> {
	const {
		data,
	} = await axios.post(
		`/api/episodics/${episodicId}/change-looks/${department}/scenes/${sceneId}`,
		{ change, oldChange }
	);
	return data;
}

export async function deleteForEpChar(
	episodicId: number,
	epCharId: number,
	changeId: number
): Promise<DeptSpecificChangeLookDeleteResponse<DEPARTMENTS.CM>> {
	const { data } = await axios.delete(
		`/api/episodics/${episodicId}/episodic-characters/${epCharId}/changes/${changeId}`
	);
	return data;
}

export async function getChangesForScene(
	prodId: number,
	sceneId: number
): Promise<DeptSpecificChangeLookGetResponse<DEPARTMENTS.CM>> {
	const { data } = await axios.get<
		DeptSpecificChangeLookGetResponse<DEPARTMENTS.CM>
	>(`/api/productions/${prodId}/scenes/${sceneId}/changes`);
	return data;
}

export async function getForEpItem(
	episodicId: number,
	epItemId: number
): Promise<DeptSpecificChangeLookGetResponse<DEPARTMENTS.CM>> {
	const { data } = await axios.get<
		DeptSpecificChangeLookGetResponse<DEPARTMENTS.CM>
	>(`/api/episodics/${episodicId}/episodic-items/${epItemId}/changes`);
	return data;
}

export async function getForEpChar(
	episodicId: number,
	epCharId: number
): Promise<DeptSpecificChangeLookGetResponse<DEPARTMENTS.CM>> {
	const { data } = await axios.get<
		DeptSpecificChangeLookGetResponse<DEPARTMENTS.CM>
	>(`/api/episodics/${episodicId}/episodic-characters/${epCharId}/changes`);
	return data;
}

export async function update(
	episodicId: number,
	prodId: number,
	changes: Partial<Change>[]
): Promise<ChangeUpdateResponse> {
	const { data } = await axios.put<ChangeUpdateResponse>(
		`/api/episodics/${episodicId}/productions/${prodId}/changes`,
		changes
	);
	return data;
}
