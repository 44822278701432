import { normalize } from 'normalizr';
import { Reducer } from 'redux';
import { Picture } from 'sos-models';
import * as actions from './picture.actions';
import * as inventoryPictureActions from '../inventory-pictures/inventory-picture.actions';
import { pictureSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	complete,
	createReducer,
	destroyComplete,
	failed,
	getComplete,
	listComplete,
	loading,
	NormalizedEntityMapping,
	uniqueArrayMerge,
	updateComplete,
} from '../utils';

export interface State extends BaseReducerState<Picture> {
	currentId: number;
	destroyStatus: ActionStatus;
	entities: NormalizedEntityMapping<Picture>;
	getCurrentForListStatus: ActionStatus;
	getStatus: ActionStatus;
	listPhotoTagsStatus: ActionStatus;
	listStatus: ActionStatus;
	rotateStatus: ActionStatus;
	setMainPhotoStatus: ActionStatus;
	uploadCount: number;
	updateStatus: ActionStatus;
}

const initial: State = {
	currentId: null,
	destroyStatus: ActionStatus.Inactive,
	entities: {},
	getCurrentForListStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
	ids: [],
	listPhotoTagsStatus: ActionStatus.Inactive,
	listStatus: ActionStatus.Inactive,
	rotateStatus: ActionStatus.Inactive,
	setMainPhotoStatus: ActionStatus.Inactive,
	uploadCount: 0,
	updateStatus: ActionStatus.Inactive,
};

export const reducer: Reducer<State> = createReducer<
	State,
	actions.Action | inventoryPictureActions.Action
>(initial, {
	[actions.GET_COMPLETE]: getComplete<
		Picture,
		State,
		actions.GetCompleteAction
	>('picture', pictureSchema),
	[actions.GET_FAILED]: failed<State>('getStatus'),
	[actions.GET]: loading<State>('getStatus'),
	[actions.GET_CURRENT_FOR_LIST_COMPLETE]: getCurrentForListComplete,
	[actions.GET_CURRENT_FOR_LIST_FAILED]: failed<State>('getStatus'),
	[actions.GET_CURRENT_FOR_LIST]: loading<State>('getStatus'),
	[actions.DESTROY_COMPLETE]: destroyComplete<
		Picture,
		State,
		actions.DestroyCompleteAction
	>('picture'),
	[actions.DESTROY_FAILED]: failed<State>('destroyStatus'),
	[actions.DESTROY]: loading<State>('destroyStatus'),
	[actions.UPDATE_COMPLETE]: updateComplete<
		Picture,
		State,
		actions.UpdateCompleteAction
	>('picture', pictureSchema),
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
	[actions.UPDATE]: loading<State>('updateStatus'),
	[actions.ROTATE_COMPLETE]: rotateComplete,
	[actions.ROTATE_FAILED]: failed<State>('rotateStatus'),
	[actions.ROTATE]: loading<State>('rotateStatus'),
	[actions.SET_MAIN_PHOTO_COMPLETE]: complete<State>('setMainPhotoStatus'),
	[actions.SET_MAIN_PHOTO_FAILED]: failed<State>('setMainPhotoStatus'),
	[actions.SET_MAIN_PHOTO]: loading<State>('setMainPhotoStatus'),
	[actions.LIST_PHOTO_TAGS_COMPLETE]: complete<State>('listPhotoTagsStatus'),
	[actions.LIST_PHOTO_TAGS_FAILED]: failed<State>('listPhotoTagsStatus'),
	[actions.LIST_PHOTO_TAGS]: loading<State>('listPhotoTagsStatus'),
	[actions.UPLOAD_COMPLETE]: uploadCompleteOrFailed,
	[actions.UPLOAD_FAILED]: uploadCompleteOrFailed,
	[inventoryPictureActions.LIST]: loading<State>('listStatus'),
	[inventoryPictureActions.LIST_COMPLETE]: listComplete<
		Picture,
		State,
		inventoryPictureActions.ListCompleteAction
	>('pictures', pictureSchema),
	[inventoryPictureActions.LIST_FAILED]: failed<State>('listStatus'),
	[actions.LIST_COMPLETE]: listComplete<
		Picture,
		State,
		actions.ListCompleteAction
	>('pictures', pictureSchema),
});

function getCurrentForListComplete(
	state: State,
	action: actions.GetCurrentForListCompleteAction
): State {
	const normalized = normalize(action.picture, pictureSchema);
	return {
		...state,
		currentId: action.picture.id,
		entities: {
			...state.entities,
			...normalized.entities.pictures,
		},
		ids: uniqueArrayMerge(state.ids, [normalized.result]),
		getCurrentForListStatus: ActionStatus.Complete,
	};
}

function rotateComplete(
	state: State,
	action: actions.RotateCompleteAction
): State {
	const normalized = normalize(action.picture, pictureSchema);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.pictures,
		},
		ids: uniqueArrayMerge(state.ids, [normalized.result]),
		rotateStatus: ActionStatus.Complete,
	};
}

function uploadCompleteOrFailed(
	state: State,
	action: actions.UploadCompleteAction | actions.UploadFailedAction
): State {
	return {
		...state,
		uploadCount: state.uploadCount + 1,
	};
}
