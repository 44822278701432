import { Vendor } from 'sos-models';
import axios from '../../config/axios';

export function create(
	vendor: Partial<Vendor>,
	episodicId: number,
	department: number
): Promise<Partial<Vendor>> {
	return axios
		.post<Partial<Vendor>>(
			`/api/episodics/${episodicId}/department/${department}/vendors`,
			vendor
		)
		.then((response) => response.data);
}

export function list(episodicId: number): Promise<Partial<Vendor>[]> {
	return axios
		.get<Partial<Vendor>[]>(`/api/episodics/${episodicId}/vendors`)
		.then((response) => response.data);
}
