import { SetPieceType } from 'sos-models';
import axios from '../../config/axios';

export function create(
	setPieceType: Partial<SetPieceType>
): Promise<Partial<SetPieceType>> {
	return axios
		.post<Partial<SetPieceType>>(`/api/set-piece-types`, setPieceType)
		.then((response) => response.data);
}

export function destroy(setPieceType: Partial<SetPieceType>) {
	return axios
		.delete(`/api/set-piece-types/${setPieceType.id}`)
		.then((response) => response.data);
}

export function update(
	setPieceType: Partial<SetPieceType>
): Promise<Partial<SetPieceType>> {
	return axios
		.put<Partial<SetPieceType>>(
			`/api/set-piece-types/${setPieceType.id}`,
			setPieceType
		)
		.then((response) => response.data);
}
