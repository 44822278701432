import { UnknownAction } from 'redux';
import {
	Character,
	EpisodicActor,
	EpisodicCharacter,
	EpisodicCharacterMergeConflictGetResponse,
	EpisodicCharacterMergePayload,
	UpdateMultipleEpCharPayload,
} from 'sos-models';

export const DESTROY = '[Episodic Character] Destroy';
type DESTROY = typeof DESTROY;

export const DESTROY_FAILED = '[Episodic Character] Destroy Failed';
type DESTROY_FAILED = typeof DESTROY_FAILED;

export const DESTROY_COMPLETE = '[Episodic Character] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const EXPORT_TO_CSV = '[Episodic Character] Export to CSV';
type EXPORT_TO_CSV = typeof EXPORT_TO_CSV;

export const EXPORT_TO_CSV_SECONDARY =
	'[Episodic Character] Export to CSV Secondary';
type EXPORT_TO_CSV_SECONDARY = typeof EXPORT_TO_CSV_SECONDARY;

export const EXPORT_TO_CSV_FAILED = '[Episodic Character] Export to CSV Failed';
type EXPORT_TO_CSV_FAILED = typeof EXPORT_TO_CSV_FAILED;

export const EXPORT_TO_CSV_COMPLETE =
	'[Episodic Character] Export to CSV Complete';
type EXPORT_TO_CSV_COMPLETE = typeof EXPORT_TO_CSV_COMPLETE;

export const EXPORT_TO_CSV_SECONDARY_COMPLETE =
	'[Episodic Character] Export to CSV Secondary Complete';
type EXPORT_TO_CSV_SECONDARY_COMPLETE = typeof EXPORT_TO_CSV_SECONDARY_COMPLETE;

export const LIST = '[Episodic Character] List';
type LIST = typeof LIST;

export const LIST_FAILED = '[Episodic Character] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const LIST_COMPLETE = '[Episodic Character] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FOR_EP_ACTOR = '[Episodic Character] List for Episodic Actor';
type LIST_FOR_EP_ACTOR = typeof LIST_FOR_EP_ACTOR;

export const LIST_FOR_EP_ACTOR_FAILED =
	'[Episodic Character] List for Episodic Actor Failed';
type LIST_FOR_EP_ACTOR_FAILED = typeof LIST_FOR_EP_ACTOR_FAILED;

export const LIST_FOR_EP_ACTOR_COMPLETE =
	'[Episodic Character] List for Episodic Actor Complete';
type LIST_FOR_EP_ACTOR_COMPLETE = typeof LIST_FOR_EP_ACTOR_COMPLETE;

export const SET_CURRENT = '[Episodic Character] Set Current';
type SET_CURRENT = typeof SET_CURRENT;

export const EXPORT_TO_NEW_SEASON = '[Episodic Character] Export to New Season';
type EXPORT_TO_NEW_SEASON = typeof EXPORT_TO_NEW_SEASON;

export const EXPORT_TO_NEW_SEASON_COMPLETE =
	'[Episodic Character] Export to New Season Complete';
type EXPORT_TO_NEW_SEASON_COMPLETE = typeof EXPORT_TO_NEW_SEASON_COMPLETE;

export const EXPORT_TO_NEW_SEASON_FAILED =
	'[Episodic Character] Export to New Season Failed';
type EXPORT_TO_NEW_SEASON_FAILED = typeof EXPORT_TO_NEW_SEASON_FAILED;

export const GET_COMPLETE = '[Episodic Character] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const GET_FAILED = '[Episodic Character] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export const GET = '[Episodic Character] Get';
type GET = typeof GET;

export const UPDATE = '[Episodic Character] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[Episodic Character] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[Episodic Character] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const UPDATE_MULTIPLE = '[Episodic Character] Update Multiple';
type UPDATE_MULTIPLE = typeof UPDATE_MULTIPLE;

export const UPDATE_CHAR_NUMS = '[Episodic Character] Update Char Nums';
type UPDATE_CHAR_NUMS = typeof UPDATE_CHAR_NUMS;

export const CREATE = '[Episodic Character] Add to Episodic Character';
type CREATE = typeof CREATE;

export const CREATE_COMPLETE = '[Episodic Character] Create complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const CREATE_FAILED = '[Episodic Character] Create failed';
type CREATE_FAILED = typeof CREATE_FAILED;

export const GET_MERGE_CONFLICTS = '[Episodic Character] Get Merge Conflicts';
type GET_MERGE_CONFLICTS = typeof GET_MERGE_CONFLICTS;

export const GET_MERGE_CONFLICTS_COMPLETE =
	'[Episodic Character] Get Merge Conflicts Complete';
type GET_MERGE_CONFLICTS_COMPLETE = typeof GET_MERGE_CONFLICTS_COMPLETE;

export const GET_MERGE_CONFLICTS_FAILED =
	'[Episodic Character] Get Merge Conflicts Failed';
type GET_MERGE_CONFLICTS_FAILED = typeof GET_MERGE_CONFLICTS_FAILED;

export const MERGE = '[Episodic Character] Merge';
type MERGE = typeof MERGE;

export const MERGE_COMPLETE = '[Episodic Character] Merge Complete';
type MERGE_COMPLETE = typeof MERGE_COMPLETE;

export const MERGE_FAILED = '[Episodic Character] Merge Failed';
type MERGE_FAILED = typeof MERGE_FAILED;

export interface DestroyAction {
	episodicId: number;
	episodicCharacterId: number;
	type: DESTROY;
}

export interface DestroyFailedAction {
	type: DESTROY_FAILED;
	error: Error;
}

export interface DestroyCompleteAction {
	type: DESTROY_COMPLETE;
	episodicCharacters: EpisodicCharacter[];
}

export interface ExportToCSVAction {
	episodicId: number;
	prodIds: number[];
	isPrimary: boolean;
	type: EXPORT_TO_CSV;
}

export interface ExportToCSVSecondaryAction {
	episodicId: number;
	prodIds: number[];
	isPrimary: boolean;
	type: EXPORT_TO_CSV;
}

export interface ExportToCSVCompleteAction {
	fileContent: string;
	type: EXPORT_TO_CSV_COMPLETE;
}

export interface ExportToCSVSecondaryCompleteAction {
	fileContent: string;
	type: EXPORT_TO_CSV_SECONDARY_COMPLETE;
}

export interface ExportToCSVFailedAction {
	error: Error;
	type: EXPORT_TO_CSV_FAILED;
}

export interface ListAction {
	episodicId: number;
	type: LIST;
}

export interface ListFailedAction {
	type: LIST_FAILED;
	error: Error;
}

export interface ListCompleteAction extends UnknownAction {
	type: LIST_COMPLETE;
	episodicCharacters: EpisodicCharacter[];
}

export interface ListForEpActorAction {
	episodicId: number;
	episodicActorId: number;
	type: LIST_FOR_EP_ACTOR;
}

export interface ListForEpActorFailedAction {
	type: LIST_FOR_EP_ACTOR_FAILED;
	error: Error;
}

export interface ListForEpActorCompleteAction {
	type: LIST_FOR_EP_ACTOR_COMPLETE;
	episodicCharacters: EpisodicCharacter[];
}

export interface SetCurrentAction {
	episodicCharacterId: number;
	type: SET_CURRENT;
}

export interface ExportToNewSeasonAction {
	epCharIds: number[];
	episodicId: number;
	newEpisodicId: number;
	checkIfExported: boolean;
	type: EXPORT_TO_NEW_SEASON;
}

export interface ExportToNewSeasonCompleteAction {
	type: EXPORT_TO_NEW_SEASON_COMPLETE;
}

export interface ExportToNewSeasonFailedAction {
	error: Error;
	type: EXPORT_TO_NEW_SEASON_FAILED;
}

export interface GetCompleteAction {
	episodicCharacters: EpisodicCharacter[];
	next: number;
	previous: number;
	type: GET_COMPLETE;
}

export interface GetAction {
	episodicCharacterId: number;
	episodicId: number;
	productionIds: number[];
	type: GET;
}

export interface GetFailedAction {
	error: Error;
	type: GET_FAILED;
}

export interface UpdateAction {
	episodicId: number;
	episodicCharacter: Partial<EpisodicCharacter>;
	episodicActor: Partial<EpisodicActor>;
	addedProdIds: number[];
	removedProdIds: number[];
	addedSceneIds: number[];
	removedSceneIds: number[];
	type: UPDATE;
}

export interface UpdateCompleteAction {
	episodicCharacters: Partial<EpisodicCharacter>[];
	type: UPDATE_COMPLETE;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export interface UpdateMultipleAction {
	episodicId: number;
	payload: UpdateMultipleEpCharPayload;
	type: UPDATE_MULTIPLE;
}

export interface UpdateCharNumsAction {
	episodicId: number;
	episodicCharacter: Partial<EpisodicCharacter>;
	characters: Partial<Character>[];
	type: UPDATE_CHAR_NUMS;
}

export interface CreateAction {
	episodicId: number;
	episodicCharacter: Partial<EpisodicCharacter>;
	epActor: Partial<EpisodicActor>;
	prodIds: number[];
	sceneIds: number[];
	type: CREATE;
}

export interface CreateCompleteAction {
	episodicCharacters: EpisodicCharacter[];
	type: CREATE_COMPLETE;
}

export interface CreateFailedAction {
	error: Error;
	type: CREATE_FAILED;
}

export interface GetMergeConflictsAction {
	episodicId: number;
	firstEpCharId: number;
	secondEpCharId: number;
	type: GET_MERGE_CONFLICTS;
}

export interface GetMergeConflictsCompleteAction {
	mergeConflicts: EpisodicCharacterMergeConflictGetResponse;
	type: GET_MERGE_CONFLICTS_COMPLETE;
}

export interface GetMergeConflictsFailedAction {
	error: Error;
	type: GET_MERGE_CONFLICTS_FAILED;
}

export interface MergeAction {
	episodicId: number;
	firstEpCharId: number;
	secondEpCharId: number;
	resolution: EpisodicCharacterMergePayload;
	type: MERGE;
}

export interface MergeCompleteAction {
	resolution: Object;
	type: MERGE_COMPLETE;
}

export interface MergeFailedAction {
	error: Error;
	type: MERGE_FAILED;
}

export function destroy(
	episodicId: number,
	episodicCharacterId: number
): DestroyAction {
	return {
		episodicId,
		episodicCharacterId,
		type: DESTROY,
	};
}

export function destroyFailed(error: Error): DestroyFailedAction {
	return {
		error,
		type: DESTROY_FAILED,
	};
}

export function destroyComplete(
	episodicCharacters: EpisodicCharacter[]
): DestroyCompleteAction {
	return {
		episodicCharacters,
		type: DESTROY_COMPLETE,
	};
}

export function exportToCSV(
	episodicId: number,
	prodIds: number[],
	isPrimary: boolean
): ExportToCSVAction {
	return {
		episodicId,
		prodIds,
		isPrimary,
		type: EXPORT_TO_CSV,
	};
}

export function exportToCSVComplete(
	fileContent: string
): ExportToCSVCompleteAction {
	return {
		fileContent,
		type: EXPORT_TO_CSV_COMPLETE,
	};
}

export function exportToCSVSecondaryComplete(
	fileContent: string
): ExportToCSVSecondaryCompleteAction {
	return {
		fileContent,
		type: EXPORT_TO_CSV_SECONDARY_COMPLETE,
	};
}

export function exportToCSVFailed(error: Error): ExportToCSVFailedAction {
	return {
		error,
		type: EXPORT_TO_CSV_FAILED,
	};
}

export function list(episodicId: number): ListAction {
	return {
		episodicId,
		type: LIST,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export function listComplete(
	episodicCharacters: EpisodicCharacter[]
): ListCompleteAction {
	return {
		episodicCharacters,
		type: LIST_COMPLETE,
	};
}

export function listForEpActor(
	episodicId: number,
	episodicActorId: number
): ListForEpActorAction {
	return {
		episodicId,
		episodicActorId,
		type: LIST_FOR_EP_ACTOR,
	};
}

export function listForEpActorFailed(error: Error): ListForEpActorFailedAction {
	return {
		error,
		type: LIST_FOR_EP_ACTOR_FAILED,
	};
}

export function listForEpActorComplete(
	episodicCharacters: EpisodicCharacter[]
): ListForEpActorCompleteAction {
	return {
		episodicCharacters,
		type: LIST_FOR_EP_ACTOR_COMPLETE,
	};
}

export function setCurrent(episodicCharacterId: number): SetCurrentAction {
	return {
		episodicCharacterId,
		type: SET_CURRENT,
	};
}

export function exportToNewSeason(
	episodicId: number,
	newEpisodicId: number,
	epCharIds: number[],
	checkIfExported: boolean
): ExportToNewSeasonAction {
	return {
		episodicId,
		epCharIds,
		newEpisodicId,
		checkIfExported,
		type: EXPORT_TO_NEW_SEASON,
	};
}

export function exportToNewSeasonComplete(): ExportToNewSeasonCompleteAction {
	return {
		type: EXPORT_TO_NEW_SEASON_COMPLETE,
	};
}

export function exportToNewSeasonFailed(
	error: Error
): ExportToNewSeasonFailedAction {
	return {
		error,
		type: EXPORT_TO_NEW_SEASON_FAILED,
	};
}

export function getComplete(
	episodicCharacters: EpisodicCharacter[],
	previous: number,
	next: number
): GetCompleteAction {
	return {
		episodicCharacters,
		previous,
		next,
		type: GET_COMPLETE,
	};
}

export function get(
	episodicCharacterId: number,
	episodicId: number,
	productionIds: number[]
): GetAction {
	return {
		episodicCharacterId,
		episodicId,
		productionIds,
		type: GET,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	};
}

export function update(
	episodicId: number,
	episodicCharacter: Partial<EpisodicCharacter>,
	episodicActor: Partial<EpisodicActor>,
	addedProdIds: number[],
	removedProdIds: number[],
	addedSceneIds: number[],
	removedSceneIds: number[]
): UpdateAction {
	return {
		episodicId,
		episodicCharacter,
		episodicActor,
		addedProdIds,
		removedProdIds,
		addedSceneIds,
		removedSceneIds,
		type: UPDATE,
	};
}

export function updateComplete(
	episodicCharacters: EpisodicCharacter[]
): UpdateCompleteAction {
	return {
		episodicCharacters,
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function updateMultiple(
	episodicId: number,
	payload: UpdateMultipleEpCharPayload
): UpdateMultipleAction {
	return {
		episodicId,
		payload,
		type: UPDATE_MULTIPLE,
	};
}

export function updateCharNums(
	episodicId: number,
	episodicCharacter: Partial<EpisodicCharacter>,
	characters: Partial<Character>[]
): UpdateCharNumsAction {
	return {
		episodicId,
		episodicCharacter,
		characters,
		type: UPDATE_CHAR_NUMS,
	};
}

export function create(
	episodicId: number,
	episodicCharacter: Partial<EpisodicCharacter>,
	epActor: Partial<EpisodicActor>,
	prodIds: number[],
	sceneIds: number[]
): CreateAction {
	return {
		episodicId,
		episodicCharacter,
		epActor,
		prodIds,
		sceneIds,
		type: CREATE,
	};
}

export function createComplete(
	episodicCharacters: EpisodicCharacter[]
): CreateCompleteAction {
	return {
		episodicCharacters,
		type: CREATE_COMPLETE,
	};
}

export function createFailed(error: Error): CreateFailedAction {
	return {
		error,
		type: CREATE_FAILED,
	};
}

export function getMergeConflicts(
	episodicId: number,
	firstEpCharId: number,
	secondEpCharId: number
): GetMergeConflictsAction {
	return {
		episodicId,
		firstEpCharId,
		secondEpCharId,
		type: GET_MERGE_CONFLICTS,
	};
}

export function getMergeConflictsComplete(
	mergeConflicts: EpisodicCharacterMergeConflictGetResponse
): GetMergeConflictsCompleteAction {
	return {
		mergeConflicts,
		type: GET_MERGE_CONFLICTS_COMPLETE,
	};
}

export function getMergeConflictsFailed(
	error: Error
): GetMergeConflictsFailedAction {
	return {
		error,
		type: GET_MERGE_CONFLICTS_FAILED,
	};
}

export function merge(
	episodicId: number,
	firstEpCharId: number,
	secondEpCharId: number,
	resolution: EpisodicCharacterMergePayload
): MergeAction {
	return {
		episodicId,
		firstEpCharId,
		secondEpCharId,
		resolution,
		type: MERGE,
	};
}

export function mergeComplete(resolution: Object): MergeCompleteAction {
	return {
		resolution,
		type: MERGE_COMPLETE,
	};
}

export function mergeFailed(error: Error): MergeFailedAction {
	return {
		error,
		type: MERGE_FAILED,
	};
}

export type Action =
	| DestroyAction
	| DestroyCompleteAction
	| DestroyFailedAction
	| ExportToCSVAction
	| ExportToCSVCompleteAction
	| ExportToCSVSecondaryCompleteAction
	| ExportToCSVFailedAction
	| ListAction
	| ListFailedAction
	| ListCompleteAction
	| ListForEpActorAction
	| ListForEpActorCompleteAction
	| ListForEpActorFailedAction
	| SetCurrentAction
	| ExportToNewSeasonAction
	| ExportToNewSeasonCompleteAction
	| ExportToNewSeasonFailedAction
	| GetAction
	| GetFailedAction
	| GetCompleteAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction
	| UpdateMultipleAction
	| UpdateCharNumsAction
	| CreateAction
	| CreateCompleteAction
	| CreateFailedAction
	| GetMergeConflictsAction
	| GetMergeConflictsCompleteAction
	| GetMergeConflictsFailedAction
	| MergeAction
	| MergeCompleteAction
	| MergeFailedAction;
