import { normalize } from 'normalizr';
import { Production } from 'sos-models';
import * as actions from './production.actions';
import { setSelectedProductions } from '../../logger';
import * as episodicActions from '../episodics/episodic.actions';
import { productionSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createReducer,
	failed,
	loading,
} from '../utils';

export interface State extends BaseReducerState<Production> {
	loading: ActionStatus;
	selectedIds: { [episodicId: number]: number[] };
}

const initial: State = {
	entities: {},
	ids: [],
	loading: ActionStatus.Inactive,
	selectedIds: {},
};

export const reducer = createReducer<
	State,
	actions.Action | episodicActions.Action
>(initial, {
	[actions.SELECT]: select,
	[actions.SELECT_ALL]: selectAll,
	[actions.DE_SELECT_ALL]: deSelectAll,
	[actions.CREATE_COMPLETE]: createComplete,
	[episodicActions.GET_COMPLETE]: getEpisodicComplete,
	[episodicActions.GET_FAILED]: failed<State>('loading'),
	[episodicActions.GET]: loading<State>('loading'),
	[episodicActions.UPDATE_TITLES_COMPLETE]: updateComplete,
});

function createComplete(
	state: State,
	{ episodicId, newProdId }: actions.CreateCompleteAction
): State {
	const selectedIds = { ...state.selectedIds };
	if (typeof selectedIds[episodicId] !== 'undefined') {
		let prodIds: number[] = [newProdId];
		Object.keys(state.entities).forEach((prodId) => {
			if (state.entities[prodId].episodic_id === episodicId) {
				prodIds.push(state.entities[prodId].id);
			}
		});
		selectedIds[episodicId] = prodIds;
	} else {
		selectedIds[episodicId] = [newProdId];
	}
	return {
		...state,
		selectedIds,
	};
}

function getEpisodicComplete(
	state: State,
	{ episodic }: episodicActions.GetCompleteAction
): State {
	if (typeof episodic.productions === 'undefined') {
		// should never happen
		return state;
	}
	const normalized = normalize(episodic.productions, [productionSchema]);

	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.productions,
		},
		ids: [...normalized.result],
		loading: ActionStatus.Complete,
	};
}

function select(state: State, action: actions.SelectAction): State {
	const ids = { ...state.selectedIds };
	if (typeof ids[action.episodicId] === 'undefined' && action.initial) {
		let prodIds: number[] = [];
		Object.keys(state.entities).forEach((prodId) => {
			if (state.entities[prodId].episodic_id === action.episodicId) {
				prodIds.push(state.entities[prodId].id);
			}
		});
		setSelectedProductions(prodIds);
		ids[action.episodicId] = prodIds;
	} else if (!action.initial) {
		ids[action.episodicId] = action.selectedIds;
		setSelectedProductions(action.selectedIds);
	}

	return {
		...state,
		selectedIds: ids,
	};
}

function selectAll(state: State, action: actions.SelectAllAction): State {
	let ids = { ...state.selectedIds };
	let prodIds: number[] = [];
	Object.keys(state.entities).forEach((prodId) => {
		if (state.entities[prodId].episodic_id === action.episodicId) {
			prodIds.push(state.entities[prodId].id);
		}
	});
	ids[action.episodicId] = prodIds;
	setSelectedProductions(prodIds);
	return {
		...state,
		selectedIds: ids,
	};
}

function deSelectAll(state: State, action: actions.SelectAllAction): State {
	let ids = { ...state.selectedIds };
	let prodIds: number[] = [];
	ids[action.episodicId] = prodIds;
	setSelectedProductions(prodIds);
	return {
		...state,
		selectedIds: ids,
	};
}

function updateComplete(
	state: State,
	action: episodicActions.UpdateTitlesCompleteAction
): State {
	const normalized = normalize(action.productions, [productionSchema]);

	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.productions,
		},
		ids: [...normalized.result],
		loading: ActionStatus.Complete,
	};
}
