import { ChangeEpisodicItem } from 'sos-models';
import * as actions from './change-episodic-item.actions';
import { changeEpisodicItemSchema } from '../schemas';
import {
	ActionStatus,
	createComplete,
	createReducer,
	destroyComplete,
	failed,
	getComplete,
	listComplete,
	loading,
	NormalizedEntityMapping,
	updateComplete,
} from '../utils';

export interface State {
	entities: NormalizedEntityMapping<ChangeEpisodicItem>;
	ids: number[];
	createStatus: ActionStatus;
	updateStatus: ActionStatus;
	destroyStatus: ActionStatus;
	getStatus: ActionStatus;
}

const initial: State = {
	entities: {},
	ids: [],
	createStatus: ActionStatus.Inactive,
	updateStatus: ActionStatus.Inactive,
	destroyStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
};

export const reducer = createReducer<State, actions.Action>(initial, {
	[actions.CREATE]: loading<State>('createStatus'),
	[actions.CREATE_COMPLETE]: createComplete<
		ChangeEpisodicItem,
		State,
		actions.CreateCompleteAction
	>('changeEpisodicItems', changeEpisodicItemSchema),
	[actions.CREATE_FAILED]: failed<State>('createStatus'),
	[actions.UPDATE]: loading<State>('updateStatus'),
	[actions.UPDATE_COMPLETE]: updateComplete<
		ChangeEpisodicItem,
		State,
		actions.UpdateCompleteAction
	>('changeEpisodicItems', changeEpisodicItemSchema),
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
	[actions.CREATE_CHANGE_EPISODIC_ITEM]: loading<State>('createStatus'),
	[actions.CREATE_CHANGE_EPISODIC_ITEM_COMPLETE]: createComplete<
		ChangeEpisodicItem,
		State,
		actions.CreateCompleteAction
	>('changeEpisodicItems', changeEpisodicItemSchema),
	[actions.CREATE_CHANGE_EPISODIC_ITEM_FAILED]: failed<State>('createStatus'),
	[actions.DELETE_CHANGE_EPISODIC_ITEM]: loading<State>('destroyStatus'),
	[actions.DELETE_CHANGE_EPISODIC_ITEM_COMPLETE]: destroyComplete<
		ChangeEpisodicItem,
		State,
		actions.DeleteChangeEpisodicItemCompleteAction
	>('changeEpisodicItems'),
	[actions.DELETE_CHANGE_EPISODIC_ITEM_FAILED]: failed<State>('destroyStatus'),
	[actions.GET_ITEMS_FOR_CHANGE]: loading<State>('getStatus'),
	[actions.GET_ITEMS_FOR_CHANGE_COMPLETE]: getComplete<
		ChangeEpisodicItem,
		State,
		actions.GetEpisodicItemsForChangeCompleteAction
	>('changeEpItems', changeEpisodicItemSchema),
	[actions.GET_ITEMS_FOR_CHANGE_FAILED]: failed<State>('getStatus'),
	[actions.LIST_COMPLETE]: listComplete<
		ChangeEpisodicItem,
		State,
		actions.ListCompleteAction
	>('changeEpisodicItems', changeEpisodicItemSchema),
});
