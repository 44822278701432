import { SetProd } from 'sos-models';

export const LIST = '[Set Prods] List Set Prods';
type LIST = typeof LIST;

export const LIST_COMPLETE = '[Set Prods] List Set Prods Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FAILED = '[Set Prods] List Set Prods Failed';
type LIST_FAILED = typeof LIST_FAILED;

export interface ListAction {
	type: LIST;
	episodicId: number;
}

export interface ListCompleteAction {
	type: LIST_COMPLETE;
	setProds: SetProd[];
}

export interface ListFailedAction {
	type: LIST_FAILED;
	error: Error;
}

export function list(episodicId: number): ListAction {
	return {
		episodicId,
		type: LIST,
	};
}

export function listComplete(setProds: SetProd[]): ListCompleteAction {
	return {
		setProds,
		type: LIST_COMPLETE,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export type Action = ListAction | ListCompleteAction | ListFailedAction;
