import { PrWrapBoxEpisodicProp } from 'sos-models';
import * as prWrapBoxEpisodicPropActions from './pr-wrap-box-episodic-prop.actions';
import { prWrapBoxEpisodicPropSchema } from '../schemas';
import {
	ActionStatus,
	BaseReducerState,
	createComplete,
	createReducer,
	destroyComplete,
	listComplete,
	updateComplete,
} from '../utils';

export interface State extends BaseReducerState<PrWrapBoxEpisodicProp> {
	listStatus: ActionStatus;
	createStatus: ActionStatus;
	destroyStatus: ActionStatus;
}

export const initial: State = {
	listStatus: ActionStatus.Inactive,
	createStatus: ActionStatus.Inactive,
	destroyStatus: ActionStatus.Inactive,
	entities: {},
	ids: [],
};

export const reducer = createReducer<
	State,
	prWrapBoxEpisodicPropActions.Action
>(initial, {
	[prWrapBoxEpisodicPropActions.CREATE_COMPLETE]: createComplete<
		PrWrapBoxEpisodicProp,
		State,
		prWrapBoxEpisodicPropActions.CreateCompleteAction
	>('prWrapBoxEpisodicProps', prWrapBoxEpisodicPropSchema),
	[prWrapBoxEpisodicPropActions.LIST_COMPLETE]: listComplete<
		PrWrapBoxEpisodicProp,
		State,
		prWrapBoxEpisodicPropActions.ListCompleteAction
	>('prWrapBoxEpisodicProps', prWrapBoxEpisodicPropSchema),
	[prWrapBoxEpisodicPropActions.DESTROY_COMPLETE]: destroyComplete<
		PrWrapBoxEpisodicProp,
		State,
		prWrapBoxEpisodicPropActions.DestroyCompleteAction
	>('prWrapBoxEpisodicProps'),
	[prWrapBoxEpisodicPropActions.UPDATE_COMPLETE]: updateComplete<
		PrWrapBoxEpisodicProp,
		State,
		prWrapBoxEpisodicPropActions.UpdateCompleteAction
	>('prWrapBoxEpisodicProps', prWrapBoxEpisodicPropSchema),
});
