import { Character } from 'sos-models';
import axios from '../../config/axios';

export async function list(episodicId: number): Promise<Partial<Character>[]> {
	const { data } = await axios.get<Partial<Character>[]>(
		`/api/episodics/${episodicId}/characters`
	);
	return data;
}

export async function get(
	episodicCharacterId: number,
	episodicId: number
): Promise<Partial<Character>> {
	const { data } = await axios.get<Partial<Character>>(
		`/api/episodics/${episodicId}/characters/${episodicCharacterId}`
	);
	return data;
}
