import {
	ScriptLocation,
	ScriptLocationCreateResponse,
	ScriptLocationListResponse,
	ScriptLocationUpdateResponse,
	ScriptLocationDeleteResponse,
} from 'sos-models';
import axios from '../../config/axios';

export async function list(
	episodicId: number
): Promise<ScriptLocationListResponse> {
	return axios
		.get<ScriptLocationListResponse>(
			`/api/episodics/${episodicId}/script-locations`
		)
		.then((response) => response.data);
}

export async function create(
	episodicId: number,
	scriptLocations: Partial<ScriptLocation>[]
): Promise<ScriptLocationCreateResponse> {
	return axios
		.post<ScriptLocationCreateResponse>(
			`/api/episodics/${episodicId}/script-locations`,
			scriptLocations
		)
		.then((response) => response.data);
}

export async function update(
	episodicId: number,
	scriptLocations: Partial<ScriptLocation>[]
): Promise<ScriptLocationUpdateResponse> {
	return axios
		.put<ScriptLocationUpdateResponse>(
			`/api/episodics/${episodicId}/script-locations`,
			scriptLocations
		)
		.then((response) => response.data);
}

export async function destroy(
	episodicId: number,
	scriptLocationId: number
): Promise<ScriptLocationDeleteResponse> {
	return await axios
		.delete<ScriptLocationDeleteResponse>(
			`/api/episodics/${episodicId}/script-locations/${scriptLocationId}`
		)
		.then((response) => response.data);
}
