import {
	DeleteSceneCounts,
	PartialScene,
	Scene,
	SceneCreateResponse,
	SceneDeleteResponse,
	SceneGetResponse,
	SceneListResponse,
	SceneUpdateResponse,
	ScriptLocation,
} from 'sos-models';
import axios from '../../config/axios';

export async function create(
	episodicId: number,
	scene: Partial<Scene>,
	scriptLocation: Partial<ScriptLocation>,
	primaryIds: number[],
	backgroundIds: number[],
	setIds: number[]
): Promise<SceneCreateResponse> {
	return await axios
		.post<SceneCreateResponse>(`/api/episodics/${episodicId}/scenes`, {
			scene,
			scriptLocation,
			primaryIds,
			backgroundIds,
			setIds,
		})
		.then((response) => response.data);
}

export async function duplicate(
	episodicId: number,
	productionId: number,
	sceneId: number,
	name: string
): Promise<SceneCreateResponse> {
	return await axios
		.post<SceneCreateResponse>(
			`/api/episodics/${episodicId}/productions/${productionId}/scenes/${sceneId}/duplicate`,
			{ name }
		)
		.then((response) => response.data);
}

export async function exportScenes(
	episodicId: number,
	prodIds: number[]
) {
	return await axios
		.get(
			`/api/episodics/${episodicId}/productions/${prodIds}/scenes/export`,
			{
				params: [prodIds],
				responseType: 'arraybuffer',
			}
		)
		.then((response) => response.data);
}

export async function get(
	episodicId: number,
	sceneId: number,
	productionIds: string
): Promise<SceneGetResponse> {
	return await axios
		.get<SceneGetResponse>(`/api/episodics/${episodicId}/scenes/${sceneId}`, {
			params: { productionIds },
		})
		.then((response) => response.data);
}

export async function list(episodicId: number): Promise<SceneListResponse> {
	return await axios
		.get<SceneListResponse>(`/api/episodics/${episodicId}/scenes`)
		.then((response) => response.data);
}

export async function destroy(
	sceneId: number,
	episodicId: number
): Promise<SceneDeleteResponse> {
	return axios
		.delete(`/api/episodics/${episodicId}/scenes/${sceneId}`)
		.then((response) => response.data);
}

export async function getDeleteSceneCounts(
	sceneId: number,
	episodicId: number
): Promise<DeleteSceneCounts> {
	return axios
		.get(`/api/episodics/${episodicId}/scenes/${sceneId}/delete-scene-counts`)
		.then((response) => response.data);
}

export async function update(
	episodicId: number,
	scenes: PartialScene[],
	addedEpCharIds: { [sceneId: number]: number[] },
	removedEpCharIds: { [sceneId: number]: number[] },
	addedSetIds: { [sceneId: number]: number[] },
	removedSetIds: { [sceneId: number]: number[] }
): Promise<SceneUpdateResponse> {
	return axios
		.put<SceneUpdateResponse>(`/api/episodics/${episodicId}/scenes`, {
			scenes,
			addedEpCharIds,
			removedEpCharIds,
			addedSetIds,
			removedSetIds,
		})
		.then((response) => response.data);
}

export async function getForPropId(
	episodicId: number,
	departmentId: number,
	epPropId: number
): Promise<Partial<Scene>[]> {
	const response = await axios.get<Partial<Scene>[]>(
		`/api/inventory/${episodicId}/${departmentId}/${epPropId}/scenes`,
		{
			params: {
				episodic_id: episodicId,
				department: departmentId,
				inv_id: epPropId,
			},
		}
	);
	return response.data;
}
