import {
	ChangeLookPictureForEpCharListResponse,
	ChangeLookPictureListResponse,
} from 'sos-models';
import axios from '../../config/axios';

export async function list(
	episodicId: number,
	departmentId: number,
	changeLookId: number
): Promise<ChangeLookPictureListResponse> {
	const { data } = await axios.get<ChangeLookPictureListResponse>(
		`/api/episodics/${episodicId}/change-looks/${departmentId}/${changeLookId}/pictures`
	);
	return data;
}

export async function listForEpChar(
	episodicId: number,
	episodicCharacterId: number
): Promise<ChangeLookPictureForEpCharListResponse> {
	const { data } = await axios.get<ChangeLookPictureForEpCharListResponse>(
		`/api/episodics/${episodicId}/episodic-characters/${episodicCharacterId}/change-look-pictures`
	);
	return data;
}
