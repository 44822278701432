import { EpisodicCharacterDeptartmentNotePayload } from 'sos-models';

export const CREATE = '[Episodic Character Note] Create';
type CREATE = typeof CREATE;

export const CREATE_COMPLETE = '[Episodic Character Note] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const CREATE_FAILED = '[Episodic Character Note] Create Failed';
type CREATE_FAILED = typeof CREATE_FAILED;

export const LIST = '[Episodic Character Notes] List';
type LIST = typeof LIST;

export const LIST_COMPLETE = '[Episodic Character Notes] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FAILED = '[Episodic Character Notes] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const UPDATE = '[Episodic Character Note] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[Episodic Character Note] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[Episodic Character Note] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export interface CreateAction {
	episodicId: number;
	episodicCharacterNotes: EpisodicCharacterDeptartmentNotePayload;
	type: CREATE;
}

export interface CreateCompleteAction {
	type: CREATE_COMPLETE;
}

export interface CreateFailedAction {
	error: Error;
	type: CREATE_FAILED;
}

export interface ListAction {
	episodicId: number;
	epCharId: number;
	type: LIST;
}

export interface ListCompleteAction {
	type: LIST_COMPLETE;
}

export interface ListFailedAction {
	type: LIST_FAILED;
	error: Error;
}

export interface UpdateAction {
	episodicId: number;
	episodicCharacterNotes: EpisodicCharacterDeptartmentNotePayload;
	type: UPDATE;
}

export interface UpdateCompleteAction {
	type: UPDATE_COMPLETE;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export function create(
	episodicId: number,
	episodicCharacterNotes: EpisodicCharacterDeptartmentNotePayload
): CreateAction {
	return {
		episodicId,

		episodicCharacterNotes,
		type: CREATE,
	};
}

export function createComplete(): CreateCompleteAction {
	return {
		type: CREATE_COMPLETE,
	};
}

export function createFailed(error: Error): CreateFailedAction {
	return {
		error,
		type: CREATE_FAILED,
	};
}

export function list(episodicId: number, epCharId: number): ListAction {
	return {
		episodicId,
		epCharId,
		type: LIST,
	};
}

export function listComplete(): ListCompleteAction {
	return {
		type: LIST_COMPLETE,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export function update(
	episodicId: number,
	episodicCharacterNotes: EpisodicCharacterDeptartmentNotePayload
): UpdateAction {
	return {
		episodicId,
		episodicCharacterNotes,
		type: UPDATE,
	};
}

export function updateComplete(): UpdateCompleteAction {
	return {
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export type Action =
	| CreateAction
	| CreateCompleteAction
	| CreateFailedAction
	| ListAction
	| ListCompleteAction
	| ListFailedAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction;
