import { AppFeature } from 'sos-models';

export const UPDATE = '[AppFeature] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[AppFeature] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[AppFeature] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const LIST = '[AppFeatures] List';
type LIST = typeof LIST;

export const LIST_FAILED = '[AppFeatures] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const LIST_COMPLETE = '[AppFeatures] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export interface UpdateAction {
	appFeatureId: number;
	enable: boolean;
	type: UPDATE;
}

export interface UpdateCompleteAction {
	appFeatures: AppFeature[];
	type: UPDATE_COMPLETE;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export interface ListAction {
	type: LIST;
}

export interface ListFailedAction {
	error: Error;
	type: LIST_FAILED;
}

export interface ListCompleteAction {
	appFeatures: AppFeature[];
	type: LIST_COMPLETE;
}

export function update(appFeatureId: number, enable: boolean): UpdateAction {
	return {
		appFeatureId,
		enable,
		type: UPDATE,
	};
}

export function updateComplete(
	appFeatures: AppFeature[]
): UpdateCompleteAction {
	return {
		appFeatures,
		type: UPDATE_COMPLETE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function list(): ListAction {
	return {
		type: LIST,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export function listComplete(appFeatures: AppFeature[]): ListCompleteAction {
	return {
		appFeatures,
		type: LIST_COMPLETE,
	};
}

export type Action =
	| ListAction
	| ListFailedAction
	| ListCompleteAction
	| UpdateAction
	| UpdateCompleteAction
	| UpdateFailedAction;
