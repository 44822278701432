import { toast } from 'react-toastify';
import { call, put, takeEvery } from 'redux-saga/effects';
import { UserSettingUpdateResponse } from 'sos-models';
import * as userSettingActions from './user-setting.actions';
import * as api from './user-setting.api';
import * as logger from '../../logger';

export function* update({
	episodicId,
	userSetting,
}: userSettingActions.UpdateAction) {
	try {
		const { updated }: UserSettingUpdateResponse = yield call(
			api.update,
			episodicId,
			userSetting
		);
		if (updated.UserSetting) {
			yield put(userSettingActions.updateComplete(updated.UserSetting[0]));
		}
	} catch (err) {
		yield put(userSettingActions.updateFailed(err));
		if (err.response) {
			toast.error(err.response.data.message);
		} else {
			logger.error(err);
		}
	}
}

export default function* saga() {
	yield takeEvery(userSettingActions.UPDATE, update);
}
