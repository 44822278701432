import { SetWrapBoxSetPiece } from 'sos-models';
import * as setWrapBoxSetPieceActions from './set-wrap-box-set-piece.actions';
import { setWrapBoxSetPieceSchema } from '../schemas';
import {
	ActionStatus,
	createComplete,
	createReducer,
	destroyComplete,
	listComplete,
	NormalizedEntityMapping,
	updateComplete,
} from '../utils';

export interface State {
	listStatus: ActionStatus;
	createStatus: ActionStatus;
	destroyStatus: ActionStatus;
	ids: number[];
	entities: NormalizedEntityMapping<SetWrapBoxSetPiece>;
}

export const initial: State = {
	listStatus: ActionStatus.Inactive,
	createStatus: ActionStatus.Inactive,
	destroyStatus: ActionStatus.Inactive,
	entities: {},
	ids: [],
};

export const reducer = createReducer<State, setWrapBoxSetPieceActions.Action>(
	initial,
	{
		[setWrapBoxSetPieceActions.LIST_COMPLETE]: listComplete<
			SetWrapBoxSetPiece,
			State,
			setWrapBoxSetPieceActions.ListCompleteAction
		>('setWrapBoxSetPieces', setWrapBoxSetPieceSchema),
		[setWrapBoxSetPieceActions.CREATE_COMPLETE]: createComplete<
			SetWrapBoxSetPiece,
			State,
			setWrapBoxSetPieceActions.CreateCompleteAction
		>('setWrapBoxSetPieces', setWrapBoxSetPieceSchema),
		[setWrapBoxSetPieceActions.DESTROY_COMPLETE]: destroyComplete<
			SetWrapBoxSetPiece,
			State,
			setWrapBoxSetPieceActions.DestroyCompleteAction
		>('setWrapBoxSetPieces'),
		[setWrapBoxSetPieceActions.UPDATE_COMPLETE]: updateComplete<
			SetWrapBoxSetPiece,
			State,
			setWrapBoxSetPieceActions.UpdateCompleteAction
		>('setWrapBoxSetPieces', setWrapBoxSetPieceSchema),
	}
);
