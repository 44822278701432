import { omit, without } from 'lodash';
import { normalize } from 'normalizr';
import { Change, DEPARTMENTS, SortOrder } from 'sos-models';
import * as actions from './change.actions';
import * as changeLookActions from '../change-looks/change-look.actions';
import * as changeSceneActions from '../change-scenes/change-scene.actions';
import * as episodicCharacterActions from '../episodic-characters/episodic-character.actions';
import { changeSchema } from '../schemas';
import {
	ActionStatus,
	createComplete,
	createReducer,
	failed,
	getComplete,
	listComplete,
	loading,
	NormalizedEntityMapping,
	uniqueArrayMerge,
	updateComplete,
} from '../utils';

export interface State {
	entities: NormalizedEntityMapping<Change>;
	createAndDeleteExistingStatus: ActionStatus;
	createStatus: ActionStatus;
	createChangeBySceneStatus: ActionStatus;
	deleteChangeForEpCharStatus: ActionStatus;
	getChangesForSceneStatus: ActionStatus;
	getForChangeLookStatus: ActionStatus;
	getForCharacterStatus: ActionStatus;
	getForEpCharStatus: ActionStatus;
	getForEpItemStatus: ActionStatus;
	getStatus: ActionStatus;
	ids: number[];
	updateStatus: ActionStatus;
	sortBy: string;
	sortOrder: SortOrder;
}

export const initial: State = {
	createAndDeleteExistingStatus: ActionStatus.Inactive,
	createStatus: ActionStatus.Inactive,
	createChangeBySceneStatus: ActionStatus.Inactive,
	deleteChangeForEpCharStatus: ActionStatus.Inactive,
	entities: {},
	getChangesForSceneStatus: ActionStatus.Inactive,
	getForChangeLookStatus: ActionStatus.Inactive,
	getForCharacterStatus: ActionStatus.Inactive,
	getForEpCharStatus: ActionStatus.Inactive,
	getForEpItemStatus: ActionStatus.Inactive,
	getStatus: ActionStatus.Inactive,
	ids: [],
	updateStatus: ActionStatus.Inactive,
	sortBy: 'change_num',
	sortOrder: 'asc',
};

export const reducer = createReducer<
	State,
	| actions.Action
	| episodicCharacterActions.Action
	| changeSceneActions.Action
	| changeLookActions.Action
>(initial, {
	[actions.DELETE_CHANGE_FOR_EP_CHAR]: loading<State>(
		'deleteChangeForEpCharStatus'
	),
	[actions.DELETE_CHANGE_FOR_EP_CHAR_COMPLETE]: deleteChangeForEpCharComplete,
	[actions.DELETE_CHANGE_FOR_EP_CHAR_FAILED]: failed<State>(
		'deleteChangeForEpCharStatus'
	),
	[actions.DELETE_COMPLETE]: (state: State, action: actions.Action) => {
		//Check if action has property departmentId
		if (action.hasOwnProperty('departmentId')) {
			return deleteChangeLookComplete(
				state,
				action as unknown as changeLookActions.DeleteCompleteAction
			);
		}
		return deleteComplete(state, action as actions.DeleteCompleteAction);
	},
	[actions.GET_CHANGES_FOR_CHANGE_LOOK]: loading<State>(
		'getForChangeLookStatus'
	),
	[actions.GET_CHANGES_FOR_CHANGE_LOOK_COMPLETE]: getForChangeLookComplete,
	[actions.GET_CHANGES_FOR_CHANGE_LOOK_FAILED]: failed<State>(
		'getForChangeLookStatus'
	),
	[actions.GET_CHANGES_FOR_EP_CHAR]: loading<State>('getForEpCharStatus'),
	[actions.GET_CHANGES_FOR_EP_CHAR_COMPLETE]: getForEpCharComplete,
	[actions.GET_CHANGES_FOR_EP_CHAR_FAILED]: failed<State>('getForEpCharStatus'),
	[actions.GET_CHANGES_FOR_ITEM]: loading<State>('getForEpItemStatus'),
	[actions.GET_CHANGES_FOR_ITEM_COMPLETE]: getForEpItemComplete,
	[actions.GET_CHANGES_FOR_ITEM_FAILED]: failed<State>('getForEpItemStatus'),
	[actions.GET_CHANGES_FOR_SCENE]: loading<State>('getChangesForSceneStatus'),
	[actions.GET_CHANGES_FOR_SCENE_COMPLETE]: getChangesForSceneComplete,
	[actions.GET_CHANGES_FOR_SCENE_FAILED]: failed<State>(
		'getChangesForSceneStatus'
	),
	[actions.CREATE_CHANGE]: loading<State>('createStatus'),
	[actions.CREATE_CHANGE_FAILED]: failed<State>('createStatus'),
	[actions.CREATE_CHANGE_COMPLETE]: createComplete<
		Change,
		State,
		actions.CreateChangeCompleteAction
	>('change', changeSchema),
	[actions.CREATE_CHANGE_BY_SCENE]: loading<State>('createChangeBySceneStatus'),
	[actions.CREATE_CHANGE_BY_SCENE_COMPLETE]: createChangeBySceneComplete,
	[actions.CREATE_CHANGE_BY_SCENE_FAILED]: failed<State>(
		'createChangeBySceneStatus'
	),
	[actions.LIST_COMPLETE]: listComplete<
		Change,
		State,
		actions.ListCompleteAction
	>('changes', changeSchema),
	[actions.UPDATE]: loading<State>('updateStatus'),
	[actions.UPDATE_COMPLETE]: updateComplete<
		Change,
		State,
		actions.UpdateCompleteAction
	>('changes', changeSchema),
	[actions.UPDATE_FAILED]: failed<State>('updateStatus'),
	[actions.GET_COMPLETE]: getComplete<Change, State, actions.GetCompleteAction>(
		'change',
		changeSchema
	),
	[actions.SET_SORTING]: setSorting,
	[changeLookActions.CREATE]: loading<State>('createStatus'),
	[changeSceneActions.CREATE_AND_DELETE_EXISTING]: loading<State>(
		'createAndDeleteExistingStatus'
	),
	[changeSceneActions.CREATE_AND_DELETE_EXISTING_COMPLETE]:
		createAndDeleteExistingComplete,
	[changeSceneActions.CREATE_AND_DELETE_EXISTING_FAILED]: failed<State>(
		'createAndDeleteExistingStatus'
	),
});

function getForChangeLookComplete(
	state: State,
	action: actions.GetChangesForChangeLookCompleteAction
): State {
	const normalized = normalize(action.changes, [changeSchema]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.changes,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		getForChangeLookStatus: ActionStatus.Complete,
	};
}

function deleteComplete(
	state: State,
	action: actions.DeleteCompleteAction
): State {
	const changeIds = action.changes.map((c) => c.id);
	const ids = without(state.ids, ...changeIds);
	const entities = omit(state.entities, changeIds);
	return {
		...state,
		entities,
		ids,
	};
}

function getForEpItemComplete(
	state: State,
	action: actions.GetChangesForItemCompleteAction
): State {
	const normalized = normalize(action.changes, [changeSchema]);

	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.changes,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		getForEpItemStatus: ActionStatus.Complete,
	};
}

function getForEpCharComplete(
	state: State,
	action: actions.GetChangesForEpCharCompleteAction
): State {
	const normalized = normalize(action.changes, [changeSchema]);
	return {
		...state,
		entities: {
			//...state.entities,
			...normalized.entities.changes,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		getForEpCharStatus: ActionStatus.Complete,
	};
}

function deleteChangeForEpCharComplete(
	state: State,
	{ response: { destroyed } }: actions.DeleteChangeForEpCharCompleteAction
): State {
	const entities: { [id: number]: Change } = omit(
		state.entities,
		destroyed.Change.map((c) => c.id)
	);
	const deletedIds = Object.keys(destroyed.Change).map(
		(id) => destroyed.Change[id].id
	);
	return {
		...state,
		entities,
		ids: state.ids.filter((id) => !deletedIds.includes(id)),
		deleteChangeForEpCharStatus: ActionStatus.Complete,
	};
}

function getChangesForSceneComplete(
	state: State,
	action: actions.GetChangesForSceneCompleteAction
): State {
	const normalized = normalize(action.changes, [changeSchema]);

	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.changes,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		getChangesForSceneStatus: ActionStatus.Complete,
	};
}

function createChangeBySceneComplete(
	state: State,
	{ created, updated }: actions.CreateChangeBySceneCompleteAction
): State {
	const normalizedCreated = normalize(created.Change, [changeSchema]);
	const normalizedUpdated = normalize(updated.Change, [changeSchema]);
	const createdUpdatedIds = uniqueArrayMerge(
		normalizedCreated.result,
		normalizedUpdated.result
	);

	return {
		...state,
		entities: {
			...state.entities,
			...normalizedCreated.entities.changes,
			...normalizedUpdated.entities.changes,
		},
		ids: uniqueArrayMerge(state.ids, createdUpdatedIds),
		createChangeBySceneStatus: ActionStatus.Complete,
	};
}

function createAndDeleteExistingComplete(
	state: State,
	{ updated }: changeSceneActions.CreateAndDeleteExistingCompleteAction
): State {
	const normalized = normalize(updated.Change, [changeSchema]);
	return {
		...state,
		entities: {
			...state.entities,
			...normalized.entities.changes,
		},
		ids: uniqueArrayMerge(state.ids, normalized.result),
		createAndDeleteExistingStatus: ActionStatus.Complete,
	};
}

function deleteChangeLookComplete(
	state: State,
	{ changeLooks, departmentId }: changeLookActions.DeleteCompleteAction
): State {
	if (departmentId !== DEPARTMENTS.CM) {
		return state;
	}
	const changeIds = changeLooks.map((cl) => cl.id);
	const ids = without(state.ids, ...changeIds);
	const entities = omit(state.entities, changeIds);
	return {
		...state,
		entities,
		ids,
	};
}

function setSorting(
	state: State,
	{ sorting }: actions.SetSortingAction
): State {
	const sortInfo = sorting[0];
	return {
		...state,
		sortBy: sortInfo.columnName,
		sortOrder: sortInfo.direction,
	};
}
