import { PropCategory } from 'sos-models';
import axios from '../../config/axios';

export function create(
	propCategory: Partial<PropCategory>
): Promise<Partial<PropCategory>> {
	return axios
		.post<Partial<PropCategory>>(`/api/prop-categories`, propCategory)
		.then((response) => response.data);
}

export function destroy(propCategory: Partial<PropCategory>) {
	return axios
		.delete(`/api/prop-categories/${propCategory.id}`)
		.then((response) => response.data);
}

export function update(
	propCategory: Partial<PropCategory>
): Promise<Partial<PropCategory>> {
	return axios
		.put<Partial<PropCategory>>(
			`/api/prop-categories/${propCategory.id}`,
			propCategory
		)
		.then((response) => response.data);
}
