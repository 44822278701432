import { ActivityCardListResponse } from 'sos-models';
import axios from '../../config/axios';

export async function get(
	offset: number,
	count: number,
	id?: number
): Promise<ActivityCardListResponse> {
	let path: number | string = id;
	if (!path) {
		path = 'current';
	}
	return await axios
		.get<ActivityCardListResponse>(`/server/index.php`, {
			params: {
				r: 'episodics/getActivityFeed',
				id: path,
				offset,
				count,
			},
		})
		.then((response) => response.data);
}
