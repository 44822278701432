import { MuChangeFieldNames } from 'sos-models';
import axios from '../../config/axios';

export async function get(episodicId: number): Promise<MuChangeFieldNames> {
	return axios
		.get<MuChangeFieldNames>(
			`/api/episodic/${episodicId}/mu-change-field-names/`
		)
		.then((response) => response.data);
}
