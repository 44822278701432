import { DEPARTMENTS, EpisodicCharacter, UniversalSearchHit } from 'sos-models';

export const SEARCH = '[Universal Search] Search';
type SEARCH = typeof SEARCH;

export const SEARCH_FAILED = '[Universal Search] Search Failed';
type SEARCH_FAILED = typeof SEARCH_FAILED;

export const SEARCH_COMPLETE = '[Universal Search] Search Complete';
type SEARCH_COMPLETE = typeof SEARCH_COMPLETE;

export const SEARCH_BG_CHARS =
	'[Universal Search] Search Background Characters';
type SEARCH_BG_CHARS = typeof SEARCH_BG_CHARS;

export const SEARCH_BG_CHARS_FAILED =
	'[Universal Search] Search Background Characters Failed';
type SEARCH_BG_CHARS_FAILED = typeof SEARCH_BG_CHARS_FAILED;

export const SEARCH_BG_CHARS_COMPLETE =
	'[Universal Search] Search Background Characters Complete';
type SEARCH_BG_CHARS_COMPLETE = typeof SEARCH_BG_CHARS_COMPLETE;

export interface SearchAction {
	episodicId: number;
	departmentIds: DEPARTMENTS[];
	productionIds: number[];
	query: string;
	type: SEARCH;
}

export interface SearchCompleteAction {
	searchHits: UniversalSearchHit[];
	type: SEARCH_COMPLETE;
}

export interface SearchFailedAction {
	error: Error;
	type: SEARCH_FAILED;
}

export interface SearchBackgroundCharactersAction {
	episodicId: number;
	departmentIds: DEPARTMENTS[];
	productionIds: number[];
	sceneId: number;
	query: string;
	type: SEARCH_BG_CHARS;
}

export interface SearchBackgroundCharactersCompleteAction {
	epChars: EpisodicCharacter[];
	type: SEARCH_BG_CHARS_COMPLETE;
}

export interface SearchBackgroundCharactersFailedAction {
	error: Error;
	type: SEARCH_BG_CHARS_FAILED;
}

export function search(
	episodicId: number,
	productionIds: number[],
	departmentIds: number[],
	query: string
): SearchAction {
	return {
		episodicId,
		productionIds,
		departmentIds,
		query,
		type: SEARCH,
	};
}

export function searchComplete(
	searchHits: UniversalSearchHit[]
): SearchCompleteAction {
	return {
		searchHits,
		type: SEARCH_COMPLETE,
	};
}

export function searchFailed(error: Error): SearchFailedAction {
	return {
		error,
		type: SEARCH_FAILED,
	};
}

export function searchBackgroundCharacters(
	episodicId: number,
	productionIds: number[],
	departmentIds: number[],
	sceneId: number,
	query: string
): SearchBackgroundCharactersAction {
	return {
		episodicId,
		productionIds,
		departmentIds,
		sceneId,
		query,
		type: SEARCH_BG_CHARS,
	};
}

export function searchBackgroundCharactersComplete(
	epChars: EpisodicCharacter[]
): SearchBackgroundCharactersCompleteAction {
	return {
		epChars,
		type: SEARCH_BG_CHARS_COMPLETE,
	};
}

export function searchBackgroundCharactersFailed(
	error: Error
): SearchBackgroundCharactersFailedAction {
	return {
		error,
		type: SEARCH_BG_CHARS_FAILED,
	};
}

export type Action =
	| SearchBackgroundCharactersAction
	| SearchBackgroundCharactersCompleteAction
	| SearchBackgroundCharactersFailedAction
	| SearchAction
	| SearchCompleteAction
	| SearchFailedAction;
