import { toast } from 'react-toastify';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
	DEPARTMENTS,
	DeptSpecificChangeLookCreateResponse,
	DeptSpecificChangeLookDeleteResponse,
	DeptSpecificChangeLookGetResponse,
	MuChangeScene,
	MuChangeUpdateResponse,
} from 'sos-models';
import * as actions from './mu-change.actions';
import * as api from './mu-change.api';
import * as logger from '../../logger';
import {
	changeLookActions,
	deptChangeSceneActions,
	muChangeSceneActions,
} from '../actions';

export function* getForEpChar({
	episodicId,
	epCharId,
}: actions.GetMuChangesForEpCharAction) {
	try {
		const {
			listed,
		}: DeptSpecificChangeLookGetResponse<DEPARTMENTS.MU> = yield call(
			api.getForEpChar,
			episodicId,
			epCharId
		);
		if (listed.MuChange) {
			yield put(
				actions.getMuChangesForEpCharComplete(listed.MuChange, epCharId)
			);
		}
	} catch (err) {
		yield put(actions.getMuChangesForEpCharFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading the change table. Please refresh to try again.'
			);
		} else {
			logger.error(err);
		}
	}
}

export function* deleteForEpChar({
	episodicId,
	epCharId,
	muChangeId,
}: actions.DeleteMuChangeForEpCharAction) {
	try {
		const result: DeptSpecificChangeLookDeleteResponse<DEPARTMENTS.MU> = yield call(
			api.deleteForEpChar,
			episodicId,
			epCharId,
			muChangeId
		);
		const { destroyed } = result as DeptSpecificChangeLookDeleteResponse<
			DEPARTMENTS.MU
		>;
		yield put(actions.deleteMuChangeForEpCharComplete(epCharId, result));
		yield put(
			changeLookActions.deleteComplete(destroyed.MuChange, DEPARTMENTS.MU)
		);

		if (destroyed.MuChangeScene) {
			yield put(muChangeSceneActions.deleteComplete(destroyed.MuChangeScene));
			yield destroyed.MuChangeScene.forEach((muChangeScene: MuChangeScene) => {
				put(
					deptChangeSceneActions.deleteChangeSceneByIdAndDeptIdComplete(
						muChangeScene.id,
						DEPARTMENTS.MU
					)
				);
			});
		}
	} catch (err) {
		yield put(actions.deleteMuChangeForEpCharFailed(err));
		logger.responseError(err, 'deleting this change', true);
	}
}

export function* getMuChangesForScene({
	prodId,
	sceneId,
}: actions.GetMuChangesForSceneAction) {
	try {
		const {
			listed,
		}: DeptSpecificChangeLookGetResponse<DEPARTMENTS.MU> = yield call(
			api.getMuChangesForScene,
			prodId,
			sceneId
		);
		yield put(actions.getMuChangesForSceneComplete(listed.MuChange, prodId));
	} catch (err) {
		yield put(actions.getMuChangesForSceneFailed(err));
		if (err.response) {
			toast.error(
				'There was an error loading the looks table. Please refresh to try again.'
			);
		} else {
			logger.error(err);
		}
	}
}

export function* create({ episodicId, change }: actions.CreateMuChangeAction) {
	try {
		const res: DeptSpecificChangeLookCreateResponse<DEPARTMENTS.MU> = yield call(
			api.create,
			episodicId,
			DEPARTMENTS.MU,
			change
		);
		yield put(actions.createMuChangeComplete(res.created.MuChange));
	} catch (err) {
		yield put(actions.createMuChangeFailed(err));
		logger.responseError(err, 'creating this look', true);
	}
}

export function* createByScene({
	episodicId,
	change,
	sceneId,
	oldChange,
}: actions.CreateMuChangeBySceneAction) {
	try {
		const { created, deleted, updated } = yield call(
			api.createByScene,
			episodicId,
			DEPARTMENTS.MU,
			change,
			sceneId,
			oldChange
		);
		yield put(actions.createMuChangeBySceneComplete(created, deleted, updated));
	} catch (err) {
		yield put(actions.createChangeBySceneFailed(err));
		logger.responseError(err, 'creating this look', true);
	}
}

export function* update({
	episodicId,
	prodId,
	muChanges,
}: actions.UpdateAction) {
	try {
		const { updated }: MuChangeUpdateResponse = yield call(
			api.update,
			episodicId,
			prodId,
			muChanges
		);
		if (updated.MuChange) {
			yield put(actions.updateComplete(updated.MuChange));
		}
	} catch (err) {
		yield put(actions.updateFailed(err));
		if (err.response) {
			toast.error('There was an error updating this makeup look.');
		} else {
			logger.error(err);
		}
	}
}

function* saga() {
	yield takeLatest(actions.GET_MU_CHANGES_FOR_EP_CHAR, getForEpChar);
	yield takeLatest(actions.DELETE_MU_CHANGE_FOR_EP_CHAR, deleteForEpChar);
	yield takeLatest(actions.GET_MU_CHANGES_FOR_SCENE, getMuChangesForScene);
	yield takeLatest(actions.CREATE_MU_CHANGE, create);
	yield takeLatest(actions.CREATE_MU_CHANGE_BY_SCENE, createByScene);
	yield takeEvery(actions.UPDATE, update);
}

export default saga;
