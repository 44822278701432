import { Sorting } from '@devexpress/dx-react-grid';

export const SET_SORTING = '[Actor Index] Set Sorting';
type SET_SORTING = typeof SET_SORTING;

export const SET_SORTING_BACKGROUND = '[Actor Index] Set Sorting Background';
type SET_SORTING_BACKGROUND = typeof SET_SORTING_BACKGROUND;

export interface SetSortingAction {
	sorting: Sorting[];
	type: SET_SORTING;
}

export interface SetSortingBackgroundAction {
	sorting: Sorting[];
	type: SET_SORTING_BACKGROUND;
}

export function setSorting(sorting: Sorting[]): SetSortingAction {
	return {
		sorting,
		type: SET_SORTING,
	};
}

export function setSortingBackground(
	sorting: Sorting[]
): SetSortingBackgroundAction {
	return {
		sorting,
		type: SET_SORTING_BACKGROUND,
	};
}

export type Action = SetSortingAction | SetSortingBackgroundAction;
