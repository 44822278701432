import { CmWrapBoxEpisodicItem } from 'sos-models';

export const CREATE_COMPLETE = '[CmWrapBoxEpisodicItem] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const LIST_COMPLETE = '[CmWrapBoxEpisodicItem] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const UPDATE_COMPLETE = '[CmWrapBoxEpisodicItem] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE = 'CmWrapBoxEpisodicItem Update';
type UPDATE = typeof UPDATE;

export const DESTROY_COMPLETE =
	'[Costume Wrap Box Episodic Item] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export interface ListCompleteAction {
	type: LIST_COMPLETE;
	cmWrapBoxEpisodicItems: CmWrapBoxEpisodicItem[];
}

export interface UpdateCompleteAction {
	type: UPDATE_COMPLETE;
	cmWrapBoxEpisodicItems: CmWrapBoxEpisodicItem[];
}

export interface UpdateAction {
	type: UPDATE;
	episodicId: number;
	cmWrapBoxEpisodicItems: CmWrapBoxEpisodicItem[];
}

export interface CreateCompleteAction {
	type: CREATE_COMPLETE;
	cmWrapBoxEpisodicItems: CmWrapBoxEpisodicItem[];
}

export interface DestroyCompleteAction {
	type: DESTROY_COMPLETE;
	cmWrapBoxEpisodicItems: CmWrapBoxEpisodicItem[];
}

export function listComplete(
	cmWrapBoxEpisodicItems: CmWrapBoxEpisodicItem[]
): ListCompleteAction {
	return {
		cmWrapBoxEpisodicItems,
		type: LIST_COMPLETE,
	};
}

export function updateComplete(
	cmWrapBoxEpisodicItems: CmWrapBoxEpisodicItem[]
): UpdateCompleteAction {
	return {
		cmWrapBoxEpisodicItems,
		type: UPDATE_COMPLETE,
	};
}

export function update(
	episodicId: number,
	cmWrapBoxEpisodicItems: CmWrapBoxEpisodicItem[]
): UpdateAction {
	return {
		episodicId,
		cmWrapBoxEpisodicItems,
		type: UPDATE,
	};
}

export function createComplete(
	cmWrapBoxEpisodicItems: CmWrapBoxEpisodicItem[]
): CreateCompleteAction {
	return {
		cmWrapBoxEpisodicItems,
		type: CREATE_COMPLETE,
	};
}

export function destroyComplete(
	cmWrapBoxEpisodicItems: CmWrapBoxEpisodicItem[]
): DestroyCompleteAction {
	return {
		cmWrapBoxEpisodicItems,
		type: DESTROY_COMPLETE,
	};
}

export type Action =
	| ListCompleteAction
	| UpdateCompleteAction
	| UpdateAction
	| CreateCompleteAction
	| DestroyCompleteAction
	| ListCompleteAction
	| UpdateCompleteAction;
