import { composeWithDevTools } from '@redux-devtools/extension';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import { cloneDeep } from 'lodash';
import { Store, StoreEnhancer, applyMiddleware, compose } from 'redux';
import * as freeze from 'redux-freeze';
import {
	mergePersistedState,
	default as persistState,
} from 'redux-localstorage';
import filter from 'redux-localstorage-filter';
import adapter from 'redux-localstorage/lib/adapters/localStorage';
import createSagaMiddleware from 'redux-saga';
import createSentryMiddleware from 'redux-sentry-middleware';
import * as logger from '../logger';
import { handleNewlyAddedColumns } from '../services/columns.service';
import {
	accountSlice,
	State as accountSliceState,
} from './accounts/accountSlice';
import { siteActions } from './actions';
import * as activityFeed from './activity-feed/activity-feed.reducer';
import * as activityMonitor from './activity-monitor/activity-monitor.reducer';
import * as actorIndex from './actor-index/actor-index.reducer';
import * as actorTableColumns from './actor-table-columns/actor-table-column.reducer';
import * as addBackgroundCharactersToSceneModal from './add-background-characters-to-scene-modal/add-background-characters-to-scene-modal.reducer';
import * as addExistingInventoryModal from './add-existing-inventory-modal/add-existing-inventory-modal.reducer';
import * as appFeatures from './app-features/app-feature.reducer';
import * as assetHub from './asset-hub/asset-hub.reducer';
import * as changeEpisodicItem from './change-episodic-items/change-episodic-item.reducer';
import * as changeViewInventoryTableColumns from './change-inventory-table-columns/change-inventory-table-column.reducer';
import * as changeLookPictures from './change-look-pictures/change-look-picture.reducer';
import * as changeLooks from './change-looks/change-look.reducer';
import * as changeSceneTableColumns from './change-scene-table-columns/change-scene-table-column.reducer';
import * as changeScenes from './change-scenes/change-scene.reducer';
import * as changes from './changes/change.reducer';
import * as charPropTableColumns from './char-prop-table-columns/char-prop-table-column.reducer';
import * as charScenes from './char-scenes/char-scene.reducer';
import * as characterIndex from './character-index/character-index.reducer';
import * as characterView from './character-view/character-view.reducer';
import * as characters from './characters/character.reducer';
import * as cmBrands from './cm-brands/cm-brand.reducer';
import * as cmCategories from './cm-categories/cm-category.reducer';
import * as cmChangePictures from './cm-change-pictures/cm-change-picture.reducer';
import * as cmEpisodicActors from './cm-episodic-actors/cm-episodic-actor.reducer';
import * as cmEpisodicCharacterNotes from './cm-episodic-character-notes/cm-episodic-character-note.reducer';
import * as cmEpisodicItemPictures from './cm-episodic-item-pictures/cm-episodic-item-picture.reducer';
import * as cmPermissions from './cm-permissions/cm-permission.reducer';
import * as cmSceneNotes from './cm-scene-notes/cm-scene-note.reducer';
import * as cmStatuses from './cm-statuses/cm-status.reducer';
import * as cmStorageLocations from './cm-storage-locations/cm-storage-location.reducer';
import * as cmWrapBoxEpisodicItems from './cm-wrap-box-episodic-items/cm-wrap-box-episodic-item.reducer';
import * as cmWrapBoxes from './cm-wrap-boxes/cm-wrap-box.reducer';
import * as departmentSettings from './department-settings/department-setting.reducer';
import * as departments from './departments/department.reducer';
import * as deptChangeScenes from './dept-change-scenes/dept-change-scene.reducer';
import * as episodicActorPictures from './episodic-actor-pictures/episodic-actor-picture.reducer';
import * as episodicActors from './episodic-actors/episodic-actor.reducer';
import * as episodicCharacterPictures from './episodic-character-pictures/episodic-character-picture.reducer';
import * as episodicCharacters from './episodic-characters/episodic-character.reducer';
import * as episodicFlags from './episodic-flags/episodic-flag.reducer';
import * as episodicItems from './episodic-items/episodic-item.reducer';
import * as episodicProps from './episodic-props/episodic-prop.reducer';
import * as episodics from './episodics/episodic.reducer';
import * as galleryView from './gallery-view/gallery-view.reducer';
import * as haChangeFieldNames from './ha-change-field-names/ha-change-field-names.reducer';
import * as haChangeScenes from './ha-change-scenes/ha-change-scene.reducer';
import * as haChanges from './ha-changes/ha-change.reducer';
import * as haEpisodicActors from './ha-episodic-actors/ha-episodic-actor.reducer';
import * as haEpisodicCharacterNotes from './ha-episodic-character-notes/ha-episodic-character-note.reducer';
import * as haLookPictures from './ha-look-pictures/ha-look-picture.reducer';
import * as haPermissions from './ha-permissions/ha-permission.reducer';
import * as haSceneNotes from './ha-scene-notes/ha-scene-note.reducer';
import * as inventoryFieldSettings from './inventory-field-settings/inventory-field-setting.reducer';
import * as inventoryIndexColumns from './inventory-index-columns/inventory-index-column.reducer';
import * as inventoryView from './inventory/inventory-view.reducer';
import * as inventory from './inventory/inventory.reducer';
import * as muChangeFieldNames from './mu-change-field-names/mu-change-field-names.reducer';
import * as muChangeScenes from './mu-change-scenes/mu-change-scene.reducer';
import * as muChanges from './mu-changes/mu-change.reducer';
import * as muEpisodicActors from './mu-episodic-actors/mu-episodic-actor.reducer';
import * as muEpisodicCharacterNotes from './mu-episodic-character-notes/mu-episodic-character-note.reducer';
import * as muLookPictures from './mu-look-pictures/mu-look-picture.reducer';
import * as muPermissions from './mu-permissions/mu-permission.reducer';
import * as muSceneNotes from './mu-scene-notes/mu-scene-note.reducer';
import * as navbar from './navbar/navbar.reducer';
import * as permissions from './permissions/permission.reducer';
import * as pictureScenes from './picture-scenes/picture-scene.reducer';
import * as pictures from './pictures/picture.reducer';
import * as prEpisodicActors from './pr-episodic-actors/pr-episodic-actor.reducer';
import * as prEpisodicCharacterNotes from './pr-episodic-character-notes/pr-episodic-character-note.reducer';
import * as prEpisodicPropPictures from './pr-episodic-prop-pictures/pr-episodic-prop-picture.reducer';
import * as prPermissions from './pr-permissions/pr-permission.reducer';
import * as prSceneNotes from './pr-scene-notes/pr-scene-note.reducer';
import * as prWrapBoxEpisodicProps from './pr-wrap-box-episodic-props/pr-wrap-box-episodic-prop.reducer';
import * as prWrapBoxes from './pr-wrap-boxes/pr-wrap-box.reducer';
import * as prodPermissions from './prod-permissions/prod-permission.reducer';
import * as productions from './productions/production.reducer';
import * as propCategories from './prop-categories/prop-category.reducer';
import * as propSceneCharScenes from './prop-scene-char-scenes/prop-scene-char-scene.reducer';
import * as propSceneTableColumns from './prop-scene-table-columns/prop-scene-table-column.reducer';
import * as propScenes from './prop-scenes/prop-scene.reducer';
import * as propStatusColors from './prop-status-colors/prop-status-color.reducer';
import * as propStatuses from './prop-statuses/prop-status.reducer';
import * as propStorageLocations from './prop-storage-locations/prop-storage-location.reducer';
import rootSaga from './sagas';
import * as sceneNotes from './scene-notes/scene-note.reducer';
import * as sceneTableColumns from './scene-table-columns/scene-table-column.reducer';
import * as sceneView from './scenes/scene-view.reducer';
import * as scenes from './scenes/scene.reducer';
import * as scriptLocations from './script-locations/script-location.reducer';
import * as setPermissions from './set-permissions/set-permission.reducer';
import * as setPictures from './set-pictures/set-picture.reducer';
import * as setPiecePictures from './set-piece-pictures/set-piece-picture.reducer';
import * as setPieceSets from './set-piece-sets/set-piece-set.reducer';
import * as setPieceStatuses from './set-piece-statuses/set-piece-status.reducer';
import * as setPieceTypes from './set-piece-types/set-piece-type.reducer';
import * as setPieces from './set-pieces/set-piece.reducer';
import * as setProds from './set-prods/set-prod.reducer';
import * as setScenes from './set-scenes/set-scene.reducer';
import * as setStorageLocations from './set-storage-locations/set-storage-location.reducer';
import * as setWrapBoxSetPieces from './set-wrap-box-set-pieces/set-wrap-box-set-piece.reducer';
import * as setWrapBoxes from './set-wrap-boxes/set-wrap-box.reducer';
import * as sets from './sets/set.reducer';
import * as tables from './tables/table.reducer';
import * as universalSearch from './universal-search/universal-search.reducer';
import * as userSetting from './user-settings/user-setting.reducer';
import * as user from './users/user.reducer';
import * as vendors from './vendors/vendor.reducer';
import * as wrapBoxInventoryTableColumns from './wrap-box-inventory-table-columns/wrap-box-inventory-table-column.reducer';
import * as wrapBoxView from './wrap-boxes/wrap-box-view.reducer';
import * as wrapBoxes from './wrap-boxes/wrap-box.reducer';

export interface State {
	accounts: accountSliceState;
	activityFeed: activityFeed.State;
	activityMonitor: activityMonitor.State;
	actorIndex: actorIndex.State;
	actorTableColumns: actorTableColumns.State;
	addBackgroundCharactersToSceneModal: addBackgroundCharactersToSceneModal.State;
	addExistingInventoryModal: addExistingInventoryModal.State;
	assetHub: assetHub.State;
	changeEpisodicItem: changeEpisodicItem.State;
	changeLookPictures: changeLookPictures.State;
	changeLooks: changeLooks.State;
	changes: changes.State;
	changeViewInventoryTableColumns: changeViewInventoryTableColumns.State;
	changeScenes: changeScenes.State;
	ChangeSceneTableColumns: changeSceneTableColumns.State;
	characterIndex: characterIndex.State;
	characters: characters.State;
	characterView: characterView.State;
	charPropTableColumns: charPropTableColumns.State;
	charScenes: charScenes.State;
	cmBrands: cmBrands.State;
	cmCategories: cmCategories.State;
	cmChangePictures: cmChangePictures.State;
	cmEpisodicActors: cmEpisodicActors.State;
	cmEpisodicItemPictures: cmEpisodicItemPictures.State;
	cmPermissions: cmPermissions.State;
	cmSceneNotes: cmSceneNotes.State;
	cmStatuses: cmStatuses.State;
	cmStorageLocations: cmStorageLocations.State;
	cmWrapBoxEpisodicItems: cmWrapBoxEpisodicItems.State;
	cmWrapBoxes: cmWrapBoxes.State;
	departments: departments.State;
	departmentSettings: departmentSettings.State;
	deptChangeScenes: deptChangeScenes.State;
	episodicActorPictures: episodicActorPictures.State;
	episodicActors: episodicActors.State;
	cmEpisodicCharacterNotes: cmEpisodicCharacterNotes.State;
	haEpisodicCharacterNotes: haEpisodicCharacterNotes.State;
	muEpisodicCharacterNotes: muEpisodicCharacterNotes.State;
	prEpisodicCharacterNotes: prEpisodicCharacterNotes.State;
	episodicCharacterPictures: episodicCharacterPictures.State;
	episodicCharacters: episodicCharacters.State;
	episodicFlags: episodicFlags.State;
	episodicItems: episodicItems.State;
	episodicProps: episodicProps.State;
	episodics: episodics.State;
	appFeatures: appFeatures.State;
	galleryView: galleryView.State;
	haChangeFieldNames: haChangeFieldNames.State;
	haChanges: haChanges.State;
	haChangeScenes: haChangeScenes.State;
	haEpisodicActors: haEpisodicActors.State;
	haLookPictures: haLookPictures.State;
	haPermissions: haPermissions.State;
	haSceneNotes: haSceneNotes.State;
	inventory: inventory.State;
	inventoryFieldSettings: inventoryFieldSettings.State;
	inventoryIndexColumns: inventoryIndexColumns.State;
	inventoryView: inventoryView.State;
	muChangeFieldNames: muChangeFieldNames.State;
	muChanges: muChanges.State;
	muChangeScenes: muChangeScenes.State;
	muEpisodicActors: muEpisodicActors.State;
	muLookPictures: muLookPictures.State;
	muPermissions: muPermissions.State;
	muSceneNotes: muSceneNotes.State;
	navbar: navbar.State;
	permissions: permissions.State;
	pictures: pictures.State;
	pictureScenes: pictureScenes.State;
	prEpisodicActors: prEpisodicActors.State;
	prEpisodicPropPictures: prEpisodicPropPictures.State;
	prodPermissions: prodPermissions.State;
	productions: productions.State;
	propCategories: propCategories.State;
	propScenes: propScenes.State;
	propSceneCharScenes: propSceneCharScenes.State;
	propSceneTableColumns: propSceneTableColumns.State;
	propStatusColors: propStatusColors.State;
	propStatuses: propStatuses.State;
	propStorageLocations: propStorageLocations.State;
	prPermissions: prPermissions.State;
	prSceneNotes: prSceneNotes.State;
	prWrapBoxEpisodicProps: prWrapBoxEpisodicProps.State;
	prWrapBoxes: prWrapBoxes.State;
	sceneNotes: sceneNotes.State;
	scenes: scenes.State;
	sceneTableColumns: sceneTableColumns.State;
	sceneView: sceneView.State;
	scriptLocations: scriptLocations.State;
	setPermissions: setPermissions.State;
	setPictures: setPictures.State;
	setPiecePictures: setPiecePictures.State;
	setPieces: setPieces.State;
	setPieceSets: setPieceSets.State;
	setPieceStatuses: setPieceStatuses.State;
	setPieceTypes: setPieceTypes.State;
	setProds: setProds.State;
	setScenes: setScenes.State;
	sets: sets.State;
	setStorageLocations: setStorageLocations.State;
	setWrapBoxes: setWrapBoxes.State;
	setWrapBoxSetPieces: setWrapBoxSetPieces.State;
	tables: tables.State;
	universalSearch: universalSearch.State;
	userSettings: userSetting.State;
	users: user.State;
	vendors: vendors.State;
	wrapBoxes: wrapBoxes.State;
	wrapBoxView: wrapBoxView.State;
	wrapBoxInventoryTableColumns: wrapBoxInventoryTableColumns.State;
}

let enhancer: StoreEnhancer;

const storage = compose<Partial<State>>(
	filter([
		'actorIndex.SortBy',
		'actorIndex.SortOrder',
		'changeViewInventoryTableColumns.ids',
		'changeViewInventoryTableColumns.hidden',
		'changeViewInventoryTableColumns.widths',
		'characterIndex.backgroundSortBy',
		'characterIndex.backgroundSortOrder',
		'characterIndex.primarySortBy',
		'characterIndex.primarySortOrder',
		'charPropTableColumns.ids',
		'charPropTableColumns.hidden',
		'charPropTableColumns.widths',
		'departments.selectedIds',
		'productions.selectedIds',
		'inventory.epCharIdsByEpisodicId',
		'inventory.setIdsByEpisodicId',
		'inventory.sortBy',
		'inventory.sortOrder',
		'inventoryIndexColumns.ids',
		'inventoryIndexColumns.hidden',
		'inventoryIndexColumns.widths',
		'scenes.sortBy',
		'scenes.sortOrder',
		'scenes.fromDateFilter',
		'scenes.toDateFilter',
		'scenes.hideOmittedFilter',
		'sceneTableColumns.ids',
		'sceneTableColumns.hidden',
		'sceneTableColumns.widths',
		'actorTableColumns.ids',
		'actorTableColumns.hidden',
		'actorTableColumns.widths',
		'propSceneTableColumns.ids',
		'propSceneTableColumns.hidden',
		'propSceneTableColumns.widths',
		'wrapBoxInventoryTableColumns.ids',
		'wrapBoxInventoryTableColumns.hidden',
		'wrapBoxInventoryTableColumns.widths',
	])
)(adapter(window.localStorage));
const localStore = persistState(storage, 'redux');

const sagaMiddleware = createSagaMiddleware({
	onError: function (err: Error) {
		logger.error(err);
	},
});

const sentryMiddleware = createSentryMiddleware(Sentry, {
	// filtering out entities from the state for 2 reasons:
	// be careful not to edit the state here - nested objects and such
	// 1 - the state would be enormous
	// 2 - they may contain sensitive info
	stateTransformer: function (state: State) {
		const data = cloneDeep(state);
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				if (data[key].entities) {
					delete data[key].entities;
				}
				if (data[key].entity) {
					delete data[key].entity;
				}
			}
		}
		return data;
	},
});

const middleware = [sentryMiddleware, sagaMiddleware];

if (
	process.env.SOS_ENV === 'local' ||
	process.env.SOS_ENV === 'dev' ||
	process.env.SOS_ENV === 'beta'
) {
	middleware.push(freeze);
	enhancer = composeWithDevTools(applyMiddleware(...middleware), localStore);
} else {
	enhancer = compose(
		applyMiddleware(...middleware),
		localStore
	) as StoreEnhancer;
}

const rootReducer = combineReducers({
	[siteActions.LOGOUT_COMPLETE]: (state) => {
		logger.unsetUser();
		state = null;
		return state;
	},
	accounts: accountSlice.reducer,
	activityFeed: activityFeed.reducer,
	activityMonitor: activityMonitor.reducer,
	actorIndex: actorIndex.reducer,
	addExistingInventoryModal: addExistingInventoryModal.reducer,
	actorTableColumns: actorTableColumns.reducer,
	addBackgroundCharactersToSceneModal:
		addBackgroundCharactersToSceneModal.reducer,
	assetHub: assetHub.reducer,
	changeEpisodicItem: changeEpisodicItem.reducer,
	changeLookPictures: changeLookPictures.reducer,
	changeLooks: changeLooks.reducer,
	changes: changes.reducer,
	changeViewInventoryTableColumns: changeViewInventoryTableColumns.reducer,
	ChangeSceneTableColumns: changeSceneTableColumns.reducer,
	changeScenes: changeScenes.reducer,
	characterIndex: characterIndex.reducer,
	characters: characters.reducer,
	characterView: characterView.reducer,
	charPropTableColumns: charPropTableColumns.reducer,
	charScenes: charScenes.reducer,
	cmBrands: cmBrands.reducer,
	cmCategories: cmCategories.reducer,
	cmChangePictures: cmChangePictures.reducer,
	cmEpisodicActors: cmEpisodicActors.reducer,
	cmEpisodicCharacterNotes: cmEpisodicCharacterNotes.reducer,
	cmEpisodicItemPictures: cmEpisodicItemPictures.reducer,
	cmPermissions: cmPermissions.reducer,
	cmSceneNotes: cmSceneNotes.reducer,
	cmStatuses: cmStatuses.reducer,
	cmStorageLocations: cmStorageLocations.reducer,
	cmWrapBoxEpisodicItems: cmWrapBoxEpisodicItems.reducer,
	cmWrapBoxes: cmWrapBoxes.reducer,
	departments: departments.reducer,
	departmentSettings: departmentSettings.reducer,
	deptChangeScenes: deptChangeScenes.reducer,
	episodicActors: episodicActors.reducer,
	episodicActorPictures: episodicActorPictures.reducer,
	episodicCharacterPictures: episodicCharacterPictures.reducer,
	episodicCharacters: episodicCharacters.reducer,
	episodicFlags: episodicFlags.reducer,
	episodicItems: episodicItems.reducer,
	episodicProps: episodicProps.reducer,
	episodics: episodics.reducer,
	appFeatures: appFeatures.reducer,
	galleryView: galleryView.reducer,
	haChangeFieldNames: haChangeFieldNames.reducer,
	haChanges: haChanges.reducer,
	haChangeScenes: haChangeScenes.reducer,
	haEpisodicActors: haEpisodicActors.reducer,
	haEpisodicCharacterNotes: haEpisodicCharacterNotes.reducer,
	haLookPictures: haLookPictures.reducer,
	haPermissions: haPermissions.reducer,
	haSceneNotes: haSceneNotes.reducer,
	inventory: inventory.reducer,
	inventoryFieldSettings: inventoryFieldSettings.reducer,
	inventoryIndexColumns: inventoryIndexColumns.reducer,
	inventoryView: inventoryView.reducer,
	muChangeFieldNames: muChangeFieldNames.reducer,
	muChanges: muChanges.reducer,
	muChangeScenes: muChangeScenes.reducer,
	muEpisodicActors: muEpisodicActors.reducer,
	muEpisodicCharacterNotes: muEpisodicCharacterNotes.reducer,
	muLookPictures: muLookPictures.reducer,
	muPermissions: muPermissions.reducer,
	muSceneNotes: muSceneNotes.reducer,
	navbar: navbar.reducer,
	permissions: permissions.reducer,
	pictures: pictures.reducer,
	pictureScenes: pictureScenes.reducer,
	prEpisodicActors: prEpisodicActors.reducer,
	prEpisodicCharacterNotes: prEpisodicCharacterNotes.reducer,
	prEpisodicPropPictures: prEpisodicPropPictures.reducer,
	prodPermissions: prodPermissions.reducer,
	productions: productions.reducer,
	propCategories: propCategories.reducer,
	propScenes: propScenes.reducer,
	propSceneCharScenes: propSceneCharScenes.reducer,
	propSceneTableColumns: propSceneTableColumns.reducer,
	propStatusColors: propStatusColors.reducer,
	propStatuses: propStatuses.reducer,
	propStorageLocations: propStorageLocations.reducer,
	prPermissions: prPermissions.reducer,
	prSceneNotes: prSceneNotes.reducer,
	prWrapBoxEpisodicProps: prWrapBoxEpisodicProps.reducer,
	prWrapBoxes: prWrapBoxes.reducer,
	sceneNotes: sceneNotes.reducer,
	scenes: scenes.reducer,
	sceneTableColumns: sceneTableColumns.reducer,
	sceneView: sceneView.reducer,
	scriptLocations: scriptLocations.reducer,
	setPermissions: setPermissions.reducer,
	setPictures: setPictures.reducer,
	setPiecePictures: setPiecePictures.reducer,
	setPieces: setPieces.reducer,
	setPieceSets: setPieceSets.reducer,
	setPieceStatuses: setPieceStatuses.reducer,
	setPieceTypes: setPieceTypes.reducer,
	setProds: setProds.reducer,
	setScenes: setScenes.reducer,
	sets: sets.reducer,
	setStorageLocations: setStorageLocations.reducer,
	setWrapBoxes: setWrapBoxes.reducer,
	setWrapBoxSetPieces: setWrapBoxSetPieces.reducer,
	tables: tables.reducer,
	universalSearch: universalSearch.reducer,
	userSettings: userSetting.reducer,
	users: user.reducer,
	vendors: vendors.reducer,
	wrapBoxes: wrapBoxes.reducer,
	wrapBoxView: wrapBoxView.reducer,
	wrapBoxInventoryTableColumns: wrapBoxInventoryTableColumns.reducer,
});

const reducer = compose(
	mergePersistedState<State>((initial, persisted) => {
		const results = { ...initial };
		for (const key in persisted) {
			if (persisted.hasOwnProperty(key)) {
				if (key.endsWith('Columns')) {
					const newPersisted = handleNewlyAddedColumns(
						initial[key],
						persisted[key]
					);
					results[key] = {
						...initial[key],
						...newPersisted,
					};
				} else {
					results[key] = {
						...initial[key],
						...persisted[key],
					};
				}
			}
		}

		return results;
	})
)(rootReducer);

const store = configureStore({
	enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(enhancer),
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
	reducer,
});

sagaMiddleware.run(rootSaga);

declare global {
	interface Window {
		sosReduxStore: Store;
	}
}

window.sosReduxStore = store;

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
