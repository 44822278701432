import { SetPicture } from 'sos-models';

export const DESTROY = '[Set Pictures] Destroy';
type DESTROY = typeof DESTROY;

export const DESTROY_COMPLETE = '[Set Pictures] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const DESTROY_FAILED = '[Set Pictures] Destroy Failed';
type DESTROY_FAILED = typeof DESTROY_FAILED;

export const LIST_COMPLETE = '[Set Pictures] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export interface DestroyAction {
	episodicId: number;
	setPictureId: number;
	type: DESTROY;
}

export interface DestroyCompleteAction {
	setPicture: SetPicture[];
	type: DESTROY_COMPLETE;
}

export interface DestroyFailedAction {
	error: Error;
	type: DESTROY_FAILED;
}

export interface ListCompleteAction {
	setPictures: SetPicture[];
	type: LIST_COMPLETE;
}

export function destroy(
	episodicId: number,
	setPictureId: number
): DestroyAction {
	return {
		episodicId,
		setPictureId,
		type: DESTROY,
	};
}

export function destroyComplete(
	setPicture: SetPicture[]
): DestroyCompleteAction {
	return {
		setPicture,
		type: DESTROY_COMPLETE,
	};
}

export function destroyFailed(error: Error): DestroyFailedAction {
	return {
		error,
		type: DESTROY_FAILED,
	};
}

export function listComplete(setPictures: SetPicture[]): ListCompleteAction {
	return {
		setPictures,
		type: LIST_COMPLETE,
	};
}

export type Action =
	| DestroyAction
	| DestroyCompleteAction
	| DestroyFailedAction
	| ListCompleteAction;
