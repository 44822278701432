import { EpisodicItem } from 'sos-models';

export const GET_COMPLETE = '[EpisodicItem] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const LIST_COMPLETE = '[Episodic Item] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FOR_EP_CHAR = '[Episodic Item] List for Episodic Character';
type LIST_FOR_EP_CHAR = typeof LIST_FOR_EP_CHAR;

export const LIST_FOR_EP_CHAR_FAILED =
	'[Episodic Item] List for Episodic Character Failed';
type LIST_FOR_EP_CHAR_FAILED = typeof LIST_FOR_EP_CHAR_FAILED;

export const LIST_NO_CLOSET = '[Episodic Item] List for No Closet';
type LIST_NO_CLOSET = typeof LIST_NO_CLOSET;

export const LIST_NO_CLOSET_FAILED =
	'[Episodic Item] List for No Closet Failed';
type LIST_NO_CLOSET_FAILED = typeof LIST_NO_CLOSET_FAILED;

export interface GetCompleteAction {
	episodicItem: EpisodicItem;
	type: GET_COMPLETE;
}

export interface ListCompleteAction {
	type: LIST_COMPLETE;
	inventory: EpisodicItem[];
}

export interface ListForEpCharAction {
	type: LIST_FOR_EP_CHAR;
	episodicId: number;
	epCharId: number;
}

export interface ListForEpCharFailedAction {
	type: LIST_FOR_EP_CHAR_FAILED;
	error: Error;
}

export interface ListNoClosetAction {
	type: LIST_NO_CLOSET;
	episodicId: number;
}

export interface ListNoClosetFailedAction {
	type: LIST_NO_CLOSET_FAILED;
	error: Error;
}

export function getComplete(episodicItem: EpisodicItem): GetCompleteAction {
	return {
		episodicItem,
		type: GET_COMPLETE,
	};
}

export function listComplete(inventory: EpisodicItem[]): ListCompleteAction {
	return {
		inventory,
		type: LIST_COMPLETE,
	};
}

export function listForEpChar(
	episodicId: number,
	epCharId: number
): ListForEpCharAction {
	return {
		type: LIST_FOR_EP_CHAR,
		episodicId,
		epCharId,
	};
}

export function listForEpCharFailed(error: Error): ListForEpCharFailedAction {
	return {
		type: LIST_FOR_EP_CHAR_FAILED,
		error,
	};
}

export function listNoCloset(episodicId: number): ListNoClosetAction {
	return {
		type: LIST_NO_CLOSET,
		episodicId,
	};
}

export function listNoClosetFailed(error: Error): ListNoClosetFailedAction {
	return {
		type: LIST_NO_CLOSET_FAILED,
		error,
	};
}

export type Action =
	| GetCompleteAction
	| ListCompleteAction
	| ListForEpCharAction
	| ListForEpCharFailedAction
	| ListNoClosetAction
	| ListNoClosetFailedAction;
