import { Sorting } from '@devexpress/dx-react-grid';
import {
	AnyWrapBox,
	CmWrapBox,
	DEPARTMENTS,
	DeptWrapBoxes,
	PartialDeptWrapBoxes,
	PrWrapBox,
	SetWrapBox,
	SortOrder,
} from 'sos-models';

export const CREATE = '[WrapBox] Create';
type CREATE = typeof CREATE;

export const CREATE_COMPLETE = '[WrapBox] Create Complete';
type CREATE_COMPLETE = typeof CREATE_COMPLETE;

export const CREATE_FAILED = '[WrapBox] Create Failed';
type CREATE_FAILED = typeof CREATE_FAILED;

export const GET = '[WrapBox] Get';
type GET = typeof GET;

export const GET_COMPLETE = '[WrapBox] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export const GET_FAILED = '[WrapBox] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export const LIST = '[WrapBox] List';
type LIST = typeof LIST;

export const LIST_COMPLETE = '[WrapBox] List Complete';
type LIST_COMPLETE = typeof LIST_COMPLETE;

export const LIST_FAILED = '[WrapBox] List Failed';
type LIST_FAILED = typeof LIST_FAILED;

export const SET_CURRENT = '[WrapBox] Set Current';
type SET_CURRENT = typeof SET_CURRENT;

export const UPDATE = '[WrapBox] Update';
type UPDATE = typeof UPDATE;

export const UPDATE_COMPLETE = '[WrapBox] Update Complete';
type UPDATE_COMPLETE = typeof UPDATE_COMPLETE;

export const UPDATE_FAILED = '[WrapBox] Update Failed';
type UPDATE_FAILED = typeof UPDATE_FAILED;

export const EXPORT_WRAP_BOXES = '[WrapBox] Export Wrap Box Table';
type EXPORT_WRAP_BOXES = typeof EXPORT_WRAP_BOXES;

export const EXPORT_FAILED = '[WrapBox] Export Failed';
type EXPORT_FAILED = typeof EXPORT_FAILED;

export const EXPORT_COMPLETE = '[WrapBox] Export Complete';
type EXPORT_COMPLETE = typeof EXPORT_COMPLETE;

export const DESTROY = '[WrapBox] Destroy';
type DESTROY = typeof DESTROY;

export const DESTROY_COMPLETE = '[WrapBox] Destroy Complete';
type DESTROY_COMPLETE = typeof DESTROY_COMPLETE;

export const DESTROY_FAILED = '[WrapBox] Destroy Failed';
type DESTROY_FAILED = typeof DESTROY_FAILED;

export const SET_SORTING = '[WrapBox] Set Sorting';
type SET_SORTING = typeof SET_SORTING;

export interface CreateAction {
	departmentId: number;
	episodicId: number;
	wrapBox: Partial<AnyWrapBox>;
	type: CREATE;
}

export interface CreateCompleteAction {
	departmentId: number;
	wrapBoxes: DeptWrapBoxes;
	type: CREATE_COMPLETE;
}

export interface CreateFailedAction {
	error: Error;
	type: CREATE_FAILED;
}

export interface GetAction {
	episodicId: number;
	departmentId: DEPARTMENTS;
	wrapBoxId: number;
	type: GET;
}

export interface GetCompleteAction {
	departmentId: DEPARTMENTS;
	wrapBox: DeptWrapBoxes;
	type: GET_COMPLETE;
}

export interface GetFailedAction {
	error: Error;
	type: GET_FAILED;
}

export interface ExportAction {
	episodicId: number;
	options: ListOptions;
	type: EXPORT_WRAP_BOXES;
}

export interface ExportCompleteAction {
	csv: string;
	type: EXPORT_COMPLETE;
}

export interface ExportFailedAction {
	error: Error;
	type: EXPORT_FAILED;
}

export interface ListOptions {
	storageLocationIds: number[];
	departments: DEPARTMENTS[];
	search: string;
	sortBy: string;
	sortOrder: SortOrder;
}

export interface ListAction {
	episodicId: number;
	type: LIST;
}

export interface ListCompleteAction {
	deptWrapBoxes: DeptWrapBoxes;
	type: LIST_COMPLETE;
}

export interface ListFailedAction {
	error: Error;
	type: LIST_FAILED;
}

export interface SetCurrentAction {
	currentDepartmentId: number;
	currentId: number;
	type: SET_CURRENT;
}

export interface UpdateAction {
	episodicId: number;
	deptWrapBoxes: PartialDeptWrapBoxes;
	type: UPDATE;
}

export interface UpdateCompleteAction {
	deptWrapBoxes: DeptWrapBoxes;
	type: UPDATE_COMPLETE;
}

export interface UpdateFailedAction {
	error: Error;
	type: UPDATE_FAILED;
}

export interface ExportAction {
	episodicId: number;
	options: ListOptions;
	type: EXPORT_WRAP_BOXES;
}

export interface ExportCompleteAction {
	csv: string;
	type: EXPORT_COMPLETE;
}

export interface ExportFailedAction {
	error: Error;
	type: EXPORT_FAILED;
}

export interface DestroyedWrapBoxes {
	CmWrapBox?: CmWrapBox[];
	PrWrapBox?: PrWrapBox[];
	SetWrapBox?: SetWrapBox[];
}

export interface DestroyAction {
	episodicId: number;
	departmentId: DEPARTMENTS;
	wrapBoxId: number;
	type: DESTROY;
}

export interface DestroyCompleteAction {
	deleted: DestroyedWrapBoxes;
	type: DESTROY_COMPLETE;
}

export interface DestroyFailedAction {
	error: Error;
	type: DESTROY_FAILED;
}

export interface SetSortingAction {
	sorting: Sorting[];
	type: SET_SORTING;
}

export function create(
	episodicId: number,
	departmentId: number,
	wrapBox: Partial<AnyWrapBox>
): CreateAction {
	return {
		departmentId,
		episodicId,
		wrapBox,
		type: CREATE,
	};
}

export function createComplete(
	departmentId: number,
	wrapBoxes: DeptWrapBoxes
): CreateCompleteAction {
	return {
		departmentId,
		wrapBoxes,
		type: CREATE_COMPLETE,
	};
}

export function createFailed(error: Error): CreateFailedAction {
	return {
		error,
		type: CREATE_FAILED,
	};
}

export function get(
	episodicId: number,
	departmentId: DEPARTMENTS,
	wrapBoxId: number
): GetAction {
	return {
		episodicId,
		departmentId,
		wrapBoxId,
		type: GET,
	};
}

export function getComplete(
	wrapBox: DeptWrapBoxes,
	departmentId: DEPARTMENTS
): GetCompleteAction {
	return {
		wrapBox,
		departmentId,
		type: GET_COMPLETE,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		error,
		type: GET_FAILED,
	};
}

export function list(episodicId: number): ListAction {
	return {
		episodicId,
		type: LIST,
	};
}

export function listFailed(error: Error): ListFailedAction {
	return {
		error,
		type: LIST_FAILED,
	};
}

export function listComplete(deptWrapBoxes: DeptWrapBoxes): ListCompleteAction {
	return {
		deptWrapBoxes,
		type: LIST_COMPLETE,
	};
}

export function setCurrent(
	currentId: number,
	currentDepartmentId: number
): SetCurrentAction {
	return {
		currentDepartmentId,
		currentId,
		type: SET_CURRENT,
	};
}

export function update(
	episodicId: number,
	deptWrapBoxes: PartialDeptWrapBoxes
): UpdateAction {
	return {
		deptWrapBoxes,
		episodicId,
		type: UPDATE,
	};
}

export function updateFailed(error: Error): UpdateFailedAction {
	return {
		error,
		type: UPDATE_FAILED,
	};
}

export function updateComplete(
	deptWrapBoxes: DeptWrapBoxes
): UpdateCompleteAction {
	return {
		deptWrapBoxes,
		type: UPDATE_COMPLETE,
	};
}

export function exportWrapBoxes(
	episodicId: number,
	options: ListOptions
): ExportAction {
	return {
		episodicId,
		options,
		type: EXPORT_WRAP_BOXES,
	};
}

export function exportFailed(error: Error): ExportFailedAction {
	return {
		error,
		type: EXPORT_FAILED,
	};
}

export function exportComplete(csv: string): ExportCompleteAction {
	return {
		csv,
		type: EXPORT_COMPLETE,
	};
}

export function destroy(
	episodicId: number,
	departmentId: DEPARTMENTS,
	wrapBoxId: number
): DestroyAction {
	return {
		episodicId,
		departmentId,
		wrapBoxId,
		type: DESTROY,
	};
}

export function destroyFailed(error: Error): DestroyFailedAction {
	return {
		error,
		type: DESTROY_FAILED,
	};
}

export function destroyComplete(
	deleted: DestroyedWrapBoxes
): DestroyCompleteAction {
	return {
		deleted,
		type: DESTROY_COMPLETE,
	};
}

export function setSorting(sorting: Sorting[]): SetSortingAction {
	return {
		sorting,
		type: SET_SORTING,
	};
}

export type Action =
	| CreateAction
	| CreateCompleteAction
	| CreateFailedAction
	| GetAction
	| GetCompleteAction
	| GetFailedAction
	| ListAction
	| ListFailedAction
	| ListCompleteAction
	| SetCurrentAction
	| UpdateAction
	| UpdateFailedAction
	| UpdateCompleteAction
	| DestroyAction
	| DestroyFailedAction
	| DestroyCompleteAction
	| ExportAction
	| ExportFailedAction
	| ExportCompleteAction
	| SetSortingAction;
