import { EpisodicActorPicture } from 'sos-models';
import * as actions from './episodic-actor-picture.actions';
import { episodicActorSchema } from '../schemas';
import { createReducer, listComplete } from '../utils';
import { Reducer } from 'redux';

export interface State {
	entities: { [id: number]: EpisodicActorPicture };
	ids: number[];
}

const initial: State = {
	entities: {},
	ids: [],
};

export const reducer: Reducer = createReducer<State, actions.Action>(initial, {
	[actions.LIST_COMPLETE]: listComplete<
		EpisodicActorPicture,
		State,
		actions.ListCompleteAction
	>('episodicActorPictures', episodicActorSchema),
});
