import {
	AnyEpisodicCharacterNote,
	EpisodicCharacterDepartmentNoteCreateResponse,
	EpisodicCharacterDepartmentNoteUpdateResponse,
	EpisodicCharacterDeptartmentNotePayload,
} from 'sos-models';
import axios from '../../config/axios';

export async function list(
	episodicId: number,
	epCharId: number
): Promise<AnyEpisodicCharacterNote[]> {
	const { data } = await axios.get<AnyEpisodicCharacterNote[]>(
		`/api/episodics/${episodicId}/episodic-characters/${epCharId}/notes`
	);
	return data;
}

export async function update(
	episodicId: number,
	episodicCharacterNotes: EpisodicCharacterDeptartmentNotePayload
): Promise<EpisodicCharacterDepartmentNoteUpdateResponse> {
	const { data } = await axios.put<
		EpisodicCharacterDepartmentNoteUpdateResponse
	>(`/api/episodics/${episodicId}/notes`, episodicCharacterNotes);
	return data;
}

export async function create(
	episodicId: number,
	episodicCharacterNotes: EpisodicCharacterDeptartmentNotePayload
): Promise<EpisodicCharacterDepartmentNoteCreateResponse> {
	const { data } = await axios.post<
		EpisodicCharacterDepartmentNoteCreateResponse
	>(`/api/episodics/${episodicId}/notes`, episodicCharacterNotes);
	return data;
}
