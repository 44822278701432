import { UnknownAction } from 'redux';
import { Permission } from 'sos-models';

export const GET = '[Permissions] Get';
type GET = typeof GET;

export const GET_FAILED = '[Permissions] Get Failed';
type GET_FAILED = typeof GET_FAILED;

export const GET_COMPLETE = '[Permissions] Get Complete';
type GET_COMPLETE = typeof GET_COMPLETE;

export interface GetAction extends UnknownAction {
	episodicId: number;
	type: GET;
}

export interface GetFailedAction extends UnknownAction {
	error: Error;
	type: GET_FAILED;
}

export interface GetCompleteAction extends UnknownAction {
	payload: any;
	type: GET_COMPLETE;
	permissions: Permission[];
}

export function get(episodicId: number): GetAction {
	return {
		type: GET,
		episodicId,
	};
}

export function getFailed(error: Error): GetFailedAction {
	return {
		type: GET_FAILED,
		error,
	};
}

export function getComplete(permissions: Permission[]): GetCompleteAction {
	return {
		type: GET_COMPLETE,
		payload: {},
		permissions: permissions,
	};
}

export type Action = GetAction | GetFailedAction | GetCompleteAction;
